import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const SmsGateWay = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Please enter your phone number in <i>Phone Number + SMS Gateway</i>{" "}
        format.
      </p>
      <div className="sms-gateway-table">
        <div className="row">
          <div className="col-5">
            <p>
              <strong>Mobile Carrier</strong>
            </p>
          </div>
          <div className="col-7">
            <p>
              <strong>SMS Gateway Domain</strong>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p>Alltel</p>
          </div>
          <div className="col-7">
            <p>sms.alltelwireless.com</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p>AT&T</p>
          </div>
          <div className="col-7">
            <p>txt.att.net</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p>Boost Mobile</p>
          </div>
          <div className="col-7">
            <p>sms.myboostmobile.com</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p>Republic Wireless</p>
          </div>
          <div className="col-7">
            <p>text.republicwireless.com</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p>Sprint</p>
          </div>
          <div className="col-7">
            <p>messaging.sprintpcs.com</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p>T-Mobile</p>
          </div>
          <div className="col-7">
            <p>tmomail.net</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p>U.S. Cellular</p>
          </div>
          <div className="col-7">
            <p>email.uscc.net</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p>Verizon Wireless</p>
          </div>
          <div className="col-7">
            <p>vtext.com</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p>Virgin Mobile</p>
          </div>
          <div className="col-7">
            <p>vmobl.com</p>
          </div>
        </div>
      </div>
      <p>
        For Example:
        <br />
        PhoneNumber@SMSGatewayDomain
        <br />
        1112223333@vtext.com
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="SMS Gateway Information Icon"
      header="Phone Number + SMS Gateway"
      content={content}
    />
  );
};

export default SmsGateWay;
