import React, { useState } from "react";
import { Modal } from "reactstrap";

const ItemPackageWeight = (props) => {
  const [modal, setModal] = useState(false);

  const { targetName } = props;

  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="info-icon modal-only button-link"
        data-trigger="focus"
        data-toggle="modal"
        data-target="#weight-modal"
        data-backdrop="static"
        tabIndex="0"
        data-original-title=""
        aria-label="Item Weight Information Icon"
        title=""
        id={targetName}
        onClick={toggle}
      />
      <Modal isOpen={modal} toggle={toggle}>
        <div className="modal-content modal-container">
          <div className="modal-header">
            <button
              type="button"
              className="close button-link"
              id="close"
              data-dismiss="modal"
              tabIndex="0"
              onClick={toggle}
            >
              <span className="visuallyhidden">Close Modal</span>
            </button>
            <h3 className="modal-title">Item Weight</h3>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <p>
                When entering the item information (i.e. Quantity and Weight),
                it is very important not to exceed the Total Package Weight that
                was previously entered at the bottom of the Label Information
                page. Remember the Total Package Weight expressed in pounds and
                ounces includes the packaging as well as the combined weight of
                all the items included in the package.
              </p>
              <p>
                <strong className="header-examples">Examples:</strong>
                <br />
                Let us say on the Label Information page you entered one (1)
                pound for the Total Package Weight. Here on the Customs
                Declaration page, you are required to provide the specific
                information (Value, Quantity, and Weight) for each item included
                in your package.
              </p>
              <p>
                If you have only one (1) item (e.g. a book) included in your
                package, it cannot exceed one (1) pound or the Total Package
                Weight.
              </p>
              <p className="list-modal">
                Quantity: one (1) book
                <br />
                Weight: one (1) pound
                <br />1 (Quantity of books) multiplied by 1 (weight of each
                book) = 1 (total item weight)
                <br />1 (total item weight is equal to 1 (Total Package Weight)
              </p>
              <p>
                In this example, you would not receive an informational message
                indicating that the combined weight of your items has exceeded
                the Total Package Weight and would proceed to the next page.
              </p>
              <p>
                <strong>
                  <span className="blue-note">NOTE:</span> The combined total
                  weight of all of the items in your package, must be less than
                  or equal to the Total Package Weight.
                </strong>
              </p>
              <p>
                If you have two (2) items (i.e. two (2) books), combined they
                cannot exceed one (1) pound (Total Package Weight), as here on
                the Customs Declarations page the total weight of each item is
                compared to the value entered for the Total Package Weight. If
                you were to continue with the example that you have two (2)
                books, each weighing one (1) pound, you would exceed the Total
                Package Weight entered on the Label Information page and here is
                why:
              </p>
              <p className="list-modal">
                Quantity: two (2) books
                <br />
                Weight: one (1) pound each
                <br />2 (Quantity of books) multiplied by 1 (weight of each
                book) = 2 (total item weight)
                <br />2 (total item weight is greater than 1 (Total Package
                Weight)
              </p>
              <p>
                In this example, you would receive an informational message
                indicating that the combined weight of your items has exceeded
                the Total Package Weight, and you would be required to take
                corrective action before being allowed to proceed to the next
                page.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ItemPackageWeight;
