import React from "react";
import DeleteFreeSuppliesModal from "../Modals/DeleteFreeSuppliesModal";

const FreeSuppliesTable = (props) => {
  const { freeSupplies, deleteFreeSupplies } = props;

  const shippingText = {
    parcelSelect: {
      description: "Parcel Select Shipping",
      estimate: "Arrives in 5 to 7 business days"
    },
    priorityMail: {
      description: "Priority Mail® Shipping",
      estimate:
        "Arrives in 2 to 3 business days. Priority Mail shipping charge will be processed separately from the rest of your order"
    }
  };

  // Place the free supplies shipping option at the bottom of the list
  const getFreeSuppliesWithShippingLast = () => {
    let freeSuppliesWithShippingLast = JSON.parse(JSON.stringify(freeSupplies));
    let shippingOption = [];
    let shippingOptionIndex = -1;
    for (let i in freeSuppliesWithShippingLast) {
      if (
        (freeSuppliesWithShippingLast[i].cnsCartTypeId &&
          freeSuppliesWithShippingLast[i].cnsCartTypeId.value === 5) ||
        (freeSuppliesWithShippingLast[i].orderItemTypeId &&
          freeSuppliesWithShippingLast[i].orderItemTypeId.value === 5)
      ) {
        shippingOption.push(freeSuppliesWithShippingLast[i]);
        shippingOptionIndex = i;
        break;
      }
    }
    freeSuppliesWithShippingLast.splice(shippingOptionIndex, 1);
    freeSuppliesWithShippingLast = freeSuppliesWithShippingLast.concat(
      shippingOption
    );
    return freeSuppliesWithShippingLast;
  };

  const freeSuppliesWithShippingLast = getFreeSuppliesWithShippingLast();

  return (
    <React.Fragment>
      <h2 className="d-md" style={{ paddingTop: "10px" }}>
        <u></u>
      </h2>
      <div
        className="row shipping-cart-table-header"
        style={{ paddingTop: "20px" }}
      >
        <div className="d-none d-md-block col-2" />
        <div className="d-none d-md-block col-6">
          <p>
            <strong>Product</strong>
          </p>
        </div>
        <div className="d-none d-md-block col-3">
          <p>
            <strong>Quantity</strong>
          </p>
        </div>
        <div className="d-none d-md-block col-1">
          <p>
            <strong>Price</strong>
          </p>
        </div>
      </div>
      {freeSuppliesWithShippingLast.map((item, index) => (
        <React.Fragment key={item.cnsCartId}>
          <div className="row shipping-cart-item">
            <div className="col-1 d-md-none order-1 mobile-shipping-cart-number">
              <p />
            </div>

            <div className="col-3 col-md-2 shipping-cart-edit-delete text-right text-md-left order-3 order-md-2">
              <p className="d-none d-md-block"> </p>

              <p className=" d-none d-md-block">
                {index + 1} of {freeSuppliesWithShippingLast.length}
              </p>

              {deleteFreeSupplies && (
                <p>
                  <DeleteFreeSuppliesModal
                    deleteFreeSupplies={deleteFreeSupplies}
                    item={item}
                    itemDescription={
                      item.productId.productClass !== "FS"
                        ? item.productId.productClassDesc +
                          " " +
                          item.productId.productDesc
                        : item.productId.productId === 700
                        ? shippingText.parcelSelect.description
                        : item.productId.productId === 701
                        ? shippingText.priorityMail.description
                        : ""
                    }
                    itemDimensions={
                      item.productId.productClass !== "FS"
                        ? item.productId.productDimensions
                        : item.productId.productId === 700
                        ? shippingText.parcelSelect.estimate
                        : item.productId.productId === 701
                        ? shippingText.priorityMail.estimate
                        : ""
                    }
                    isShippingOption={item.productId.productClass === "FS"}
                  />
                </p>
              )}
            </div>

            <div className="col-8 col-md-6 order-2 order-md-3">
              {item.productId.productClass !== "FS" ? (
                <React.Fragment>
                  <strong>
                    <p>
                      {item.productId.productClassDesc +
                        " " +
                        item.productId.productDesc}
                    </p>
                  </strong>
                  <p>{item.productId.productDimensions}</p>
                </React.Fragment>
              ) : item.productId.productId === 700 ? (
                <React.Fragment>
                  <strong>
                    <p>{shippingText.parcelSelect.description}</p>
                  </strong>
                  <p>{shippingText.parcelSelect.estimate}</p>
                </React.Fragment>
              ) : item.productId.productId === 701 ? (
                <React.Fragment>
                  <strong>
                    <p>{shippingText.priorityMail.description}</p>
                  </strong>
                  <p>{shippingText.priorityMail.estimate}</p>
                </React.Fragment>
              ) : null}
            </div>
            <div className="col-11 col-md-3 order-4 offset-1 offset-md-0">
              {item.productId.productClass !== "FS" && (
                <React.Fragment>
                  <p className="d-md-none mobile-shipping-cart-package-details">
                    Quantity
                  </p>
                  <p>{item.itemQty}</p>
                </React.Fragment>
              )}
            </div>
            <div className="col-11 col-md-1 order-5 offset-1 offset-md-0 service-type-table">
              <p className="d-md-none mobile-shipping-cart-service">Price</p>
              <div className="row">
                <div className="col-4 text-right">
                  {item.labelCost && item.labelCost.postageAmt ? (
                    <p>${Number(item.labelCost.postageAmt).toFixed(2)}</p>
                  ) : item.orderItemCost && item.orderItemCost.postageAmt ? (
                    <p>${Number(item.orderItemCost.postageAmt).toFixed(2)}</p>
                  ) : (
                    <p>Free</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default FreeSuppliesTable;
