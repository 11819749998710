import React from "react";
import "../click-n-ship.css";
import "../bootstrap.min.css";
import "../calendar.css";
import "../default-styles.css";
import CnsNavBarLinks from "./CnsNavBarLinks";
import CnsNavResponsive from "./CnsNavResponsive";
import { withRouter } from "react-router-dom";

class CnsNavBar extends React.Component {
  constructor(props) {
    super(props);

    let arrOfCnsLinks = [
      {
        id: 1,
        path: "/labelInformation",
        title: "Create a Label",
        css: "tab d-none d-md-inline-block"
      },
      {
        id: 2,
        path: "/preferences",
        title: "Preferences",
        css: "tab d-none d-md-inline-block"
      },
      {
        id: 3,
        path: "/shippingHistory",
        title: "Shipping History",
        css: "tab d-none d-md-inline-block"
      },
      {
        id: 4,
        path: "/addressBook",
        title: "Address Book",
        css: "tab d-none d-md-inline-block"
      },
      {
        id: 8,
        path: "/uspsConnect",
        title: "USPS Connect",
        css: "tab d-none d-md-inline-block"
      },
      {
        id: 5,
        path: "/shippingCart",
        title: "Shipping Cart",
        css: "tab last-tab d-none d-md-inline-block"
      },
      {
        id: 6,
        path: "/scanFormList",
        title: "SCAN Form",
        css: "scan-form tab last-tab d-none d-md-inline-block"
      },
      {
        id: 7,
        path: "/privacy-policy",
        title: "FAQs",
        css: "tab last-tab d-md-none"
      }
    ];

    let arrOfCnsLinksOver20 = [
      {
        id: 2,
        path: "/preferences",
        title: "Preferences",
        css: "tab d-none d-md-inline-block"
      },
      {
        id: 3,
        path: "/shippingHistory",
        title: "Shipping History",
        css: "tab d-none d-md-inline-block"
      },
      {
        id: 4,
        path: "/addressBook",
        title: "Address Book",
        css: "tab d-none d-md-inline-block"
      },
      {
        id: 8,
        path: "/uspsConnect",
        title: "USPS Connect",
        css: "tab d-none d-md-inline-block"
      },
      {
        id: 5,
        path: "/shippingCart",
        title: "Shipping Cart",
        css: "tab last-tab d-none d-md-inline-block"
      },
      {
        id: 6,
        path: "/scanFormList",
        title: "SCAN Form",
        css: "scan-form tab last-tab d-none d-md-inline-block"
      },
      {
        id: 7,
        path: "/privacy-policy",
        title: "FAQs",
        css: "tab last-tab d-md-none"
      }
    ];

    // Redirect to the shipping cart page if the cart is full and the user is
    // is on any of these URLs
    const urlsThatShouldRedirectToCart = [
      "/",
      "/index",
      "/index.shtml",
      "/labelInformation",
      "/preferences",
      "/shippingHistory",
      "/uspsConnect"
    ];
    if (
      this.props.cartCount >= 20 &&
      urlsThatShouldRedirectToCart.includes(window.location.pathname)
    ) {
      this.props.history.push("/shippingCart");
    }

    this.state = {
      listOfCnsLinks: arrOfCnsLinks,
      arrOfCnsLinksOver20: arrOfCnsLinksOver20
    };
  }

  render() {
    return (
      <div className="click-n-ship-main-header">
        {this.state.showSpinner ? (
          <div className="white-spinner-wrapper">
            <div className="white-spinner-container">
              <div className="spinner-content">
                <h5>Processing</h5>
                <div className="white-spinner-progress">
                  <span className="white-spinner">
                    <div className="spinner">
                      <div className="bar-1" />
                      <div className="bar-2" />
                      <div className="bar-3" />
                      <div className="bar-4" />
                      <div className="bar-5" />
                    </div>
                  </span>
                </div>
                <p>Optional Supporting Text</p>
              </div>
              <div className="gray-overlay" />
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 main-header">
              <CnsNavBarLinks
                listOfCnsLinks={
                  this.props.cartCount === 20
                    ? this.state.arrOfCnsLinksOver20
                    : this.state.listOfCnsLinks
                }
                manifestCount={this.props.manifestCount}
                cartCount={this.props.cartCount}
                userData={this.props.userData}
              />
              <a href="/" aria-label="Choose Experience">
                <h1>
                  Click-N-Ship<sup>&reg;</sup>
                </h1>
              </a>
              <div className="row">
                <div className="d-md-none col-sm-12 dropdown-selection container go-to-wrapper">
                  <div className="dropdown-selection">
                    <CnsNavResponsive
                      listOfCnsLinks={
                        this.props.cartCount === 20
                          ? this.state.arrOfCnsLinksOver20
                          : this.state.listOfCnsLinks
                      }
                      manifestCount={this.props.manifestCount}
                      cartCount={this.props.cartCount}
                      userData={this.props.userData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CnsNavBar);
