import React from "react";

const ProductWithServices = (props) => {
  const { product, orderItemPmtDetails, rowClassList } = props;

  return (
    <React.Fragment>
      {product && (
        <p className={rowClassList}>
          <span>{product.productClassDesc}</span>
        </p>
      )}
      {orderItemPmtDetails &&
        orderItemPmtDetails.map(
          (item) =>
            item.transactionDetailSubtype && (
              <p key={item.orderItemPmtDetailId} className={rowClassList}>
                <span>{item.transactionDetailSubtype}</span>
              </p>
            )
        )}
    </React.Fragment>
  );
};

export default ProductWithServices;
