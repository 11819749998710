import React from "react";
import { Link } from "react-router-dom";
import "../click-n-ship.css";
import "../bootstrap.min.css";
import "../calendar.css";
import "../default-styles.css";
import { withRouter } from "react-router-dom";

class CnsNavBarLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItemClicked: "Create a Label",
      style: "tab-link active"
    };
  }

  addRedBarToClicked = (item) => {
    this.setState({
      navItemClicked: item.title
    });
  };

  render() {
    return (
      <ul className="header-tabs">
        {this.props.listOfCnsLinks.map((item) => {
          // Set whether to display certain tabs
          let listClasses;
          if (item.title === "Shipping Cart" && this.props.manifestCount > 0) {
            // If labels are eligible for SCAN forms, this is not the last tab
            listClasses = item.css.replace("last-tab", "");
          } else if (
            item.title === "SCAN Form" &&
            !(this.props.manifestCount > 0)
          ) {
            // If labels are not eligible for SCAN forms, hide this tab
            listClasses = item.css.replace("d-md-inline-block", "");
          } else if (
            item.title === "USPS Connect" &&
            (this.props.userData.accountType !== "Business" ||
              !this.props.userData.parcelSelectFlag)
          ) {
            // If the customer is not a business user or is missing the
            // USPS Connect flag, hide this tab
            listClasses = item.css.replace("d-md-inline-block", "");
          } else {
            // Otherwise, leave the CSS alone
            listClasses = item.css;
          }
          // Determine the active link
          let linkClasses;
          let currentPagePath = this.props.history.location.pathname;
          if (item.path === currentPagePath) {
            // If the current navigation bar link is the path of the current
            // page, make this the active link
            linkClasses = this.state.style;
          } else if (
            item.path === "/labelInformation" &&
            (currentPagePath === "/" ||
              currentPagePath === "/customsInformation" ||
              currentPagePath === "/gxgLocationsDestinations" ||
              currentPagePath === "/customsInformationGxg" ||
              currentPagePath === "/gxgCommerceInformation" ||
              currentPagePath === "/gxgGuaranteedDate")
          ) {
            // If the current navigation bar link is "Create Label" and the
            // path of the current page matches any of the above, make this
            // the active link
            linkClasses = this.state.style;
          } else {
            // Otherwise, do not make this the active link
            linkClasses = "tab-link";
          }
          // Set the name of the link that will display
          let linkTitle = item.title;
          if (
            linkTitle === "Shipping Cart" &&
            this.props.cartCount !== undefined
          ) {
            linkTitle += " (" + this.props.cartCount + ")";
          }
          // Return the list of links to display in the navigation bar
          return (
            <li
              key={item.id}
              className={listClasses}
              onClick={(e) => this.addRedBarToClicked(item)}
            >
              {!linkClasses.includes("active") ? (
                <Link to={item.path} className={linkClasses}>
                  {linkTitle}
                </Link>
              ) : (
                <React.Fragment>
                  <span className="visuallyhidden">Current page</span>
                  <span className={linkClasses}>{linkTitle}</span>
                </React.Fragment>
              )}
            </li>
          );
        })}
      </ul>
    );
  }
}

export default withRouter(CnsNavBarLinks);
