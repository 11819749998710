import React from "react";

const NewV2Service = (props) => {
  const text = (
    <>
      <strong>USPS Label Delivery - New Service!</strong> Use the new
      Click-N-Ship experience to have labels delivered directly to the door of
      residential and business customers. USPS Label Delivery benefits consumers
      who have limited options to print or obtain a return or outbound label.
      Labels are available for Priority Mail Express, Priority Mail, First Class
      Package Service, Parcel Select and USPS Ground.
    </>
  );

  const button = (
    <a
      className="btn btn-primary"
      tabIndex="0"
      href={props.cnsbUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>Get Started Now</span>
    </a>
  );

  if (props.carouselStyling) {
    return (
      <>
        <div className="row">
          <div id="redirectToNewCnsText" className="col-12 col-md-6 col-lg-7">
            <p className="bannerCarouselText">{text}</p>
          </div>
          <div
            id="redirectToNewCnsLink"
            className="col-12 col-md-6 col-lg-5 text-center banner-button"
          >
            {button}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div
        id="redirect-to-new-cns-banner-wrapper"
        className="row container-fluid"
        style={{
          display: "flex",
          background: "#ededed",
          borderLeft: "15px solid #3573b1",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "20px",
          fontSize: "16px"
        }}
      >
        <div
          id="redirect-to-new-cns-banner-description-wrapper"
          className="col-12 col-md-7 col-lg-8"
        >
          <p
            id="redirect-to-new-cns-banner-description"
            style={{ color: "#333366" }}
          >
            {text}
          </p>
        </div>
        <div className="col-12 col-md-5 col-lg-4 text-center banner-button">
          {button}
        </div>
      </div>
    );
  }
};

export default NewV2Service;
