import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import update from "immutability-helper";
import DutiesTaxesPopover from "../Popovers/DutiesTaxesPopover";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";

class GXGDateAndFinalPrice extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      isLoaded: false,
      errors: {
        globalError: []
      }
    };
  }

  editLabel = (e) => {
    e.preventDefault();
    this.props.setEditCartId(
      this.state.fetchedGXGList.guaranteedDatePriceViewBean.cnsCartId
    );
    this.props.history.push("/labelInformation", {
      editItem: this.state.fetchedGXGList.guaranteedDatePriceViewBean.cnsCartId
    });
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: errorMessage
        }
      });
    }
  };
  componentDidMount() {
    this.props.toggleSpinner(true);
    // If there is no history, redirect the user to the create label page
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.cnsCartId
    ) {
      axios
        .get(
          "/go/cnsrest/fetchGxgGuaranteedDate?holdCartId=" +
            this.props.location.state.cnsCartId,
          {
            headers: { "Content-Type": "application/json" }
          }
        )
        .then((response) => {
          this.props.toggleSpinner(false);
          if (
            Object.keys(response.data.fieldErrors).length === 0 &&
            response.data.actionErrors.length === 0
          ) {
            this.setState({
              focusOnTop: true,
              isLoaded: true,
              fetchedGXGList: response.data
            });
          } else {
            window.scrollTo(0, 0);
            this.setGlobalError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.toggleSpinner(false);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        });
    } else {
      window.location.replace("/labelInformation");
    }
  }

  componentDidUpdate() {
    // Focus on the top element on the page after loading
    if (this.state.focusOnTop && this.state.isLoaded) {
      document.getElementById("gxg-step-10-top").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
  }

  goToCart = (e) => {
    e.preventDefault();
    this.props.history.push("/shippingCart");
  };

  render() {
    return (
      <React.Fragment>
        <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        />
        {this.state.isLoaded ? (
          <div
            id="gxg-step-10-top"
            className="Step_Ten_Container Global_Express_Container"
            tabIndex="-1"
          >
            <div className="container-fluid">
              <h2 className="d-md-none responsive-header">Create a Label</h2>
              <h2 className="normal">
                Step 10:{" "}
                <strong>
                  Global Express Guaranteed<sup>®</sup> Date and Final Price
                </strong>
              </h2>

              <div className="row">
                <div
                  id="global-express-form-id"
                  className="col-12 gray-box global-express-form"
                >
                  <div className="gray-box-wrapper global-express-form-content">
                    <div className="global-express-form-container">
                      {this.state.fetchedGXGList.guaranteedDatePriceViewBean
                        .deliveryDate !== "N/A" ? (
                        <React.Fragment>
                          <div className="row">
                            <div className="col-12">
                              <p>
                                To ensure the following delivery date, ship
                                from:
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 global-express-guaranted-address list-items">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: this.state.fetchedGXGList
                                    .guaranteedDatePriceViewBean.location
                                }}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment />
                      )}
                      <div className="row">
                        <div className="col-12">
                          <label className="global-express-details">
                            <strong>Guaranteed Delivery Date</strong>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className="delivery-date">Delivery on</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 delivery-date">
                          <p>
                            {
                              this.state.fetchedGXGList
                                .guaranteedDatePriceViewBean.deliveryDate
                            }
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <label className="global-express-details final-price">
                            <strong> Final Post Office Price</strong>
                          </label>
                          <div className="global-express-service-type-price-container-details">
                            <table className="col-12 col-md-4 global-express-service-type-price">
                              <tbody>
                                <tr className="post-office-price-header-wrapper">
                                  <th className="service-type-header">
                                    Service Type
                                  </th>
                                  <th className="post-office-price-header">
                                    USPS Price
                                  </th>
                                </tr>
                                <tr
                                  className="global-express-service-type-price-container"
                                  valign="top"
                                >
                                  <td className="service-type-item">
                                    <p>Postage</p>
                                    <p>Insurance</p>
                                  </td>
                                  <td className="service-price">
                                    <p>
                                      {
                                        this.state.fetchedGXGList
                                          .guaranteedDatePriceViewBean
                                          .postageAmount
                                      }
                                    </p>
                                    <p>
                                      {this.state.fetchedGXGList
                                        .guaranteedDatePriceViewBean
                                        .insuranceAmount === "Free"
                                        ? "Free"
                                        : this.state.fetchedGXGList
                                            .guaranteedDatePriceViewBean
                                            .insuranceAmount}
                                    </p>
                                  </td>
                                </tr>
                                <tr
                                  className="total-price-container"
                                  valign="top"
                                >
                                  <td className="total-txt">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="usps-total-price-service-type">
                                    <strong>
                                      {
                                        this.state.fetchedGXGList
                                          .guaranteedDatePriceViewBean
                                          .totalAmount
                                      }
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          id="duties-and-taxes"
                          className="col-12 service-type-duties-tax"
                        >
                          <p>
                            Duties and Taxes{" "}
                            <DutiesTaxesPopover targetName="duties-taxes-popover-id" />
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <label className="global-express-details">
                            <strong>Shipping Label Information</strong>
                          </label>
                        </div>
                      </div>
                      <div className="row mailer-address-wrapper">
                        <div className="col-12 col-md-4 global-express-service-mailer-container">
                          <p className="mailer-address-header">
                            Mailers's Address
                          </p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.fetchedGXGList
                                .guaranteedDatePriceViewBean.shipFrom
                                .displayAddress
                            }}
                          />
                        </div>
                        <div className="col-12 col-md-4 global-express-service-mailer-container">
                          <p className="ship-header">Ship To</p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.fetchedGXGList
                                .guaranteedDatePriceViewBean.shipTo
                                .displayAddress
                            }}
                          />
                        </div>
                        <div className="col-12 col-md-4 global-express-service-mailer-container">
                          <p className="package-information-header">
                            Package Information
                          </p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.fetchedGXGList
                                .guaranteedDatePriceViewBean.packageInformation
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 global-express-mailer-header">
                          <p className="holidays">
                            Holidays or Non-Business Days
                          </p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.fetchedGXGList
                                .guaranteedDatePriceViewBean.holidays
                            }}
                          />
                        </div>
                      </div>
                      <p className="alert-message gxg-suspension-message">
                        The Global Express Guaranteed<sup>&reg;</sup> money-back
                        guarantee for packages to{" "}
                        {
                          this.state.fetchedGXGList.guaranteedDatePriceViewBean
                            .shipTo.countryName
                        }{" "}
                        is currently suspended.
                      </p>
                      <div className="row">
                        <div className="col-12 button-wrapper step10-buttons">
                          <div className="button-container go-cart-button">
                            <a
                              href="/shippingCart"
                              role="button"
                              className="btn-primary"
                              onClick={this.goToCart}
                            >
                              Go to Cart
                            </a>
                          </div>
                          <div className="button-container">
                            <a
                              href="/labelInformation"
                              role="button"
                              className="btn-primary button--white"
                              onClick={this.editLabel}
                            >
                              Choose Another Service
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(GXGDateAndFinalPrice);
