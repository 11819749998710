import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const DutiesTaxesPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        All items entering a foreign country are subject to customs inspection
        and the assessment of duties and taxes in accordance with that country's
        national laws. Customs duties and taxes are assessed, generally, if the
        merchandise is dutiable and the value of the item is above the threshold
        set by the country's laws. If duties and taxes are assessed on an item
        mailed via Global Express Guaranteed® service, they will be collected
        from the recipient prior to, or upon, delivery. In order to complete
        clearance of certain items through customs, we may be required to pay in
        advance on certain duties and taxes as assessed by customs officials. We
        may contact the recipient before clearance is complete to confirm the
        arrangements for reimbursement. In addition, for some countries, there
        may be an administrative fee that is collected from the recipient to
        cover the costs of clearing the item through customs and collecting
        duties and taxes. Shipments may be delayed if we are not able to reach
        the recipient to confirm that we will be reimbursed for any amounts
        advanced, and the Money-Back Delivery Guarantee will not apply in these
        cases. If a recipient refuses to pay the duties and taxes, we may
        contact the sender to make alternative arrangements.
        <br />
        Recipient's payment for duties and taxes will be made by one of the
        following means at the sole discretion of FedEx Express: cash, check
        (personal or business, provided valid identification is offered), money
        order, traveler's check, or as locally required in the destination
        country. Payment of duties and taxes may not be made by credit card.
        <br />
        Access web site <a href="http://ita.doc.gov">http://ita.doc.gov</a> for
        an estimate of duties and taxes that, if levied against a shipment, the
        recipient will be required to pay prior to delivery.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Duties And Taxes Information Icon"
      header="Duties & Taxes"
      content={content}
    />
  );
};

export default DutiesTaxesPopover;
