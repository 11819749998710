// These color ramps were obtained from
// https://developers.arcgis.com/javascript/latest/visualization/symbols-color-ramps/esri-color-ramps/

const pointCloud1 = ["#5813fc", "#1cc2fd", "#7dfd94", "#f5c926", "#ff2b18"];

const vibrantRainbow = [
  "#32b8a6",
  "#f5cb11",
  "#eb7200",
  "#c461eb",
  "#6c7000",
  "#bf2e2e",
  "#46e39c",
  "#9fd40c",
  "#ad00f2",
  "#fffb00",
  "#7ff2fa",
  "#e8a784"
];

export { pointCloud1, vibrantRainbow };
