import React, { useState } from "react";
import { Modal } from "reactstrap";

const TermsConditionsModal = (props) => {
  const [modal, setModal] = useState(false);

  const { targetName } = props;

  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="inline-link button-link"
        id={targetName}
        onClick={toggle}
      >
        Terms &amp; Conditions
      </button>
      <Modal isOpen={modal} toggle={toggle}>
        <div className="modal-content modal-container">
          <div className="modal-header">
            <button
              type="button"
              className="close button-link"
              data-dismiss="modal"
              tabIndex="0"
              onClick={toggle}
            >
              <span className="visuallyhidden">Close Modal</span>
            </button>
            <h3 className="modal-title">Terms & Conditions</h3>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <div className="scrollInterior">
                <p>
                  Insurance Coverage: Insurance is provided only in accordance
                  with postal regulations in the Domestic Mail Manual (DMM) and,
                  for international shipments, the International Mail Manual
                  (IMM). The DMM&reg; and IMM set forth the specific types of
                  losses that are covered, the limitations on coverage, terms of
                  insurance, conditions of payment, and adjudication procedures.
                  Copies of the DMM and IMM are available for inspection at any
                  Post Office&reg;. If copies are not available and information
                  on Priority Mail Express International&trade; insurance is
                  requested, please contact postmaster prior to mailing. The DMM
                  and the IMM consist of federal regulations, and USPS personnel
                  are NOT authorized to change or waive these regulations or
                  grant exceptions.
                </p>
                <p>
                  <strong>
                    Limitations prescribed in the DMM and IMM provide, in part,
                    that:
                  </strong>
                </p>
                <ul>
                  <li>
                    The contents of Priority Mail Express International&trade;
                    shipments defined as merchandise by postal regulations are
                    insured against loss, damage, or missing contents. Coverage
                    of up to $200.00 per shipment for merchandise, or $100 for
                    items containing only documents is included at no additional
                    charge. Online insurance may NOT be combined with insurance
                    purchased at the Post Office. There is a limit of $5000.00
                    insurance that can be purchased at a Post Office. Please
                    note: additional insurance is void if waiver of the
                    addressee's signature is requested.
                  </li>
                  <li>
                    Coverage extends to the actual value of the contents at the
                    time of mailing (including depreciation based on the life
                    expectancy of the contents), the repair cost, or the limit
                    of insurance coverage purchased, whichever is least.
                  </li>
                  <li>
                    For Priority Mail Express International&trade;, insurance
                    coverage may vary by country and may not be available to
                    some countries. There is no indemnity for items containing
                    coins, banknotes, currency notes (paper money); securities
                    of any kind payable to the bearer; traveler's checks,
                    platinum, gold and silver (manufactured or not); precious
                    stones, jewelry, and other valuable or prohibited articles.
                  </li>
                  <li>
                    Items defined by postal indemnity regulations as
                    nonnegotiable documents are insured against loss, damage, or
                    missing contents up to $100 per shipment for document
                    reconstruction, subject to additional limitations for
                    multiple pieces lost or damaged in a single catastrophic
                    occurrence.
                  </li>
                  <li>
                    Document reconstruction insurance provides reimbursement for
                    the reasonable costs incurred in reconstructing duplicates
                    of nonnegotiable documents mailed. Document reconstruction
                    insurance coverage above $100 per shipment is NOT available,
                    and attempts to purchase additional document insurance are
                    void.
                  </li>
                </ul>
                <p>
                  No coverage is provided for consequential losses due to loss,
                  damage, or delay of Priority Mail Express
                  International&trade;, or for concealed damage, spoilage of
                  perishable items, and articles improperly packaged or too
                  fragile to withstand normal handling in the mail.
                </p>
                <p>COVERAGE, TERMS, AND LIMITATIONS ARE SUBJECT TO CHANGE.</p>
                <p>
                  Please consult the Domestic Mail Manual (DMM) and the
                  International Mail Manual (IMM) for additional limitations and
                  terms of coverage. View the DMM and IMM online at pe.usps.com.
                </p>
                <p>
                  <strong>Claims:</strong> An inquiry to the Priority Mail
                  Express&reg; Dallas Call Center must be made prior to making a
                  claim. Please call 1-800-222-1811 for assistance.
                </p>
                <p>
                  <strong>
                    THANK YOU FOR CHOOSING PRIORITY MAIL EXPRESS
                    INTERNATIONAL&trade;
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default TermsConditionsModal;
