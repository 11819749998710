import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const FreeSuppliesPriorityMailPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Shipment cost is calculated at ${props.priorityPrice} per Priority Mail
        package
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Priority Mail shipping cost"
      header=""
      content={content}
    />
  );
};

export default FreeSuppliesPriorityMailPopover;
