import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import axios from "axios";

// CSS
import "../../../click-n-ship.css";
import "../../../bootstrap.min.css";
import "../../../default-styles.css";

// Other components
import PostOfficeInfo from "./PostOfficeInfo";

// utils
import * as cimSymbols from "../../../utils/esri/cimSymbols";
import * as colorRamps from "../../../utils/esri/colorRamps";

// ArcGIS
import "./UspsConnectMap.css";
import esriConfig from "@arcgis/core/config";
import "@arcgis/core/assets/esri/themes/light/main.css";
import ArcgisMap from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Extent from "@arcgis/core/geometry/Extent";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import RouteParameters from "@arcgis/core/rest/support/RouteParameters";
import FeatureSet from "@arcgis/core/rest/support/FeatureSet";
import * as route from "@arcgis/core/rest/route";

const UspsConnectMap = (props) => {
  const {
    // Required only when fetching all drop-off facilities within a radius
    // (i.e. the preferences and USPS Connect pages)
    fetchDropOffLocations,
    setFetchDropOffLocations,
    shipFromAddress,
    setGlobalError,
    // Required only on the USPS Connect page
    separateAddress,
    setFacilityInfo,
    // Required only when a single drop-off facility is to be shown
    // and whose info has already been obtained
    // (i.e. in step 5 on the create label page)
    selectedDropOffLocationData,
    setTotalDriveDistance,
    setAverageDriveTime,
    // Always required
    mapSource,
    radius,
    lookupCodes
  } = props;

  if (lookupCodes["esri_key"] && lookupCodes["esri_key"].trim()) {
    esriConfig.apiKey = lookupCodes["esri_key"];
  }

  let map = new ArcgisMap({
    basemap: "streets"
  });

  let mapDivRef = useRef(null);
  let view = useRef(null);

  let shipFromAddressPointLayer = new GraphicsLayer({
    id: "shipFromAddressPointLayer"
  });

  let separateAddressPointLayer = new GraphicsLayer({
    id: "separateAddressPointLayer"
  });

  let dropOffLocationsPointLayer = new GraphicsLayer({
    id: "dropOffLocationsPointLayer"
  });

  const [dropOffLocationsError, setDropOffLocationsError] = useState(false);

  // Flag that indicates if a previous axios call is still executing.
  // This is used to ensure that only one call to fetch drop-off locations
  // and perform the necessary effects afterward can happen at a time
  const [
    isExecutingPreviousAxiosCall,
    setIsExecutingPreviousAxiosCall
  ] = useState(false);

  useEffect(() => {
    displayMap();

    // When viewing only one facility (i.e step 5 of the label creation flow),
    // this facility will be passed in as a prop, and its data will be used
    // to populate the map.
    //
    // This only needs to be done when the component is mounted.
    if (mapSource === "createLabel" && selectedDropOffLocationData) {
      displayMapData(JSON.parse(JSON.stringify(selectedDropOffLocationData)));
    }
  }, []);

  // Populate the map using facility info from the drop-off locations API
  // (applies to the preferences and USPS Connect pages)
  useEffect(() => {
    if (
      (mapSource === "preferences" || mapSource === "uspsConnect") &&
      fetchDropOffLocations &&
      !isExecutingPreviousAxiosCall
    ) {
      // Reset flags/data, and remove pins/colors from the map
      setFetchDropOffLocations(false);
      setIsExecutingPreviousAxiosCall(true);
      setDropOffLocationsError(false);
      if (mapSource === "uspsConnect") {
        setFacilityInfo([]);
      }
      displayMap();
      // If no radius was provided, do not fetch drop-off locations
      if (!radius) {
        setIsExecutingPreviousAxiosCall(false);
        return;
      }
      // Fetch drop-off locations
      axios
        .post(
          "/go/cnsrest/fetchDropOffLocations",
          JSON.stringify({
            ...shipFromAddress,
            ...separateAddress,
            radius,
            mapSource
          }),
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.dropoffLocationData &&
            response.data.dropoffLocationData.successful &&
            response.data.dropoffLocationData.locationList &&
            Array.isArray(response.data.dropoffLocationData.locationList)
          ) {
            displayMapData(response.data.dropoffLocationData);
          } else {
            setIsExecutingPreviousAxiosCall(false);
            setDropOffLocationsError(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsExecutingPreviousAxiosCall(false);
          setDropOffLocationsError(true);
        });
    }
  }, [
    fetchDropOffLocations,
    isExecutingPreviousAxiosCall,
    setFetchDropOffLocations,
    shipFromAddress,
    separateAddress,
    radius,
    mapSource,
    setGlobalError
  ]);

  // Focus on the drop-off locations API error,
  // if present and on the USPS Connect page
  useEffect(() => {
    if (dropOffLocationsError && mapSource === "uspsConnect") {
      setFacilityInfo([]);
      document.getElementById("dropOffLocationsErrorId").focus();
    }
  }, [dropOffLocationsError, mapSource]);

  // Draw the map
  const displayMap = () => {
    let mapView = {};
    // The map object
    mapView.map = map;
    // The <div> element that is to contain the map
    mapView.container = mapDivRef && mapDivRef.current;
    // Constraints
    mapView.constraints = {
      snapToZoom: false
    };
    if (!radius || !view.current || !view.current.center) {
      // If no radius (or on the initial map load),
      // then drop-off locations are not being fetched,
      // and the entire United States should be displayed
      mapView.extent = new Extent({
        // The X and Y coordinates of the map's bounding box
        // (initialized to the U.S. boundaries)
        xmin: -15670101.409658078,
        xmax: -6277519.373978118,
        ymin: 3119053.269508996,
        ymax: 6563000.015924982,
        // The projected or geographic coordinate system used
        spatialReference: {
          wkid: 102100
        }
      });
    } else {
      // If there's a radius, this means drop-off locations are being fetched,
      // and the center and scale should be set to however the map was oriented
      // previously
      mapView.center = new Point({
        latitude: view.current.center.latitude,
        longitude: view.current.center.longitude
      });
      mapView.scale = view.current.scale;
    }
    // Create the MapView.
    // The end result is that any previous layers for pins or color coding
    // will be removed in the new MapView, and the map will be oriented based
    // on the criteria above
    view.current = new MapView(mapView);
  };

  // Format the map data and draw all map layers
  const displayMapData = (dropoffLocationData) => {
    // Sort the drop-off facilities
    dropoffLocationData.locationList = sortDropOffLocations(
      dropoffLocationData.locationList
    );
    view.current.when(
      () => {
        // Center and zoom the map
        centerScaleMap(dropoffLocationData);
        // "ship from" pin
        plotShipFromAddressPoint(dropoffLocationData);
        // "separate" address pin
        plotSeparateAddressPoint(dropoffLocationData);
        // Drop-off location pins
        plotDropOffLocationsPoints(dropoffLocationData.locationList);
        // ZIP code color coding
        // (only applies on the preferences and USPS Connect pages)
        plotZipCodeBoundariesLayer(dropoffLocationData);
      },
      (error) => {
        console.log(error);
        setIsExecutingPreviousAxiosCall(false);
        setDropOffLocationsError(true);
      }
    );
  };

  // Display the "separate" address if its distance falls
  // within the radius specified
  // (only applies on the USPS Connect page)
  const displaySeparateAddress = (separateAddressGeoDataDistance) => {
    return (
      mapSource === "uspsConnect" &&
      Number(separateAddressGeoDataDistance) > -1 &&
      Number(separateAddressGeoDataDistance) <= Number(radius)
    );
  };

  // Sort the drop-off facilities by distance, starting with the closest
  const sortDropOffLocations = (locationList) => {
    locationList.sort((a, b) => {
      const distanceA = Number(a.dropOffLocationGeoDataDistance);
      const distanceB = Number(b.dropOffLocationGeoDataDistance);
      if (isNaN(distanceA) || distanceA < 0) {
        return 1;
      }
      if (isNaN(distanceB) || distanceB < 0) {
        return -1;
      }
      return distanceA - distanceB;
    });
    return locationList;
  };

  // Center and zoom the map appropriately
  const centerScaleMap = (dropoffLocationData) => {
    if (
      dropoffLocationData.shipFromAddressGeoDataLatitude &&
      dropoffLocationData.shipFromAddressGeoDataLongitude
    ) {
      // Center the map on the "ship from" address
      view.current.center = new Point({
        latitude: dropoffLocationData.shipFromAddressGeoDataLatitude,
        longitude: dropoffLocationData.shipFromAddressGeoDataLongitude
      });
      // Scale the map to zoom appropriately but still show all drop-off locations:
      // Convert map dimensions to miles
      const smallerDimension = Math.min(
        view.current.width,
        view.current.height
      );
      const mapRadiusInPixels = smallerDimension / 2;
      const mapRadiusInMm = mapRadiusInPixels * 0.26;
      const mapRadiusInKm = mapRadiusInMm * (1 / 1000000);
      const mapRadiusInMiles = mapRadiusInKm * 0.62;
      let maxRadiusInLocation;
      if (dropoffLocationData.locationList.length > 0) {
        // Get the largest distance from the entered address
        maxRadiusInLocation = 0;
        dropoffLocationData.locationList.forEach((location) => {
          maxRadiusInLocation = Math.max(
            maxRadiusInLocation,
            location.dropOffLocationGeoDataDistance
          );
        });
        // If the "separate" address falls within the radius searched, compare it
        // to the max distance obtained from the drop-off locations
        // (applies only on the USPS Connect page)
        if (
          displaySeparateAddress(
            dropoffLocationData.separateAddressGeoDataDistance
          )
        ) {
          maxRadiusInLocation = Math.max(
            maxRadiusInLocation,
            dropoffLocationData.separateAddressGeoDataDistance
          );
        }
        // In case no valid distances were returned from WebTools/Esri for any
        // reason, default back to the radius entered by the user
        if (!(maxRadiusInLocation > 0)) {
          maxRadiusInLocation = Number(radius);
        }
      } else {
        // If there were no drop-off locations found, zoom the map based on the
        // radius that was entered by the user
        maxRadiusInLocation = Number(radius);
      }
      // Set the scale / zoom level.
      // The multiplier adds a little padding to ensure all pins can fit
      // on the map.
      view.current.scale = (1 / (mapRadiusInMiles / maxRadiusInLocation)) * 2;
    }
  };

  // Draw the points layer for what is being treated as the "ship from" address
  // (create label page - the preferred/eReg address)
  // (preferences page - the preferred/eReg address)
  // (USPS Connect page - the searched address)
  const plotShipFromAddressPoint = (dropoffLocationData) => {
    if (
      dropoffLocationData.shipFromAddressGeoDataLatitude &&
      dropoffLocationData.shipFromAddressGeoDataLongitude
    ) {
      const pinGraphic = new Graphic({
        geometry: new Point({
          latitude: dropoffLocationData.shipFromAddressGeoDataLatitude,
          longitude: dropoffLocationData.shipFromAddressGeoDataLongitude
        }),
        symbol:
          mapSource === "uspsConnect" ? cimSymbols.pushPin2 : cimSymbols.house
      });
      shipFromAddressPointLayer.add(pinGraphic);
    }
  };

  // Draw the points layer for the "separate" address
  // (this applies only on the USPS Connect page and
  // corresponds to the user's eReg/preferred address)
  const plotSeparateAddressPoint = (dropoffLocationData) => {
    if (
      displaySeparateAddress(
        dropoffLocationData.separateAddressGeoDataDistance
      ) &&
      dropoffLocationData.separateAddressGeoDataLatitude &&
      dropoffLocationData.separateAddressGeoDataLongitude
    ) {
      const pinGraphic = new Graphic({
        geometry: new Point({
          latitude: dropoffLocationData.separateAddressGeoDataLatitude,
          longitude: dropoffLocationData.separateAddressGeoDataLongitude
        }),
        symbol: cimSymbols.house
      });
      separateAddressPointLayer.add(pinGraphic);
    }
  };

  // Draw the points layer for the drop-off locations
  // with the opening/closing days and times formatted properly
  const plotDropOffLocationsPoints = (dropOffLocations) => {
    for (let i = 0; i < dropOffLocations.length; i++) {
      // Format days and times
      let formattedDaysHours = JSON.parse(
        JSON.stringify(dropOffLocations[i].daysHours)
      );
      for (let i in formattedDaysHours) {
        formattedDaysHours[i].day = formatDay(formattedDaysHours[i].day);
        formattedDaysHours[i].times = formatTimes(formattedDaysHours[i].times);
      }
      // Group days with the same times together.
      // Weekends should be displayed in their own group even if they
      // share times with weekdays (hence the separate call to
      // getFormattedDayHourGroups()).
      const formattedDayHourGroupsWeekdays = getFormattedDayHourGroups(
        formattedDaysHours.slice(0, 5)
      );
      const formattedDayHourGroupsWeekends = getFormattedDayHourGroups(
        formattedDaysHours.slice(5)
      );
      dropOffLocations[
        i
      ].formattedDayHourGroups = formattedDayHourGroupsWeekdays.concat(
        formattedDayHourGroupsWeekends
      );
      // Add a pin (with its associated popup) for the drop-off facility
      if (
        dropOffLocations[i].dropOffLocationGeoDataLatitude &&
        dropOffLocations[i].dropOffLocationGeoDataLongitude
      ) {
        const popupDiv = document.createElement("div");
        const pinGraphic = new Graphic({
          geometry: new Point({
            latitude: dropOffLocations[i].dropOffLocationGeoDataLatitude,
            longitude: dropOffLocations[i].dropOffLocationGeoDataLongitude
          }),
          symbol: cimSymbols.tearPin2,
          popupTemplate: {
            outFields: ["*"],
            content: popupDiv
          },

          location: dropOffLocations[i]
        });
        dropOffLocationsPointLayer.add(pinGraphic);
        ReactDOM.render(
          <PostOfficeInfo
            facility={dropOffLocations[i]}
            isDesktopAndTableFormat={false}
            isTableFormat={false}
          />,
          popupDiv
        );
      }
    }

    // Store all facility info to use elsewhere
    // (only applies on the USPS Connect page)
    if (mapSource === "uspsConnect") {
      setFacilityInfo(dropOffLocations);
    }

    view.current.on("pointer-move", (event) => {
      const opts = {
        include: dropOffLocationsPointLayer
      };

      view.current.hitTest(event, opts).then((response) => {
        if (response.results.length) {
          var graphic = response.results.filter(function (result) {
            // check if the graphic belongs to the layer of interest
            return result.graphic.layer;
          })[0].graphic;
          view.current.popup.open({
            location: {
              latitude: graphic.geometry.latitude,
              longitude: graphic.geometry.longitude
            }, //graphic.geometry.centroid,
            features: [graphic]
          });
          view.current.popup.focus();
        } else {
          view.current.popup.close();
        }
      });
    });
  };

  // Draw the ZIP code boundary layers, which color-code the ZIP codes
  // that are serviceable for each drop-off facility
  const plotZipCodeBoundariesLayer = (dropoffLocationData) => {
    let zipCodeBoundaryLayers = [];
    const locationList = dropoffLocationData.locationList;
    for (let i = 0; i < locationList.length; i++) {
      // Call Esri to get the data needed to set color boundaries
      // (each ZIP must be surrounded by single quotes)
      let formattedZipsServed = [];
      locationList[i].zipsServed.forEach((zipServed) => {
        const formattedZipServed = "'" + zipServed + "'";
        formattedZipsServed.push(formattedZipServed);
      });
      const zipCodeBoundariesUrl =
        lookupCodes["esri_ZIP_code_boundaries"] +
        "/1/query?where=" +
        encodeURIComponent("ZIP in (" + formattedZipsServed.join(",") + ")") +
        "&f=pjson";
      axios
        .get(zipCodeBoundariesUrl, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          let zipCodeGraphics = [];
          if (
            response &&
            response.data &&
            response.data.features &&
            Array.isArray(response.data.features)
          ) {
            response.data.features.forEach((feature) => {
              if (feature.geometry && feature.geometry.rings) {
                zipCodeGraphics.push(
                  new Graphic({
                    geometry: new Polygon({
                      rings: feature.geometry.rings,
                      spatialReference: {
                        wkid:
                          (response.data.spatialReference &&
                            response.data.spatialReference.wkid) ||
                          102100
                      }
                    })
                  })
                );
              }
            });
          }
          zipCodeBoundaryLayers.push(
            new FeatureLayer({
              source: zipCodeGraphics,
              objectIdField:
                "zipCodeBoundaryLayer-" + locationList[i].locationName,
              geometryType: "polygon",
              renderer: {
                type: "simple",
                symbol: {
                  type: "simple-fill",
                  color:
                    colorRamps.vibrantRainbow[
                      i % colorRamps.vibrantRainbow.length
                    ]
                }
              }
            })
          );
        })
        .catch(() => {
          zipCodeBoundaryLayers.push(
            new FeatureLayer({
              source: [],
              objectIdField:
                "zipCodeBoundaryLayer-" + locationList[i].locationName,
              geometryType: "polygon"
            })
          );
        })
        .finally(() => {
          // After all ZIP code boundaries have been obtained,
          // add the corresponding layers to the map,
          // then add the pins and drive time/distance
          if (
            dropoffLocationData.locationList.length ===
            zipCodeBoundaryLayers.length
          ) {
            zipCodeBoundaryLayers.forEach((layer) => {
              map.layers.add(layer);
            });
            map.layers.add(shipFromAddressPointLayer);
            map.layers.add(separateAddressPointLayer);
            map.layers.add(dropOffLocationsPointLayer);
            view.current.map = map;
            // Drive time and distance (only applies on the create label page)
            if (mapSource === "createLabel") {
              calculateDriveTimeAndDistance();
            }
            setIsExecutingPreviousAxiosCall(false);
          }
        });
    }
  };

  // Determine the actual driving distance and average drive time.
  // This is only needed when a SECOND drop-off facility is to be shown
  // and whose info has already been obtained
  // (i.e. in step 5 on the create label page).
  const calculateDriveTimeAndDistanceForSecondAddress = () => {
    const averageDriveUrl = lookupCodes["esri_drive_time_distance"] + "?f=json";
    // LOCAL
    // const averageDriveUrl =
    //   "https://gis1-prod.usps.gov/arcgis/rest/services/routeTask/NAServer/Route_World?f=json";
    // SIT
    // "https://utility.arcgis.com/usrsvcs/servers/270fb9968379481eb380d8e3b1407789/rest/services/World/Route/NAServer/Route_World?f=json";
    const originDestinationGraphicsArray = [];
    originDestinationGraphicsArray.push(
      map.findLayerById("shipFromAddressPointLayer").graphics.items[0]
    );
    originDestinationGraphicsArray.push(
      map.findLayerById("dropOffLocationsPointLayer").graphics.items[1]
    );

    const routeParams = new RouteParameters({
      returnDirections: false,
      stops: new FeatureSet({
        features: originDestinationGraphicsArray
      })
    });
    route
      .solve(averageDriveUrl, routeParams)
      .then(function (data) {
        if (
          data &&
          data.routeResults &&
          data.routeResults[0] &&
          data.routeResults[0].route &&
          data.routeResults[0].route.attributes
        ) {
          const attributes = data.routeResults[0].route.attributes;
          setTotalDriveDistance(Math.round(attributes.Total_Miles));
          setAverageDriveTime(Math.round(attributes.Total_TravelTime));
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Determine the actual driving distance and average drive time.
  // This is only needed when a single drop-off facility is to be shown
  // and whose info has already been obtained
  // (i.e. in step 5 on the create label page).
  const calculateDriveTimeAndDistance = () => {
    const averageDriveUrl = lookupCodes["esri_drive_time_distance"] + "?f=json";
    // LOCAL
    // const averageDriveUrl =
    //   "https://gis1-prod.usps.gov/arcgis/rest/services/routeTask/NAServer/Route_World?f=json";
    // SIT
    // "https://utility.arcgis.com/usrsvcs/servers/270fb9968379481eb380d8e3b1407789/rest/services/World/Route/NAServer/Route_World?f=json";
    const originDestinationGraphicsArray = [];
    originDestinationGraphicsArray.push(
      map.findLayerById("shipFromAddressPointLayer").graphics.items[0]
    );
    originDestinationGraphicsArray.push(
      map.findLayerById("dropOffLocationsPointLayer").graphics.items[0]
    );

    const routeParams = new RouteParameters({
      returnDirections: false,
      stops: new FeatureSet({
        features: originDestinationGraphicsArray
      })
    });
    route
      .solve(averageDriveUrl, routeParams)
      .then(function (data) {
        if (
          data &&
          data.routeResults &&
          data.routeResults[0] &&
          data.routeResults[0].route &&
          data.routeResults[0].route.attributes
        ) {
          const attributes = data.routeResults[0].route.attributes;
          setTotalDriveDistance(Math.round(attributes.Total_Miles));
          setAverageDriveTime(Math.round(attributes.Total_TravelTime));

          if (
            map.findLayerById("dropOffLocationsPointLayer").graphics.items[1]
          ) {
            calculateDriveTimeAndDistanceForSecondAddress();
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Format days in the way they will be displayed to the user
  const formatDay = (day) => {
    return day === "MO"
      ? "Mon"
      : day === "TU"
      ? "Tue"
      : day === "WE"
      ? "Wed"
      : day === "TH"
      ? "Thu"
      : day === "FR"
      ? "Fri"
      : day === "SA"
      ? "Sat"
      : day === "SU"
      ? "Sun"
      : "";
  };

  // Format times in the way they will be displayed to the user
  const formatTimes = (times) => {
    if (times && Array.isArray(times) && times.length > 0) {
      for (let i in times) {
        const openingTime = getFormattedTime(times[i].open);
        const closingTime = getFormattedTime(times[i].close);
        if (openingTime && closingTime) {
          times[i] = openingTime + "-" + closingTime;
        } else {
          if (openingTime) {
            times[i] = "Opens: " + openingTime;
          } else {
            times[i] = "Closes: " + closingTime;
          }
        }
      }
      return times;
    } else {
      return ["Closed"];
    }
  };

  // Returns a time formatted as hours:minutes with am/pm
  const getFormattedTime = (time) => {
    if (time) {
      // Split the time into hours (index 0), minutes (index 1), seconds (index 2)
      const timeAsArray = time.split(":");
      let formattedHours =
        timeAsArray[0] > 12
          ? String(timeAsArray[0] - 12)
          : // String -> Number -> String is done to remove leading zeros
            String(Number(timeAsArray[0]));
      if (formattedHours === "0") {
        formattedHours = "12";
      }
      const formattedMinutes = timeAsArray[1];
      return (
        formattedHours +
        ":" +
        formattedMinutes +
        " " +
        (timeAsArray[0] < 12 ? "am" : "pm")
      );
    } else {
      return null;
    }
  };

  // Group the days passed in to this function that share the same
  // opening / closing times
  const getFormattedDayHourGroups = (formattedDaysHours) => {
    let formattedDayHourGroups = [];
    let last;
    // first = the first index of a group (after an iteration through this
    //   outer loop, this is incremented to "last", which was the first index
    //   that was part of a different group in the previous iteration)
    for (let first = 0; first < formattedDaysHours.length; first = last) {
      const times1 = formattedDaysHours[first].times;
      let endOfGroupReached = false;
      // last = the index used to search for the last index of a group
      //   (when it is determined that a different group has been started,
      //   the last index of the previous group is last - 1)
      for (last = first + 1; last < formattedDaysHours.length; last++) {
        const times2 = formattedDaysHours[last].times;
        // The end of a group has been reached if the first and last index have
        // a different number of total opening/closing times or different
        // individual opening/closing times
        if (times1.length === times2.length) {
          for (let i = 0; i < times1.length; i++) {
            if (times1[i] !== times2[i]) {
              endOfGroupReached = true;
              break;
            }
          }
        } else {
          endOfGroupReached = true;
        }
        if (endOfGroupReached) {
          break;
        }
      }
      // Once the first and last positions of a group have been found, the
      // starting/ending day range (or the only day, if that day's
      // times are unique) and times are saved for the group
      formattedDayHourGroups.push({
        days:
          first === last - 1
            ? formattedDaysHours[first].day
            : formattedDaysHours[first].day +
              "-" +
              formattedDaysHours[last - 1].day,
        times: times1
      });
    }
    return formattedDayHourGroups;
  };

  return (
    <React.Fragment>
      {dropOffLocationsError && (
        <div>
          <p
            id="dropOffLocationsErrorId"
            className="error-message"
            tabIndex="-1"
          >
            No locations currently available.
          </p>
        </div>
      )}
      <div
        id="connectMapViewDivId"
        className={
          "esri-widget row col-9 col-md-9 col-lg-9" +
          (dropOffLocationsError ? " dropOffLocationsErrorPadding" : "")
        }
        ref={mapDivRef}
      ></div>
    </React.Fragment>
  );
};

export default UspsConnectMap;
