const militaryIds = [2, 3, 6];

// State IDs of PTFAS (U.S. Possessions, Territories,
// and Freely Associated States) locations
const ptfasIds = [8, 16, 18, 30, 34, 50, 49, 58];

const militaryPtfas = {
  isMilitary: (stateId) => {
    return militaryIds.includes(Number(stateId));
  },

  isPtfas: (stateId) => {
    return ptfasIds.includes(Number(stateId));
  }
};

export default militaryPtfas;
