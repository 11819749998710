// Note that this list contains only the 50 states in the U.S.,
// plus the Distrinct of Columbia.  It does not include territories
// (Guam, Puerto Rico, etc.) or APO locations (AE - Armed Forces Europe, etc.).

const StatesList = [
  { id: "5", abbreviation: "AL", name: "ALABAMA" },
  { id: "4", abbreviation: "AK", name: "ALASKA" },
  { id: "9", abbreviation: "AZ", name: "ARIZONA" },
  { id: "7", abbreviation: "AR", name: "ARKANSAS" },
  { id: "10", abbreviation: "CA", name: "CALIFORNIA" },
  { id: "11", abbreviation: "CO", name: "COLORADO" },
  { id: "12", abbreviation: "CT", name: "CONNECTICUT" },
  { id: "14", abbreviation: "DE", name: "DELAWARE" },
  { id: "13", abbreviation: "DC", name: "DISTRICT OF COLUMBIA" },
  { id: "15", abbreviation: "FL", name: "FLORIDA" },
  { id: "17", abbreviation: "GA", name: "GEORGIA" },
  { id: "19", abbreviation: "HI", name: "HAWAII" },
  { id: "21", abbreviation: "ID", name: "IDAHO" },
  { id: "22", abbreviation: "IL", name: "ILLINOIS" },
  { id: "23", abbreviation: "IN", name: "INDIANA" },
  { id: "20", abbreviation: "IA", name: "IOWA" },
  { id: "24", abbreviation: "KS", name: "KANSAS" },
  { id: "25", abbreviation: "KY", name: "KENTUCKY" },
  { id: "26", abbreviation: "LA", name: "LOUISIANA" },
  { id: "29", abbreviation: "ME", name: "MAINE" },
  { id: "28", abbreviation: "MD", name: "MARYLAND" },
  { id: "27", abbreviation: "MA", name: "MASSACHUSETTS" },
  { id: "31", abbreviation: "MI", name: "MICHIGAN" },
  { id: "32", abbreviation: "MN", name: "MINNESOTA" },
  { id: "35", abbreviation: "MS", name: "MISSISSIPPI" },
  { id: "33", abbreviation: "MO", name: "MISSOURI" },
  { id: "36", abbreviation: "MT", name: "MONTANA" },
  { id: "39", abbreviation: "NE", name: "NEBRASKA" },
  { id: "43", abbreviation: "NV", name: "NEVADA" },
  { id: "40", abbreviation: "NH", name: "NEW HAMPSHIRE" },
  { id: "41", abbreviation: "NJ", name: "NEW JERSEY" },
  { id: "42", abbreviation: "NM", name: "NEW MEXICO" },
  { id: "44", abbreviation: "NY", name: "NEW YORK" },
  { id: "37", abbreviation: "NC", name: "NORTH CAROLINA" },
  { id: "38", abbreviation: "ND", name: "NORTH DAKOTA" },
  { id: "45", abbreviation: "OH", name: "OHIO" },
  { id: "46", abbreviation: "OK", name: "OKLAHOMA" },
  { id: "47", abbreviation: "OR", name: "OREGON" },
  { id: "48", abbreviation: "PA", name: "PENNSYLVANIA" },
  { id: "51", abbreviation: "RI", name: "RHODE ISLAND" },
  { id: "52", abbreviation: "SC", name: "SOUTH CAROLINA" },
  { id: "53", abbreviation: "SD", name: "SOUTH DAKOTA" },
  { id: "54", abbreviation: "TN", name: "TENNESSEE" },
  { id: "55", abbreviation: "TX", name: "TEXAS" },
  { id: "56", abbreviation: "UT", name: "UTAH" },
  { id: "59", abbreviation: "VT", name: "VERMONT" },
  { id: "57", abbreviation: "VA", name: "VIRGINIA" },
  { id: "60", abbreviation: "WA", name: "WASHINGTON" },
  { id: "62", abbreviation: "WV", name: "WEST VIRGINIA" },
  { id: "61", abbreviation: "WI", name: "WISCONSIN" },
  { id: "63", abbreviation: "WY", name: "WYOMING" }
];

export default StatesList;
