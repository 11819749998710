import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const TaxIdPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        The recipient's Tax ID Number (if applicable or known by the shipper) is
        used for customs purposes.
      </p>
      <p>If you do not know the Tax ID Number, you can also use</p>
      <p>
        Goods for Service Tax (GST) Number
        <br />
        Value Added Tax (VAT) Number
        <br />
        Employer Identification Number (EIN)
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Tax Id Information Icon"
      header="Tax ID Number"
      content={content}
    />
  );
};

export default TaxIdPopover;
