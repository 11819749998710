// These CIM symbol objects were obtained from
// https://developers.arcgis.com/javascript/latest/visualization/symbols-color-ramps/esri-web-style-symbols-2d/
//
// If the size, color, or rotation of any of these need to be modified,
// instead of doing so here (which would affect all instances of that
// CIM symbol), you can use cimSymbolUtils after importing the symbol
// (https://developers.arcgis.com/javascript/latest/api-reference/esri-symbols-support-cimSymbolUtils.html)

import CIMSymbol from "@arcgis/core/symbols/CIMSymbol";

const pushPin2 = new CIMSymbol({
  data: {
    type: "CIMSymbolReference",
    symbol: {
      type: "CIMPointSymbol",
      symbolLayers: [
        {
          type: "CIMVectorMarker",
          enable: true,
          anchorPoint: {
            x: 0,
            y: -0.5
          },
          anchorPointUnits: "Relative",
          dominantSizeAxis3D: "Y",
          size: 18.5,
          billboardMode3D: "FaceNearPlane",
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 21,
            ymax: 21
          },
          markerGraphics: [
            {
              type: "CIMMarkerGraphic",
              geometry: {
                rings: [
                  [
                    [15.2, 16.3],
                    [15.13, 15.47],
                    [14.9, 14.66],
                    [14.54, 13.91],
                    [14.05, 13.24],
                    [13.44, 12.65],
                    [12.74, 12.19],
                    [11.97, 11.86],
                    [11.15, 11.67],
                    [11.15, 1.3],
                    [10.5, 0],
                    [9.85, 1.3],
                    [9.85, 11.67],
                    [9.03, 11.86],
                    [8.26, 12.19],
                    [7.56, 12.65],
                    [6.95, 13.24],
                    [6.46, 13.91],
                    [6.1, 14.66],
                    [5.87, 15.47],
                    [5.8, 16.3],
                    [5.89, 17.22],
                    [6.16, 18.1],
                    [6.59, 18.91],
                    [7.18, 19.63],
                    [7.89, 20.21],
                    [8.7, 20.64],
                    [9.58, 20.91],
                    [10.5, 21],
                    [11.42, 20.91],
                    [12.3, 20.64],
                    [13.11, 20.21],
                    [13.82, 19.62],
                    [14.41, 18.91],
                    [14.84, 18.1],
                    [15.11, 17.22],
                    [15.2, 16.3]
                  ]
                ]
              },
              symbol: {
                type: "CIMPolygonSymbol",
                symbolLayers: [
                  {
                    type: "CIMSolidStroke",
                    enable: true,
                    capStyle: "Round",
                    joinStyle: "Round",
                    lineStyle3D: "Strip",
                    miterLimit: 10,
                    width: 0,
                    color: [110, 110, 110, 255]
                  },
                  {
                    type: "CIMSolidFill",
                    enable: true,
                    color: [201, 49, 0, 255]
                  }
                ]
              }
            }
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true
        }
      ],
      haloSize: 1,
      scaleX: 1,
      angleAlignment: "Display",
      version: "2.0.0",
      build: "8933"
    }
  }
});

const house = new CIMSymbol({
  data: {
    type: "CIMSymbolReference",
    symbol: {
      type: "CIMPointSymbol",
      symbolLayers: [
        {
          type: "CIMVectorMarker",
          enable: true,
          anchorPointUnits: "Relative",
          dominantSizeAxis3D: "Y",
          size: 15.75,
          billboardMode3D: "FaceNearPlane",
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 21,
            ymax: 21
          },
          markerGraphics: [
            {
              type: "CIMMarkerGraphic",
              geometry: {
                rings: [
                  [
                    [18, 11],
                    [17, 11],
                    [17, 5],
                    [18, 5],
                    [18, 4],
                    [3, 4],
                    [3, 5],
                    [4, 5],
                    [4, 11],
                    [3, 11],
                    [3, 12],
                    [10.5, 18],
                    [18, 12],
                    [18, 11]
                  ],
                  [
                    [6, 11],
                    [6, 8],
                    [9, 8],
                    [9, 11],
                    [6, 11]
                  ],
                  [
                    [12, 5],
                    [15, 5],
                    [15, 11],
                    [12, 11],
                    [12, 5]
                  ]
                ]
              },
              symbol: {
                type: "CIMPolygonSymbol",
                symbolLayers: [
                  {
                    type: "CIMSolidStroke",
                    enable: true,
                    capStyle: "Round",
                    joinStyle: "Round",
                    lineStyle3D: "Strip",
                    miterLimit: 10,
                    width: 0,
                    color: [0, 0, 0, 255]
                  },
                  {
                    type: "CIMSolidFill",
                    enable: true,
                    color: [108, 64, 0, 255]
                  }
                ]
              }
            }
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true
        },
        {
          type: "CIMVectorMarker",
          enable: true,
          colorLocked: true,
          anchorPointUnits: "Relative",
          dominantSizeAxis3D: "Y",
          size: 18.5,
          billboardMode3D: "FaceNearPlane",
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: "CIMMarkerGraphic",
              geometry: {
                rings: [
                  [
                    [0, 5],
                    [0.87, 4.92],
                    [1.71, 4.7],
                    [2.5, 4.33],
                    [3.21, 3.83],
                    [3.83, 3.21],
                    [4.33, 2.5],
                    [4.7, 1.71],
                    [4.92, 0.87],
                    [5, 0],
                    [4.92, -0.87],
                    [4.7, -1.71],
                    [4.33, -2.5],
                    [3.83, -3.21],
                    [3.21, -3.83],
                    [2.5, -4.33],
                    [1.71, -4.7],
                    [0.87, -4.92],
                    [0, -5],
                    [-0.87, -4.92],
                    [-1.71, -4.7],
                    [-2.5, -4.33],
                    [-3.21, -3.83],
                    [-3.83, -3.21],
                    [-4.33, -2.5],
                    [-4.7, -1.71],
                    [-4.92, -0.87],
                    [-5, 0],
                    [-4.92, 0.87],
                    [-4.7, 1.71],
                    [-4.33, 2.5],
                    [-3.83, 3.21],
                    [-3.21, 3.83],
                    [-2.5, 4.33],
                    [-1.71, 4.7],
                    [-0.87, 4.92],
                    [0, 5]
                  ]
                ]
              },
              symbol: {
                type: "CIMPolygonSymbol",
                symbolLayers: [
                  {
                    type: "CIMSolidStroke",
                    enable: true,
                    capStyle: "Round",
                    joinStyle: "Round",
                    lineStyle3D: "Strip",
                    miterLimit: 10,
                    width: 0.5,
                    color: [167, 169, 172, 255]
                  },
                  {
                    type: "CIMSolidFill",
                    enable: true,
                    color: [255, 255, 255, 255]
                  }
                ]
              }
            }
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true
        }
      ],
      haloSize: 1,
      scaleX: 1,
      angleAlignment: "Display",
      version: "2.0.0",
      build: "8933"
    }
  }
});

const tearPin2 = new CIMSymbol({
  data: {
    type: "CIMSymbolReference",
    symbol: {
      type: "CIMPointSymbol",
      symbolLayers: [
        {
          type: "CIMVectorMarker",
          enable: true,
          anchorPoint: {
            x: 0,
            y: -2.21
          },
          anchorPointUnits: "Relative",
          dominantSizeAxis3D: "Y",
          size: 6,
          billboardMode3D: "FaceNearPlane",
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: "CIMMarkerGraphic",
              geometry: {
                rings: [
                  [
                    [0, 5],
                    [0.87, 4.92],
                    [1.71, 4.7],
                    [2.5, 4.33],
                    [3.21, 3.83],
                    [3.83, 3.21],
                    [4.33, 2.5],
                    [4.7, 1.71],
                    [4.92, 0.87],
                    [5, 0],
                    [4.92, -0.87],
                    [4.7, -1.71],
                    [4.33, -2.5],
                    [3.83, -3.21],
                    [3.21, -3.83],
                    [2.5, -4.33],
                    [1.71, -4.7],
                    [0.87, -4.92],
                    [0, -5],
                    [-0.87, -4.92],
                    [-1.71, -4.7],
                    [-2.5, -4.33],
                    [-3.21, -3.83],
                    [-3.83, -3.21],
                    [-4.33, -2.5],
                    [-4.7, -1.71],
                    [-4.92, -0.87],
                    [-5, 0],
                    [-4.92, 0.87],
                    [-4.7, 1.71],
                    [-4.33, 2.5],
                    [-3.83, 3.21],
                    [-3.21, 3.83],
                    [-2.5, 4.33],
                    [-1.71, 4.7],
                    [-0.87, 4.92],
                    [0, 5]
                  ]
                ]
              },
              symbol: {
                type: "CIMPolygonSymbol",
                symbolLayers: [
                  {
                    type: "CIMSolidFill",
                    enable: true,
                    color: [255, 255, 255, 255]
                  }
                ]
              }
            }
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true
        },
        {
          type: "CIMVectorMarker",
          enable: true,
          colorLocked: true,
          anchorPoint: {
            x: 0,
            y: -0.5
          },
          anchorPointUnits: "Relative",
          dominantSizeAxis3D: "Y",
          size: 18.5,
          billboardMode3D: "FaceNearPlane",
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 21,
            ymax: 21
          },
          markerGraphics: [
            {
              type: "CIMMarkerGraphic",
              geometry: {
                rings: [
                  [
                    [17.17, 14.33],
                    [16.97, 12.96],
                    [16.38, 11.37],
                    [12.16, 3.98],
                    [11.2, 1.94],
                    [10.5, 0],
                    [9.8, 1.96],
                    [8.84, 4.02],
                    [4.61, 11.41],
                    [4.02, 12.98],
                    [3.83, 14.33],
                    [3.96, 15.63],
                    [4.34, 16.88],
                    [4.95, 18.03],
                    [5.78, 19.04],
                    [6.8, 19.88],
                    [7.95, 20.49],
                    [9.2, 20.87],
                    [10.5, 21],
                    [11.8, 20.87],
                    [13.05, 20.5],
                    [14.2, 19.88],
                    [15.22, 19.05],
                    [16.05, 18.03],
                    [16.66, 16.88],
                    [17.04, 15.63],
                    [17.17, 14.33]
                  ]
                ]
              },
              symbol: {
                type: "CIMPolygonSymbol",
                symbolLayers: [
                  {
                    type: "CIMSolidStroke",
                    enable: true,
                    capStyle: "Round",
                    joinStyle: "Round",
                    lineStyle3D: "Strip",
                    miterLimit: 10,
                    width: 0,
                    color: [110, 110, 110, 255]
                  },
                  {
                    type: "CIMSolidFill",
                    enable: true,
                    color: [88, 89, 91, 255]
                  }
                ]
              }
            }
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true
        }
      ],
      haloSize: 1,
      scaleX: 1,
      angleAlignment: "Display",
      version: "2.0.0",
      build: "8933"
    }
  }
});

export { pushPin2, house, tearPin2 };
