const validation = {
  // Matches one or more alphabetic characters
  alphabetic: /^[A-Za-z]+$/,
  // Matches one or more alphabetic or numeric characters
  alphanumeric: /^[A-Za-z\d]+$/,
  // Matches American currency formats
  // (should round to two decimal places elsewhere if necessary)
  // (ex. "543", "543.", "54.3", "5.43", "0.54", "0.5", ".54", ".5")
  currency: /^\d*(\.\d{0,2})?$/,
  // Matches email addresses
  email: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,})+$/,
  // Matches the range of acceptable characters for names or addresses
  nameAddress: /^(|[a-zA-Z0-9 \s,''\-#&()./:;\\_]){0,50}$/,
  // Matches one or more numeric characters
  numbers: /^\d+$/,
  // Matches ZIP or ZIP+4 formats
  zip: /^\d{5}(-\d{4})?$/
};

export default validation;
