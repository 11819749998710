import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const NeedHelp = (props) => {
  //const { loyaltyInfo } = props;
  const content = (
    <React.Fragment>
      <p>
        <strong>File an Insurance Claim</strong> - File a claim for a domestic
        or international shipment online.
      </p>
      <p>
        <strong>Request a Refund</strong> - Request a Refund for an unused
        Click-N-Ship® label.
      </p>
      <p>
        <strong>Request a Service Refund</strong> - Request a service refund for
        a domestic Priority Mail Express® package that was not delivered by its
        guaranteed date or for a Signature Confirmation™ extra service that was
        purchased but a signature was not captured.
      </p>
      {/* {loyaltyInfo ? ( 
        <p>
          <strong>USPS Loyalty Customer Portal® Activity History</strong> -
          Visit your loyalty activity history page to view events and
          transactions that impact your loyalty points amount, credit amount and
          benefit status.
        </p>
      ) : (
        <React.Fragment />
      )} */}
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Shipping History Help Information Icon"
      header="Shipping History Help"
      content={content}
    />
  );
};

export default NeedHelp;
