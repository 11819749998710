import React, { useState } from "react";
import { Modal } from "reactstrap";

const HsTariffNotFound = (props) => {
  const {
    // Modal controls
    isOpen,
    toggle,
    // Props for allowing the user to add an item without an HS tariff
    // and toggle the parent modal
    updateHsTariff,
    isHsTariffMandatory,
    setCanOmitHsTariff,
    checkForProhibitions,
    toggleParentModal
  } = props;

  const [hsTariffCheckbox, setHsTariffCheckbox] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setHsTariffCheckbox(false);
    toggle(false);
  };

  const showHsTariffText = () => {
    if (!isHsTariffMandatory) {
      return renderAllowNoHsTariff();
    }

    return renderRequireHsTariff();
  };

  const renderAllowNoHsTariff = () => {
    return (
      <React.Fragment>
        <p id="tlc-no-hs-tariff-allowed-text">
          A valid HS Tariff Code cannot be determined based off the inputs
          you've provided. You can update the item description to try again or
          you can check the box to ship without an HS Tariff Code. A duty and
          tax estimate will not be available for this item.
        </p>
        <div className="row section-row">
          <div className="col-12">
            <div className="usps-checkbox checkbox-field">
              <label
                className="checkbox-component"
                htmlFor="tlc-hs-tariff-checkbox"
              >
                <input
                  type="checkbox"
                  id="tlc-hs-tariff-checkbox"
                  name="tlc-hs-tariff-checkbox"
                  className="form-control checkbox-field"
                  checked={hsTariffCheckbox}
                  onChange={(e) => {
                    setHsTariffCheckbox(e.currentTarget.checked);
                  }}
                />
                <span className="checkbox" />I am shipping this item without an
                HS Tariff Code.
              </label>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderRequireHsTariff = () => {
    return (
      <React.Fragment>
        <p>
          A valid HS Tariff Code cannot be determined based off the inputs
          you've provided. You can update the item description to try again.
        </p>
      </React.Fragment>
    );
  };

  return (
    <Modal id="modal-tlc-hs-tariff" isOpen={isOpen}>
      <div className="modal-content modal-container">
        <div className="modal-header">
          <button
            type="button"
            className="close button-link"
            id="close"
            data-dismiss="modal"
            tabIndex="0"
            onClick={closeModal}
          >
            <span className="visuallyhidden">Close Modal</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="body-content">
            <div id="modal-tlc-hs-tariff-text">{showHsTariffText()}</div>
            <div className="row">
              <div className="col-12 button-wrapper tlc-button-wrapper">
                <div className="button-container">
                  <button
                    className="btn-primary button--white"
                    id="tlc-hs-tariff-modal-close-button"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
                {hsTariffCheckbox && (
                  <div className="button-container">
                    {" "}
                    <button
                      className="btn-primary"
                      id="tlc-hs-tariff-modal-continue-button"
                      onClick={(e) => {
                        updateHsTariff("");
                        setCanOmitHsTariff(true);
                        checkForProhibitions(false);
                        closeModal(e);
                        toggleParentModal(null, null);
                      }}
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HsTariffNotFound;
