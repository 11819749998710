import React, { useState } from "react";
import { Modal } from "reactstrap";
import { withRouter } from "react-router-dom";

const DeleteAllCartLabels = (props) => {
  const { className } = props;

  const [modal, setModal] = useState(
    props.test &&
      (props.location.pathname === "/labelInformation" ||
        props.location.pathname === "/")
  );

  const toggle = () => setModal(!modal);

  const userSelection = (e, selection) => {
    e.preventDefault();
    if (selection === "payNow") {
      props.history.push("/shippingCart");
    }
    toggle();
  };

  return (
    <React.Fragment>
      <Modal isOpen={modal} id="postage-due-modal" className={className}>
        <div className="modal-content modal-container">
          <div className="modal-header">
            <h4 className="modal-title">Postage Due</h4>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <p>
                A discrepancy has been identified for a label on your account
                and additional postage is due at this time. Please go to your
                cart and pay this postage now [to avoid collections]. To dispute
                this charge please contact the{" "}
                <a
                  rel=""
                  style={{ color: "#2f6fa9", textDecoration: "underline" }}
                  href="https://emailus.usps.com/emailUs/iq/usps/request.do?forward=emailUs"
                >
                  USPS Customer Care Center
                </a>
                .
              </p>
            </div>
            <div className="modal-buttons">
              <div className="button-container">
                <a
                  href="/shippingCart"
                  role="button"
                  className="btn-primary error-display"
                  id="cnsIDXSModalSubmit"
                  tabIndex="0"
                  onClick={(e) => {
                    userSelection(e, "payNow");
                  }}
                >
                  Pay Now
                </a>
              </div>
              <div className="button-container pull-right">
                <button
                  type="button"
                  className="btn-primary error-display"
                  id="cnsIDXSModalSubmit"
                  tabIndex="0"
                  onClick={(e) => {
                    userSelection(e, "payLater");
                  }}
                >
                  Pay Later
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(DeleteAllCartLabels);
