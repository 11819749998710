import React from "react";

const PageNumbers = (props) => {
  const {
    // Optional
    divId,
    divClassName,
    ulId,
    liClassName,
    // Required
    currentPage,
    setCurrentPage,
    pageNumbersToDisplay,
    numOfPages
  } = props;

  return (
    <div
      id={divId}
      className={"col-12 col-md-6" + (divClassName ? " " + divClassName : "")}
    >
      <ul id={ulId} className="results-pagination">
        <li
          className={
            "navigation previous" + (currentPage <= 1 ? " inactive" : "")
          }
        >
          {currentPage > 1 ? (
            <button
              type="button"
              className="button-link"
              onClick={() => setCurrentPage(currentPage - 1)}
              aria-label="Previous Page"
            >
              <span className="navigation-text mobile">Previous</span>
            </button>
          ) : null}
        </li>

        {pageNumbersToDisplay.map((pageNumber) => {
          return (
            <li
              key={pageNumber}
              className={
                "hover-item" +
                (liClassName ? " " + liClassName : "") +
                (currentPage === pageNumber ? " active" : "")
              }
            >
              <button
                type="button"
                className="button-link"
                onClick={() => {
                  setCurrentPage(pageNumber);
                }}
              >
                {currentPage === pageNumber ? (
                  <span className="visuallyhidden">
                    Currently selected page
                  </span>
                ) : (
                  ""
                )}
                {pageNumber}
              </button>
            </li>
          );
        })}

        <li
          className={
            "navigation next" + (currentPage >= numOfPages ? " inactive" : "")
          }
        >
          {currentPage < numOfPages ? (
            <button
              type="button"
              className="button-link"
              onClick={() => setCurrentPage(currentPage + 1)}
              aria-label="Next Page"
            >
              <span className="navigation-text mobile">Next</span>
            </button>
          ) : null}
        </li>
      </ul>
    </div>
  );
};

export default PageNumbers;
