import React from "react";

const CategoryLists = {
  getCategories: () => {
    return (
      <React.Fragment>
        <option value="">Select a Category</option>
        <option value="Appliances, Parts & Accessories">
          Appliances, Parts & Accessories
        </option>
        <option value="Arts & Collectibles">Arts & Collectibles</option>
        <option value="Automotive & Vehicles">Automotive & Vehicles</option>
        <option value="Beauty & Health">Beauty & Health</option>
        <option value="Books, Movies & Music">Books, Movies & Music</option>
        <option value="Clothing & Accessories">Clothing & Accessories</option>
        <option value="Electronics, Computers & Cameras">
          Electronics, Computers & Cameras
        </option>
        <option value="Food & Groceries">Food & Groceries</option>
        <option value="Home & Garden">Home & Garden</option>
        <option value="Industrial, Scientific & Machinery">
          Industrial, Scientific & Machinery
        </option>
        <option value="Jewelry & Watches">Jewelry & Watches</option>
        <option value="Office Products">Office Products</option>
        <option value="Pet Supplies">Pet Supplies</option>
        <option value="Sports & Outdoors">Sports & Outdoors</option>
        <option value="Tools & Construction materials">
          Tools & Construction materials
        </option>
        <option value="Toys & Games">Toys & Games</option>
      </React.Fragment>
    );
  },

  getSubcategories: (category) => {
    switch (category) {
      case "Appliances, Parts & Accessories":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Air Compressors">Air Compressors</option>
              <option value="Bathroom Appliances">Bathroom Appliances</option>
              <option value="Clothing Iron">Clothing Iron</option>
              <option value="Coffee Makers, Toasters & Other Heat-generating Kitchen Appliances">
                Coffee Makers, Toasters & Other Heat-generating Kitchen
                Appliances
              </option>
              <option value="Cutting Blades">Cutting Blades</option>
              <option value="Fans">Fans</option>
              <option value="Food Grinders">Food Grinders</option>
              <option value="Heaters, Radiators & Air Conditioning Units">
                Heaters, Radiators & Air Conditioning Units
              </option>
              <option value="Mechanical Kitchen Appliances">
                Mechanical Kitchen Appliances
              </option>
              <option value="Microwave Ovens">Microwave Ovens</option>
              <option value="Other Household Appliances">
                Other Household Appliances
              </option>
              <option value="Other Kitchen Appliances">
                Other Kitchen Appliances
              </option>
              <option value="Oven, Cooktops & Ventilating Hoods">
                Oven, Cooktops & Ventilating Hoods
              </option>
              <option value="Refrigerators & Freezers">
                Refrigerators & Freezers
              </option>
              <option value="Sewing Machines">Sewing Machines</option>
              <option value="Sinks, Toilets & Bathtubs">
                Sinks, Toilets & Bathtubs
              </option>
              <option value="Vacuum Cleaners">Vacuum Cleaners</option>
              <option value="Washing Machines & Dryers">
                Washing Machines & Dryers
              </option>
              <option value="Weighing Scales">Weighing Scales</option>
            </React.Fragment>
          );
        };
      case "Arts & Collectibles":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Antique Hand-Painted & Hand-Drawn Art">
                Antique Hand-Painted & Hand-Drawn Art
              </option>
              <option value="Antiques Over 100 Years Old">
                Antiques Over 100 Years Old
              </option>
              <option value="Archaeological, Zoological & Historical Collector's Pieces">
                Archaeological, Zoological & Historical Collector's Pieces
              </option>
              <option value="Art Paint & Brushes">Art Paint & Brushes</option>
              <option value="Art Prints">Art Prints</option>
              <option value="Blank Art Canvas">Blank Art Canvas</option>
              <option value="Coin">Coin</option>
              <option value="Hand-Painted & Hand-Drawn Art">
                Hand-Painted & Hand-Drawn Art
              </option>
              <option value="Non-Original Wooden Art">
                Non-Original Wooden Art
              </option>
              <option value="Original Prints & Engravings">
                Original Prints & Engravings
              </option>
              <option value="Original Sculptures & Carvings">
                Original Sculptures & Carvings
              </option>
              <option value="Sewing Materials">Sewing Materials</option>
              <option value="Stamp Collections">Stamp Collections</option>
              <option value="Statuettes and Ornaments">
                Statuettes and Ornaments
              </option>
            </React.Fragment>
          );
        };
      case "Automotive & Vehicles":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Aircraft & Parts">Aircraft & Parts</option>
              <option value="Bicycles & Accessories">
                Bicycles & Accessories
              </option>
              <option value="Car, Truck & Tractor Parts">
                Car, Truck & Tractor Parts
              </option>
              <option value="Cranes">Cranes</option>
              <option value="Diesel-powered Cars & Trucks">
                Diesel-powered Cars & Trucks
              </option>
              <option value="Electric Cars & Trucks">
                Electric Cars & Trucks
              </option>
              <option value="Gas-powered Cars & Trucks">
                Gas-powered Cars & Trucks
              </option>
              <option value="Motorcycle & Bicycle Parts">
                Motorcycle & Bicycle Parts
              </option>
              <option value="Motorcycles">Motorcycles</option>
              <option value="Railway & Tramway Vehicles & Parts">
                Railway & Tramway Vehicles & Parts
              </option>
              <option value="Scooters & Chairs for the Disabled & Parts">
                Scooters & Chairs for the Disabled & Parts
              </option>
              <option value="Ship & Boat Parts">Ship & Boat Parts</option>
              <option value="Ships & Boats">Ships & Boats</option>
              <option value="Spacecraft & Parts">Spacecraft & Parts</option>
              <option value="Special Vehicles (e.g., Fire Trucks, Cement Mixers, etc.)">
                Special Vehicles (e.g., Fire Trucks, Cement Mixers, etc.)
              </option>
              <option value="Tractors & Work Trucks">
                Tractors & Work Trucks
              </option>
              <option value="Trailers">Trailers</option>
            </React.Fragment>
          );
        };
      case "Beauty & Health":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Air Fresheners & Incense">
                Air Fresheners & Incense
              </option>
              <option value="Bath & Hand Soap">Bath & Hand Soap</option>
              <option value="Bath salts">Bath salts</option>
              <option value="Contact Lenses">Contact Lenses</option>
              <option value="Contraceptives">Contraceptives</option>
              <option value="Dental Fittings & Hygiene">
                Dental Fittings & Hygiene
              </option>
              <option value="Feminine Hygiene">Feminine Hygiene</option>
              <option value="Hair Cutting Tools">Hair Cutting Tools</option>
              <option value="Hair Dryers, Straighteners & Curlers">
                Hair Dryers, Straighteners & Curlers
              </option>
              <option value="Make-up">Make-up</option>
              <option value="Manicure & Pedicure Sets">
                Manicure & Pedicure Sets
              </option>
              <option value="Massage Tools">Massage Tools</option>
              <option value="Medicine, Ointments, First Aid & Bandages">
                Medicine, Ointments, First Aid & Bandages
              </option>
              <option value="Other Hygiene">Other Hygiene</option>
              <option value="Pedometer">Pedometer</option>
              <option value="Perfume & Deodorant">Perfume & Deodorant</option>
              <option value="Personal Medical Equipment">
                Personal Medical Equipment
              </option>
              <option value="Shampoo & Hair Products">
                Shampoo & Hair Products
              </option>
              <option value="Shaving & Waxing">Shaving & Waxing</option>
              <option value="Vaccines, Blood, & Cell Cultures">
                Vaccines, Blood, & Cell Cultures
              </option>
            </React.Fragment>
          );
        };
      case "Books, Movies & Music":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Books & Brochures">Books & Brochures</option>
              <option value="Catalogs">Catalogs</option>
              <option value="Coloring Books">Coloring Books</option>
              <option value="DVDs, CDs, Tapes & Other Recorded Media">
                DVDs, CDs, Tapes & Other Recorded Media
              </option>
              <option value="Maps & Globes">Maps & Globes</option>
              <option value="Music Boxes">Music Boxes</option>
              <option value="Musical Instruments & Parts">
                Musical Instruments & Parts
              </option>
              <option value="Newspapers, Journals & Magazines">
                Newspapers, Journals & Magazines
              </option>
              <option value="Printed Decals">Printed Decals</option>
              <option value="Sheet Music">Sheet Music</option>
            </React.Fragment>
          );
        };
      case "Clothing & Accessories":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Baby Clothes">Baby Clothes</option>
              <option value="Belts & Buckles">Belts & Buckles</option>
              <option value="Fabric Material">Fabric Material</option>
              <option value="Glasses & Sunglasses">Glasses & Sunglasses</option>
              <option value="Gloves and Mittens">Gloves and Mittens</option>
              <option value="Hats & Headgear">Hats & Headgear</option>
              <option value="Hoodies, Sweaters & Cardigans">
                Hoodies, Sweaters & Cardigans
              </option>
              <option value="Luggage, Backpacks, Bags & Wallets">
                Luggage, Backpacks, Bags & Wallets
              </option>
              <option value="Men's & Boys' Jackets, Shirts & Suits">
                Men's & Boys' Jackets, Shirts & Suits
              </option>
              <option value="Men's & Boys' Pants & Swimwear">
                Men's & Boys' Pants & Swimwear
              </option>
              <option value="Men's & Boys' Underwear & Pajamas">
                Men's & Boys' Underwear & Pajamas
              </option>
              <option value="Other Clothing Accessories">
                Other Clothing Accessories
              </option>
              <option value="Shoes & Accessories">Shoes & Accessories</option>
              <option value="Socks, Pantyhose & Tights">
                Socks, Pantyhose & Tights
              </option>
              <option value="Ties & Scarves">Ties & Scarves</option>
              <option value="Used Clothing">Used Clothing</option>
              <option value="Wigs & False Hair">Wigs & False Hair</option>
              <option value="Women's & Girls' Jackets, Shirts & Dresses">
                Women's & Girls' Jackets, Shirts & Dresses
              </option>
              <option value="Women's & Girls' Pants, Skirts & Swimwear">
                Women's & Girls' Pants, Skirts & Swimwear
              </option>
              <option value="Women's & Girls' Underwear & Pajamas">
                Women's & Girls' Underwear & Pajamas
              </option>
            </React.Fragment>
          );
        };
      case "Electronics, Computers & Cameras":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Audio-playing Devices">
                Audio-playing Devices
              </option>
              <option value="Batteries & Power Banks">
                Batteries & Power Banks
              </option>
              <option value="Cables & Connectors">Cables & Connectors</option>
              <option value="Cameras, Video Cameras & Parts">
                Cameras, Video Cameras & Parts
              </option>
              <option value="Chargers">Chargers</option>
              <option value="Computer Parts & Accessories">
                Computer Parts & Accessories
              </option>
              <option value="Device Cases & Bags">Device Cases & Bags</option>
              <option value="DVD & Blu-ray Players">
                DVD & Blu-ray Players
              </option>
              <option value="Gift, Credit & Smart Cards">
                Gift, Credit & Smart Cards
              </option>
              <option value="GPS Devices & Radios">GPS Devices & Radios</option>
              <option value="Laptops & Desktops">Laptops & Desktops</option>
              <option value="Electrical Parts">Electrical Parts</option>
              <option value="Smoke Detectors & Alarms">
                Smoke Detectors & Alarms
              </option>
              <option value="Speakers, Headphones & Microphones">
                Speakers, Headphones & Microphones
              </option>
              <option value="Streaming Service Devices">
                Streaming Service Devices
              </option>
              <option value="Telephones & Smartphones">
                Telephones & Smartphones
              </option>
              <option value="Televisions & Projectors">
                Televisions & Projectors
              </option>
              <option value="Traffic & Safety Signal Equipment">
                Traffic & Safety Signal Equipment
              </option>
              <option value="Video Game Consoles & Games">
                Video Game Consoles & Games
              </option>
            </React.Fragment>
          );
        };
      case "Food & Groceries":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Alcoholic Beverages">Alcoholic Beverages</option>
              <option value="Baby Formula">Baby Formula</option>
              <option value="Chocolate & Cocoa Products">
                Chocolate & Cocoa Products
              </option>
              <option value="Coffee, Tea & Mate">Coffee, Tea & Mate</option>
              <option value="Dairy & Eggs">Dairy & Eggs</option>
              <option value="Extracts & Concentrates">
                Extracts & Concentrates
              </option>
              <option value="Fats & Oils">Fats & Oils</option>
              <option value="Fruit">Fruit</option>
              <option value="Fruit & Nut Mix">Fruit & Nut Mix</option>
              <option value="Grains & Flour">Grains & Flour</option>
              <option value="Jams & Jellies">Jams & Jellies</option>
              <option value="Live Fish & Seafood">Live Fish & Seafood</option>
              <option value="Meat & Fish">Meat & Fish</option>
              <option value="Nuts">Nuts</option>
              <option value="Other Food Items">Other Food Items</option>
              <option value="Sauces">Sauces</option>
              <option value="Soda & Other Drinks">Soda & Other Drinks</option>
              <option value="Soups & Broths">Soups & Broths</option>
              <option value="Spices & Sugar">Spices & Sugar</option>
              <option value="Syrup & Molasses">Syrup & Molasses</option>
              <option value="Tobacco Products & E-cigarettes">
                Tobacco Products & E-cigarettes
              </option>
              <option value="Treats">Treats</option>
              <option value="Vegetables & Other Plants">
                Vegetables & Other Plants
              </option>
            </React.Fragment>
          );
        };
      case "Home & Garden":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Adhesives & Tape">Adhesives & Tape</option>
              <option value="Animal Hides & Parts">Animal Hides & Parts</option>
              <option value="Aritifical Plants & Flowers">
                Aritifical Plants & Flowers
              </option>
              <option value="Bedding & Towels">Bedding & Towels</option>
              <option value="Candles">Candles</option>
              <option value="Clocks & Parts">Clocks & Parts</option>
              <option value="Clothing Hangers">Clothing Hangers</option>
              <option value="Containers & Baskets">Containers & Baskets</option>
              <option value="Curtains">Curtains</option>
              <option value="Electric Kitchen Appliances">
                Electric Kitchen Appliances
              </option>
              <option value="Furniture">Furniture</option>
              <option value="Household Cleaners & Trash Bags">
                Household Cleaners & Trash Bags
              </option>
              <option value="Lamps & Lighting">Lamps & Lighting</option>
              <option value="Live Plants & Fresh Flowers">
                Live Plants & Fresh Flowers
              </option>
              <option value="Mirrors">Mirrors</option>
              <option value="Other Household Items">
                Other Household Items
              </option>
              <option value="Other Outdoor & Garden Items">
                Other Outdoor & Garden Items
              </option>
              <option value="Rugs & Mats">Rugs & Mats</option>
              <option value="Sacks & Bags">Sacks & Bags</option>
              <option value="Safes & Strong-boxes">Safes & Strong-boxes</option>
              <option value="Statuettes & Other Decor">
                Statuettes & Other Decor
              </option>
              <option value="Tableware & Kitchenware">
                Tableware & Kitchenware
              </option>
              <option value="Toilet Paper, Napkins & Tablecloths">
                Toilet Paper, Napkins & Tablecloths
              </option>
            </React.Fragment>
          );
        };
      case "Industrial, Scientific & Machinery":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Agricultural & Food Machinery">
                Agricultural & Food Machinery
              </option>
              <option value="Batteries & Accumulators">
                Batteries & Accumulators
              </option>
              <option value="Buttons & Mannequins">Buttons & Mannequins</option>
              <option value="Dies & Tanning Extracts">
                Dies & Tanning Extracts
              </option>
              <option value="Electric Parts, Electronic Waste & Scrap">
                Electric Parts, Electronic Waste & Scrap
              </option>
              <option value="Energy-generating Equipment">
                Energy-generating Equipment
              </option>
              <option value="Explosives & Combustibles">
                Explosives & Combustibles
              </option>
              <option value="Industrial Animal Products">
                Industrial Animal Products
              </option>
              <option value="Industrial Furnaces & Ovens">
                Industrial Furnaces & Ovens
              </option>
              <option value="Industrial Glass Items">
                Industrial Glass Items
              </option>
              <option value="Industrial Metal Items">
                Industrial Metal Items
              </option>
              <option value="Industrial Plastic Items">
                Industrial Plastic Items
              </option>
              <option value="Industrial Rubber Items">
                Industrial Rubber Items
              </option>
              <option value="Industrial Stone & Cement Items">
                Industrial Stone & Cement Items
              </option>
              <option value="Industrial Textile Items">
                Industrial Textile Items
              </option>
              <option value="Industrial Vacuum Cleaners">
                Industrial Vacuum Cleaners
              </option>
              <option value="Industrial Washers & Dryers">
                Industrial Washers & Dryers
              </option>
              <option value="Industrial Wood Items">
                Industrial Wood Items
              </option>
              <option value="Laboratory Equipment">Laboratory Equipment</option>
              <option value="Magnets">Magnets</option>
              <option value="Measuring Equipment">Measuring Equipment</option>
              <option value="Medical Equipment">Medical Equipment</option>
              <option value="Metal-working Machinery">
                Metal-working Machinery
              </option>
              <option value="Motors & Generators">Motors & Generators</option>
              <option value="Moulds">Moulds</option>
              <option value="Navigation &  Surveying Equipment">
                Navigation & Surveying Equipment
              </option>
              <option value="Ores, Mineral Fuels & Oils">
                Ores, Mineral Fuels & Oils
              </option>
              <option value="Organic Compounds">Organic Compounds</option>
              <option value="Other Chemical Products">
                Other Chemical Products
              </option>
              <option value="Other Electrical Machinery & Parts">
                Other Electrical Machinery & Parts
              </option>
              <option value="Other Industrial Machinery">
                Other Industrial Machinery
              </option>
              <option value="Other Industrial Machinery Parts">
                Other Industrial Machinery Parts
              </option>
              <option value="Other Minerals & Stone">
                Other Minerals & Stone
              </option>
              <option value="Packing Materials & Containers">
                Packing Materials & Containers
              </option>
              <option value="Precious Metal Compounds & Inorganic Chemicals">
                Precious Metal Compounds & Inorganic Chemicals
              </option>
              <option value="Printing & Paper Machinery">
                Printing & Paper Machinery
              </option>
              <option value="Railway Materials">Railway Materials</option>
              <option value="Semiconductor-producing Machinery">
                Semiconductor-producing Machinery
              </option>
              <option value="Textile Machinery">Textile Machinery</option>
              <option value="Transformers">Transformers</option>
              <option value="Vegetative Materials">Vegetative Materials</option>
              <option value="Vending Machines">Vending Machines</option>
              <option value="Welding Equipment">Welding Equipment</option>
            </React.Fragment>
          );
        };
      case "Jewelry & Watches":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Imitation Jewelry & Parts">
                Imitation Jewelry & Parts
              </option>
              <option value="Jewelry Boxes & Cases">
                Jewelry Boxes & Cases
              </option>
              <option value="Jewelry of Pearls or Precious Metal & Parts">
                Jewelry of Pearls or Precious Metal & Parts
              </option>
              <option value="Pearls & Precious Stones">
                Pearls & Precious Stones
              </option>
              <option value="Precious Metals">Precious Metals</option>
              <option value="Watches & Parts">Watches & Parts</option>
            </React.Fragment>
          );
        };
      case "Office Products":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Account Books, Receipt Books & Binders">
                Account Books, Receipt Books & Binders
              </option>
              <option value="Adhesives & Tape">Adhesives & Tape</option>
              <option value="Calculators">Calculators</option>
              <option value="Calendars">Calendars</option>
              <option value="Chalkboards & Dry Erase Boards">
                Chalkboards & Dry Erase Boards
              </option>
              <option value="Documents & Business Cards">
                Documents & Business Cards
              </option>
              <option value="Erasers">Erasers</option>
              <option value="Filing Cabinets & Trays">
                Filing Cabinets & Trays
              </option>
              <option value="Letter Openers">Letter Openers</option>
              <option value="Other Mathematical Tools">
                Other Mathematical Tools
              </option>
              <option value="Other Plastic School Supplies">
                Other Plastic School Supplies
              </option>
              <option value="Paper & Paperboard">Paper & Paperboard</option>
              <option value="Pencil Sharpeners, Staplers & Other Office Machines">
                Pencil Sharpeners, Staplers & Other Office Machines
              </option>
              <option value="Pens & Pencils">Pens & Pencils</option>
              <option value="Printers & Ink">Printers & Ink</option>
              <option value="Safety Pins, Paper Clips & Staples">
                Safety Pins, Paper Clips & Staples
              </option>
              <option value="Scissors">Scissors</option>
              <option value="Stamps">Stamps</option>
            </React.Fragment>
          );
        };
      case "Pet Supplies":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Cages & Accessories">Cages & Accessories</option>
              <option value="Fish Tanks, Terrariums & Accessories">
                Fish Tanks, Terrariums & Accessories
              </option>
              <option value="Food Bowls">Food Bowls</option>
              <option value="Leashes, Harnesses & Saddles">
                Leashes, Harnesses & Saddles
              </option>
              <option value="Litter Box">Litter Box</option>
              <option value="Pet Beds">Pet Beds</option>
              <option value="Pet Food">Pet Food</option>
              <option value="Pet Toys">Pet Toys</option>
            </React.Fragment>
          );
        };
      case "Sports & Outdoors":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Air Mattresses & Sleeping Bags">
                Air Mattresses & Sleeping Bags
              </option>
              <option value="Air Pumps">Air Pumps</option>
              <option value="Binoculars & Telescopes">
                Binoculars & Telescopes
              </option>
              <option value="Camping Backpacks & Sports Bags">
                Camping Backpacks & Sports Bags
              </option>
              <option value="Compasses">Compasses</option>
              <option value="Firearms & Weapons">Firearms & Weapons</option>
              <option value="Firewood & Charcoal">Firewood & Charcoal</option>
              <option value="Fireworks & Flares">Fireworks & Flares</option>
              <option value="Fishing Gear">Fishing Gear</option>
              <option value="Grills & Stoves">Grills & Stoves</option>
              <option value="Hammocks">Hammocks</option>
              <option value="Lamps & Flashlights">Lamps & Flashlights</option>
              <option value="Matches & Lighters">Matches & Lighters</option>
              <option value="Pans & Dutch Ovens">Pans & Dutch Ovens</option>
              <option value="Sports Equipment & Balls">
                Sports Equipment & Balls
              </option>
              <option value="Tents, Tarps & Rope">Tents, Tarps & Rope</option>
              <option value="Umbrellas">Umbrellas</option>
              <option value="Walking Sticks & Whips">
                Walking Sticks & Whips
              </option>
              <option value="Water Sports">Water Sports</option>
              <option value="Winter Sports">Winter Sports</option>
            </React.Fragment>
          );
        };
      case "Tools & Construction materials":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Carpet, Flooring & Tile">
                Carpet, Flooring & Tile
              </option>
              <option value="Doors & Windows">Doors & Windows</option>
              <option value="Hand Tools">Hand Tools</option>
              <option value="Lawn Equipment">Lawn Equipment</option>
              <option value="Lumber & Furniture Wood">
                Lumber & Furniture Wood
              </option>
              <option value="Metal Mountings">Metal Mountings</option>
              <option value="Moulding Patterns">Moulding Patterns</option>
              <option value="Moulding Patterns & Engineering Drawings">
                Moulding Patterns & Engineering Drawings
              </option>
              <option value="Nails, Screws & Bolts">
                Nails, Screws & Bolts
              </option>
              <option value="Other Equipment">Other Equipment</option>
              <option value="Other Metal Equipment">
                Other Metal Equipment
              </option>
              <option value="Paint, Varnish & Paint Thinner">
                Paint, Varnish & Paint Thinner
              </option>
              <option value="Pipes & Hoses">Pipes & Hoses</option>
              <option value="Power Tools & Parts">Power Tools & Parts</option>
              <option value="Pulleys, Winches & Jacks">
                Pulleys, Winches & Jacks
              </option>
              <option value="Pumps, Compressors & Generators">
                Pumps, Compressors & Generators
              </option>
              <option value="Roofing">Roofing</option>
              <option value="Safety Gear & Scaffolding">
                Safety Gear & Scaffolding
              </option>
              <option value="Stone, Gravel & Brick">
                Stone, Gravel & Brick
              </option>
              <option value="Tape, Construction Adhesives & Putty">
                Tape, Construction Adhesives & Putty
              </option>
              <option value="Wire & Rods">Wire & Rods</option>
            </React.Fragment>
          );
        };
      case "Toys & Games":
        return () => {
          return (
            <React.Fragment>
              {getDefaultSubcategory()}
              <option value="Amusement Park Equipment">
                Amusement Park Equipment
              </option>
              <option value="Arcade Games">Arcade Games</option>
              <option value="Games & Puzzles">Games & Puzzles</option>
              <option value="Other Toys">Other Toys</option>
              <option value="Wheeled Toys">Wheeled Toys</option>
            </React.Fragment>
          );
        };
      default:
        return () => {
          return <React.Fragment>{getDefaultSubcategory()}</React.Fragment>;
        };
    }
  }
};

const getDefaultSubcategory = () => {
  return (
    <React.Fragment>
      <option value="">Select a sub-category</option>
    </React.Fragment>
  );
};

export default CategoryLists;
