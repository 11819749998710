import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import RemoveBatchModal from "../Modals/RemoveBatchModal";
import RemoveAllBatchModal from "../Modals/RemoveAllBatchModal";
import AddressStandardizedPopover from "../Popovers/AddressStandardizedPopover";
import PostOfficeHoursPopover from "../Popovers/PostOfficeHoursPopover";
import SmsGateWay from "../Popovers/SmsGateWay";
import AddressBook from "../Modals/AddressBook";
import WhiteSpinner from "../Modals/WhiteSpinner";
import AssistiveError from "../Subcomponents/AssistiveError";
import StatesTerritoriesList from "../Subcomponents/StatesTerritoriesList";
import validation from "../../utils/validation";
import update from "immutability-helper";

class StepTwoDeliveryAddress extends React.Component {
  constructor(props) {
    super(props);

    // Set the maximum number of batch items that can be created,
    // without accounting for how many cart items are already present
    this.batchLengthLimit = 20;

    // This is part of the ID that will be given to each post office
    // when using HFP and searching for a ZIP code. The unique ID of each post
    // office is appended to this. It is made a variable here so it can be
    // easily sliced off when only the unique number portion is needed.
    this.postOfficeHoursIconIdPrefix = "postOfficeHoursIcon";

    this.state = {
      editLabelIndex: 0,
      batchCanBeSelected:
        this.batchLengthLimit -
        (this.props.cartCount + this.props.configInfo.batch.length),
      fetchServicesData: {
        cnsCartList: [
          {
            batchNbr: this.props.configInfo.cnsCartList.batchNbr,
            cnsCartId: this.props.configInfo.cnsCartList.cnsCartId,
            deliveryAddress: {
              addressId: "-1",
              contactId: "-1",
              countryId: "840",
              firstName: "",
              middleInit: "",
              lastName: "",
              companyName: "",
              line1Addr: "",
              line2Addr: "",
              cityName: "",
              stateId: "",
              postalCode: "",
              urbanizationCode: "",
              refNbr: ""
            },
            labelInd: {
              batchInd: "Y",
              shipNotifyInd: ""
            },
            cnsCartTypeId: 1
          }
        ],
        saveDeliveryToAddressBook: false
      },
      saveLabelInfoAdditionalData: {
        updateCartId: null,
        saveDeliveryToAddressBook: false
      },
      mobileShow: false,
      fetchedHfpData: false,
      emailAddresseeRadioChecked:
        this.props.configInfo.labelHfp.recipEmailType === "EMAIL" ||
        this.props.configInfo.labelHfp.recipEmailType === "",
      emailSenderRadioChecked:
        this.props.configInfo.labelHfp.senderEmailType === "EMAIL" ||
        this.props.configInfo.labelHfp.senderEmailType === "",
      postOfficeHoursIconId: undefined
    };
  }

  setBatch = (e) => {
    const checked = e.currentTarget.checked;
    // Update the checkbox state
    this.props.setBatchChecked(checked);
    this.props.setAddToBatchMessage(false);
    // Empty the batch list, if necessary
    if (!checked) {
      this.props.removeAllBatch();
    }
  };

  changeDeliveryAddress = (e) => (input) => {
    if (e === "GABBatch") {
      // Clear any existing errors for the fields provided by the address book
      if (input.firstName) {
        this.props.clearError("deliveryFirstName");
      }
      if (input.lastName) {
        this.props.clearError("deliveryLastName");
      }
      if (input.firstName && input.lastName) {
        this.props.clearError("deliveryCompany");
      } else if (input.companyName) {
        this.props.clearError("deliveryFirstName");
        this.props.clearError("deliveryLastName");
        this.props.clearError("deliveryCompany");
      }
      const address = input.address;
      if (address) {
        if (address.addressLine1) {
          this.props.clearError("deliveryStreetAddr");
        }
        // Since this field is optional, remove the error
        this.props.clearError("deliveryAptSuiteOther");
        if (address.city) {
          this.props.clearError("deliveryCity");
        }
        if (address.state && address.state.stateDbId) {
          this.props.clearError("deliveryState");
        }
        if (address.zipCode || address.postalCode) {
          this.props.clearError("deliveryZip");
        }
        // Update the fields provided by the address book
        if (address.country) {
          if (address.country.countryId === "840") {
            let newState = update(this.state, {
              fetchServicesData: {
                cnsCartList: {
                  0: {
                    deliveryAddress: {
                      contactId: { $set: String(input.contactId) },
                      countryId: {
                        $set: parseInt(address.country.countryId, 10)
                      },
                      firstName: { $set: input.firstName || "" },
                      middleInit: { $set: input.middleName || "" },
                      lastName: { $set: input.lastName || "" },
                      companyName: {
                        $set: input.companyName || ""
                      },
                      line1Addr: {
                        $set: address.addressLine1 || ""
                      },
                      line2Addr: {
                        $set: address.addressLine2 || ""
                      },
                      cityName: { $set: address.city || "" },
                      stateId: {
                        $set:
                          address.state && address.state.stateDbId
                            ? String(address.state.stateDbId)
                            : ""
                      },
                      postalCode: {
                        $set: address.zipCode || address.postalCode || ""
                      },
                      urbanizationCode: {
                        $set: address.urbanizationCode || ""
                      },
                      refNbr: {
                        $set: address.referenceNumber || ""
                      },
                      emailAddress: {
                        $set: input.emailAddress || ""
                      }
                    }
                  }
                }
              }
            });

            this.setState(newState);
          }
        }
      }
    } else {
      let newState;
      if (input.target) {
        // When "input" is the entire event object
        const { value, maxLength } = input.target;
        newState = update(this.state, {
          fetchServicesData: {
            cnsCartList: {
              0: {
                deliveryAddress: {
                  [e]: { $set: value.slice(0, maxLength) }
                }
              }
            }
          }
        });
      } else {
        // When "input" is the event value
        newState = update(this.state, {
          fetchServicesData: {
            cnsCartList: {
              0: {
                deliveryAddress: {
                  [e]: { $set: input }
                }
              }
            }
          }
        });
      }
      this.setState(newState);
    }
  };

  changeDeliveryAddressBatch = (batchContacts) => {
    let dataToSend = [];
    // Add each contact selected from the address book to the batch order
    for (let i in batchContacts) {
      this.props.toggleSpinner(true);
      // Collect the data that will be passed to the endpoint
      const contact = batchContacts[i];
      dataToSend.push({
        fetchServicesData: {
          cnsCartList: [
            {
              batchNbr: this.props.configInfo.cnsCartList.batchNbr,
              cnsCartId: this.props.configInfo.cnsCartList.cnsCartId,
              cnsCartTypeId: 1,
              deliveryAddress: {
                addressId: String(contact.address.addressId),
                contactId: String(contact.contactId),
                countryId: contact.address.country.countryId,
                firstName: contact.firstName,
                middleInit: contact.middleName,
                lastName: contact.lastName,
                companyName: contact.companyName,
                line1Addr: contact.address.addressLine1,
                line2Addr: contact.address.addressLine2,
                cityName: contact.address.city,
                stateId:
                  contact.address.state && contact.address.state.stateDbId
                    ? String(contact.address.state.stateDbId)
                    : "",
                postalCode:
                  contact.address.zipCode || contact.address.postalCode,
                urbanizationCode: contact.address.urbanizationCode,
                refNbr: contact.address.referenceNumber,
                emailAddress: contact.emailAddress
              },
              labelInd: {
                batchInd: "Y",
                shipNotifyInd: ""
              }
            }
          ],
          saveDeliveryToAddressBook: false
        },
        saveLabelInfoAdditionalData: {
          updateCartId: null,
          saveDeliveryToAddressBook: false
        }
      });
      // Call the endpoint
      axios
        .post("/go/cnsrest/addToBatch", dataToSend[i])
        .then((response) => {
          if (
            Object.keys(response.data.fieldErrors).length === 0 &&
            response.data.actionErrors.length === 0
          ) {
            this.props.changeNonNestedFields("batch")(response.data);
            this.emptyBatchFields();
            this.props.setAddToBatchMessage(false);
          } else {
            window.scrollTo(0, 0);
            this.setGlobalError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.props.toggleSpinner(false);
        });
      this.props.setInBatchEdit(false);
    }
  };

  changeBatchLabelInd = (e) => (input) => {
    let newState = update(this.state, {
      fetchServicesData: {
        cnsCartList: {
          0: {
            labelInd: {
              [e]: { $set: input }
            }
          }
        }
      }
    });
    this.setState(newState);
  };

  changeBatchNonNestedFields = (input) => (event) => {
    let newState;
    if (event.target) {
      // When "event" is the entire event object
      const { value, maxLength } = event.target;
      newState = update(this.state, {
        fetchServicesData: {
          cnsCartList: {
            0: {
              deliveryAddress: {
                [input]: { $set: value.slice(0, maxLength) }
              }
            }
          }
        }
      });
    } else {
      // When "event" is the event value
      newState = update(this.state, {
        fetchServicesData: {
          cnsCartList: {
            0: {
              deliveryAddress: {
                [input]: { $set: event }
              }
            }
          }
        }
      });
    }
    this.setState(newState);
  };

  changeBatchSaveAddressBook = (input) => (event) => {
    let newState = update(this.state, {
      fetchServicesData: {
        [input]: { $set: event }
      }
    });
    this.setState(newState);
  };

  validate = (e) => {
    let errorsFound = false;
    let errorObject = {};
    if (
      !this.state.fetchServicesData.cnsCartList[0].deliveryAddress.firstName &&
      !this.state.fetchServicesData.cnsCartList[0].deliveryAddress.companyName
    ) {
      errorObject["deliveryFirstName"] =
        "Please enter a first name. Leave blank if you are using a company name.";
      errorsFound = true;
    } else if (
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.firstName &&
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.firstName
        .length > 0 &&
      !validation.nameAddress.test(
        this.state.fetchServicesData.cnsCartList[0].deliveryAddress.firstName
      )
    ) {
      errorObject["deliveryFirstName"] = "Please enter a valid first name.";
      errorsFound = true;
    }
    if (
      !this.state.fetchServicesData.cnsCartList[0].deliveryAddress.lastName &&
      !this.state.fetchServicesData.cnsCartList[0].deliveryAddress.companyName
    ) {
      errorObject["deliveryLastName"] =
        "Please enter a last name. Leave blank if you are using a company name.";
      errorsFound = true;
    } else if (
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.lastName &&
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.lastName
        .length > 0 &&
      !validation.nameAddress.test(
        this.state.fetchServicesData.cnsCartList[0].deliveryAddress.lastName
      )
    ) {
      errorObject["deliveryLastName"] = "Please enter a valid last name.";
      errorsFound = true;
    }
    if (
      (!this.state.fetchServicesData.cnsCartList[0].deliveryAddress.firstName ||
        !this.state.fetchServicesData.cnsCartList[0].deliveryAddress
          .lastName) &&
      !this.state.fetchServicesData.cnsCartList[0].deliveryAddress.companyName
    ) {
      errorObject["deliveryCompany"] =
        "Please enter a company name. You can leave this field blank if First and Last names have been provided.";
      errorsFound = true;
    } else if (
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.companyName &&
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.companyName
        .length > 0 &&
      !validation.nameAddress.test(
        this.state.fetchServicesData.cnsCartList[0].deliveryAddress.companyName
      )
    ) {
      errorObject["deliveryCompany"] = "Please enter a valid company name.";
      errorsFound = true;
    }
    if (
      !this.state.fetchServicesData.cnsCartList[0].deliveryAddress.line1Addr
    ) {
      errorObject["deliveryStreetAddr"] = "Please enter a street address.";
      errorsFound = true;
    } else if (
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.line1Addr &&
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.line1Addr
        .length > 0 &&
      !validation.nameAddress.test(
        this.state.fetchServicesData.cnsCartList[0].deliveryAddress.line1Addr
      )
    ) {
      errorObject["deliveryStreetAddr"] =
        "Please enter a valid street address.";
      errorsFound = true;
    }
    if (
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.line2Addr &&
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.line2Addr
        .length > 0 &&
      !validation.nameAddress.test(
        this.state.fetchServicesData.cnsCartList[0].deliveryAddress.line2Addr
      )
    ) {
      errorObject["deliveryAptSuiteOther"] =
        "Please enter valid address information.";
      errorsFound = true;
    }
    if (!this.state.fetchServicesData.cnsCartList[0].deliveryAddress.cityName) {
      errorObject["deliveryCity"] = "Please enter a city.";
      errorsFound = true;
    }
    if (
      !this.state.fetchServicesData.cnsCartList[0].deliveryAddress.stateId ||
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.stateId ===
        -1 ||
      this.state.fetchServicesData.cnsCartList[0].deliveryAddress.stateId ===
        "-1"
    ) {
      errorObject["deliveryState"] = "Please select a state.";
      errorsFound = true;
    }
    if (
      !this.state.fetchServicesData.cnsCartList[0].deliveryAddress.postalCode
    ) {
      errorObject["deliveryZip"] = "Please enter ZIP Code™.";
      errorsFound = true;
    }
    if (
      this.state.fetchServicesData.cnsCartList[0].labelInd.shipNotifyInd ===
        "Y" &&
      !this.state.fetchServicesData.cnsCartList[0].deliveryAddress.emailAddress
    ) {
      errorObject["sendRecipientEmail"] = "Please enter email.";
      errorsFound = true;
    }

    this.errors = errorObject;
    this.props.setInputErrors("errorObject")(this.errors);
    return errorsFound;
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.props.setInputErrors("globalError")(combinedErrors);
  };

  addLabelToBatch = (e) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    if (!this.validate()) {
      // Collect the data that will be passed to the endpoint
      let holdEditedCartId = this.state.fetchServicesData.cnsCartList[0]
        .cnsCartId;
      let dataToSend = {};
      dataToSend.fetchServicesData = JSON.parse(
        JSON.stringify(this.state.fetchServicesData)
      );
      dataToSend.fetchServicesData.cnsCartList[0].toNote = this.state.fetchServicesData.cnsCartList[0].deliveryAddress.toNote;
      delete dataToSend.fetchServicesData.cnsCartList[0].deliveryAddress.toNote;
      dataToSend.saveLabelInfoAdditionalData = JSON.parse(
        JSON.stringify(this.state.saveLabelInfoAdditionalData)
      );
      dataToSend.saveLabelInfoAdditionalData.saveDeliveryToAddressBook = this.state.fetchServicesData.saveDeliveryToAddressBook;
      // Call the endpoint
      axios
        .post("/go/cnsrest/addToBatch", dataToSend)
        .then((response) => {
          if (
            Object.keys(response.data.fieldErrors).length === 0 &&
            response.data.actionErrors.length === 0
          ) {
            this.emptyBatchFields();
            if (this.props.inBatchEdit) {
              //ok soooo.....this looks at the array of batch labels and tries to find one. if it finds a match then we remove the item and add the new one else we just add a new one
              //this is for if the user hits edit on a batched label then deletes the label.
              let matches = this.props.configInfo.batch.some(
                (item) => item.cnsCartList[0].cnsCartId === holdEditedCartId
              );
              if (matches === true) {
                this.props.removeBatchItem(e, this.state.editLabelIndex);
                this.props.changeNonNestedFields("batch")(response.data);
              } else {
                this.props.changeNonNestedFields("batch")(response.data);
              }
            } else {
              this.props.changeNonNestedFields("batch")(response.data);
            }
            this.props.setAddToBatchMessage(false);
            this.props.setInBatchEdit(false);
            // Scroll to the top of this section and focus on the batch address
            // that was just added
            document.getElementById("mailing-address").scrollIntoView();
            this.lastAddedBatchItem =
              response.data &&
              response.data.cnsCartList &&
              response.data.cnsCartList[0] &&
              response.data.cnsCartList[0].cnsCartId;
            this.setState({
              focusOnBatchAdd: true
            });
          } else {
            window.scrollTo(0, 0);
            this.setGlobalError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.props.toggleSpinner(false);
        });
    } else {
      this.props.toggleSpinner(false);
      this.setState({
        focusOnError: true
      });
    }
  };

  emptyBatchFields = () => {
    // Empty the input fields
    let newState = update(this.state, {
      fetchServicesData: {
        cnsCartList: {
          0: {
            deliveryAddress: {
              addressId: { $set: "" },
              contactId: { $set: "" },
              countryId: { $set: "840" },
              firstName: { $set: "" },
              middleInit: { $set: "" },
              lastName: { $set: "" },
              companyName: { $set: "" },
              line1Addr: { $set: "" },
              line2Addr: { $set: "" },
              cityName: { $set: "" },
              stateId: { $set: 0 },
              postalCode: { $set: "" },
              urbanizationCode: { $set: "" },
              refNbr: { $set: "" },
              emailAddress: { $set: "" },
              toNote: { $set: "" }
            },
            labelInd: {
              shipNotifyInd: { $set: "N" }
            },
            toNote: { $set: "" }
          },
          saveDeliveryToAddressBook: {
            $set: false
          }
        },
        saveDeliveryToAddressBook: {
          $set: false
        }
      }
    });
    this.setState(newState);
  };

  editBatchLabel = (e, index) => {
    e.preventDefault();
    // Clear any existing errors for the fields provided by the label to be
    // edited
    if (
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress
        .firstName
    ) {
      this.props.clearError("deliveryFirstName");
    }
    if (
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress.lastName
    ) {
      this.props.clearError("deliveryLastName");
    }
    if (
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress
        .firstName &&
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress.lastName
    ) {
      this.props.clearError("deliveryCompany");
    } else if (
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress
        .companyName
    ) {
      this.props.clearError("deliveryFirstName");
      this.props.clearError("deliveryLastName");
      this.props.clearError("deliveryCompany");
    }
    if (
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress
        .line1Addr
    ) {
      this.props.clearError("deliveryStreetAddr");
    }
    // Since this field is optional, remove the error
    this.props.clearError("deliveryAptSuiteOther");
    if (
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress.cityName
    ) {
      this.props.clearError("deliveryCity");
    }
    if (
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress.state &&
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress.state
        .stateDbId
    ) {
      this.props.clearError("deliveryState");
    }
    if (
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress
        .postalCode
    ) {
      this.props.clearError("deliveryZip");
    }
    if (
      this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress
        .emailAddress
    ) {
      this.props.clearError("sendRecipientEmail");
    }
    // Update the fields provided by the label to be edited
    this.props.setInBatchEdit(true);
    let newState = update(this.state, {
      editLabelIndex: { $set: index },
      fetchServicesData: {
        cnsCartList: {
          0: {
            cnsCartId: {
              $set: this.props.configInfo.batch[index].cnsCartList[0].cnsCartId
            },
            batchNbr: {
              $set: this.props.configInfo.batch[index].cnsCartList[0].batchNbr
            },
            deliveryAddress: {
              addressId: {
                $set: this.props.configInfo.batch[index].cnsCartList[0]
                  .deliveryAddress.addressId
              },
              contactId: {
                $set: this.props.configInfo.batch[index].cnsCartList[0]
                  .deliveryAddress.contactId
              },
              countryId: {
                $set: this.props.configInfo.batch[index].cnsCartList[0]
                  .deliveryAddress.countryId
              },
              firstName: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.firstName || ""
              },
              middleInit: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.middleInit || ""
              },
              lastName: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.lastName || ""
              },
              companyName: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.companyName || ""
              },
              line1Addr: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.line1Addr || ""
              },
              line2Addr: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.line2Addr || ""
              },
              cityName: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.cityName || ""
              },
              stateId: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.state &&
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.state.stateDbId
                    ? String(
                        this.props.configInfo.batch[index].cnsCartList[0]
                          .deliveryAddress.state.stateDbId
                      )
                    : ""
              },
              postalCode: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.postalCode || ""
              },
              urbanizationCode: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.urbanizationCode || ""
              },
              refNbr: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.refNbr || ""
              },
              emailAddress: {
                $set:
                  this.props.configInfo.batch[index].cnsCartList[0]
                    .deliveryAddress.emailAddress || ""
              },
              toNote: {
                $set: this.props.configInfo.batch[index].cnsCartList[0].toNote
              }
            },
            labelInd: {
              shipNotifyInd: {
                $set: this.props.configInfo.batch[index].cnsCartList[0].labelInd
                  .shipNotifyInd
              }
            },
            toNote: {
              $set: this.props.configInfo.batch[index].cnsCartList[0].toNote
            }
          },
          saveDeliveryToAddressBook: {
            $set: this.props.configInfo.batch[index].cnsCartList[0]
              .saveDeliveryToAddressBook
          }
        }
      }
    });

    this.setState(newState);
  };

  sendEmailNotification = (e) => {
    if (this.props.batchChecked) {
      this.changeBatchLabelInd("shipNotifyInd")(e.target.checked ? "Y" : "N");
      this.props.changeTrackingNotifications("shipNotifyInd")("N");
    } else {
      this.changeBatchLabelInd("shipNotifyInd")("N");
      this.props.changeTrackingNotifications("shipNotifyInd")(
        e.target.checked ? "Y" : "N"
      );
    }
  };

  mobileClickMoreActions = (e) => {
    let windowWidth = window.innerWidth <= 767;

    if (
      (windowWidth && this.state.mobileShow === false) ||
      (!windowWidth && this.state.mobileShow === false) ||
      (windowWidth && this.state.mobileShow === true)
    ) {
      this.setState((prevState) => {
        return {
          mobileShow: !prevState.mobileShow
        };
      });
    }
  };

  updateHoldForPickupStatus = (e) => {
    this.props.changeTrackingNotifications("holdForPickupInd")(
      e.currentTarget.checked ? "Y" : "N"
    );
    if (e.currentTarget.checked) {
      // Prepopulate the hold for pickup zip code with the destination zip code
      this.props.changeHoldForPickup("holdForPickupZip")(
        this.props.configInfo.deliveryAddress.postalCode
      );
    }
    this.setState({
      postOfficeHoursIconId: undefined
    });
  };

  validateHfpInputs = (e) => {
    let errorFound = false;
    let errorObject = {};
    if (
      !this.props.configInfo.labelHfp.holdForPickupZip ||
      !validation.zip.test(this.props.configInfo.labelHfp.holdForPickupZip)
    ) {
      errorObject["holdForPickupSearchNoZip"] = "Please enter ZIP Code™.";
      this.props.setInputErrors("holdForPickupSearchNoZip")(
        errorObject["holdForPickupSearchNoZip"]
      );
      errorFound = true;
    }

    this.hfpErrors = errorObject;
    return errorFound;
  };

  fetchHfpData = () => {
    if (!this.validateHfpInputs()) {
      this.props.clearError("holdForPickupSearchNoZip");
      this.props.changeHoldForPickup("facilityId")("");
      this.setState({
        locatingPostOffices: true,
        postOfficeHoursIconId: undefined
      });
      axios
        .post(
          "/go/cnsrest/searchHfpLocations?pickupPostalCode=" +
            this.props.configInfo.labelHfp.holdForPickupZip
        )
        .then((response) => {
          this.postOfficeHoursIconRefs = {};
          const poLocations = response.data.poLocations.poLocations;
          for (let i in poLocations) {
            this.postOfficeHoursIconRefs[
              poLocations[i].locationID
            ] = React.createRef();
          }
          this.setState({
            HfpResponse: response,
            fetchedHfpData: true
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({
            locatingPostOffices: false
          });
        });
    } else {
      this.setState({
        focusOnHfpError: true
      });
    }
  };

  clickAddresseeCheckbox = (e) => {
    if (e.currentTarget.checked) {
      this.props.changeHoldForPickup("recipCheckbox")(true);
    } else {
      this.props.changeHoldForPickup("clearAddressee")("");
    }
  };

  clickAddresseeEmailRadio = (e) => {
    this.setState({
      emailAddresseeRadioChecked: true
    });
    this.props.clearError("holdForPickupAddresseeEmail");
    this.props.changeHoldForPickup("recipEmailType")("EMAIL");
  };

  clickAddresseePhoneRadio = (e) => {
    this.setState({
      emailAddresseeRadioChecked: false
    });
    this.props.clearError("holdForPickupAddresseePhone");
    this.props.changeHoldForPickup("recipEmailType")("SMS");
  };

  clickSenderCheckbox = (e) => {
    if (e.currentTarget.checked) {
      this.props.changeHoldForPickup("senderCheckbox")(true);
    } else {
      this.props.changeHoldForPickup("clearSender")("");
    }
  };

  clickSenderEmailRadio = (e) => {
    this.setState({
      emailSenderRadioChecked: true
    });
    this.props.clearError("holdForPickupMeEmail");
    this.props.changeHoldForPickup("senderEmailType")("EMAIL");
  };

  clickSenderPhoneRadio = (e) => {
    this.setState({
      emailSenderRadioChecked: false
    });
    this.props.clearError("holdForPickupMePhone");
    this.props.changeHoldForPickup("senderEmailType")("SMS");
  };

  componentDidMount() {
    let windowWidth = window.innerWidth <= 767;

    if (windowWidth && this.state.mobileShow === false) {
      this.setState({ mobileShow: false });
    } else if (!windowWidth && this.state.mobileShow === false) {
      this.setState({ mobileShow: true });
    }

    if (this.props.configInfo.labelHfp.holdForPickupZip.length > 0) {
      this.fetchHfpData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.cartCount !== prevProps.cartCount ||
      this.props.configInfo.batch.length !== prevProps.configInfo.batch.length
    ) {
      // Update the number of contacts that can be selected for batch orders
      // when the number of cart items or batch items is changed
      this.setState({
        batchCanBeSelected:
          this.batchLengthLimit -
          (this.props.cartCount + this.props.configInfo.batch.length)
      });
    }
    // If the state of the batch order checkbox changes...
    if (this.props.batchChecked !== prevProps.batchChecked) {
      if (this.props.batchChecked) {
        // Copy the relevant non-batch fields to the corresponding batch fields
        this.setState((prevState, prevProps) => {
          let newBatchFields = JSON.parse(
            JSON.stringify(prevState.fetchServicesData)
          );
          const nonBatchCopy = JSON.parse(JSON.stringify(prevProps.configInfo));
          newBatchFields.cnsCartList[0].deliveryAddress = {
            ...newBatchFields.cnsCartList[0].deliveryAddress,
            contactId: nonBatchCopy.deliveryAddress.contactId,
            firstName: nonBatchCopy.deliveryAddress.firstName,
            middleInit: nonBatchCopy.deliveryAddress.middleInit,
            lastName: nonBatchCopy.deliveryAddress.lastName,
            companyName: nonBatchCopy.deliveryAddress.companyName,
            line1Addr: nonBatchCopy.deliveryAddress.line1Addr,
            line2Addr: nonBatchCopy.deliveryAddress.line2Addr,
            cityName: nonBatchCopy.deliveryAddress.cityName,
            stateId: nonBatchCopy.deliveryAddress.stateId,
            postalCode: nonBatchCopy.deliveryAddress.postalCode,
            urbanizationCode: nonBatchCopy.deliveryAddress.urbanizationCode,
            refNbr: nonBatchCopy.deliveryAddress.refNbr,
            emailAddress: nonBatchCopy.deliveryAddress.emailAddress,
            toNote: nonBatchCopy.labelInd.toNote
          };
          newBatchFields.saveDeliveryToAddressBook =
            nonBatchCopy.deliveryAddress.saveDeliveryToAddressBook;
          newBatchFields.cnsCartList[0].labelInd.shipNotifyInd =
            nonBatchCopy.labelInd.shipNotifyInd;
          return {
            fetchServicesData: newBatchFields
          };
        });
      } else {
        // Copy the relevant batch fields to the corresponding non-batch fields
        let newNonBatchFields = JSON.parse(
          JSON.stringify(this.props.configInfo)
        );
        const batchCopy = JSON.parse(
          JSON.stringify(this.state.fetchServicesData)
        );
        newNonBatchFields.deliveryAddress = {
          ...newNonBatchFields.deliveryAddress,
          contactId: batchCopy.cnsCartList[0].deliveryAddress.contactId,
          firstName: batchCopy.cnsCartList[0].deliveryAddress.firstName,
          middleInit: batchCopy.cnsCartList[0].deliveryAddress.middleInit,
          lastName: batchCopy.cnsCartList[0].deliveryAddress.lastName,
          companyName: batchCopy.cnsCartList[0].deliveryAddress.companyName,
          line1Addr: batchCopy.cnsCartList[0].deliveryAddress.line1Addr,
          line2Addr: batchCopy.cnsCartList[0].deliveryAddress.line2Addr,
          cityName: batchCopy.cnsCartList[0].deliveryAddress.cityName,
          stateId: batchCopy.cnsCartList[0].deliveryAddress.stateId,
          postalCode: batchCopy.cnsCartList[0].deliveryAddress.postalCode,
          urbanizationCode: this.state.fetchServicesData.cnsCartList[0]
            .urbanizationCode,
          refNbr: batchCopy.cnsCartList[0].deliveryAddress.refNbr,
          emailAddress: batchCopy.cnsCartList[0].deliveryAddress.emailAddress,
          saveDeliveryToAddressBook: batchCopy.saveDeliveryToAddressBook
        };
        newNonBatchFields.labelInd = {
          ...newNonBatchFields.labelInd,
          shipNotifyInd: batchCopy.cnsCartList[0].labelInd.shipNotifyInd,
          toNote: batchCopy.cnsCartList[0].deliveryAddress.toNote
        };
        this.props.changeDeliveryAddress("switchFromBatch")(newNonBatchFields);
      }
    }
    // Focus on the HFP error
    if (this.state.focusOnHfpError) {
      if (this.hfpErrors["holdForPickupSearchNoZip"]) {
        document.getElementById("pick-zip-code-enter-a11y").focus();
      }
      // Reset fields
      this.setState({
        focusOnHfpError: false
      });
    }
    // Scroll to the top of this section, and
    // focus on the first field with an error
    if (this.state.focusOnError) {
      document.getElementById("mailing-address").scrollIntoView();
      if (this.errors["deliveryFirstName"]) {
        document.getElementById("batchFirstName-a11y").focus();
      } else if (this.errors["deliveryLastName"]) {
        document.getElementById("batchLastName-a11y").focus();
      } else if (this.errors["deliveryCompany"]) {
        document.getElementById("batchCompany-a11y").focus();
      } else if (this.errors["deliveryStreetAddr"]) {
        document.getElementById("batchStreetAddress-a11y").focus();
      } else if (this.errors["deliveryAptSuiteOther"]) {
        document.getElementById("batchappsuitother-a11y").focus();
      } else if (this.errors["deliveryCity"]) {
        document.getElementById("batchCity-a11y").focus();
      } else if (this.errors["deliveryState"]) {
        document.getElementById("batchState-a11y").focus();
      } else if (this.errors["deliveryZip"]) {
        document.getElementById("batchZip-a11y").focus();
      } else if (this.errors["sendRecipientEmail"]) {
        document.getElementById("send-not-email-batch-a11y").focus();
      }
      // Reset fields
      this.setState({
        focusOnError: false
      });
    }
    // Focus on the batch address that was just added from clicking the
    // "Add to Batch" button
    if (this.state.focusOnBatchAdd) {
      if (
        this.props.batchChecked &&
        document.getElementById("batch-item" + this.lastAddedBatchItem)
      ) {
        document.getElementById("batch-item" + this.lastAddedBatchItem).focus();
      }
      this.setState({
        focusOnBatchAdd: false
      });
    }
    // Focus on the input fields after choosing to edit a batch address
    if (this.props.focusOnBatchEdit) {
      if (this.props.batchChecked && this.state.batchCanBeSelected > 0) {
        document.getElementById("batch-flow-inputs").focus();
      }
      this.props.setFocusOnBatchEdit(false);
    }
    // Focus on message indicating the batch order is empty
    // (only after removing all batch addresses)
    if (this.props.focusOnBatchEmpty) {
      if (this.props.batchChecked && this.props.configInfo.batch.length === 0) {
        document.getElementById("batch-order-text-container-id").focus();
      }
      this.props.setFocusOnBatchEmpty(false);
    }
  }

  setPostOfficeHoursIconId = (id, facilityIdClosed) => {
    if (facilityIdClosed) {
      // Only defined if a popover is being closed
      this.postOfficeHoursIconRefs[facilityIdClosed].current.focus();
    }
    this.setState({
      postOfficeHoursIconId: id
    });
  };

  getSelectedLocation = () => {
    const poLocations = this.state.HfpResponse.data.poLocations.poLocations;
    const facilityId = this.state.postOfficeHoursIconId.replace(
      this.postOfficeHoursIconIdPrefix,
      ""
    );
    // Find the PO location that matches the selected facility ID
    for (let i in poLocations) {
      if (poLocations[i].locationID === facilityId) {
        // Find the business hours of this PO location
        for (let j in poLocations[i].serviceHours) {
          if (poLocations[i].serviceHours[j].name === "BUSINESS") {
            return poLocations[i].serviceHours[j].hours;
          }
        }
        break;
      }
    }
    return null;
  };

  renderHfpCount = () => {
    const startingLocation = 1;
    let endingLocation =
      (this.state.HfpResponse.data.holdForPickupLocationsViewBean
        .priorityOnlyList
        ? this.state.HfpResponse.data.holdForPickupLocationsViewBean
            .priorityOnlyList.length
        : 0) +
      (this.state.HfpResponse.data.holdForPickupLocationsViewBean
        .expressOnlyList
        ? this.state.HfpResponse.data.holdForPickupLocationsViewBean
            .expressOnlyList.length
        : 0) +
      (this.state.HfpResponse.data.holdForPickupLocationsViewBean.mutualList
        ? this.state.HfpResponse.data.holdForPickupLocationsViewBean.mutualList
            .length
        : 0);
    const totalLocations = endingLocation;
    return (
      <React.Fragment>
        Showing Results {startingLocation}-{endingLocation} of {totalLocations}
      </React.Fragment>
    );
  };

  renderHfpResults = (listType) => {
    this.hfpListType = listType;
    if (
      this.hfpListType === "priority" &&
      this.state.HfpResponse.data.holdForPickupLocationsViewBean
        .priorityOnlyList
    ) {
      return this.state.HfpResponse.data.holdForPickupLocationsViewBean.priorityOnlyList.map(
        this.renderHfpItem
      );
    } else if (
      this.hfpListType === "express" &&
      this.state.HfpResponse.data.holdForPickupLocationsViewBean.expressOnlyList
    ) {
      return this.state.HfpResponse.data.holdForPickupLocationsViewBean.expressOnlyList.map(
        this.renderHfpItem
      );
    } else if (
      this.hfpListType === "mutual" &&
      this.state.HfpResponse.data.holdForPickupLocationsViewBean.mutualList
    ) {
      return this.state.HfpResponse.data.holdForPickupLocationsViewBean.mutualList.map(
        this.renderHfpItem
      );
    }
  };

  renderHfpItem = (item) => {
    return (
      <li
        key={item.facilityId}
        className="click-ship-individual-address click-ship-address-one"
      >
        <div className="row individual-address-content-wrapper">
          <div className="col-12 col-md-8">
            <input
              id={"pickupAddress" + item.facilityId}
              type="radio"
              className="pickup-address radio-button"
              name="pickup-radio"
              onChange={(e) =>
                this.props.changeHoldForPickup("facilityId")(item.facilityId)
              }
              checked={
                item.facilityId === this.props.configInfo.labelHfp.facilityId
              }
            />
            <p className="click-ship-location">{item.postOfficeName}</p>
            <p className="click-individual-address-detail">
              {item.line1Addr}, <br className="d-md-none" />
              {item.cityName}, {item.stateDbId.statePossAbbrv} {item.postalCode}
            </p>
          </div>
          <div className="col-12 col-md-4">
            <p className="available-services">Available Services</p>
            {this.hfpListType === "priority" ? (
              <p className="click-priority-mail">Priority Mail</p>
            ) : this.hfpListType === "express" ? (
              <p className="click-priority-mail">Priority Mail Express</p>
            ) : this.hfpListType === "mutual" ? (
              <p className="click-priority-mail">
                Priority Mail
                <br />
                Priority Mail Express
              </p>
            ) : null}
            <p className="post-office-hours">
              Post Office Hours{" "}
              <button
                type="button"
                className="info-icon post-office-hours-popover-icon button-link"
                id={this.postOfficeHoursIconIdPrefix + item.facilityId}
                data-trigger="focus"
                data-toggle="popover"
                data-target="#print-labels-receipt-modal"
                data-backdrop="static"
                tabIndex="0"
                data-original-title=""
                title=""
                aria-label="Post Office Hours Icon"
                onClick={(e) =>
                  this.setPostOfficeHoursIconId(e.currentTarget.id)
                }
                ref={this.postOfficeHoursIconRefs[item.facilityId]}
              />
            </p>
          </div>
        </div>
      </li>
    );
  };

  render() {
    return (
      <div className="Step_Two_Container" id="mailing-address">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 required-field">
              <h2 className="normal">
                Step 2: <strong>Delivery Address</strong>
              </h2>
              <span className="error-message">
                {this.props.configInfo.errors.step2}
              </span>
            </div>
          </div>
          <div
            className="row batch-order-container"
            style={{
              backgroundColor: this.props.batchChecked ? "#EDEDED" : ""
            }}
          >
            {this.props.isDomesticDestination() &&
            (this.state.batchCanBeSelected > 0 ||
              this.props.configInfo.batch.length > 0) &&
            !this.props.configInfo.userData.parcelSelectFlag ? (
              // To display the Batch Order checkbox:
              // - the destination country must be domestic
              // - there must either be
              //   room in the cart or
              //   another batch item must have already been added
              // - the user must not be in the parcel select flow
              <div className="col-12 batch-order-option">
                <label
                  id="batch-order-id"
                  className="checkbox-component"
                  htmlFor="batch-order-checkbox"
                >
                  <input
                    type="checkbox"
                    id="batch-order-checkbox"
                    onChange={(e) => this.setBatch(e)}
                    checked={this.props.batchChecked}
                    disabled={
                      this.props.configInfo.labelInd.holdForPickupInd === "Y"
                    }
                  />
                  <span className="checkbox" />
                  Batch Order
                  <p>
                    {this.props.configInfo.labelInd.holdForPickupInd === "Y"
                      ? "Batch orders cannot be used with Hold for Pickup service."
                      : "Ship up to 20 identical packages to multiple addresses."}
                  </p>
                </label>
              </div>
            ) : (
              <div />
            )}
            {this.props.batchChecked &&
            this.props.configInfo.batch.length === 0 ? (
              <div
                id="batch-order-text-container-id"
                className="col-12 batch-order-text-container"
                tabIndex="-1"
              >
                <label className="batch-order-label">This batch is empty</label>
                <p>Use the form below to add addresses to this batch order.</p>
              </div>
            ) : (
              <div />
            )}
            {this.props.batchChecked &&
            this.props.configInfo.batch.length > 0 ? (
              <React.Fragment>
                <div className="col-12 batch-order-list">
                  <p className="batch-size d-md-none">
                    <strong>
                      Batch size:{" "}
                      <span>{this.props.configInfo.batch.length} </span>{" "}
                      addresses
                    </strong>
                  </p>
                  <ol>
                    {this.props.configInfo.batch.map((item, keys) => (
                      <li
                        id={"batch-item" + item.cnsCartList[0].cnsCartId}
                        key={item.cnsCartList[0].cnsCartId}
                        tabIndex="-1"
                      >
                        <div className="row">
                          <div className="col-8 col-md-2 order-1">
                            <span>
                              {
                                item.cnsCartList[0].deliveryAddress
                                  .fullOrCompanyName
                              }
                            </span>
                          </div>
                          <div className="col-8 col-md-5 order-3 order-md-2">
                            <span>
                              {item.cnsCartList[0].deliveryAddress.line1Addr}{" "}
                            </span>
                            <br className="d-md-none" />
                            <span>
                              {item.cnsCartList[0].deliveryAddress.cityName}{" "}
                              {item.cnsCartList[0].deliveryAddress.stateCode}
                            </span>
                          </div>
                          <div className="col-4 col-md-5 order-2 order-md-3 text-right text-md-left">
                            <button
                              type="button"
                              className="inline-link-edit button-link"
                              onClick={(e) => this.editBatchLabel(e, keys)}
                              aria-label="Edit Batch Address"
                            >
                              <strong>Edit</strong>
                            </button>
                            <span className="item-divider"> | </span>
                            <RemoveBatchModal
                              buttonLabel={"Remove"}
                              removeBatch={this.props.removeBatchItem}
                              item={item}
                              keys={keys}
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
                <div className="col-12 batch-remove-all">
                  <div className="button-container">
                    <RemoveAllBatchModal
                      removeAllBatch={this.props.removeAllBatch}
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div />
            )}
          </div>
          {!this.props.batchChecked || this.state.batchCanBeSelected > 0 ? (
            <React.Fragment>
              <div
                className="row use-address-book-container"
                style={{
                  backgroundColor: this.props.batchChecked ? "#EDEDED" : ""
                }}
              >
                <div className="col-12">
                  <p className="click-ship-address-link">
                    <AddressBook
                      buttonLabel={"Use Address Book"}
                      changeDeliveryAddress={
                        this.props.batchChecked
                          ? this.changeDeliveryAddress
                          : this.props.changeDeliveryAddress
                      }
                      changeDeliveryAddressBatch={
                        this.changeDeliveryAddressBatch
                      }
                      addressFieldType={
                        this.props.batchChecked ? "GABBatch" : "GABDelivery"
                      }
                      showInternational={
                        this.props.batchChecked ? "false" : "true"
                      }
                      batchCanBeSelected={this.state.batchCanBeSelected}
                      targetName={
                        this.props.batchChecked
                          ? "btn-open-contact-delivery-batch"
                          : "btn-open-contact-delivery-notBatch"
                      }
                      lookupCodes={this.props.lookupCodes}
                    />
                  </p>
                  <p className="address-standardized-desc">
                    Addresses will be standardized.{" "}
                    <AddressStandardizedPopover targetName="deliveryAddressStandardizedIcon" />
                  </p>
                  <div className="error-message-container required-field">
                    <span className="error-message broker-download-btn-error" />
                  </div>
                </div>
              </div>
              {!this.props.batchChecked ? (
                // Non-batch flow
                <div className="row click-step-two-input-container">
                  <div className="col-12 col-md-11 col-lg-10">
                    <div className="row">
                      <div className="col-12 col-md-6 form-group required-field">
                        <div>
                          <label htmlFor="deliveryCountry">*Country</label>
                          <select
                            id="deliveryCountry"
                            className="form-control country-dropdown dropdown"
                            onChange={(e) =>
                              this.props.changeDeliveryAddress(
                                "stateIdDeliveryAddressChangeCountry"
                              )([
                                e.target.value,
                                e.target.options[e.target.selectedIndex].text
                              ])
                            }
                            value={
                              this.props.configInfo.deliveryAddress.countryId
                            }
                            onBlur={this.props.fetchDropOffLocationsIfNeeded}
                          >
                            {this.props.countryList.map((country) => (
                              <option
                                key={country.countryDbId}
                                value={country.countryDbId}
                              >
                                {country.countryName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-9 col-md-5 form-group required-field" +
                          (this.props.configInfo.errors.deliveryFirstName !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="deliveryFirstName"
                          errorMessage={
                            this.props.configInfo.errors.deliveryFirstName
                          }
                        />
                        <label htmlFor="deliveryFirstName">*First Name</label>
                        <input
                          tabIndex="0"
                          type="text"
                          className="form-control"
                          placeholder="First"
                          id="deliveryFirstName"
                          onChange={(e) => {
                            this.props.changeDeliveryAddress("firstName")(e);
                            this.props.configInfo.errors.deliveryFirstName !==
                            ""
                              ? this.props.clearError("deliveryFirstName")
                              : this.props.clearError("");
                          }}
                          value={
                            this.props.configInfo.deliveryAddress.firstName
                          }
                          maxLength="50"
                        />
                        <span className="error-message">
                          {this.props.configInfo.errors.deliveryFirstName}
                        </span>
                      </div>
                      <div className="col-3 col-md-1 form-group">
                        <label htmlFor="deliveryMI">MI</label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="deliveryMI"
                          className="form-control"
                          value={
                            this.props.configInfo.deliveryAddress.middleInit
                          }
                          onChange={(e) =>
                            this.props.changeDeliveryAddress("middleInit")(e)
                          }
                          maxLength="1"
                        />
                      </div>
                      <div
                        className={
                          "col-12 col-md-6 form-group required-field" +
                          (this.props.configInfo.errors.deliveryLastName !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="deliveryLastName"
                          errorMessage={
                            this.props.configInfo.errors.deliveryLastName
                          }
                        />
                        <label htmlFor="deliveryLastName">*Last Name</label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="deliveryLastName"
                          className="form-control"
                          placeholder="Last"
                          onChange={(e) => {
                            this.props.changeDeliveryAddress("lastName")(e);
                            this.props.configInfo.errors.deliveryLastName !== ""
                              ? this.props.clearError("deliveryLastName")
                              : this.props.clearError("");
                          }}
                          value={this.props.configInfo.deliveryAddress.lastName}
                          maxLength="50"
                        />
                        <span className="error-message">
                          {this.props.configInfo.errors.deliveryLastName}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-12 col-md-6 form-group required-field" +
                          (this.props.configInfo.errors.deliveryCompany !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="deliveryCompany"
                          errorMessage={
                            this.props.configInfo.errors.deliveryCompany
                          }
                        />
                        <label htmlFor="deliveryCompany">
                          Company (Only required if first and last names are not
                          provided.)
                        </label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="deliveryCompany"
                          className="form-control"
                          value={
                            this.props.configInfo.deliveryAddress.companyName
                          }
                          onChange={(e) => {
                            this.props.changeDeliveryAddress("companyName")(e);
                            this.props.configInfo.errors.deliveryCompany !== ""
                              ? this.props.clearError("deliveryCompany")
                              : this.props.clearError("");
                          }}
                          maxLength="50"
                        />
                        <span className="error-message">
                          {this.props.configInfo.errors.deliveryCompany}
                        </span>
                      </div>
                    </div>

                    {this.props.isDomesticDestination() ? (
                      <div>
                        <div className="row dom-address-inputs">
                          <div
                            className={
                              "col-12 col-md-6 form-group required-field" +
                              (this.props.configInfo.errors
                                .deliveryStreetAddr !== ""
                                ? " error"
                                : "")
                            }
                          >
                            <AssistiveError
                              inputId="streetAddress"
                              errorMessage={
                                this.props.configInfo.errors.deliveryStreetAddr
                              }
                            />
                            <label htmlFor="streetAddress">
                              *Street Address
                            </label>
                            <input
                              tabIndex="0"
                              type="text"
                              id="streetAddress"
                              className="form-control"
                              value={
                                this.props.configInfo.deliveryAddress.line1Addr
                              }
                              maxLength="50"
                              onChange={(e) => {
                                this.props.changeDeliveryAddress("line1Addr")(
                                  e
                                );
                                this.props.configInfo.errors
                                  .deliveryStreetAddr !== ""
                                  ? this.props.clearError("deliveryStreetAddr")
                                  : this.props.clearError("");
                              }}
                            />
                            <span className="error-message">
                              {this.props.configInfo.errors.deliveryStreetAddr}
                            </span>
                          </div>
                          <div
                            className={
                              "col-12 col-md-6 form-group" +
                              (this.props.configInfo.errors
                                .deliveryAptSuiteOther !== ""
                                ? " error"
                                : "")
                            }
                          >
                            <AssistiveError
                              inputId="app_suite_other"
                              errorMessage={
                                this.props.configInfo.errors
                                  .deliveryAptSuiteOther
                              }
                            />
                            <label htmlFor="app_suite_other">
                              Apt/Suite/Other
                            </label>
                            <input
                              tabIndex="0"
                              type="text"
                              id="app_suite_other"
                              className="form-control"
                              value={
                                this.props.configInfo.deliveryAddress.line2Addr
                              }
                              maxLength="50"
                              onChange={(e) => {
                                this.props.changeDeliveryAddress("line2Addr")(
                                  e
                                );
                                this.props.configInfo.errors
                                  .deliveryAptSuiteOther !== ""
                                  ? this.props.clearError(
                                      "deliveryAptSuiteOther"
                                    )
                                  : this.props.clearError("");
                              }}
                            />
                            <span className="error-message">
                              {
                                this.props.configInfo.errors
                                  .deliveryAptSuiteOther
                              }
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={
                              "col-12 col-md-6 form-group required-field step-two-city" +
                              (this.props.configInfo.errors.deliveryCity !== ""
                                ? " error"
                                : "")
                            }
                          >
                            <AssistiveError
                              inputId="deliveryCity"
                              errorMessage={
                                this.props.configInfo.errors.deliveryCity
                              }
                            />
                            <label htmlFor="deliveryCity">*City</label>
                            <input
                              tabIndex="0"
                              type="text"
                              id="deliveryCity"
                              className="form-control"
                              value={
                                this.props.configInfo.deliveryAddress.cityName
                              }
                              maxLength="50"
                              onChange={(e) => {
                                this.props.changeDeliveryAddress("cityName")(e);
                                this.props.configInfo.errors.deliveryCity !== ""
                                  ? this.props.clearError("deliveryCity")
                                  : this.props.clearError("");
                              }}
                            />
                            <span className="error-message">
                              {this.props.configInfo.errors.deliveryCity}
                            </span>
                          </div>

                          <div
                            className={
                              "col-6 col-md-3 form-group required-field step-two-state" +
                              (this.props.configInfo.errors.deliveryState !== ""
                                ? " error"
                                : "")
                            }
                          >
                            <AssistiveError
                              inputId="deliveryState"
                              errorMessage={
                                this.props.configInfo.errors.deliveryState
                              }
                            />
                            <label htmlFor="deliveryState">*State</label>
                            <select
                              id="deliveryState"
                              className="form-control state-dropdown dropdown"
                              onChange={(e) => {
                                this.props.changeDeliveryAddress("stateId")(
                                  e.target.value
                                );
                                this.props.configInfo.errors.deliveryState !==
                                ""
                                  ? this.props.clearError("deliveryState")
                                  : this.props.clearError("");
                              }}
                              value={
                                this.props.configInfo.deliveryAddress.stateId
                              }
                            >
                              <StatesTerritoriesList />
                            </select>
                            <span className="error-message">
                              {this.props.configInfo.errors.deliveryState}
                            </span>
                          </div>

                          <div
                            className={
                              "col-6 col-md-3 form-group required-field step-two-city" +
                              (this.props.configInfo.errors.deliveryZip !== ""
                                ? " error"
                                : "")
                            }
                          >
                            <AssistiveError
                              inputId="zipCodeUs"
                              errorMessage={
                                this.props.configInfo.errors.deliveryZip
                              }
                            />
                            <label htmlFor="zipCodeUs">
                              *ZIP Code<sup>™</sup>
                            </label>
                            <input
                              id="zipCodeUs"
                              tabIndex="0"
                              type="text"
                              className="form-control"
                              value={
                                this.props.configInfo.deliveryAddress.postalCode
                              } //was zip5
                              maxLength="10"
                              onChange={(e) => {
                                this.props.changeDeliveryAddress("postalCode")(
                                  e
                                );
                                this.props.configInfo.errors.deliveryZip !== ""
                                  ? this.props.clearError("deliveryZip")
                                  : this.props.clearError("");
                              }}
                              onBlur={this.props.fetchDropOffLocationsIfNeeded}
                            />
                            <span className="error-message">
                              {this.props.configInfo.errors.deliveryZip}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="row intl-address-inputs">
                          <div
                            className={
                              "col-12 col-md-6 form-group required-field" +
                              (this.props.configInfo.errors
                                .deliveryStreetAddr !== ""
                                ? " error"
                                : "")
                            }
                          >
                            <AssistiveError
                              inputId="intDeliveryAddress1"
                              errorMessage={
                                this.props.configInfo.errors.deliveryStreetAddr
                              }
                            />
                            <label htmlFor="intDeliveryAddress1">
                              *Address 1
                            </label>
                            <input
                              tabIndex="0"
                              id="intDeliveryAddress1"
                              type="text"
                              className="form-control"
                              value={
                                this.props.configInfo.deliveryAddress.line1Addr
                              }
                              onChange={(e) => {
                                this.props.changeDeliveryAddress("line1Addr")(
                                  e.target.value
                                );
                                this.props.configInfo.errors
                                  .deliveryStreetAddr !== ""
                                  ? this.props.clearError("deliveryStreetAddr")
                                  : this.props.clearError("");
                              }}
                              maxLength="50"
                            />
                            <span className="error-message">
                              {this.props.configInfo.errors.deliveryStreetAddr}
                            </span>
                          </div>
                          <div
                            className={
                              "col-12 col-md-6 form-group" +
                              (this.props.configInfo.errors
                                .deliveryAptSuiteOther !== ""
                                ? " error"
                                : "")
                            }
                          >
                            <AssistiveError
                              inputId="intDeliveryAddress2"
                              errorMessage={
                                this.props.configInfo.errors
                                  .deliveryAptSuiteOther
                              }
                            />
                            <label htmlFor="intDeliveryAddress2">
                              Address 2
                            </label>
                            <input
                              tabIndex="0"
                              type="text"
                              id="intDeliveryAddress2"
                              className="form-control"
                              value={
                                this.props.configInfo.deliveryAddress.line2Addr
                              }
                              onChange={(e) => {
                                this.props.changeDeliveryAddress("line2Addr")(
                                  e.target.value
                                );
                                this.props.configInfo.errors
                                  .deliveryAptSuiteOther !== ""
                                  ? this.props.clearError(
                                      "deliveryAptSuiteOther"
                                    )
                                  : this.props.clearError("");
                              }}
                              maxLength="50"
                            />
                            <span className="error-message">
                              {
                                this.props.configInfo.errors
                                  .deliveryAptSuiteOther
                              }
                            </span>
                          </div>
                        </div>
                        <div className="row intl-address-inputs">
                          <div
                            className={
                              "col-12 col-md-6 form-group" +
                              (this.props.configInfo.errors.deliveryAddress3 !==
                              ""
                                ? " error"
                                : "")
                            }
                          >
                            <AssistiveError
                              inputId="intDeliveryAddress3"
                              errorMessage={
                                this.props.configInfo.errors.deliveryAddress3
                              }
                            />
                            <label htmlFor="intDeliveryAddress3">
                              Address 3
                            </label>
                            <input
                              id="intDeliveryAddress3"
                              tabIndex="0"
                              type="text"
                              className="form-control"
                              value={
                                this.props.configInfo.deliveryAddress
                                  .line3Addr || ""
                              }
                              onChange={(e) => {
                                this.props.changeDeliveryAddress("line3Addr")(
                                  e.target.value
                                );
                                this.props.configInfo.errors
                                  .deliveryAddress3 !== ""
                                  ? this.props.clearError("deliveryAddress3")
                                  : this.props.clearError("");
                              }}
                              maxLength="50"
                            />
                            <span className="error-message">
                              {this.props.configInfo.errors.deliveryAddress3}
                            </span>
                          </div>
                        </div>
                        <div className="row intl-address-inputs">
                          <div className="col-12 col-md-6 form-group">
                            <label htmlFor="province">Province</label>
                            <input
                              id="province"
                              tabIndex="0"
                              type="text"
                              className="form-control"
                              value={
                                this.props.configInfo.deliveryAddress.province
                              }
                              onChange={(e) =>
                                this.props.changeDeliveryAddress("province")(
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="row intl-address-inputs">
                          <div
                            className={
                              "col-12 col-md-6 form-group required-field" +
                              (this.props.configInfo.errors.deliveryCity !== ""
                                ? " error"
                                : "")
                            }
                          >
                            <AssistiveError
                              inputId="intCity"
                              errorMessage={
                                this.props.configInfo.errors.deliveryCity
                              }
                            />
                            <label htmlFor="intCity">*City</label>
                            <input
                              id="intCity"
                              tabIndex="0"
                              type="text"
                              className="form-control"
                              value={
                                this.props.configInfo.deliveryAddress.cityName
                              }
                              onChange={(e) => {
                                this.props.changeDeliveryAddress("cityName")(
                                  e.target.value
                                );
                                this.props.configInfo.errors.deliveryCity !== ""
                                  ? this.props.clearError("deliveryCity")
                                  : this.props.clearError("");
                              }}
                            />
                            <span className="error-message">
                              {this.props.configInfo.errors.deliveryCity}
                            </span>
                          </div>
                          <div
                            className={
                              "col-12 col-md-6 form-group required" +
                              (this.props.configInfo.errors.deliveryZip !==
                                "" &&
                              this.props.configInfo.deliveryAddress
                                .countryId === "124"
                                ? " error"
                                : "")
                            }
                          >
                            {this.props.configInfo.deliveryAddress.countryId ===
                            "124" ? (
                              <AssistiveError
                                inputId="postalCode"
                                errorMessage={
                                  this.props.configInfo.errors.deliveryZip
                                }
                              />
                            ) : null}
                            {/* If canada then make required */}
                            {this.props.configInfo.deliveryAddress.countryId ===
                            "124" ? (
                              <label htmlFor="postalCode">*Postal Code</label>
                            ) : (
                              <label htmlFor="postalCode">Postal Code</label>
                            )}
                            <input
                              id="postalCode"
                              tabIndex="0"
                              type="text"
                              className="form-control"
                              value={
                                this.props.configInfo.deliveryAddress.postalCode
                              }
                              onChange={(e) => {
                                this.props.changeDeliveryAddress("postalCode")(
                                  e.target.value
                                );
                                this.props.configInfo.errors.deliveryZip !==
                                  "" &&
                                this.props.configInfo.deliveryAddress
                                  .countryId === "124"
                                  ? this.props.clearError("deliveryZip")
                                  : this.props.clearError("");
                              }}
                            />
                            {this.props.configInfo.deliveryAddress.countryId ===
                            "124" ? (
                              <span className="error-message">
                                {this.props.configInfo.errors.deliveryZip}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row intl-address-inputs">
                          <div className="col-12 col-md-6 form-group">
                            <label htmlFor="intPhoneNumber">Phone Number</label>
                            <input
                              id="intPhoneNumber"
                              tabIndex="0"
                              type="text"
                              className="form-control"
                              placeholder="(000) 000-0000"
                              value={
                                this.props.configInfo.deliveryAddress.phoneNbr
                              }
                              onChange={(e) =>
                                this.props.changeDeliveryAddress("phoneNbr")(
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {this.props.isDomesticDestination() &&
                    this.props.configInfo.deliveryAddress.stateId === "49" ? (
                      <div className="row">
                        <div className="col-12 col-md-6 form-group urbanization-code-input">
                          <label htmlFor="urban">Urbanization Code</label>
                          <input
                            id="urban"
                            tabIndex="0"
                            type="text"
                            className="form-control"
                            maxLength="50"
                            value={
                              this.props.configInfo.deliveryAddress
                                .urbanizationCode
                            }
                            onChange={(e) =>
                              this.props.changeDeliveryAddress(
                                "urbanizationCode"
                              )(e)
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}

                    <div className="row">
                      <div className="col-12 col-md-6 extend form-group">
                        <label htmlFor="refNumberDel">
                          Reference Number (This number is for your reference
                          only.)
                        </label>
                        <input
                          id="refNumberDel"
                          tabIndex="0"
                          type="text"
                          className="form-control"
                          placeholder="You may enter up to 10 characters"
                          onChange={(e) =>
                            this.props.changeDeliveryAddress("refNbr")(e)
                          }
                          value={this.props.configInfo.deliveryAddress.refNbr}
                          maxLength="10"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                // Batch flow
                <div
                  id="batch-flow-inputs"
                  className="row click-step-two-input-container"
                  style={{
                    backgroundColor: "#EDEDED"
                  }}
                  tabIndex="-1"
                >
                  <div className="col-12 col-md-11 col-lg-10">
                    <div className="row">
                      <div className="col-12 col-md-6 form-group required-field" />
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-9 col-md-5 form-group required-field" +
                          (this.props.configInfo.errors.deliveryFirstName !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="batchFirstName"
                          errorMessage={
                            this.props.configInfo.errors.deliveryFirstName
                          }
                        />
                        <label htmlFor="batchFirstName">*First Name</label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="batchFirstName"
                          className="form-control"
                          placeholder="First"
                          onChange={(e) => {
                            this.changeDeliveryAddress("firstName")(e);
                            this.props.configInfo.errors.deliveryFirstName !==
                            ""
                              ? this.props.clearError("deliveryFirstName")
                              : this.props.clearError("");
                          }}
                          value={
                            this.state.fetchServicesData.cnsCartList[0]
                              .deliveryAddress.firstName
                          }
                          maxLength="50"
                        />
                        <span className="error-message">
                          {this.props.configInfo.errors.deliveryFirstName}
                        </span>
                      </div>
                      <div className="col-3 col-md-1 form-group">
                        <label htmlFor="batchMI">MI</label>
                        <input
                          id="batchMI"
                          tabIndex="0"
                          type="text"
                          className="form-control"
                          value={
                            this.state.fetchServicesData.cnsCartList[0]
                              .deliveryAddress.middleInit
                          }
                          onChange={(e) =>
                            this.changeDeliveryAddress("middleInit")(e)
                          }
                          maxLength="1"
                        />
                      </div>
                      <div
                        className={
                          "col-12 col-md-6 form-group required-field" +
                          (this.props.configInfo.errors.deliveryLastName !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="batchLastName"
                          errorMessage={
                            this.props.configInfo.errors.deliveryLastName
                          }
                        />
                        <label htmlFor="batchLastName">*Last Name</label>
                        <input
                          id="batchLastName"
                          tabIndex="0"
                          type="text"
                          className="form-control"
                          placeholder="Last"
                          onChange={(e) => {
                            this.changeDeliveryAddress("lastName")(e);
                            this.props.configInfo.errors.deliveryLastName !== ""
                              ? this.props.clearError("deliveryLastName")
                              : this.props.clearError("");
                          }}
                          value={
                            this.state.fetchServicesData.cnsCartList[0]
                              .deliveryAddress.lastName
                          }
                          maxLength="50"
                        />
                        <span className="error-message">
                          {this.props.configInfo.errors.deliveryLastName}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-12 col-md-6 form-group required-field" +
                          (this.props.configInfo.errors.deliveryCompany !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="batchCompany"
                          errorMessage={
                            this.props.configInfo.errors.deliveryCompany
                          }
                        />
                        <label htmlFor="batchCompany">
                          Company (Only required if first and last names are not
                          provided.)
                        </label>
                        <input
                          id="batchCompany"
                          tabIndex="0"
                          type="text"
                          className="form-control"
                          value={
                            this.state.fetchServicesData.cnsCartList[0]
                              .deliveryAddress.companyName
                          }
                          maxLength="50"
                          onChange={(e) => {
                            this.changeDeliveryAddress("companyName")(e);
                            this.props.configInfo.errors.deliveryCompany !== ""
                              ? this.props.clearError("deliveryCompany")
                              : this.props.clearError("");
                          }}
                        />
                        <span className="error-message">
                          {this.props.configInfo.errors.deliveryCompany}
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className="row dom-address-inputs">
                        <div
                          className={
                            "col-12 col-md-6 form-group required-field" +
                            (this.props.configInfo.errors.deliveryStreetAddr !==
                            ""
                              ? " error"
                              : "")
                          }
                        >
                          <AssistiveError
                            inputId="batchStreetAddress"
                            errorMessage={
                              this.props.configInfo.errors.deliveryStreetAddr
                            }
                          />
                          <label htmlFor="batchStreetAddress">
                            *Street Address
                          </label>
                          <input
                            id="batchStreetAddress"
                            tabIndex="0"
                            type="text"
                            className="form-control"
                            value={
                              this.state.fetchServicesData.cnsCartList[0]
                                .deliveryAddress.line1Addr
                            }
                            maxLength="50"
                            onChange={(e) => {
                              this.changeDeliveryAddress("line1Addr")(e);
                              this.props.configInfo.errors
                                .deliveryStreetAddr !== ""
                                ? this.props.clearError("deliveryStreetAddr")
                                : this.props.clearError("");
                            }}
                          />
                          <span className="error-message">
                            {this.props.configInfo.errors.deliveryStreetAddr}
                          </span>
                        </div>
                        <div
                          className={
                            "col-12 col-md-6 form-group" +
                            (this.props.configInfo.errors
                              .deliveryAptSuiteOther !== ""
                              ? " error"
                              : "")
                          }
                        >
                          <AssistiveError
                            inputId="batchappsuitother"
                            errorMessage={
                              this.props.configInfo.errors.deliveryAptSuiteOther
                            }
                          />
                          <label htmlFor="batchappsuitother">
                            Apt/Suite/Other
                          </label>
                          <input
                            id="batchappsuitother"
                            tabIndex="0"
                            type="text"
                            className="form-control"
                            value={
                              this.state.fetchServicesData.cnsCartList[0]
                                .deliveryAddress.line2Addr
                            }
                            maxLength="50"
                            onChange={(e) => {
                              this.changeDeliveryAddress("line2Addr")(e);
                              this.props.configInfo.errors
                                .deliveryAptSuiteOther !== ""
                                ? this.props.clearError("deliveryAptSuiteOther")
                                : this.props.clearError("");
                            }}
                          />
                          <span className="error-message">
                            {this.props.configInfo.errors.deliveryAptSuiteOther}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className={
                            "col-12 col-md-6 form-group required-field step-two-city" +
                            (this.props.configInfo.errors.deliveryCity !== ""
                              ? " error"
                              : "")
                          }
                        >
                          <AssistiveError
                            inputId="batchCity"
                            errorMessage={
                              this.props.configInfo.errors.deliveryCity
                            }
                          />
                          <label htmlFor="batchCity">*City</label>
                          <input
                            id="batchCity"
                            tabIndex="0"
                            type="text"
                            className="form-control"
                            value={
                              this.state.fetchServicesData.cnsCartList[0]
                                .deliveryAddress.cityName
                            }
                            maxLength="50"
                            onChange={(e) => {
                              this.changeDeliveryAddress("cityName")(e);
                              this.props.configInfo.errors.deliveryCity !== ""
                                ? this.props.clearError("deliveryCity")
                                : this.props.clearError("");
                            }}
                          />
                          <span className="error-message">
                            {this.props.configInfo.errors.deliveryCity}
                          </span>
                        </div>

                        <div
                          className={
                            "col-6 col-md-3 form-group required-field step-two-state" +
                            (this.props.configInfo.errors.deliveryState !== ""
                              ? " error"
                              : "")
                          }
                        >
                          <AssistiveError
                            inputId="batchState"
                            errorMessage={
                              this.props.configInfo.errors.deliveryState
                            }
                          />
                          <label htmlFor="batchState">*State</label>
                          <select
                            id="batchState"
                            className="form-control state-dropdown dropdown"
                            onChange={(e) => {
                              this.changeDeliveryAddress("stateId")(
                                e.target.value
                              );
                              this.props.configInfo.errors.deliveryState !== ""
                                ? this.props.clearError("deliveryState")
                                : this.props.clearError("");
                            }}
                            value={
                              this.state.fetchServicesData.cnsCartList[0]
                                .deliveryAddress.stateId
                            }
                          >
                            <StatesTerritoriesList />
                          </select>
                          <span className="error-message">
                            {this.props.configInfo.errors.deliveryState}
                          </span>
                        </div>

                        <div
                          className={
                            "col-6 col-md-3 form-group required-field step-two-city" +
                            (this.props.configInfo.errors.deliveryZip !== ""
                              ? " error"
                              : "")
                          }
                        >
                          <AssistiveError
                            inputId="batchZip"
                            errorMessage={
                              this.props.configInfo.errors.deliveryZip
                            }
                          />
                          <label htmlFor="batchZip">
                            *ZIP Code<sup>™</sup>
                          </label>
                          <input
                            id="batchZip"
                            tabIndex="0"
                            type="text"
                            className="form-control"
                            value={
                              this.state.fetchServicesData.cnsCartList[0]
                                .deliveryAddress.postalCode
                            } //was zip5
                            maxLength="10"
                            onChange={(e) => {
                              this.changeDeliveryAddress("postalCode")(e);
                              this.props.configInfo.errors.deliveryZip !== ""
                                ? this.props.clearError("deliveryZip")
                                : this.props.clearError("");
                            }}
                          />
                          <span className="error-message">
                            {this.props.configInfo.errors.deliveryZip}
                          </span>
                        </div>
                      </div>
                    </div>

                    {this.state.fetchServicesData.cnsCartList[0].deliveryAddress
                      .stateId === "49" ? (
                      <div className="row">
                        <div className="col-12 col-md-6 form-group urbanization-code-input">
                          <label htmlFor="batchUrban">Urbanization Code</label>
                          <input
                            id="batchUrban"
                            tabIndex="0"
                            type="text"
                            className="form-control"
                            maxLength="50"
                            value={
                              this.state.fetchServicesData.cnsCartList[0]
                                .deliveryAddress.urbanizationCode
                            }
                            onChange={(e) =>
                              this.changeDeliveryAddress("urbanizationCode")(e)
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}

                    <div className="row">
                      <div className="col-12 col-md-6 extend form-group">
                        <label htmlFor="refNumberBatch">
                          Reference Number (This number is for your reference
                          only.)
                        </label>
                        <input
                          id="refNumberBatch"
                          tabIndex="0"
                          type="text"
                          className="form-control"
                          placeholder="You may enter up to 10 characters"
                          onChange={(e) =>
                            this.changeDeliveryAddress("refNbr")(e)
                          }
                          value={
                            this.state.fetchServicesData.cnsCartList[0]
                              .deliveryAddress.refNbr
                          }
                          maxLength="10"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="row more-actions-container"
                style={{
                  backgroundColor: this.props.batchChecked ? "#EDEDED" : ""
                }}
              >
                <div className="col-12 check-boxes">
                  <div className="checkbox-wrap">
                    <div className="row">
                      {!this.props.batchChecked ? (
                        <div className="col-12">
                          <label
                            className="checkbox-component"
                            htmlFor="first-checkbox"
                          >
                            <input
                              type="checkbox"
                              id="first-checkbox"
                              onChange={(e) =>
                                this.props.changeDeliveryAddress(
                                  "saveDeliveryToAddressBook"
                                )(
                                  !this.props.configInfo.deliveryAddress
                                    .saveDeliveryToAddressBook
                                )
                              }
                              checked={
                                this.props.configInfo.deliveryAddress
                                  .saveDeliveryToAddressBook
                              }
                            />
                            <span className="checkbox" />
                            {"Save to my address book."}
                          </label>
                        </div>
                      ) : (
                        <div className="col-12">
                          <label
                            className="checkbox-component"
                            htmlFor="first-checkbox-batch"
                          >
                            <input
                              type="checkbox"
                              id="first-checkbox-batch"
                              onChange={(e) =>
                                this.changeBatchSaveAddressBook(
                                  "saveDeliveryToAddressBook"
                                )(
                                  !this.state.fetchServicesData
                                    .saveDeliveryToAddressBook
                                )
                              }
                              checked={
                                this.state.fetchServicesData
                                  .saveDeliveryToAddressBook
                              }
                            />
                            <span className="checkbox" />
                            {"Save to my address book."}
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <h2
                          className={
                            "normal more-actions-header" +
                            (this.state.mobileShow ? " active" : "")
                          }
                          onClick={this.mobileClickMoreActions}
                        >
                          <strong>More Actions</strong>
                        </h2>
                        <div className="row email-notification-option">
                          <div className="col-12">
                            <label
                              id="send-recipient-email-id"
                              className="checkbox-component"
                              htmlFor="send-not-checkbox"
                            >
                              <input
                                type="checkbox"
                                id="send-not-checkbox"
                                onChange={this.sendEmailNotification}
                                checked={
                                  this.props.batchChecked
                                    ? this.state.fetchServicesData
                                        .cnsCartList[0].labelInd
                                        .shipNotifyInd === "Y"
                                    : this.props.configInfo.labelInd
                                        .shipNotifyInd === "Y"
                                }
                              />
                              <span className="checkbox" />
                              Send recipient an email notification.
                            </label>

                            {!this.props.batchChecked &&
                            this.props.configInfo.labelInd.shipNotifyInd ===
                              "Y" ? (
                              <div className="row email-notification-wrapper">
                                <div
                                  className={
                                    "col-9 col-md-5 form-group required-field" +
                                    (this.props.configInfo.errors
                                      .sendRecipientEmail !== ""
                                      ? " error"
                                      : "")
                                  }
                                >
                                  <AssistiveError
                                    inputId="send-not-email"
                                    errorMessage={
                                      this.props.configInfo.errors
                                        .sendRecipientEmail
                                    }
                                  />
                                  <label htmlFor="send-not-email">*Email</label>
                                  <input
                                    id="send-not-email"
                                    tabIndex="0"
                                    type="text"
                                    className="form-control"
                                    placeholder="email123@email.com"
                                    onChange={(e) => {
                                      this.props.changeDeliveryAddress(
                                        "emailAddress"
                                      )(e);
                                      this.props.configInfo.errors
                                        .sendRecipientEmail !== ""
                                        ? this.props.clearError(
                                            "sendRecipientEmail"
                                          )
                                        : this.props.clearError("");
                                    }}
                                    value={
                                      this.props.configInfo.deliveryAddress
                                        .emailAddress
                                    }
                                    maxLength="50"
                                  />
                                  <span className="error-message">
                                    {
                                      this.props.configInfo.errors
                                        .sendRecipientEmail
                                    }
                                  </span>
                                </div>
                                <div className="col-12 col-md-9 form-group enter-any-additional-instructions">
                                  <div className="add-a-message">
                                    <label
                                      htmlFor="click-ship-message-box"
                                      className="inputLabel"
                                    >
                                      Add a message
                                    </label>
                                    <textarea
                                      className="form-control"
                                      id="click-ship-message-box"
                                      rows="5"
                                      maxLength="125"
                                      onChange={(e) =>
                                        this.props.changeTrackingNotifications(
                                          "toNote"
                                        )(e.target.value)
                                      }
                                      value={
                                        this.props.configInfo.labelInd.toNote
                                      }
                                    />
                                    <div id="click-ship-message-counter">
                                      {this.props.configInfo.labelInd.toNote
                                        ? this.props.configInfo.labelInd.toNote
                                            .length
                                        : 0}{" "}
                                      / 125
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : this.props.batchChecked &&
                              this.state.fetchServicesData.cnsCartList[0]
                                .labelInd.shipNotifyInd === "Y" ? (
                              <div className="row email-notification-wrapper">
                                <div
                                  className={
                                    "col-9 col-md-5 form-group required-field" +
                                    (this.props.configInfo.errors
                                      .sendRecipientEmail !== ""
                                      ? " error"
                                      : "")
                                  }
                                >
                                  <AssistiveError
                                    inputId="send-not-email-batch"
                                    errorMessage={
                                      this.props.configInfo.errors
                                        .sendRecipientEmail
                                    }
                                  />
                                  <label htmlFor="send-not-email-batch">
                                    *Email
                                  </label>{" "}
                                  <input
                                    id="send-not-email-batch"
                                    tabIndex="0"
                                    type="text"
                                    className="form-control"
                                    placeholder="email123@email.com"
                                    onChange={(e) => {
                                      this.changeDeliveryAddress(
                                        "emailAddress"
                                      )(e);
                                      this.props.configInfo.errors
                                        .sendRecipientEmail !== ""
                                        ? this.props.clearError(
                                            "sendRecipientEmail"
                                          )
                                        : this.props.clearError("");
                                    }}
                                    value={
                                      this.state.fetchServicesData
                                        .cnsCartList[0].deliveryAddress
                                        .emailAddress
                                    }
                                    maxLength="50"
                                  />
                                  <span className="error-message">
                                    {
                                      this.props.configInfo.errors
                                        .sendRecipientEmail
                                    }
                                  </span>
                                </div>
                                <div className="col-12 col-md-9 form-group enter-any-additional-instructions">
                                  <div className="add-a-message">
                                    <label
                                      htmlFor="click-ship-message-box-batch"
                                      className="inputLabel"
                                    >
                                      Add a message
                                    </label>
                                    <textarea
                                      className="form-control"
                                      id="click-ship-message-box-batch"
                                      rows="5"
                                      maxLength="125"
                                      onChange={(e) =>
                                        this.changeBatchNonNestedFields(
                                          "toNote"
                                        )(e)
                                      }
                                      value={
                                        this.state.fetchServicesData
                                          .cnsCartList[0].deliveryAddress.toNote
                                      }
                                    />
                                    <div id="click-ship-message-counter">
                                      {this.state.fetchServicesData
                                        .cnsCartList[0].deliveryAddress.toNote
                                        ? this.state.fetchServicesData
                                            .cnsCartList[0].deliveryAddress
                                            .toNote.length
                                        : 0}{" "}
                                      / 125
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div />
                            )}
                          </div>
                        </div>
                        {!this.props.batchChecked ? (
                          <div className="row hold-for-pickup-option">
                            <div className="col-12">
                              <div className="row hold-for-pickup-option">
                                <div className="col-12">
                                  <label
                                    id="hold-for-pickup-id"
                                    className="checkbox-component"
                                    htmlFor="hold-for-pickup-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="hold-for-pickup-checkbox"
                                      onChange={this.updateHoldForPickupStatus}
                                      checked={
                                        this.props.configInfo.labelInd
                                          .holdForPickupInd === "Y"
                                      }
                                    />
                                    <span className="checkbox" />
                                    Hold for Pick Up at a Post Office™.
                                    <p className="hold-for-pickup-desc">
                                      Find a nearby post office location where
                                      the shipment will be held until the
                                      recipient can pick it up.
                                    </p>
                                  </label>

                                  {this.props.configInfo.labelInd
                                    .holdForPickupInd === "Y" ? (
                                    <div className="row form-group hold-pickup-content-wrapper">
                                      <div className="col-12">
                                        <div className="row">
                                          <div
                                            id="pick-zip-code-wrapper-id"
                                            className={
                                              "pick-zip-code-wrapper col-6 col-md-4 col-lg-3" +
                                              (this.props.configInfo.errors
                                                .holdForPickupSearchNoZip !== ""
                                                ? " error"
                                                : "")
                                            }
                                          >
                                            <AssistiveError
                                              inputId="pick-zip-code-enter"
                                              errorMessage={
                                                this.props.configInfo.errors
                                                  .holdForPickupSearchNoZip
                                              }
                                            />
                                            <label
                                              htmlFor="pick-zip-code-enter"
                                              className="inputLabel desktop"
                                            >
                                              *Pick a ZIP Code<sup>™</sup>
                                            </label>
                                            <input
                                              tabIndex="0"
                                              type="text"
                                              className="form-control"
                                              id="pick-zip-code-enter"
                                              minLength="2"
                                              maxLength="10"
                                              onChange={(e) => {
                                                this.props.changeHoldForPickup(
                                                  "holdForPickupZip"
                                                )(e);
                                                this.props.configInfo.errors
                                                  .holdForPickupSearchNoZip !==
                                                ""
                                                  ? this.props.clearError(
                                                      "holdForPickupSearchNoZip"
                                                    )
                                                  : this.props.clearError("");
                                              }}
                                              onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                  this.fetchHfpData();
                                                }
                                              }}
                                              value={
                                                this.props.configInfo.labelHfp
                                                  .holdForPickupZip
                                              }
                                            />
                                            <button
                                              type="button"
                                              aria-label="Search for Hold for Pick Up locations"
                                              className="button-link pick-zip-code-cursor-icon"
                                              onClick={this.fetchHfpData}
                                            />
                                            <span className="error-message">
                                              {
                                                this.props.configInfo.errors
                                                  .holdForPickupSearchNoZip
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        {this.state.locatingPostOffices ? (
                                          <WhiteSpinner
                                            topLine={"Locating Post Offices"}
                                            bottomLine={
                                              "Please do not refresh the page."
                                            }
                                          />
                                        ) : (
                                          <React.Fragment />
                                        )}

                                        {this.state.fetchedHfpData ? (
                                          <div className="row">
                                            <div className="col-12 col-md-11 col-lg-10 click-ship-address-box-wrapper">
                                              {(this.state.HfpResponse.data
                                                .holdForPickupLocationsViewBean
                                                .priorityOnlyList &&
                                                this.state.HfpResponse.data
                                                  .holdForPickupLocationsViewBean
                                                  .priorityOnlyList.length >
                                                  0) ||
                                              (this.state.HfpResponse.data
                                                .holdForPickupLocationsViewBean
                                                .expressOnlyList &&
                                                this.state.HfpResponse.data
                                                  .holdForPickupLocationsViewBean
                                                  .expressOnlyList > 0) ||
                                              (this.state.HfpResponse.data
                                                .holdForPickupLocationsViewBean
                                                .mutualList &&
                                                this.state.HfpResponse.data
                                                  .holdForPickupLocationsViewBean
                                                  .mutualList.length > 0) ? (
                                                <React.Fragment>
                                                  <p className="click-ship-address-box-results">
                                                    <strong>
                                                      {this.renderHfpCount()}
                                                    </strong>
                                                  </p>
                                                  <ul className="click-ship-address-box-container">
                                                    {this.renderHfpResults(
                                                      "mutual"
                                                    )}
                                                    {this.renderHfpResults(
                                                      "priority"
                                                    )}
                                                    {this.renderHfpResults(
                                                      "express"
                                                    )}
                                                    {this.state
                                                      .postOfficeHoursIconId !==
                                                    undefined ? (
                                                      <PostOfficeHoursPopover
                                                        targetName={
                                                          this.state
                                                            .postOfficeHoursIconId
                                                        }
                                                        setPostOfficeHoursIconId={
                                                          this
                                                            .setPostOfficeHoursIconId
                                                        }
                                                        poLocationHours={this.getSelectedLocation()}
                                                        infoIconFacilityId={this.state.postOfficeHoursIconId.replace(
                                                          this
                                                            .postOfficeHoursIconIdPrefix,
                                                          ""
                                                        )}
                                                      />
                                                    ) : null}
                                                  </ul>
                                                </React.Fragment>
                                              ) : (
                                                <p className="click-ship-address-box-results">
                                                  <strong>
                                                    No results found. Please try
                                                    another ZIP code<sup>™</sup>
                                                    .
                                                  </strong>
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          <React.Fragment />
                                        )}

                                        <div className="row">
                                          <div className="col-12 check-boxes">
                                            <div className="checkbox-wrap">
                                              <div className="row">
                                                <div className="col-12">
                                                  <label
                                                    id="notify-addressee-id"
                                                    className="checkbox-component"
                                                    htmlFor="notify-addressee-checkbox"
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      id="notify-addressee-checkbox"
                                                      checked={
                                                        this.props.configInfo
                                                          .labelHfp
                                                          .recipCheckbox
                                                      }
                                                      onClick={
                                                        this
                                                          .clickAddresseeCheckbox
                                                      }
                                                    />
                                                    <span className="checkbox" />
                                                    Notify addressee.
                                                  </label>
                                                  {this.props.configInfo
                                                    .labelHfp.recipCheckbox ? (
                                                    <div className="notify-addressee-wrapper required-field">
                                                      <div className="radio-container notify-addressee-email-wrapper required-field">
                                                        <input
                                                          id="click-email-addressee-radio"
                                                          type="radio"
                                                          className="radio-button"
                                                          name="notify-addressee-radio"
                                                          checked={
                                                            this.state
                                                              .emailAddresseeRadioChecked
                                                          }
                                                          onChange={
                                                            this
                                                              .clickAddresseeEmailRadio
                                                          }
                                                        />
                                                        <label htmlFor="click-email-addressee-radio">
                                                          Email
                                                        </label>
                                                        {this.state
                                                          .emailAddresseeRadioChecked ? (
                                                          <div
                                                            className={
                                                              "form-group click-email-input required-field" +
                                                              (this.props
                                                                .configInfo
                                                                .errors
                                                                .holdForPickupAddresseeEmail !==
                                                              ""
                                                                ? " error"
                                                                : "")
                                                            }
                                                          >
                                                            <AssistiveError
                                                              inputId="holdForPickupAddresseeEmailId"
                                                              errorMessage={
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupAddresseeEmail
                                                              }
                                                            />
                                                            <input
                                                              tabIndex="0"
                                                              id="holdForPickupAddresseeEmailId"
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="email123@email.com"
                                                              value={
                                                                this.props
                                                                  .configInfo
                                                                  .labelHfp
                                                                  .recipEmailText
                                                              }
                                                              onChange={(e) => {
                                                                this.props.changeHoldForPickup(
                                                                  "recipEmailText"
                                                                )(e);
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupAddresseeEmail !==
                                                                ""
                                                                  ? this.props.clearError(
                                                                      "holdForPickupAddresseeEmail"
                                                                    )
                                                                  : this.props.clearError(
                                                                      ""
                                                                    );
                                                              }}
                                                              maxLength="50"
                                                            />
                                                            <span className="error-message">
                                                              {
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupAddresseeEmail
                                                              }
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <div />
                                                        )}
                                                      </div>
                                                      <div className="radio-container notify-addressee-text-message-wrapper required-field">
                                                        <input
                                                          id="click-text-message-addressee-radio"
                                                          type="radio"
                                                          className="radio-button"
                                                          name="notify-addressee-radio"
                                                          checked={
                                                            !this.state
                                                              .emailAddresseeRadioChecked
                                                          }
                                                          onChange={
                                                            this
                                                              .clickAddresseePhoneRadio
                                                          }
                                                        />
                                                        <label htmlFor="click-text-message-addressee-radio">
                                                          Text Message{" "}
                                                          <SmsGateWay targetName="notifyAddresseeTextMessageIcon" />
                                                        </label>
                                                        {!this.state
                                                          .emailAddresseeRadioChecked ? (
                                                          <div
                                                            className={
                                                              "form-group click-text-message-input required-field" +
                                                              (this.props
                                                                .configInfo
                                                                .errors
                                                                .holdForPickupAddresseePhone !==
                                                              ""
                                                                ? " error"
                                                                : "")
                                                            }
                                                          >
                                                            <AssistiveError
                                                              inputId="holdForPickupAddresseePhoneId"
                                                              errorMessage={
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupAddresseePhone
                                                              }
                                                            />
                                                            <input
                                                              tabIndex="0"
                                                              id="holdForPickupAddresseePhoneId"
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Phone Number"
                                                              onChange={(e) => {
                                                                this.props.changeHoldForPickup(
                                                                  "recipPhoneText"
                                                                )(e);
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupAddresseePhone !==
                                                                ""
                                                                  ? this.props.clearError(
                                                                      "holdForPickupAddresseePhone"
                                                                    )
                                                                  : this.props.clearError(
                                                                      ""
                                                                    );
                                                              }}
                                                              value={
                                                                this.props
                                                                  .configInfo
                                                                  .labelHfp
                                                                  .recipPhoneText
                                                              }
                                                              maxLength="50"
                                                            />
                                                            <span className="error-message">
                                                              {
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupAddresseePhone
                                                              }
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <div />
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div />
                                                  )}

                                                  <div />
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-12">
                                                  <label
                                                    id="notify-me-id"
                                                    className="checkbox-component"
                                                    htmlFor="notify-me-checkbox"
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      id="notify-me-checkbox"
                                                      checked={
                                                        this.props.configInfo
                                                          .labelHfp
                                                          .senderCheckbox
                                                      }
                                                      onClick={
                                                        this.clickSenderCheckbox
                                                      }
                                                    />
                                                    <span className="checkbox" />
                                                    Notify me.
                                                  </label>
                                                  {this.props.configInfo
                                                    .labelHfp.senderCheckbox ? (
                                                    <div className="notify-me-wrapper required-field">
                                                      <div className="radio-container notify-me-email-wrapper required-field">
                                                        <input
                                                          id="click-email-me-radio"
                                                          type="radio"
                                                          className="radio-button"
                                                          name="notify-me-radio"
                                                          checked={
                                                            this.state
                                                              .emailSenderRadioChecked
                                                          }
                                                          onChange={
                                                            this
                                                              .clickSenderEmailRadio
                                                          }
                                                        />
                                                        <label htmlFor="click-email-me-radio">
                                                          Email
                                                        </label>
                                                        {this.state
                                                          .emailSenderRadioChecked ? (
                                                          <div
                                                            className={
                                                              "form-group click-email-input required-field" +
                                                              (this.props
                                                                .configInfo
                                                                .errors
                                                                .holdForPickupMeEmail !==
                                                              ""
                                                                ? " error"
                                                                : "")
                                                            }
                                                          >
                                                            <AssistiveError
                                                              inputId="holdForPickupMeEmailId"
                                                              errorMessage={
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupMeEmail
                                                              }
                                                            />
                                                            <input
                                                              tabIndex="0"
                                                              id="holdForPickupMeEmailId"
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="email123@email.com"
                                                              value={
                                                                this.props
                                                                  .configInfo
                                                                  .labelHfp
                                                                  .senderEmailText
                                                              }
                                                              onChange={(e) => {
                                                                this.props.changeHoldForPickup(
                                                                  "senderEmailText"
                                                                )(e);
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupMeEmail !==
                                                                ""
                                                                  ? this.props.clearError(
                                                                      "holdForPickupMeEmail"
                                                                    )
                                                                  : this.props.clearError(
                                                                      ""
                                                                    );
                                                              }}
                                                              maxLength="50"
                                                            />
                                                            <span className="error-message">
                                                              {
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupMeEmail
                                                              }
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <div />
                                                        )}
                                                      </div>
                                                      <div className="radio-container notify-me-text-message-wrapper required-field">
                                                        <input
                                                          id="click-text-message-me-radio"
                                                          type="radio"
                                                          className="radio-button"
                                                          name="notify-me-radio"
                                                          checked={
                                                            !this.state
                                                              .emailSenderRadioChecked
                                                          }
                                                          onChange={
                                                            this
                                                              .clickSenderPhoneRadio
                                                          }
                                                        />
                                                        <label htmlFor="click-text-message-me-radio">
                                                          Text Message{" "}
                                                          <SmsGateWay targetName="notifyMeTextMessageIcon" />
                                                        </label>
                                                        {!this.state
                                                          .emailSenderRadioChecked ? (
                                                          <div
                                                            className={
                                                              "form-group click-text-message-input required-field" +
                                                              (this.props
                                                                .configInfo
                                                                .errors
                                                                .holdForPickupMePhone !==
                                                              ""
                                                                ? " error"
                                                                : "")
                                                            }
                                                          >
                                                            <AssistiveError
                                                              inputId="holdForPickupMePhoneId"
                                                              errorMessage={
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupMePhone
                                                              }
                                                            />
                                                            <input
                                                              tabIndex="0"
                                                              id="holdForPickupMePhoneId"
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Phone Number"
                                                              onChange={(e) => {
                                                                this.props.changeHoldForPickup(
                                                                  "senderPhoneText"
                                                                )(e);
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupMePhone !==
                                                                ""
                                                                  ? this.props.clearError(
                                                                      "holdForPickupMePhone"
                                                                    )
                                                                  : this.props.clearError(
                                                                      ""
                                                                    );
                                                              }}
                                                              value={
                                                                this.props
                                                                  .configInfo
                                                                  .labelHfp
                                                                  .senderPhoneText
                                                              }
                                                              maxLength="50"
                                                            />
                                                            <span className="error-message">
                                                              {
                                                                this.props
                                                                  .configInfo
                                                                  .errors
                                                                  .holdForPickupMePhone
                                                              }
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <div />
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div />
                                                  )}

                                                  <div />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <React.Fragment />
                        )}
                      </div>
                    </div>

                    {this.props.batchChecked ? (
                      <div className="row">
                        <div className="col-12">
                          <AssistiveError
                            inputId="add-to-batch-message"
                            errorMessage={this.props.addToBatchMessage}
                          />
                          <div className="batch-add-button-container">
                            <button
                              type="button"
                              className="btn-primary"
                              id="add-to-batch-button"
                              onClick={this.addLabelToBatch}
                            >
                              Add to Batch
                            </button>
                          </div>
                          <span
                            id="add-to-batch-message"
                            className="error-message"
                          >
                            {this.props.addToBatchMessage}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          <div className="row">
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StepTwoDeliveryAddress;
