import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import update from "immutability-helper";
import AssistiveError from "../Subcomponents/AssistiveError";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";

class GXGParticipatingLocations extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      isLoaded: false,
      searchDeliveryPostalCode: "",
      searchDeliveryCity: "",
      cnsCartId: "",
      saveDestinationAddress: "N",
      deliveryPointId: "",
      positeid: "",
      errors: {
        globalError: [],
        cityOrPostalCode: "",
        cityOrPostalCodeSearchFail: "",
        destination: ""
      }
    };
  }

  editLabel = (e) => {
    e.preventDefault();
    this.props.setEditCartId(this.state.fetchedGXGList.cnsCartId);
    this.props.history.push("/labelInformation", {
      editItem: this.state.fetchedGXGList.cnsCartId
    });
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: errorMessage
        }
      });
    }
  };

  clearError = (fieldName) => {
    this.setState((prevState) => {
      return {
        errors: {
          ...prevState.errors,
          [fieldName]: ""
        }
      };
    });
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  setGlobalSearchError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("cityOrPostalCodeSearchFail")(combinedErrors);
  };

  componentDidMount() {
    this.props.toggleSpinner(true);
    // If there is no history, redirect the user to the create label page
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.cnsCartId
    ) {
      axios
        .post(
          "/go/cnsrest/fetchGxgLocationsDestinations?holdCartId=" +
            this.props.location.state.cnsCartId,
          {
            headers: { "Content-Type": "application/json" }
          }
        )
        .then((response) => {
          if (
            Object.keys(response.data.fieldErrors).length === 0 ||
            response.data.actionErrors.length === 0
          ) {
            this.props.toggleSpinner(false);
            this.setState({
              focusOnTop: true,
              isLoaded: true,
              fetchedGXGList: response.data,
              shipFromOtherZipInd:
                response.data.gxgLocationsAndDestinations.cnsCart.labelInd
                  .shipFromOtherZipInd,
              foundGXGCity:
                response.data.gxgLocationsAndDestinations.destinationPoints
                  .length === 0
                  ? false
                  : true,
              deliveryPointId: response.data.gxgLocationsAndDestinations.cnsCart
                .labelGxg.deliveryPointId
                ? response.data.gxgLocationsAndDestinations.cnsCart.labelGxg
                    .deliveryPointId
                : "",
              positeid: response.data.gxgLocationsAndDestinations.cnsCart
                .labelGxg.positeid
                ? response.data.gxgLocationsAndDestinations.cnsCart.labelGxg
                    .positeid
                : ""
            });
          } else {
            this.setGlobalError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.toggleSpinner(false);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        });
    } else {
      window.location.replace("/labelInformation");
    }
  }

  componentDidUpdate() {
    // Scroll to the first section that has an error, and
    // focus on the first field with an error
    if (this.state.focusOnError) {
      // Step 6 - Participating Post Office Location
      if (this.step6ErrorFound) {
        document
          .getElementById("step-participating-post-office")
          .scrollIntoView();
      } else if (this.step7ErrorFound) {
        // Step 7 - Destination Postal Code
        document
          .getElementById("step-destination-postal-code")
          .scrollIntoView();
        if (this.errors["cityOrPostalCode"]) {
          document.getElementById("gxg-city-a11y").focus();
        } else if (this.errors["destination"]) {
          document.getElementById("gxg-destination-section-a11y").focus();
        }
      }
      // Reset fields
      this.step6ErrorFound = false;
      this.step7ErrorFound = false;
      this.setState({
        focusOnError: false
      });
    }
    // Focus on the top element on the page after loading
    if (this.state.focusOnTop && this.state.isLoaded) {
      document.getElementById("gxg-step-6-top").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
  }

  refreshData = (e) => {
    axios
      .post(
        "/go/cnsrest/fetchGxgLocationsDestinations?holdCartId=" +
          this.props.location.state.cnsCartId,
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then((response) => {
        this.setState({
          isLoaded: true,
          fetchedGXGList: response.data,
          shipFromOtherZipInd:
            response.data.gxgLocationsAndDestinations.cnsCart.labelInd
              .shipFromOtherZipInd,
          foundGXGCity:
            response.data.gxgLocationsAndDestinations.destinationPoints
              .length === 0
              ? false
              : true
        });
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        this.props.toggleSpinner(false);
      });
  };

  validateSearch = (e) => {
    let errorsFound = false;
    let errorObject = {};
    // Step 7 - Destination Postal Code
    if (
      this.state.searchDeliveryCity.trim() === "" &&
      this.state.searchDeliveryPostalCode.trim() === ""
    ) {
      errorObject["cityOrPostalCode"] = "Please enter a city or postal code.";
      errorsFound = true;
      this.step7ErrorFound = true;
    }

    this.errors = errorObject;
    this.setInputErrors("errorObject")(errorObject);

    return errorsFound;
  };

  validateSave = (e) => {
    let errorsFound = false;
    let errorObject = {};
    // Step 7 - Destination Postal Code
    if (!this.state.deliveryPointId) {
      errorObject["destination"] = "Please select a destination.";
      errorsFound = true;
      this.step7ErrorFound = true;
    }

    this.errors = errorObject;
    this.setInputErrors("errorObject")(errorObject);

    return errorsFound;
  };

  searchGxgCityLocations = (e) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    if (!this.validateSearch()) {
      axios
        .post(
          "/go/cnsrest/searchGxgDestinations?cnsCartId=" +
            this.props.location.state.cnsCartId +
            "&searchDeliveryCity=" +
            this.state.searchDeliveryCity +
            "&searchDeliveryPostalCode=" +
            this.state.searchDeliveryPostalCode
        )
        .then((response) => {
          if (
            Object.keys(response.data.fieldErrors).length === 0 &&
            response.data.actionErrors.length === 0
          ) {
            this.refreshData();
          } else {
            this.setGlobalSearchError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
            this.props.toggleSpinner(false);
          }
        })
        .catch((error) => {
          this.props.toggleSpinner(false);
          window.scrollTo(0, 0);
          this.setGlobalError({}, [
            "An unexpected error has occurred. Please refresh the page and try again."
          ]);
        });
    } else {
      this.props.toggleSpinner(false);
      this.setState({
        focusOnError: true
      });
    }
  };

  proceedToGxgCustoms = (e) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    let dataToSend = {};
    dataToSend.saveDestinationAddress = this.state.saveDestinationAddress;
    dataToSend["cnsCart"] = {};
    dataToSend["cnsCart"]["labelGxg"] = {};
    dataToSend["cnsCart"]["labelInd"] = {};
    dataToSend["cnsCart"].cnsCartId = this.props.location.state.cnsCartId;

    dataToSend["cnsCart"].labelGxg.positeid = this.state.positeid || "DEFAULT";
    dataToSend["cnsCart"].labelGxg.deliveryPointId = this.state.deliveryPointId;
    dataToSend[
      "cnsCart"
    ].labelInd.shipFromOtherZipInd = this.state.shipFromOtherZipInd;
    if (!this.validateSave()) {
      axios
        .post(
          "/go/cnsrest/saveGxgLocationsDestinations?positeid=" +
            dataToSend["cnsCart"].labelGxg.positeid +
            "&deliveryPointId=" +
            this.state.deliveryPointId +
            "&shipFromOtherZipInd=" +
            this.state.shipFromOtherZipInd +
            "&saveDestinationAddress=" +
            this.state.saveDestinationAddress +
            "&cnsCartIdParam=" +
            this.props.location.state.cnsCartId,
          dataToSend
        )
        .then((response) => {
          if (
            Object.keys(response.data.fieldErrors).length === 0 ||
            response.data.actionErrors.length === 0
          ) {
            this.props.toggleSpinner(false);
            this.props.history.push("/customsInformationGxg", {
              cnsCartId: this.props.location.state.cnsCartId
            });
          } else {
            this.props.toggleSpinner(false);
            window.scrollTo(0, 0);
            this.setGlobalError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.toggleSpinner(false);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        });
    } else {
      this.props.toggleSpinner(false);
      this.setState({
        focusOnError: true
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        />
        {this.state.isLoaded ? (
          <div id="gxg-step-6-top" tabIndex="-1">
            <div
              className="Step_Six_Container"
              id="step-participating-post-office"
            >
              <div className="container-fluid">
                <h2 className="d-md-none responsive-header">Create a Label</h2>
                <h2 className="normal">
                  Step 6:<strong> Participating Post Office Location </strong>
                </h2>
                <div className="row">
                  <div className="col-12 form-group step-six-click-ship">
                    <p>
                      <strong>
                        Choose a Post Office location for dropping off your
                        shipment.
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-11 col-lg-10 click-ship-address-box-wrapper">
                    <ul className="click-ship-address-box-container">
                      {this.state.fetchedGXGList.gxgLocationsAndDestinations.gxgSiteList.map(
                        (item) => (
                          <React.Fragment key={item.siteId}>
                            {item.siteId !== "DEFAULT" ? (
                              <li className="click-ship-individual-address">
                                <div className="row individual-address-content-wrapper">
                                  <div className="col-12 col-md-8">
                                    <input
                                      id={"dropoffLoc" + item.siteId}
                                      type="radio"
                                      className="radio-button"
                                      name="dropoff-location-radio"
                                      checked={
                                        this.state.positeid === item.siteId
                                          ? true
                                          : false
                                      }
                                      value={item.siteId}
                                      onChange={(e) =>
                                        this.setState({
                                          positeid: e.currentTarget.value,
                                          shipFromOtherZipInd: "N"
                                        })
                                      }
                                    />
                                    <p className="click-ship-location">
                                      {item.siteName}
                                    </p>
                                    <p className="click-individual-address-detail">
                                      {item.mailingAddr}, {item.cityName},{" "}
                                      {item.stateCode} {item.zipCode}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              <React.Fragment />
                            )}
                          </React.Fragment>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-10">
                    <p className="drop-shipment-wrapper">
                      While you can drop off your shipments at any Postal
                      Office, shipping from one of these participating locations
                      will ensure a guaranteed GXG delivery date.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-10 another-post-office-wrapper">
                    <label
                      className="checkbox-component other-post-office"
                      htmlFor="other-post-office-check"
                    >
                      <input
                        type="checkbox"
                        id="other-post-office-check"
                        checked={this.state.shipFromOtherZipInd === "Y"}
                        onChange={(e) =>
                          this.setState({
                            positeid: "",
                            shipFromOtherZipInd: e.currentTarget.checked
                              ? "Y"
                              : "N"
                          })
                        }
                      />
                      <span
                        className="checkbox"
                        name="other-post-office-check"
                      />
                      I will drop my package off at another Post Office. I
                      understand that by doing so I won't be guaranteed a
                      delivery date.
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-10">
                    <p className="failure-to-follow-p">
                      Failure to follow the{" "}
                      <a
                        className="inline-link secondary"
                        href="https://about.usps.com/publications/pub141/html/index-of-countries.html#Index%20of%20Countries"
                      >
                        individual country regulations
                      </a>{" "}
                      could result in items not clearing customs and loss of
                      shipping costs.{" "}
                      <a
                        className="inline-link secondary"
                        href="https://about.usps.com/publications/pub141/html/serviceDescription.html#unship"
                      >
                        Click here for additional details
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 horizontal-line-container">
                    <hr className="horizontal-line" />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="Step_Seven_Container"
              id="step-destination-postal-code"
            >
              <div className="container-fluid">
                <h2 className="normal">
                  Step 7:<strong> Destination Postal Code</strong>
                </h2>
                <div className="row">
                  <div className="col-12 col-md-10 form-group step-seven-click-ship">
                    <p>
                      For a guaranteed delivery date, select a specific Postal
                      Code from the list below. Selecting "All Cities Served"
                      may delay delivery date.
                    </p>
                  </div>
                </div>

                <AssistiveError
                  inputId="gxg-destination-section"
                  errorMessage={this.state.errors.destination}
                />

                {this.state.foundGXGCity ? (
                  <div
                    className="row"
                    style={{
                      overflow: "scroll",
                      height: "500px",
                      overflowX: "hidden"
                    }}
                  >
                    <div className="col-12 col-md-11 col-lg-10">
                      <ul className="click-ship-destination-wrapper">
                        <li className="click-ship-destination-content-label">
                          <div className="row destination-address-content">
                            <div className="col-9">
                              <p className="destinaton-city-location-label">
                                Destination City
                              </p>
                            </div>
                            <div className="col-3 text-right">
                              <p className="destination-postal-code-label">
                                Postal Code
                              </p>
                            </div>
                          </div>
                        </li>
                        {this.state.fetchedGXGList.gxgLocationsAndDestinations.destinationPoints.map(
                          (item) => (
                            <li
                              key={item.destinationPoint}
                              className="click-ship-destination-content"
                            >
                              <div className="row destination-address-content">
                                <div className="col-9">
                                  <input
                                    id={
                                      "gxgDestination" + item.destinationPoint
                                    }
                                    type="radio"
                                    className="radio-button"
                                    name="destination-city-radio"
                                    checked={
                                      this.state.deliveryPointId ===
                                      item.destinationPoint
                                        ? true
                                        : false
                                    }
                                    value={item.destinationPoint}
                                    onChange={(e) =>
                                      this.setState({
                                        deliveryPointId: e.currentTarget.value
                                      })
                                    }
                                    onClick={(e) =>
                                      this.state.errors.destination !== ""
                                        ? this.clearError("destination")
                                        : this.clearError("")
                                    }
                                  />
                                  <p className="destinaton-city-location">
                                    {item.cityName}
                                  </p>
                                </div>
                                <div className="col-3 text-right">
                                  <p className="destination-postal-code">
                                    {item.postalCode}
                                  </p>
                                </div>
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    {/*this block was made because it was left out of the redesign docs so i made it up   */}
                    <div className="col-12 col-md-11 col-lg-10">
                      <div className="row dom-address-inputs">
                        <div className="col-12 col-md-10 form-group step-seven-click-ship">
                          <p>
                            The destination city/Postal Code you provided could
                            not be found. Please search for a destination city
                            below to complete this step.
                          </p>
                        </div>
                        <AssistiveError
                          inputId="gxg-city"
                          errorMessage={this.state.errors.cityOrPostalCode}
                        />
                        <div
                          className={
                            "col-12 col-md-6 form-group required-field" +
                            (this.state.errors.cityOrPostalCode !== ""
                              ? " error"
                              : "")
                          }
                        >
                          <label htmlFor="gxg-city">*City</label>
                          <input
                            tabIndex="0"
                            type="text"
                            id="gxg-city"
                            className="form-control"
                            onChange={(e) => {
                              this.setState({
                                searchDeliveryCity: e.target.value
                              });
                              this.state.errors.cityOrPostalCode !== ""
                                ? this.clearError("cityOrPostalCode")
                                : this.clearError("");
                              this.state.errors.cityOrPostalCodeSearchFail !==
                              ""
                                ? this.clearError("cityOrPostalCodeSearchFail")
                                : this.clearError("");
                              this.state.errors.destination !== ""
                                ? this.clearError("destination")
                                : this.clearError("");
                            }}
                          />
                          <span className="error-message">
                            {this.state.errors.cityOrPostalCode}
                          </span>
                        </div>
                        <div
                          className={
                            "col-12 col-md-6 form-group" +
                            (this.state.errors.cityOrPostalCode !== ""
                              ? " error"
                              : "")
                          }
                        >
                          <label htmlFor="gxg-postal-code">Postal Code</label>
                          <input
                            tabIndex="0"
                            type="text"
                            id="gxg-postal-code"
                            className="form-control"
                            onChange={(e) => {
                              this.setState({
                                searchDeliveryPostalCode: e.target.value
                              });
                              this.state.errors.cityOrPostalCode !== ""
                                ? this.clearError("cityOrPostalCode")
                                : this.clearError("");
                              this.state.errors.cityOrPostalCodeSearchFail !==
                              ""
                                ? this.clearError("cityOrPostalCodeSearchFail")
                                : this.clearError("");
                              this.state.errors.destination !== ""
                                ? this.clearError("destination")
                                : this.clearError("");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 button-wrapper">
                          <div className="button-container">
                            <button
                              type="button"
                              className="btn-primary ser-perf-save-btn"
                              tabIndex="0"
                              onClick={(e) => this.searchGxgCityLocations(e)}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <AssistiveGlobalError
                      globalErrorId="cityOrPostalCodeSearchFail-error"
                      globalErrorArray={
                        this.state.errors.cityOrPostalCodeSearchFail
                      }
                      styling={{ paddingLeft: "15px" }}
                    />
                  </div>
                )}

                <div className="row">
                  <div className="col-12 col-md-10">
                    <label
                      className="checkbox-component update-address-label"
                      htmlFor="update-address-check"
                    >
                      <input
                        type="checkbox"
                        id="update-address-check"
                        onChange={(e) =>
                          this.setState({
                            saveDestinationAddress: e.currentTarget ? "Y" : "N"
                          })
                        }
                      />
                      <span className="checkbox" name="update-address-check" />
                      Update Address
                    </label>
                  </div>
                </div>
                <span className="error-message">
                  {this.state.errors.destination}
                </span>
                <div className="row">
                  <div className="col-12 col-md-10 button-wrapper">
                    <div className="button-container">
                      <a
                        href="/customsInformationGxg"
                        role="button"
                        className="btn-primary"
                        onClick={(e) => this.proceedToGxgCustoms(e)}
                      >
                        Proceed to Customs
                      </a>
                    </div>
                    <div className="button-container">
                      <a
                        href="/labelInformation"
                        className="btn-primary button--white"
                        onClick={this.editLabel}
                      >
                        Choose Another Service
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(GXGParticipatingLocations);
