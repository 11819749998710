import React from "react";

const AssistiveError = (props) => {
  const { inputId, errorMessage } = props;

  return (
    <span id={inputId + "-a11y"} className="visuallyhidden" tabIndex="-1">
      {errorMessage}
    </span>
  );
};

export default AssistiveError;
