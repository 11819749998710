import React, { Component } from "react";
import moment from "moment";
import "react-dates/initialize";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class DateRangeSelector extends Component {
  constructor(props) {
    super(props);
    let availableDates = [];

    for (var shipdate in this.props.currentShippingDates) {
      availableDates.push(
        moment(
          moment(
            Object.values(this.props.currentShippingDates[shipdate])[0]
          ).format("YYYY-MM-DD")
        )
      );
    }
    this.state = {
      startDate: new Date(),
      endDate: moment(),
      focusedInput: null,
      availableDates: availableDates
    };
  }

  handleDateChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  handleFocusChange = (focusedInput) => this.setState({ focusedInput });

  // If currentShippingDates has not been passed as a prop,
  // this is the shipping history page
  isShippingHistory = () => {
    return this.state.availableDates.length === 0;
  };

  isDayBlocked = (day) => {
    return (
      this.isShippingHistory() ||
      this.state.availableDates.filter((d) => d.isSame(day, "day")).length > 0
    );
  };

  componentDidMount() {
    let element = document.getElementById("input-date");
    element.classList.add("shipping-date-calendar");
  }

  render() {
    return (
      <div>
        <DatePicker
          selected={
            !this.isShippingHistory()
              ? new Date(this.props.shippingDate)
              : this.props.shippingDate
          }
          onChange={(date) =>
            this.props.changeNonNestedFields("shippingDate")(date)
          }
          filterDate={(date) => this.isDayBlocked(date)}
          id={this.props.inputId ? this.props.inputId : "input-date"}
          tabIndex="0"
          type="text"
          autoComplete="off"
          placeholderText="MM/DD/YYYY"
          readonly=""
          className="shipping-date-calendar form-control shipping-date-calendar hasDatepicker"
          style={{
            background:
              "transparent url(/click-n-ship/images/calendar-icon.svg) no-repeat",
            "background-position": "right 10px top 9px",
            "background-size": "24px 24px",
            cursor: "pointer",
            width: "100%",
            "padding-right": "20%"
          }}
        />
      </div>
    );
  }
}

export default DateRangeSelector;
