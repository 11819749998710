import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import PriorityMailPopover from "../Popovers/PriorityMailPopover";
import PriorityMailInternationalPopover from "../Popovers/PriorityMailInternationalPopover";
import sortIcon from "../../click-n-ship/images/sort-icon.svg";
import AdditionalDropoffInfo from "../Subcomponents/DropoffAdditionalInfo/AdditionalDropoffInfo";
import UspsConnectMap from "../Subcomponents/UspsConnectMap/UspsConnectMap";

class ServiceTypeViewServices extends React.Component {
  constructor(props) {
    super(props);
    let arrOfProductIds = [];

    for (let items in Object.keys(
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType
    )) {
      let itemList = Object.keys(
        this.props.listOfServices.data.productServiceViewBean
          .productsByServiceType
      )[items];

      for (let product in this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType[itemList]) {
        if (itemList === "EXM" || itemList === "PRI") {
          arrOfProductIds.push(
            this.props.listOfServices.data.productServiceViewBean
              .productsByServiceType[itemList][product].productId
          );
        }
      }
    }

    // For CL/CLM products, if a valid drop-off location is not available,
    // ensure that no service is preselected
    if (
      this.props.serviceShow === "USPS Connect Local" ||
      this.props.serviceShow === "USPS Connect Local Mail"
    ) {
      if (
        !this.props.dropOffLocations ||
        !this.props.selectedDropOffLocationIndex
      ) {
        this.props.setPackageTypeProductId("");
      }
    }

    this.state = {
      renderList: true,
      showCommercialGold: false,
      arrOfProductIds: arrOfProductIds,
      totalDriveDistance: [],
      averageDriveTime: []
    };

    // This is part of the ID that will be given to each service.
    // The unique ID of each service is appended to this. It is made a variable
    // here so it can be easily sliced off when only the unique number portion
    // is needed.
    this.serviceInfoIconIdPrefix = "serviceInfoIcon";
  }

  componentDidMount() {
    if (this.props.configInfo.labelInd.commercialGoldInd === "Y") {
      this.setState({
        goldPopoverItem: this.props.configInfo.saveLabelInfoAdditionalData
          .productId
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.serviceShow !== prevProps.serviceShow) {
      //Popover for Commercial Gold was erroring the page on service change because the target was not rendered in the DOM yet
      //This would happen when a package type was saved in preferences that was one of the eligible package types
      this.setState({
        goldPopoverItem: ""
      });
    }
  }

  swapView = (e) => {
    e.preventDefault();
    this.props.setServiceInfoIconId(undefined);
    this.setState({
      renderList: e.currentTarget.innerText === "List",
      goldPopoverItem: ""
    });
  };

  updateParents = (e, item) => {
    this.props.setPackageTypeProductId(item.productId);
    this.props.saveSelectedServiceInfo(item);

    this.props.changeTrackingNotifications("crematedRemainsInd")(false);

    if (
      this.props.configInfo.labelInd.commercialGoldInd === "Y" &&
      this.state.arrOfProductIds.includes(item.productId)
    ) {
      this.setState({
        showCommercialGold: true,
        goldPopoverItem: item.productId
      });
    }
  };

  displayMap = () => {
    // Display map if user does not have a preferred radius
    if (!this.props.configInfo.uspsConnectRadius) {
      return true;
    }
    if (this.props.lookupCodes) {
      // Display map if preferred radius >= 10 and <= the lookup code
      // (assuming the lookup code >= 10)
      if (this.props.lookupCodes["usps_connect_drop_off_radius"] >= 10) {
        if (
          this.props.configInfo.uspsConnectRadius >= 10 &&
          this.props.configInfo.uspsConnectRadius <=
            this.props.lookupCodes["usps_connect_drop_off_radius"]
        ) {
          return true;
        }
      }
      // Display map if preferred radius >= the lookup code and <= 10
      // (assuming the lookup code <= 10)
      if (this.props.lookupCodes["usps_connect_drop_off_radius"] <= 10) {
        if (
          this.props.configInfo.uspsConnectRadius >=
            this.props.lookupCodes["usps_connect_drop_off_radius"] &&
          this.props.configInfo.uspsConnectRadius <= 10
        ) {
          return true;
        }
      }
    }
    return false;
  };

  setTotalDriveDistance = (totalDriveDistance) => {
    let joinedDistance = this.state.totalDriveDistance.concat(
      totalDriveDistance
    );
    this.setState({
      totalDriveDistance: joinedDistance
    });
  };

  setAverageDriveTime = (averageDriveTime) => {
    let joinedDriveTime = this.state.averageDriveTime.concat(averageDriveTime);
    this.setState({
      averageDriveTime: joinedDriveTime
    });
  };

  renderViewAs = () => {
    return (
      <React.Fragment>
        {this.props.configInfo.labelInd.commercialGoldInd === "Y" ? (
          <div style={{ paddingTop: "20px" }}>
            <h2>
              <strong id="commercialBasePopover" className="text-center">
                Commercial Base Pricing has been added to your account{" "}
              </strong>
            </h2>
            <p style={{ paddingTop: "10px" }}>
              {" "}
              With your advancement to the USPS Loyalty Program Gold Tier level,
              you now have access to Commercial Base pricing on all Priority
              Mail® and Priority Mail Express® labels.
            </p>
          </div>
        ) : (
          <React.Fragment />
        )}
        {this.props.serviceShow !== "USPS Connect Local" &&
        this.props.serviceShow !== "USPS Connect Local Mail" ? (
          <div className="row step-five-display-items">
            <div className="col-12">
              <div className="view-as">
                <strong>View as:</strong>
                <strong
                  className={
                    "view-as-list" + (this.state.renderList ? " active" : "")
                  }
                >
                  <button
                    type="button"
                    className="button-link"
                    onClick={this.swapView}
                  >
                    {this.state.renderList ? (
                      <span className="visuallyhidden">Currently selected</span>
                    ) : null}
                    List
                  </button>
                </strong>
                <strong
                  className={
                    "view-as-grid" + (!this.state.renderList ? " active" : "")
                  }
                >
                  <button
                    type="button"
                    className="button-link"
                    onClick={this.swapView}
                  >
                    {!this.state.renderList ? (
                      <span className="visuallyhidden">Currently selected</span>
                    ) : null}
                    Grid
                  </button>
                </strong>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.renderList ||
        this.props.serviceShow === "USPS Connect Local" ||
        this.props.serviceShow === "USPS Connect Local Mail" ? (
          <li className="package-content-label">
            <div className="row destination-address-content">
              <div className="col-8 col-md-9">
                <div className="row">
                  <div
                    id="package-type-column-header"
                    className="package-type-service-container col-12 col-md-8"
                  >
                    <p className="package-type-service-label">
                      <button
                        type="button"
                        className="button-link"
                        onClick={(e) =>
                          this.servicesFound
                            ? this.props.sortColumns(e, "packageType")
                            : null
                        }
                      >
                        <strong>
                          Package Type <img src={sortIcon} alt="Sort icon" />
                          {this.props.sortPackageTypeAsc !== null ? (
                            <span className="visuallyhidden">
                              Currently sorted in
                              {this.props.sortPackageTypeAsc === false
                                ? " reverse "
                                : ""}
                              alphabetical order
                            </span>
                          ) : null}
                        </strong>
                      </button>
                    </p>
                  </div>
                  <div className="col-4 d-none d-md-block">
                    <p className="scheduled-delivery-label">
                      {this.props.serviceShow === "Priority Mail" ? (
                        <strong>Expected Delivery</strong>
                      ) : (
                        <strong>Scheduled Delivery</strong>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 col-md-3 text-right">
                <p className="service-postal-code-label">
                  <button
                    type="button"
                    className="button-link"
                    onClick={(e) =>
                      this.servicesFound
                        ? this.props.sortColumns(e, "price")
                        : null
                    }
                  >
                    <strong>
                      Price <img src={sortIcon} alt="Sort icon" />
                      {this.props.sortPriceAsc !== null ? (
                        <span className="visuallyhidden">
                          Currently sorted from
                          {this.props.sortPriceAsc
                            ? " lowest to highest "
                            : " hightest to lowest "}
                          price
                        </span>
                      ) : null}
                    </strong>
                  </button>
                </p>
              </div>
            </div>
          </li>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  };

  renderDestination = () => {
    if (this.props.isDomesticDestination()) {
      // Domestic destination
      if (
        this.state.renderList ||
        this.props.serviceShow === "USPS Connect Local" ||
        this.props.serviceShow === "USPS Connect Local Mail"
      ) {
        // List view
        return <div>{this.renderServiceClassListDomestic()}</div>;
      } else {
        // Grid view
        return (
          <div className="row">
            <div className="col-12">
              <ul className="priority package-schedule-price-wrapper select-service-grid">
                {this.renderServiceClassGridDomestic()}
              </ul>
            </div>
          </div>
        );
      }
    } else {
      // International destination
      if (this.state.renderList) {
        // List view
        return <div>{this.renderServiceClassListInternational()}</div>;
      } else {
        // Grid view
        return (
          <div className="row">
            <div className="col-12">
              <ul className="priority package-schedule-price-wrapper select-service-grid">
                {this.renderServiceClassGridInternational()}
              </ul>
            </div>
          </div>
        );
      }
    }
  };

  renderServiceClassListDomestic = () => {
    if (
      this.props.serviceShow === "Priority Mail" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.PRI
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.PRI.map(
        this.renderServicesList
      );
    } else if (
      this.props.serviceShow === "Priority Mail Express" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.EXM
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.EXM.map(
        this.renderServicesList
      );
    } else if (
      this.props.serviceShow === "Ground Advantage" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.GA
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.GA.map(
        this.renderServicesList
      );
    } else if (
      this.props.serviceShow === "USPS Retail Ground" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.RG
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.RG.map(
        this.renderServicesList
      );
    } else if (
      this.props.serviceShow === "First-Class Package Service - Retail" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.FC
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.FC.map(
        this.renderServicesList
      );
    } else if (
      this.props.serviceShow === "USPS Connect Local" &&
      this.props.dropOffLocations &&
      this.props.selectedDropOffLocationIndex &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.CL
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.CL.map(
        this.renderServicesList
      );
    } else if (
      this.props.serviceShow === "USPS Connect Local Mail" &&
      this.props.dropOffLocations &&
      this.props.selectedDropOffLocationIndex &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.CLM
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.CLM.map(
        this.renderServicesList
      );
    }
    return this.renderNoServices();
  };

  renderServiceClassGridDomestic = () => {
    if (
      this.props.serviceShow === "Priority Mail" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.PRI
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.PRI.map(
        this.renderServicesGrid
      );
    } else if (
      this.props.serviceShow === "Priority Mail Express" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.EXM
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.EXM.map(
        this.renderServicesGrid
      );
    } else if (
      this.props.serviceShow === "Ground Advantage" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.GA
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.GA.map(
        this.renderServicesGrid
      );
    } else if (
      this.props.serviceShow === "USPS Retail Ground" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.RG
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.RG.map(
        this.renderServicesGrid
      );
    } else if (
      this.props.serviceShow === "First-Class Package Service - Retail" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.FC
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.FC.map(
        this.renderServicesGrid
      );
    }
    return this.renderNoServices();
  };

  renderServiceClassListInternational = () => {
    if (
      this.props.serviceShow === "Priority Mail International" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.IPM
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.IPM.map(
        this.renderServicesList
      );
    } else if (
      this.props.serviceShow === "Priority Mail Express International" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.IEM
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.IEM.map(
        this.renderServicesList
      );
    } else if (
      this.props.serviceShow === "First-Class Package International Service" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.IFC
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.IFC.map(
        this.renderServicesList
      );
    } else if (
      this.props.serviceShow === "Global Express Guaranteed" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.GXG
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.GXG.map(
        this.renderServicesList
      );
    }
    return this.renderNoServices();
  };

  renderServiceClassGridInternational = () => {
    if (
      this.props.serviceShow === "Priority Mail International" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.IPM
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.IPM.map(
        this.renderServicesGrid
      );
    } else if (
      this.props.serviceShow === "Priority Mail Express International" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.IEM
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.IEM.map(
        this.renderServicesGrid
      );
    } else if (
      this.props.serviceShow === "First-Class Package International Service" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.IFC
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.IFC.map(
        this.renderServicesGrid
      );
    } else if (
      this.props.serviceShow === "Global Express Guaranteed" &&
      this.props.listOfServices.data.productServiceViewBean
        .productsByServiceType.GXG
    ) {
      return this.props.listOfServices.data.productServiceViewBean.productsByServiceType.GXG.map(
        this.renderServicesGrid
      );
    }
    return this.renderNoServices();
  };

  renderServicesList = (item) => {
    this.servicesFound = true;
    return (
      <React.Fragment key={item.productId}>
        <li
          className={
            "package-row" +
            (this.props.configInfo.saveLabelInfoAdditionalData.productId ===
            item.productId
              ? " package-row-selected"
              : "")
          }
          onClick={(e) => this.updateParents(e, item)}
        >
          <div className="row package-del-price-content" id="serviceRow">
            <div className="col package-row-text">
              <div className="row">
                <div className="package-type-container-spacing package-type-container col">
                  <input
                    id={"package-type-radio-list" + item.productId}
                    type="radio"
                    className="radio-button package-type-radio"
                    name="radio-package-type"
                    onChange={(e) => this.updateParents(e, item)}
                    checked={
                      this.props.configInfo.saveLabelInfoAdditionalData
                        .productId === item.productId
                    }
                  />
                  <React.Fragment>
                    {item.productNameDisplay === "Global Express Guaranteed" ? (
                      <p className="package-type-title">
                        Global Express Guaranteed®
                      </p>
                    ) : (
                      <p
                        className="package-type-title"
                        dangerouslySetInnerHTML={{
                          __html: item.productNameDisplay
                        }}
                      />
                    )}
                  </React.Fragment>
                  <p
                    className="package-type-desc-txt"
                    dangerouslySetInnerHTML={{
                      __html: item.boxDimensions
                    }}
                  />
                </div>
                <div
                  id="scheduled-delivery-container-list-id"
                  className="scheduled-delivery-container-spacing scheduled-delivery-container col"
                >
                  {this.props.serviceShow === "USPS Connect Local" ||
                  this.props.serviceShow === "USPS Connect Local Mail" ? (
                    this.props.dropOffLocations[
                      this.props.selectedDropOffLocationIndex
                    ] &&
                    this.props.dropOffLocations[
                      this.props.selectedDropOffLocationIndex
                    ].dropOffLocationDeliveryOptions === "SAMEDAY_NEXTDAY" ? (
                      <React.Fragment>
                        <p>
                          {this.props.dropOffLocations[this.props.dropoffIndex]
                            .dropOffLocationSNDC === "Y" ? (
                            <strong>Next-Day Delivery*</strong>
                          ) : (
                            <strong>Same-Day or Next-Day Delivery*</strong>
                          )}
                        </p>
                        {(this.props.serviceShow === "USPS Connect Local" &&
                          this.props.configInfo.saveLabelInfoAdditionalData
                            .productId === item.productId) ||
                        this.props.serviceShow === "USPS Connect Local Mail" ? (
                          <p style={{ fontSize: "12px" }}>
                            * Same-day delivery is expected but not guaranteed.
                            Next-day delivery may be impacted by holidays and
                            Sunday delivery availability. Additional
                            restrictions may apply. Speak with your USPS
                            Representative for details.
                          </p>
                        ) : null}
                      </React.Fragment>
                    ) : this.props.dropOffLocations[
                        this.props.selectedDropOffLocationIndex
                      ] &&
                      this.props.dropOffLocations[
                        this.props.selectedDropOffLocationIndex
                      ].dropOffLocationDeliveryOptions === "NEXTDAY" ? (
                      <React.Fragment>
                        <p>
                          <strong>Next-Day Delivery*</strong>
                        </p>
                        {(this.props.serviceShow === "USPS Connect Local" &&
                          this.props.configInfo.saveLabelInfoAdditionalData
                            .productId === item.productId) ||
                        this.props.serviceShow === "USPS Connect Local Mail" ? (
                          <p style={{ fontSize: "12px" }}>
                            * Next-day delivery may be impacted by holidays and
                            Sunday delivery availability. Additional
                            restrictions may apply. Speak with your USPS
                            Representative for details.
                          </p>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <p>
                          <strong>Same-Day or Next-Day Delivery*</strong>
                        </p>
                        {(this.props.serviceShow === "USPS Connect Local" &&
                          this.props.configInfo.saveLabelInfoAdditionalData
                            .productId === item.productId) ||
                        this.props.serviceShow === "USPS Connect Local Mail" ? (
                          <p style={{ fontSize: "12px" }}>
                            * Same-day delivery is not guaranteed, and may not
                            be available at all locations. Next-day delivery may
                            be impacted by holidays and availability of Sunday
                            delivery. Additional restrictions may apply. Speak
                            with your USPS Representative for details.
                          </p>
                        ) : null}
                      </React.Fragment>
                    )
                  ) : (
                    <p className="scheduled-delivery-dates">
                      {item.scheduledDelivery}
                    </p>
                  )}
                </div>
                <div
                  id={"price-container-id_" + item.productId}
                  className="price-container-spacing price-container col"
                >
                  <p className="package-price-content">
                    ${item.price.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        {((this.props.serviceShow === "USPS Connect Local" &&
          this.props.configInfo.saveLabelInfoAdditionalData.productId ===
            item.productId) ||
          this.props.serviceShow === "USPS Connect Local Mail") &&
          this.props.dropOffLocations &&
          this.props.selectedDropOffLocationIndex && (
            <React.Fragment>
              {this.displayMap() ? (
                <div
                  className={
                    "parcel-select-row" +
                    (this.props.configInfo.saveLabelInfoAdditionalData
                      .productId === item.productId
                      ? " parcel-select-row-selected"
                      : "")
                  }
                >
                  <p className="package-type-drop-off-location-header">
                    <strong style={{ fontSize: "20px" }}>
                      Choose Your Drop-off Location
                    </strong>
                  </p>
                  <br />
                  {this.props.dropOffLocations.map(
                    (dropOffLocation, dropOffAddressIndex) => (
                      <div className="row selected-drop-off-location-wrapper">
                        <input
                          type="radio"
                          name="radio-dropoff-select"
                          className="radio-button package-type-radio"
                          style={{ marginRight: "0px", marginTop: "5px" }}
                          checked={
                            this.props.dropoffIndex == dropOffAddressIndex
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.props.dropOffAddressSelect(dropOffAddressIndex)
                          }
                        />
                        <label>
                          <p
                            className="selected-drop-off-location-local"
                            style={{ paddingTop: "0px", paddingBottom: "10px" }}
                          >
                            <strong className="step5TextBlack">
                              {dropOffLocation.locationName}
                            </strong>
                            <br />
                            {(dropOffLocation.dropOffLocationAddress2
                              ? dropOffLocation.dropOffLocationAddress2 + ", "
                              : "") +
                              (dropOffLocation.dropOffLocationCity
                                ? dropOffLocation.dropOffLocationCity + ", "
                                : "") +
                              (dropOffLocation.dropOffLocationState
                                ? dropOffLocation.dropOffLocationState + " "
                                : "") +
                              (dropOffLocation.dropOffLocationZip5
                                ? dropOffLocation.dropOffLocationZip5
                                : "") +
                              (dropOffLocation.dropOffLocationZip4
                                ? "-" + dropOffLocation.dropOffLocationZip4
                                : "")}
                            {this.state.totalDriveDistance[
                              dropOffAddressIndex
                            ] > -1 &&
                              this.state.averageDriveTime[dropOffAddressIndex] >
                                -1 && (
                                <div
                                  className="selected-drop-off-location-local"
                                  style={{ padding: "0" }}
                                >
                                  <strong className="step5TextBlack">
                                    Total Distance:{" "}
                                    {
                                      this.state.totalDriveDistance[
                                        dropOffAddressIndex
                                      ]
                                    }{" "}
                                    Mile
                                    {this.state.totalDriveDistance[
                                      dropOffAddressIndex
                                    ] !== 1
                                      ? "s"
                                      : ""}
                                    ,
                                  </strong>{" "}
                                  Average Drive Time:{" "}
                                  {
                                    this.state.averageDriveTime[
                                      dropOffAddressIndex
                                    ]
                                  }{" "}
                                  minute
                                  {this.state.averageDriveTime[
                                    dropOffAddressIndex
                                  ] !== 1
                                    ? "s"
                                    : ""}
                                </div>
                              )}
                          </p>
                        </label>
                      </div>
                    )
                  )}
                  <div className="row selected-drop-off-location-wrapper">
                    {/* This is where it originally was {this.state.totalDriveDistance > -1 &&
                      this.state.averageDriveTime > -1 && (
                        <p className="selected-drop-off-location-local">
                          <strong className="step5TextBlack">
                            Total Distance: {this.state.totalDriveDistance} Mile
                            {this.state.totalDriveDistance !== 1 ? "s" : ""},
                          </strong>{" "}
                          Average Drive Time: {this.state.averageDriveTime}{" "}
                          minute
                          {this.state.averageDriveTime !== 1 ? "s" : ""}
                        </p>
                      )} */}
                    <div id="Step5ConnectMapWrapper">
                      <UspsConnectMap
                        selectedDropOffLocationData={
                          this.props.dropoffLocationData
                        }
                        setTotalDriveDistance={this.setTotalDriveDistance}
                        setAverageDriveTime={this.setAverageDriveTime}
                        mapSource="createLabel"
                        radius={
                          this.props.configInfo.uspsConnectRadius ||
                          this.props.lookupCodes[
                            "usps_connect_drop_off_radius"
                          ] ||
                          30
                        }
                        lookupCodes={this.props.lookupCodes}
                      />
                    </div>
                  </div>
                </div>
              ) : this.props.serviceShow === "USPS Connect Local" &&
                this.props.configInfo.saveLabelInfoAdditionalData.productId ===
                  item.productId ? (
                <div
                  className={
                    "parcel-select-row" +
                    (this.props.configInfo.saveLabelInfoAdditionalData
                      .productId === item.productId
                      ? " parcel-select-row-selected"
                      : "")
                  }
                >
                  <p>
                    USPS Connect Local allows you to ship with special
                    discounted rates because you're taking packages directly to
                    the back dock of the designated postal facility unit nearest
                    your package destinations. To use this shipping method, you
                    must:
                  </p>
                  <ul id="parcel-select-conditions-list">
                    <li>
                      <p>
                        Make sure all packages are only going to your customers
                        in the ZIP Codes™ served by your designated postal
                        facility.
                      </p>
                    </li>
                    <li>
                      <p>
                        Take all packages to the same designated postal facility
                        at the same time*.
                      </p>
                    </li>
                  </ul>
                  <div className="row selected-drop-off-location-wrapper">
                    <p>
                      <strong>
                        This Label's Designated Postal Facility is:
                      </strong>
                    </p>
                    <p id="selected-drop-off-location">
                      <strong>
                        {
                          this.props.dropOffLocations[
                            this.props.selectedDropOffLocationIndex
                          ].locationName
                        }
                      </strong>
                      <br />
                      {
                        this.props.dropOffLocations[
                          this.props.selectedDropOffLocationIndex
                        ].dropOffLocationAddress2
                      }
                      <br />
                      {(this.props.dropOffLocations[
                        this.props.selectedDropOffLocationIndex
                      ].dropOffLocationCity
                        ? this.props.dropOffLocations[
                            this.props.selectedDropOffLocationIndex
                          ].dropOffLocationCity + ", "
                        : "") +
                        (this.props.dropOffLocations[
                          this.props.selectedDropOffLocationIndex
                        ].dropOffLocationState
                          ? this.props.dropOffLocations[
                              this.props.selectedDropOffLocationIndex
                            ].dropOffLocationState + " "
                          : "") +
                        (this.props.dropOffLocations[
                          this.props.selectedDropOffLocationIndex
                        ].dropOffLocationZip5
                          ? this.props.dropOffLocations[
                              this.props.selectedDropOffLocationIndex
                            ].dropOffLocationZip5
                          : "") +
                        (this.props.dropOffLocations[
                          this.props.selectedDropOffLocationIndex
                        ].dropOffLocationZip4
                          ? "-" +
                            this.props.dropOffLocations[
                              this.props.selectedDropOffLocationIndex
                            ].dropOffLocationZip4
                          : "")}
                    </p>
                  </div>
                  <AdditionalDropoffInfo
                    locationName={
                      this.props.dropOffLocations[
                        this.props.selectedDropOffLocationIndex
                      ].dropOffLocationState
                    }
                    physicalState={
                      this.props.dropOffLocations[
                        this.props.selectedDropOffLocationIndex
                      ].locationName
                    }
                  />
                  {/* <p id="eligible-usps-connect-post-offices">
              See all eligible USPS Connect™ Local drop-off locations and their
              serviced ZIP Codes™ at{" "}
              <a
                href="https://tools.usps.com/go/TrackConfirmAction_input"
                className="inline-link right-chevron"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>USPS Connect™ Local</strong>
              </a>
            </p> */}
                  <p id="drop-off-location-fyi">
                    * Please see the bottom of your label for the address of the
                    postal facility you must bring your SCAN form and packages
                    to for processing.
                  </p>
                </div>
              ) : this.props.serviceShow === "USPS Connect Local Mail" ? (
                <div
                  className={
                    "parcel-select-row" +
                    (this.props.configInfo.saveLabelInfoAdditionalData
                      .productId === item.productId
                      ? " parcel-select-row-selected"
                      : "")
                  }
                >
                  <p>
                    USPS Connect Local mail allows you to ship with special
                    discounted rates because you're taking your mail directly to
                    the designated postal facility unit nearest your package
                    destinations. To use this shipping method, you must:
                  </p>
                  <ul id="parcel-select-conditions-list">
                    <li>
                      <p>
                        Make sure all mail pieces are only going to your
                        customers in the ZIP Codes™ served by your designated
                        postal facility.
                      </p>
                    </li>
                    <li>
                      <p>
                        Take all mail pieces to the same designated postal
                        facility at the same time*.
                      </p>
                    </li>
                  </ul>
                  <div className="row selected-drop-off-location-wrapper">
                    <p>
                      <strong>
                        This Label's Designated Postal Facility is:
                      </strong>
                    </p>
                    <p id="selected-drop-off-location">
                      <strong>
                        {
                          this.props.dropOffLocations[
                            this.props.selectedDropOffLocationIndex
                          ].locationName
                        }
                      </strong>
                      <br />
                      {
                        this.props.dropOffLocations[
                          this.props.selectedDropOffLocationIndex
                        ].dropOffLocationAddress2
                      }
                      <br />
                      {(this.props.dropOffLocations[
                        this.props.selectedDropOffLocationIndex
                      ].dropOffLocationCity
                        ? this.props.dropOffLocations[
                            this.props.selectedDropOffLocationIndex
                          ].dropOffLocationCity + ", "
                        : "") +
                        (this.props.dropOffLocations[
                          this.props.selectedDropOffLocationIndex
                        ].dropOffLocationState
                          ? this.props.dropOffLocations[
                              this.props.selectedDropOffLocationIndex
                            ].dropOffLocationState + " "
                          : "") +
                        (this.props.dropOffLocations[
                          this.props.selectedDropOffLocationIndex
                        ].dropOffLocationZip5
                          ? this.props.dropOffLocations[
                              this.props.selectedDropOffLocationIndex
                            ].dropOffLocationZip5
                          : "") +
                        (this.props.dropOffLocations[
                          this.props.selectedDropOffLocationIndex
                        ].dropOffLocationZip4
                          ? "-" +
                            this.props.dropOffLocations[
                              this.props.selectedDropOffLocationIndex
                            ].dropOffLocationZip4
                          : "")}
                    </p>
                  </div>
                  <AdditionalDropoffInfo
                    locationName={
                      this.props.dropOffLocations[
                        this.props.selectedDropOffLocationIndex
                      ].dropOffLocationState
                    }
                    physicalState={
                      this.props.dropOffLocations[
                        this.props.selectedDropOffLocationIndex
                      ].locationName
                    }
                  />
                  {/* <p id="eligible-usps-connect-post-offices">
              See all eligible USPS Connect™ Local drop-off locations and their
              serviced ZIP Codes™ at{" "}
              <a
                href="https://tools.usps.com/go/TrackConfirmAction_input"
                className="inline-link right-chevron"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>USPS Connect™ Local</strong>
              </a>
            </p> */}
                  <p id="drop-off-location-fyi">
                    * Please see the bottom of your label for the address of the
                    postal facility you must bring your SCAN form and mail
                    pieces to for processing.
                  </p>
                </div>
              ) : null}
            </React.Fragment>
          )}
      </React.Fragment>
    );
  };

  renderServicesGrid = (item) => {
    this.props.addServiceIconRef(item.productId);
    this.servicesFound = true;
    return (
      <li
        key={item.productId}
        className={
          "package-row" +
          (this.props.configInfo.saveLabelInfoAdditionalData.productId ===
          item.productId
            ? " package-row-selected"
            : "")
        }
        onClick={(e) => this.updateParents(e, item)}
      >
        <div className="row package-del-price-content">
          <div className="col package-row-text">
            <div className="row">
              <div className="package-type-container col">
                <input
                  id={"package-type-radio-grid" + item.productId}
                  type="radio"
                  className="radio-button package-type-radio visuallyhidden"
                  name="radio-package-type"
                  onChange={(e) => this.updateParents(e, item)}
                  checked={
                    this.props.configInfo.saveLabelInfoAdditionalData
                      .productId === item.productId
                  }
                />
                <React.Fragment>
                  {item.productNameDisplay === "Global Express Guaranteed" ? (
                    <p className="package-type-title">
                      Global Express Guaranteed®
                    </p>
                  ) : (
                    <p
                      className="package-type-title"
                      dangerouslySetInnerHTML={{
                        __html: item.productNameDisplay
                      }}
                    />
                  )}
                </React.Fragment>
                <button
                  type="button"
                  className="info-icon domestic-shipping-option-popover-link button-link"
                  data-trigger="focus"
                  data-toggle="popover"
                  data-target="#domestic-shipping-option-modal"
                  data-backdrop="static"
                  tabIndex="0"
                  data-original-title=""
                  title=""
                  id={this.serviceInfoIconIdPrefix + item.productId}
                  onClick={(e) =>
                    this.props.setServiceInfoIconId(e.currentTarget.id)
                  }
                  ref={this.props.serviceIconRefs[item.productId]}
                />
                <p
                  className="package-type-desc-txt"
                  dangerouslySetInnerHTML={{
                    __html: item.boxDimensions
                  }}
                />
              </div>
              <div className="scheduled-delivery-container col">
                <p className="scheduled-delivery-dates">
                  {item.scheduledDelivery}
                </p>
              </div>
              <div className="price-container col">
                <p className="package-price-content">
                  ${item.price.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
          <div className="col package-row-img">
            <img
              src={process.env.PUBLIC_URL + item.serviceProductImage}
              alt={item.serviceProductAlt}
            />
          </div>
        </div>
      </li>
    );
  };

  renderNoServices = () => {
    this.servicesFound = false;
    return (
      <p id="services-empty-table">
        There are no services for the Service Type you selected, please select
        another Service Type.
      </p>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderViewAs()}
        {this.renderDestination()}

        {this.props.serviceInfoIconId !== undefined ? (
          this.props.isDomesticDestination() ? (
            <React.Fragment>
              <PriorityMailPopover
                targetName={this.props.serviceInfoIconId}
                setServiceInfoIconId={this.props.setServiceInfoIconId}
                infoIconServiceId={this.props.serviceInfoIconId.replace(
                  this.serviceInfoIconIdPrefix,
                  ""
                )}
              />{" "}
            </React.Fragment>
          ) : (
            <PriorityMailInternationalPopover
              targetName={this.props.serviceInfoIconId}
              setServiceInfoIconId={this.props.setServiceInfoIconId}
              infoIconServiceId={this.props.serviceInfoIconId.replace(
                this.serviceInfoIconIdPrefix,
                ""
              )}
            />
          )
        ) : null}
      </React.Fragment>
    );
  }
}

export default ServiceTypeViewServices;
