import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import AssistiveError from "../Subcomponents/AssistiveError";

const PrintPostOfficeModal = (props) => {
  const {
    buttonLabel,
    className,
    displayPrintPostOffice,
    toggle,
    emailAddress,
    handleEmailAddress,
    isValidEmail,
    sendCode,
    responseError
  } = props;

  const [displayEmailError, setDisplayEmailError] = useState(false);

  const [focusOnEmailError, setFocusOnEmailError] = useState(false);

  const emailErrorMessage = "Please enter a valid email address.";

  // Focus on the email field when there is a validation error
  useEffect(() => {
    if (focusOnEmailError) {
      if (displayEmailError) {
        document.getElementById("emailAddress-a11y").focus();
      }
      setFocusOnEmailError(false);
    }
  }, [focusOnEmailError]);

  // Focus on the response error message after calling label broker
  useEffect(() => {
    if (responseError) {
      document.getElementById("labelBrokerResponseErrorId").focus();
    }
  }, [responseError]);

  return (
    <React.Fragment>
      <button
        type="button"
        className="inline-link right-chevron button-link"
        data-toggle="modal"
        data-backdrop="static"
        tabIndex="0"
        onClick={toggle}
      >
        {buttonLabel}
      </button>
      <Modal
        isOpen={displayPrintPostOffice}
        toggle={toggle}
        className={className}
      >
        <div className="modal-content modal-container">
          <div className="modal-header">
            <button
              type="button"
              className="close button-link"
              id="close"
              data-dismiss="modal"
              tabIndex="0"
              onClick={toggle}
            >
              <span className="visuallyhidden">Close Modal</span>
            </button>
            <h3 className="modal-title">Print at the Post Office</h3>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <p>
                Choosing this option will send you an email containing this
                label in the form of a Label Broker ID&reg; code. Your labels
                can then be printed by scanning this code at your local Post
                Office&trade;.
              </p>
            </div>
          </div>
          <div
            className={"required-field" + (displayEmailError ? " error" : "")}
          >
            <AssistiveError
              inputId="emailAddress"
              errorMessage={displayEmailError ? emailErrorMessage : ""}
            />
            <label htmlFor="emailAddress" id="for-emailAddress">
              <strong>*Email</strong>
            </label>
            <div className="input-group">
              <input
                type="text"
                name="emailAddress"
                maxLength="50"
                id="emailAddress"
                className="form-control"
                onChange={(e) => {
                  handleEmailAddress(e);
                  setDisplayEmailError(false);
                }}
                value={emailAddress}
              />
            </div>
            <span
              role="alert"
              className="error-message"
              style={
                displayEmailError === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              {emailErrorMessage}
            </span>
          </div>
          <div className="modal-buttons">
            <div className="button-container">
              <button
                type="button"
                className="btn-primary"
                data-dismiss="modal"
                tabIndex="0"
                onClick={() => {
                  if (isValidEmail()) {
                    sendCode();
                  } else {
                    setDisplayEmailError(true);
                    setFocusOnEmailError(true);
                  }
                }}
              >
                Send Code
              </button>
            </div>
          </div>
          <span
            role="alert"
            id="labelBrokerResponseErrorId"
            className="error-message"
            style={
              responseError === "" ? { display: "none" } : { display: "block" }
            }
            tabIndex="-1"
          >
            {responseError}
          </span>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default PrintPostOfficeModal;
