import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const CommercialShipmentPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Check here if this label is being printed for a business transaction, or
        by customers buying or selling goods.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Commercial Shipment Information Icon"
      header="Commercial Shipment"
      content={content}
    />
  );
};

export default CommercialShipmentPopover;
