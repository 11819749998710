import React from "react";

import ConnectLocalSignup from "../Modals/ConnectLocalSignup";

const ConnectLocalSelfEnrollment = (props) => {
  const {
    successSignup,
    refreshUserInfo,
    toggleSpinner,
    isModalActive,
    setIsModalActive,
    carouselStyling
  } = props;

  const textBeforeSignup = (
    <>
      <strong>USPS Connect™ Local is available in your area!</strong> Use
      Click-N-Ship to create your prepaid label and bring your labeled packages
      or envelopes to the designated postal facility to get same-day or next-day
      service in your neighborhood at affordable rates. Sign up today and save!
    </>
  );

  const textAfterSignup = (
    <>
      <strong>You are now enrolled in USPS Connect™ Local!</strong> Start
      shipping and saving today by choosing USPS Connect™ Local in the Service
      Type dropdown during label creation for eligible ZIP Codes™. You will be
      notified of any eligible labels in a shipment during the Service Type
      selection.{" "}
      <a
        href="https://www.uspsconnect.com"
        style={{
          textDecoration: "underline",
          color: "#3573b1"
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Read more about USPS Connect Local
      </a>{" "}
      or get started below!
    </>
  );

  if (carouselStyling) {
    if (!successSignup) {
      // The banner carousel is active and the user has not signed up for
      // Connect Local yet
      return (
        <div className="row">
          <div id="selfEnrollmentText" className="col-12 col-md-9 col-lg-10">
            <p className="bannerCarouselText">{textBeforeSignup}</p>
          </div>
          <div
            id="selfEnrollmentLink"
            className="col-12 col-md-3 col-lg-2 text-center banner-button"
          >
            <ConnectLocalSignup
              buttonLabel={"Sign Up"}
              refreshUserInfo={refreshUserInfo}
              toggleSpinner={toggleSpinner}
              isModalActive={isModalActive}
              setIsModalActive={setIsModalActive}
            />
          </div>
        </div>
      );
    } else {
      // The banner carousel is active and the user has signed up for
      // Connect Local
      return <p className="bannerCarouselText">{textAfterSignup}</p>;
    }
  } else {
    if (!successSignup) {
      // The banner carousel is not active and the user has not signed up
      // for Connect Local yet
      return (
        <div
          id="usps-connect-local-self-enrollment-banner-wrapper"
          className="row container-fluid"
          style={{
            display: "flex",
            background: "#ededed",
            borderLeft: "15px solid #3573b1",
            marginRight: "auto",
            marginLeft: "auto",
            marginTop: "20px",
            fontSize: "16px"
          }}
        >
          <div
            id="usps-connect-local-self-enrollment-banner-description-wrapper"
            className="col-12 col-sm-8 col-md-9 col-lg-10"
          >
            <p
              id="usps-connect-local-self-enrollment-banner-description"
              style={{ color: "#000000" }}
            >
              {textBeforeSignup}
            </p>
          </div>
          <div className="col-12 col-sm-4 col-md-3 col-lg-2 text-center banner-button">
            <ConnectLocalSignup
              buttonLabel={"Sign Up"}
              refreshUserInfo={refreshUserInfo}
              toggleSpinner={toggleSpinner}
              isModalActive={isModalActive}
              setIsModalActive={setIsModalActive}
            />
          </div>
        </div>
      );
    } else {
      // The banner carousel is not active and the user has signed up
      // for Connect Local
      return (
        <div
          id="usps-connect-local-after-self-enrollment-banner-wrapper"
          className="row container-fluid"
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            background: "#ededed",
            borderLeft: "15px solid #218748",
            marginRight: "auto",
            marginLeft: "auto",
            marginTop: "20px",
            fontSize: "16px"
          }}
        >
          <div
            id="usps-connect-local-after-self-enrollment-banner-description-wrapper"
            className="col-12"
          >
            <p
              id="usps-connect-local-after-self-enrollment-banner-description"
              style={{
                color: "#000000"
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.32"
                height="21.82"
                viewBox="0 0 24.32 21.82"
              >
                <g
                  id="Component_105"
                  data-name="Component 105"
                  transform="translate(0 0.979)"
                >
                  <g
                    id="Ellipse_72"
                    data-name="Ellipse 72"
                    transform="translate(0 0.206)"
                    fill="none"
                    stroke="#218748"
                    strokeWidth="2.5"
                  >
                    <circle
                      cx="10.318"
                      cy="10.318"
                      r="10.318"
                      stroke="none"
                    ></circle>
                    <circle
                      cx="10.318"
                      cy="10.318"
                      r="9.068"
                      fill="none"
                    ></circle>
                  </g>
                  <rect
                    id="Rectangle_1112"
                    data-name="Rectangle 1112"
                    width="13.033"
                    height="6.516"
                    transform="translate(10.665 8.553) rotate(-47)"
                    fill="#fff"
                  ></rect>
                  <line
                    id="Line_479"
                    data-name="Line 479"
                    x2="3.801"
                    y2="3.801"
                    transform="translate(6.788 9.709)"
                    fill="none"
                    stroke="#218748"
                    strokeLinecap="round"
                    strokeWidth="2.5"
                  ></line>
                  <line
                    id="Line_481"
                    data-name="Line 481"
                    x1="10.318"
                    y2="10.318"
                    transform="translate(10.589 3.192)"
                    fill="none"
                    stroke="#218748"
                    strokeLinecap="round"
                    strokeWidth="2.5"
                  ></line>
                  <line
                    id="Line_482"
                    data-name="Line 482"
                    x1="8.689"
                    y2="8.689"
                    transform="translate(10.589 4.822)"
                    fill="none"
                    stroke="#218748"
                    strokeLinecap="square"
                    strokeWidth="2.5"
                  ></line>
                  <line
                    id="Line_483"
                    data-name="Line 483"
                    x2="2.172"
                    y2="2.172"
                    transform="translate(8.417 11.338)"
                    fill="none"
                    stroke="#218748"
                    strokeLinecap="square"
                    strokeWidth="2.5"
                  ></line>
                </g>
              </svg>{" "}
              {textAfterSignup}
            </p>
          </div>
        </div>
      );
    }
  }
};

export default ConnectLocalSelfEnrollment;
