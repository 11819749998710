import axios from "axios";
import ReactGA from "react-ga";

export function getAlert() {
  alert("getAlert from Child");
}

export function setUserInformation(callback, eventType, data) {
  axios
    .get("/go/cnsrest/security")
    .then((data) => {})
    .catch((error) => {})
    .finally(function () {
      if (typeof callback === "function") {
        callback(eventType)(data);
      }
    });
}

// NOT USING UNTIL LunaMetrics CLARIFIES
export function createLabelDataLayer(location) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "CNSlabel",
    attribute: {
      category: "Click-N-Ship Interactions",
      action: "Create a Label",
      label: location
    }
  });
}

// PREFERENCES -- TRIGGERED WHEN USER CLICKS 'SAVE' ON A PREFERENCE
export function preferences_PackageOptions(preferenceData) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var label = null;
  var dimension147 = null;
  var dimension148 = null;

  // leave dimension147 & dimension148 null if there's no service selected
  if (
    "No Service Assigned" === preferenceData.domesticProductId.productClassDesc
  ) {
    label = preferenceData.domesticProductId.productClassDesc;
  } else {
    // All values should be available in preferenceData.domesticProductId object
    label = preferenceData.domesticProductId.productDescription;

    if ("Y" === preferenceData.flatRateInd) {
      dimension147 = "Flat Rate";
    } else if ("N" === preferenceData.flatRateInd) {
      dimension147 = "Weight";
    }

    dimension148 = preferenceData.domesticProductId.productDimensions;
  }

  window.dataLayer.push({
    event: "CNSprefPackage",
    category: "Click-N-Ship Preferences",
    action: "Package Options",
    label: label,
    attribute: {
      category: "Click-N-Ship Preferences",
      action: "Package Options",
      label: label, // String: package Type ex: 'Priority Mail Small Flat Rate Envelope'
      dimension147: dimension147, // CNS Package Flat Rate or Weight
      dimension148: dimension148 // CNS Preference Package Size
    }
  });
}

export function preferences_ShipmentEmailNotification(preferenceData) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var metric134 = preferenceData.emailUpdatesInd === "Y" ? 1 : 0;
  var metric135 = preferenceData.ptsTxtUpdatesInd === "Y" ? 1 : 0;
  var metric136 = preferenceData.allUpdates === "Y" ? 1 : 0;
  var metric137 = preferenceData.ptsExpectedDelvInd === "Y" ? 1 : 0;
  var metric138 = preferenceData.ptsDayOfDelvInd === "Y" ? 1 : 0;
  var metric139 = preferenceData.ptsDeliveredToAddrInd === "Y" ? 1 : 0;
  var metric140 = preferenceData.ptsPickupAvailableInd === "Y" ? 1 : 0;
  var metric141 = preferenceData.ptsAlertsOtherInd === "Y" ? 1 : 0;
  var metric142 = preferenceData.ptsPkgInTransitInd === "Y" ? 1 : 0;

  window.dataLayer.push({
    // Event Information
    event: "CNSpreferencesTracking",
    attribute: {
      category: "CNS Preferences",
      action: "Tracking Notifications",
      label: "Save Preferences",
      metric134: metric134,
      metric135: metric135,
      metric136: metric136,
      metric137: metric137,
      metric138: metric138,
      metric139: metric139,
      metric140: metric140,
      metric141: metric141,
      metric142: metric142
    }
  });
}

export function preferences_NotifyRecipientOfShipping(preferenceData) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var label =
    "Y" === preferenceData.shipNotifyInd
      ? "Receive Notifications"
      : "Do not receive notifications";

  window.dataLayer.push({
    event: "CNSprefNotify",
    attribute: {
      category: "Click-N-Ship Preferences",
      action: "Notify Recipient of Shipping",
      label: label // Based on checkbox. If checked: 'Receive notifications' If unchecked: 'Do not receive notifications'
    }
  });
}

export function preferences_Print(preferenceData) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var label =
    "Y" === preferenceData.printOptions
      ? "Print labels with receipt"
      : "Print labels without receipt";

  window.dataLayer.push({
    event: "CNSprefPrint",
    attribute: {
      category: "Click-N-Ship Preferences",
      action: "Print Preferences",
      label: label // 'Print labels with receipt' OR 'Print labels without receipt'
    }
  });
}

export function preferences_ReferenceNumber(preferenceData) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var label = preferenceData.refNbr
    ? "Reference number set"
    : "No reference number has been set";

  window.dataLayer.push({
    event: "CNSprefReference",
    attribute: {
      category: "Click-N-Ship Preferences",
      action: "Reference Number",
      label: label //'Reference number set' OR 'No reference number has been set'
    }
  });
}

// This one won't really work...should be adding an event to the 'delete' link for return address preference
export function preferences_ReturnAddress(preferenceData) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var label = preferenceData.userReturnAddr
    ? "Return address set"
    : "No return address has been set";

  window.dataLayer.push({
    event: "CNSprefReturn",
    attribute: {
      category: "Click-N-Ship Preferences",
      action: "Return Address",
      label: label // 'Return address set' OR 'No return address has been set' if that is an option
    }
  });
}

export function preferences_ShipFromZipCode(preferenceData) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var label = preferenceData.shipFromPostalCode
    ? "Alternate zip code set"
    : "No alternate zip code set";

  window.dataLayer.push({
    event: "CNSprefZip",
    attribute: {
      category: "Click-N-Ship Preferences",
      action: "Shipping from Zip Code",
      label: label //'Alternate zip code set' OR 'No alternate zip code set'
    }
  });
}

// ShippingHistory -- TRIGGERED WHEN USER CLICKS 'SEARCH' OR 'PROCEED' BUTTONS RESPECTIVELY
/** Based on dropdown selection, options include:
 * 		'All Labels'
 * 		'Transaction / Label'
 * 		'Name / Company'
 * 		'Pending Transactions'
 * 		'Refund Transactions'
 */
export function shippingHistory_Search(searchFilterSelection) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "CNShistSearch",
    category: "Click-N-Ship Interactions",
    action: "Search",
    label: searchFilterSelection,
    attribute: {
      category: "Click-N-Ship Interactions",
      action: "Search",
      label: searchFilterSelection
    }
  });
}

/** Based on dropdown selection, options include:
 * 		'Track Labels'
 * 		'Ship Labels Again'
 * 		'Print Labels'
 * 		'Save Labels'
 * 		'Refund Labels'
 * 		'Cancel Refund'
 */
export function shippingHistory_Proceed(proceedFilterSelection) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "CNShistProceed",
    attribute: {
      category: "Click-N-Ship Interactions",
      action: "Proceed",
      label: proceedFilterSelection
    }
  });
}

// CONTENT GROUPS & CUSTOM DIMENSIONS
/**
 * userData contains:
 * 		userId
 * 		accountType
 * 		accountStartDate
 * 		manifestCount (not currently used)
 */
export function userInformation(userData) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    category: "Click-N-Ship userInformation",
    action: "userInformation",
    label: "userInformation",
    hitType: "event",
    eventCategory: "Click-N-Ship userInformation",
    eventAction: "userInformation",
    // User Information
    loginStatus: "Logged In", // or 'Not Logged In', Login Status
    accountType: userData.accountType, //'Personal', // Account Type, customer_type
    customerID: userData.userId, //'123456789', // Customer ID, crid
    accountStartDate: userData.accountStartDate //'2016-08-08', // Account Start Date, account_startdate

    // NOT BEING USED RIGHT NOW BECAUSE IT HASN'T BEEN DECIDED HOW THESE FIELDS ARE DETERMINED
    //			'customerSpending': 'Large Spender', // Customer Spending
    //			'customerLoyalty': 'Heavy User', // Customer Loyalty
  });
}

////////////////////////////////////////////////////////////////////////////////////
/**
 * Quantity values should be integers; all other values should be strings
 * For each product in the transaction, the name or ID (SKU) is also required -?
 */
export function transaction_Purchase(purchaseData, userData) {
  ReactGA.initialize("GTM-MVCC8H", { debug: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );

  var products = [];

  // BEST GUESSES HERE MAPPING VALUES...WILL WAIT FOR FEEDBACK
  products = purchaseData.items.map(function (obj, idx) {
    var dimension125 = obj.detailedServiceDescription
      .trim()
      .split("</div>")
      .map(function (obj, idx) {
        return obj.replace(/<br\/>|<br>/g, " ").replace(/<\/?[^>]+(>|$)/g, "");
      })
      .filter(function (obj, idx) {
        return obj.length > 0;
      })
      .join(" | ");

    var dimension129 =
      obj.packageIndicators.crematedRemainsInd &&
      obj.packageIndicators.crematedRemainsInd === "Y"
        ? "I am shipping Cremated Remains"
        : "" + obj.packageIndicators.noWkndDlvyInd &&
          obj.packageIndicators.noWkndDlvyInd === "Y"
        ? "No Delivery on Saturdays"
        : "";
    if (dimension129.length <= 0) {
      dimension129 = null;
    }

    var dimension130 =
      obj.packageIndicators.nonDelvOption &&
      obj.packageIndicators.nonDelvOption.displayText
        ? obj.packageIndicators.nonDelvOption.displayText
        : null;

    var dimension131 =
      obj.packageIndicators.additionalService &&
      obj.packageIndicators.additionalService.displayText
        ? obj.packageIndicators.additionalService.displayText
        : null;
    var metric131 = obj.packageServiceDetails.additionalServiceAmt
      ? obj.packageServiceDetails.additionalServiceAmt.toFixed(2)
      : null;

    var dimension133 =
      obj.packageIndicators.ptsNotifyInd &&
      obj.packageIndicators.ptsNotifyInd === "Y"
        ? "I would like to get tracking notifications"
        : null;

    var dimension134 =
      obj.packageIndicators.flatRateInd &&
      obj.packageIndicators.flatRateInd === "Y"
        ? "Flat Rate"
        : obj.packageDetails.pounds + "lb " + obj.packageDetails.ounces + "oz";

    var dimension135 =
      obj.packageDetails.pkgValueAmt && obj.packageDetails.pkgValueAmt > 0
        ? obj.packageDetails.pkgValueAmt.toFixed(2)
        : null;

    var dimension138 = null,
      dimension139 = null,
      dimension140 = null,
      dimension141 = null,
      dimension142 = null;

    if ("Y" === obj.packageIndicators.gxgPkgInd) {
      dimension138 = obj.packageDetails.containerType
        ? obj.packageDetails.containerType.name
        : null;

      dimension142 =
        obj.packageItems && obj.packageItems.length > 0
          ? obj.packageItems[0].itemType
          : null;
    }

    var dimension143 =
      obj.packageItems && obj.packageItems.length > 0
        ? obj.packageItems[0].unitValue.toFixed(2)
        : null;
    var dimension144 =
      obj.packageItems && obj.packageItems.length > 0
        ? obj.packageItems[0].itemQty
        : null;
    var dimension145 =
      obj.packageItems && obj.packageItems.length > 0
        ? obj.packageItems[0].pounds + "lb " + obj.packageItems[0].ounces + "oz"
        : null;

    var dimension146 =
      obj.packageCustomsDetails && obj.packageCustomsDetails.eelCode
        ? obj.packageCustomsDetails.eelCode.value +
          " " +
          obj.packageCustomsDetails.eelCode.description
        : null;
    var metric143 = obj.packageIndicators.emailUpdatesInd === "Y" ? 1 : 0;
    var metric144 = obj.packageIndicators.ptsTxtUpdatesInd === "Y" ? 1 : 0;
    var metric145 = obj.packageIndicators.allUpdates === "Y" ? 1 : 0;
    var metric146 = obj.packageIndicators.ptsExpectedDelvInd === "Y" ? 1 : 0;
    var metric147 = obj.packageIndicators.ptsDayOfDelvInd === "Y" ? 1 : 0;
    var metric148 = obj.packageIndicators.ptsDeliveredToAddrInd === "Y" ? 1 : 0;
    var metric149 = obj.packageIndicators.ptsPickupAvailableInd === "Y" ? 1 : 0;
    var metric150 = obj.packageIndicators.ptsAlertsOtherInd === "Y" ? 1 : 0;
    var metric151 = obj.packageIndicators.ptsPkgInTransitInd === "Y" ? 1 : 0;

    if (
      metric146 === 1 &&
      metric147 === 1 &&
      metric148 === 1 &&
      metric149 === 1 &&
      metric150 === 1 &&
      metric151 === 1
    ) {
      metric145 = 1;
    } else {
      metric145 = 0;
    }

    return {
      name: obj.product.productClassDesc + " " + obj.product.productDescription, //'CNS Priority Mail Express Flat Rate Envelope', // Product Name, Package type
      price: obj.packageServiceDetails.ttlShipCostAmt.toString(), //'28.65', // Product Price
      brand: "USPS CNS", // Product Brand,
      category: "Click-N-Ship", // Product Category,
      variant: obj.product.productClassDesc, // 'Priority Mail Express', // Product Variant, Service type,
      quantity: 1, // Product Quantity,
      dimension125: dimension125, //'CNS Priority Mail Express Flat Rate Envelope | CNS Insurance for packages valued up to 100 | CNS USPS Tracking | CNS Adult Signature Required 21 or Older', // CNS Label Summary,
      metric125: obj.packageServiceDetails.ttlShipCostAmt.toString(), //'28.65', // CNS Label Price,
      dimension126:
        obj.product.productClassDesc + " " + obj.product.productDescription, //'CNS Priority Mail Express Flat Rate Envelope', 				// CNS Package Type
      metric126: obj.packageServiceDetails.postageAmt.toFixed(2), //'22.95', // CNS Package Type Price
      dimension127: obj.packageIndicators.insuranceInd, //'CNS Insurance for packages valued up to 100', // CNS Insurance Type
      metric127: obj.packageServiceDetails.insuranceCostAmt
        ? obj.packageServiceDetails.insuranceCostAmt.toFixed(2)
        : "0.00", //'0.00', // CNS Insurance Type Price
      dimension128: "CNS USPS Tracking", // CNS Tracking Type
      metric128: "0.00", // CNS Tracking Type Price -- WE DON'T CHARGE FOR TRACKING..?
      dimension129: dimension129, // CNS Extra Services
      metric129: null, // CNS Extra Services Price
      dimension130: dimension130, // CNS Non-Delivery Option
      metric130: null, // CNS Non-Delivery Option Price
      dimension131: dimension131, //'CNS Adult Signature Required 21 or Older', // CNS Signature Type
      metric131: metric131, //'5.70', // CNS Signature Type Price
      dimension132: obj.trackingNbr, //'389248900002', // CNS Label Number
      dimension133: dimension133, //'I would like to get tracking notifications | Save this to my address book', // CNS Additional Actions Selected
      dimension134: dimension134, //'Flat Rate', 	// CNS Weight Details
      dimension135: dimension135, //'1.00', // CNS Package Value
      dimension136: obj.product.productDimensions, //'12-1/2x9-1/2', // CNS Package Size
      dimension137: null, // CNS Total Customs Pages
      dimension138: dimension138, // CNS GXG Package Type
      dimension139: dimension139, // CNS GXG Content Type
      dimension140: dimension140, // CNS GXG Package Dimension
      dimension141: dimension141, // CNS GXG Package Shape
      dimension142: dimension142, // CNS GXG Item Type
      dimension143: dimension143, // CNS Customs Item Value
      dimension144: dimension144, // CNS Customs Item Quantity
      dimension145: dimension145, // CNS Customs Item Weight
      dimension146: dimension146, // CNS Customs Export Exemption
      metric143: metric143, // Email Updates
      metric144: metric144, // Text Updates
      metric145: metric145, // All Updates
      metric146: metric146, // Expected Delivery
      metric147: metric147, // Day of Delivery
      metric148: metric148, // Package Delivered
      metric149: metric149, // Available for Pickup
      metric150: metric150, // Alerts & Other Updates
      metric151: metric151 // Package In-Transit
    };
  });

  window.dataLayer.push({
    // User Information
    loginStatus: "Logged In",
    accountType: userData.accountType,
    customerID: userData.userId,
    accountStartDate: userData.accountStartDate,
    hitType: "event",
    eventCategory: "ecomPurchase",
    eventAction: "ecomPurchase",
    // Ecommerce Information
    event: "ecomPurchase",
    category: "ecomPurchase",
    action: "ecomPurchase",
    ecommerce: {
      purchase: {
        actionField: {
          id: purchaseData.orderId.toString(), // 'T12345', // Transaction ID. Required.
          affiliation: "USPS CNS", // Affiliation always = 'USPS CNS'
          revenue: purchaseData.orderTotal.substring(1), //'266.75', // Total incl. extra services, shipping, and tax
          tax: "0.00", // Only tax -- we don't charge tax
          shipping: "0.00" // Only shipping -- TODO: Is this only on postage?
        },
        products: products
      }
    }
  });
}

export function addToCart_CreateLabelPage(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var mainDataLayerObj = data.map(function (task, index, array) {
    var dimension125 = task.detailedServiceDescription
      .trim()
      .split("</div>")
      .map(function (obj, idx) {
        return obj
          .replace(/<br\/>|<br>/g, " ")
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/&reg\b/, "")
          .replace(/;/, "");
      })
      .filter(function (obj, idx) {
        return obj.length > 0;
      })
      .join(" | ");

    var dimension129 =
      task.packageIndicators.crematedRemainsInd &&
      task.packageIndicators.crematedRemainsInd === "Y"
        ? "I am shipping Cremated Remains"
        : "" + task.packageIndicators.noWkndDlvyInd &&
          task.packageIndicators.noWkndDlvyInd === "Y"
        ? "No Delivery on Saturdays"
        : "";

    if (dimension129.length <= 0) {
      dimension129 = null;
    }
    var dimension130 =
      task.packageIndicators.nonDelvOption &&
      task.packageIndicators.nonDelvOption.displayText
        ? task.packageIndicators.nonDelvOption.displayText
        : null;

    var dimension131 =
      task.packageIndicators.additionalService &&
      task.packageIndicators.additionalService.displayText
        ? task.packageIndicators.additionalService.displayText
        : null;

    var metric131 = task.packageServiceDetails.additionalServiceAmt
      ? task.packageServiceDetails.additionalServiceAmt.toFixed(2)
      : null;

    var dimension133 =
      task.packageIndicators.ptsNotifyInd &&
      task.packageIndicators.ptsNotifyInd === "Y"
        ? "I would like to get tracking notifications"
        : null;

    var dimension134 =
      task.packageIndicators.flatRateInd &&
      task.packageIndicators.flatRateInd === "Y"
        ? "Flat Rate"
        : task.packageDetails.pounds +
          "lb " +
          task.packageDetails.ounces +
          "oz";

    var dimension135 =
      task.packageDetails.pkgValueAmt && task.packageDetails.pkgValueAmt > 0
        ? task.packageDetails.pkgValueAmt.toFixed(2)
        : null;

    var dimension138 = null,
      dimension139 = null,
      dimension140 = null,
      dimension141 = null,
      dimension142 = null;

    if ("Y" === task.packageIndicators.gxgPkgInd) {
      dimension138 = task.packageDetails.containerType
        ? task.packageDetails.containerType.name
        : null;
      dimension142 =
        task.packageItems && task.packageItems.length > 0
          ? task.packageItems[0].itemType
          : null;
    }

    var dimension143 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].unitValue.toFixed(2)
        : null;

    var dimension144 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].itemQty
        : null;

    var dimension145 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].pounds +
          "lb " +
          task.packageItems[0].ounces +
          "oz"
        : null;

    var dimension146 =
      task.packageCustomsDetails && task.packageCustomsDetails.eelCode
        ? task.packageCustomsDetails.eelCode.value +
          " " +
          task.packageCustomsDetails.eelCode.description
        : null;

    var metric143 = task.labelInd.emailUpdatesInd === "Y" ? 1 : 0;

    var metric144 = task.labelInd.ptsTxtUpdatesInd === "Y" ? 1 : 0;

    var metric145 = task.labelInd.allUpdates === "Y" ? 1 : 0;

    var metric146 = task.labelInd.ptsExpectedDelvInd === "Y" ? 1 : 0;

    var metric147 = task.labelInd.ptsDayOfDelvInd === "Y" ? 1 : 0;

    var metric148 = task.labelInd.ptsDeliveredToAddrInd === "Y" ? 1 : 0;

    var metric149 = task.labelInd.ptsPickupAvailableInd === "Y" ? 1 : 0;

    var metric150 = task.labelInd.ptsAlertsOtherInd === "Y" ? 1 : 0;

    var metric151 = task.labelInd.ptsPkgInTransitInd === "Y" ? 1 : 0;

    return {
      name:
        task.product.productClassDesc.replace("&reg;", "") +
        " " +
        task.product.productDescription,
      price: task.packageServiceDetails.ttlShipCostAmt.toString(),
      brand: "USPS CNS",
      category: "Click-N-Ship",
      variant: task.product.productClassDesc,
      quantity: task.length,
      dimension125: dimension125,
      metric125: task.packageServiceDetails.ttlShipCostAmt.toString(),
      dimension126:
        task.product.productClassDesc.replace(/&reg;/, "") +
        " " +
        task.product.productDescription.replace(/&reg;/, ""),
      metric126: task.packageServiceDetails.postageAmt.toFixed(2),
      dimension127: task.packageIndicators.insuranceInd,
      dimension128: "CNS USPS Tracking",
      metric128: "0.00",
      dimension129: dimension129,
      metric129: task.labelCost.additionalServiceAmt,
      dimension130: dimension130,
      metric130: null,
      dimension131: dimension131, //'CNS Adult Signature Required 21 or Older', // CNS Signature Type
      metric131: metric131, //'5.70', // CNS Signature Type Price
      dimension132: task.trackingNbr, //'389248900002', // CNS Label Number
      dimension133: dimension133, //'I would like to get tracking notifications | Save this to my address book', // CNS Additional Actions Selected
      dimension134: dimension134, //'Flat Rate', 	// CNS Weight Details
      dimension135: dimension135, //'1.00', // CNS Package Value
      dimension136: task.product.productDimensions, //'12-1/2x9-1/2', // CNS Package Size
      dimension137: null, // CNS Total Customs Pages
      dimension138: dimension138, // CNS GXG Package Type
      dimension139: dimension139, // CNS GXG Content Type
      dimension140: dimension140, // CNS GXG Package Dimension
      dimension141: dimension141, // CNS GXG Package Shape
      dimension142: dimension142, // CNS GXG Item Type
      dimension143: dimension143, // CNS Customs Item Value
      dimension144: dimension144, // CNS Customs Item Quantity
      dimension145: dimension145, // CNS Customs Item Weight
      dimension146: dimension146,
      metric143: metric143, // Email Updates
      metric144: metric144, // Text Updates
      metric145: metric145, // All Updates
      metric146: metric146, // Expected Delivery
      metric147: metric147, // Day of Delivery
      metric148: metric148, // Package Delivered
      metric149: metric149, // Available for Pickup
      metric150: metric150, // Alerts & Other Updates
      metric151: metric151 // Package In-Transit
    };
  });

  window.dataLayer.push({
    event: "ecomAdd",
    action: "ecomAdd",
    category: "ecomAdd",
    ecommerce: {
      add: {
        products: mainDataLayerObj
      }
    }
  });
}

export function addToCart_ShippingHistoryShipAgain(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var mainDataLayerObj = data.items.reduce(function (filtered, task) {
    // var cookie = document.cookie
    //   .split(";")
    //   .map(function(arr) {
    //     return arr.trim().split(/(=)/);
    //   })
    //   .reduce(function(outer, inner) {
    //     outer[inner[0]] = outer[inner[0]] ? outer[inner[0]] + ", " + inner.slice(2).join("") : inner.slice(2).join("");
    //     return outer;
    //   }, []);

    var dimension125 = task.detailedServiceDescription
      .trim()
      .split("</div>")
      .map(function (obj, idx) {
        return obj
          .replace(/<br\/>|<br>/g, " ")
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/&reg\b/, "")
          .replace(/;/, "");
      })
      .filter(function (obj, idx) {
        return obj.length > 0;
      })
      .join(" | ");

    var dimension129 =
      task.packageIndicators.crematedRemainsInd &&
      task.packageIndicators.crematedRemainsInd === "Y"
        ? "I am shipping Cremated Remains"
        : "" + task.packageIndicators.noWkndDlvyInd &&
          task.packageIndicators.noWkndDlvyInd === "Y"
        ? "No Delivery on Saturdays"
        : "";

    if (dimension129.length <= 0) {
      dimension129 = null;
    }
    var dimension130 =
      task.packageIndicators.nonDelvOption &&
      task.packageIndicators.nonDelvOption.displayText
        ? task.packageIndicators.nonDelvOption.displayText
        : null;

    var dimension131 =
      task.packageIndicators.additionalService &&
      task.packageIndicators.additionalService.displayText
        ? task.packageIndicators.additionalService.displayText
        : null;

    var metric131 = task.packageServiceDetails.additionalServiceAmt
      ? task.packageServiceDetails.additionalServiceAmt.toFixed(2)
      : null;

    var dimension133 =
      task.packageIndicators.ptsNotifyInd &&
      task.packageIndicators.ptsNotifyInd === "Y"
        ? "I would like to get tracking notifications"
        : null;

    var dimension134 =
      task.packageIndicators.flatRateInd &&
      task.packageIndicators.flatRateInd === "Y"
        ? "Flat Rate"
        : task.packageDetails.pounds +
          "lb " +
          task.packageDetails.ounces +
          "oz";

    var dimension135 =
      task.packageDetails.pkgValueAmt && task.packageDetails.pkgValueAmt > 0
        ? task.packageDetails.pkgValueAmt.toFixed(2)
        : null;

    var dimension138 = null,
      dimension139 = null,
      dimension140 = null,
      dimension141 = null,
      dimension142 = null;

    if ("Y" === task.packageIndicators.gxgPkgInd) {
      dimension138 = task.packageDetails.containerType
        ? task.packageDetails.containerType.name
        : null;
      dimension142 =
        task.packageItems && task.packageItems.length > 0
          ? task.packageItems[0].itemType
          : null;
    }

    var dimension143 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].unitValue.toFixed(2)
        : null;

    var dimension144 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].itemQty
        : null;

    var dimension145 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].pounds +
          "lb " +
          task.packageItems[0].ounces +
          "oz"
        : null;

    var dimension146 =
      task.packageCustomsDetails && task.packageCustomsDetails.eelCode
        ? task.packageCustomsDetails.eelCode.value +
          " " +
          task.packageCustomsDetails.eelCode.description
        : null;

    var name =
      task.product.productClassDesc + " " + task.product.productDescription;
    var price = task.packageServiceDetails.ttlShipCostAmt.toString();
    var variant = task.product.productClassDesc;
    var metric125 = task.packageServiceDetails.ttlShipCostAmt.toString();
    var dimension126 =
      task.product.productClassDesc.replace(/&reg;/, "") +
      " " +
      task.product.productDescription.replace(/&reg;/, "");
    var metric126 = task.packageServiceDetails.postageAmt.toFixed(2);
    var dimension127 = task.packageIndicators.insuranceInd;
    var metric127 = task.packageServiceDetails.insuranceCostAmt.toFixed(2);
    var dimension132 = task.trackingNbr;
    var dimension136 = task.product.productDimensions;

    var ReturnValue = {
      name: name,
      price: price,
      brand: "USPS CNS",
      category: "Click-N-Ship",
      variant: variant,
      quantity: 1,
      dimension125: dimension125,
      metric125: metric125,
      dimension126: dimension126,
      metric126: metric126,
      dimension127: dimension127,
      metric127: metric127,
      dimension128: "CNS USPS Tracking",
      metric128: "0.00",
      dimension129: dimension129,
      metric129: null,
      dimension130: dimension130,
      metric130: null,
      dimension131: dimension131,
      metric131: metric131,
      dimension132: dimension132,
      dimension133: dimension133,
      dimension134: dimension134,
      dimension135: dimension135,
      dimension136: dimension136,
      dimension137: null,
      dimension138: dimension138,
      dimension139: dimension139,
      dimension140: dimension140,
      dimension141: dimension141,
      dimension142: dimension142,
      dimension143: dimension143,
      dimension144: dimension144,
      dimension145: dimension145,
      dimension146: dimension146
    };

    // cookie.tempHistoryID.indexOf(task.packageDetails.cnsCartId.toString()) !== -1

    //   if(!cookie.tempHistoryID.includes(task.packageDetails.cnsCartId.toString())){

    filtered.push(ReturnValue);

    return filtered;
  }, []);

  window.dataLayer.push({
    event: "ecomAdd",
    action: "ecomAdd",
    category: "ecomAdd",
    ecommerce: {
      add: {
        products: mainDataLayerObj
      }
    }
  });
}

export function removeFromCart_ShippingCartDelete(data, cnsCartId) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var mainDataLayerObj = data.items.reduce(function (filtered, task) {
    var dimension125 = task.detailedServiceDescription
      .trim()
      .split("</div>")
      .map(function (obj, idx) {
        return obj
          .replace(/<br\/>|<br>/g, " ")
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/&reg\b/, "")
          .replace(/;/, "");
      })
      .filter(function (obj, idx) {
        return obj.length > 0;
      })
      .join(" | ");

    var dimension129 =
      task.packageIndicators.crematedRemainsInd &&
      task.packageIndicators.crematedRemainsInd === "Y"
        ? "I am shipping Cremated Remains"
        : "" + task.packageIndicators.noWkndDlvyInd &&
          task.packageIndicators.noWkndDlvyInd === "Y"
        ? "No Delivery on Saturdays"
        : "";

    if (dimension129.length <= 0) {
      dimension129 = null;
    }
    var dimension130 =
      task.packageIndicators.nonDelvOption &&
      task.packageIndicators.nonDelvOption.displayText
        ? task.packageIndicators.nonDelvOption.displayText
        : null;

    var dimension131 =
      task.packageIndicators.additionalService &&
      task.packageIndicators.additionalService.displayText
        ? task.packageIndicators.additionalService.displayText
        : null;

    var metric131 = task.packageServiceDetails.additionalServiceAmt
      ? task.packageServiceDetails.additionalServiceAmt.toFixed(2)
      : null;

    var dimension133 =
      task.packageIndicators.ptsNotifyInd &&
      task.packageIndicators.ptsNotifyInd === "Y"
        ? "I would like to get tracking notifications"
        : null;

    var dimension134 =
      task.packageIndicators.flatRateInd &&
      task.packageIndicators.flatRateInd === "Y"
        ? "Flat Rate"
        : task.packageDetails.pounds +
          "lb " +
          task.packageDetails.ounces +
          "oz";

    var dimension135 =
      task.packageDetails.pkgValueAmt && task.packageDetails.pkgValueAmt > 0
        ? task.packageDetails.pkgValueAmt.toFixed(2)
        : null;

    var dimension138 = null,
      dimension139 = null,
      dimension140 = null,
      dimension141 = null,
      dimension142 = null;

    if ("Y" === task.packageIndicators.gxgPkgInd) {
      dimension138 = task.packageDetails.containerType
        ? task.packageDetails.containerType.name
        : null;
      dimension142 =
        task.packageItems && task.packageItems.length > 0
          ? task.packageItems[0].itemType
          : null;
    }

    var dimension143 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].unitValue.toFixed(2)
        : null;

    var dimension144 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].itemQty
        : null;

    var dimension145 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].pounds +
          "lb " +
          task.packageItems[0].ounces +
          "oz"
        : null;

    var dimension146 =
      task.packageCustomsDetails && task.packageCustomsDetails.eelCode
        ? task.packageCustomsDetails.eelCode.value +
          " " +
          task.packageCustomsDetails.eelCode.description
        : null;

    var name =
      task.product.productClassDesc + " " + task.product.productDescription;
    var price = task.packageServiceDetails.ttlShipCostAmt.toString();
    var variant = task.product.productClassDesc;
    var metric125 = task.packageServiceDetails.ttlShipCostAmt.toString();
    var dimension126 =
      task.product.productClassDesc.replace(/&reg;/, "") +
      " " +
      task.product.productDescription.replace(/&reg;/, "");
    var metric126 = task.packageServiceDetails.postageAmt.toFixed(2);
    var dimension127 = task.packageIndicators.insuranceInd;
    var metric127 = task.packageServiceDetails.insuranceCostAmt.toFixed(2);
    var dimension132 = task.trackingNbr;
    var dimension136 = task.product.productDimensions;

    var ReturnValue = {
      name: name,
      price: price,
      brand: "USPS CNS",
      category: "Click-N-Ship",
      variant: variant,
      quantity: 1,
      dimension125: dimension125,
      metric125: metric125,
      dimension126: dimension126,
      metric126: metric126,
      dimension127: dimension127,
      metric127: metric127,
      dimension128: "CNS USPS Tracking",
      metric128: "0.00",
      dimension129: dimension129,
      metric129: null,
      dimension130: dimension130,
      metric130: null,
      dimension131: dimension131,
      metric131: metric131,
      dimension132: dimension132,
      dimension133: dimension133,
      dimension134: dimension134,
      dimension135: dimension135,
      dimension136: dimension136,
      dimension137: null,
      dimension138: dimension138,
      dimension139: dimension139,
      dimension140: dimension140,
      dimension141: dimension141,
      dimension142: dimension142,
      dimension143: dimension143,
      dimension144: dimension144,
      dimension145: dimension145,
      dimension146: dimension146
    };

    if (task.packageDetails.cnsCartId === cnsCartId) {
      filtered.push(ReturnValue);
    } else if (cnsCartId == null) {
      filtered.push(ReturnValue);
    }
    return filtered;
  }, []);

  window.dataLayer.push({
    event: "ecomRemove",
    action: "ecomRemove",
    category: "ecomRemove",
    ecommerce: {
      remove: {
        products: mainDataLayerObj
      }
    }
  });
}

export function removeFromCart_ShippingCartDeleteAfterEdit(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  var mainDataLayerObj = data.reduce(function (filtered, task) {
    var dimension125 = task.detailedServiceDescription
      .trim()
      .split("</div>")
      .map(function (obj, idx) {
        return obj
          .replace(/<br\/>|<br>/g, " ")
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/&reg\b/, "")
          .replace(/;/, "");
      })
      .filter(function (obj, idx) {
        return obj.length > 0;
      })
      .join(" | ");

    var dimension129 =
      task.packageIndicators.crematedRemainsInd &&
      task.packageIndicators.crematedRemainsInd === "Y"
        ? "I am shipping Cremated Remains"
        : "" + task.packageIndicators.noWkndDlvyInd &&
          task.packageIndicators.noWkndDlvyInd === "Y"
        ? "No Delivery on Saturdays"
        : "";

    if (dimension129.length <= 0) {
      dimension129 = null;
    }
    var dimension130 =
      task.packageIndicators.nonDelvOption &&
      task.packageIndicators.nonDelvOption.displayText
        ? task.packageIndicators.nonDelvOption.displayText
        : null;

    var dimension131 =
      task.packageIndicators.additionalService &&
      task.packageIndicators.additionalService.displayText
        ? task.packageIndicators.additionalService.displayText
        : null;

    var metric131 = task.packageServiceDetails.additionalServiceAmt
      ? task.packageServiceDetails.additionalServiceAmt.toFixed(2)
      : null;

    var dimension133 =
      task.packageIndicators.ptsNotifyInd &&
      task.packageIndicators.ptsNotifyInd === "Y"
        ? "I would like to get tracking notifications"
        : null;

    var dimension134 =
      task.packageIndicators.flatRateInd &&
      task.packageIndicators.flatRateInd === "Y"
        ? "Flat Rate"
        : task.packageDetails.pounds +
          "lb " +
          task.packageDetails.ounces +
          "oz";

    var dimension135 =
      task.packageDetails.pkgValueAmt && task.packageDetails.pkgValueAmt > 0
        ? task.packageDetails.pkgValueAmt.toFixed(2)
        : null;

    var dimension138 = null,
      dimension139 = null,
      dimension140 = null,
      dimension141 = null,
      dimension142 = null;

    if ("Y" === task.packageIndicators.gxgPkgInd) {
      dimension138 = task.packageDetails.containerType
        ? task.packageDetails.containerType.name
        : null;
      dimension142 =
        task.packageItems && task.packageItems.length > 0
          ? task.packageItems[0].itemType
          : null;
    }

    var dimension143 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].unitValue.toFixed(2)
        : null;

    var dimension144 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].itemQty
        : null;

    var dimension145 =
      task.packageItems && task.packageItems.length > 0
        ? task.packageItems[0].pounds +
          "lb " +
          task.packageItems[0].ounces +
          "oz"
        : null;

    var dimension146 =
      task.packageCustomsDetails && task.packageCustomsDetails.eelCode
        ? task.packageCustomsDetails.eelCode.value +
          " " +
          task.packageCustomsDetails.eelCode.description
        : null;

    var name =
      task.product.productClassDesc + " " + task.product.productDescription;
    var price = task.packageServiceDetails.ttlShipCostAmt.toString();
    var variant = task.product.productClassDesc;
    var metric125 = task.packageServiceDetails.ttlShipCostAmt.toString();
    var dimension126 =
      task.product.productClassDesc.replace(/&reg;/, "") +
      " " +
      task.product.productDescription.replace(/&reg;/, "");
    var metric126 = task.packageServiceDetails.postageAmt.toFixed(2);
    var dimension127 = task.packageIndicators.insuranceInd;
    var metric127 =
      task.packageServiceDetails.insuranceCostAmt === undefined
        ? (task.packageServiceDetails.insuranceCostAmt = 0.0)
        : task.packageServiceDetails.insuranceCostAmt.toFixed(2);
    var dimension132 = task.trackingNbr;
    var dimension136 = task.product.productDimensions;

    var ReturnValue = {
      name: name,
      price: price,
      brand: "USPS CNS",
      category: "Click-N-Ship",
      variant: variant,
      quantity: 1,
      dimension125: dimension125,
      metric125: metric125,
      dimension126: dimension126,
      metric126: metric126,
      dimension127: dimension127,
      metric127: metric127,
      dimension128: "CNS USPS Tracking",
      metric128: "0.00",
      dimension129: dimension129,
      metric129: null,
      dimension130: dimension130,
      metric130: null,
      dimension131: dimension131,
      metric131: metric131,
      dimension132: dimension132,
      dimension133: dimension133,
      dimension134: dimension134,
      dimension135: dimension135,
      dimension136: dimension136,
      dimension137: null,
      dimension138: dimension138,
      dimension139: dimension139,
      dimension140: dimension140,
      dimension141: dimension141,
      dimension142: dimension142,
      dimension143: dimension143,
      dimension144: dimension144,
      dimension145: dimension145,
      dimension146: dimension146
    };

    filtered.push(ReturnValue);

    return filtered;
  }, []);

  window.dataLayer.push({
    event: "ecomRemove",
    action: "ecomRemove",
    caategory: "ecomRemove",
    ecommerce: {
      remove: {
        products: mainDataLayerObj
      }
    }
  });
}

export function idxsPromptAppears() {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    category: "category",
    action: "action",
    label: "label",
    event: "application",

    application: {
      element: "IDXS Prompt",
      userAction: "impression"
    }
  });
}

export function idxsPromptIsClicked(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "application",
    category: "category",
    action: "action",
    application: {
      element: "IDXS Prompt",
      userAction: "interaction",
      selectedCheckbox: data.answer
    }
  });
}

export function idxsConfirmationAppears(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "application",
    category: "category",
    action: "action",
    application: {
      element: "IDXS Confirmation Banner",
      userAction: "impression",
      confirmationNumber: data.transactionNumber
    }
  });
}

export function idxsBannerIsClicked(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "application",
    category: "category",
    action: "action",
    application: {
      element: "IDXS Confirmation Banner",
      userAction: "interaction",
      clickedElement: data.buttonText
    }
  });
}
