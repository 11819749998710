import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import ServiceTypeViewServices from "./ServiceTypeViewServices";
import Moment from "moment";
import axios from "axios";
import { withRouter } from "react-router-dom";
import * as analytics from "../Analytics/Analytics.js";
import update from "immutability-helper";
import TermsConditionsModal from "../Modals/TermsConditionsModal";
import AdultSigRequired21Older from "../Popovers/AdultSigRequired21Older";
import AdultSigResDelivery21Older from "../Popovers/AdultSigResDelivery21Older";
import hidePostagePriceUtils from "../../utils/hidePostagePrice";

class ServiceTypeSelectThePackage extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.isDomesticDestination()) {
      if (Number(this.props.configInfo.labelPkg.pkgValueAmt) > 500) {
        this.props.changeNonNestedFields("additionalService")("DEL");
      } else {
        this.props.changeNonNestedFields("eSOFAllowed")("N");
      }
    }

    if (
      this.props.configInfo.labelPkg.pkgValueAmt > 50 &&
      this.props.serviceShow !== "USPS Connect Local Mail" &&
      this.props.serviceShow !== "Priority Mail"
    ) {
      this.props.changeTrackingNotifications("insuranceInd")("Y");
    }

    this.state = {
      selectedService: this.props.selectedService,
      showSpinner: false,
      showPrivacy: false,
      dropoffIndex: this.props.configInfo.cnsCartList.dropOffAddressIndex
      // servicesErrors: {
      //   nonDelvOption: ""
      // }
    };
  }

  saveSelectedServiceInfo = (w) => {
    this.setState({
      selectedService: w
    });
    // For PM or PME packages > $500, need to preselect an additional service
    if (Number(this.props.configInfo.labelPkg.pkgValueAmt) > 500) {
      if (this.props.serviceShow === "Priority Mail") {
        this.props.changeTrackingNotifications("additionalService")("DEL");
      } else if (this.props.serviceShow === "Priority Mail Express") {
        this.props.changeTrackingNotifications("additionalService")("SIGRQ");
      } else {
        this.props.changeTrackingNotifications("additionalService")("");
      }
    } else {
      // For packages <= $500, need to preselect "No selection",
      // if available
      this.props.changeTrackingNotifications("additionalService")("");
    }
    // Reset insurance and other services
    this.props.changeTrackingNotifications("insuranceInd")("N");
    this.props.changeTrackingNotifications("noWkndDlvyInd")(false);
    this.props.changeTrackingNotifications("crematedRemainsInd")(false);
    this.props.changeTrackingNotifications("sundayDeliveryInd")(false);
  };

  getPrice = (e) => {
    let t = this.props.configInfo.labelInd.additionalService;
    let test = this.state.selectedService.additionalServices[t].displayPrice;
    return test;
  };

  displayAdditionalServicePrice = () => {
    let additionalServicePrice =
      this.props.configInfo.labelInd.additionalService === "SIG" ||
      (this.props.configInfo.labelInd.additionalService === "DEL" &&
        this.state.selectedService.additionalServices.SIG &&
        this.props.configInfo.labelInd.insuranceInd !== "Y")
        ? this.state.selectedService.additionalServices.SIG.displayPrice
        : this.props.configInfo.labelInd.additionalService === "SIGRQ"
        ? this.state.selectedService.additionalServices.SIGRQ.displayPrice
        : this.props.configInfo.labelInd.additionalService === "RES"
        ? this.state.selectedService.additionalServices.RES.displayPrice
        : this.props.configInfo.labelInd.additionalService === "REQ"
        ? this.state.selectedService.additionalServices.REQ.displayPrice
        : this.props.configInfo.labelInd.additionalService === "SIGRES"
        ? this.state.selectedService.additionalServices.SIGRES.displayPrice
        : "";
    if (additionalServicePrice === "$0.00" || additionalServicePrice === "") {
      additionalServicePrice = "Free";
    }
    return additionalServicePrice;
  };

  dropOffAddressSelect = (dropOffIndex) => {
    this.setState({ dropoffIndex: dropOffIndex });
  };

  calculateTotal = (e) => {
    if (this.props.configInfo.batch.length === 0) {
      let additionalServicePrice,
        insurancePrice,
        delivery1030Price,
        sundayDeliveryPrice;
      // Additional Service
      if (
        this.props.configInfo.labelInd.additionalService === "SIG" ||
        (this.props.configInfo.labelInd.additionalService === "DEL" &&
          this.state.selectedService.additionalServices.SIG &&
          this.props.configInfo.labelInd.insuranceInd !== "Y")
      ) {
        additionalServicePrice = this.state.selectedService.additionalServices
          .SIG.price;
      } else if (this.props.configInfo.labelInd.additionalService === "REQ") {
        additionalServicePrice = this.state.selectedService.additionalServices
          .REQ.price;
      } else if (
        this.props.configInfo.labelInd.additionalService === "SIGRES"
      ) {
        additionalServicePrice = this.state.selectedService.additionalServices
          .SIGRES.price;
      } else if (
        this.props.isDomesticDestination() &&
        this.props.configInfo.labelInd.additionalService === "RES"
      ) {
        additionalServicePrice = this.state.selectedService.additionalServices
          .RES.price;
      } else {
        additionalServicePrice = 0;
      }
      // Insurance
      if (
        this.props.configInfo.labelInd.insuranceInd === "Y" &&
        this.state.selectedService.additionalServices.INS
      ) {
        insurancePrice = this.state.selectedService.additionalServices.INS
          .price;
      } else if (
        this.props.configInfo.labelInd.insRestrictedDeliveryInd === "Y"
      ) {
        insurancePrice = this.state.selectedService.additionalServices.INSRES
          .price;
      } else {
        insurancePrice = 0;
      }
      // 10:30 Delivery Time
      if (
        this.props.configInfo.labelInd.deliveryTimeOption === "Y" &&
        this.state.selectedService.additionalServices &&
        this.state.selectedService.additionalServices["1030AM"]
      ) {
        delivery1030Price = this.state.selectedService.additionalServices[
          "1030AM"
        ].price;
      } else {
        delivery1030Price = 0;
      }
      // Sunday Delivery
      if (
        this.props.configInfo.labelInd.sundayDeliveryInd === "Y" &&
        this.state.selectedService.sundayDelivery
      ) {
        sundayDeliveryPrice = this.state.selectedService.sundayDelivery.price;
      } else {
        sundayDeliveryPrice = 0;
      }
      // Sum of prices
      return (
        this.state.selectedService.price +
        insurancePrice +
        additionalServicePrice +
        delivery1030Price +
        sundayDeliveryPrice
      ).toFixed(2);
    } else {
      let additionalServicePrice = 0;
      let insurancePrice = 0;
      let delivery1030Price = 0;
      for (let batchItem in this.props.configInfo.batch) {
        // Additional Service
        if (
          this.props.configInfo.labelInd.additionalService === "SIG" ||
          (this.props.configInfo.labelInd.additionalService === "DEL" &&
            this.state.selectedService.additionalServices.SIG &&
            this.props.configInfo.labelInd.insuranceInd !== "Y")
        ) {
          additionalServicePrice += this.state.selectedService
            .additionalServices.SIG.price;
        } else if (this.props.configInfo.labelInd.additionalService === "REQ") {
          additionalServicePrice += this.state.selectedService
            .additionalServices.REQ.price;
        } else if (
          this.props.configInfo.labelInd.additionalService === "SIGRES"
        ) {
          additionalServicePrice += this.state.selectedService
            .additionalServices.SIGRES.price;
        } else if (
          this.props.isDomesticDestination() &&
          this.props.configInfo.labelInd.additionalService === "RES"
        ) {
          additionalServicePrice += this.state.selectedService
            .additionalServices.RES.price;
        } else {
          additionalServicePrice += 0;
        }
        // Insurance
        if (this.props.configInfo.labelInd.insuranceInd === "Y") {
          insurancePrice += this.state.selectedService.additionalServices.INS
            .price;
        } else if (
          this.props.configInfo.labelInd.insRestrictedDeliveryInd === "Y"
        ) {
          insurancePrice += this.state.selectedService.additionalServices.INSRES
            .price;
        } else {
          insurancePrice += 0;
        }
        // 10:30 Delivery Time
        if (
          this.props.configInfo.labelInd.deliveryTimeOption === "Y" &&
          this.state.selectedService.additionalServices &&
          this.state.selectedService.additionalServices["1030AM"]
        ) {
          delivery1030Price += this.state.selectedService.additionalServices[
            "1030AM"
          ].price;
        } else {
          delivery1030Price += 0;
        }
      }
      // Sum of prices
      return (
        this.state.selectedService.price * this.props.configInfo.batch.length +
        insurancePrice +
        additionalServicePrice +
        delivery1030Price
      ).toFixed(2);
    }
  };

  convertToString = (obj) => {
    Object.keys(obj).forEach((k) => {
      // if (typeof obj[k] === "object") {
      //   return toString(obj[k]);
      // }

      obj[k] = "" + obj[k];
    });

    return obj;
  };

  showInfo = (e) => {
    e.preventDefault();
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        servicesErrors: { $merge: { ...errorMessage } }
      });
      this.setState(newState);
    }
  };

  clearServicesError = (fieldName) => {
    this.setState((prevState) => {
      return {
        servicesErrors: {
          ...prevState.servicesErrors,
          [fieldName]: ""
        }
      };
    });
  };

  validateFields = () => {
    let errorsFound = false;
    // let errorObject = {};
    // if (
    //   (this.props.serviceShow === "Priority Mail International" ||
    //     this.props.serviceShow === "Priority Mail Express International") &&
    //   !this.props.configInfo.labelInd.nonDelvOption
    // ) {
    //   errorObject["nonDelvOption"] = "Please select an option.";
    //   errorsFound = true;
    // }
    // this.setInputErrors("errorObject")(errorObject);
    return errorsFound;
  };

  addToCartNoBatch = (e, startNewLabel) => {
    e.preventDefault();
    this.props.toggleSpinner(true);

    if (!this.validateFields()) {
      var dataToSend = {};
      let cnsCartList = [{}];
      var saveLabelInfoDataToSend = {};
      if (this.props.configInfo.batch.length === 0) {
        // Non-batch route
        cnsCartList[0].batchNbr = this.props.configInfo.cnsCartList.batchNbr;
        cnsCartList[0].cnsCartId = this.props.configInfo.cnsCartList.cnsCartId;
        cnsCartList[0].cnsCartTypeId = "1";
        // Step 1 - Return Address
        cnsCartList[0].returnAddress = JSON.parse(
          JSON.stringify(this.props.configInfo.returnAddress)
        );
        cnsCartList[0].returnAddress.addressCategory = String(
          this.props.configInfo.returnAddress.addressCategory.value || ""
        );
        cnsCartList[0].ptsTrackingEmail =
          this.props.configInfo.labelInd.ptsTrackingEmail || "";
        cnsCartList[0].ptsTxtPhoneNbr =
          // Must have exactly 10 digits, otherwise store nothing
          this.props.configInfo.labelInd.ptsTxtPhoneNbr &&
          this.props.configInfo.labelInd.ptsTxtPhoneNbr.replace(/[^\d]/g, "")
            .length === 10
            ? // Prepend a 1
              "1" +
              // Remove all nonnumeric characters
              this.props.configInfo.labelInd.ptsTxtPhoneNbr.replace(
                /[^\d]/g,
                ""
              )
            : "";
        cnsCartList[0].shipFromPostalCode = this.props.configInfo.shipFromPostalCode;
        delete cnsCartList[0].returnAddress.avsCode;
        delete cnsCartList[0].returnAddress.country;
        delete cnsCartList[0].returnAddress.stateCode;
        delete cnsCartList[0].returnAddress.countryName;
        delete cnsCartList[0].returnAddress.countryCode;
        delete cnsCartList[0].returnAddress.fullName;
        delete cnsCartList[0].returnAddress.fullOrCompanyName;
        delete cnsCartList[0].returnAddress.zip5;
        delete cnsCartList[0].returnAddress.zipPlus4;
        delete cnsCartList[0].returnAddress.domestic;
        delete cnsCartList[0].returnAddress.singlePly;
        delete cnsCartList[0].returnAddress.displayAddress;
        delete cnsCartList[0].returnAddress.mobileDisplayAddress;
        delete cnsCartList[0].returnAddress.militaryCity;
        delete cnsCartList[0].returnAddress.labelInd;
        delete cnsCartList[0].returnAddress.state;
        delete cnsCartList[0].returnAddress.optLock;
        delete cnsCartList[0].returnAddress.cnsCartId;
        delete cnsCartList[0].returnAddress.saveReturnToAddressBook;
        // Step 2 - Delivery Address
        cnsCartList[0].deliveryAddress = JSON.parse(
          JSON.stringify(this.props.configInfo.deliveryAddress)
        );
        cnsCartList[0].toNote = this.props.configInfo.labelInd.toNote || "";
        cnsCartList[0].labelHfp = JSON.parse(
          JSON.stringify(this.props.configInfo.labelHfp)
        );
        delete cnsCartList[0].deliveryAddress.avsCode;
        delete cnsCartList[0].deliveryAddress.addressCategory;
        delete cnsCartList[0].deliveryAddress.stateCode;
        delete cnsCartList[0].deliveryAddress.country;
        delete cnsCartList[0].deliveryAddress.countryName;
        delete cnsCartList[0].deliveryAddress.state;
        delete cnsCartList[0].deliveryAddress.countryCode;
        delete cnsCartList[0].deliveryAddress.fullName;
        delete cnsCartList[0].deliveryAddress.fullOrCompanyName;
        delete cnsCartList[0].deliveryAddress.zip5;
        delete cnsCartList[0].deliveryAddress.zipPlus4;
        delete cnsCartList[0].deliveryAddress.domestic;
        delete cnsCartList[0].deliveryAddress.singlePly;
        delete cnsCartList[0].deliveryAddress.displayAddress;
        delete cnsCartList[0].deliveryAddress.mobileDisplayAddress;
        delete cnsCartList[0].deliveryAddress.militaryCity;
        delete cnsCartList[0].deliveryAddress.saveDeliveryToAddressBook;
        delete cnsCartList[0].deliveryAddress.cnsCartId;
        delete cnsCartList[0].deliveryAddress.optLock;
        delete cnsCartList[0].labelHfp.recipCheckbox;
        delete cnsCartList[0].labelHfp.recipPhoneText;
        delete cnsCartList[0].labelHfp.senderCheckbox;
        delete cnsCartList[0].labelHfp.senderPhoneText;
        // Step 3 - Shipping Date
        cnsCartList[0].shippingDate = Moment(
          this.props.configInfo.shippingDate
        ).format("MM/DD/YYYY");
        // Step 4 - Package Details
        cnsCartList[0].labelPkg = JSON.parse(
          JSON.stringify(this.props.configInfo.labelPkg)
        );
        cnsCartList[0].labelPkg.largePackageInd =
          this.props.configInfo.labelPkg.largePackageInd || "N";
        cnsCartList[0].labelPkg.weightOzQty =
          Number(
            this.props.configInfo.labelPkg.weightOZQtyPound === undefined
              ? 0
              : this.props.configInfo.labelPkg.weightOZQtyPound
          ) *
            16 +
          Number(
            this.props.configInfo.labelPkg.weightOZQtyOunces === undefined
              ? 0
              : this.props.configInfo.labelPkg.weightOZQtyOunces
          );
        delete cnsCartList[0].labelPkg.weightOZQtyPound;
        delete cnsCartList[0].labelPkg.weightOZQtyOunces;
        delete cnsCartList[0].labelPkg.shippingDate;
        delete cnsCartList[0].labelPkg.fromFnceZipCode;
        delete cnsCartList[0].labelPkg.containerType;
        delete cnsCartList[0].labelPkg.optLock;
        delete cnsCartList[0].labelPkg.pounds;
        delete cnsCartList[0].labelPkg.ounces;
        // Other
        cnsCartList[0].labelInd = JSON.parse(
          JSON.stringify(this.props.configInfo.labelInd)
        );
        if (
          cnsCartList[0].labelInd &&
          cnsCartList[0].labelInd.additionalService === "DEL" &&
          this.state.selectedService.additionalServices &&
          this.state.selectedService.additionalServices.SIG &&
          this.props.configInfo.labelInd.insuranceInd !== "Y"
        ) {
          cnsCartList[0].labelInd.additionalService = "SIG";
        }
        if (this.props.hideNonmachinable()) {
          // If the dropdown is hidden, set the nonmachinable indicator
          // based on whether weight was entered
          if (this.props.configInfo.labelInd.flatRateInd === "Y") {
            // Flat rate products are treated as machinable
            cnsCartList[0].labelInd.nonmachinableInd = "0";
          } else {
            // Non-flat rate products are treated as nonmachinable
            cnsCartList[0].labelInd.nonmachinableInd = "1";
          }
        } else if (
          !this.props.hideNonmachinable() &&
          !cnsCartList[0].labelInd.nonmachinableInd
        ) {
          // If the dropdown is shown but the nonmachinable indicator has not
          // been entered, assume "None" ("0") is the intended choice
          cnsCartList[0].labelInd.nonmachinableInd = "0";
        }
        if (this.props.isDomesticDestination()) {
          delete cnsCartList[0].labelInd.giftInd;
        }
        cnsCartList[0].labelInd.sundayDeliveryInd =
          this.showSundayDelivery() && cnsCartList[0].labelInd.sundayDeliveryInd
            ? cnsCartList[0].labelInd.sundayDeliveryInd
            : "N";
        delete cnsCartList[0].labelInd.userId;
        delete cnsCartList[0].labelInd.domesticProductId;
        delete cnsCartList[0].labelInd.largePackageInd;
        delete cnsCartList[0].labelInd.pkgShape;
        delete cnsCartList[0].labelInd.ptsTrackingEmail;
        delete cnsCartList[0].labelInd.ptsTxtPhoneNbr;
        delete cnsCartList[0].labelInd.weightOzQty;
        delete cnsCartList[0].labelInd.userReturnAddr;
        delete cnsCartList[0].labelInd.pounds;
        delete cnsCartList[0].labelInd.ounces;
        delete cnsCartList[0].labelInd.rectangular;
        delete cnsCartList[0].labelInd.serviceType;
        delete cnsCartList[0].labelInd.shippingHistoryColumnFilter;
        delete cnsCartList[0].labelInd.shippingHistorySortFilter;
        delete cnsCartList[0].labelInd.shPagesize;
        delete cnsCartList[0].labelInd.optLock;
        delete cnsCartList[0].labelInd.toNote;
        delete cnsCartList[0].labelInd.printOptions;
        delete cnsCartList[0].labelInd.refNbr;
        delete cnsCartList[0].labelInd.shipFromPostalCode;
        delete cnsCartList[0].labelInd.pkgGirthQty;
        delete cnsCartList[0].labelInd.pkgHeightQty;
        delete cnsCartList[0].labelInd.pkgLengthQty;
        delete cnsCartList[0].labelInd.pkgWidthQty;
        delete cnsCartList[0].labelInd.weightOZQtyOunces;
        delete cnsCartList[0].labelInd.weightOZQtyPound;
        delete cnsCartList[0].labelInd.screenSize;
        delete cnsCartList[0].labelInd.commercialGoldInd;
        delete cnsCartList[0].labelInd.lpaNotice; //TODO: Remove

        cnsCartList[0].hazmatType =
          this.props.hazmatType === undefined ? null : this.props.hazmatType;

        cnsCartList[0].dropOffAddressIndex = this.state.dropoffIndex;

        cnsCartList[0].eSOFAllowed = this.props.configInfo.eSOFAllowed;
        cnsCartList[0].productId = {
          productId:
            String(
              this.props.configInfo.saveLabelInfoAdditionalData.productId
            ) || "0"
        };

        dataToSend.cnsCartList = cnsCartList;

        let additionalParametersToSend = {
          cnsCartId: this.props.configInfo.cnsCartList.cnsCartId,
          productId: this.props.configInfo.saveLabelInfoAdditionalData
            .productId,
          batchCount: 1,
          eSOFAllowed: this.props.configInfo.eSOFAllowed,
          saveReturnToAddressBook: this.props.configInfo.returnAddress
            .saveReturnToAddressBook,
          saveDeliveryToAddressBook: this.props.configInfo.deliveryAddress
            .saveDeliveryToAddressBook
        };

        saveLabelInfoDataToSend = {
          fetchServicesData: dataToSend,
          saveLabelInfoAdditionalData: additionalParametersToSend
        };

        axios
          .post(
            "/go/cnsrest/saveLabelInfo",
            JSON.stringify(saveLabelInfoDataToSend),
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            // This try-catch ensures Google Analytics errors don't prevent
            // the user from progressing to the next page
            try {
              if (this.props.isFromCart) {
                analytics.removeFromCart_ShippingCartDeleteAfterEdit([
                  this.props.configInfo.cnsCartList
                ]);
                analytics.addToCart_CreateLabelPage(response.data.cnsCartList);
              } else {
                analytics.addToCart_CreateLabelPage(response.data.cnsCartList);
              }
            } catch (e) {}

            this.props.updateCartSize();

            if (startNewLabel) {
              // User clicked the button to add to cart and start new label
              this.props.history.push("/labelInformation");
              // The above statement ensures
              // this.props.history.location.state.editItem is removed
              window.scrollTo(0, 0);
              window.location.reload();
            } else if (!this.state.selectedService.customsRequired) {
              // Redirect to the shipping cart
              this.props.history.push("/shippingCart");
            } else if (
              this.state.selectedService.productNameDisplay &&
              this.state.selectedService.productNameDisplay
                .replace("&reg;", "")
                .replace("®", "")
                .replace("&trade;", "")
                .replace("™", "") === "Global Express Guaranteed"
            ) {
              // Redirect to the GXG info pages
              this.props.history.push("/gxgLocationsDestinations", {
                cnsCartId: this.props.configInfo.cnsCartList.cnsCartId
              });
            } else {
              // Redirect to the non-GXG customs info page
              this.props.history.push("/customsInformation", {
                cnsCartId: this.props.configInfo.cnsCartList.cnsCartId
              });
            }
          })
          .catch((error) => {
            this.props.toggleSpinner(false);
          });
      } else {
        // Batch route
        let cnsCartListArr = [];
        let cnsCartList = {};

        for (
          let index = 0;
          index < this.props.configInfo.batch.length;
          index++
        ) {
          cnsCartList.batchNbr = this.props.configInfo.cnsCartList.batchNbr;
          cnsCartList.cnsCartId = this.props.configInfo.batch[
            index
          ].cnsCartList[0].cnsCartId;
          cnsCartList.cnsCartTypeId = "1";
          // Step 1 - Return Address
          cnsCartList.returnAddress = JSON.parse(
            JSON.stringify(this.props.configInfo.returnAddress)
          );
          cnsCartList.returnAddress.addressCategory = String(
            this.props.configInfo.returnAddress.addressCategory.value || ""
          );

          cnsCartList.ptsTrackingEmail =
            this.props.configInfo.labelInd.ptsTrackingEmail || "";
          cnsCartList.ptsTxtPhoneNbr =
            // Must have exactly 10 digits, otherwise store nothing
            this.props.configInfo.labelInd.ptsTxtPhoneNbr &&
            this.props.configInfo.labelInd.ptsTxtPhoneNbr.replace(/[^\d]/g, "")
              .length === 10
              ? // Prepend a 1
                "1" +
                // Remove all nonnumeric characters
                this.props.configInfo.labelInd.ptsTxtPhoneNbr.replace(
                  /[^\d]/g,
                  ""
                )
              : "";
          cnsCartList.shipFromPostalCode = this.props.configInfo.shipFromPostalCode;
          delete cnsCartList.returnAddress.avsCode;
          delete cnsCartList.returnAddress.country;
          delete cnsCartList.returnAddress.stateCode;
          delete cnsCartList.returnAddress.countryName;
          delete cnsCartList.returnAddress.countryCode;
          delete cnsCartList.returnAddress.fullName;
          delete cnsCartList.returnAddress.fullOrCompanyName;
          delete cnsCartList.returnAddress.zip5;
          delete cnsCartList.returnAddress.zipPlus4;
          delete cnsCartList.returnAddress.domestic;
          delete cnsCartList.returnAddress.singlePly;
          delete cnsCartList.returnAddress.displayAddress;
          delete cnsCartList.returnAddress.mobileDisplayAddress;
          delete cnsCartList.returnAddress.militaryCity;
          delete cnsCartList.returnAddress.labelInd;
          delete cnsCartList.returnAddress.state;
          delete cnsCartList.returnAddress.optLock;
          delete cnsCartList.returnAddress.cnsCartId;
          delete cnsCartList.returnAddress.saveReturnToAddressBook;
          // Step 2 - Delivery Address
          cnsCartList.deliveryAddress = JSON.parse(
            JSON.stringify(
              this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress
            )
          );
          cnsCartList.toNote =
            this.props.configInfo.batch[index].cnsCartList[0].toNote || "";
          cnsCartList.deliveryAddress.poFlag = "";
          cnsCartList.deliveryAddress.province = "";
          cnsCartList.deliveryAddress.phoneNbr = "";
          delete cnsCartList.deliveryAddress.avsCode;
          delete cnsCartList.deliveryAddress.addressCategory;
          delete cnsCartList.deliveryAddress.stateCode;
          delete cnsCartList.deliveryAddress.country;
          delete cnsCartList.deliveryAddress.countryName;
          delete cnsCartList.deliveryAddress.state;
          delete cnsCartList.deliveryAddress.countryCode;
          delete cnsCartList.deliveryAddress.fullName;
          delete cnsCartList.deliveryAddress.fullOrCompanyName;
          delete cnsCartList.deliveryAddress.zip5;
          delete cnsCartList.deliveryAddress.zipPlus4;
          delete cnsCartList.deliveryAddress.domestic;
          delete cnsCartList.deliveryAddress.singlePly;
          delete cnsCartList.deliveryAddress.displayAddress;
          delete cnsCartList.deliveryAddress.mobileDisplayAddress;
          delete cnsCartList.deliveryAddress.militaryCity;
          delete cnsCartList.deliveryAddress.saveDeliveryToAddressBook;
          delete cnsCartList.deliveryAddress.cnsCartId;
          delete cnsCartList.deliveryAddress.optLock;
          // Step 3 - Shipping Date
          cnsCartList.shippingDate = Moment(
            this.props.configInfo.shippingDate
          ).format("MM/DD/YYYY");
          // Step 4 - Package Details
          cnsCartList.labelPkg = JSON.parse(
            JSON.stringify(this.props.configInfo.labelPkg)
          );
          cnsCartList.labelPkg.largePackageInd =
            this.props.configInfo.labelPkg.largePackageInd || "N";
          if (
            this.props.configInfo.labelPkg.weightOZQtyPound &&
            this.props.configInfo.labelPkg.weightOZQtyOunces
          ) {
            cnsCartList.labelPkg.weightOzQty =
              Number(this.props.configInfo.labelPkg.weightOZQtyPound) * 16 +
              Number(this.props.configInfo.labelPkg.weightOZQtyOunces);
          } else if (
            this.props.configInfo.labelPkg.weightOZQtyPound &&
            !this.props.configInfo.labelPkg.weightOZQtyOunces
          ) {
            cnsCartList.labelPkg.weightOzQty =
              Number(this.props.configInfo.labelPkg.weightOZQtyPound) * 16;
          } else {
            cnsCartList.labelPkg.weightOzQty = Number(
              this.props.configInfo.labelPkg.weightOZQtyOunces
            );
          }
          delete cnsCartList.labelPkg.weightOZQtyPound;
          delete cnsCartList.labelPkg.weightOZQtyOunces;
          delete cnsCartList.labelPkg.shippingDate;
          delete cnsCartList.labelPkg.fromFnceZipCode;
          delete cnsCartList.labelPkg.containerType;
          delete cnsCartList.labelPkg.optLock;
          delete cnsCartList.labelPkg.pounds;
          delete cnsCartList.labelPkg.ounces;
          // Other
          cnsCartList.labelInd = JSON.parse(
            JSON.stringify(this.props.configInfo.labelInd)
          );
          if (
            cnsCartList.labelInd &&
            cnsCartList.labelInd.additionalService === "DEL" &&
            this.state.selectedService.additionalServices &&
            this.state.selectedService.additionalServices.SIG &&
            this.props.configInfo.labelInd.insuranceInd !== "Y"
          ) {
            cnsCartList.labelInd.additionalService = "SIG";
          }
          if (this.props.isDomesticDestination()) {
            delete cnsCartList.labelInd.giftInd;
          }
          delete cnsCartList.labelInd.userId;
          delete cnsCartList.labelInd.domesticProductId;
          delete cnsCartList.labelInd.largePackageInd;
          delete cnsCartList.labelInd.pkgShape;
          delete cnsCartList.labelInd.ptsTrackingEmail;
          delete cnsCartList.labelInd.ptsTxtPhoneNbr;
          delete cnsCartList.labelInd.weightOzQty;
          delete cnsCartList.labelInd.userReturnAddr;
          delete cnsCartList.labelInd.pounds;
          delete cnsCartList.labelInd.ounces;
          delete cnsCartList.labelInd.rectangular;
          delete cnsCartList.labelInd.serviceType;
          delete cnsCartList.labelInd.shippingHistoryColumnFilter;
          delete cnsCartList.labelInd.shippingHistorySortFilter;
          delete cnsCartList.labelInd.shPagesize;
          delete cnsCartList.labelInd.optLock;
          delete cnsCartList.labelInd.toNote;
          delete cnsCartList.labelInd.printOptions;
          delete cnsCartList.labelInd.refNbr;
          delete cnsCartList.labelInd.shipFromPostalCode;
          delete cnsCartList.labelInd.pkgGirthQty;
          delete cnsCartList.labelInd.pkgHeightQty;
          delete cnsCartList.labelInd.pkgLengthQty;
          delete cnsCartList.labelInd.pkgWidthQty;
          delete cnsCartList.labelInd.weightOZQtyOunces;
          delete cnsCartList.labelInd.weightOZQtyPound;
          delete cnsCartList.labelInd.screenSize;
          delete cnsCartList.labelInd.commercialGoldInd;
          delete cnsCartList.labelInd.lpaNotice; //TODO: Remove
          cnsCartList.labelInd.batchInd = "Y";
          cnsCartList.hazmatType =
            this.props.hazmatType === undefined ? null : this.props.hazmatType;
          cnsCartList.eSOFAllowed = this.props.configInfo.eSOFAllowed;
          cnsCartList.productId = {
            productId: "0"
          };

          cnsCartListArr.push(cnsCartList);
          cnsCartList = {};
        }

        dataToSend.cnsCartList = cnsCartListArr;

        let additionalParametersToSend = {
          cnsCartId: this.props.configInfo.cnsCartList.cnsCartId,
          productId: this.props.configInfo.saveLabelInfoAdditionalData
            .productId,
          batchCount: this.props.configInfo.batch.length,
          eSOFAllowed: this.props.configInfo.eSOFAllowed,
          saveReturnToAddressBook: this.props.configInfo.returnAddress
            .saveReturnToAddressBook,
          saveDeliveryToAddressBook: this.props.configInfo.deliveryAddress
            .saveDeliveryToAddressBook
        };

        saveLabelInfoDataToSend = {
          fetchServicesData: dataToSend,
          saveLabelInfoAdditionalData: additionalParametersToSend
        };

        axios
          .post(
            "/go/cnsrest/saveLabelInfo",
            JSON.stringify(saveLabelInfoDataToSend),
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            this.props.updateCartSize();

            if (startNewLabel) {
              // User clicked the button to add to cart and start new label
              this.props.history.push("/labelInformation");
              // The above statement ensures
              // this.props.history.location.state.editItem is removed
              window.scrollTo(0, 0);
              window.location.reload();
            } else {
              // Redirect to the shipping cart
              this.props.history.push("/shippingCart");
            }
          })
          .catch((error) => {
            console.log(error);
            this.props.toggleSpinner(false);
          });
      }
    } else {
      this.props.toggleSpinner(false);
    }
  };

  // The Sunday delivery option is only displayed if a user fulfills all the
  // following:
  // - selects a USPS Connect Local service
  // - has a drop-off location that supports Sunday delivery
  // - is returned the Sunday delivery extra service from the rate call
  showSundayDelivery = () => {
    return (
      this.props.serviceShow === "USPS Connect Local" &&
      this.props.dropOffLocations &&
      this.props.selectedDropOffLocationIndex &&
      this.props.dropOffLocations[this.state.dropoffIndex]
        .dropOffLocationSundayDelivery === "true" &&
      this.state.selectedService.sundayDelivery
    );
  };

  render1030Delivery = () => {
    // Only PME labels can have 10:30 AM delivery
    if (this.props.serviceShow === "Priority Mail Express") {
      const additionalServices = this.state.selectedService.additionalServices;
      // Only display if 10:30 AM delivery is available
      if (additionalServices && additionalServices["1030AM"]) {
        return (
          <React.Fragment>
            <div id="priority" className="insurance-container priority">
              <div className="row">
                <div className="col-12 col-md-11 col-lg-10">
                  <label className="click-ship-mailing-address">
                    <strong>
                      Priority Mail Express<sup>&reg;</sup> 10:30 Delivery
                    </strong>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-11 col-lg-10">
                  <ul className="click-ship-service-type-price-wrapper">
                    <li className="click-ship-destination-content-label">
                      <div className="row destination-address-content">
                        <div className="col-9">
                          <p className="destinaton-city-location-label">
                            <strong>Service Type</strong>
                          </p>
                        </div>
                        <div className="col-3 text-right">
                          <p className="destination-postal-code-label">
                            <strong>Price</strong>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="click-ship-destination-content">
                      <div className="row destination-address-content">
                        <div className="col-9">
                          <label
                            className="checkbox-component insurance-express-check"
                            htmlFor="pme-1030-delivery-check"
                          >
                            <input
                              type="checkbox"
                              id="pme-1030-delivery-check"
                              onChange={(e) =>
                                this.props.changeTrackingNotifications(
                                  "deliveryTimeOption"
                                )(e.currentTarget.checked ? "Y" : "N")
                              }
                              checked={
                                this.props.configInfo.labelInd
                                  .deliveryTimeOption === "Y"
                              }
                            />
                            <span className="checkbox" />
                          </label>
                          <p className="insurance-service-type">
                            10:30 Delivery Time
                          </p>
                        </div>
                        <div className="col-3 text-right">
                          <p className="destination-postal-code">
                            {additionalServices["1030AM"].displayPrice !==
                            "$0.00"
                              ? additionalServices["1030AM"].displayPrice
                              : "Free"}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    }
  };

  renderInsurance = () => {
    return (
      <div id="priority" className="insurance-container priority">
        <div className="row">
          <div className="col-12 col-md-11 col-lg-10">
            <label className="click-ship-mailing-address">
              <strong>Insurance</strong>
            </label>
            {this.props.serviceShow === "Priority Mail" ? (
              <p>
                Priority Mail<sup>&reg;</sup> covers up to $100 of package
                value. For packages with a value over $100, additional insurance
                can be purchased to cover the balance.
              </p>
            ) : this.props.serviceShow === "Priority Mail Express" ? (
              <p>
                Priority Mail Express<sup>&reg;</sup> covers up to $100 of
                package value. For packages with a value over $100, additional
                insurance can be purchased to cover the balance.
              </p>
            ) : this.props.serviceShow === "Priority Mail International" ? (
              <React.Fragment>
                <p>
                  Priority Mail International<sup>&reg;</sup> shipments
                  containing merchandise are insured for up to $200.00 at no
                  additional charge. Additional insurance coverage may be
                  purchased at the sender's option.
                </p>
                <br />
                <p>
                  Priority Mail International<sup>&reg;</sup> shipments
                  containing nonnegotiable documents are insured for up to
                  $100.00 at no additional charge. No additional insurance is
                  available.
                </p>
                <br />
                <p>
                  Insurance coverage is not provided for some items. Consult the
                  International Mail Manual or your local Post Office for
                  detailed information.
                </p>
              </React.Fragment>
            ) : this.props.serviceShow ===
              "Priority Mail Express International" ? (
              <React.Fragment>
                <p>
                  Priority Mail Express International<sup>&reg;</sup> shipments
                  containing merchandise are insured against loss, damage, or
                  missing contents up to $200.00 at no additional charge.
                  Additional merchandise insurance coverage may be purchased at
                  the sender's option.
                </p>
                <p>
                  Priority Mail Express International
                  <sup>&reg;</sup>
                  shipments containing nonnegotiable documents are insured
                  against loss, damage, or missing contents up to $100.00 of
                  document reconstruction costs at no additional charge. No
                  additional document reconstruction insurance is available.
                </p>
                <p>
                  Consult the International Mail Manual or your local Post
                  Office for detailed information.
                </p>
              </React.Fragment>
            ) : this.props.serviceShow ===
              "First-Class Package International Service" ? (
              <React.Fragment>
                <p>Insurance is not available for this service.</p>
              </React.Fragment>
            ) : this.props.serviceShow === "Global Express Guaranteed" ? (
              <p>
                Global Express Guaranteed<sup>&reg;</sup> covers up to $100 of
                package value. For packages with a value over $100, additional
                insurance can be purchased to cover the balance.
              </p>
            ) : null}
          </div>
        </div>
        {this.props.serviceShow !==
        "First-Class Package International Service" ? (
          <div className="row">
            <div className="col-12 col-md-11 col-lg-10">
              <ul className="click-ship-service-type-price-wrapper">
                <li className="click-ship-destination-content-label">
                  <div className="row destination-address-content">
                    <div className="col-9">
                      <p className="destinaton-city-location-label">
                        <strong>Service Type</strong>
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <p className="destination-postal-code-label">
                        <strong>Price</strong>
                      </p>
                    </div>
                  </div>
                </li>

                {/* FREE INSURANCE */}
                {this.props.serviceShow === "Priority Mail" ? (
                  <li className="click-ship-destination-content">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <input
                          type="radio"
                          className="radio-button"
                          name="radio-insurance"
                          onChange={(e) =>
                            this.props.changeTrackingNotifications(
                              "insuranceInd"
                            )("N")
                          }
                          checked={
                            this.props.configInfo.labelInd.insuranceInd !==
                              "Y" &&
                            this.props.configInfo.labelInd
                              .insRestrictedDeliveryInd !== "Y"
                          }
                        />
                        <p className="insurance-service-type insurance-100-free">
                          Insurance for packages valued up to $100
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code">Free</p>
                      </div>
                    </div>
                  </li>
                ) : this.props.serviceShow === "Priority Mail Express" ? (
                  <li className="click-ship-destination-content">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <input
                          type="radio"
                          className="radio-button"
                          name="radio-insurance"
                          onChange={(e) =>
                            this.props.changeTrackingNotifications(
                              "insuranceInd"
                            )("N")
                          }
                          checked={
                            this.props.configInfo.labelInd.insuranceInd !==
                              "Y" &&
                            this.props.configInfo.labelInd
                              .insRestrictedDeliveryInd !== "Y"
                          }
                        />
                        <p className="insurance-service-type insurance-100-free">
                          Insurance for packages valued up to $100
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code">Free</p>
                      </div>
                    </div>
                  </li>
                ) : this.props.serviceShow === "USPS Retail Ground" ? (
                  <li className="click-ship-destination-content">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <input
                          type="radio"
                          className="radio-button"
                          name="radio-insurance"
                          onChange={(e) =>
                            this.props.changeTrackingNotifications(
                              "insuranceInd"
                            )("N")
                          }
                          checked={
                            this.props.configInfo.labelInd.insuranceInd !==
                              "Y" &&
                            this.props.configInfo.labelInd
                              .insRestrictedDeliveryInd !== "Y"
                          }
                        />
                        <p className="insurance-service-type insurance-100-free">
                          No Selection
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code">Free</p>
                      </div>
                    </div>
                  </li>
                ) : this.props.serviceShow ===
                    "First-Class Package Service - Retail" ||
                  this.props.serviceShow === "USPS Connect Local" ||
                  this.props.serviceShow === "Ground Advantage" ? (
                  <li className="click-ship-destination-content">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <input
                          type="radio"
                          className="radio-button"
                          name="radio-insurance"
                          onChange={(e) =>
                            this.props.changeTrackingNotifications(
                              "insuranceInd"
                            )("N")
                          }
                          checked={
                            this.props.configInfo.labelInd.insuranceInd !==
                              "Y" &&
                            this.props.configInfo.labelInd
                              .insRestrictedDeliveryInd !== "Y"
                          }
                        />
                        {this.props.serviceShow === "Ground Advantage" ? (
                          <p className="insurance-service-type insurance-100-free">
                            Insurance for packages valued up to $100
                          </p>
                        ) : (
                          <p className="insurance-service-type insurance-100-free">
                            No Selection
                          </p>
                        )}
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code">Free</p>
                      </div>
                    </div>
                  </li>
                ) : this.props.serviceShow === "Priority Mail International" ? (
                  <React.Fragment>
                    <li className="click-ship-destination-content">
                      <div className="row destination-address-content">
                        <div className="col-9">
                          <p className="insurance-service-type insurance-100-free">
                            Nonnegotiable documents valued up to $100
                          </p>
                        </div>
                        <div className="col-3 text-right">
                          <p
                            id="Nonnegotiable"
                            className="destination-postal-code"
                          >
                            Free
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="click-ship-destination-content">
                      <div className="row destination-address-content">
                        <div className="col-9">
                          <p className="insurance-service-type insurance-100-free">
                            Merchandise valued up to $200
                          </p>
                        </div>
                        <div className="col-3 text-right">
                          <p
                            id="merchOver200"
                            className="destination-postal-code"
                          >
                            Free
                          </p>
                        </div>
                      </div>
                    </li>
                    {Number(this.props.configInfo.labelPkg.pkgValueAmt) >
                    200 ? (
                      <li className="click-ship-destination-content">
                        <div className="row destination-address-content">
                          <div className="col-9">
                            <input
                              type="radio"
                              className="radio-button"
                              name="radio-insurance"
                              onChange={(e) =>
                                this.props.changeTrackingNotifications(
                                  "insuranceInd"
                                )("N")
                              }
                              checked={
                                this.props.configInfo.labelInd.insuranceInd !==
                                  "Y" &&
                                this.props.configInfo.labelInd
                                  .insRestrictedDeliveryInd !== "Y"
                              }
                            />
                            <p className="insurance-service-type insurance-100-free">
                              No Selection
                            </p>
                          </div>
                          <div className="col-3 text-right">
                            <p
                              id="extraFreeInsurance"
                              className="destination-postal-code"
                            >
                              Free
                            </p>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <React.Fragment />
                    )}
                  </React.Fragment>
                ) : this.props.serviceShow ===
                  "Priority Mail Express International" ? (
                  <div>
                    <li className="click-ship-destination-content">
                      <div className="row destination-address-content">
                        <div className="col-9">
                          <p className="insurance-service-type insurance-100-free">
                            Merchandise valued up to $200
                          </p>
                        </div>
                        <div className="col-3 text-right">
                          <p className="destination-postal-code">Free</p>
                        </div>
                      </div>
                    </li>

                    <li className="click-ship-destination-content">
                      <div className="row destination-address-content">
                        <div className="col-9">
                          <p className="insurance-service-type insurance-100-free">
                            Nonnegotiable documents valued up to $100
                          </p>
                        </div>
                        <div className="col-3 text-right">
                          <p className="destination-postal-code">Free</p>
                        </div>
                      </div>
                    </li>
                  </div>
                ) : this.props.serviceShow === "Global Express Guaranteed" ? (
                  <li className="click-ship-destination-content">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <input
                          type="radio"
                          className="radio-button"
                          name="radio-insurance"
                          onChange={(e) =>
                            this.props.changeTrackingNotifications(
                              "insuranceInd"
                            )("N")
                          }
                          checked={
                            this.props.configInfo.labelInd.insuranceInd !==
                              "Y" &&
                            this.props.configInfo.labelInd
                              .insRestrictedDeliveryInd !== "Y"
                          }
                        />
                        <p className="insurance-service-type insurance-100-free">
                          Insurance for packages valued up to $100
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code">Free</p>
                      </div>
                    </div>
                  </li>
                ) : null}
                {this.state.selectedService.additionalServices.INS !==
                  undefined &&
                // PM
                ((this.props.serviceShow === "Priority Mail" &&
                  Number(this.props.configInfo.labelPkg.pkgValueAmt) > 100) ||
                  // CL
                  this.props.serviceShow === "USPS Connect Local" ||
                  // PSG
                  (this.props.serviceShow === "Ground Advantage" &&
                    Number(this.props.configInfo.labelPkg.pkgValueAmt) > 100) ||
                  // PME
                  (this.props.serviceShow === "Priority Mail Express" &&
                    Number(this.props.configInfo.labelPkg.pkgValueAmt) > 100) ||
                  // RG
                  this.props.serviceShow === "USPS Retail Ground" ||
                  // FCPS
                  this.props.serviceShow ===
                    "First-Class Package Service - Retail" ||
                  // PMI
                  (this.props.serviceShow === "Priority Mail International" &&
                    Number(this.props.configInfo.labelPkg.pkgValueAmt) > 200) ||
                  // PMEI
                  (this.props.serviceShow ===
                    "Priority Mail Express International" &&
                    Number(this.props.configInfo.labelPkg.pkgValueAmt) > 200) ||
                  // GXG
                  (this.props.serviceShow === "Global Express Guaranteed" &&
                    Number(this.props.configInfo.labelPkg.pkgValueAmt) >
                      100)) ? (
                  <React.Fragment>
                    {this.props.serviceShow ===
                    "Priority Mail Express International" ? (
                      <li className="click-ship-destination-content">
                        <div className="row destination-address-content">
                          <div className="col-9">
                            <input
                              type="radio"
                              className="radio-button"
                              name="radio-insurance"
                              onChange={(e) =>
                                this.props.changeTrackingNotifications(
                                  "insuranceInd"
                                )("N")
                              }
                              checked={
                                this.props.configInfo.labelInd.insuranceInd ===
                                "N"
                              }
                            />
                            <p className="insurance-service-type">
                              No Selection
                            </p>
                          </div>
                          <div className="col-3 text-right">
                            <p className="destination-postal-code">Free</p>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <React.Fragment />
                    )}
                    {/* INSURE FOR THE ENTIRE PACKAGE VALUE */}
                    {Number(this.props.configInfo.labelPkg.pkgValueAmt) > 0 ? (
                      <li className="click-ship-destination-content">
                        <div className="row destination-address-content">
                          <div className="col-9">
                            <input
                              type="radio"
                              className="radio-button"
                              name="radio-insurance"
                              onChange={(e) =>
                                this.props.changeTrackingNotifications(
                                  "insuranceInd"
                                )("Y")
                              }
                              checked={
                                this.props.configInfo.labelInd.insuranceInd ===
                                "Y"
                              }
                            />
                            <p className="insurance-service-type">
                              Insure for the entire package value
                              {this.props.serviceShow ===
                                "Priority Mail International" ||
                              this.props.serviceShow ===
                                "Priority Mail Express International" ||
                              this.props.serviceShow ===
                                "Global Express Guaranteed" ? (
                                <React.Fragment>
                                  <br />
                                  <span className="insurance-container-restrictions-text">
                                    Restrictions Apply
                                    {this.props.serviceShow ===
                                    "Priority Mail Express International" ? (
                                      <React.Fragment>
                                        , see{" "}
                                        <TermsConditionsModal
                                          targetName={"termsConditions"}
                                        />
                                      </React.Fragment>
                                    ) : null}
                                  </span>
                                </React.Fragment>
                              ) : null}
                            </p>
                          </div>
                          <div className="col-3 text-right">
                            <p className="destination-postal-code">
                              {
                                this.state.selectedService.additionalServices
                                  .INS.displayPrice
                              }
                            </p>
                          </div>
                        </div>
                      </li>
                    ) : null}
                    {/* INSURANCE RESTRICTED DELIVERY */}
                    {this.state.selectedService.additionalServices.INSRES !==
                      undefined &&
                    (this.props.serviceShow === "Priority Mail" ||
                      this.props.serviceShow === "Priority Mail Express") &&
                    Number(this.props.configInfo.labelPkg.pkgValueAmt) > 500 ? (
                      <li className="click-ship-destination-content">
                        <div className="row destination-address-content">
                          <div className="col-9">
                            <input
                              type="radio"
                              className="radio-button "
                              name="radio-insurance"
                              onChange={(e) =>
                                this.props.changeTrackingNotifications(
                                  "insRestrictedDeliveryInd"
                                )("Y")
                              }
                              checked={
                                this.props.configInfo.labelInd
                                  .insRestrictedDeliveryInd === "Y"
                              }
                            />
                            <p className="insurance-service-type">
                              Insurance Restricted Delivery
                            </p>
                          </div>
                          <div className="col-3 text-right">
                            <p className="destination-postal-code">
                              {
                                this.state.selectedService.additionalServices
                                  .INSRES.displayPrice
                              }
                            </p>
                          </div>
                        </div>
                      </li>
                    ) : null}
                  </React.Fragment>
                ) : null}
                {/* PACKAGE VALUE */}
                {this.props.serviceShow === "Priority Mail International" ||
                this.props.serviceShow ===
                  "Priority Mail Express International" ||
                this.props.serviceShow === "Global Express Guaranteed" ? (
                  <li className="click-ship-prio-int-destination-content">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <p
                          className="prio-int-destinaton-city-location"
                          style={{ paddingLeft: "35px" }}
                        >
                          Package value: $
                          {this.props.configInfo.labelPkg.pkgValueAmt}
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code"></p>
                      </div>
                    </div>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  renderSignatureServices = () => {
    return (
      <React.Fragment>
        {this.props.isDomesticDestination() ? (
          <div className="signature-services priority priority-express">
            <div className="row">
              <div className="col-12">
                <label className="click-ship-mailing-address">
                  <strong>Signature Services</strong>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-11 col-lg-10">
                <ul className="click-ship-service-type-price-wrapper">
                  <li className="click-ship-destination-content-label">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <p className="destinaton-city-location-label">
                          <strong>Service Type</strong>
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code-label">
                          <strong>Price</strong>
                        </p>
                      </div>
                    </div>
                  </li>

                  {Number(this.props.configInfo.labelPkg.pkgValueAmt) <= 500 &&
                  (this.props.serviceShow === "Priority Mail" ||
                    this.props.serviceShow === "USPS Connect Local" ||
                    this.props.serviceShow === "USPS Connect Local Mail" ||
                    this.props.serviceShow === "Ground Advantage") ? (
                    <div>
                      <li className="click-ship-destination-content">
                        <div className="row destination-address-content">
                          <div className="col-12">
                            <input
                              type="radio"
                              className="radio-button service-type"
                              name="radio-signature"
                              checked={
                                !this.props.configInfo.labelInd
                                  .additionalService ||
                                this.props.configInfo.labelInd
                                  .additionalService === "NONE"
                              }
                              onChange={(e) =>
                                this.props.changeTrackingNotifications(
                                  "additionalService"
                                )("")
                              }
                            />
                            <p className="destinaton-city-location">
                              No Selection
                            </p>
                          </div>
                        </div>
                      </li>
                      {this.props.configInfo.labelInd
                        .insRestrictedDeliveryInd === "N" ? (
                        <div>
                          {Object.values(
                            this.state.selectedService.additionalServices
                          ).map((item, key) => (
                            <React.Fragment>
                              {item.price !== 0 &&
                              Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] !== "INS" &&
                              Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] !== "INSRES" &&
                              Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] !== "OUTLABEL" ? (
                                <li
                                  key={Math.floor(Math.random() * 10000000) + 1}
                                  className="click-ship-destination-content"
                                >
                                  <div className="row destination-address-content">
                                    <div className="col-9">
                                      {/* Radio button for additional service */}
                                      <input
                                        onChange={(e) =>
                                          this.props.changeTrackingNotifications(
                                            "additionalService"
                                          )(
                                            Object.keys(
                                              this.state.selectedService
                                                .additionalServices
                                            )[key]
                                          )
                                        }
                                        type="radio"
                                        className="radio-button service-type"
                                        name="radio-signature"
                                        checked={
                                          this.props.configInfo.labelInd
                                            .additionalService ===
                                          Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key]
                                        }
                                      />
                                      {/* Name of additional service */}
                                      <p className="destinaton-city-location">
                                        {Object.keys(
                                          this.state.selectedService
                                            .additionalServices
                                        )[key] === "SIG" ? (
                                          "Signature Confirmation™"
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "RES" ? (
                                          <React.Fragment>
                                            Adult Signature Restricted Delivery
                                            21 or Older{" "}
                                            <AdultSigResDelivery21Older
                                              targetName={
                                                "adult-sig-res-delivery-popover-id-1"
                                              }
                                            />
                                          </React.Fragment>
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "SIGRES" ? (
                                          "Signature Confirmation™ Restricted Delivery"
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "REQ" ? (
                                          <React.Fragment>
                                            Adult Signature Required 21 or Older{" "}
                                            <AdultSigRequired21Older
                                              targetName={
                                                "adult-sig-required-popover-id-1"
                                              }
                                            />
                                          </React.Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                      {/* Electronic signature option */}
                                      {Object.keys(
                                        this.state.selectedService
                                          .additionalServices
                                      )[key] === "SIG" &&
                                      this.props.configInfo.labelInd
                                        .additionalService === "SIG" ? (
                                        <label
                                          className="checkbox-component"
                                          htmlFor="esofCheckBox"
                                          style={{
                                            paddingRight: "15px",
                                            paddingLeft: "25px",
                                            marginLeft: "25px"
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            id="esofCheckBox"
                                            checked={
                                              this.props.configInfo
                                                .eSOFAllowed === "Y"
                                            }
                                            onChange={(e) =>
                                              this.props.changeNonNestedFields(
                                                "eSOFAllowed"
                                              )(
                                                e.currentTarget.checked
                                                  ? "Y"
                                                  : "N"
                                              )
                                            }
                                          />
                                          <span
                                            className="checkbox"
                                            name="tracking-notifications-check"
                                          />
                                          <span>
                                            Do not allow an Electronic Signature
                                          </span>
                                        </label>
                                      ) : (
                                        <React.Fragment />
                                      )}
                                    </div>
                                    {/* Price of additional service */}
                                    <div className="col-3 text-right">
                                      <p className="destination-postal-code">
                                        {item.displayPrice !== "$0.00"
                                          ? item.displayPrice
                                          : "Free"}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                <div />
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  ) : (
                    <React.Fragment />
                  )}

                  {Number(this.props.configInfo.labelPkg.pkgValueAmt) > 500 &&
                  (this.props.serviceShow === "Priority Mail" ||
                    this.props.serviceShow === "USPS Connect Local" ||
                    this.props.serviceShow === "USPS Connect Local Mail" ||
                    this.props.serviceShow === "Ground Advantage") ? (
                    <div>
                      {this.props.configInfo.labelInd
                        .insRestrictedDeliveryInd === "N" ? (
                        <div>
                          {Object.values(
                            this.state.selectedService.additionalServices
                          ).map((item, key) => (
                            <React.Fragment>
                              {(item.price !== 0 ||
                                Object.keys(
                                  this.state.selectedService.additionalServices
                                )[key] === "DEL") &&
                              Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] !== "INS" &&
                              Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] !== "SIG" &&
                              Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] !== "INSRES" ? (
                                <li
                                  key={Math.floor(Math.random() * 10000000) + 1}
                                  className="click-ship-destination-content"
                                >
                                  <div className="row destination-address-content">
                                    <div className="col-9">
                                      {/* Radio button for additional service */}
                                      <input
                                        onChange={(e) =>
                                          this.props.changeTrackingNotifications(
                                            "additionalService"
                                          )(
                                            Object.keys(
                                              this.state.selectedService
                                                .additionalServices
                                            )[key]
                                          )
                                        }
                                        type="radio"
                                        className="radio-button service-type"
                                        name="radio-signature"
                                        checked={
                                          this.props.configInfo.labelInd
                                            .additionalService ===
                                          Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key]
                                        }
                                      />
                                      {/* Name of additional service:
                                    DEL is used here as a placeholder because it is also free; this is temporary until we can add a new service for free signature confirmations that can bypass rates*/}
                                      <p className="destinaton-city-location">
                                        {Object.keys(
                                          this.state.selectedService
                                            .additionalServices
                                        )[key] === "DEL" ? (
                                          "Signature Confirmation™"
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "RES" ? (
                                          <React.Fragment>
                                            Adult Signature Restricted Delivery
                                            21 or Older{" "}
                                            <AdultSigResDelivery21Older
                                              targetName={
                                                "adult-sig-res-delivery-popover-id-2"
                                              }
                                            />
                                          </React.Fragment>
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "SIGRES" ? (
                                          "Signature Confirmation™ Restricted Delivery"
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "REQ" ? (
                                          <React.Fragment>
                                            Adult Signature Required 21 or Older{" "}
                                            <AdultSigRequired21Older
                                              targetName={
                                                "adult-sig-required-popover-id-2"
                                              }
                                            />
                                          </React.Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                      {/* Electronic signature option */}
                                      {Object.keys(
                                        this.state.selectedService
                                          .additionalServices
                                      )[key] === "DEL" &&
                                      this.props.configInfo.labelInd
                                        .additionalService === "DEL" ? (
                                        <label
                                          className="checkbox-component"
                                          htmlFor="esofCheckBox"
                                          style={{
                                            paddingRight: "15px",
                                            paddingLeft: "25px",
                                            marginLeft: "25px"
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            id="esofCheckBox"
                                            checked={
                                              this.props.configInfo
                                                .eSOFAllowed === "Y"
                                            }
                                            onChange={(e) =>
                                              this.props.changeNonNestedFields(
                                                "eSOFAllowed"
                                              )(
                                                e.currentTarget.checked
                                                  ? "Y"
                                                  : "N"
                                              )
                                            }
                                          />
                                          <span
                                            className="checkbox"
                                            name="tracking-notifications-check"
                                          />
                                          <span>
                                            Do not allow an Electronic Signature
                                          </span>
                                        </label>
                                      ) : (
                                        <React.Fragment />
                                      )}
                                    </div>
                                    {/* Price of additional service */}
                                    <div className="col-3 text-right">
                                      <p className="destination-postal-code">
                                        {
                                          // If the currently iterated item is
                                          // DEL and the insure for the entire
                                          // package value is not selected,
                                          // the user must pay for signature
                                          // confirmation, so use that price
                                          // here
                                          Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "DEL" &&
                                          this.state.selectedService
                                            .additionalServices["SIG"] &&
                                          this.props.configInfo.labelInd
                                            .insuranceInd !== "Y"
                                            ? this.state.selectedService
                                                .additionalServices["SIG"]
                                                .displayPrice !== "$0.00"
                                              ? this.state.selectedService
                                                  .additionalServices["SIG"]
                                                  .displayPrice
                                              : "Free"
                                            : // Otherwise, show the DEL price
                                            // as the signature confirmation
                                            // price (should be free)
                                            item.displayPrice !== "$0.00"
                                            ? item.displayPrice
                                            : "Free"
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                <div />
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  ) : (
                    <React.Fragment />
                  )}

                  {this.props.serviceShow === "Priority Mail Express" &&
                  this.props.configInfo.labelInd.crematedRemainsInd !== "Y" ? (
                    <div>
                      {Number(this.props.configInfo.labelPkg.pkgValueAmt) <=
                      500 ? (
                        <li className="click-ship-destination-content">
                          <div className="row destination-address-content">
                            <div className="col-12">
                              <input
                                type="radio"
                                className="radio-button service-type"
                                name="radio-signature"
                                checked={
                                  !this.props.configInfo.labelInd
                                    .additionalService ||
                                  this.props.configInfo.labelInd
                                    .additionalService === "NONE"
                                }
                                onChange={(e) =>
                                  this.props.changeTrackingNotifications(
                                    "additionalService"
                                  )("")
                                }
                              />
                              <p className="destinaton-city-location">
                                No Selection
                              </p>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <React.Fragment />
                      )}
                      {this.props.configInfo.labelInd
                        .insRestrictedDeliveryInd === "N" ? (
                        <div>
                          {Object.values(
                            this.state.selectedService.additionalServices
                          ).map((item, key) => (
                            <React.Fragment>
                              {Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] !== "1030AM" &&
                              (item.price !== 0 ||
                                Object.keys(
                                  this.state.selectedService.additionalServices
                                )[key] === "SIGRQ") &&
                              Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] !== "INS" &&
                              Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] !== "SIG" &&
                              Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] !== "INSRES" ? (
                                <li
                                  key={Math.floor(Math.random() * 10000000) + 1}
                                  className="click-ship-destination-content"
                                >
                                  <div className="row destination-address-content">
                                    <div className="col-9">
                                      {/* Radio button for additional service */}
                                      <input
                                        onChange={(e) =>
                                          this.props.changeTrackingNotifications(
                                            "additionalService"
                                          )(
                                            Object.keys(
                                              this.state.selectedService
                                                .additionalServices
                                            )[key]
                                          )
                                        }
                                        type="radio"
                                        className="radio-button service-type"
                                        name="radio-signature"
                                        checked={
                                          this.props.configInfo.labelInd
                                            .additionalService ===
                                          Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key]
                                        }
                                      />
                                      {/* Name of additional service */}
                                      <p className="destinaton-city-location">
                                        {Object.keys(
                                          this.state.selectedService
                                            .additionalServices
                                        )[key] === "SIGRQ" ? (
                                          "Signature Confirmation™"
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "RES" ? (
                                          <React.Fragment>
                                            Adult Signature Restricted Delivery
                                            21 or Older{" "}
                                            <AdultSigResDelivery21Older
                                              targetName={
                                                "adult-sig-res-delivery-popover-id-3"
                                              }
                                            />
                                          </React.Fragment>
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "SIGRES" ? (
                                          "Signature Confirmation™ Restricted Delivery"
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "REQ" ? (
                                          <React.Fragment>
                                            Adult Signature Required 21 or Older{" "}
                                            <AdultSigRequired21Older
                                              targetName={
                                                "adult-sig-required-popover-id-3"
                                              }
                                            />
                                          </React.Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                      {/* Electronic signature option */}
                                      {Object.keys(
                                        this.state.selectedService
                                          .additionalServices
                                      )[key] === "SIGRQ" &&
                                      this.props.configInfo.labelInd
                                        .additionalService === "SIGRQ" ? (
                                        <label
                                          className="checkbox-component"
                                          htmlFor="esofCheckBox"
                                          style={{
                                            paddingRight: "15px",
                                            paddingLeft: "25px",
                                            marginLeft: "25px"
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            id="esofCheckBox"
                                            checked={
                                              this.props.configInfo
                                                .eSOFAllowed === "Y"
                                            }
                                            onChange={(e) =>
                                              this.props.changeNonNestedFields(
                                                "eSOFAllowed"
                                              )(
                                                e.currentTarget.checked
                                                  ? "Y"
                                                  : "N"
                                              )
                                            }
                                          />
                                          <span
                                            className="checkbox"
                                            name="tracking-notifications-check"
                                          />
                                          <span>
                                            Do not allow an Electronic Signature
                                          </span>
                                        </label>
                                      ) : (
                                        <React.Fragment />
                                      )}
                                    </div>
                                    {/* Price of additional service */}
                                    <div className="col-3 text-right">
                                      <p className="destination-postal-code">
                                        {item.displayPrice !== "$0.00"
                                          ? item.displayPrice
                                          : "Free"}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                <div />
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  ) : (
                    <React.Fragment />
                  )}

                  {this.props.serviceShow === "Priority Mail Express" &&
                  this.props.configInfo.labelInd.crematedRemainsInd === "Y" ? (
                    <div>
                      {this.props.configInfo.labelInd
                        .insRestrictedDeliveryInd === "N" ? (
                        <div>
                          {Object.values(
                            this.state.selectedService.additionalServices
                          ).map((item, key) => (
                            <React.Fragment>
                              {Object.keys(
                                this.state.selectedService.additionalServices
                              )[key] === "SIGRQ" ? (
                                <li
                                  key={Math.floor(Math.random() * 10000000) + 1}
                                  className="click-ship-destination-content"
                                >
                                  <div className="row destination-address-content">
                                    <div className="col-9">
                                      {/* Radio button for additional service */}
                                      <input
                                        onChange={(e) =>
                                          this.props.changeTrackingNotifications(
                                            "additionalService"
                                          )(
                                            Object.keys(
                                              this.state.selectedService
                                                .additionalServices
                                            )[key]
                                          )
                                        }
                                        type="radio"
                                        className="radio-button service-type"
                                        name="radio-signature"
                                        checked={
                                          this.props.configInfo.labelInd
                                            .additionalService ===
                                          Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key]
                                        }
                                      />
                                      {/* Name of additional service */}
                                      <p className="destinaton-city-location">
                                        {Object.keys(
                                          this.state.selectedService
                                            .additionalServices
                                        )[key] === "SIGRQ" ? (
                                          "Signature Confirmation™"
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "RES" ? (
                                          <React.Fragment>
                                            Adult Signature Restricted Delivery
                                            21 or Older{" "}
                                            <AdultSigResDelivery21Older
                                              targetName={
                                                "adult-sig-res-delivery-popover-id-4"
                                              }
                                            />
                                          </React.Fragment>
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "SIGRES" ? (
                                          "Signature Confirmation™ Restricted Delivery"
                                        ) : Object.keys(
                                            this.state.selectedService
                                              .additionalServices
                                          )[key] === "REQ" ? (
                                          <React.Fragment>
                                            Adult Signature Required 21 or Older{" "}
                                            <AdultSigRequired21Older
                                              targetName={
                                                "adult-sig-required-popover-id-4"
                                              }
                                            />
                                          </React.Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                      {/* Electronic signature option */}
                                      {Object.keys(
                                        this.state.selectedService
                                          .additionalServices
                                      )[key] === "SIGRQ" &&
                                      this.props.configInfo.labelInd
                                        .additionalService === "SIGRQ" ? (
                                        <label
                                          className="checkbox-component"
                                          htmlFor="esofCheckBox"
                                          style={{
                                            paddingRight: "15px",
                                            paddingLeft: "25px",
                                            marginLeft: "25px"
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            id="esofCheckBox"
                                            checked={
                                              this.props.configInfo
                                                .eSOFAllowed === "Y"
                                            }
                                            onChange={(e) =>
                                              this.props.changeNonNestedFields(
                                                "eSOFAllowed"
                                              )(
                                                e.currentTarget.checked
                                                  ? "Y"
                                                  : "N"
                                              )
                                            }
                                          />
                                          <span
                                            className="checkbox"
                                            name="tracking-notifications-check"
                                          />
                                          <span>
                                            Do not allow an Electronic Signature
                                          </span>
                                        </label>
                                      ) : (
                                        <React.Fragment />
                                      )}
                                    </div>
                                    {/* Price of additional service */}
                                    <div className="col-3 text-right">
                                      <p className="destination-postal-code">
                                        {item.displayPrice !== "$0.00"
                                          ? item.displayPrice
                                          : "Free"}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                <div />
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  };

  // renderNonDeliveryOptions = () => {
  //   return (
  //     <React.Fragment>
  //       {this.props.serviceShow === "Priority Mail International" ||
  //       this.props.serviceShow === "Priority Mail Express International" ? (
  //         <div className="row non-delivery-options-wrapper services priority-international priority-express-international">
  //           <div className="col-12 radio-buttons">
  //             <p>
  //               <strong>Non-Delivery Options</strong>
  //             </p>
  //             <div className="radio-wrap">
  //               <div className="radio-container">
  //                 <input
  //                   id="return-sender-radio"
  //                   type="radio"
  //                   className="radio-button return-sender"
  //                   name="radio-nondelivery"
  //                   value="RETURN_TO_SENDER"
  //                   onChange={(e) =>
  //                     this.props.changeTrackingNotifications("nonDelvOption")(
  //                       e.currentTarget.value
  //                     )
  //                   }
  //                   onClick={(e) =>
  //                     this.state.servicesErrors.nonDelvOption !== ""
  //                       ? this.clearServicesError("nonDelvOption")
  //                       : null
  //                   }
  //                   checked={
  //                     this.props.configInfo.labelInd.nonDelvOption ===
  //                     "RETURN_TO_SENDER"
  //                   }
  //                 />
  //                 <label htmlFor="return-sender-ratio">Return to sender</label>
  //               </div>
  //               <div className="radio-container">
  //                 <input
  //                   id="treat-abandoned-ratio"
  //                   type="radio"
  //                   className="radio-button treat-abandoned"
  //                   name="radio-nondelivery"
  //                   value="ABANDON"
  //                   onChange={(e) =>
  //                     this.props.changeTrackingNotifications("nonDelvOption")(
  //                       e.currentTarget.value
  //                     )
  //                   }
  //                   onClick={(e) =>
  //                     this.state.servicesErrors.nonDelvOption !== ""
  //                       ? this.clearServicesError("nonDelvOption")
  //                       : null
  //                   }
  //                   checked={
  //                     this.props.configInfo.labelInd.nonDelvOption === "ABANDON"
  //                   }
  //                 />
  //                 <label htmlFor="treat-abandoned-ratio">
  //                   Treat as abandoned. Fees may apply.
  //                 </label>
  //               </div>
  //               <span className="error-message">
  //                 {this.state.servicesErrors.nonDelvOption}
  //               </span>
  //             </div>
  //           </div>
  //         </div>
  //       ) : (
  //         <React.Fragment />
  //       )}
  //     </React.Fragment>
  //   );
  // };

  renderExtraServices = () => {
    return (
      <React.Fragment>
        {this.props.isDomesticDestination() ||
        this.props.serviceShow ===
          "First-Class Package International Service" ? (
          <div className="row extra-services priority services">
            <div className="col-12 col-md-11 col-lg-10">
              <label className="click-ship-mailing-address">
                <strong>Extra Services</strong>
              </label>
              <ul className="click-ship-service-type-price-wrapper">
                <li className="click-ship-destination-content-label">
                  <div className="row destination-address-content">
                    <div className="col-9">
                      <p className="destinaton-city-location-label">
                        <strong>Service Type</strong>
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <p className="destination-postal-code-label">
                        <strong>Price</strong>
                      </p>
                    </div>
                  </div>
                </li>
                {this.props.isDomesticDestination() ? (
                  <li className="click-ship-destination-content">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <p className="insurance-service-type express-insurance-text">
                          USPS Tracking<sup>®</sup>
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code">Free</p>
                      </div>
                    </div>
                  </li>
                ) : null}

                {(!this.props.customsRequiredServiceTypes ||
                  !this.props.customsRequiredServiceTypes.includes(
                    this.state.selectedService &&
                      this.state.selectedService.productClass
                  )) &&
                hidePostagePriceUtils.isServiceTypeEligible(
                  this.props.configInfo.userData,
                  this.state.selectedService &&
                    this.state.selectedService.productClass,
                  this.props.lookupCodes
                ) ? (
                  <li className="click-ship-destination-content">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <label
                          className="checkbox-component insurance-express-check"
                          htmlFor="hide-checkbox"
                        >
                          <input
                            type="checkbox"
                            id="hide-checkbox"
                            onChange={(e) =>
                              this.props.changeTrackingNotifications(
                                "stealthPostageInd"
                              )(e.currentTarget.checked ? "Y" : "N")
                            }
                            checked={
                              this.props.configInfo.labelInd
                                .stealthPostageInd === "Y"
                            }
                          />
                          <span className="checkbox" />
                        </label>
                        <p className="insurance-service-type express-insurance-text">
                          Suppress Postage from Label
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code">Free</p>
                      </div>
                    </div>
                  </li>
                ) : null}
                {this.props.serviceShow === "Priority Mail Express" ? (
                  <React.Fragment>
                    <li className="click-ship-destination-content">
                      <div className="row destination-address-content">
                        <div className="col-9">
                          <label
                            className="checkbox-component insurance-express-check"
                            htmlFor="no-saturday-check"
                          >
                            <input
                              type="checkbox"
                              id="no-saturday-check"
                              onChange={(e) =>
                                this.props.changeTrackingNotifications(
                                  "noWkndDlvyInd"
                                )(e.currentTarget.checked)
                              }
                              checked={
                                this.props.configInfo.labelInd.noWkndDlvyInd ===
                                "Y"
                              }
                            />
                            <span className="checkbox" />
                          </label>
                          <p className="insurance-service-type express-insurance-text">
                            No Delivery on Saturdays
                          </p>
                        </div>
                        <div className="col-3 text-right">
                          <p className="destination-postal-code">Free</p>
                        </div>
                      </div>
                    </li>
                    <li className="click-ship-destination-content">
                      {this.state.selectedService.productId === 200 ? (
                        <div className="row destination-address-content">
                          <div className="col-9">
                            <label
                              className="checkbox-component insurance-express-check"
                              htmlFor="cremated-remains"
                            >
                              <input
                                type="checkbox"
                                id="cremated-remains"
                                onChange={(e) =>
                                  this.props.changeTrackingNotifications(
                                    "crematedRemainsInd"
                                  )(e.currentTarget.checked)
                                }
                                checked={
                                  this.props.configInfo.labelInd
                                    .crematedRemainsInd === "Y"
                                }
                              />
                              <span className="checkbox" />
                            </label>

                            <p className="insurance-service-type express-insurance-text">
                              I am Shipping Cremated Remains
                            </p>
                          </div>

                          <div className="col-3 text-right">
                            <p className="destination-postal-code">Free</p>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment />
                      )}
                    </li>
                  </React.Fragment>
                ) : null}
                {this.showSundayDelivery() ? (
                  <li className="click-ship-destination-content">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <label
                          className="checkbox-component insurance-express-check"
                          htmlFor="sunday-delivery-check"
                        >
                          <input
                            type="checkbox"
                            id="sunday-delivery-check"
                            onChange={(e) =>
                              this.props.changeTrackingNotifications(
                                "sundayDeliveryInd"
                              )(e.currentTarget.checked)
                            }
                            checked={
                              this.props.configInfo.labelInd
                                .sundayDeliveryInd === "Y"
                            }
                          />
                          <span className="checkbox" />
                        </label>
                        <p className="insurance-service-type express-insurance-text">
                          Sunday Delivery
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code">
                          {this.state.selectedService.sundayDelivery
                            .displayPrice !== "$0.00"
                            ? this.state.selectedService.sundayDelivery
                                .displayPrice
                            : "Free"}
                        </p>
                      </div>
                      <div className="col-9">
                        <p class="insurance-service-type express-insurance-text">
                          By choosing this service, you agree to drop off this
                          package at the appropriate DDU by{" "}
                          {
                            this.props.lookupCodes[
                              "sunday_delivery_cutoff_time"
                            ]
                          }{" "}
                          on Sunday, and pay the additional Sunday delivery fee;
                          otherwise your package may not be delivered on Sunday
                        </p>
                      </div>
                    </div>
                  </li>
                ) : null}
                {this.props.serviceShow ===
                "First-Class Package International Service" ? (
                  <li className="click-ship-destination-content">
                    <div className="row destination-address-content">
                      <div className="col-9">
                        <p
                          className="insurance-service-type express-insurance-text"
                          style={{ paddingLeft: "30px" }}
                        >
                          Electronic USPS Delivery Confirmation<sup>&reg;</sup>{" "}
                          International
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="destination-postal-code">Free</p>
                      </div>
                    </div>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  };

  renderLabelSummary = () => {
    return (
      <div className="col-12 col-md-11 col-lg-10 step-five-label-summary priority">
        <ul className="click-ship-label-summary-wrapper">
          <li className="label-summary-text">
            <div className="row label-summary-top">
              <div className="col-12">
                <p className="label-summary-label">Label Summary</p>
              </div>
            </div>
          </li>
          {this.props.isDomesticDestination() ? (
            <li className="label-summary-content">
              <div className="row label-summary-wrapper">
                <div className="col-9">
                  <p className="label-summary-data">
                    USPS Tracking<sup>®</sup>
                  </p>
                </div>
                <div className="col-3 text-right">
                  <p className="label-summary-price">Free</p>
                </div>
              </div>
            </li>
          ) : (
            <React.Fragment />
          )}
          {this.props.configInfo.hazmatType !== null &&
          this.props.configInfo.hazmatType != "0" ? (
            <li className="label-summary-content">
              <div className="row label-summary-wrapper">
                <div className="col-9">
                  <p className="label-summary-data">HAZMAT</p>
                </div>
                <div className="col-3 text-right">
                  <p className="label-summary-price">Free</p>
                </div>
              </div>
            </li>
          ) : (
            <React.Fragment />
          )}
          {this.props.configInfo.labelInd.stealthPostageInd === "Y" ? (
            <li className="label-summary-content">
              <div className="row label-summary-wrapper">
                <div className="col-9">
                  <p className="label-summary-data">
                    Suppress Postage from Label
                  </p>
                </div>
                <div className="col-3 text-right">
                  <p className="label-summary-price">Free</p>
                </div>
              </div>
            </li>
          ) : (
            <React.Fragment />
          )}
          {this.props.configInfo.labelInd.sundayDeliveryInd === "Y" &&
          this.state.selectedService.sundayDelivery ? (
            <li className="label-summary-content">
              <div className="row label-summary-wrapper">
                <div className="col-9">
                  <p className="label-summary-data">Sunday Delivery</p>
                </div>
                <div className="col-3 text-right">
                  <p className="label-summary-price">
                    {this.state.selectedService.sundayDelivery.displayPrice !==
                    "$0.00"
                      ? this.state.selectedService.sundayDelivery.displayPrice
                      : "Free"}
                  </p>
                </div>
              </div>
            </li>
          ) : null}
          {this.props.serviceShow !==
            "First-Class Package International Service" &&
          this.props.serviceShow !== "USPS Retail Ground" &&
          this.props.serviceShow !== "First-Class Package Service - Retail" &&
          this.props.serviceShow !== "USPS Connect Local" &&
          this.props.serviceShow !== "USPS Connect Local Mail" &&
          this.props.serviceShow !== "Ground Advantage" &&
          this.props.configInfo.labelInd.insuranceInd === "N" &&
          this.props.configInfo.labelInd.insRestrictedDeliveryInd === "N" ? (
            <li className="label-summary-content">
              <div className="row label-summary-wrapper">
                <div className="col-9">
                  <p className="label-summary-data">
                    Insurance is covered up to $
                    {this.props.serviceShow === "Priority Mail" ||
                    this.props.serviceShow === "Priority Mail Express" ||
                    this.props.serviceShow === "Global Express Guaranteed" ||
                    this.props.serviceShow === "Ground Advantage"
                      ? 100
                      : this.props.serviceShow ===
                          "Priority Mail International" ||
                        this.props.serviceShow ===
                          "Priority Mail Express International"
                      ? 200
                      : 0}
                  </p>
                </div>
                <div className="col-3 text-right">
                  <p className="label-summary-price">Free</p>
                </div>
              </div>
            </li>
          ) : this.props.configInfo.labelInd.insuranceInd === "Y" &&
            this.props.configInfo.labelInd.insRestrictedDeliveryInd === "N" &&
            this.state.selectedService.additionalServices.INS ? (
            <li className="label-summary-content">
              <div className="row label-summary-wrapper">
                <div className="col-9">
                  <p className="label-summary-data">
                    Insure for the entire package value
                  </p>
                </div>
                <div className="col-3 text-right">
                  <p className="label-summary-price">
                    {
                      this.state.selectedService.additionalServices.INS
                        .displayPrice
                    }
                  </p>
                </div>
              </div>
            </li>
          ) : this.props.configInfo.labelInd.insRestrictedDeliveryInd === "Y" &&
            this.props.configInfo.labelInd.insuranceInd === "N" ? (
            <li className="label-summary-content">
              <div className="row label-summary-wrapper">
                <div className="col-9">
                  <p className="label-summary-data">
                    Insurance Restricted Delivery
                  </p>
                </div>
                <div className="col-3 text-right">
                  <p className="label-summary-price">
                    {
                      this.state.selectedService.additionalServices.INSRES
                        .displayPrice
                    }
                  </p>
                </div>
              </div>
            </li>
          ) : (
            <div />
          )}
          {this.props.configInfo.labelInd.additionalService &&
          this.props.configInfo.labelInd.additionalService !== "NONE" ? (
            <li className="label-summary-content">
              <div className="row label-summary-wrapper">
                <div className="col-9">
                  <p className="label-summary-data">
                    {this.props.configInfo.labelInd.additionalService ===
                      "SIG" ||
                    this.props.configInfo.labelInd.additionalService ===
                      "SIGRQ" ||
                    this.props.configInfo.labelInd.additionalService === "DEL"
                      ? "Signature Confirmation™"
                      : this.props.configInfo.labelInd.additionalService ===
                        "RES"
                      ? "Adult Signature Restricted Delivery 21 or Older"
                      : this.props.configInfo.labelInd.additionalService ===
                        "REQ"
                      ? "Adult Signature Required 21 or Older"
                      : this.props.configInfo.labelInd.additionalService ===
                        "SIGRES"
                      ? "Signature Confirmation™ Restricted Delivery"
                      : ""}
                  </p>
                </div>
                <div className="col-3 text-right">
                  <p className="label-summary-price">
                    {this.displayAdditionalServicePrice()}
                  </p>
                </div>
              </div>
            </li>
          ) : (
            <div />
          )}
          {this.props.configInfo.labelInd.crematedRemainsInd === "Y" ? (
            <li className="label-summary-content">
              <div className="row label-summary-wrapper">
                <div className="col-9">
                  <p className="label-summary-data">Cremated Remains</p>
                </div>
                <div className="col-3 text-right">
                  <p className="label-summary-price">Free</p>
                </div>
              </div>
            </li>
          ) : (
            <div />
          )}

          {this.props.configInfo.labelInd.deliveryTimeOption === "Y" &&
          this.state.selectedService.additionalServices &&
          this.state.selectedService.additionalServices["1030AM"] ? (
            <li className="label-summary-content">
              <div className="row label-summary-wrapper">
                <div className="col-9">
                  <p className="label-summary-data">10:30 Delivery Time</p>
                </div>
                <div className="col-3 text-right">
                  <p className="label-summary-price">
                    {
                      this.state.selectedService.additionalServices["1030AM"]
                        .displayPrice
                    }
                  </p>
                </div>
              </div>
            </li>
          ) : (
            <div />
          )}

          <li className="label-summary-content">
            <div className="row label-summary-wrapper">
              <div className="col-9">
                <p
                  className="label-summary-data"
                  dangerouslySetInnerHTML={{
                    __html: this.state.selectedService.productNameDisplay
                  }}
                />
              </div>
              <div className="col-3 text-right">
                <p className="label-summary-price">
                  {this.props.configInfo.batch.length > 0
                    ? "($" +
                      this.state.selectedService.price.toFixed(2) +
                      " x " +
                      this.props.configInfo.batch.length +
                      " labels) " +
                      "$" +
                      Number(
                        this.state.selectedService.price *
                          this.props.configInfo.batch.length
                      ).toFixed(2)
                    : "$" + this.state.selectedService.price.toFixed(2)}
                </p>
              </div>
            </div>
          </li>

          <div className="horizontal-line-container">
            <hr className="horizontal-line label-summary-horizonal-line" />
          </div>
          <li className="label-summary-total-content">
            <div className="row label-summary-wrapper">
              <div className="col-9">
                <p className="label-summary-total">Total</p>
              </div>
              <div className="col-3 text-right">
                <p className="label-summary-price">${this.calculateTotal()}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <ServiceTypeViewServices
          serviceShow={this.props.serviceShow}
          listOfServices={this.props.listOfServices}
          setPackageTypeProductId={this.props.setPackageTypeProductId}
          saveSelectedServiceInfo={this.saveSelectedServiceInfo}
          configInfo={this.props.configInfo}
          sortColumns={this.props.sortColumns}
          isDomesticDestination={this.props.isDomesticDestination}
          serviceInfoIconId={this.props.serviceInfoIconId}
          setServiceInfoIconId={this.props.setServiceInfoIconId}
          changeTrackingNotifications={this.props.changeTrackingNotifications}
          serviceIconRefs={this.props.serviceIconRefs}
          addServiceIconRef={this.props.addServiceIconRef}
          sortPackageTypeAsc={this.props.sortPackageTypeAsc}
          sortPriceAsc={this.props.sortPriceAsc}
          selectedDropOffLocationIndex={this.props.selectedDropOffLocationIndex}
          dropOffLocations={this.props.dropOffLocations}
          dropoffLocationData={this.props.dropoffLocationData}
          lookupCodes={this.props.lookupCodes}
          dropOffAddressSelect={this.dropOffAddressSelect}
          dropoffIndex={this.state.dropoffIndex}
        />

        {this.props.configInfo.saveLabelInfoAdditionalData.productId !== "" &&
        this.state.selectedService ? (
          <React.Fragment>
            {this.props.serviceShow === "Global Express Guaranteed" ? (
              <p className="alert-message gxg-suspension-message">
                The Global Express Guaranteed<sup>&reg;</sup> money-back
                guarantee for packages to{" "}
                {this.props.configInfo.deliveryAddress.countryName} is currently
                suspended.
              </p>
            ) : null}
            {this.render1030Delivery()}

            {this.props.serviceShow !== "USPS Connect Local Mail"
              ? this.renderInsurance()
              : null}

            <div
              id="priority"
              className="insurance-container services priority"
            >
              <div className="row">
                <div className="col-12 col-md-11 col-lg-10" />
              </div>
              <div className="row">
                <div className="col-12 col-md-11 col-lg-10">
                  <ul className="click-ship-service-type-price-wrapper">
                    <li className="click-ship-destination-content-label">
                      <div className="row destination-address-content">
                        <div className="col-9">
                          <p className="destinaton-city-location-label">
                            <strong>Tracking</strong>
                          </p>
                        </div>
                        <div className="col-3 text-right">
                          <p className="destination-postal-code-label" />
                        </div>
                      </div>
                    </li>
                    <li className="click-ship-destination-content">
                      <div className="row destination-address-content">
                        <div className="col-9">
                          <input
                            type="radio"
                            className="radio-button"
                            name="t"
                          />
                          <p className="insurance-service-type insurance-100-free">
                            {" "}
                            USPS Tracking<sup>®</sup>
                          </p>
                        </div>
                        <div className="col-3 text-right">
                          <p className="destination-postal-code">Free</p>
                        </div>
                      </div>
                    </li>
                    <div />
                    <div />
                  </ul>
                </div>
              </div>
            </div>

            {this.renderSignatureServices()}
            {/* {this.renderNonDeliveryOptions()} */}
            {this.renderExtraServices()}
            {!this.props.isDomesticDestination() ? (
              <div
                className="row non-delivery-options-wrapper services priority-international priority-express-international"
                style={{ display: "block" }}
              >
                <div className="col-12 radio-buttons">
                  <p>
                    <strong>Non-Delivery Options</strong>
                  </p>
                  <div className="radio-wrap">
                    <div className="radio-container">
                      <input
                        id="return-sender-radio"
                        type="radio"
                        checked={
                          this.props.configInfo.labelInd.nonDelvOption ===
                          "RETURN_TO_SENDER"
                            ? true
                            : false
                        }
                        className="radio-button return-sender"
                        name="radio-nondelivery"
                        onClick={(e) =>
                          this.props.changeTrackingNotifications(
                            "nonDelvOption"
                          )("RETURN_TO_SENDER")
                        }
                      />
                      <label htmlFor="return-sender-ratio">
                        Return to sender
                      </label>
                    </div>
                    <div className="radio-container">
                      <input
                        id="treat-abandoned-ratio"
                        type="radio"
                        checked={
                          this.props.configInfo.labelInd.nonDelvOption ===
                          "ABANDON"
                            ? true
                            : false
                        }
                        className="radio-button return-abandon"
                        name="radio-nondelivery"
                        onClick={(e) =>
                          this.props.changeTrackingNotifications(
                            "nonDelvOption"
                          )("ABANDON")
                        }
                      />
                      <label htmlFor="treat-abandoned-ratio">
                        Treat as abandoned. Fees may apply.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment />
            )}

            {this.renderLabelSummary()}

            <div className="row step-five-display-items">
              <div className="col-12 col-md-11 col-lg-10 expand-collapse-wrapper collapsed">
                <p className="expand-collapse-header">Privacy Act Statement</p>
                <div className="expandable-content">
                  <p
                    style={
                      !this.state.showPrivacy
                        ? {}
                        : {
                            height: "120px",
                            "overflow-y": "hidden"
                          }
                    }
                  >
                    Your information will be used to respond to your mail
                    recovery service request. Collection is authorized by 39
                    U.S.C. 401, 403, and 404. Providing the information is
                    voluntary, but if not provided, we may not process your
                    request in the mail recovery application. We do not disclose
                    your information to third parties without your consent,
                    except to facilitate the transaction, to act on your behalf
                    or request, or as legally required. This includes the
                    following limited circumstances: to a congressional office
                    on enforcement agency in accordance with law. (e) to the
                    sender or address of the mail-piece in connection with the
                    resolution of a claim. (f) to an expert consultant for the
                    purpose of determining the value of a lost or damaged item,
                    or to determine otherwise the validity of the claim. For
                    more information regarding our privacy policies visit{" "}
                    <a href="https://www.usps.com/privacypolicy">
                      <strong>www.usps.com/privacypolicy</strong>
                    </a>
                    .
                  </p>
                  <button
                    type="button"
                    className={
                      "expand-collapse button-link" +
                      (this.state.showPrivacy ? " expanded" : " collapsed")
                    }
                    tabIndex="0"
                    style={{ cursor: "pointer" }}
                    onClick={(e) =>
                      this.setState((prevState) => {
                        return {
                          showPrivacy: !prevState.showPrivacy
                        };
                      })
                    }
                  >
                    <span className="visuallyhidden">
                      Expand additional information.
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="row step-five-domestic-continue">
              <div className="col-12 button-wrapper">
                <div className="button-container">
                  <a
                    href={
                      !this.state.selectedService.customsRequired
                        ? "/shippingCart"
                        : this.state.selectedService.productNameDisplay &&
                          this.state.selectedService.productNameDisplay
                            .replace("&reg;", "")
                            .replace("®", "")
                            .replace("&trade;", "")
                            .replace("™", "") === "Global Express Guaranteed"
                        ? "/gxgLocationsDestinations"
                        : "/customsInformation"
                    }
                    role="button"
                    className="btn-primary"
                    onClick={(e) => this.addToCartNoBatch(e, false)}
                  >
                    {!this.props.isFromCart
                      ? !this.state.selectedService.customsRequired
                        ? "Add to Cart"
                        : "Continue"
                      : "Update Label"}
                  </a>
                </div>
                {!this.state.selectedService.customsRequired ? (
                  <div className="button-container">
                    <button
                      type="button"
                      className="btn-primary button--white"
                      onClick={(e) => this.addToCartNoBatch(e, true)}
                    >
                      {!this.props.isFromCart ? "Add to Cart" : "Update Label"}{" "}
                      &amp; Start New Label
                    </button>
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ServiceTypeSelectThePackage);
