import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const AesExemptionITNPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        An Internal Transaction Number (ITN) or AES Downtime Citation is a
        number provided by the US Census Bureau upon successfully filing of
        export information is required when either of the following applies:
      </p>
      <p>
        <strong>A</strong>. A type of goods (per schedule B Export Codes at{" "}
        <a
          href="http://www.census.gov/foreign-trade/schedules/b/"
          className="inline-link secondary"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.census.gov/foreign-trade/schedules/b/
        </a>
        ) is valued at more than $2,500
      </p>
      <p>
        <strong>B</strong>. An item requires an export license under U.S. Law to
        determine if an export license is required, go to:{" "}
        <a
          href="http://www.export.gov/regulation/exp_reg_licenses.asp"
          className="inline-link secondary"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.export.gov/regulation/exp_reg_licenses.asp
        </a>{" "}
        or call: 1-800-USA-TRADE
      </p>
      <p>
        <strong>NOTE</strong>: Customers who fail to electronically file their
        export information may be subject to civil and criminal penalties.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="AES Exemption Information Icon"
      header="AES International"
      content={content}
    />
  );
};

export default AesExemptionITNPopover;
