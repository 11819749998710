import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const TermsConditionsDeliveryPopover = (props) => {
  const content = (
    <React.Fragment>
      {!props.forOcpi ? (
        <p>
          CTP - Carrier Paid To
          <br />
          CIP - Carriage, Insurance, Paid To
          <br />
          DAF - Delivery at Frontier
          <br />
          DDU - Delivered Duty Unpaid
          <br />
          Other - If other, customer will be required to input their own terms,
          up to 30 characters.
        </p>
      ) : (
        <p>DAP - Delivery at Place</p>
      )}
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Delivery Terms and Conditions Information Icon"
      header="Terms and Conditions of Delivery"
      content={content}
    />
  );
};

export default TermsConditionsDeliveryPopover;
