import React, { useState } from "react";
import { Modal } from "reactstrap";

const TermsConditionsGxgModal = (props) => {
  const [modal, setModal] = useState(false);

  const { buttonLabel } = props;

  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  return (
    <React.Fragment>
      <button
        type="button"
        color="#333366"
        onClick={toggle}
        className="inline-link right-chevron button-link"
        style={{ color: "#333366", fontWeight: "bold ", cursor: "pointer" }}
      >
        {buttonLabel}
      </button>
      <Modal isOpen={modal} toggle={toggle}>
        <div className="modal-content modal-container">
          <div className="modal-header">
            <button
              type="button"
              className="close button-link"
              id="close"
              data-dismiss="modal"
              tabIndex="0"
              onClick={toggle}
            >
              <span className="visuallyhidden">Close Modal</span>
            </button>
            <h3 className="modal-title">{buttonLabel}</h3>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <div id="gxgTermsConditions">
                <div className="scrollable">
                  <div className="scrollContainer">
                    <div
                      className="scrollInterior"
                      style={{ maxHeight: "600px", overflow: "auto" }}
                    >
                      <ol>
                        <li>
                          <p>
                            <b>1. The USPS Contract With You:</b> These terms
                            and conditions, along with those set out in the
                            Global Express Guaranteed&reg; Service Guide
                            (Service Guide) and the International Mail Manual
                            (IMM), are all the terms of the contract between you
                            (the sender of the shipment) and us (USPS, FedEx
                            Express, and/or their agents). When you tender a
                            shipment to us, you accept our terms for you, and
                            for anyone else who has a legal interest in the
                            shipment. Our terms and conditions also protect
                            anyone with whom we may contract to collect,
                            transport, or deliver your shipment. No employee of
                            USPS, FedEx Express, or anyone else has any
                            authority to change any of our terms or conditions,
                            or make any promises on our behalf. If there is a
                            conflict between the terms and conditions of the Air
                            Waybill (Shipping Label), this Service Guide, or the
                            International Mail Manual (IMM) then in effect, the
                            current Service Guide or International Mail Manual
                            (IMM) will control, in that order of priority.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>2. What "Shipment" Means:</b> A shipment means
                            all allowable contents that travel under one Air
                            Waybill (Shipping Label), not just any single
                            document or item included in a shipment. You certify
                            that the shipment details are complete and accurate.
                            You agree that all shipments may be carried by any
                            means, including air, surface, or any other carrier.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>3. Your Obligations:</b> You warrant that each
                            article in the shipment is properly described on
                            this Air Waybill (Shipping Label) and any
                            accompanying documents, acceptable for transport by
                            us, and properly marked, addressed, and packed to
                            ensure safe transportation with ordinary care in
                            handling. You are responsible for all charges,
                            including postage, and possible surcharges, Customs
                            and duties assessments including fees related to our
                            prepayment of the same, governmental penalties and
                            fines, taxes, and our attorney fees and legal costs,
                            related to this shipment.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>4. Allowable Contents:</b> We accept as shipments
                            documents (correspondence and printed matter "DOC")
                            and non-documents (all other items "NON-DOC"), with
                            restrictions on content and value as defined by each
                            destination country in the Service Guide. No
                            shipment may contain contents with a total value
                            greater than US $2,499. NON-DOC shipments may be
                            subject to inspection.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>5. Prohibited Contents:</b> We do not accept as a
                            shipment anything that is considered a restricted
                            article or hazardous material as defined by the
                            Department of Transportation (DOT), or dangerous
                            goods as defined by the International Air Transport
                            Association (IATA) or the International Civil
                            Aviation Organization (ICAO), or certain other items
                            listed in the Service Guide or International Mail
                            Manual (IMM), such as cash or cash equivalents. See
                            the Service Guide for additional prohibited items
                            and restrictions for this service.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>6. Customs:</b> You appoint us as your agent to
                            conduct export and import clearance. You also
                            certify us as the nominal consignee solely for the
                            purpose of designating a Customs broker to perform
                            Customs clearance. You are responsible for and
                            warrant your compliance with all applicable laws,
                            rules, and regulations, including but not limited to
                            Customs laws; import, export, and reexport laws; and
                            governmental regulations of any country to, from,
                            through, or over which your shipment may be carried.
                            You agree to furnish such information and complete
                            and attach to this Air Waybill (Shipping Label) such
                            documents as are necessary to comply with such laws,
                            rules, and regulations. We assume no liability to
                            you or any other person for any losses or expenses
                            due to your failure to comply with this provision.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>7. Export Control:</b> You hereby certify that
                            all statements and information contained in this Air
                            Waybill (Shipping Label) and any accompanying
                            documents relating to exportation are true and
                            correct. You certify that this shipment does not
                            require filing of Electronic Export Information, or
                            EEI (formerly known as Shipper's Export Declaration,
                            or SED) or any such filing of export data.
                            Furthermore, you understand that civil and criminal
                            penalties, including forfeiture and sale, may be
                            imposed for making false or fraudulent statements,
                            or for violation of any United States Laws on
                            exportation, including but not limited to 13 USC
                            Sec. 305, 22 USC Sec. 401, 18 USC Sec. 1001, and 50
                            USC App. 2410.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>8. Letter of Instruction:</b> If you do not
                            complete all the documents required for carriage or
                            if the documents you submit are not appropriate for
                            the services or destination requested, you hereby
                            instruct us, where permitted by law, to complete,
                            correct, or replace the documents for you. However,
                            we are not obligated to do so. If a substitute form
                            of air waybill is needed to complete delivery of
                            your shipment and we complete that document, the
                            terms of this Air Waybill (Shipping Label) will
                            continue to govern. We are not liable to you or any
                            other person for our actions on your behalf under
                            this provision.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>9. Money-Back Guarantee:</b> If the shipment is
                            accepted at a designated USPS retail outlet or other
                            authorized location by the specified deposit time
                            for Global Express Guaranteed&reg; service, the
                            shipment will be delivered to the recipient in
                            accordance with the transit times outlined in the
                            Service Guide on or before the close of business in
                            the local time zone or we will refund your postage
                            paid, as outlined below. Check with your local
                            authorized USPS acceptance office for specific
                            information about services and the Money-Back
                            Guarantee. If the shipment is not delivered by the
                            scheduled day and you file a refund request, the
                            USPS will refund the postage unless: 1) the delivery
                            was attempted but could not be made; 2) the delivery
                            address was incomplete or inaccurate; or 3) the
                            shipment was delayed by circumstances beyond our
                            control as outlined in Paragraph 13. This Money-Back
                            Guarantee does not apply to shipments to P.O. Box
                            addresses acceptable for delivery. All requests must
                            be initiated within 30 days of the mailing date by
                            contacting a Customer Service Representative at
                            1-800-222-1811; this Representative will provide
                            more details on how to file a refund request. The
                            original receipt of the Air Waybill (Shipping Label)
                            may be required when filing a refund request.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>10. Loss or Damage Claims:</b> If the shipment is
                            lost or damaged, you may file a claim. All claims
                            must be initiated within 30 days of the mailing date
                            by contacting a Customer Service Representative at
                            1-800-222-1811; this Representative will provide
                            more details on how to file a claim. The original
                            receipt of this Air Waybill (Shipping Label) may be
                            required when filing a claim. If the recipient
                            accepts the shipment without noting any damage on
                            the delivery record, we will assume the shipment was
                            delivered in good condition. In order for us to
                            consider a claim for damage, the contents, original
                            shipping cartons, and packing must be available to
                            us for inspection. Written documentation supporting
                            the amount of a claim may also be required. All
                            supporting documentation must be submitted within 9
                            months of the mailing date.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>11. Insurance Coverage: </b> Additional insurance
                            may be purchased for shipments not to exceed the
                            total shipment value of US $2,499 or a lesser amount
                            as limited by country, content, or value. You are
                            responsible for requesting insurance and paying the
                            applicable fee. Regardless of who is at fault, if
                            you fail to pay the applicable fee, our liability
                            will be limited to US $100. As provided in Paragraph
                            15 below, DOC shipments are insured up to US $100
                            per shipment at no additional charge for document
                            reconstruction (for the reasonable costs incurred in
                            reconstructing duplicates of nonnegotiable documents
                            mailed), and NON-DOC shipments are insured against
                            loss or damage up to US $100 per shipment at no
                            additional charge. Additional insurance up to a
                            total of US $2,499 per shipment may be purchased for
                            an additional fee. Coverage, terms, and limitations
                            are subject to change. Consult the Service Guide for
                            insurance fees, limitations, and terms of coverage
                            to each individual country.
                          </p>
                        </li>
                        <br />
                        <b>WE ARE NOT LIABLE FOR</b>
                        <br />
                        <li>
                          <p>
                            <b>12. Delayed Shipments:</b> We will make our best
                            effort to deliver your shipment according to our
                            regular delivery schedules. Except as provided in
                            Paragraph 15 below, USPS, FedEx Express, and/or
                            their agents are not liable for any losses or
                            damages caused by delays, even if they are our
                            fault, in picking up, transporting (including delays
                            caused by diversions), or delivering a shipment.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>13. Circumstances Beyond Our Control:</b> We are
                            not liable if a shipment is lost, misdelivered,
                            damaged, or delayed because of circumstances beyond
                            our control. These circumstances include: terrorist
                            activity; strikes; labor actions; an "act of God,"
                            for example an earthquake, cyclone, storm, or flood;
                            "force majeure," for example war, plane crash, or
                            embargo; import/export clearance delays; any defect
                            or characteristic to do with the nature of the
                            shipment, even if known to us when we accepted it;
                            or any action or omission by anyone outside USPS,
                            FedEx Express, or their agents, for example the
                            sender of the shipment, the recipient, or an
                            interested third party, Customs or government
                            officials, or other carriers or third parties with
                            whom we contract to deliver to destinations that we
                            do not serve directly. We are not liable even if
                            you, the sender, did not ask for or know about a
                            thirdparty delivery. We are also not liable for
                            electrical or magnetic damage to, or erasure of,
                            electronic or photographic images or recordings.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>14. Consequential Damages:</b> We are not liable
                            for the following, whether they arise in contract or
                            any other form of civil action, including
                            negligence, and even if they are our fault:
                            consequential or special damages or loss; other
                            indirect loss; or breach of other contracts.
                            Consequential damage or loss includes but is not
                            limited to loss of income, profit, interest,
                            markets, and use of contents.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>15. Extent of Our Liability:</b> We make no
                            warranties, express or implied. Our liability for a
                            shipment that is not delivered according to the
                            Money-Back Guarantee is limited to a refund of the
                            postage paid by the sender as outlined in Paragraph
                            9. Unless additional insurance is purchased, our
                            liability for a lost or damaged shipment is limited
                            to the lowest of US $100, or the actual amount of
                            the loss or damage, or the actual value of the
                            allowable contents. Actual value means the lowest of
                            the cost of replacing, reconstructing, or
                            reconstituting the allowable contents of the
                            shipment (determined at the time and place of
                            acceptance). These terms and conditions, and where
                            applicable the Warsaw Convention, limit and/or
                            exclude our liability for loss, damage, or delay.
                            You agree that there are no agreed stopping places,
                            and we reserve the right to route the shipment in
                            any way we deem appropriate.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>16. False Statements and Right to Inspect:</b>{" "}
                            The submission of a false, fictitious, or fraudulent
                            statement may result in imprisonment of up to 5 to 8
                            years depending on the nature of the violation (18
                            USC Sec. 1001). In addition, a civil penalty of up
                            to US $5,000 and an additional assessment of twice
                            the amount falsely claimed may be imposed (31 USC
                            Sec. 3802). Your NONDOC shipment may, at our option
                            or at the request of governmental authorities, be
                            opened and inspected by us or such authorities at
                            any time.
                          </p>
                        </li>

                        <li>
                          <p>
                            <b>17. Mandatory Law:</b> Insofar as any provision
                            contained or referred to in this Air Waybill
                            (Shipping Label) may be contrary to any applicable
                            international treaties, laws, governmental
                            regulations, orders, or requirements, such provision
                            shall remain in effect as part of our agreement to
                            the extent that it is not overridden. The invalidity
                            or unenforceability of any provision shall not
                            affect any other provision contained or referred to
                            in this Air Waybill (Shipping Label). Unless
                            otherwise indicated, the sender's address indicated
                            on the face of this Air Waybill (Shipping Label) is
                            the place of execution and the place of departure,
                            and the recipient's address listed on the face of
                            this Air Waybill (Shipping Label) is the place of
                            destination.
                          </p>
                        </li>
                        <br />
                        <b>PRIVACY ACT STATEMENT</b>
                        <li>
                          <p>
                            <b>18. Privacy Act Statement:</b> Your information
                            will be used to fulfill the product or service you
                            requested. Collection is authorized by 39 U.S.C.
                            401, 403, 404, 407; 13 U.S.C. 301- 307; and 50
                            U.S.C. 1702. Providing this information is
                            voluntary, but if not provided, we may not process
                            your transaction. We do not disclose your
                            information to third parties without your consent,
                            except to facilitate the transaction, to act on your
                            behalf or request, or as legally required. This
                            includes the following limited circumstances: to a
                            congressional office on your behalf; to financial
                            entities regarding financial transaction issues; to
                            a USPS&reg; auditor; to entities, including law
                            enforcement, as required by law or in legal
                            proceedings; to contractors and other entities
                            aiding us to fulfill the service (service
                            providers); to domestic and international customs
                            pursuant to federal law and agreements, the U.S.
                            Census Bureau, and other governmental entities with
                            authority over exportation. For more information
                            regarding our privacy policies visit
                            www.usps.com/privacypolicy.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default TermsConditionsGxgModal;
