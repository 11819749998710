import React, { useEffect, useRef } from "react";
import { Popover, Modal } from "reactstrap";

const PriorityMailPopover = (props) => {
  const { targetName, setServiceInfoIconId, infoIconServiceId } = props;

  const popoverTextRef = useRef(null);

  useEffect(() => {
    // Set the focus to inside the popover
    if (popoverTextRef.current) {
      const xPosition = window.scrollX;
      const yPosition = window.scrollY;
      popoverTextRef.current.focus();
      // This is to maintain the current screen position instead of directing
      // the user to the top of the page
      window.scrollTo(xPosition, yPosition);
    }
  }, [targetName]);

  const toggle = (e) => {
    e.preventDefault();
    setServiceInfoIconId(undefined, infoIconServiceId);
  };

  return (
    <React.Fragment>
      {window.innerWidth > 760 ? (
        <Popover
          placement="auto"
          trigger="legacy"
          isOpen={true}
          target={targetName}
          toggle={(e) => toggle(e)}
        >
          <div className="domestic-shipping-option-popover">
            <div className="popover-wrapper">
              <div className="popover-header">
                <button
                  type="button"
                  className="close button-link"
                  data-dismiss="popover"
                  tabIndex="0"
                  onClick={toggle}
                  ref={popoverTextRef}
                >
                  <span className="visuallyhidden">Close Popover</span>
                </button>
                <h4>
                  Priority Mail<sup>®</sup> Service
                </h4>
              </div>
              <p>
                Use Priority Mail<sup>®</sup> to send merchandise to over 190
                countries worldwide. Your shipments are economical and receive
                priority handling to all destinations.
              </p>
              <p>
                Priority Mail International<sup>®</sup> features:
              </p>
              <ul>
                <li>
                  <p>USPS Tracking</p>
                </li>
                <li>
                  <p>Free Package Pickup</p>
                </li>
                <li>
                  <p>Insurance Included</p>
                </li>
                <li>
                  <p>Free Shipping Supplies</p>
                </li>
              </ul>
              <p>USPS Customs Forms Envelope (2976-E) is required.</p>
              <p>
                Available online at The Postal Store<sup>®</sup> or at your
                local Post Office<sup>™</sup>.
              </p>
            </div>
          </div>
        </Popover>
      ) : (
        <React.Fragment>
          <Modal isOpen={true} toggle={toggle}>
            <div className="modal-content modal-container">
              <div className="modal-header">
                <button
                  type="button"
                  className="close button-link"
                  data-dismiss="modal"
                  tabIndex="0"
                  onClick={toggle}
                >
                  <span className="visuallyhidden">Close Modal</span>
                </button>
                <h3 className="modal-title">
                  Priority Mail<sup>®</sup> Service
                </h3>
              </div>
              <div className="modal-body">
                <div className="body-content">
                  <p>
                    Use Priority Mail<sup>®</sup> to send merchandise to over
                    190 countries worldwide. Your shipments are economical and
                    receive priority handling to all destinations.
                  </p>
                  <p>
                    Priority Mail International<sup>®</sup> features:
                  </p>
                  <ul>
                    <li>
                      <p>USPS Tracking</p>
                    </li>
                    <li>
                      <p>Free Package Pickup</p>
                    </li>
                    <li>
                      <p>Insurance Included</p>
                    </li>
                    <li>
                      <p>Free Shipping Supplies</p>
                    </li>
                  </ul>
                  <p>USPS Customs Forms Envelope (2976-E) is required.</p>
                  <p>
                    Available online at The Postal Store<sup>®</sup> or at your
                    local Post Office<sup>™</sup>.
                  </p>
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PriorityMailPopover;
