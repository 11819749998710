import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import update from "immutability-helper";
import DeleteLabelItem from "../Modals/DeleteLabelItem";
import DeleteAllLabelItems from "../Modals/DeleteAllLabelItems";
import TaxIdPopover from "../Popovers/TaxIdPopover";
import DetailedDescPopover from "../Popovers/DetailedDescPopover";
import CountryOfOriginPopover from "../Popovers/CountryOfOriginPopover";
import HsTariffPopover from "../Popovers/HsTariffPopover";
import ItemPackageWeight from "../Modals/ItemPackageWeight";
import ChooseAESExemptionPopover from "../Popovers/ChooseAESExemptionPopover";
import AssistiveError from "../Subcomponents/AssistiveError";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";

class GXGCustomsInformation extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      commercialSender: false,
      inEditItem: false,
      isLoaded: false,
      isDesktop: true,
      requiresExport: false,
      showRestrictions: true, // In desktop view, preselect this
      showRestrictionsMobile: false, // In mobile view, don't preselect this
      showProhibitions: false,
      showProhibitionsMobile: false,
      showDimFields: false,
      packageType: "None",
      errors: {
        globalError: [],
        packageType: "",
        contentType: "",
        packageShape: "",

        length: "",
        width: "",
        height: "",
        girth: "",

        itemType: "",
        itemValue: "",
        quantity: "",
        weight: "",
        description: "",

        aes: "",
        hsTariffError: "",

        globalItemError: []
      },
      nonDelvOption: {},
      itemType: "",
      labelCustDec: {
        pfcCode: "",
        invoiceNbr: "",
        certificateNbr: "",
        licenseNbr: "",
        contentsDesc: "",
        comments: ""
      },
      labelGxg: {},
      labelItem: {
        itemDesc: "",
        labelItemId: "0",
        cnsCartId: String(
          this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.cnsCartId
            ? this.props.history.location.state.cnsCartId
            : 0
        )
      },
      labelItemsClickedMobile: [],
      labelPkg: {
        containerType: ""
      }
    };
  }

  editLabel = (e) => {
    e.preventDefault();
    this.props.setEditCartId(
      this.state.fetchedCartList.cnsCartList[0].cnsCartId
    );
    this.props.history.push("/labelInformation", {
      editItem: this.state.fetchedCartList.cnsCartList[0].cnsCartId
    });
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: errorMessage
        }
      });
    }
  };

  clearError = (fieldName) => {
    this.setState((prevState) => {
      return {
        errors: {
          ...prevState.errors,
          [fieldName]: fieldName === "globalItemError" ? [] : ""
        }
      };
    });
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  labelItemError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalItemError")(combinedErrors);
  };

  //SET AES<ADD EXPORTLICIENCE IND CODE
  componentDidMount() {
    this.props.toggleSpinner(true);
    // If there is no history, redirect the user to the create label page
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.cnsCartId
    ) {
      this.updateScreenSize();
      window.addEventListener("resize", this.updateScreenSize);
      axios
        .get(
          "/go/cnsrest/fetchCustomsInfo?fetchPrefQueryString=" +
            this.props.location.state.cnsCartId
        )
        .then((response) => {
          this.props.toggleSpinner(false);
          if (
            Object.keys(response.data.fieldErrors).length === 0 &&
            response.data.actionErrors.length === 0
          ) {
            this.setState({
              focusOnTop: true,
              isLoaded: true,
              fetchedCartList: response.data,
              fetchedLabelItemList: response.data.cnsCartList[0].labelItems,
              packageType: response.data.cnsCartList[0].labelPkg.containerType
                ? response.data.cnsCartList[0].labelPkg.containerType._name
                : "None",
              showDimFields: response.data.cnsCartList[0].labelPkg.containerType
                ._name
                ? response.data.cnsCartList[0].labelPkg.containerType._name ===
                    "None" ||
                  response.data.cnsCartList[0].labelPkg.containerType._name ===
                    "GxgEnvelopePack"
                  ? false
                  : true
                : false,
              taxIdNumber: response.data.cnsCartList[0].labelGxg.taxId
                ? response.data.cnsCartList[0].labelGxg.taxId
                : "",
              doxWpxType: response.data.cnsCartList[0].labelGxg.doxWpxType
                ? response.data.cnsCartList[0].labelGxg.doxWpxType
                : false,
              pkgHeightQty:
                response.data.cnsCartList[0].labelPkg.pkgHeightQty || "",
              pkgWidthQty:
                response.data.cnsCartList[0].labelPkg.pkgWidthQty || "",
              pkgLengthQty:
                response.data.cnsCartList[0].labelPkg.pkgLengthQty || "",
              pkgGirthQty:
                response.data.cnsCartList[0].labelPkg.pkgShape === "NR" &&
                response.data.cnsCartList[0].labelPkg.pkgGirthQty
                  ? response.data.cnsCartList[0].labelPkg.pkgGirthQty
                  : "",
              weightOzQty: response.data.cnsCartList[0].labelPkg.weightOzQty,
              pkgShape: response.data.cnsCartList[0].labelPkg.pkgShape
                ? response.data.cnsCartList[0].labelPkg.pkgShape
                : "",
              commercialSender: response.data.cnsCartList[0].labelItem
                ? response.data.cnsCartList[0].labelItem.hsTariffNbr
                  ? true
                  : false
                : false,
              labelPkg: {
                ...this.state.labelPkg,
                containerType: response.data.cnsCartList[0].labelCustDec
                  ? response.data.cnsCartList[0].labelCustDec.contents._name
                  : ""
              },

              labelCustDec: {
                ...this.state.labelCustDec,
                contents: response.data.cnsCartList[0].labelCustDec
                  ? response.data.cnsCartList[0].labelCustDec.contents._name
                  : "",
                contentsDesc: response.data.cnsCartList[0].labelCustDec
                  ? response.data.cnsCartList[0].labelCustDec.contentsDesc
                  : "",
                eelCode: response.data.cnsCartList[0].labelCustDec
                  ? response.data.cnsCartList[0].labelCustDec.eelCode.value
                  : "",
                comments: response.data.cnsCartList[0].labelCustDec
                  ? response.data.cnsCartList[0].labelCustDec.comments
                  : "",
                licenseNbr: response.data.cnsCartList[0].labelCustDec
                  ? response.data.cnsCartList[0].labelCustDec.licenseNbr
                  : "",
                certificateNbr: response.data.cnsCartList[0].labelCustDec
                  ? response.data.cnsCartList[0].labelCustDec.certificateNbr
                  : "",
                invoiceNbr: response.data.cnsCartList[0].labelCustDec
                  ? response.data.cnsCartList[0].labelCustDec.invoiceNbr
                  : "",
                pfcCode: response.data.cnsCartList[0].labelCustDec
                  ? response.data.cnsCartList[0].labelCustDec.pfcCode
                  : ""
              },
              labelInd: {
                ...this.state.labelInd,
                exportLicenseInd:
                  this.state.requiresExport === false ? "N" : "Y"
              },
              eelCodeOptions: response.data.eelCodeOptions,
              countryList: this.reorderCountryList(
                response.data.countryZonesViewBean
              )
            });
          } else {
            this.setGlobalError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.toggleSpinner(false);
        });
    } else {
      window.location.replace("/labelInformation");
    }
  }

  componentDidUpdate() {
    // Scroll to the first section that has an error, and
    // focus on the first field with an error
    if (this.state.focusOnError) {
      if (this.packageInfoErrorFound) {
        // Package Information
        document.getElementById("section-package-info").scrollIntoView();
        if (this.errors["packageType"]) {
          document.getElementById("package-type-dropdown-a11y").focus();
        } else if (this.errors["length"]) {
          document.getElementById("gxg-length-input-a11y").focus();
        } else if (this.errors["width"]) {
          document.getElementById("gxg-width-input-a11y").focus();
        } else if (this.errors["height"]) {
          document.getElementById("gxg-height-input-a11y").focus();
        } else if (this.errors["girth"]) {
          document.getElementById("gxg-girth-input-a11y").focus();
        } else if (this.errors["contentType"]) {
          document.getElementById("content-type-input-a11y").focus();
        } else if (this.errors["packageShape"]) {
          document.getElementById("package-shape-input-a11y").focus();
        }
      } else if (this.itemInfoErrorFound) {
        // Item Information
        document.getElementById("section-item-info").scrollIntoView();
        if (this.errors["itemType"]) {
          document.getElementById("item-type-dropdown-a11y").focus();
        } else if (this.errors["description"]) {
          document.getElementById("gxg-item-detailed-description-a11y").focus();
        } else if (this.errors["hsTariffError"]) {
          document.getElementById("gxg-hs-tariff-input-a11y").focus();
        } else if (this.errors["itemValue"]) {
          document.getElementById("gxg-item-value-a11y").focus();
        } else if (this.errors["quantity"]) {
          document.getElementById("gxg-item-quantity-a11y").focus();
        } else if (this.errors["weight"]) {
          document.getElementById("gxg-item-weight-pounds-a11y").focus();
        }
      } else if (this.exportInfoErrorFound) {
        // Export Information
        document.getElementById("section-export-info").scrollIntoView();
        if (this.errors["aes"]) {
          document.getElementById("gxg-eel-code-dropdown-a11y").focus();
        }
      }
      // Reset fields
      this.packageInfoErrorFound = false;
      this.itemInfoErrorFound = false;
      this.exportInfoErrorFound = false;
      this.setState({
        focusOnError: false
      });
    }
    // Focus on the top element on the page after loading
    if (this.state.focusOnTop && this.state.isLoaded) {
      document.getElementById("gxg-customs-info-top").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
    // Scroll to the top of the item information section, and
    // focus on the first field with an error
    if (this.state.focusOnLabelItemError) {
      document.getElementById("section-item-info").scrollIntoView();
      if (this.errors["itemType"]) {
        document.getElementById("item-type-dropdown-a11y").focus();
      } else if (this.errors["description"]) {
        document.getElementById("gxg-item-detailed-description-a11y").focus();
      } else if (this.errors["hsTariffError"]) {
        document.getElementById("gxg-hs-tariff-input-a11y").focus();
      } else if (this.errors["itemValue"]) {
        document.getElementById("gxg-item-value-a11y").focus();
      } else if (this.errors["quantity"]) {
        document.getElementById("gxg-item-quantity-a11y").focus();
      } else if (this.errors["weight"]) {
        document.getElementById("gxg-item-weight-pounds-a11y").focus();
      }
      this.setState({
        focusOnLabelItemError: false
      });
    }
    // Scroll to and focus on the input fields after choosing to edit an item
    if (this.state.focusOnItemEdit) {
      document.getElementById("section-item-info").scrollIntoView();
      document.getElementById("section-item-info-inputs").focus();
      this.setState({
        focusOnItemEdit: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  }

  updateScreenSize = () => {
    this.setState({
      isDesktop: window.innerWidth > 767
    });
  };

  refreshItems = (e) => {
    axios
      .get(
        "/go/cnsrest/fetchCustomsInfo?fetchPrefQueryString=" +
          this.props.location.state.cnsCartId
      )
      .then((response) => {
        this.setState((prevState) => {
          return {
            isLoaded: true,
            //fetchedCartList: response.data,
            fetchedLabelItemList: response.data.cnsCartList[0].labelItems,
            itemType: "",
            labelItem: {
              ...prevState.labelItem,
              itemDesc: "",
              itemQty: "",
              labelItemId: "0",
              unitValue: "",
              unitWeightOz: "",
              hsTariffNbr: "",
              countryId: {
                ...prevState.labelItem.countryId,
                countryDbId: "0"
              }
            },
            setWeightInOz: "",
            weightInPounds: "",
            inEditItem: false
          };
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.props.toggleSpinner(false);
      });
  };

  // Add "Select" as an option and move the United States to the top of the list
  reorderCountryList = (countryList) => {
    let unitedStates = [];
    unitedStates.push({
      countryDbId: 0,
      countryName: "Select"
    });
    let unitedStatesIndex = -1;
    for (let i in countryList) {
      if (countryList[i].countryDbId === 840) {
        unitedStates.push(countryList[i]);
        unitedStatesIndex = i;
        break;
      }
    }
    countryList.splice(unitedStatesIndex, 1);
    countryList = unitedStates.concat(countryList);
    return countryList;
  };

  validateSave = (e) => {
    let errorsFound = false;
    let errorObject = {};
    // Package Information
    if (this.state.packageType === "None") {
      errorObject["packageType"] = "Please select a package type.";
      errorsFound = true;
      this.packageInfoErrorFound = true;
    }
    if (
      this.state.packageType !== "None" &&
      this.state.packageType !== "GxgEnvelopePack"
    ) {
      if (!this.state.pkgHeightQty) {
        errorObject["height"] = "Please enter a height.";
        errorsFound = true;
        this.packageInfoErrorFound = true;
      }
      if (!this.state.pkgLengthQty) {
        errorObject["length"] = "Please enter a length.";
        errorsFound = true;
        this.packageInfoErrorFound = true;
      }
      if (!this.state.pkgWidthQty) {
        errorObject["width"] = "Please enter a width.";
        errorsFound = true;
        this.packageInfoErrorFound = true;
      }
      if (this.state.pkgShape === "NR" && !this.state.pkgGirthQty) {
        errorObject["girth"] = "Please enter a girth.";
        errorsFound = true;
        this.packageInfoErrorFound = true;
      }
    }
    if (!this.state.doxWpxType) {
      errorObject["contentType"] = "Please select a content type.";
      errorsFound = true;
      this.packageInfoErrorFound = true;
    }
    if (!this.state.pkgShape) {
      errorObject["packageShape"] = "Please select a package shape.";
      errorsFound = true;
      this.packageInfoErrorFound = true;
    }
    // Item Information
    if (this.state.fetchedLabelItemList.length === 0) {
      if (!this.state.itemType) {
        errorObject["itemType"] = "Please select an item type.";
        errorsFound = true;
        this.itemInfoErrorFound = true;
      }
      if (!this.state.labelItem.itemDesc) {
        errorObject["description"] = "Please enter an item description.";
        errorsFound = true;
        this.itemInfoErrorFound = true;
      }
      if (
        !this.state.labelItem.unitValue ||
        Number(this.state.labelItem.unitValue) < 0
      ) {
        errorObject["itemValue"] = "Please enter item value.";
        errorsFound = true;
        this.itemInfoErrorFound = true;
      }
      if (
        !this.state.labelItem.itemQty ||
        Number(this.state.labelItem.itemQty) < 0
      ) {
        errorObject["quantity"] = "Please enter quantity.";
        errorsFound = true;
        this.itemInfoErrorFound = true;
      }
      if (
        (!this.state.setWeightInOz && !this.state.weightInPounds) ||
        (Number(this.state.setWeightInOz) < 0 &&
          Number(this.state.weightInPounds) < 0)
      ) {
        errorObject["weight"] = "Please enter package weight.";
        errorsFound = true;
        this.itemInfoErrorFound = true;
      }
    }
    // Export Information
    if (!this.state.labelCustDec.eelCode) {
      errorObject["aes"] = "Please select an AES exemption.";
      errorsFound = true;
      this.exportInfoErrorFound = true;
    }

    if (
      this.state.labelItem.hsTariffNbr &&
      this.state.commercialSender === true &&
      this.state.labelItem.hsTariffNbr.length !== 6
    ) {
      errorObject["hsTariffError"] =
        "HS Tariff Number must be exactly 6 characters long";
      errorsFound = true;
      this.itemInfoErrorFound = true;
    }

    this.errors = errorObject;
    this.setInputErrors("errorObject")(errorObject);

    return errorsFound;
  };

  saveCustomsInfo = (e) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    if (!this.validateSave()) {
      var dataToSend = {};
      let cnsCartList = [{}];
      cnsCartList[0].labelPkg = this.state.labelPkg;
      cnsCartList[0].labelCustDec = this.state.labelCustDec;
      cnsCartList[0].labelInd = this.state.labelInd;
      cnsCartList[0].labelGxg = this.state.labelGxg;
      cnsCartList[0].labelGxg["doxWpxType"] = this.state.doxWpxType;
      cnsCartList[0].labelGxg["taxId"] = this.state.taxIdNumber;
      cnsCartList[0].labelPkg["pkgLengthQty"] =
        this.state.packageType &&
        this.state.packageType !== "None" &&
        this.state.packageType !== "GxgEnvelopePack"
          ? this.state.pkgLengthQty
          : "";
      cnsCartList[0].labelPkg["pkgWidthQty"] =
        this.state.packageType &&
        this.state.packageType !== "None" &&
        this.state.packageType !== "GxgEnvelopePack"
          ? this.state.pkgWidthQty
          : "";
      cnsCartList[0].labelPkg["pkgHeightQty"] =
        this.state.packageType &&
        this.state.packageType !== "None" &&
        this.state.packageType !== "GxgEnvelopePack"
          ? this.state.pkgHeightQty
          : "";
      cnsCartList[0].labelPkg["pkgGirthQty"] =
        this.state.packageType &&
        this.state.packageType !== "None" &&
        this.state.packageType !== "GxgEnvelopePack"
          ? this.state.pkgGirthQty
          : "";
      cnsCartList[0].labelPkg["containerType"] = this.state.packageType;
      cnsCartList[0].labelPkg["pkgShape"] = this.state.pkgShape;

      dataToSend.labelItem = this.state.labelItem;
      dataToSend.labelItem["unitWeightOz"] =
        Number(
          this.state.weightInPounds === undefined
            ? 0
            : this.state.weightInPounds
        ) *
          16 +
        Number(
          this.state.setWeightInOz === undefined ? 0 : this.state.setWeightInOz
        );
      dataToSend.labelItem["itemType"] = this.state.itemType;
      dataToSend.cnsCartList = cnsCartList;
      axios
        .post(
          "/go/cnsrest/saveCustomsInfo?cnsCartId=" +
            this.props.location.state.cnsCartId +
            "&labelItemId=" +
            String(this.state.labelItem.labelItemId),
          JSON.stringify(dataToSend),
          {
            headers: { "Content-Type": "application/json" }
          }
        )
        .then((response) => {
          if (
            !response.data.fieldErrors ||
            (Object.keys(response.data.fieldErrors).length === 0 &&
              (!response.data.actionErrors ||
                response.data.actionErrors.length === 0))
          ) {
            this.props.history.push("/gxgCommerceInformation", {
              cnsCartId: this.props.location.state.cnsCartId
            });
          } else {
            window.scrollTo(0, 0);
            this.setGlobalError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
            this.props.toggleSpinner(false);
          }
        })
        .catch((error) => {
          console.log(error);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
          this.props.toggleSpinner(false);
        });
    } else {
      this.props.toggleSpinner(false);
      this.setState({
        focusOnError: true
      });
    }
  };

  validateLabelItem = (e) => {
    let errorsFound = false;
    let errorObject = {};

    if (!this.state.itemType) {
      errorObject["itemType"] = "Please select an item type.";
      errorsFound = true;
    }
    if (!this.state.labelItem.itemDesc) {
      errorObject["description"] = "Please enter an item description.";
      errorsFound = true;
    }
    if (
      !this.state.labelItem.unitValue ||
      Number(this.state.labelItem.unitValue) < 0
    ) {
      errorObject["itemValue"] = "Please enter item value.";
      errorsFound = true;
    }
    if (
      !this.state.labelItem.itemQty ||
      Number(this.state.labelItem.itemQty) < 0
    ) {
      errorObject["quantity"] = "Please enter quantity.";
      errorsFound = true;
    }
    if (
      (!this.state.setWeightInOz && !this.state.weightInPounds) ||
      (Number(this.state.setWeightInOz) < 0 &&
        Number(this.state.weightInPounds) < 0)
    ) {
      errorObject["weight"] = "Please enter package weight.";
      errorsFound = true;
    }

    if (
      this.state.labelItem.hsTariffNbr &&
      this.state.commercialSender === true &&
      this.state.labelItem.hsTariffNbr.length !== 6
    ) {
      errorObject["hsTariffError"] =
        "HS Tariff Number must be exactly 6 characters long";
      errorsFound = true;
    }

    this.errors = errorObject;
    this.setInputErrors("errorObject")(errorObject);

    return errorsFound;
  };

  addLabelItem = (e) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    // Validate label item fields
    if (!this.validateLabelItem()) {
      // Clear existing label item errors
      if (this.state.errors.globalItemError.length > 0) {
        this.clearError("globalItemError");
      }
      // Modify label item fields before saving
      let labelItem = {};
      labelItem["labelItem"] = this.state.labelItem;
      labelItem["labelItem"]["unitWeightOz"] =
        Number(
          this.state.weightInPounds === undefined
            ? 0
            : this.state.weightInPounds
        ) *
          16 +
        Number(
          this.state.setWeightInOz === undefined ? 0 : this.state.setWeightInOz
        );
      labelItem["labelItem"]["labelItemId"] = String(
        this.state.labelItem.labelItemId || ""
      );
      labelItem["labelItem"]["unitValue"] = String(
        this.state.labelItem.unitValue || ""
      );
      labelItem["labelItem"]["itemType"] = this.state.itemType;
      // Save the label item
      axios
        .post(
          "/go/cnsrest/saveLabelItem?cnsCartId=" +
            this.props.location.state.cnsCartId +
            "&labelItemId=" +
            String(this.state.labelItem.labelItemId),
          JSON.stringify(labelItem),
          {
            headers: { "Content-Type": "application/json" }
          }
        )
        .then((response) => {
          if (
            (response.data.fieldErrors === undefined ||
              Object.keys(response.data.fieldErrors).length === 0) &&
            (response.data.actionErrors === undefined ||
              response.data.actionErrors.length === 0)
          ) {
            this.refreshItems();
          } else {
            this.props.toggleSpinner(false);

            this.labelItemError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.toggleSpinner(false);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        });
    } else {
      this.props.toggleSpinner(false);
      this.setState({
        focusOnLabelItemError: true
      });
    }
  };

  cancelAddLabelItem = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        inEditItem: false,
        itemType: "",
        labelItem: {
          ...prevState.labelItem,
          itemDesc: "",
          itemQty: "",
          labelItemId: "0",
          unitValue: "",
          unitWeightOz: "",
          hsTariffNbr: "",
          countryId: {
            ...prevState.labelItem.countryId,
            countryDbId: "0"
          }
        },
        setWeightInOz: "",
        weightInPounds: ""
      };
    });
  };

  changeItemInfo = (input) => (event) => {
    if (input === "countryId") {
      this.setState({
        labelItem: {
          ...this.state.labelItem,
          countryId: {
            ...this.state.labelItem.countryId,

            countryDbId: event
          }
        }
      });
    } else {
      this.setState({
        labelItem: {
          ...this.state.labelItem,
          [input]: event
        }
      });
    }
  };

  changeLabelCustDec = (input) => (event) => {
    if (input === "contents") {
      this.setState({
        labelCustDec: {
          ...this.state.labelCustDec,
          [input]: event
        },
        labelPkg: {
          ...this.state.labelPkg,
          containerType: event
        }
      });
    } else {
      this.setState({
        labelCustDec: {
          ...this.state.labelCustDec,
          [input]: event
        }
      });
    }
  };

  setWeightInPounds = (e) => {
    this.setState({ weightInPounds: e.target.value });
  };

  setWeightInOz = (e) => {
    this.setState({ setWeightInOz: e.target.value });
  };

  //this whole weight mess needs to get cleaned up
  getPoundsAndOz = (e, totalWeightInOz, quantity) => {
    if (quantity !== undefined) {
      totalWeightInOz = totalWeightInOz * quantity;
    }
    let pounds;
    let ounces;
    if (totalWeightInOz >= 16) {
      pounds = Math.floor(totalWeightInOz / 16);

      ounces = totalWeightInOz - pounds * 16;
    } else {
      pounds = 0;
      ounces = totalWeightInOz - pounds * 16;
    }
    return pounds + "lbs " + ounces + "oz";
  };

  getOunces = (totalWeightInOz) => {
    let pounds;
    let ounces;
    if (totalWeightInOz >= 16) {
      pounds = Math.floor(totalWeightInOz / 16);

      ounces = totalWeightInOz - pounds * 16;
    } else {
      pounds = 0;
      ounces = totalWeightInOz - pounds * 16;
    }
    if (ounces === 0) {
      return "";
    } else if (ounces === "NaN") {
      return "";
    } else {
      return "";
    }
  };

  getPounds = (totalWeightInOz) => {
    let pounds;
    if (totalWeightInOz >= 16) {
      pounds = Math.floor(totalWeightInOz / 16);
    } else {
      pounds = 0;
    }
    if (pounds === 0) {
      return "";
    } else {
      return pounds;
    }
  };

  setEditWeight = (totalWeightInOz) => {
    let pounds;
    let ounces;
    if (totalWeightInOz >= 16) {
      pounds = Math.floor(totalWeightInOz / 16);

      ounces = totalWeightInOz - pounds * 16;
    } else {
      pounds = 0;
      ounces = totalWeightInOz - pounds * 16;
    }
    this.setState({
      setWeightInOz: ounces === isNaN ? "0" : ounces,
      weightInPounds: pounds === isNaN ? "0" : pounds,
      unitWeightOz: totalWeightInOz.totalWeightInOz
    });
  };

  editLabelItem = (e, item) => {
    e.preventDefault();
    // Clear any existing errors for the item fields
    this.clearError("itemType");
    this.clearError("description");
    this.clearError("itemValue");
    this.clearError("quantity");
    this.clearError("weight");
    // Update the item fields
    this.setState((prevState) => {
      return {
        inEditItem: true,
        focusOnItemEdit: true,
        commercialSender: item.hsTariffNbr || item.countryId ? true : false,
        itemType: item.itemType,
        labelItem: {
          ...prevState.labelItem,
          itemDesc: item.itemDesc,
          itemQty: item.itemQty,
          labelItemId: item.labelItemId,
          unitValue: item.unitValue,
          unitWeightOz: this.setEditWeight(item.unitWeightOz),
          hsTariffNbr: item.hsTariffNbr ? item.hsTariffNbr : "",
          countryId: {
            ...prevState.labelItem.countryId,
            countryDbId: item.countryId ? item.countryId.countryDbId : "0"
          }
        }
      };
    });
  };

  deleteItem = (item) => {
    this.props.toggleSpinner(true);
    if (this.state.errors.globalItemError.length > 0) {
      this.clearError("globalItemError");
    }
    axios
      .post(
        "/go/cnsrest/deleteLabelItem?cnsCartId=" +
          this.props.location.state.cnsCartId +
          "&labelItemId=" +
          item.labelItemId,
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then((response) => {
        if (
          (response.data.fieldErrors === undefined ||
            Object.keys(response.data.fieldErrors).length === 0) &&
          (response.data.actionErrors === undefined ||
            response.data.actionErrors.length === 0)
        ) {
          this.refreshItems();
        } else {
          this.labelItemError(
            response.data.fieldErrors,
            response.data.actionErrors
          );
          this.props.toggleSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  deleteAllItems = () => {
    this.props.toggleSpinner(true);
    if (this.state.errors.globalItemError.length > 0) {
      this.clearError("globalItemError");
    }
    axios
      .post(
        "/go/cnsrest/deleteLabelItem?cnsCartId=" +
          this.props.location.state.cnsCartId +
          "&labelItemId=" +
          this.getLabelItemIdList(),
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then((response) => {
        if (
          (response.data.fieldErrors === undefined ||
            Object.keys(response.data.fieldErrors).length === 0) &&
          (response.data.actionErrors === undefined ||
            response.data.actionErrors.length === 0)
        ) {
          this.refreshItems();
        } else {
          this.labelItemError(
            response.data.fieldErrors,
            response.data.actionErrors
          );
          this.props.toggleSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  // Add all label item IDs to a comma-separated string
  getLabelItemIdList = () => {
    let labelItemIdList = "";
    labelItemIdList += this.state.fetchedLabelItemList[0].labelItemId;
    for (let i = 1; i < this.state.fetchedLabelItemList.length; i++) {
      labelItemIdList += "," + this.state.fetchedLabelItemList[i].labelItemId;
    }
    return labelItemIdList;
  };

  getTotalPackageWeight = (e) => {
    let allItemWeight = this.state.fetchedLabelItemList.reduce(function (
      total,
      arr
    ) {
      return total + arr.unitWeightOz * arr.itemQty;
    },
    0);

    return this.getPoundsAndOz(1, allItemWeight);
  };

  getTotalPackageValue = (e) => {
    let allItemValue = this.state.fetchedLabelItemList.reduce(function (
      total,
      arr
    ) {
      return total + arr.unitValue * arr.itemQty;
    },
    0);

    return allItemValue.toFixed(2);
  };

  showRestrictions = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        showRestrictions: true,
        showRestrictionsMobile: prevState.isDesktop
          ? true
          : !prevState.showRestrictionsMobile,
        showProhibitions: false,
        showProhibitionsMobile: false
      };
    });
  };

  showProhibitions = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        showRestrictions: false,
        showRestrictionsMobile: false,
        showProhibitions: true,
        showProhibitionsMobile: prevState.isDesktop
          ? true
          : !prevState.showProhibitionsMobile
      };
    });
  };

  setPackageType = (e) => {
    let showDimFields;
    const value = e.target.value;
    if (value === "GxgEnvelopePack" || value === "None") {
      showDimFields = false;
    } else {
      showDimFields = true;
    }
    this.setState({
      packageType: value,
      showDimFields: showDimFields
    });
  };

  setCommercialSender = (e) => {
    const checked = e.currentTarget.checked;
    if (!checked) {
      // Reset the HS tariff number and country ID when the user is not
      // a commercial sender, and uncheck the box
      this.setState((prevState) => {
        return {
          labelItem: {
            ...prevState.labelItem,
            hsTariffNbr: "",
            countryId: {
              ...prevState.labelItem.countryId,
              countryDbId: "0"
            }
          },
          commercialSender: checked
        };
      });
    } else {
      // Check the box
      this.setState({
        commercialSender: checked
      });
    }
  };

  setLabelItemsClickedMobile = (labelItemId) => {
    this.setState((prevState) => {
      let newArray = [...prevState.labelItemsClickedMobile];
      if (!newArray.includes(labelItemId)) {
        // Expand this item (add it to the array of items that were clicked)
        // since it was previously collapsed
        newArray.push(labelItemId);
      } else {
        // Collapse this item (remove it from the array of items that were
        // clicked) since it was previously expanded
        const indexToRemove = newArray.indexOf(labelItemId);
        newArray.splice(indexToRemove, 1);
      }
      return {
        labelItemsClickedMobile: newArray
      };
    });
  };

  render() {
    return (
      <React.Fragment>
        <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        />
        {this.state.isLoaded ? (
          <div
            className="Step_Eight_Container"
            id="gxg-customs-info-top"
            tabIndex="-1"
          >
            <div className="container-fluid">
              <h2 className="d-md-none responsive-header">Create a Label</h2>
              <p>*indicates a required field</p>

              <h2 className="normal step-eight-header">
                Step 8: <strong>Customs Information</strong>
              </h2>
              <div className="row" id="section-package-info">
                <div className="col-12">
                  <label className="customs-info-label">
                    Package Information
                  </label>
                  <p>
                    Provide as much information as possible and any relevant
                    information about the contents of your shipment.
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className={
                    "col-12 col-md-6 col-lg-4 form-group required-field" +
                    (this.state.errors.packageType !== "" ? " error" : "")
                  }
                >
                  <AssistiveError
                    inputId="package-type-dropdown"
                    errorMessage={this.state.errors.packageType}
                  />
                  <label htmlFor="package-type-dropdown">*Package Type</label>
                  <select
                    id="package-type-dropdown"
                    className="form-control dropdown"
                    onChange={(e) => {
                      this.setPackageType(e);
                      this.state.errors.packageType !== ""
                        ? this.clearError("packageType")
                        : this.clearError("");
                    }}
                    value={this.state.packageType}
                  >
                    <option value="None">Select</option>
                    <option value="GxgEnvelopePack">GXG Envelope or Pak</option>
                    <option value="CustomerBox">Customer Supplied Box</option>
                    <option value="CustomerEnvelope">
                      Customer Supplied Envelope
                    </option>
                    <option value="Package">Package</option>
                    <option value="Tube">Tube</option>
                  </select>
                  <span className="error-message">
                    {this.state.errors.packageType}
                  </span>
                </div>
              </div>
              {this.state.showDimFields ? (
                <div
                  className="row package-dimensions-input"
                  style={{ padding: "0px" }}
                >
                  <div
                    className={
                      "col-12 col-md-3 col-lg-2 package-length-input form-group required-field" +
                      (this.state.errors.length !== "" ? " error" : "")
                    }
                  >
                    <AssistiveError
                      inputId="gxg-length-input"
                      errorMessage={this.state.errors.length}
                    />
                    <label htmlFor="gxg-length-input">*Length</label>
                    <div className="input-group">
                      <input
                        id="gxg-length-input"
                        className="form-control append"
                        type="text"
                        placeholder="0"
                        aria-label=""
                        aria-describedby=""
                        onChange={(e) => {
                          this.setState({
                            pkgLengthQty: e.target.value
                          });
                          this.state.errors.length !== ""
                            ? this.clearError("length")
                            : this.clearError("");
                        }}
                        value={this.state.pkgLengthQty}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">in</span>
                      </div>
                    </div>
                    <span className="error-message">
                      {this.state.errors.length}
                    </span>
                  </div>
                  <div
                    className={
                      "col-12 col-md-3 col-lg-2 package-width-input form-group required-field" +
                      (this.state.errors.width !== "" ? " error" : "")
                    }
                  >
                    <AssistiveError
                      inputId="gxg-width-input"
                      errorMessage={this.state.errors.width}
                    />
                    <label htmlFor="gxg-width-input">*Width</label>
                    <div className="input-group">
                      <input
                        id="gxg-width-input"
                        className="form-control append"
                        type="text"
                        placeholder="0"
                        aria-label=""
                        aria-describedby=""
                        onChange={(e) => {
                          this.setState({
                            pkgWidthQty: e.target.value
                          });
                          this.state.errors.width !== ""
                            ? this.clearError("width")
                            : this.clearError("");
                        }}
                        value={this.state.pkgWidthQty}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">in</span>
                      </div>
                    </div>
                    <span className="error-message">
                      {this.state.errors.width}
                    </span>
                  </div>
                  <div
                    className={
                      "col-12 col-md-3 col-lg-2 package-height-input form-group required-field" +
                      (this.state.errors.height !== "" ? " error" : "")
                    }
                  >
                    <AssistiveError
                      inputId="gxg-height-input"
                      errorMessage={this.state.errors.height}
                    />
                    <label htmlFor="gxg-height-input">*Height</label>
                    <div className="input-group">
                      <input
                        id="gxg-height-input"
                        className="form-control append"
                        type="text"
                        placeholder="0"
                        aria-label=""
                        aria-describedby=""
                        onChange={(e) => {
                          this.setState({
                            pkgHeightQty: e.target.value
                          });
                          this.state.errors.height !== ""
                            ? this.clearError("height")
                            : this.clearError("");
                        }}
                        value={this.state.pkgHeightQty}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">in</span>
                      </div>
                    </div>
                    <span className="error-message">
                      {this.state.errors.height}
                    </span>
                  </div>
                  {this.state.pkgShape === "NR" ? (
                    <div
                      className={
                        "col-12 col-md-3 col-lg-2 package-girth-input form-group required-field" +
                        (this.state.errors.girth !== "" ? " error" : "")
                      }
                    >
                      <AssistiveError
                        inputId="gxg-girth-input"
                        errorMessage={this.state.errors.girth}
                      />
                      <label htmlFor="gxg-girth-input">*Girth</label>
                      <div className="input-group">
                        <input
                          id="gxg-girth-input"
                          className="form-control append"
                          type="text"
                          placeholder="0"
                          aria-label=""
                          aria-describedby=""
                          onChange={(e) => {
                            this.setState({
                              pkgGirthQty: e.target.value
                            });
                            this.state.errors.girth !== ""
                              ? this.clearError("girth")
                              : this.clearError("");
                          }}
                          value={this.state.pkgGirthQty}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">in</span>
                        </div>
                      </div>
                      <span className="error-message">
                        {this.state.errors.girth}
                      </span>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              ) : (
                <React.Fragment />
              )}
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 extend form-group required-field">
                  <label htmlFor="tax-id-input">
                    Tax ID Number <TaxIdPopover targetName="taxIdInfoIcon" />
                  </label>
                  <input
                    tabIndex="0"
                    type="text"
                    id="tax-id-input"
                    className="form-control"
                    onChange={(e) =>
                      this.setState({
                        taxIdNumber: e.target.value
                      })
                    }
                    value={this.state.taxIdNumber}
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className={
                    "col-12 radio-buttons required-field" +
                    (this.state.errors.contentType !== "" ? " error" : "")
                  }
                >
                  <AssistiveError
                    inputId="content-type-input"
                    errorMessage={this.state.errors.contentType}
                  />
                  <label className="customs-info-radio-label">
                    <strong>*Content Type</strong>
                  </label>
                  <div className="radio-wrap customs-info-radio-wrap">
                    <div className="radio-container">
                      <input
                        id="document-radio"
                        type="radio"
                        className="radio-button document"
                        name="radio-btn-content-type"
                        onChange={(e) => {
                          this.setState({
                            doxWpxType: "D"
                          });
                          this.state.errors.contentType !== ""
                            ? this.clearError("contentType")
                            : this.clearError("");
                        }}
                        checked={this.state.doxWpxType === "D" ? true : false}
                      />
                      <label htmlFor="document-radio">Document</label>
                    </div>
                    <div className="radio-container">
                      <input
                        id="no-doc-radio"
                        type="radio"
                        className="radio-button no-doc"
                        name="radio-btn-content-type"
                        onChange={(e) => {
                          this.setState({
                            doxWpxType: "W"
                          });
                          this.state.errors.contentType !== ""
                            ? this.clearError("contentType")
                            : this.clearError("");
                        }}
                        checked={this.state.doxWpxType === "W" ? true : false}
                      />
                      <label htmlFor="no-doc-radio">Non-Document</label>
                    </div>
                    <span className="error-message">
                      {this.state.errors.contentType}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className={
                    "col-12 radio-buttons required-field" +
                    (this.state.errors.packageShape !== "" ? " error" : "")
                  }
                >
                  <AssistiveError
                    inputId="package-shape-input"
                    errorMessage={this.state.errors.packageShape}
                  />
                  <label className="customs-info-radio-label">
                    <strong>*Package Shape</strong>
                  </label>
                  <div className="radio-wrap customs-info-radio-wrap">
                    <div className="radio-container">
                      <input
                        id="rectangular-radio"
                        type="radio"
                        className="radio-button rectangular"
                        name="radio-btn-package-shape"
                        onChange={(e) => {
                          this.setState({
                            pkgShape: "R",
                            pkgGirthQty: ""
                          });
                          this.state.errors.packageShape !== ""
                            ? this.clearError("packageShape")
                            : this.clearError("");
                        }}
                        checked={this.state.pkgShape === "R" ? true : false}
                      />
                      <label htmlFor="rectangular-radio">Rectangular</label>
                    </div>
                    <div className="radio-container">
                      <input
                        id="no-rec-radio"
                        type="radio"
                        className="radio-button no-rec"
                        name="radio-btn-package-shape"
                        onChange={(e) => {
                          this.setState({
                            pkgShape: "NR"
                          });
                          this.state.errors.packageShape !== ""
                            ? this.clearError("packageShape")
                            : this.clearError("");
                        }}
                        checked={this.state.pkgShape === "NR" ? true : false}
                      />
                      <label htmlFor="no-rec-radio">Non-Rectangular</label>
                    </div>
                    <span className="error-message">
                      {this.state.errors.packageShape}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-10 commercial-sender-cb-wrapper">
                  <label
                    className="checkbox-component commercial-sender-confirmation"
                    htmlFor="commercial-sender-cb"
                  >
                    <input
                      type="checkbox"
                      id="commercial-sender-cb"
                      onChange={this.setCommercialSender}
                      checked={this.state.commercialSender}
                    />
                    <span className="checkbox" name="other-post-office-check" />
                    I am a commercial sender
                  </label>
                  <p className="commercial-sender-note">
                    <strong className="secondary-bold">NOTE:</strong> Select
                    this checkbox if your package contents require an HS Tariff,
                    certificate, license, or invoice number.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 horizontal-line-container">
                  <hr className="horizontal-line" />
                </div>
              </div>
              <div className="row" id="section-item-info">
                <div className="col-12">
                  <label className="customs-info-label">
                    <strong>Item Information</strong>
                  </label>
                  <p>
                    Provide information about item(s) you are shipping. You must
                    add at least 1 item and up to 30 items.
                  </p>
                </div>
              </div>

              <div id="section-item-info-inputs" className="row" tabIndex="-1">
                <div className="col-12 col-lg-10">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div
                        className={
                          "form-group required-field" +
                          (this.state.errors.itemType !== "" ? " error" : "")
                        }
                      >
                        <AssistiveError
                          inputId="item-type-dropdown"
                          errorMessage={this.state.errors.itemType}
                        />
                        <label htmlFor="item-type-dropdown">*Item Type</label>
                        <div className="dropdown-selection">
                          <select
                            id="item-type-dropdown"
                            className="form-control dropdown"
                            onChange={(e) => {
                              this.setState({
                                itemType: e.target.value
                              });
                              this.state.errors.itemType !== ""
                                ? this.clearError("itemType")
                                : this.clearError("");
                            }}
                            value={this.state.itemType}
                          >
                            <option value="">Select</option>
                            <option value="ATM Cards">ATM Cards</option>
                            <option value="Acids">Acids</option>
                            <option value="Advertising">Advertising</option>
                            <option value="Aerosol Hairspray">
                              Aerosol Hairspray
                            </option>
                            <option value="Airline Ticket Blank">
                              Airline Ticket Blank
                            </option>
                            <option value="Airline Ticket Issue">
                              Airline Ticket Issue
                            </option>
                            <option value="Airline Ticket, Valid">
                              Airline Ticket, Valid
                            </option>
                            <option value="Alcohol, Non-Hazardous">
                              Alcohol, Non-Hazardous
                            </option>
                            <option value="Alkaline Batteries">
                              Alkaline Batteries
                            </option>
                            <option value="Aluminum">Aluminum</option>
                            <option value="Ammunition">Ammunition</option>
                            <option value="Animal Parts, Non-Hazardous">
                              Animal Parts, Non-Hazardous
                            </option>
                            <option value="Animals, Dead">Animals, Dead</option>
                            <option value="Animals, Live">Animals, Live</option>
                            <option value="Annual Reports">
                              Annual Reports
                            </option>
                            <option value="Antiques">Antiques</option>
                            <option value="Antitoxins, Non-Hazardous">
                              Antitoxins, Non-Hazardous
                            </option>
                            <option value="Apparel, Textile">
                              Apparel, Textile
                            </option>
                            <option value="Appliances">Appliances</option>
                            <option value="Architectural Plans">
                              Architectural Plans
                            </option>
                            <option value="Artwork, Commercial">
                              Artwork, Commercial
                            </option>
                            <option value="Artwork, Fine">Artwork, Fine</option>
                            <option value="Atlas">Atlas</option>
                            <option value="Audio Tapes">Audio Tapes</option>
                            <option value="Bacteria">Bacteria</option>
                            <option value="Baggage, Personal">
                              Baggage, Personal
                            </option>
                            <option value="Ball Bearings">Ball Bearings</option>
                            <option value="Bandages">Bandages</option>
                            <option value="Batteries, Hazardous">
                              Batteries, Hazardous
                            </option>
                            <option value="Batteries, Non-Hazardous">
                              Batteries, Non-Hazardous
                            </option>
                            <option value="Battery Fluid">Battery Fluid</option>
                            <option value="Bearer Documents">
                              Bearer Documents
                            </option>
                            <option value="Beepers">Beepers</option>
                            <option value="Beer, Non-Hazardous">
                              Beer, Non-Hazardous
                            </option>
                            <option value="Billing Statements">
                              Billing Statements
                            </option>
                            <option value="Binders">Binders</option>
                            <option value="Bio Products, Hazardous">
                              Bio Products, Hazardous
                            </option>
                            <option value="Bio Products, Non-Hazardous">
                              Bio Products, Non-Hazardous
                            </option>
                            <option value="Bird Feathers">Bird Feathers</option>
                            <option value="Birth Certificates">
                              Birth Certificates
                            </option>
                            <option value="Blank Forms">Blank Forms</option>
                            <option value="Blood, Animal, Non-Hazardous">
                              Blood, Animal, Non-Hazardous
                            </option>
                            <option value="Blood, Human, Non-Hazardous">
                              Blood, Human, Non-Hazardous
                            </option>
                            <option value="Blue Ice">Blue Ice</option>
                            <option value="Blueprints">Blueprints</option>
                            <option value="Bonds, Endorsed">
                              Bonds, Endorsed
                            </option>
                            <option value="Books">Books</option>
                            <option value="Brochures">Brochures</option>
                            <option value="Business Cards">
                              Business Cards
                            </option>
                            <option value="Business Checks">
                              Business Checks
                            </option>
                            <option value="Business Documents">
                              Business Documents
                            </option>
                            <option value="Buttons">Buttons</option>
                            <option value="CD">CD</option>
                            <option value="CD Players">CD Players</option>
                            <option value="CD/CD-RW Drives">
                              CD/CD-RW Drives
                            </option>
                            <option value="Calendars">Calendars</option>
                            <option value="Camcorder Batteries">
                              Camcorder Batteries
                            </option>
                            <option value="Camera">Camera</option>
                            <option value="Car Batteries">Car Batteries</option>
                            <option value="Cards, Debit">Cards, Debit</option>
                            <option value="Cards, Phone">Cards, Phone</option>
                            <option value="Cards, Smart">Cards, Smart</option>
                            <option value="Carpets">Carpets</option>
                            <option value="Cartridge, Ink">
                              Cartridge, Ink
                            </option>
                            <option value="Cash">Cash</option>
                            <option value="Catalogs">Catalogs</option>
                            <option value="Cell Phone Batteries">
                              Cell Phone Batteries
                            </option>
                            <option value="Cell Phones">Cell Phones</option>
                            <option value="Checks Blank Stock">
                              Checks Blank Stock
                            </option>
                            <option value="Checks Personal">
                              Checks Personal
                            </option>
                            <option value="Checks, Cancelled">
                              Checks, Cancelled
                            </option>
                            <option value="Checks, Cashier">
                              Checks, Cashier
                            </option>
                            <option value="Checks, Endorsed">
                              Checks, Endorsed
                            </option>
                            <option value="Checks, Payroll">
                              Checks, Payroll
                            </option>
                            <option value="Checks, Travellers">
                              Checks, Travellers
                            </option>
                            <option value="Chemicals, Hazardous">
                              Chemicals, Hazardous
                            </option>
                            <option value="Chemicals, Non-Hazardous">
                              Chemicals, Non-Hazardous
                            </option>
                            <option value="Cheques, Endorsed">
                              Cheques, Endorsed
                            </option>
                            <option value="Chewing Tobacco">
                              Chewing Tobacco
                            </option>
                            <option value="Cigarettes/cigars">
                              Cigarettes/cigars
                            </option>
                            <option value="Clay">Clay</option>
                            <option value="Cloth Labels">Cloth Labels</option>
                            <option value="Clothing, Fabric">
                              Clothing, Fabric
                            </option>
                            <option value="Coins, Collectible">
                              Coins, Collectible
                            </option>
                            <option value="Coins, Currency">
                              Coins, Currency
                            </option>
                            <option value="Collectible Stamps">
                              Collectible Stamps
                            </option>
                            <option value="Collectible, Book">
                              Collectible, Book
                            </option>
                            <option value="Commercial Appliance">
                              Commercial Appliance
                            </option>
                            <option value="Compact Disc">Compact Disc</option>
                            <option value="Computer Hardware">
                              Computer Hardware
                            </option>
                            <option value="Computer Printouts">
                              Computer Printouts
                            </option>
                            <option value="Computer Programs">
                              Computer Programs
                            </option>
                            <option value="Computer Speakers">
                              Computer Speakers
                            </option>
                            <option value="Contact Lenses">
                              Contact Lenses
                            </option>
                            <option value="Cooking Utensils">
                              Cooking Utensils
                            </option>
                            <option value="Corpses">Corpses</option>
                            <option value="Corrosives">Corrosives</option>
                            <option value="Cosmetics, Hazardous">
                              Cosmetics, Hazardous
                            </option>
                            <option value="Cosmetics, Non-Hazardous">
                              Cosmetics, Non-Hazardous
                            </option>
                            <option value="Costumes">Costumes</option>
                            <option value="Credit Card Blanks">
                              Credit Card Blanks
                            </option>
                            <option value="Credit Cards">Credit Cards</option>
                            <option value="Cultures">Cultures</option>
                            <option value="Currency">Currency</option>
                            <option value="Cut Flowers">Cut Flowers</option>
                            <option value="Cutlery">Cutlery</option>
                            <option value="DVD">DVD</option>
                            <option value="DVD Players">DVD Players</option>
                            <option value="DVD/DVD-RW Drives">
                              DVD/DVD-RW Drives
                            </option>
                            <option value="Dangerous Goods">
                              Dangerous Goods
                            </option>
                            <option value="Debit Cards">Debit Cards</option>
                            <option value="Dental Supplies">
                              Dental Supplies
                            </option>
                            <option value="Diamonds">Diamonds</option>
                            <option value="Digital Video Disc">
                              Digital Video Disc
                            </option>
                            <option value="Dishes">Dishes</option>
                            <option value="Diskettes">Diskettes</option>
                            <option value="Documents">Documents</option>
                            <option value="Draperies">Draperies</option>
                            <option value="Drawings">Drawings</option>
                            <option value="Drugs, Non-Prescription">
                              Drugs, Non-Prescription
                            </option>
                            <option value="Drugs, Prescription">
                              Drugs, Prescription
                            </option>
                            <option value="Dry Cell Batteries">
                              Dry Cell Batteries
                            </option>
                            <option value="Dry Ice">Dry Ice</option>
                            <option value="Electrical Equipment">
                              Electrical Equipment
                            </option>
                            <option value="Embryos">Embryos</option>
                            <option value="Emeralds">Emeralds</option>
                            <option value="Equipment Wireless">
                              Equipment Wireless
                            </option>
                            <option value="Equipment, Electrical">
                              Equipment, Electrical
                            </option>
                            <option value="Eurail Passes">Eurail Passes</option>
                            <option value="Explosives">Explosives</option>
                            <option value="Eyewear, Glasses">
                              Eyewear, Glasses
                            </option>
                            <option value="Fabric Samples">
                              Fabric Samples
                            </option>
                            <option value="Fabrics">Fabrics</option>
                            <option value="Fabrics, Finished">
                              Fabrics, Finished
                            </option>
                            <option value="Fertilizers">Fertilizers</option>
                            <option value="Film, Commercial">
                              Film, Commercial
                            </option>
                            <option value="Film, Developed">
                              Film, Developed
                            </option>
                            <option value="Film, Promotional/Training">
                              Film, Promotional/Training
                            </option>
                            <option value="Film, Undeveloped">
                              Film, Undeveloped
                            </option>
                            <option value="Firearm Parts">Firearm Parts</option>
                            <option value="Firearms">Firearms</option>
                            <option value="Fireworks">Fireworks</option>
                            <option value="First Aid Kits">
                              First Aid Kits
                            </option>
                            <option value="Flammables">Flammables</option>
                            <option value="Flatware">Flatware</option>
                            <option value="Floppy Disks">Floppy Disks</option>
                            <option value="Floppy/Disk Drives">
                              Floppy/Disk Drives
                            </option>
                            <option value="Food, Nonperishable">
                              Food, Nonperishable
                            </option>
                            <option value="Food, Perishable">
                              Food, Perishable
                            </option>
                            <option value="Forks">Forks</option>
                            <option value="Fungi">Fungi</option>
                            <option value="Furniture">Furniture</option>
                            <option value="Furs">Furs</option>
                            <option value="Garments, Textile">
                              Garments, Textile
                            </option>
                            <option value="Gases">Gases</option>
                            <option value="Gasoline">Gasoline</option>
                            <option value="Gift Shipment">Gift Shipment</option>
                            <option value="Glasses, Eyewear">
                              Glasses, Eyewear
                            </option>
                            <option value="Gold">Gold</option>
                            <option value="Graphite Products">
                              Graphite Products
                            </option>
                            <option value="Graphs">Graphs</option>
                            <option value="Greeting Cards">
                              Greeting Cards
                            </option>
                            <option value="Hair">Hair</option>
                            <option value="Hazardous Batteries">
                              Hazardous Batteries
                            </option>
                            <option value="Hazardous Waste">
                              Hazardous Waste
                            </option>
                            <option value="Herbs">Herbs</option>
                            <option value="Household Appliances">
                              Household Appliances
                            </option>
                            <option value="Human Parts">Human Parts</option>
                            <option value="Ice, Blue">Ice, Blue</option>
                            <option value="Ice, Dry">Ice, Dry</option>
                            <option value="Ice, Wet">Ice, Wet</option>
                            <option value="Identification Cards">
                              Identification Cards
                            </option>
                            <option value="Immigration Document">
                              Immigration Document
                            </option>
                            <option value="Industrial Diamonds">
                              Industrial Diamonds
                            </option>
                            <option value="Infectious Substance">
                              Infectious Substance
                            </option>
                            <option value="Ink Cartridge">Ink Cartridge</option>
                            <option value="Insects">Insects</option>
                            <option value="Inter Office Documents">
                              Inter Office Documents
                            </option>
                            <option value="Invitations">Invitations</option>
                            <option value="Invoices">Invoices</option>
                            <option value="Iron">Iron</option>
                            <option value="Issue Airline Ticket">
                              Issue Airline Ticket
                            </option>
                            <option value="Jewelry">Jewelry</option>
                            <option value="Jewelry, Costume">
                              Jewelry, Costume
                            </option>
                            <option value="Journals">Journals</option>
                            <option value="Keyboards">Keyboards</option>
                            <option value="Knives">Knives</option>
                            <option value="Lab Equipment">Lab Equipment</option>
                            <option value="Labels, Cloth">Labels, Cloth</option>
                            <option value="Labels, Paper">Labels, Paper</option>
                            <option value="Laptops">Laptops</option>
                            <option value="Leather Goods">Leather Goods</option>
                            <option value="Light Fixtures">
                              Light Fixtures
                            </option>
                            <option value="Lightbulbs">Lightbulbs</option>
                            <option value="Linens">Linens</option>
                            <option value="Liquid Ink">Liquid Ink</option>
                            <option value="Liquids, Hazardous">
                              Liquids, Hazardous
                            </option>
                            <option value="Liquids, Non-Hazardous">
                              Liquids, Non-Hazardous
                            </option>
                            <option value="Liquor, Hazardous">
                              Liquor, Hazardous
                            </option>
                            <option value="Liquor, Non-Hazardous">
                              Liquor, Non-Hazardous
                            </option>
                            <option value="Luggage, Personal">
                              Luggage, Personal
                            </option>
                            <option value="Machinery, Electrical">
                              Machinery, Electrical
                            </option>
                            <option value="Magazines">Magazines</option>
                            <option value="Magnetized Materials">
                              Magnetized Materials
                            </option>
                            <option value="Manuals">Manuals</option>
                            <option value="Manuscripts">Manuscripts</option>
                            <option value="Maps">Maps</option>
                            <option value="Marine Parts">Marine Parts</option>
                            <option value="Medical Equipment">
                              Medical Equipment
                            </option>
                            <option value="Medical Supplies">
                              Medical Supplies
                            </option>
                            <option value="Medical Waste">Medical Waste</option>
                            <option value="Medicine, Over the Counter">
                              Medicine, Over the Counter
                            </option>
                            <option value="Memos">Memos</option>
                            <option value="Metals, Non-Precious">
                              Metals, Non-Precious
                            </option>
                            <option value="Metals, Precious">
                              Metals, Precious
                            </option>
                            <option value="Micro Film">Micro Film</option>
                            <option value="Microfiche">Microfiche</option>
                            <option value="Mineral Products">
                              Mineral Products
                            </option>
                            <option value="Minerals">Minerals</option>
                            <option value="Mobile Phones">Mobile Phones</option>
                            <option value="Money">Money</option>
                            <option value="Money Orders">Money Orders</option>
                            <option value="Monitors">Monitors</option>
                            <option value="Mouse, Computer">
                              Mouse, Computer
                            </option>
                            <option value="Music, Printed">
                              Music, Printed
                            </option>
                            <option value="Musical Instruments">
                              Musical Instruments
                            </option>
                            <option value="Navigational Charts">
                              Navigational Charts
                            </option>
                            <option value="Negatives">Negatives</option>
                            <option value="Newspapers">Newspapers</option>
                            <option value="Nitrates">Nitrates</option>
                            <option value="Non-Alkaline Battery">
                              Non-Alkaline Battery
                            </option>
                            <option value="Non-Bearer Documents">
                              Non-Bearer Documents
                            </option>
                            <option value="Non-Bearer Negotiable Instrument">
                              Non-Bearer Negotiable Instrument
                            </option>
                            <option value="Non-Hazardous Batteries">
                              Non-Hazardous Batteries
                            </option>
                            <option value="Non-Precious Metals">
                              Non-Precious Metals
                            </option>
                            <option value="Nonprescription Drug">
                              Nonprescription Drug
                            </option>
                            <option value="Notebook, Computer">
                              Notebook, Computer
                            </option>
                            <option value="Office Equipment">
                              Office Equipment
                            </option>
                            <option value="Office Supplies">
                              Office Supplies
                            </option>
                            <option value="Over Counter Medicine">
                              Over Counter Medicine
                            </option>
                            <option value="Oxidizers">Oxidizers</option>
                            <option value="PC, Personal Computer">
                              PC, Personal Computer
                            </option>
                            <option value="Pagers">Pagers</option>
                            <option value="Paints, Hazardous">
                              Paints, Hazardous
                            </option>
                            <option value="Paints, Non-Hazardous">
                              Paints, Non-Hazardous
                            </option>
                            <option value="Paper Labels">Paper Labels</option>
                            <option value="Paper Patterns">
                              Paper Patterns
                            </option>
                            <option value="Parts, Aircraft">
                              Parts, Aircraft
                            </option>
                            <option value="Parts, Automotive">
                              Parts, Automotive
                            </option>
                            <option value="Parts, Computer">
                              Parts, Computer
                            </option>
                            <option value="Parts, Electrical">
                              Parts, Electrical
                            </option>
                            <option value="Parts, Electronic">
                              Parts, Electronic
                            </option>
                            <option value="Parts, Machine">
                              Parts, Machine
                            </option>
                            <option value="Parts, Plastic">
                              Parts, Plastic
                            </option>
                            <option value="Passports">Passports</option>
                            <option value="Patterns, Paper">
                              Patterns, Paper
                            </option>
                            <option value="Pearls">Pearls</option>
                            <option value="Pencils">Pencils</option>
                            <option value="Pens">Pens</option>
                            <option value="Perfume, Hazardous">
                              Perfume, Hazardous
                            </option>
                            <option value="Perfume, Non-Hazardous">
                              Perfume, Non-Hazardous
                            </option>
                            <option value="Periodicals">Periodicals</option>
                            <option value="Personal Effects">
                              Personal Effects
                            </option>
                            <option value="Phone Cards">Phone Cards</option>
                            <option value="Photocopies">Photocopies</option>
                            <option value="Photographs">Photographs</option>
                            <option value="Plants">Plants</option>
                            <option value="Plasma, Non-Hazardous">
                              Plasma, Non-Hazardous
                            </option>
                            <option value="Platinum">Platinum</option>
                            <option value="Poisons">Poisons</option>
                            <option value="Pokemon Toys">Pokemon Toys</option>
                            <option value="Pornography">Pornography</option>
                            <option value="Potpourri">Potpourri</option>
                            <option value="Powder">Powder</option>
                            <option value="Precious Metals">
                              Precious Metals
                            </option>
                            <option value="Precious Stones">
                              Precious Stones
                            </option>
                            <option value="Press Kits">Press Kits</option>
                            <option value="Printers, Computer">
                              Printers, Computer
                            </option>
                            <option value="Printouts">Printouts</option>
                            <option value="Pump Spray Cosmetics">
                              Pump Spray Cosmetics
                            </option>
                            <option value="Radioactives">Radioactives</option>
                            <option value="Radios, AM/FM">Radios, AM/FM</option>
                            <option value="Rail Pass, Issued">
                              Rail Pass, Issued
                            </option>
                            <option value="Records">Records</option>
                            <option value="Rocks">Rocks</option>
                            <option value="Rubies">Rubies</option>
                            <option value="Rugs">Rugs</option>
                            <option value="Samples">Samples</option>
                            <option value="Sand">Sand</option>
                            <option value="Sapphires">Sapphires</option>
                            <option value="Satellite">Satellite</option>
                            <option value="Satellite Parts">
                              Satellite Parts
                            </option>
                            <option value="Satellite Phones">
                              Satellite Phones
                            </option>
                            <option value="Scanners">Scanners</option>
                            <option value="Seeds">Seeds</option>
                            <option value="Serums">Serums</option>
                            <option value="Serums, Non-Hazardous">
                              Serums, Non-Hazardous
                            </option>
                            <option value="Servers">Servers</option>
                            <option value="Sheet Music">Sheet Music</option>
                            <option value="Shoes">Shoes</option>
                            <option value="Silver">Silver</option>
                            <option value="Silverware">Silverware</option>
                            <option value="Slides">Slides</option>
                            <option value="Smart Cards">Smart Cards</option>
                            <option value="Smoking Tobacco">
                              Smoking Tobacco
                            </option>
                            <option value="Software">Software</option>
                            <option value="Soil">Soil</option>
                            <option value="Souvenirs">Souvenirs</option>
                            <option value="Speakers">Speakers</option>
                            <option value="Specification Sheets">
                              Specification Sheets
                            </option>
                            <option value="Sperm, Animal, Non-Hazardous">
                              Sperm, Animal, Non-Hazardous
                            </option>
                            <option value="Sperm, Human, Non-Hazardous">
                              Sperm, Human, Non-Hazardous
                            </option>
                            <option value="Spoons">Spoons</option>
                            <option value="Sports Equipment">
                              Sports Equipment
                            </option>
                            <option value="Stamps">Stamps</option>
                            <option value="Stamps, Collectible">
                              Stamps, Collectible
                            </option>
                            <option value="Stationary">Stationary</option>
                            <option value="Steel">Steel</option>
                            <option value="Stereo Equipment">
                              Stereo Equipment
                            </option>
                            <option value="Stocks">Stocks</option>
                            <option value="Stocks, Endorsed">
                              Stocks, Endorsed
                            </option>
                            <option value="Stocks, Non-Negotiable">
                              Stocks, Non-Negotiable
                            </option>
                            <option value="Stones, Precious">
                              Stones, Precious
                            </option>
                            <option value="Supplies, Medical">
                              Supplies, Medical
                            </option>
                            <option value="Supplies, Office">
                              Supplies, Office
                            </option>
                            <option value="Surgical Supplies">
                              Surgical Supplies
                            </option>
                            <option value="Tableware">Tableware</option>
                            <option value="Tapes, Audio">Tapes, Audio</option>
                            <option value="Tapes, Computer">
                              Tapes, Computer
                            </option>
                            <option value="Tapes, Video">Tapes, Video</option>
                            <option value="Technical Drawings">
                              Technical Drawings
                            </option>
                            <option value="Telephones">Telephones</option>
                            <option value="Televisions">Televisions</option>
                            <option value="Textiles, Finished">
                              Textiles, Finished
                            </option>
                            <option value="Textiles, Unfinished">
                              Textiles, Unfinished
                            </option>
                            <option value="Thread">Thread</option>
                            <option value="Tickets, Events">
                              Tickets, Events
                            </option>
                            <option value="Tiles">Tiles</option>
                            <option value="Tires">Tires</option>
                            <option value="Tissue Sample, Non-Hazardous">
                              Tissue Sample, Non-Hazardous
                            </option>
                            <option value="Tobacco">Tobacco</option>
                            <option value="Tobacco Leaf">Tobacco Leaf</option>
                            <option value="Toiletries, Hazardous">
                              Toiletries, Hazardous
                            </option>
                            <option value="Toiletries, Non-Hazardous">
                              Toiletries, Non-Hazardous
                            </option>
                            <option value="Toner Cartridge">
                              Toner Cartridge
                            </option>
                            <option value="Tools">Tools</option>
                            <option value="Toxics">Toxics</option>
                            <option value="Toxins, Non-Hazardous">
                              Toxins, Non-Hazardous
                            </option>
                            <option value="Toys">Toys</option>
                            <option value="Transparencies">
                              Transparencies
                            </option>
                            <option value="Un3373">Un3373</option>
                            <option value="Undergarments">Undergarments</option>
                            <option value="Unfinished Textiles">
                              Unfinished Textiles
                            </option>
                            <option value="Unsolicited Gifts">
                              Unsolicited Gifts
                            </option>
                            <option value="VCR Players">VCR Players</option>
                            <option value="Vaccines">Vaccines</option>
                            <option value="Vase">Vase</option>
                            <option value="Video Tape">Video Tape</option>
                            <option value="Videos">Videos</option>
                            <option value="Viruses">Viruses</option>
                            <option value="Visas">Visas</option>
                            <option value="Vitamins">Vitamins</option>
                            <option value="Walkie Talkie">Walkie Talkie</option>
                            <option value="Watches">Watches</option>
                            <option value="Weapons">Weapons</option>
                            <option value="Weather Charts">
                              Weather Charts
                            </option>
                            <option value="Weed Killers">Weed Killers</option>
                            <option value="Wet Ice">Wet Ice</option>
                            <option value="Wig">Wig</option>
                            <option value="Wine, Non-Hazardous">
                              Wine, Non-Hazardous
                            </option>
                            <option value="Wireless Equipment">
                              Wireless Equipment
                            </option>
                            <option value="Wireless Lan">Wireless Lan</option>
                            <option value="Wood">Wood</option>
                            <option value="X Rays">X Rays</option>
                            <option value="Yarn">Yarn</option>
                          </select>
                        </div>
                        <span className="error-message">
                          {this.state.errors.itemType}
                        </span>
                      </div>
                      <div
                        className={
                          "form-group enter-any-additional-instructions required-field" +
                          (this.state.errors.description !== "" ? " error" : "")
                        }
                      >
                        <div className="add-a-message">
                          <AssistiveError
                            inputId="gxg-item-detailed-description"
                            errorMessage={this.state.errors.description}
                          />
                          <label
                            htmlFor="gxg-item-detailed-description"
                            className="inputLabel"
                          >
                            *Detailed Description{" "}
                            <DetailedDescPopover targetName="detailedDescGxgIcon" />
                          </label>
                          <textarea
                            className="form-control"
                            id="gxg-item-detailed-description"
                            rows="5"
                            maxLength="35"
                            onChange={(e) => {
                              this.changeItemInfo("itemDesc")(e.target.value);
                              this.state.errors.description !== ""
                                ? this.clearError("description")
                                : this.clearError("");
                            }}
                            value={this.state.labelItem.itemDesc}
                          />
                          <span className="error-message">
                            {this.state.errors.description}
                          </span>
                          <div id="detailed-description-counter">
                            {this.state.labelItem.itemDesc
                              ? this.state.labelItem.itemDesc.length
                              : 0}{" "}
                            / 35
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.commercialSender ? (
                      <div className="col-12 col-md-6 commercial-sender-section-wrapper">
                        <div className="commercial-senders-info-box">
                          <p>
                            <strong>Commercial Senders Only </strong>
                          </p>
                          <div className="row">
                            <div
                              className={
                                "col-12 form-group required-field" +
                                (this.state.errors.hsTariffError !== ""
                                  ? " error"
                                  : "")
                              }
                            >
                              <AssistiveError
                                inputId="gxg-hs-tariff-input"
                                errorMessage={this.state.errors.hsTariffError}
                              />
                              <label htmlFor="gxg-hs-tariff-input">
                                HS Tariff Number{" "}
                                <HsTariffPopover targetName="hsTariffGxgIcon" />
                              </label>
                              <input
                                tabIndex="0"
                                type="text"
                                id="gxg-hs-tariff-input"
                                className="form-control"
                                value={this.state.labelItem.hsTariffNbr || ""}
                                onChange={(e) => {
                                  this.changeItemInfo("hsTariffNbr")(
                                    e.target.value
                                  );
                                  this.state.errors.hsTariffError !== ""
                                    ? this.clearError("hsTariffError")
                                    : this.clearError("");
                                }}
                              />
                              <span className="error-message">
                                {this.state.errors.hsTariffError}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 form-group">
                              <label htmlFor="gxg-country-of-origin-dropdown">
                                Country of Origin{" "}
                                <CountryOfOriginPopover targetName="countryOfOriginGxgIcon" />
                              </label>
                              <select
                                id="gxg-country-of-origin-dropdown"
                                className="form-control country-dropdown dropdown"
                                onChange={(e) =>
                                  this.changeItemInfo("countryId")(
                                    e.target.value
                                  )
                                }
                                value={
                                  this.state.labelItem.countryId
                                    ? this.state.labelItem.countryId.countryDbId
                                    : "0"
                                }
                              >
                                {this.state.countryList.map((country) => (
                                  <option
                                    key={country.countryDbId}
                                    value={country.countryDbId}
                                  >
                                    {country.countryName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <React.Fragment />
                    )}
                  </div>
                </div>
              </div>
              <div className="row item-value-quantity-wrapper">
                <div
                  className={
                    "col-6 col-md-3 form-group required-field item-value-wrapper" +
                    (this.state.errors.itemValue !== "" ? " error" : "")
                  }
                >
                  <AssistiveError
                    inputId="gxg-item-value"
                    errorMessage={this.state.errors.itemValue}
                  />
                  <label htmlFor="gxg-item-value">*Item Value</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      id="gxg-item-value"
                      className="form-control prepend"
                      tabIndex="0"
                      type="text"
                      value={this.state.labelItem.unitValue}
                      onChange={(e) => {
                        this.changeItemInfo("unitValue")(e.target.value);
                        this.state.errors.itemValue !== ""
                          ? this.clearError("itemValue")
                          : this.clearError("");
                      }}
                    />
                  </div>
                  <span className="error-message">
                    {this.state.errors.itemValue}
                  </span>
                </div>
                <div
                  className={
                    "col-6 col-md-3 form-group required-field quantity-wrapper" +
                    (this.state.errors.quantity !== "" ? " error" : "")
                  }
                >
                  <AssistiveError
                    inputId="gxg-item-quantity"
                    errorMessage={this.state.errors.quantity}
                  />
                  <label htmlFor="gxg-item-quantity">*Quantity</label>
                  <input
                    tabIndex="0"
                    type="number"
                    id="gxg-item-quantity"
                    className="form-control"
                    value={this.state.labelItem.itemQty}
                    onChange={(e) => {
                      this.changeItemInfo("itemQty")(e.target.value);
                      this.state.errors.quantity !== ""
                        ? this.clearError("quantity")
                        : this.clearError("");
                    }}
                  />
                  <span className="error-message">
                    {this.state.errors.quantity}
                  </span>
                </div>
              </div>
              <div className="row">
                <div
                  className={
                    "col-12 col-md-6 package-weight-wrapper form-group required-field" +
                    (this.state.errors.weight !== "" ? " error" : "")
                  }
                >
                  <AssistiveError
                    inputId="gxg-item-weight-pounds"
                    errorMessage={this.state.errors.weight}
                  />
                  <label>
                    *Weight <ItemPackageWeight targetName={"weightInfoIcon"} />
                  </label>
                  <div className="row package-weight-fields-wrapper">
                    <div className="col-6 form-group required-field">
                      <form className="form-inline package-weight-input">
                        <div className="package-weight-lbs-input">
                          <div className="input-group">
                            <input
                              id="gxg-item-weight-pounds"
                              className="dimensions-form form-control append"
                              type="text"
                              placeholder="0"
                              aria-label=""
                              aria-describedby=""
                              onChange={(e) => {
                                this.setWeightInPounds(e);
                                this.state.errors.weight !== ""
                                  ? this.clearError("weight")
                                  : this.clearError("");
                              }}
                              value={
                                this.state.weightInPounds ||
                                this.state.weightInPounds !== undefined
                                  ? this.state.weightInPounds
                                  : this.getPounds(
                                      this.state.labelItem.unitWeightOz
                                    )
                              }
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">lbs</span>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-6 package-oz-input form-group required-field">
                      <form className="form-inline package-weight-input">
                        <div className="package-lbs-input">
                          <div className="input-group">
                            <input
                              id="gxg-item-weight-ounces"
                              className="dimensions-form form-control append"
                              type="text"
                              placeholder="0"
                              aria-label=""
                              aria-describedby=""
                              onChange={(e) => {
                                this.setWeightInOz(e);
                                this.state.errors.weight !== ""
                                  ? this.clearError("weight")
                                  : this.clearError("");
                              }}
                              value={
                                this.state.setWeightInOz ||
                                this.state.setWeightInOz !== ""
                                  ? this.state.setWeightInOz
                                  : this.getOunces(
                                      this.state.labelItem.unitWeightOz
                                    )
                              }
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">oz</span>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <span className="error-message">
                    {this.state.errors.weight}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-12 form-group total-package-weight-txt">
                  <label>Total Package Weight</label>
                  <p>
                    <strong>
                      [
                      <span id="total-package-weight-lbs">
                        {this.getTotalPackageWeight()}
                      </span>
                      ]
                    </strong>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 form-group total-weight-txt">
                  <label>Total Weight</label>
                  <p>
                    <strong>
                      [
                      <span id="total-weight-lbs">
                        {this.getPoundsAndOz(
                          1,
                          this.state.fetchedCartList.cnsCartList[0].labelPkg
                            .weightOzQty
                        )}
                      </span>
                      ]
                    </strong>
                  </p>
                </div>
              </div>
              {!this.state.inEditItem === true ? (
                <div className="row">
                  <div className="col-12 col-md-10">
                    <div className="button-container">
                      <button
                        type="button"
                        className="btn-primary button--white"
                        id="step8-add-item"
                        onClick={this.addLabelItem}
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-6 col-md-6">
                    <div className="button-container">
                      <button
                        type="button"
                        className="btn-primary button--white"
                        id="step8-update-item"
                        onClick={this.addLabelItem}
                      >
                        Update Item
                      </button>
                    </div>
                  </div>
                  <div className="col-6 col-md-6">
                    <div className="button-container">
                      <button
                        type="button"
                        className="btn-primary button--white"
                        id="step8-cancel-item"
                        onClick={this.cancelAddLabelItem}
                      >
                        Cancel Item
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.fetchedLabelItemList.length > 0 ? (
                <React.Fragment>
                  <div className="row">
                    <div className="col-12 col-md-11 col-lg-10">
                      <p className="customs-info-label">
                        <strong>Package Items</strong>
                      </p>

                      <table className="table package-items-table summary">
                        <thead className="items-table-header">
                          <tr className="d-none d-md-table-row">
                            <th scope="col">Item #</th>
                            <th scope="col">Description</th>
                            <th scope="col">HS Tariff #</th>
                            <th scope="col" className="country-td">
                              Country of Origin
                            </th>
                            <th scope="col">Qty</th>
                            <th scope="col">Value</th>
                            <th scope="col">Weight</th>
                            <th scope="col" />
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody className="step-8-full-table">
                          {this.state.fetchedLabelItemList.map((item, keys) => (
                            <tr
                              key={item.labelItemId}
                              className={
                                this.state.labelItemsClickedMobile.includes(
                                  item.labelItemId
                                )
                                  ? "package-items-tr-active"
                                  : ""
                              }
                            >
                              <th scope="row" />
                              {this.state.isDesktop ||
                              !(item.hsTariffNbr || item.countryId) ? (
                                <td className="package-items-desc">
                                  {item.itemDesc}
                                </td>
                              ) : (
                                <td className="package-items-desc-mobile">
                                  <button
                                    type="button"
                                    className="package-items-desc button-link"
                                    onClick={() =>
                                      this.setLabelItemsClickedMobile(
                                        item.labelItemId
                                      )
                                    }
                                  >
                                    {item.itemDesc}
                                  </button>
                                </td>
                              )}
                              <td className="package-items-hs">
                                {item.hsTariffNbr}
                              </td>
                              <td className="country-td">
                                {item.countryOfOrigin}
                              </td>
                              <td className="package-items-qty">
                                {item.itemQty}
                              </td>
                              <td className="package-items-value">
                                ${(item.unitValue * item.itemQty).toFixed(2)}$
                              </td>
                              <td className="package-items-weight">
                                {this.getPoundsAndOz(
                                  1,
                                  item.unitWeightOz,
                                  item.itemQty
                                )}
                              </td>
                              <td className="package-items-edit">
                                <button
                                  type="button"
                                  className="inline-link-edit button-link"
                                  onClick={(e) => this.editLabelItem(e, item)}
                                  aria-label="Edit this item"
                                >
                                  <span>Edit</span>
                                </button>
                              </td>
                              <td className="package-items-remove">
                                <span>
                                  {" "}
                                  <DeleteLabelItem
                                    buttonLabel={"Remove"}
                                    deleteItem={this.deleteItem}
                                    item={item}
                                    keys={keys}
                                  />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row total-package-weight-box required-field">
                    <div className="col-6 col-md-5">
                      <DeleteAllLabelItems
                        buttonLabel={"Remove All"}
                        deleteAllItems={this.deleteAllItems}
                      />
                    </div>
                    <div className="col-6 col-md-5 text-right">
                      <p className="calc-package-weight-text">
                        Total Package Value:{" "}
                        <strong>${this.getTotalPackageValue()}</strong>
                      </p>
                      <p className="total-package-weight-text">
                        Total Package Weight: {this.getTotalPackageWeight()}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
              <AssistiveGlobalError
                globalErrorId="label-item-error-section"
                globalErrorArray={this.state.errors.globalItemError}
              />
              <div className="row">
                <div className="col-12 d-md-none horizontal-line-container">
                  <hr className="horizontal-line" />
                </div>
              </div>
              <div className="row">
                <div className="col-12 census-bureau-customs-header">
                  <p className="customs-info-label">
                    US Census Bureau and Customs Information{" "}
                  </p>
                </div>
              </div>
              <div className="row" id="section-export-info">
                <div className="col-12">
                  <p>
                    International packages within certain categories accepted by
                    the Postal Service or any delivery company must display an{" "}
                    <a
                      href="https://ace.cbp.dhs.gov/"
                      className="inline-link right-chevron"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      AES Exemption
                    </a>
                  </p>
                </div>
              </div>
              <React.Fragment>
                <div className="row">
                  <div
                    className={
                      "col-12 col-md-5 col-lg-4 form-group required-field" +
                      (this.state.errors.aes !== "" ? " error" : "")
                    }
                  >
                    <AssistiveError
                      inputId="gxg-eel-code-dropdown"
                      errorMessage={this.state.errors.aes}
                    />
                    <label htmlFor="gxg-eel-code-dropdown">
                      *AES Exemption{" "}
                      <ChooseAESExemptionPopover targetName="AESExemption" />
                    </label>
                    <select
                      id="gxg-eel-code-dropdown"
                      className="form-control dropdown"
                      onChange={(e) => {
                        this.changeLabelCustDec("eelCode")(e.target.value);
                        this.state.errors.aes !== ""
                          ? this.clearError("aes")
                          : this.clearError("");
                      }}
                      value={this.state.labelCustDec.eelCode}
                    >
                      <option value="">Select</option>
                      {this.state.eelCodeOptions.map((eelCode) => (
                        <option key={eelCode.value} value={eelCode.value}>
                          {eelCode.value + eelCode.description}
                        </option>
                      ))}
                    </select>
                    <span className="error-message">
                      {this.state.errors.aes}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 us-census-bureau-text">
                    <a
                      href="https://www.census.gov/foreign-trade/aes/index.html"
                      className="inline-link right-chevron"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      US Census Bureau
                    </a>
                  </div>
                </div>
              </React.Fragment>
              <div className="row">
                <div className="col-12 horizontal-line-container">
                  <hr className="horizontal-line" />
                </div>
              </div>
              <div className="row">
                <div
                  id="restrictions-and-prohibitions"
                  className="col-12 vdrawers"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <h2>
                          Please read about shipping restrictions and
                          prohibitions.
                        </h2>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        id="no-padding-mobile-restrictions-gxg"
                        className="col-12 col-md-4 col-lg-3 v-tab"
                      >
                        <div
                          className={
                            "shipping-restrictions" +
                            (this.state.showRestrictions
                              ? " active" +
                                (this.state.showRestrictionsMobile
                                  ? " mobile-active"
                                  : " mobile-inactive")
                              : "")
                          }
                        >
                          <button
                            type="button"
                            className="button-link"
                            onClick={(e) => this.showRestrictions(e)}
                          >
                            <h3>Restrictions</h3>
                          </button>
                          {this.state.showRestrictionsMobile ? (
                            <div className="mobile-content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: this.state.fetchedCartList
                                    .cnsCartList[0].restrictions
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div
                          className={
                            "shipping-restrictions" +
                            (this.state.showProhibitions
                              ? " active" +
                                (this.state.showProhibitionsMobile
                                  ? " mobile-active"
                                  : " mobile-inactive")
                              : "")
                          }
                        >
                          <button
                            type="button"
                            className="button-link"
                            onClick={(e) => this.showProhibitions(e)}
                          >
                            <h3>Prohibitions</h3>
                          </button>
                          {this.state.showProhibitionsMobile ? (
                            <div className="mobile-content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: this.state.fetchedCartList
                                    .cnsCartList[0].prohibitions
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-none d-md-block col-md-8 col-lg-9 v-tab-content">
                        {this.state.showRestrictions ? (
                          <div
                            className="active"
                            dangerouslySetInnerHTML={{
                              __html: this.state.fetchedCartList.cnsCartList[0]
                                .restrictions
                            }}
                          />
                        ) : (
                          <div
                            className="active"
                            dangerouslySetInnerHTML={{
                              __html: this.state.fetchedCartList.cnsCartList[0]
                                .prohibitions
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 button-wrapper">
                  <div className="button-container">
                    <a
                      href="/gxgCommerceInformation"
                      role="button"
                      className="btn-primary"
                      onClick={(e) => this.saveCustomsInfo(e)}
                    >
                      Continue
                    </a>
                  </div>
                  <div className="button-container">
                    <a
                      href="/labelInformation"
                      className="btn-primary button--white"
                      onClick={this.editLabel}
                    >
                      Edit Label
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(GXGCustomsInformation);
