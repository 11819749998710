import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const TermsConditionsPaymentPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        This is printed on the customer's Commercial Invoice. Enter the terms
        and conditions of payment in 100 characters or less.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Payment Terms Information Icon"
      header="Terms and Conditions of Payment"
      content={content}
    />
  );
};

export default TermsConditionsPaymentPopover;
