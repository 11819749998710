import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import "react-dates/lib/css/_datepicker.css";
import DateRangeSelector from "../Popovers/DateRangeSelector";
import AssistiveError from "../Subcomponents/AssistiveError";
import moment from "moment";

class StepThreeShippingDate extends React.Component {
  render() {
    return (
      <div className="Step_Three_Container" id="shipping-date">
        <div className="container-fluid">
          <h2 className="normal">
            Step 3: <strong>Shipping Date</strong>
          </h2>
          <div className="row">
            <div
              className={
                "col-12 col-md-6 col-lg-5 shipping-date-container required-field" +
                (this.props.configInfo.errors.shippingDate !== ""
                  ? " error"
                  : "")
              }
            >
              <AssistiveError
                inputId="input-date"
                errorMessage={this.props.configInfo.errors.shippingDate}
              />
              <label
                htmlFor="input-date"
                className="choose-shipping-date-label"
              >
                *Shipping Date. Choose a date up to 3 days from today.
              </label>
              <div
                onFocus={(e) =>
                  this.props.configInfo.errors.shippingDate !== ""
                    ? this.props.clearError("shippingDate")
                    : this.props.clearError("")
                }
              >
                <DateRangeSelector
                  changeNonNestedFields={this.props.changeNonNestedFields}
                  currentShippingDates={this.props.currentShippingDates}
                  isOutsideRange={(day) => moment().diff(day) > 5}
                  shippingDate={this.props.configInfo.shippingDate}
                />
              </div>
              <span className="error-message">
                {this.props.configInfo.errors.shippingDate}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StepThreeShippingDate;
