import React from "react";

const LastLPAPersonal = (props) => {
  if (props.carouselStyling) {
    return (
      <>
        <div className="row">
          <div id="redirectToNewCnsText" className="col-12 ">
            <p className="bannerCarouselText">
              <strong>Get Commercial Rates</strong> on domestic mail in our{" "}
              <a
                className="btn btn-link"
                tabIndex="0"
                href={props.cnsbUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  paddingLeft: "0px",
                  fontSize: "15px",
                  textDecoration: "underline"
                }}
              >
                enhanced Click-N-Ship® experience.
              </a>
            </p>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div
        id="redirect-to-new-cns-banner-wrapper"
        className="row container-fluid"
        style={{
          display: "flex",
          background: "#ededed",
          borderLeft: "15px solid #3573b1",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "20px",
          fontSize: "16px"
        }}
      >
        <div
          id="redirect-to-new-cns-banner-description-wrapper"
          className="col-12 col-md-7 col-lg-8"
        >
          <p
            id="redirect-to-new-cns-banner-description"
            style={{ color: "#333366" }}
          >
            <strong>Get lower rates</strong> on Priority Mail® and Priority Mail
            Express® labels in our
            <a
              className="btn btn-link"
              tabIndex="0"
              href={props.cnsbUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              enhanced Click-N-Ship® experience
            </a>
          </p>
        </div>
        {/* <div className="col-12 col-md-5 col-lg-4 text-center banner-button">
          {button}
        </div> */}
      </div>
    );
  }
};

export default LastLPAPersonal;
