import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

class Build extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      isLoaded: false
    };
  }

  componentDidMount() {
    axios
      .get("/go/cnsrest/buildInformation")
      .then((response) => {
        this.setState({
          isLoaded: true,
          shortVersion: response.data.shortVersion,
          buildInformation: response.data.buildInformation,
          userAgent: response.data.userAgent
        });
      })
      .finally(() => {
        this.props.toggleSpinner(false);
      });
  }

  backToHome = () => {
    window.location.replace("/labelInformation");
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoaded ? (
          <div className="message-wrapper">
            <div id="text" className="center" style={{ marginLeft: "15px" }}>
              <div id="textForm">
                <br />
                <h2>
                  <span id="shortVersion" style={{ display: "block" }}>
                    {this.state.shortVersion}
                  </span>
                  <span id="buildInformation" style={{ display: "block" }}>
                    {this.state.buildInformation}
                  </span>
                  <span id="staticBuildDate" style={{ display: "block" }}>
                    Static Content Date: STATIC_DATE
                  </span>
                  <span id="staticBuildVersion" style={{ display: "block" }}>
                    Static Content Version: STATIC_VERSION
                  </span>
                </h2>
              </div>
              <br />
              <div>
                <p>
                  User Agent: <span id="userAgent">{this.state.userAgent}</span>
                </p>
              </div>
            </div>
            <div className="col-12 button-wrapper">
              <div className="button-container">
                <a
                  href="/labelInformation"
                  role="button"
                  className="btn-primary button--white"
                  onClick={(e) => this.backToHome()}
                >
                  Back to Click-N-Ship Home
                </a>
              </div>
              <div className="button-container">
                <a
                  href={
                    "mailto:?body=" +
                    this.state.shortVersion +
                    "%0D%0A" +
                    this.state.buildInformation +
                    "%0D%0A" +
                    "Static Content Date: STATIC_DATE" +
                    "%0D%0A" +
                    "Static Content Version: STATIC_VERSION" +
                    "%0D%0A" +
                    "User Agent: " +
                    this.state.userAgent
                  }
                  role="button"
                  className="btn-primary"
                >
                  Copy Text to Email
                </a>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Build);
