import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import "react-dates/lib/css/_datepicker.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import update from "immutability-helper";
import DateRangeSelector from "../Popovers/DateRangeSelector";
import PurposeOfShipmentPopover from "../Popovers/PurposeOfShipmentPopover";
import CountryUltimateDestinationPopover from "../Popovers/CountryUltimateDestinationPopover";
import PartiesToTransactionPopover from "../Popovers/PartiesToTransactionPopover";
import CommercialShipmentPopover from "../Popovers/CommercialShipmentPopover";
import TermsConditionsPaymentPopover from "../Popovers/TermsConditionsPaymentPopover";
import TermsConditionsDeliveryPopover from "../Popovers/TermsConditionsDeliveryPopover";
import UrbanizationCodesPopover from "../Popovers/UrbanizationCodesPopover";
import TermsConditionsGxgModal from "../Modals/TermsConditionsGxgModal";
import StatesTerritoriesList from "../Subcomponents/StatesTerritoriesList";
import AssistiveError from "../Subcomponents/AssistiveError";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";

import moment from "moment";

class GXGCommerceInformation extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      isLoaded: false,
      termsConditions: false,
      errors: {
        purposeOfShipment: "",
        partiesToTransaction: "",
        invoiceDate: "",
        invoiceNumber: "",
        termsAndConditions: "",
        termsAndConditionsDelivery: "",
        agreement: "",
        country: "",
        firstName: "",
        lastName: "",
        streetAddress: "",
        line1Addr: "",
        city: "",
        stateId: "",
        zipCode: "",
        globalError: []
      }
    };
  }

  editLabel = (e) => {
    e.preventDefault();
    this.props.setEditCartId(
      this.state.fetchedGxgCommerceInfo.cnsCart.cnsCartId
    );
    this.props.history.push("/labelInformation", {
      editItem: this.state.fetchedGxgCommerceInfo.cnsCart.cnsCartId
    });
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: errorMessage
        }
      });
    }
  };

  clearError = (fieldName) => {
    this.setState((prevState) => {
      return {
        errors: {
          ...prevState.errors,
          [fieldName]: ""
        }
      };
    });
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };
  //SET AES<ADD EXPORTLICIENCE IND CODE
  componentDidMount() {
    this.props.toggleSpinner(true);
    // If there is no history, redirect the user to the create label page
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.cnsCartId
    ) {
      axios
        .get(
          "/go/cnsrest/fetchGxgCommercial?holdCartId=" +
            this.props.location.state.cnsCartId
        )
        .then((response) => {
          this.props.toggleSpinner(false);
          if (
            Object.keys(response.data.fieldErrors).length === 0 &&
            response.data.actionErrors.length === 0
          ) {
            this.setState({
              focusOnTop: true,
              isLoaded: true,
              fetchedGxgCommerceInfo: response.data,
              // Shipping info and parties to transaction
              purposeOfShipment: response.data.cnsCart.labelGxg
                .purposeOfShipment
                ? response.data.cnsCart.labelGxg.purposeOfShipment
                : "",
              ultimateDestinationId: response.data.cnsCart.labelGxg
                .ultimateDestinationId
                ? response.data.cnsCart.labelGxg.ultimateDestinationId
                : "",
              partiesToTransaction: response.data.cnsCart.labelGxg
                .partiesToTransaction
                ? response.data.cnsCart.labelGxg.partiesToTransaction
                : "",
              packingCost: response.data.cnsCart.labelGxg.packingCost
                ? String(response.data.cnsCart.labelGxg.packingCost)
                : "0",
              // Commercial shipment
              commercialShipment:
                response.data.cnsCart.labelGxg.commercialShipmentInd === "Y",
              invoiceDate: response.data.cnsCart.labelGxg.invoiceDate
                ? response.data.cnsCart.labelGxg.invoiceDate
                : "",
              invoiceNbr:
                response.data.cnsCart.labelCustDec &&
                response.data.cnsCart.labelCustDec.invoiceNbr
                  ? response.data.cnsCart.labelCustDec.invoiceNbr
                  : "",
              customerOrderNumber: response.data.cnsCart.labelGxg
                .customerOrderNumber
                ? response.data.cnsCart.labelGxg.customerOrderNumber
                : "",
              paymentTerms: response.data.cnsCart.labelGxg.paymentTerms
                ? response.data.cnsCart.labelGxg.paymentTerms
                : "",
              deliveryTerms: response.data.cnsCart.labelGxg.deliveryTerms
                ? response.data.cnsCart.labelGxg.deliveryTerms
                : "",
              // Buyer information
              differentBuyer:
                response.data.cnsCart.buyerAddress &&
                response.data.cnsCart.buyerAddress.countryId
                  ? true
                  : false,
              countryId:
                response.data.cnsCart.buyerAddress &&
                response.data.cnsCart.buyerAddress.countryId
                  ? String(response.data.cnsCart.buyerAddress.countryId)
                  : "0",
              firstName:
                response.data.cnsCart.buyerAddress &&
                response.data.cnsCart.buyerAddress.firstName
                  ? response.data.cnsCart.buyerAddress.firstName
                  : "",
              middleInit:
                response.data.cnsCart.buyerAddress &&
                response.data.cnsCart.buyerAddress.middleInit
                  ? response.data.cnsCart.buyerAddress.middleInit
                  : "",
              lastName:
                response.data.cnsCart.buyerAddress &&
                response.data.cnsCart.buyerAddress.lastName
                  ? response.data.cnsCart.buyerAddress.lastName
                  : "",
              streetAddress:
                response.data.cnsCart.buyerAddress &&
                this.isBuyerDomesticInitially(
                  response.data.cnsCart.buyerAddress.countryId
                ) &&
                response.data.cnsCart.buyerAddress.line1Addr
                  ? response.data.cnsCart.buyerAddress.line1Addr
                  : "",
              aptSuiteOther:
                response.data.cnsCart.buyerAddress &&
                this.isBuyerDomesticInitially(
                  response.data.cnsCart.buyerAddress.countryId
                ) &&
                response.data.cnsCart.buyerAddress.line2Addr
                  ? response.data.cnsCart.buyerAddress.line2Addr
                  : "",
              line1Addr:
                response.data.cnsCart.buyerAddress &&
                !this.isBuyerDomesticInitially(
                  response.data.cnsCart.buyerAddress.countryId
                ) &&
                response.data.cnsCart.buyerAddress.line1Addr
                  ? response.data.cnsCart.buyerAddress.line1Addr
                  : "",
              line2Addr:
                response.data.cnsCart.buyerAddress &&
                !this.isBuyerDomesticInitially(
                  response.data.cnsCart.buyerAddress.countryId
                ) &&
                response.data.cnsCart.buyerAddress.line2Addr
                  ? response.data.cnsCart.buyerAddress.line2Addr
                  : "",
              line3Addr:
                response.data.cnsCart.buyerAddress &&
                !this.isBuyerDomesticInitially(
                  response.data.cnsCart.buyerAddress.countryId
                ) &&
                response.data.cnsCart.buyerAddress.line3Addr
                  ? response.data.cnsCart.buyerAddress.line3Addr
                  : "",
              province:
                response.data.cnsCart.buyerAddress &&
                !this.isBuyerDomesticInitially(
                  response.data.cnsCart.buyerAddress.countryId
                ) &&
                response.data.cnsCart.buyerAddress.province
                  ? response.data.cnsCart.buyerAddress.province
                  : "",
              cityName:
                response.data.cnsCart.buyerAddress &&
                response.data.cnsCart.buyerAddress.cityName
                  ? response.data.cnsCart.buyerAddress.cityName
                  : "",
              stateId:
                response.data.cnsCart.buyerAddress &&
                this.isBuyerDomesticInitially(
                  response.data.cnsCart.buyerAddress.countryId
                ) &&
                response.data.cnsCart.buyerAddress.stateId
                  ? String(response.data.cnsCart.buyerAddress.stateId)
                  : "",
              zipCode:
                response.data.cnsCart.buyerAddress &&
                this.isBuyerDomesticInitially(
                  response.data.cnsCart.buyerAddress.countryId
                ) &&
                response.data.cnsCart.buyerAddress.zip5
                  ? response.data.cnsCart.buyerAddress.zip5
                  : "",
              postalCode:
                response.data.cnsCart.buyerAddress &&
                !this.isBuyerDomesticInitially(
                  response.data.cnsCart.buyerAddress.countryId
                ) &&
                response.data.cnsCart.buyerAddress.postalCode
                  ? response.data.cnsCart.buyerAddress.postalCode
                  : "",
              urbanizationCode:
                response.data.cnsCart.buyerAddress &&
                this.isBuyerDomesticInitially(
                  response.data.cnsCart.buyerAddress.countryId
                ) &&
                response.data.cnsCart.buyerAddress.stateId &&
                String(response.data.cnsCart.buyerAddress.stateId) === "49" &&
                response.data.cnsCart.buyerAddress.urbanizationCode
                  ? response.data.cnsCart.buyerAddress.urbanizationCode
                  : "",
              // Other
              countryList: this.reorderCountryList(
                response.data.countryZonesViewBean
              )
            });
          } else {
            this.props.toggleSpinner(false);
            this.setGlobalError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.toggleSpinner(false);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        });
    } else {
      window.location.replace("/labelInformation");
    }
  }

  componentDidUpdate() {
    // Scroll to the first section that has an error, and
    // focus on the first field with an error
    if (this.state.focusOnError) {
      if (this.shipmentPartiesErrorFound) {
        // Shipping info and parties to transaction
        document.getElementById("shipment-parties-section").scrollIntoView();
        if (this.errors["purposeOfShipment"]) {
          document.getElementById("gxg-purpose-of-shipment-a11y").focus();
        } else if (this.errors["partiesToTransaction"]) {
          document.getElementById("related-radio-a11y").focus();
        }
      } else if (this.commercialShipmentErrorFound) {
        // Commercial shipment
        document.getElementById("commercial-shipment-section").scrollIntoView();
        if (this.errors["invoiceDate"]) {
          document.getElementById("input-date-a11y").focus();
        } else if (this.errors["invoiceNumber"]) {
          document.getElementById("gxg-invoice-number-a11y").focus();
        } else if (this.errors["termsAndConditions"]) {
          document
            .getElementById("gxg-terms-conditions-payment-input-a11y")
            .focus();
        } else if (this.errors["termsAndConditionsDelivery"]) {
          document
            .getElementById("gxg-terms-conditions-delivery-input-a11y")
            .focus();
        }
      } else if (this.buyerInfoTermsErrorFound) {
        // Buyer information
        document.getElementById("buyer-info-terms-section").scrollIntoView();
        if (this.errors["country"]) {
          document.getElementById("gxg-buyer-info-country-a11y").focus();
        } else if (this.errors["firstName"]) {
          document.getElementById("gxg-buyer-info-first-name-a11y").focus();
        } else if (this.errors["lastName"]) {
          document.getElementById("gxg-buyer-info-last-name-a11y").focus();
        } else if (this.errors["streetAddress"]) {
          document.getElementById("gxg-buyer-info-street-address-a11y").focus();
        } else if (this.errors["line1Addr"]) {
          document.getElementById("gxg-buyer-info-address1-a11y").focus();
        } else if (this.errors["city"]) {
          document.getElementById("gxg-buyer-info-city-a11y").focus();
        } else if (this.errors["stateId"]) {
          document.getElementById("gxg-buyer-info-state-a11y").focus();
        } else if (this.errors["zipCode"]) {
          document.getElementById("gxg-buyer-info-zip-code-a11y").focus();
        }
      } else {
        // Terms and conditions (no need to scroll here)
        if (this.errors["agreement"]) {
          document.getElementById("gxg-terms-conditions-a11y").focus();
        }
      }
      // Reset fields
      this.shipmentPartiesErrorFound = false;
      this.commercialShipmentErrorFound = false;
      this.buyerInfoTermsErrorFound = false;
      this.setState({
        focusOnError: false
      });
    }
    // Focus on the top element on the page after loading
    if (this.state.focusOnTop && this.state.isLoaded) {
      document.getElementById("shipment-parties-section").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
  }

  // Add "Select" as an option and move the United States to the top of the list
  reorderCountryList = (countryList) => {
    let unitedStates = [];
    unitedStates.push({
      countryDbId: 0,
      countryName: "Select"
    });
    let unitedStatesIndex = -1;
    for (let i in countryList) {
      if (countryList[i].countryDbId === 840) {
        unitedStates.push(countryList[i]);
        unitedStatesIndex = i;
        break;
      }
    }
    countryList.splice(unitedStatesIndex, 1);
    countryList = unitedStates.concat(countryList);
    return countryList;
  };

  changeItemInfo = (input) => (event) => {
    this.setState({ [input]: event });
  };

  changeInvoiceDate = (notUsed) => (event) => {
    this.setState({
      invoiceDate: event
    });
  };

  isBuyerDomesticInitially = (countryId) => {
    return countryId ? String(countryId) === "840" : false;
  };

  isBuyerDomestic = () => {
    return this.state.countryId === "840";
  };

  validateGetFinalPrice = (e) => {
    let errorsFound = false;
    let errorObject = {};
    // Shipping info and parties to transaction
    if (!this.state.purposeOfShipment) {
      errorObject["purposeOfShipment"] = "Please enter purpose of shipment.";
      errorsFound = true;
      this.shipmentPartiesErrorFound = true;
    }
    if (!this.state.partiesToTransaction) {
      errorObject["partiesToTransaction"] =
        "Please select parties to transaction.";
      errorsFound = true;
      this.shipmentPartiesErrorFound = true;
    }
    // Commercial shipment
    if (this.state.commercialShipment) {
      if (!this.state.invoiceDate) {
        errorObject["invoiceDate"] = "Please select an invoice date.";
        errorsFound = true;
        this.commercialShipmentErrorFound = true;
      }
      if (!this.state.invoiceNbr) {
        errorObject["invoiceNumber"] = "Provide an invoice number.";
        errorsFound = true;
        this.commercialShipmentErrorFound = true;
      }
      if (!this.state.paymentTerms) {
        errorObject["termsAndConditions"] =
          "Please enter the terms and conditions of payment.";
        errorsFound = true;
        this.commercialShipmentErrorFound = true;
      }
      if (!this.state.deliveryTerms) {
        errorObject["termsAndConditionsDelivery"] =
          "Please enter the terms and conditions of delivery.";
        errorsFound = true;
        this.commercialShipmentErrorFound = true;
      }
    }
    // Buyer information
    if (this.state.differentBuyer === true) {
      if (this.state.countryId === undefined || this.state.countryId === "0") {
        errorObject["country"] = " Please select country.";
        errorsFound = true;
        this.buyerInfoTermsErrorFound = true;
      }
      if (!this.state.firstName) {
        errorObject["firstName"] = "Please enter a first name.";
        errorsFound = true;
        this.buyerInfoTermsErrorFound = true;
      }
      if (!this.state.lastName) {
        errorObject["lastName"] = "Please enter a last name.";
        errorsFound = true;
        this.buyerInfoTermsErrorFound = true;
      }
      if (this.isBuyerDomestic() && !this.state.streetAddress) {
        errorObject["streetAddress"] = "Provide a valid street address.";
        errorsFound = true;
        this.buyerInfoTermsErrorFound = true;
      }
      if (!this.isBuyerDomestic() && !this.state.line1Addr) {
        errorObject["line1Addr"] = "Provide a valid Address 1.";
        errorsFound = true;
        this.buyerInfoTermsErrorFound = true;
      }
      if (!this.state.cityName) {
        errorObject["city"] = "Please enter city.";
        errorsFound = true;
        this.buyerInfoTermsErrorFound = true;
      }
      if (
        this.isBuyerDomestic() &&
        (!this.state.stateId || this.state.stateId === "-1")
      ) {
        errorObject["stateId"] = "Please select a state.";
        errorsFound = true;
        this.buyerInfoTermsErrorFound = true;
      }
      if (this.isBuyerDomestic() && !this.state.zipCode) {
        errorObject["zipCode"] = "Please enter ZIP Code™.";
        errorsFound = true;
        this.buyerInfoTermsErrorFound = true;
      }
    }
    // Terms and conditions
    if (!this.state.termsConditions) {
      errorObject["agreement"] = "Please accept the Terms and Conditions.";
      errorsFound = true;
      // No need to scroll to a section here
    }

    this.errors = errorObject;
    this.setInputErrors("errorObject")(errorObject);

    return errorsFound;
  };

  getFinalPrice = (e) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    if (!this.validateGetFinalPrice()) {
      let dataToSend = { cnsCart: {} };
      dataToSend["cnsCart"]["labelGxg"] = {};
      dataToSend["cnsCart"]["labelGxg"] = {};
      dataToSend["cnsCart"]["labelCustDec"] = {};
      // Shipping info and parties to transaction
      dataToSend["cnsCart"]["labelGxg"][
        "purposeOfShipment"
      ] = this.state.purposeOfShipment;
      dataToSend["cnsCart"]["labelGxg"]["ultimateDestinationId"] = String(
        this.state.ultimateDestinationId
      );
      dataToSend["cnsCart"]["labelGxg"][
        "partiesToTransaction"
      ] = this.state.partiesToTransaction;
      dataToSend["cnsCart"]["labelGxg"]["packingCost"] =
        this.state.packingCost || "0";
      // Commercial shipment
      dataToSend["cnsCart"]["labelGxg"]["commercialShipmentInd"] =
        this.state.commercialShipment === true ? "Y" : "N";
      if (this.state.commercialShipment) {
        dataToSend["cnsCart"]["labelGxg"]["invoiceDate"] = moment(
          this.state.invoiceDate
        ).format("YYYY-MM-DD");
        dataToSend["cnsCart"]["labelCustDec"][
          "invoiceNbr"
        ] = this.state.invoiceNbr;
        dataToSend["cnsCart"]["labelGxg"][
          "customerOrderNumber"
        ] = this.state.customerOrderNumber;
        dataToSend["cnsCart"]["labelGxg"][
          "paymentTerms"
        ] = this.state.paymentTerms;
        dataToSend["cnsCart"]["labelGxg"][
          "deliveryTerms"
        ] = this.state.deliveryTerms;
      }
      // Buyer information
      dataToSend["buyerRecipientDifference"] = this.state.differentBuyer
        ? "Y"
        : "N";
      if (this.state.differentBuyer) {
        dataToSend["cnsCart"]["buyerAddress"] = {};
        dataToSend["cnsCart"]["buyerAddress"]["countryId"] = this.state
          .countryId
          ? this.state.countryId
          : "";
        dataToSend["cnsCart"]["buyerAddress"]["firstName"] = this.state
          .firstName
          ? this.state.firstName
          : "";
        dataToSend["cnsCart"]["buyerAddress"]["middleInit"] = this.state
          .middleInit
          ? this.state.middleInit
          : "";
        dataToSend["cnsCart"]["buyerAddress"]["lastName"] = this.state.lastName
          ? this.state.lastName
          : "";
        if (this.isBuyerDomestic()) {
          dataToSend["cnsCart"]["buyerAddress"]["line1Addr"] = this.state
            .streetAddress // Stored as line1Addr in the backend
            ? this.state.streetAddress
            : "";
          dataToSend["cnsCart"]["buyerAddress"]["line2Addr"] = this.state
            .aptSuiteOther // Stored as line2Addr in the backend
            ? this.state.aptSuiteOther
            : "";
        } else {
          dataToSend["cnsCart"]["buyerAddress"]["line1Addr"] = this.state
            .line1Addr
            ? this.state.line1Addr
            : "";
          dataToSend["cnsCart"]["buyerAddress"]["line2Addr"] = this.state
            .line2Addr
            ? this.state.line2Addr
            : "";
          dataToSend["cnsCart"]["buyerAddress"]["line3Addr"] = this.state
            .line3Addr
            ? this.state.line3Addr
            : "";
          dataToSend["cnsCart"]["buyerAddress"]["province"] = this.state
            .province
            ? this.state.province
            : "";
        }
        dataToSend["cnsCart"]["buyerAddress"]["cityName"] = this.state.cityName
          ? this.state.cityName
          : "";
        if (this.isBuyerDomestic()) {
          dataToSend["cnsCart"]["buyerAddress"]["stateId"] = this.state.stateId
            ? this.state.stateId
            : "";
          dataToSend["cnsCart"]["buyerAddress"]["postalCode"] = this.state
            .zipCode // Stored as postalCode in the backend
            ? this.state.zipCode
            : "";
        } else {
          dataToSend["cnsCart"]["buyerAddress"]["postalCode"] = this.state
            .postalCode
            ? this.state.postalCode
            : "";
        }
        if (this.isBuyerDomestic() && this.state.stateId === "49") {
          dataToSend["cnsCart"]["buyerAddress"]["urbanizationCode"] = this.state
            .urbanizationCode
            ? this.state.urbanizationCode
            : "";
        }
      }
      dataToSend["termsConditions"] = this.state.termsConditions ? "Y" : "N";
      // Call the endpoint
      axios
        .post(
          "/go/cnsrest/saveGxgCommerceInfo?holdCartId=" +
            this.props.location.state.cnsCartId,
          dataToSend
        )
        .then((response) => {
          if (
            Object.keys(response.data.fieldErrors).length === 0 &&
            response.data.actionErrors.length === 0
          ) {
            this.props.toggleSpinner(false);
            this.props.history.push("/gxgGuaranteedDate", {
              cnsCartId: this.props.location.state.cnsCartId
            });
          } else {
            this.props.toggleSpinner(false);
            window.scrollTo(0, 0);
            this.setGlobalError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.toggleSpinner(false);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        });
    } else {
      this.props.toggleSpinner(false);
      this.setState({
        focusOnError: true
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        />
        {this.state.isLoaded ? (
          <div
            className="Step_Nine_Container"
            id="shipment-parties-section"
            tabIndex="-1"
          >
            <div className="container-fluid">
              <h2 className="d-md-none responsive-header">Create a Label</h2>
              <p>*Indicates a required field.</p>
              <h2 className="normal step-nine-header">
                Step 9:<strong> Additional GXG Information</strong>
              </h2>
              {this.state.fetchedGxgCommerceInfo.commodityQuestions.map(
                (item, keys) => (
                  <div key={item.packageItemId} className="row">
                    <div className="col-12 form-group step-seven-click-ship">
                      <label>Item {keys + 1}</label>
                      <p className="item-one-label">{item.commodityName}</p>
                    </div>
                  </div>
                )
              )}
              <div className="row">
                <div className="col-12 form-group total-declared-value-wrapper">
                  <p>
                    <strong>
                      Is the total declared value for Customs of the shipment
                      more than 20 Canadian Dollars, approximately $20 US?
                    </strong>
                  </p>
                  <div className="radio-buttons">
                    <div className="radio-wrap">
                      <div className="radio-container">
                        <input
                          id="first-radio"
                          type="radio"
                          className="radio-button first"
                          name="total-declared-value-rb"
                        />
                        <label htmlFor="first-radio">Yes</label>
                      </div>
                      <div className="radio-container">
                        <input
                          id="second-radio"
                          type="radio"
                          className="radio-button second"
                          name="total-declared-value-rb"
                        />
                        <label htmlFor="second-radio">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 form-group">
                  <p>
                    <strong>Shipping Information</strong>
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className={
                    "col-12 col-md-4 required-field shipping-purpose-wrapper" +
                    (this.state.errors.purposeOfShipment !== "" ? " error" : "")
                  }
                >
                  <AssistiveError
                    inputId="gxg-purpose-of-shipment"
                    errorMessage={this.state.errors.purposeOfShipment}
                  />
                  <label htmlFor="gxg-purpose-of-shipment">
                    *Purpose of Shipment{" "}
                    <PurposeOfShipmentPopover targetName="purpose-of-shipment-popover-id" />
                  </label>
                  <input
                    tabIndex="0"
                    type="text"
                    id="gxg-purpose-of-shipment"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({
                        purposeOfShipment: e.target.value
                      });
                      this.state.errors.purposeOfShipment !== ""
                        ? this.clearError("purposeOfShipment")
                        : this.clearError("");
                    }}
                    value={this.state.purposeOfShipment}
                  />
                  <span className="error-message">
                    {this.state.errors.purposeOfShipment}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 form-group required-field">
                  <label htmlFor="gxg-country-of-ultimate-destination">
                    Country of Ultimate Destination{" "}
                    <CountryUltimateDestinationPopover targetName="country-ultimate-destination-popover-id" />
                  </label>
                  <select
                    id="gxg-country-of-ultimate-destination"
                    className="form-control country-dropdown dropdown"
                    onChange={(e) =>
                      this.changeItemInfo("ultimateDestinationId")(
                        e.target.value
                      )
                    }
                    value={
                      this.state.ultimateDestinationId
                        ? this.state.ultimateDestinationId
                        : "0"
                    }
                  >
                    {this.state.countryList.map((country) => (
                      <option
                        key={country.countryDbId}
                        value={country.countryDbId}
                      >
                        {country.countryName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-12 form-group">
                  <p>
                    <strong>
                      *Parties to Transaction{" "}
                      <PartiesToTransactionPopover targetName="parties-to-transaction-popover-id" />
                    </strong>
                  </p>
                </div>
                <div className="col-12 radio-buttons">
                  <div
                    className={
                      "radio-wrap required-field" +
                      (this.state.errors.partiesToTransaction !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="related-radio"
                      errorMessage={this.state.errors.partiesToTransaction}
                    />
                    <div className="radio-container">
                      <input
                        id="related-radio"
                        type="radio"
                        className="radio-button related"
                        name="parties-to-transaction-rb"
                        checked={this.state.partiesToTransaction === "R"}
                        onChange={(e) => {
                          this.setState({
                            partiesToTransaction: "R"
                          });
                          this.state.errors.partiesToTransaction !== ""
                            ? this.clearError("partiesToTransaction")
                            : this.clearError("");
                        }}
                      />
                      <label htmlFor="related-radio">
                        Related - the sender and the recipient are with the same
                        company or family.
                      </label>
                    </div>
                    <div className="radio-container">
                      <input
                        id="unrelated-radio"
                        type="radio"
                        className="radio-button unrelated"
                        name="parties-to-transaction-rb"
                        checked={this.state.partiesToTransaction === "U"}
                        onChange={(e) => {
                          this.setState({
                            partiesToTransaction: "U"
                          });
                          this.state.errors.partiesToTransaction !== ""
                            ? this.clearError("partiesToTransaction")
                            : this.clearError("");
                        }}
                      />
                      <label htmlFor="unrelated-radio">
                        Unrelated - the sender and the recipient are not with
                        the same company or family.
                      </label>
                    </div>
                    <span className="error-message">
                      {this.state.errors.partiesToTransaction}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 form-group packing-cost-wrapper">
                  <label htmlFor="gxg-packing-cost">Packing Cost</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      tabIndex="0"
                      type="text"
                      id="gxg-packing-cost"
                      className="form-control prepend"
                      onChange={(e) =>
                        this.setState({
                          packingCost: e.target.value
                        })
                      }
                      value={this.state.packingCost}
                    />
                  </div>
                </div>
              </div>
              <div className="row" id="commercial-shipment-section">
                <div className="col-12 commercial-shipment-cb-wrapper">
                  <label
                    className="checkbox-component commercial-shipment"
                    htmlFor="commercial-shipment-check"
                  >
                    <input
                      type="checkbox"
                      id="commercial-shipment-check"
                      checked={this.state.commercialShipment}
                      onChange={(e) =>
                        this.setState({
                          commercialShipment: e.currentTarget.checked
                        })
                      }
                    />
                    <span
                      className="checkbox"
                      name="commercial-shipment-check"
                    />
                    Commercial Shipment{" "}
                    <CommercialShipmentPopover targetName="commercial-shipment-popover-id" />
                  </label>
                </div>
              </div>{" "}
              {this.state.commercialShipment ? (
                <div className="row commercial-shipment-input">
                  <div className="col-12 col-md-5 col-lg-4">
                    <div className="row">
                      <AssistiveError
                        inputId="input-date"
                        errorMessage={this.state.errors.invoiceDate}
                      />
                      <div
                        className={
                          "col-12 required-field" +
                          (this.state.errors.invoiceDate !== "" ? " error" : "")
                        }
                        onFocus={(e) =>
                          this.state.errors.invoiceDate !== ""
                            ? this.clearError("invoiceDate")
                            : this.clearError("")
                        }
                      >
                        <label
                          htmlFor="input-date"
                          className="choose-shipping-date-label"
                        >
                          *Invoice Date
                        </label>
                        <DateRangeSelector
                          changeNonNestedFields={this.changeInvoiceDate}
                          currentShippingDates={[]}
                          isOutsideRange={(day) => moment().diff(day) > 5}
                          shippingDate={this.state.invoiceDate}
                        />
                        <span className="error-message">
                          {this.state.errors.invoiceDate}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-12 form-group required-field" +
                          (this.state.errors.invoiceNumber !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="gxg-invoice-number"
                          errorMessage={this.state.errors.invoiceNumber}
                        />
                        <label htmlFor="gxg-invoice-number">
                          *Invoice Number
                        </label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="gxg-invoice-number"
                          className="form-control"
                          onChange={(e) => {
                            this.setState({
                              invoiceNbr: e.target.value
                            });
                            this.state.errors.invoiceNumber !== ""
                              ? this.clearError("invoiceNumber")
                              : this.clearError("");
                          }}
                          value={this.state.invoiceNbr}
                        />
                        <span className="error-message">
                          {this.state.errors.invoiceNumber}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 form-group">
                        <label htmlFor="gxg-customer-order-number">
                          Customer Order Number
                        </label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="gxg-customer-order-number"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({
                              customerOrderNumber: e.target.value
                            })
                          }
                          value={this.state.customerOrderNumber}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-12 form-group required-field" +
                          (this.state.errors.termsAndConditions !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="gxg-terms-conditions-payment-input"
                          errorMessage={this.state.errors.termsAndConditions}
                        />
                        <label
                          htmlFor="gxg-terms-conditions-payment-input"
                          className="inputLabel"
                        >
                          *Terms and Conditions of Payment{" "}
                          <TermsConditionsPaymentPopover targetName="terms-conditions-payment-popover-id" />
                        </label>
                        <textarea
                          id="gxg-terms-conditions-payment-input"
                          className="form-control extra-input-field"
                          rows="3"
                          maxLength="100"
                          onChange={(e) => {
                            this.setState({
                              paymentTerms: e.target.value
                            });
                            this.state.errors.termsAndConditions !== ""
                              ? this.clearError("termsAndConditions")
                              : this.clearError("");
                          }}
                          value={this.state.paymentTerms}
                        />
                        <span className="error-message">
                          {this.state.errors.termsAndConditions}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-12 form-group required-field" +
                          (this.state.errors.termsAndConditionsDelivery !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="gxg-terms-conditions-delivery-input"
                          errorMessage={
                            this.state.errors.termsAndConditionsDelivery
                          }
                        />
                        <label htmlFor="gxg-terms-conditions-delivery-input">
                          *Terms and Conditions of Delivery{" "}
                          <TermsConditionsDeliveryPopover targetName="terms-conditions-delivery-popover-id" />
                        </label>
                        <select
                          id="gxg-terms-conditions-delivery-input"
                          className="form-control dropdown"
                          onChange={(e) => {
                            this.setState({
                              deliveryTerms: e.target.value
                            });
                            this.state.errors.termsAndConditionsDelivery !== ""
                              ? this.clearError("termsAndConditionsDelivery")
                              : this.clearError("");
                          }}
                          value={this.state.deliveryTerms}
                        >
                          <option value="">Select one</option>
                          <option value="CPT">Carriage Paid To</option>
                          <option value="CIP">
                            Carriage, Insurance, Paid To
                          </option>
                          <option value="DAF">Delivery at Frontier</option>
                          <option value="DDU">Delivery Duty Unpaid</option>
                          <option value="OTHER">Other</option>
                        </select>
                        <span className="error-message">
                          {this.state.errors.termsAndConditionsDelivery}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment />
              )}
              <div className="row">
                <div className="col-12 horizontal-line-container">
                  <hr className="horizontal-line" />
                </div>
              </div>
              <div className="row" id="buyer-info-terms-section">
                <div className="col-12 recipients-info-wrapper">
                  <label>Recipient Information</label>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.fetchedGxgCommerceInfo.cnsCart
                        .deliveryAddress.displayAddress
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 check-boxes">
                  <div className="checkbox-wrap">
                    <div className="row">
                      <div className="col-12">
                        <label
                          className="checkbox-component"
                          htmlFor="diff-buyer-info"
                        >
                          <input
                            type="checkbox"
                            id="diff-buyer-info"
                            onChange={(e) =>
                              this.setState({
                                differentBuyer: e.currentTarget.checked
                              })
                            }
                            checked={this.state.differentBuyer}
                          />
                          <span className="checkbox" />
                          The buyer information is different than the recipient
                          information
                        </label>
                      </div>
                    </div>
                    {this.state.differentBuyer ? (
                      <div className="row diff-buyer-info-input">
                        <div className="col-12 col-md-11 col-lg-10">
                          <div className="row">
                            <div className="col-12">
                              <p>
                                <strong>Buyer Information</strong>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className={
                                "col-12 col-md-6 form-group required-field" +
                                (this.state.errors.country !== ""
                                  ? " error"
                                  : "")
                              }
                            >
                              <AssistiveError
                                inputId="gxg-buyer-info-country"
                                errorMessage={this.state.errors.country}
                              />
                              <label htmlFor="gxg-buyer-info-country">
                                *Country
                              </label>
                              <select
                                id="gxg-buyer-info-country"
                                className="form-control country-dropdown dropdown"
                                onChange={(e) => {
                                  this.changeItemInfo("countryId")(
                                    e.target.value
                                  );
                                  this.state.errors.country !== ""
                                    ? this.clearError("country")
                                    : this.clearError("");
                                }}
                                value={
                                  this.state.countryId
                                    ? this.state.countryId
                                    : "0"
                                }
                              >
                                {this.state.countryList.map((country) => (
                                  <option
                                    key={country.countryDbId}
                                    value={country.countryDbId}
                                  >
                                    {country.countryName}
                                  </option>
                                ))}
                              </select>
                              <span className="error-message">
                                {this.state.errors.country}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className={
                                "col-9 col-md-5 form-group required-field" +
                                (this.state.errors.firstName !== ""
                                  ? " error"
                                  : "")
                              }
                            >
                              <AssistiveError
                                inputId="gxg-buyer-info-first-name"
                                errorMessage={this.state.errors.firstName}
                              />
                              <label htmlFor="gxg-buyer-info-first-name">
                                *First Name
                              </label>
                              <input
                                tabIndex="0"
                                type="text"
                                id="gxg-buyer-info-first-name"
                                className="form-control"
                                placeholder="First"
                                onChange={(e) => {
                                  this.setState({
                                    firstName: e.target.value
                                  });
                                  this.state.errors.firstName !== ""
                                    ? this.clearError("firstName")
                                    : this.clearError("");
                                }}
                                value={this.state.firstName}
                              />
                              <span className="error-message">
                                {this.state.errors.firstName}
                              </span>{" "}
                            </div>
                            <div className="col-3 col-md-1 form-group">
                              <label htmlFor="gxg-buyer-info-mi">MI</label>
                              <input
                                tabIndex="0"
                                type="text"
                                id="gxg-buyer-info-mi"
                                className="form-control"
                                maxLength="1"
                                onChange={(e) =>
                                  this.setState({
                                    middleInit: e.target.value
                                  })
                                }
                                value={this.state.middleInit}
                              />
                            </div>
                            <div
                              className={
                                "col-12 col-md-6 form-group required-field" +
                                (this.state.errors.lastName !== ""
                                  ? " error"
                                  : "")
                              }
                            >
                              <AssistiveError
                                inputId="gxg-buyer-info-last-name"
                                errorMessage={this.state.errors.lastName}
                              />
                              <label htmlFor="gxg-buyer-info-last-name">
                                *Last Name
                              </label>
                              <input
                                tabIndex="0"
                                type="text"
                                id="gxg-buyer-info-last-name"
                                className="form-control"
                                placeholder="Last"
                                onChange={(e) => {
                                  this.setState({
                                    lastName: e.target.value
                                  });
                                  this.state.errors.lastName !== ""
                                    ? this.clearError("lastName")
                                    : this.clearError("");
                                }}
                                value={this.state.lastName}
                              />
                              <span className="error-message">
                                {this.state.errors.lastName}
                              </span>{" "}
                            </div>
                          </div>
                          {this.isBuyerDomestic() ? (
                            <div className="row">
                              <div
                                className={
                                  "col-12 col-md-6 form-group required-field" +
                                  (this.state.errors.streetAddress !== ""
                                    ? " error"
                                    : "")
                                }
                              >
                                <AssistiveError
                                  inputId="gxg-buyer-info-street-address"
                                  errorMessage={this.state.errors.streetAddress}
                                />
                                <label htmlFor="gxg-buyer-info-street-address">
                                  *Street Address
                                </label>
                                <input
                                  tabIndex="0"
                                  type="text"
                                  id="gxg-buyer-info-street-address"
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      streetAddress: e.target.value
                                    });
                                    this.state.errors.streetAddress !== ""
                                      ? this.clearError("streetAddress")
                                      : this.clearError("");
                                  }}
                                  value={this.state.streetAddress}
                                />
                                <span className="error-message">
                                  {this.state.errors.streetAddress}
                                </span>
                              </div>
                              <div className="col-12 col-md-6 form-group">
                                <label htmlFor="gxg-buyer-info-apt">
                                  Apt/Suite/Other
                                </label>
                                <input
                                  tabIndex="0"
                                  type="text"
                                  id="gxg-buyer-info-apt"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.setState({
                                      aptSuiteOther: e.target.value
                                    })
                                  }
                                  value={this.state.aptSuiteOther}
                                />
                              </div>
                            </div>
                          ) : (
                            <React.Fragment>
                              <div className="row">
                                <div
                                  className={
                                    "col-12 col-md-6 form-group required-field" +
                                    (this.state.errors.line1Addr !== ""
                                      ? " error"
                                      : "")
                                  }
                                >
                                  <AssistiveError
                                    inputId="gxg-buyer-info-address1"
                                    errorMessage={this.state.errors.line1Addr}
                                  />
                                  <label htmlFor="gxg-buyer-info-address1">
                                    *Address 1
                                  </label>
                                  <input
                                    tabIndex="0"
                                    type="text"
                                    id="gxg-buyer-info-address1"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.setState({
                                        line1Addr: e.target.value
                                      });
                                      this.state.errors.line1Addr !== ""
                                        ? this.clearError("line1Addr")
                                        : this.clearError("");
                                    }}
                                    value={this.state.line1Addr}
                                  />
                                  <span className="error-message">
                                    {this.state.errors.line1Addr}
                                  </span>
                                </div>
                                <div className="col-12 col-md-6 form-group">
                                  <label htmlFor="gxg-buyer-info-address2">
                                    Address 2
                                  </label>
                                  <input
                                    tabIndex="0"
                                    type="text"
                                    id="gxg-buyer-info-address2"
                                    className="form-control"
                                    onChange={(e) =>
                                      this.setState({
                                        line2Addr: e.target.value
                                      })
                                    }
                                    value={this.state.line2Addr}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 form-group">
                                  <label htmlFor="gxg-buyer-info-address3">
                                    Address 3
                                  </label>
                                  <input
                                    tabIndex="0"
                                    type="text"
                                    id="gxg-buyer-info-address3"
                                    className="form-control"
                                    onChange={(e) =>
                                      this.setState({
                                        line3Addr: e.target.value
                                      })
                                    }
                                    value={this.state.line3Addr}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 form-group">
                                  <label htmlFor="gxg-buyer-info-province">
                                    Province
                                  </label>
                                  <input
                                    tabIndex="0"
                                    type="text"
                                    id="gxg-buyer-info-province"
                                    className="form-control"
                                    onChange={(e) =>
                                      this.setState({
                                        province: e.target.value
                                      })
                                    }
                                    value={this.state.province}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                          <div className="row">
                            <div
                              className={
                                "col-12 col-md-6 form-group required-field" +
                                (this.state.errors.city !== "" ? " error" : "")
                              }
                            >
                              <AssistiveError
                                inputId="gxg-buyer-info-city"
                                errorMessage={this.state.errors.city}
                              />
                              <label htmlFor="gxg-buyer-info-city">*City</label>
                              <input
                                tabIndex="0"
                                type="text"
                                id="gxg-buyer-info-city"
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    cityName: e.target.value
                                  });
                                  this.state.errors.city !== ""
                                    ? this.clearError("city")
                                    : this.clearError("");
                                }}
                                value={this.state.cityName}
                              />
                              <span className="error-message">
                                {this.state.errors.city}
                              </span>
                            </div>
                            {this.isBuyerDomestic() ? (
                              <React.Fragment>
                                <div
                                  className={
                                    "col-6 col-md-3 form-group required-field step-two-state" +
                                    (this.state.errors.stateId !== ""
                                      ? " error"
                                      : "")
                                  }
                                >
                                  <AssistiveError
                                    inputId="gxg-buyer-info-state"
                                    errorMessage={this.state.errors.stateId}
                                  />
                                  <label htmlFor="gxg-buyer-info-state">
                                    *State
                                  </label>
                                  <select
                                    id="gxg-buyer-info-state"
                                    className="form-control state-dropdown dropdown"
                                    onChange={(e) => {
                                      this.setState({
                                        stateId: e.target.value
                                      });
                                      this.state.errors.stateId !== ""
                                        ? this.clearError("stateId")
                                        : this.clearError("");
                                    }}
                                    value={this.state.stateId}
                                  >
                                    <StatesTerritoriesList />
                                  </select>
                                  <span className="error-message">
                                    {this.state.errors.stateId}
                                  </span>
                                </div>
                                <div
                                  className={
                                    "col-6 col-md-3 form-group step-two-zip required-field" +
                                    (this.state.errors.zipCode !== ""
                                      ? " error"
                                      : "")
                                  }
                                >
                                  <AssistiveError
                                    inputId="gxg-buyer-info-zip-code"
                                    errorMessage={this.state.errors.zipCode}
                                  />
                                  <label htmlFor="gxg-buyer-info-zip-code">
                                    *ZIP Code<sup>™</sup>
                                  </label>
                                  <input
                                    tabIndex="0"
                                    type="number"
                                    id="gxg-buyer-info-zip-code"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.setState({
                                        zipCode: e.target.value
                                      });
                                      this.state.errors.zipCode !== ""
                                        ? this.clearError("zipCode")
                                        : this.clearError("");
                                    }}
                                    value={this.state.zipCode}
                                  />
                                  <span className="error-message">
                                    {this.state.errors.zipCode}
                                  </span>
                                </div>
                              </React.Fragment>
                            ) : (
                              <div className="col-12 col-md-6 form-group">
                                <label htmlFor="gxg-buyer-info-postal-code">
                                  Postal Code
                                </label>
                                <input
                                  tabIndex="0"
                                  type="text"
                                  id="gxg-buyer-info-postal-code"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.setState({
                                      postalCode: e.target.value
                                    })
                                  }
                                  value={this.state.postalCode}
                                />
                              </div>
                            )}
                          </div>
                          {this.isBuyerDomestic() &&
                          this.state.stateId === "49" ? (
                            <div className="row">
                              <div className="col-12 col-md-6 form-group urbanization-code-input">
                                <label htmlFor="gxg-buyer-info-urbanization-code">
                                  Urbanization Code{" "}
                                  <UrbanizationCodesPopover targetName="urbanization-codes-popover-id" />
                                </label>
                                <input
                                  tabIndex="0"
                                  type="text"
                                  id="gxg-buyer-info-urbanization-code"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.setState({
                                      urbanizationCode: e.target.value
                                    })
                                  }
                                  value={this.state.urbanizationCode}
                                />
                              </div>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                          {/* <div className="row">
                            <div className="col-12 col-md-6 form-group">
                              <label>Phone Number</label>
                              <input tabIndex="0" type="text" className="form-control" placeholder="(000) 000-0000" />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <React.Fragment />
                    )}

                    <div className="row">
                      <div className="col-12">
                        <AssistiveError
                          inputId="gxg-terms-conditions"
                          errorMessage={this.state.errors.agreement}
                        />
                        <label
                          className="checkbox-component"
                          htmlFor="gxg-terms-conditions"
                        >
                          <input
                            type="checkbox"
                            id="gxg-terms-conditions"
                            onClick={(e) =>
                              this.state.errors.agreement !== ""
                                ? this.clearError("agreement")
                                : this.clearError("")
                            }
                            onChange={(e) =>
                              this.setState({
                                termsConditions: e.currentTarget.checked
                              })
                            }
                            checked={this.state.termsConditions}
                          />
                          <span className="checkbox" />I have read and agree to
                          the{" "}
                          <TermsConditionsGxgModal
                            buttonLabel={"USPS GXG Terms and Conditions"}
                          />
                        </label>
                        <span className="error-message">
                          {this.state.errors.agreement}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 button-wrapper step9-buttons">
                  <div className="button-container">
                    <a
                      href="/gxgGuaranteedDate"
                      role="button"
                      className="btn-primary"
                      onClick={(e) => this.getFinalPrice(e)}
                    >
                      Get Date and Final Price
                    </a>
                  </div>
                  <div className="button-container">
                    <a
                      href="/labelInformation"
                      role="button"
                      className="btn-primary button--white"
                      onClick={this.editLabel}
                    >
                      Edit Label
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(GXGCommerceInformation);
