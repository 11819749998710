import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const UrbanizationCodesPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Puerto Rican addresses include an urbanization code, often the name of a
        neighborhood.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Urbanization Information Icon"
      header="Urbanization Codes"
      content={content}
    />
  );
};

export default UrbanizationCodesPopover;
