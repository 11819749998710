import React, { useEffect, useRef } from "react";
import { Popover, Modal } from "reactstrap";

const PostOfficeHoursPopover = (props) => {
  const {
    targetName,
    setPostOfficeHoursIconId,
    poLocationHours,
    infoIconFacilityId
  } = props;

  const popoverTextRef = useRef(null);

  useEffect(() => {
    // Set the focus to inside the popover
    if (popoverTextRef.current) {
      const xPosition = window.scrollX;
      const yPosition = window.scrollY;
      popoverTextRef.current.focus();
      // This is to maintain the current screen position instead of directing
      // the user to the top of the page
      window.scrollTo(xPosition, yPosition);
    }
  }, [targetName]);

  const toggle = (e) => {
    e.preventDefault();
    setPostOfficeHoursIconId(undefined, infoIconFacilityId);
  };

  const getDay = (day) => {
    return day === "MO"
      ? "Mon"
      : day === "TU"
      ? "Tue"
      : day === "WE"
      ? "Wed"
      : day === "TH"
      ? "Thu"
      : day === "FR"
      ? "Fri"
      : day === "SA"
      ? "Sat"
      : day === "SU"
      ? "Sun"
      : "";
  };

  const getTimes = (times) => {
    if (times && times.length > 0) {
      // Opening
      let openingHour = times[0].open.substring(0, 2);
      const openingMinute = times[0].open.substring(2, 5);
      const openingTime =
        (openingHour > 12 ? String(openingHour - 12) : openingHour) +
        openingMinute +
        " " +
        (openingHour < 12 ? "am" : "pm");
      // Closing
      let closingHour = times[0].close.substring(0, 2);
      const closingMinute = times[0].close.substring(2, 5);
      const closingTime =
        (closingHour > 12 ? String(closingHour - 12) : closingHour) +
        closingMinute +
        " " +
        (closingHour < 12 ? "am" : "pm");

      return openingTime + " - " + closingTime;
    } else {
      return "Closed";
    }
  };

  return (
    <React.Fragment>
      {window.innerWidth > 760 ? (
        <Popover
          placement="auto"
          trigger="legacy"
          isOpen={true}
          target={targetName}
          toggle={(e) => toggle(e)}
        >
          <div className="post-office-hours-popover">
            <div className="popover-wrapper">
              <div className="popover-header">
                <button
                  type="button"
                  className="close button-link"
                  data-dismiss="popover"
                  tabIndex="0"
                  onClick={toggle}
                  ref={popoverTextRef}
                >
                  <span className="visuallyhidden">Close Popover</span>
                </button>
                <h4>Post Office Hours</h4>
              </div>
              {poLocationHours &&
                poLocationHours.map((item) => (
                  <React.Fragment key={item.day}>
                    <p>{getDay(item.day)}</p>
                    <p className="opening-closing-hours">
                      {getTimes(item.times)}
                    </p>
                  </React.Fragment>
                ))}
            </div>
          </div>
        </Popover>
      ) : (
        <React.Fragment>
          <Modal isOpen={true} toggle={toggle}>
            <div className="modal-content modal-container">
              <div className="modal-header">
                <button
                  type="button"
                  className="close button-link"
                  data-dismiss="modal"
                  tabIndex="0"
                  onClick={toggle}
                >
                  <span className="visuallyhidden">Close Modal</span>
                </button>
                <h3 className="modal-title">Post Office Hours</h3>
              </div>
              <div className="modal-body">
                <div className="body-content">
                  {poLocationHours &&
                    poLocationHours.map((item) => (
                      <React.Fragment>
                        <p>{getDay(item.day)}</p>
                        <p className="opening-closing-hours">
                          {getTimes(item.times)}
                        </p>
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PostOfficeHoursPopover;
