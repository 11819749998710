import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { withRouter } from "react-router-dom";

const TimeOutModal = (props) => {
  const { className, test, resetTimeOutModal, logUserOut } = props;

  const [modal, setModal] = useState(test);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    setModal(test);
  }, [test]);

  const userSelection = () => {
    toggle();
    resetTimeOutModal();
  };

  return (
    <React.Fragment>
      <Modal isOpen={modal} id="time-out-modal" className={className}>
        <div className="modal-content modal-container">
          <div className="modal-header">
            <h4 className="modal-title">Security Timeout</h4>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <p>You are about to be logged out due to inactivity.</p>
            </div>
            <div className="modal-buttons">
              <div className="button-container">
                <button
                  type="button"
                  className="btn-primary error-display"
                  id="cnsIDXSModalSubmit"
                  tabIndex="0"
                  onClick={(e) => {
                    userSelection();
                  }}
                >
                  Stay Logged In
                </button>
              </div>
              <div className="button-container pull-right">
                <button
                  type="button"
                  className="btn-primary error-display"
                  id="cnsIDXSModalSubmit"
                  tabIndex="0"
                  onClick={(e) => {
                    logUserOut();
                  }}
                >
                  LogOut
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(TimeOutModal);
