import React, { useState } from "react";
import { Modal } from "reactstrap";

const RemoveAllBatchModal = (props) => {
  const { className, removeAllBatch } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn-primary button--white"
        data-toggle="modal"
        data-target="#cancel-batch"
        data-backdrop="static"
        tabIndex="0"
        onClick={toggle}
        aria-label="Remove All Batch Addresses"
      >
        <strong>Remove All</strong>
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        id="cancel-batch"
      >
        <div className="modal-content modal-container">
          <div className="modal-header">
            <h3 className="modal-title">
              Are you sure you want to cancel this batch order?
            </h3>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <p>This will remove all labels from this batch.</p>
            </div>
          </div>
          <div className="modal-buttons">
            <div className="button-container">
              <button
                type="button"
                className="btn-primary remove-selected-button"
                data-dismiss="modal"
                tabIndex="0"
                onClick={removeAllBatch}
              >
                Yes
              </button>
            </div>
            <div className="button-container">
              <button
                type="button"
                className="btn-primary button--white"
                data-dismiss="modal"
                tabIndex="0"
                onClick={toggle}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RemoveAllBatchModal;
