import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const AddressStandardizedPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Your delivery address and ZIP Code<sup>™</sup> are checked for proper
        Postal Service<sup>™</sup> format and standardized if necessary. We'll
        even look-up the ZIP Code<sup>™</sup> for you. For example:
      </p>
      <p>
        <strong className="standardize-address-header">As Entered</strong>
        <br />
        ABC Movers
        <br />
        1500 East Main Avenue, Suite 201
        <br />
        Springfield, VA 22162
      </p>
      <p>
        <strong className="standardize-address-header">
          After Standardization
        </strong>
        <br />
        ABC Movers
        <br />
        1500 E MAIN AVE STE 201
        <br />
        SPRINGFIELD VA 22162-1010
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Address Standardization Information Icon"
      header="Address Standardization"
      content={content}
    />
  );
};

export default AddressStandardizedPopover;
