import React, { useState } from "react";
import { Modal } from "reactstrap";
import { withRouter } from "react-router-dom";
import axios from "axios";
const CNSExtensionModal = (props) => {
  const { lookupCodes } = props;

  const [modal, setModal] = useState(true);
  const [dontShow, setDontShow] = useState(false);

  const toggle = () => setModal(!modal);

  // Sets user's preference for displaying the modal again. (check box within modal)
  const userSelection = (e, selection) => {
    e.preventDefault();
    let dataToSend = {};

    if (dontShow) {
      axios
        .post("/go/cnsrest/saveUserPreferences/lpaNotice", dataToSend, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        });
    }

    toggle();
  };

  const saveDontShow = () => {};

  return (
    <React.Fragment>
      <Modal isOpen={modal} id="postage-due-modal">
        <div className="modal-content modal-container">
          <div className="row">
            <div className="col-10">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{
                    color: "#333366",
                    fontWeight: "bold",
                    paddingLeft: "20px",
                    fontSize: "21px"
                  }}
                >
                  Choose your Click-N-Ship experience
                </h5>
              </div>
            </div>
            <div className="col-2">
              <button
                type="button"
                className="close button-link"
                id="close"
                data-dismiss="modal"
                tabIndex="0"
                onClick={toggle}
              >
                <span className="visuallyhidden">Close Modal</span>
              </button>
            </div>
          </div>

          <div className="modal-body">
            <div
              className="body-content"
              style={{
                width: "600px",
                padding: "20px"
              }}
            >
              <p
                style={{
                  color: "black",
                  fontWeight: "10px"
                }}
              >
                <p
                  style={{
                    color: "black"
                  }}
                >
                  Access to this version of Click-N-Ship<sup>&reg;</sup> is
                  being extended, so for now you can continue to create and
                  purchase labels here.
                </p>

                <br />
                <p
                  style={{
                    color: "black"
                  }}
                >
                  Or, for an upgraded experience, switch to enhanced
                  Click-N-Ship, where you&apos;ll have access to lower
                  Commercial Rates to save on shipping. Once you switch, you can
                  sync your Address Book contacts and groups, making shipping
                  even easier.
                </p>

                <br />
                <p
                  style={{
                    color: "black",
                    fontWeight: "10px"
                  }}
                >
                  <a
                    className=" btn-link"
                    tabIndex="0"
                    href={lookupCodes.cnsb_landing_page}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "underline"
                    }}
                  >
                    Start Using Enhanced Click-N-Ship Today
                  </a>
                </p>
                <br />
              </p>

              <div className="d-flex row justify-content-center">
                <div
                  className="col-1 d-flex justify-content-end"
                  style={{
                    padding: "0"
                  }}
                >
                  <input
                    type="checkbox"
                    id="select-all-labels-checkbox"
                    style={{
                      opacity: "1",
                      marginTop: "5px",
                      marginRight: "5px"
                    }}
                    check={dontShow}
                    onClick={(e) => setDontShow(!dontShow)}
                  />
                </div>
                <div
                  className="col-6"
                  style={{
                    padding: "0",
                    fontSize: "14px"
                  }}
                >
                  Don't show this message again
                </div>
              </div>
            </div>

            <div className="modal-buttons d-flex row justify-content-center">
              <div
                className="button-container col-4 d-flex align-content-start"
                style={{ padding: "0" }}
              >
                <a
                  href="/shippingCart"
                  role="button"
                  className="btn-primary error-display"
                  id="cnsIDXSModalSubmit"
                  tabIndex="0"
                  onClick={(e) => {
                    userSelection(e, "payNow");
                  }}
                  style={{
                    width: "100%",
                    textWrap: "nowrap"
                  }}
                >
                  OK
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(CNSExtensionModal);
