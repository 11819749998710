import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import RemoveScanFormModal from "../Modals/RemoveScanFormModal";
import CancelAllScanForms from "../Modals/CancelAllScanForms";
import CreateAndPrintScanForm from "../Modals/CreateAndPrintScanForm";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
import update from "immutability-helper";

class ScanForms extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      fetchedCartList: "",
      zipSelected: "",
      hasFetchedScanForms: false,
      isLoaded: false,
      scanformDownloaded: false,
      certify: false,
      showCreatePrintModal: false,
      errors: { globalError: [] }
    };

    this.manifestNumber = 0;
  }

  componentDidMount() {
    this.props.toggleSpinner(true);
    this.fetchScanForms();
  }

  fetchScanForms = () => {
    axios
      .get("/go/cnsrest/fetchManifestInfo")
      .then((response) => {
        response.data = this.getDropOffLocations(response.data);
        this.setState((prevState) => {
          return {
            isLoaded: true,
            hasFetchedScanForms: !prevState.hasFetchedScanForms,
            fetchedCartList: response.data,
            zipSelected:
              prevState.zipSelected &&
              response.data.fncePostalCodes.includes(prevState.zipSelected)
                ? prevState.zipSelected
                : response.data.fncePostalCodes[0],
            manifestInfo: Object.keys(response.data.manifestsForUser),
            eligibleLabels: this.getEligibleLabels(response.data),
            certify: false
          };
        });
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        this.props.toggleSpinner(false);
        document.getElementById("scan-form-top-id").focus({
          preventScroll: true
        });
      });
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: errorMessage
        }
      });
    }
  };

  getDropOffLocations = (localFetchedCartList) => {
    // Collect the drop-off locations
    let dropOffArray = [];
    const manifests = localFetchedCartList.manifestsForUser;
    for (let date in manifests) {
      if (Array.isArray(manifests[date])) {
        for (let item in manifests[date]) {
          if (
            manifests[date][item].parcelSelectType &&
            manifests[date][item].orderItemDropOff
          ) {
            if (
              !dropOffArray.includes(
                manifests[date][item].orderItemDropOff.locationName
              )
            ) {
              dropOffArray.push(
                manifests[date][item].orderItemDropOff.locationName
              );
            }
          }
        }
      }
    }
    // Sort the drop-off locations and merge into ZIP codes array
    dropOffArray.sort();
    localFetchedCartList.fncePostalCodesUnmodified =
      localFetchedCartList.fncePostalCodes;
    localFetchedCartList.fncePostalCodes = localFetchedCartList.fncePostalCodes.concat(
      dropOffArray
    );
    return localFetchedCartList;
  };

  getEligibleLabels = (localFetchedCartList) => {
    let eligibleLabels = 0;
    const manifests = localFetchedCartList.manifestsForUser;
    for (let item in manifests) {
      if (Array.isArray(manifests[item])) {
        eligibleLabels += manifests[item].length;
      }
    }
    return eligibleLabels;
  };

  showItem = (item) => {
    return (
      // If not a USPS Connect Local item, check ZIP code
      (!item.parcelSelectType &&
        item.shipFromPostalCode === this.state.zipSelected) ||
      // If a USPS Connect Local item, check drop-off location
      (item.parcelSelectType &&
        item.orderItemDropOff &&
        item.orderItemDropOff.locationName === this.state.zipSelected)
    );
  };

  // Limit is currently 100 items for
  // SCAN forms with drop-off locations
  // (currently, there is no limit for
  // non-USPS Connect SCAN forms)
  showAllLabels = () => {
    return (
      (this.state.fetchedCartList &&
        this.state.fetchedCartList.fncePostalCodesUnmodified &&
        this.state.fetchedCartList.fncePostalCodesUnmodified.includes(
          this.state.zipSelected
        )) ||
      this.state.numberOfLabels <= 100
    );
  };

  getPrice = () => {
    let result = this.state.fetchedCartList.manifestsForUser[
      this.state.manifestInfo[0]
    ].reduce((total, arr) => {
      if (this.showItem(arr)) {
        return total + arr.orderItemCost.ttlShipCostAmt;
      } else {
        return total;
      }
    }, 0);
    return result.toFixed(2);
  };

  removeScanForm = (e, labelId) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    let dataToSend = { labelId: String(labelId) };
    axios
      .post("/go/cnsrest/deleteLabelFromSCANForm", JSON.stringify(dataToSend), {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        this.fetchScanForms();
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        this.props.toggleSpinner(false);
      });
  };

  cancelAllScanForm = (e) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    let dataToSend = { manifestDate: String(this.state.manifestInfo[0]) };
    axios
      .post("/go/cnsrest/deleteManifest", JSON.stringify(dataToSend), {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        this.setState({
          isLoaded: true,
          fetchedCartList: response.data,
          eligibleLabels: this.getEligibleLabels(response.data),
          certify: false
        });
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        this.props.toggleSpinner(false);
      });
  };

  toggleCreatePrintModal = (e, refresh) => {
    this.setState((prevState) => {
      return {
        showCreatePrintModal: !prevState.showCreatePrintModal
      };
    });
    if (refresh) {
      this.props.toggleSpinner(true);
      this.fetchScanForms();
    }
  };

  // Called when the user clicks the create and print scan form button
  printScanForms = (e) => {
    if (this.state.certify) {
      this.props.toggleSpinner(true);
      let dataToSend = {
        manifestDate: this.state.manifestInfo[0],
        selectedPostal: this.state.zipSelected
      };
      axios
        .post("/go/cnsrest/commitManifest", JSON.stringify(dataToSend), {
          headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
          this.setState({
            showCreatePrintModal: true
          });
          this.manifestNumber = response.data.manifestNumber
            ? response.data.manifestNumber[0]
            : "";
          this.reprintScanForms();
        })
        .catch((error) => {
          console.log(error);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        })
        .finally(() => {
          this.props.toggleSpinner(false);
        });
    } else {
      this.setState({
        showCreatePrintModal: true
      });
    }
  };

  // Called after a successful call to the commit manifest endpoint,
  // or when the user clicks the reprint scan form button
  reprintScanForms = () => {
    let iframeHtml = "";
    iframeHtml +=
      window.location.protocol +
      "//" +
      window.location.hostname +
      "/go/Secure/LabelDownloadServlet?orderItemIds=" +
      "";
    iframeHtml += "&fileForSave=" + true;
    iframeHtml += "&prtNoInstructions=" + false;
    iframeHtml += "&manifestNumber=" + this.manifestNumber;

    var hiddenFrame = document.createElement("iframe");
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) {
      hiddenFrame.src = iframeHtml;
      hiddenFrame.id = "pdfFrame";
      hiddenFrame.name = "pdfFrameName";
      hiddenFrame.style = "visibility: hidden";
      document.body.appendChild(hiddenFrame);
      this.setState({
        scanformDownloaded: true
      });
      var inter = window.setInterval(function () {
        var state = document.getElementById("iePdfFrame").contentWindow.document
          .readyState;
        if (state === "complete") {
          window.clearInterval(inter);
        }
      }, 100);
    } else {
      hiddenFrame.src = iframeHtml;
      hiddenFrame.id = "pdfFrame";
      hiddenFrame.name = "pdfFrameName";
      hiddenFrame.style = "visibility: hidden";
      hiddenFrame.onload = function () {};
      document.body.appendChild(hiddenFrame);
    }
  };

  handleCertify = (e) => {
    this.setState({
      certify: e.currentTarget.checked
    });
  };

  createNewLabel = (e, cnsCartId) => {
    e.preventDefault();
    this.props.history.push("/labelInformation");
  };

  getLabelTotalForZip = (e) => {
    let counter = 0;

    for (let zip in this.state.fetchedCartList.manifestsForUser[
      this.state.manifestInfo[0]
    ]) {
      if (
        this.showItem(
          this.state.fetchedCartList.manifestsForUser[
            this.state.manifestInfo[0]
          ][zip]
        )
      ) {
        counter++;
      }
    }
    return counter;
  };

  selectZip = (event) => {
    this.setState({
      zipSelected: event.currentTarget.value,
      certify: false
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      // The selected zip code is changed
      this.state.zipSelected !== prevState.zipSelected ||
      // A call to fetch manifest info is made.
      // Note: despite the name, 'true' is not the only time a call to the
      // endpoint was made; this variable simply toggles between true and false
      // to ensure this if block is entered after every call to the endpoint
      this.state.hasFetchedScanForms !== prevState.hasFetchedScanForms
    ) {
      // Update the number of labels to be added to the scan form
      let numberOfLabels = 0;
      const labels = this.state.fetchedCartList.manifestsForUser[
        this.state.manifestInfo[0]
      ];
      for (let item in labels) {
        if (this.showItem(labels[item])) {
          numberOfLabels++;
        }
      }
      this.setState({
        numberOfLabels: numberOfLabels
      });
    }
  }

  render() {
    return (
      <div id="scan-form-top-id" className="SCAN_Form_Container" tabIndex="-1">
        <div className="container-fluid">
          <h2 className="d-md-none">SCAN Forms</h2>
          <h2 className="scan-form-open-header">
            Shipment Confirmation Acceptance Notice (SCAN) Form Open
          </h2>
          <AssistiveGlobalError
            globalErrorArray={this.state.errors.globalError}
          />
          {this.state.isLoaded ? (
            <React.Fragment>
              {this.state.eligibleLabels > 0 ? (
                <div className="row">
                  <div className="col-12 open-scan-form-container open-label">
                    <div className="scan-form-wrapper">
                      <p className="alert-message">
                        You have {this.state.eligibleLabels}{" "}
                        {this.state.eligibleLabels > 1 ||
                        this.state.eligibleLabels === 0
                          ? "labels"
                          : "label"}{" "}
                        that{" "}
                        {this.state.eligibleLabels > 1 ||
                        this.state.eligibleLabels === 0
                          ? "are"
                          : "is"}{" "}
                        eligible to be added to a SCAN Form. You have until
                        11:59 Central Time of the Ship Date to create and print
                        your SCAN Form.
                      </p>
                      <div className="row">
                        <div className="col-12 col-md-3 scan-form-zip-code form-group">
                          <label htmlFor="scan-form-zip-dropdown">
                            *SCAN Form Zip Code<sup>TM</sup>
                          </label>
                          <select
                            className="form-control search-by-dropdown dropdown"
                            onChange={this.selectZip}
                            value={this.state.zipSelected}
                            id="scan-form-zip-dropdown"
                          >
                            {this.state.fetchedCartList.fncePostalCodes.map(
                              (item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 open-scan-form-container scan-form-details">
                          <p>
                            <strong>SCAN Form Details</strong>
                          </p>
                          <p className="scan-form-details-expiration-time-day">
                            Expires 11:59PM CST{" "}
                            {Object.keys(
                              this.state.fetchedCartList.manifestsForUser
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-10 scan-form-table">
                          <div className="row scan-form-table-header">
                            <div className="col-3 d-none d-md-block">
                              <p>
                                <strong>Shipping Address</strong>
                              </p>
                            </div>
                            <div className="col-3 d-none d-md-block">
                              <p>
                                <strong>Package Details</strong>
                              </p>
                            </div>
                            <div className="col-3 d-none d-md-block">
                              <p>
                                <strong>Service</strong>
                              </p>
                            </div>
                            <div className="col-1 d-none d-md-block">
                              <p>
                                <strong>Price</strong>
                              </p>
                            </div>
                            <div className="col-2 d-none d-md-block">
                              <p>
                                <strong>Actions</strong>
                              </p>
                            </div>
                          </div>

                          {this.state.eligibleLabels > 0 ? (
                            this.state.fetchedCartList.manifestsForUser[
                              this.state.manifestInfo[0]
                            ].map((item) => (
                              <div key={item.orderItemId}>
                                {this.showItem(item) ? (
                                  <div className="row scan-form-item">
                                    <div className="col-8 col-md-3 order-1 list-items">
                                      <p className="d-md-none mobile-scan-form-shipping-address">
                                        Shipping Address
                                      </p>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.shipTo.displayAddress
                                        }}
                                      />
                                    </div>
                                    <div className="col-12 col-md-3 order-3 list-items">
                                      <p className="d-md-none mobile-scan-form-package-details">
                                        Package Details
                                      </p>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.packageDetailsDisplay
                                        }}
                                      />
                                    </div>
                                    <div className="col-12 col-md-4 order-4">
                                      <div className="row scan-form-table-services">
                                        <div className="col-12 col-md-9 list-items">
                                          <p className="d-md-none mobile-scan-form-service">
                                            Service
                                          </p>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item.detailedServiceDescription &&
                                                !item.detailedServiceDescription.includes(
                                                  "null"
                                                )
                                                  ? // Default (ex. for PM)
                                                    item.detailedServiceDescription
                                                  : // Revert to product class
                                                    // (ex.for FCPS)
                                                    item.productId &&
                                                    item.productId
                                                      .productClassDesc
                                            }}
                                          />
                                        </div>
                                        <div className="col-12 col-md-3">
                                          <p className="d-md-none mobile-scan-form-price">
                                            Price
                                          </p>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                "$" +
                                                (item.orderItemCost
                                                  .ttlShipCostAmt
                                                  ? item.orderItemCost.ttlShipCostAmt.toFixed(
                                                      2
                                                    )
                                                  : "0.00")
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-4 col-md-2 order-2 order-md-5 text-right text-md-left">
                                      <RemoveScanFormModal
                                        buttonLabel={"Remove"}
                                        removeScanForm={this.removeScanForm}
                                        item={item}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <React.Fragment />
                                )}
                              </div>
                            ))
                          ) : (
                            <React.Fragment />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-10 gray-box cancel-scan-form">
                          <div className="row gray-box-wrapper cancel-scan-form-content">
                            <div className="col-6">
                              <CancelAllScanForms
                                buttonLabel={"Cancel All SCAN Form(s)"}
                                cancelAllScanForm={this.cancelAllScanForm}
                              />
                            </div>
                            <div className="col-6 text-right">
                              <p className="scan-form-label-total">
                                {" "}
                                Label(s) Total(
                                {this.state.fetchedCartList.manifestCount > 0
                                  ? this.getLabelTotalForZip()
                                  : 0}
                                ):{" "}
                                <strong>
                                  $
                                  {this.state.eligibleLabels > 0
                                    ? this.getPrice()
                                    : 0}
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-11 col-lg-10 checkbox-print-scan-form-label">
                          <label
                            className="checkbox-component print-labels-checkbox"
                            htmlFor="print-scan-form-label-check"
                          >
                            <input
                              type="checkbox"
                              id="print-scan-form-label-check"
                              onChange={this.handleCertify}
                              checked={this.state.certify}
                            />
                            <span
                              className="checkbox"
                              name="print-scan-form-label-check"
                            />
                            I certify that every label listed above will be
                            shipped with this SCAN Form. I understand that the
                            labels above will be ineligible for refund requests
                            if not shipped with this SCAN Form and that no
                            changes can be made to this SCAN Form after
                            printing.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 button-wrapper">
                        <div className="button-container">
                          <CreateAndPrintScanForm
                            buttonLabel={"Create and Print SCAN Form"}
                            className="scan-form-confirmation-modal"
                            printScanForms={this.printScanForms}
                            certify={this.state.certify}
                            reprintScanForms={this.reprintScanForms}
                            showCreatePrintModal={
                              this.state.showCreatePrintModal
                            }
                            toggle={this.toggleCreatePrintModal}
                            scanFormNumber={this.manifestNumber}
                            numberOfLabels={
                              this.showAllLabels()
                                ? this.state.numberOfLabels
                                : 100
                            }
                            requestPickupLink={
                              this.props.lookupCodes[
                                "nav.ScheduleAPickupAction"
                              ]
                            }
                          />
                        </div>
                        <div className="button-container">
                          <a
                            href="/labelInformation"
                            role="button"
                            className="btn-primary button--white scan-create-label-button"
                            onClick={(e) => this.createNewLabel(e)}
                          >
                            Create Another Label
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className="row">
                    <div className="col-12 open-scan-form-container ">
                      <p>
                        <strong>You have no open SCAN Forms</strong>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 button-wrapper">
                      <div className="button-container">
                        <a
                          href="/labelInformation"
                          role="button"
                          className="btn-primary button--white scan-create-label-button"
                        >
                          Create Another Label
                        </a>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(ScanForms);
