import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const ReceiptPrintLabelsPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Selecting the "Print labels with receipt (1 per page)" option will print
        each label on one half of the page and the receipt (label record) will
        print on the other half.
      </p>
      <p>
        Selecting the "Print labels without receipt (2 per page)" option will
        allow you to save paper by printing two labels* on one page. Receipt
        information is also in your confirmation email and Shipping History.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Print with Receipt Information Icon"
      header="Print labels with receipt"
      content={content}
    />
  );
};

export default ReceiptPrintLabelsPopover;
