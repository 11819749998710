// See https://getbootstrap.com/docs/4.0/components/carousel/ for docs
// related to classes/attributes used
//
// Some code was adapted from
// https://www.geeksforgeeks.org/reactjs-reactstrap-carousel-component/

import React, { useState, useEffect, useReducer, useRef } from "react";

import HidePostagePrice from "./HidePostagePrice";
import RedirectToNewCns from "./RedirectToNewCns";
import ConnectLocalSelfEnrollment from "./ConnectLocalSelfEnrollment";
import NewV2Service from "./NewV2Service";
import LastLPAPersonal from "./LastLPAPersonal";
import LastLPABusiness from "./LastLPABusiness";
import "./BannerCarousel.css";
import HolidaySurcharge from "./HolidaySurcharge";

const BannerCarousel = (props) => {
  const {
    // Props used by multiple banners
    lookupCodes,
    // Props specific to the banner about self-enrolling in Connect Local
    showConnectLocalSelfEnrollmentBanner,
    showConnectLocalSelfEnrollmentPlaceholder,
    successSignup,
    refreshUserInfo,
    toggleSpinner,
    // Props specific to the banner that promotes CNSv2
    showRedirectToNewCnsBanner,
    // Props specific to the banner that promotes hiding the postage price
    showHidePostagePriceBanner,
    showNewV2ServiceBanner,
    showHolidaySurchargeBanner,
    showlastLPAPersonal,
    showlastLPABusiness
  } = props;

  // The interval at which the banners will automatically cycle
  // (in milliseconds)
  const bannerCycleInterval = useRef(
    (lookupCodes["banner_carousel_timer"] > 0
      ? Number(lookupCodes["banner_carousel_timer"])
      : 60) * 1000
  );

  // The ID of the timer that controls the automatic cycling of the banners
  const bannerCycleIntervalId = useRef(0);

  // The time (in milliseconds) at which the most recent banner transition
  // occurred
  const bannerCycleStart = useRef(Date.now());

  // Object that contains the list of banners that should be shown to the user
  // and the index of the currently displayed banner
  const [banners, setBanners] = useReducer(
    (state, action) => {
      switch (action.type) {
        // Update the banner list and the currently-selected banner index to
        // reflect its new position.  The latter is to ensure that, if the
        // banner order/number changes, the currently-selected banner remains
        // displayed.
        case "updateBannerList":
          let newIndex = 0;
          if (state.listToDisplay.length > 0) {
            const previouslyActiveBannerName =
              state.listToDisplay[state.activeIndex].type.name;
            for (let i in action.newList) {
              if (action.newList[i].type.name === previouslyActiveBannerName) {
                newIndex = Number(i);
                break;
              }
            }
          }
          return {
            listToDisplay: action.newList,
            activeIndex: newIndex
          };
        // Display the previous banner
        case "decrement":
          return {
            ...state,
            activeIndex:
              state.activeIndex > 0
                ? state.activeIndex - 1
                : state.listToDisplay.length - 1
          };
        // Display the previous banner
        case "increment":
          return {
            ...state,
            activeIndex:
              state.activeIndex < state.listToDisplay.length - 1
                ? state.activeIndex + 1
                : 0
          };
        // Display the indicated banner
        case "goToIndex":
          return {
            ...state,
            activeIndex: action.newIndex
          };
        // Default if an invalid case is provided
        default:
          return state;
      }
    },
    { listToDisplay: [], activeIndex: 0 }
  );

  // The status of whether a modal activated by a banner is currently displayed
  // - null = no modal has been opened or closed yet
  // - true = a modal is open
  // - false = a modal has been closed
  const [isModalActive, setIsModalActive] = useState(null);

  // The status of whether there is keyboard focus or mouse hovering within the
  // banner carousel
  // - null = no keyboard focus or mouse hovering has been detected yet
  // - true = there is keyboard focus or mouse hovering
  // - false = there is no longer keyboard focus or mouse hovering
  const [isCarouselFocused, setIsCarouselFocused] = useState(null);

  // Timer for automatically cycling through the banners
  // (called when the component mounts).
  // The timer is cleared when the component unmounts
  // (hence the return statement).
  useEffect(() => {
    bannerCycleIntervalId.current = startCarouselTimer();
    return clearCarouselTimer;
  }, []);

  // Update the array of banners to display if any flags change
  useEffect(() => {
    // Get the number of banners to display
    let numberOfBanners = 0;
    if (showRedirectToNewCnsBanner) {
      numberOfBanners++;
      //numberOfBanners++;
    }
    if (showHidePostagePriceBanner) {
      numberOfBanners++;
    }
    if (showConnectLocalSelfEnrollmentBanner) {
      numberOfBanners++;
    }
    if (showNewV2ServiceBanner) {
      numberOfBanners++;
    }
    if (showHolidaySurchargeBanner) {
      numberOfBanners++;
    }
    if (showlastLPABusiness) {
      numberOfBanners++;
    }

    if (showlastLPAPersonal) {
      numberOfBanners++;
    }

    // Update the array of banners to display
    let newBannersToDisplay = [];
    if (showHolidaySurchargeBanner) {
      newBannersToDisplay.push(
        <HolidaySurcharge
          carouselStyling={isCarouselPresent(numberOfBanners)}
        />
      );
    }
    if (showRedirectToNewCnsBanner) {
      newBannersToDisplay.push(
        <NewV2Service
          carouselStyling={isCarouselPresent(numberOfBanners)}
          cnsbUrl={lookupCodes["cnsb_landing_page"]}
        />
      );
      // newBannersToDisplay.push(
      //   <RedirectToNewCns
      //     cnsbUrl={lookupCodes["cnsb_landing_page"]}
      //     carouselStyling={isCarouselPresent(numberOfBanners)}
      //   />
      // );
    }
    if (showHidePostagePriceBanner) {
      newBannersToDisplay.push(
        <HidePostagePrice
          carouselStyling={isCarouselPresent(numberOfBanners)}
        />
      );
    }

    if (showNewV2ServiceBanner) {
      newBannersToDisplay.push(
        <NewV2Service
          carouselStyling={isCarouselPresent(numberOfBanners)}
          cnsbUrl={lookupCodes["cnsb_landing_page"]}
        />
      );
    }

    if (showlastLPAPersonal) {
      newBannersToDisplay.push(
        <LastLPAPersonal
          carouselStyling={isCarouselPresent(numberOfBanners)}
          cnsbUrl={lookupCodes["cnsb_landing_page"]}
        />
      );
    }

    if (showlastLPABusiness) {
      newBannersToDisplay.push(
        <LastLPABusiness
          carouselStyling={isCarouselPresent(numberOfBanners)}
          cnsbUrl={lookupCodes["cnsb_landing_page"]}
        />
      );
    }

    if (showConnectLocalSelfEnrollmentBanner) {
      newBannersToDisplay.push(
        <ConnectLocalSelfEnrollment
          successSignup={successSignup}
          refreshUserInfo={refreshUserInfo}
          toggleSpinner={toggleSpinner}
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          carouselStyling={isCarouselPresent(numberOfBanners)}
        />
      );
    }
    setBanners({
      type: "updateBannerList",
      newList: newBannersToDisplay
    });
  }, [
    lookupCodes,
    showRedirectToNewCnsBanner,
    showHidePostagePriceBanner,
    showNewV2ServiceBanner,
    showHolidaySurchargeBanner,
    showConnectLocalSelfEnrollmentBanner,
    successSignup,
    refreshUserInfo,
    toggleSpinner,
    isModalActive,
    showlastLPABusiness,
    showlastLPAPersonal
  ]);

  // If a modal activated by a banner is active, clear the current timer
  // for automatically cycling through the banners.
  // If a modal is closed, start the automatic cycling again.
  useEffect(() => {
    if (isModalActive) {
      clearCarouselTimer();
    } else if (isModalActive === false) {
      resetCarouselTimer();
    }
  }, [isModalActive]);

  // If there is keyboard focus or mouse hovering within the banner carousel,
  // clear the current timer for automatically cycling through the banners.
  // If there is no longer keyboard focus or mouse hovering,
  // start the automatic cycling again.
  useEffect(() => {
    if (!isModalActive && isCarouselFocused) {
      clearCarouselTimer();
    } else if (!isModalActive && isCarouselFocused === false) {
      resetCarouselTimer();
    }
  }, [isModalActive, isCarouselFocused]);

  // Use a carousel to rotate through all banners if more than one banner
  // is present
  const isCarouselPresent = (numberOfBanners) => {
    return numberOfBanners > 1;
  };

  // Start a timer to automatically cycle through the banners
  // after clearing the old one, if present
  const startCarouselTimer = () => {
    clearCarouselTimer();
    return setInterval(() => {
      setBanners({ type: "increment" });
      bannerCycleStart.current = Date.now();
    }, bannerCycleInterval.current);
  };

  // Clear the timer to automatically cycle through the banners
  const clearCarouselTimer = () => {
    clearInterval(bannerCycleIntervalId.current);
  };

  // Start a new timer to automatically cycle through the banners
  const resetCarouselTimer = () => {
    bannerCycleIntervalId.current = startCarouselTimer();
  };

  // Display the previous banner
  const goToPrevious = () => {
    setBanners({ type: "decrement" });
  };

  // Display the next banner
  const goToNext = () => {
    setBanners({ type: "increment" });
  };

  // Display the selected banner
  const goToIndex = (index) => {
    setBanners({
      type: "goToIndex",
      newIndex: index
    });
  };

  return (
    <React.Fragment>
      {isCarouselPresent(banners.listToDisplay.length) ? (
        <div
          id="bannerCarouselWrapper"
          className="carousel slide container-fluid"
          // Mouse hovering
          onMouseOver={() => setIsCarouselFocused(true)}
          onMouseOut={() => setIsCarouselFocused(false)}
          // Keyboard focus
          onFocus={() => setIsCarouselFocused(true)}
          onBlur={() => setIsCarouselFocused(false)}
        >
          <ol className="carousel-indicators">
            {banners.listToDisplay.map((banner, index) => (
              <li
                key={banner.type.name}
                className={banners.activeIndex === index ? "active" : ""}
                onClick={() => goToIndex(index)}
              ></li>
            ))}
          </ol>
          <button
            className="carousel-control-prev"
            aria-label="Previous banner"
            onClick={goToPrevious}
          ></button>
          <button
            className="carousel-control-next"
            aria-label="Next banner"
            onClick={goToNext}
          ></button>
          <div className="carousel-inner">
            {banners.listToDisplay.map((banner, index) => (
              <div
                key={banner.type.name}
                className={
                  "carousel-item" +
                  (banners.activeIndex === index ? " active" : "")
                }
              >
                {banners.listToDisplay[index]}
              </div>
            ))}
          </div>
        </div>
      ) : banners.listToDisplay.length === 1 ? (
        banners.listToDisplay[0]
      ) : // If no banners are currently active, check if a placeholder should
      // be displayed.  This placeholder, if present, means the
      // self-enrollment banner may occupy this space once the
      // drop-off locations call is finished.
      showConnectLocalSelfEnrollmentPlaceholder ? (
        <div style={{ marginTop: "88px" }}></div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default BannerCarousel;
