import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import PackageWeightPopover from "../Popovers/PackageWeightPopover";
import GirthModal from "../Modals/GirthModal";
import AssistiveError from "../Subcomponents/AssistiveError";
import InputFields from "../Subcomponents/InputFields";

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import militaryPtfasUtils from "../../utils/militaryPtfas";

class StepFourPackageDetails extends React.Component {
  constructor(props) {
    super(props);

    //REMINDER: HAZMAT_IND in the database is not being used. We are using the HAZMAT_TYPE as an indicator now.
    let arrOfHazmatItems = [
      {
        esc: 810,
        description:
          "Air Eligible Ethanol Package - (authorized fragrance and hand sanitizer shipments)"
      },

      {
        esc: 811,
        description: "Class 1 – Toy Propellant/Safety Fuse Package"
      },

      {
        esc: 812,
        description:
          "Hazardous Materials Class 3 - Package  (Hand sanitizer, rubbing alcohol, ethanol base products, flammable liquids etc.)"
      },

      {
        esc: 813,
        description:
          "Class 7 – Radioactive Materials Package  (e.g., smoke detectors, minerals, gun sights, etc.)"
      },

      {
        esc: 814,
        description:
          "Class 8 – Corrosive Materials Package - Air Eligible Corrosive Materials (certain cleaning or tree/weed killing compounds, etc.)"
      },

      {
        esc: 815,
        description:
          "Class 8 – Nonspillable Wet Battery Package - Sealed lead acid batteries"
      },

      {
        esc: 816,
        description:
          "Class 9 - Lithium Battery Marked – Ground Only Package - New Individual or spare lithium batteries (marked UN3480 or UN3090)"
      },

      {
        esc: 817,
        description:
          "Class 9 - Lithium Battery – Returns Package - Used electronic devices containing or packaged with lithium batteries (markings required)"
      },

      {
        esc: 818,
        description:
          "Class 9 - Lithium batteries, marked package - New electronic devices packaged with lithium batteries (marked UN3481 or UN3091)"
      },
      {
        esc: 819,
        description:
          "Class 9 – Dry Ice Package (limited to 5 lbs. if shipped via Air)"
      },

      {
        esc: 820,
        description:
          "Class 9 – Lithium batteries, unmarked package - New electronic devices installed or packaged with lithium batteries (no marking)"
      },

      {
        esc: 821,
        description: "Class 9 – Magnetized Materials Package"
      },

      {
        esc: 822,
        description:
          "Division 4.1 – Mailable flammable solids and Safety Matches Package - Safety/strike on box matches, book matches, mailable flammable solids"
      },

      {
        esc: 823,
        description:
          "Division 5.1 – Oxidizers Package - Hydrogen peroxide (8 to 20% concentration)"
      },

      {
        esc: 824,
        description: "Division 5.2 – Organic Peroxides Package"
      },

      {
        esc: 825,
        description:
          "Division 6.1 – Toxic Materials Package (with an LD50 of 50 mg/kg or less) - (pesticides, herbicides, etc.)"
      },

      {
        esc: 826,
        description:
          "Division 6.2 Hazardous Materials - Biological Materials (e.g., lab test kits, authorized COVID test kit returns)"
      },

      {
        esc: 827,
        description:
          "Excepted Quantity Provision Package (e.g., small volumes of flammable liquids, corrosive, toxic or environmentally hazardous materials - marking required)"
      },

      {
        esc: 828,
        description:
          "Ground Only Hazardous Materials (For items that are not listed, but are restricted to surface only)"
      },

      {
        esc: 829,
        description:
          "ID8000 Consumer Commodity Package - Air Eligible ID8000 Consumer Commodity (Non-flammable aerosols, Flammable combustible liquids, Toxic Substance, Miscellaneious hazardous materials)"
      },

      {
        esc: 830,
        description: "Lighters Package - Authorized Lighters"
      },

      {
        esc: 831,
        description:
          "LTD QTY Ground Package - Aerosols, spray disinfectants, spray paint, hair spray, propane, butane, cleaning products etc.)"
      },

      {
        esc: 832,
        description: "Small Quantity Provision Package (markings required)"
      }
    ];

    let arrOfHazmatItemsInternational = [
      {
        esc: 0,
        description: "Select"
      },

      {
        esc: 813,
        description:
          "Class 7 – Radioactive Materials Package  (e.g., smoke detectors, minerals, gun sights, etc.)"
      },

      {
        esc: 820,
        description:
          "Class 9 – Lithium batteries, unmarked package - New electronic devices installed or packaged with lithium batteries (no marking)"
      },

      {
        esc: 826,
        description:
          "Division 6.2 Hazardous Materials - Biological Materials (e.g., lab test kits, authorized COVID test kit returns)"
      }
    ];

    this.state = {
      arrOfHazmatItems: arrOfHazmatItems,
      arrOfHazmatItemsInternational: arrOfHazmatItemsInternational
    };
  }

  componentDidUpdate(prevProps) {
    // Reset the HAZMAT type dropdown whenever the delivery country
    // or delivery state changes
    const countryId = Number(this.props.configInfo.deliveryAddress?.countryId);
    const prevCountryId = Number(
      prevProps.configInfo.deliveryAddress?.countryId
    );
    const stateId = Number(this.props.configInfo.deliveryAddress?.stateId);
    const prevStateId = Number(prevProps.configInfo.deliveryAddress?.stateId);
    if (countryId !== prevCountryId || stateId !== prevStateId) {
      this.props.resetHazmatType();
    }
  }
  changeHowToShip = () => {
    this.props.changeTrackingNotifications("setFlatRateInd")("Y");
    this.props.configInfo.errors.shippingType !== ""
      ? this.props.clearError("shippingType")
      : this.props.clearError("");
    this.props.configInfo.errors.pounds !== ""
      ? this.props.clearError("pounds")
      : this.props.clearError("");
  };

  changeHowToShipWeight = () => {
    this.props.changeTrackingNotifications("flatRateInd")("N");
    this.props.configInfo.errors.shippingType !== ""
      ? this.props.clearError("shippingType")
      : this.props.clearError("");
    this.props.configInfo.errors.pounds !== ""
      ? this.props.clearError("pounds")
      : this.props.clearError("");
  };

  changeIsOver12Checked = (e) => {
    if (e.currentTarget.checked) {
      this.props.changelabelPkg("largePackageInd")("Y");
    } else {
      this.props.changelabelPkg("resetPackageDim")("");
    }
  };

  changeGirthChecked = (e) => {
    if (e.currentTarget.checked) {
      this.props.changelabelPkg("pkgShape")("NR");
    } else {
      this.props.changelabelPkg("resetGirth")("");
    }
  };

  showInfo = (e) => {
    e.preventDefault();
  };

  //configInfo={this.state} changelabelPkg={this.changelabelPkg} flatRateInd changeTrackingNotifications={this.changeTrackingNotifications}

  toggle = () => {
    this.setState((prevState) => {
      return {
        dropdownOpen: !prevState.dropdownOpen
      };
    });
  };

  hazmatESCToDesc = (esc) => {
    for (let i in this.state.arrOfHazmatItems) {
      if (this.state.arrOfHazmatItems[i].esc == esc) {
        return this.state.arrOfHazmatItems[i].description;
      }
    }
    return "Select a hazardous or dangerous material category";
  };

  render() {
    return (
      <div className="Step_Four_Container" id="package-details">
        <div className="container-fluid">
          <h2 className="normal">
            Step 4: <strong>Package Details</strong>
          </h2>
          <div className="required-field">
            {this.props.isDomesticDestination() ? (
              <div className="row package-weight-desc">
                <div className="col-12 package-details-wrapper">
                  <AssistiveError
                    inputId="step4-flatrate-radio"
                    errorMessage={this.props.configInfo.errors.shippingType}
                  />
                  <label className="Package-details-header">
                    *How do you want to ship?
                  </label>

                  <div className="wrap-radio">
                    <div className="radio-container-package-details">
                      <div>
                        <input
                          name="ship-package-details-radio"
                          className="package-details-radio radio-button"
                          type="radio"
                          id="step4-flatrate-radio"
                          checked={
                            this.props.configInfo.labelInd.flatRateInd === "Y"
                          }
                          onChange={this.changeHowToShip}
                        />
                        <label htmlFor="step4-flatrate-radio">
                          <strong>Ship Flat Rate.</strong>
                          <br />
                          <span className="package-details-radio-txt">
                            {this.props.isInUspsConnectFlow() &&
                            this.props.dropOffLocations &&
                            this.props.selectedDropOffLocationIndex ? (
                              <React.Fragment>
                                Use USPS<sup>®</sup> packaging to ship anywhere
                                in the U.S. up to 70 lbs or up to 13 oz if using
                                USPS Connect™ Local Mail.
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                Use USPS<sup>®</sup> packaging to ship anywhere
                                in the U.S. up to 70 lbs.
                              </React.Fragment>
                            )}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="radio-container-package-details">
                      <input
                        id="step4-package-weight-radio"
                        type="radio"
                        className="package-details-radio radio-button"
                        name="ship-package-details-radio"
                        checked={this.props.isWeightRequired()}
                        onChange={this.changeHowToShipWeight}
                      />
                      <label htmlFor="step4-package-weight-radio">
                        <strong>Ship by Package Weight.</strong>
                        <br />
                        <span className="package-details-radio-txt">
                          Enter a value of zero or higher for pounds and ounces.
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="package-weight-input">
              <div className="package-weight-wrapper required-field">
                <AssistiveError
                  inputId="pounds-input"
                  errorMessage={this.props.configInfo.errors.pounds}
                />
                {!this.props.isDomesticDestination() ? (
                  <label className="package-weight-label">*Weight</label>
                ) : null}
                <div className="row package-weight-fields-wrapper">
                  <div
                    className={
                      "col-6 col-md-3 col-lg-2 step-four-lbs-input form-group required-field" +
                      (this.props.configInfo.errors.pounds !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <form className="form-inline package-weight-input">
                      <div className="input-group mb-3">
                        <input
                          className="dimensions-form form-control append"
                          id="pounds-input"
                          type="text"
                          placeholder="0"
                          aria-label=""
                          aria-describedby=""
                          onChange={(e) => {
                            this.props.changelabelPkg("weightOZQtyPound")(
                              e.target.value
                            );
                            this.props.configInfo.errors.pounds !== ""
                              ? this.props.clearError("pounds")
                              : this.props.clearError("");
                            this.changeHowToShipWeight();
                          }}
                          value={
                            this.props.isWeightRequired()
                              ? this.props.configInfo.labelPkg.weightOZQtyPound
                              : ""
                          }
                          maxLength="3"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">lbs</span>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className={
                      "col-6 col-md-3 col-lg-2 package-oz-input form-group required-field" +
                      (this.props.configInfo.errors.pounds !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <form className="form-inline package-weight-input">
                      <div className="input-group mb-3">
                        <input
                          className="dimensions-form form-control append"
                          id="ounces-input"
                          type="text"
                          placeholder="0"
                          aria-label=""
                          aria-describedby=""
                          onChange={(e) => {
                            this.props.changelabelPkg("weightOZQtyOunces")(
                              e.target.value
                            );
                            this.props.configInfo.errors.pounds !== ""
                              ? this.props.clearError("pounds")
                              : this.props.clearError("");
                            this.changeHowToShipWeight();
                          }}
                          value={
                            this.props.isWeightRequired()
                              ? this.props.configInfo.labelPkg.weightOZQtyOunces
                              : ""
                          }
                          maxLength="4"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">oz</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <span className="error-message">
                {this.props.configInfo.errors.pounds}
              </span>
              <div className="row">
                <div className="col-12 package-weight-info">
                  <p>
                    What if I don't know my package weight?{" "}
                    <PackageWeightPopover targetName="packageWeightIcon" />
                  </p>
                </div>
              </div>
            </div>
            {this.props.isWeightRequired() ? (
              <div className="row">
                <div
                  id="package-dimensions-id"
                  className="col-12 package-dimensions"
                >
                  <label
                    className="checkbox-component"
                    htmlFor="dimensions-checkbox"
                  >
                    <input
                      type="checkbox"
                      id="dimensions-checkbox"
                      checked={
                        this.props.configInfo.labelPkg.largePackageInd === "Y"
                      }
                      onChange={this.changeIsOver12Checked}
                    />
                    <span className="checkbox" name="dimensions-check" />
                    This package has a dimension measuring over 12".
                  </label>
                </div>
              </div>
            ) : (
              <div />
            )}

            {this.props.configInfo.labelPkg.largePackageInd === "Y" ? (
              <React.Fragment>
                <div className="row package-dimensions-input">
                  <div
                    className={
                      "col-12 col-md-3 col-lg-2 package-length-input form-group required-field" +
                      (this.props.configInfo.errors.length !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="pkgLengthQtyId"
                      errorMessage={this.props.configInfo.errors.length}
                    />
                    <label htmlFor="pkgLengthQtyId">*Length</label>
                    <div className="input-group">
                      <input
                        className="form-control append"
                        id="pkgLengthQtyId"
                        type="text"
                        placeholder="0"
                        aria-label=""
                        aria-describedby=""
                        value={this.props.configInfo.labelPkg.pkgLengthQty}
                        onChange={(e) => {
                          this.props.changelabelPkg("pkgLengthQty")(
                            e.target.value
                          );
                          this.props.configInfo.errors.length !== ""
                            ? this.props.clearError("length")
                            : this.props.clearError("");
                        }}
                        maxLength="3"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">in</span>
                      </div>
                    </div>
                    <span className="error-message">
                      {this.props.configInfo.errors.length}
                    </span>
                  </div>
                  <div
                    className={
                      "col-12 col-md-3 col-lg-2 package-width-input form-group required-field" +
                      (this.props.configInfo.errors.width !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="pkgWidthQtyId"
                      errorMessage={this.props.configInfo.errors.width}
                    />
                    <label htmlFor="pkgWidthQtyId">*Width</label>
                    <div className="input-group">
                      <input
                        className="form-control append"
                        id="pkgWidthQtyId"
                        type="text"
                        placeholder="0"
                        aria-label=""
                        aria-describedby=""
                        value={this.props.configInfo.labelPkg.pkgWidthQty}
                        onChange={(e) => {
                          this.props.changelabelPkg("pkgWidthQty")(
                            e.target.value
                          );
                          this.props.configInfo.errors.width !== ""
                            ? this.props.clearError("width")
                            : this.props.clearError("");
                        }}
                        maxLength="3"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">in</span>
                      </div>
                    </div>
                    <span className="error-message">
                      {this.props.configInfo.errors.width}
                    </span>
                  </div>
                  <div
                    className={
                      "col-12 col-md-3 col-lg-2 package-height-input form-group required-field" +
                      (this.props.configInfo.errors.height !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="pkgHeightQtyId"
                      errorMessage={this.props.configInfo.errors.height}
                    />
                    <label htmlFor="pkgHeightQtyId">*Height</label>
                    <div className="input-group">
                      <input
                        className="form-control append"
                        id="pkgHeightQtyId"
                        type="text"
                        placeholder="0"
                        aria-label=""
                        aria-describedby=""
                        value={this.props.configInfo.labelPkg.pkgHeightQty}
                        onChange={(e) => {
                          this.props.changelabelPkg("pkgHeightQty")(
                            e.target.value
                          );
                          this.props.configInfo.errors.height !== ""
                            ? this.props.clearError("height")
                            : this.props.clearError("");
                        }}
                        maxLength="3"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">in</span>
                      </div>
                    </div>
                    <span className="error-message">
                      {this.props.configInfo.errors.height}
                    </span>
                  </div>
                  <div className="col-12 non-standard-package form-group">
                    <label
                      htmlFor="non-standard-package-checkbox"
                      className="checkbox-text"
                    >
                      <input
                        tabIndex="0"
                        type="checkbox"
                        id="non-standard-package-checkbox"
                        checked={
                          this.props.configInfo.labelPkg.pkgShape === "NR"
                        }
                        onChange={this.changeGirthChecked}
                      />
                      <span className="checkbox" />
                      This package isn't a standard, rectangular box.
                    </label>
                  </div>
                </div>

                {this.props.configInfo.labelPkg.pkgShape === "NR" ? (
                  <div className="row non-standard-input">
                    <div
                      className={
                        "col-12 col-md-3 col-lg-2 package-girth-input form-group required-field" +
                        (this.props.configInfo.errors.girth !== ""
                          ? " error"
                          : "")
                      }
                    >
                      <AssistiveError
                        inputId="pkgGirthQtyId"
                        errorMessage={this.props.configInfo.errors.girth}
                      />
                      <label htmlFor="pkgGirthQtyId">*Girth</label>{" "}
                      <GirthModal targetName="girthWeightIcon" />
                      <div className="input-group">
                        <input
                          className="form-control append"
                          id="pkgGirthQtyId"
                          type="text"
                          placeholder="0"
                          aria-label=""
                          aria-describedby=""
                          value={this.props.configInfo.labelPkg.pkgGirthQty}
                          onChange={(e) => {
                            this.props.changelabelPkg("pkgGirthQty")(
                              e.target.value
                            );
                            this.props.configInfo.errors.girth !== ""
                              ? this.props.clearError("girth")
                              : this.props.clearError("");
                          }}
                          maxLength="2"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">in</span>
                        </div>
                      </div>
                      <span className="error-message">
                        {this.props.configInfo.errors.girth}
                      </span>
                    </div>
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </React.Fragment>
            ) : (
              <div />
            )}
            {this.props.isInUspsConnectFlow() &&
            !this.props.hideNonmachinable() &&
            this.props.dropOffLocations &&
            this.props.selectedDropOffLocationIndex ? (
              <div id="nonmachinable-section">
                <p>
                  <strong>
                    Do any of the below characteristics apply to your package?
                  </strong>
                </p>
                <div className="row">
                  <InputFields
                    divClassList="col-12 col-md-5"
                    inputId="nonmachinable-dropdown"
                    labelText="*If multiple options apply, just select one. Otherwise, choose
                      none."
                    elementType="select"
                    inputValue={
                      this.props.configInfo.labelInd.nonmachinableInd || "0"
                    }
                    inputOnChange={(e) => {
                      this.props.changeTrackingNotifications(
                        "nonmachinableInd"
                      )(e.currentTarget.value);
                    }}
                    optionsList={[
                      {
                        name: "None",
                        value: "0"
                      },
                      {
                        name: "Glass container with more than 24oz of liquid",
                        value: "1"
                      },
                      {
                        name:
                          "Metal or plastic container with more than 1 gallon of liquid",
                        value: "2"
                      },
                      {
                        name: "Metal-banded parcel",
                        value: "3"
                      },
                      {
                        name: "Insecurely wrapped",
                        value: "4"
                      },
                      {
                        name: "Can, Roll, or Tube",
                        value: "5"
                      },
                      {
                        name: "Wooden or Metal box",
                        value: "6"
                      },
                      {
                        name:
                          "Books or printed material weighing more than 25 pounds",
                        value: "7"
                      }
                    ]}
                  />
                </div>
              </div>
            ) : null}
            {!this.props.isDomesticDestination() ? (
              <div className="row">
                <div id="shipping-gift-id" className="col-12 shipping-gift">
                  <label className="checkbox-component" htmlFor="gift-checkbox">
                    <input
                      type="checkbox"
                      id="gift-checkbox"
                      checked={this.props.configInfo.labelInd.giftInd === "Y"}
                      onChange={(e) =>
                        this.props.changeTrackingNotifications("giftInd")(
                          e.currentTarget.checked
                        )
                      }
                    />
                    <span className="checkbox" name="gift-check" />
                    <strong>I am shipping a gift.</strong>
                  </label>
                </div>
              </div>
            ) : null}
            <span id="shipping-type-error" className="error-message">
              {this.props.configInfo.errors.shippingType}
            </span>
          </div>
          <div className="package-value">
            <AssistiveError
              inputId="pkgValueAmtId"
              errorMessage={this.props.configInfo.errors.value}
            />
            {this.props.isDomesticDestination() ? (
              <div className="row">
                <div className="col-12">
                  <label
                    htmlFor="pkgValueAmtId"
                    className="package-value-header"
                  >
                    Package Value
                  </label>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div
                className={
                  "col-12 col-md-4" +
                  (!this.props.isDomesticDestination()
                    ? " required-field"
                    : "") +
                  (this.props.configInfo.errors.value !== "" ? " error" : "")
                }
              >
                {this.props.isDomesticDestination() ? (
                  <label className="package-value-label-us">
                    Enter a value up to and including $5,000.00.
                  </label>
                ) : (
                  <label
                    htmlFor="pkgValueAmtId"
                    className="package-value-label-int"
                  >
                    *Package Value. Value must be greater than zero.
                  </label>
                )}
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    tabIndex="0"
                    id="pkgValueAmtId"
                    type="text"
                    className="form-control prepend"
                    value={this.props.configInfo.labelPkg.pkgValueAmt}
                    onChange={(e) => {
                      this.props.changelabelPkg("pkgValueAmt")(e.target.value);
                      this.props.configInfo.errors.value !== ""
                        ? this.props.clearError("value")
                        : this.props.clearError("");
                    }}
                    maxLength="8"
                  />
                </div>
                <span className="error-message">
                  {this.props.configInfo.errors.value}
                </span>
              </div>
            </div>

            {this.props.configInfo.deliveryAddress.countryId === 840 && (
              <div className="row hazmatContainer">
                <div className="col-12 col-md-10 required-field">
                  <h6 className="hazmatHeader">
                    Does this parcel contain anything potentially hazardous?{" "}
                  </h6>

                  <p>
                    Identify whether your shipment contains dangerous goods. It
                    will be noted on the shipping label.
                  </p>

                  <p>
                    This category includes items such as batteries, dry ice,
                    flammable liquids, aerosol sprays, air bags, ammunition,
                    fireworks, gasoline, lighters, lithium batteries, matches,
                    nail polish, nail polish remover, nitrogen-refrigerated
                    liquid, paint, perfume, aerosols, camping stoves,
                    radioactive materials, solvents, and more.
                  </p>

                  <p>These items must ship in separate packages.</p>

                  <div className="row hazardQuestion">
                    <div className="col-6">
                      <p>
                        Are you shipping dangerous goods or hazardous materials?
                      </p>
                    </div>
                    <div className="col-3 linkUnderline">
                      <a
                        href="https://www.usps.com/ship/shipping-restrictions.htm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </div>
                    <div className="col-3 linkUnderline">
                      <a
                        href="https://www.uspsdelivers.com/hazmat-shipping-safety/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        See Examples
                      </a>
                    </div>
                  </div>

                  <AssistiveError
                    inputId="hazmatRadio"
                    errorMessage={this.props.configInfo.errors.hazmatRadio}
                  />
                  <span className="error-message">
                    {this.props.configInfo.errors.hazmatRadio}
                  </span>
                  <div className="radio-container-package-details">
                    <input
                      id="step4-hazmatYES"
                      type="radio"
                      className="package-details-radio radio-button"
                      name="hazmat-radio"
                      checked={this.props.hazmatYesNo === true}
                      onChange={() => this.props.setHazmatYesNo(true)}
                    />
                    <label htmlFor="step4-hazmatYES">
                      Yes
                      <br />
                    </label>
                  </div>

                  <div className="radio-container-package-details">
                    <input
                      id="step4-hazmatNO"
                      type="radio"
                      className="package-details-radio radio-button"
                      name="hazmat-radio"
                      checked={this.props.hazmatYesNo === false}
                      onChange={() => this.props.setHazmatYesNo(false)}
                    />
                    <label htmlFor="step4-hazmatNO">
                      No
                      <br />
                    </label>
                  </div>
                  {/* REMINDER: HAZMAT_IND in the database is not being used. We are using the HAZMAT_TYPE as an indicator now. */}
                  {this.props.hazmatYesNo === true ? (
                    <div
                      className={
                        "hazmatCategory" +
                        (this.props.configInfo.errors.hazmat !== ""
                          ? " error"
                          : "")
                      }
                    >
                      <AssistiveError
                        inputId="hazmat"
                        errorMessage={this.props.configInfo.errors.hazmat}
                      />
                      <h6 className="hazmatHeader">Select a category</h6>
                      <label htmlFor="hazmatDropDown">Select a category</label>

                      <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                        value={this.props.hazmatType}
                        className={"dropdown-selection hazmatChevron"}
                        id="hazmat"
                      >
                        <DropdownToggle
                          id="hazmatDropDown"
                          className="btn btn-default dropdown-items-wrapper "
                        >
                          {this.props.hazmatType === null ||
                          this.props.hazmatType === undefined ? (
                            <div style={{ color: "#b3b2b2", fontSize: "12px" }}>
                              {this.hazmatESCToDesc(this.props.hazmatType)}
                            </div>
                          ) : (
                            <div>
                              {" "}
                              {this.hazmatESCToDesc(this.props.hazmatType)}
                            </div>
                          )}
                        </DropdownToggle>

                        {this.props.isDomesticDestination() &&
                        !militaryPtfasUtils.isMilitary(
                          this.props.configInfo.deliveryAddress?.stateId
                        ) &&
                        !militaryPtfasUtils.isPtfas(
                          this.props.configInfo.deliveryAddress?.stateId
                        ) ? (
                          <DropdownMenu>
                            {this.state.arrOfHazmatItems.map((item, keys) => (
                              <li key={item.esc}>
                                <DropdownItem
                                  onClick={(e) => this.props.setHazmatType(e)}
                                  value={item.esc}
                                >
                                  {item.description}
                                </DropdownItem>
                              </li>
                            ))}
                          </DropdownMenu>
                        ) : (
                          <DropdownMenu>
                            {this.state.arrOfHazmatItemsInternational.map(
                              (item, keys) => (
                                <li key={item.esc}>
                                  <DropdownItem
                                    onClick={(e) => this.props.setHazmatType(e)}
                                    value={item.esc}
                                  >
                                    {item.description}
                                  </DropdownItem>
                                </li>
                              )
                            )}
                          </DropdownMenu>
                        )}
                      </Dropdown>
                      <span className="error-message">
                        {this.props.configInfo.errors.hazmat}
                      </span>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StepFourPackageDetails;
