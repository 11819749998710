import React, { useState } from "react";
import { Popover, Modal } from "reactstrap";

const AdultSigRequired21Older = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const { targetName } = props;

  const toggle = (e) => {
    e.preventDefault();
    setPopoverOpen(!popoverOpen);
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="info-icon button-link"
        data-trigger="focus"
        data-toggle="modal"
        data-target="#adult-sig-required-modal"
        data-backdrop="static"
        tabIndex="0"
        aria-label="Adult Signature Required Information Icon"
        id={targetName}
        onClick={toggle}
      />
      {window.innerWidth > 760 ? (
        <Popover
          placement="auto"
          trigger="legacy"
          isOpen={popoverOpen}
          target={targetName}
          toggle={(e) => toggle(e)}
        >
          <div className="adult-sig-required-popover">
            <div className="popover-wrapper">
              <div className="popover-header">
                <button
                  type="button"
                  className="close button-link"
                  data-dismiss="popover"
                  tabIndex="0"
                  onClick={(e) => toggle(e)}
                >
                  <span className="visuallyhidden">Close Popover</span>
                </button>
                <h4>Adult Signature Required</h4>
              </div>
              <p>
                Provides added level of assurance by requiring the signature of
                any person 21 years of age or older at the recipient's address.
                After delivery, you'll be able to determine the date and time of
                delivery using USPS Tracking<sup>®</sup>.
              </p>
              <p>
                Signature Waiver is not available with this service and USPS
                <sup>®</sup> delivery personnel cannot leave a package at an
                address without the signature of an adult 21 years of age or
                older (government-issued photo identification is required).
              </p>
              <p>
                You can also request to have a Proof of Delivery letter e-mailed
                to you. The Proof of Delivery letter includes the signature of
                the recipient and the recipient's name (first initial and last
                name).
              </p>
            </div>
          </div>
        </Popover>
      ) : (
        <React.Fragment>
          <Modal isOpen={popoverOpen} toggle={toggle}>
            <div className="modal-content modal-container">
              <div className="modal-header">
                <button
                  type="button"
                  className="close button-link"
                  data-dismiss="modal"
                  tabIndex="0"
                  onClick={toggle}
                >
                  <span className="visuallyhidden">Close Modal</span>
                </button>
                <h3 className="modal-title">Adult Signature Required</h3>
              </div>
              <div className="modal-body">
                <div className="body-content">
                  <p>
                    Provides added level of assurance by requiring the signature
                    of any person 21 years of age or older at the recipient's
                    address. After delivery, you'll be able to determine the
                    date and time of delivery using USPS Tracking<sup>®</sup>.
                  </p>
                  <p>
                    Signature Waiver is not available with this service and USPS
                    <sup>®</sup> delivery personnel cannot leave a package at an
                    address without the signature of an adult 21 years of age or
                    older (government-issued photo identification is required).
                  </p>
                  <p>
                    You can also request to have a Proof of Delivery letter
                    e-mailed to you. The Proof of Delivery letter includes the
                    signature of the recipient and the recipient's name (first
                    initial and last name).
                  </p>
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AdultSigRequired21Older;
