import React, { useState, useEffect } from "react";

const AdditionalDropoffInfo = (props) => {
  const { physicalState, locationName } = props;

  const [renderState, setRenderState] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(0);

  const [info, setInfo] = useState(0);

  const dropoffInfo = [
    {
      LOCALENAME: "ADELPHI",
      PHYSICALSTATE: "NY",
      instructions:
        "Enter the Post Office parking lot on Fulton St and proceed to the back of the building to access back dock area.",
      PhoneNumber: "347-272-1939 "
    },
    {
      LOCALENAME: "ANSONIA",
      PHYSICALSTATE: "NY",
      instructions:
        "Back dock area is located on 68th St between Columbus Ave and Broadway.",
      PhoneNumber: "212-362-6581 "
    },
    {
      LOCALENAME: "BLYTHEBOURNE",
      PHYSICALSTATE: "NY",
      instructions:
        "Back dock is located on 12th Ave between 51st and 52nd St.",
      PhoneNumber: "172-436-1675 "
    },
    {
      LOCALENAME: "BREVOORT",
      PHYSICALSTATE: "NY",
      instructions: "36 Perry Place between Norstand  Ave  and Bedford Ave.",
      PhoneNumber: "718-638-7099 "
    },
    {
      LOCALENAME: "BUSHWICK",
      PHYSICALSTATE: "NY",
      instructions:
        "Back dock is located on Gates Ave between Bushwick Ave and Broadway.",
      PhoneNumber: "917-246-4840 "
    },
    {
      LOCALENAME: "CADMAN PLAZA",
      PHYSICALSTATE: "NY",
      instructions:
        "Enter back dock area on Adams St. between Johnson Ave and Tillary St",
      PhoneNumber: "718-855-0912 "
    },
    {
      LOCALENAME: "CANAL STREET",
      PHYSICALSTATE: "NY",
      instructions:
        "Enter back dock area on Lispenard St between 6th Ave and Church St ",
      PhoneNumber: "212-966-9573 "
    },
    {
      LOCALENAME: "CANARSIE",
      PHYSICALSTATE: "NY",
      instructions: "Entrance is a gated parking lot at E 103rd St  ",
      PhoneNumber: "718-649-2073 "
    },
    {
      LOCALENAME: "CATHEDRAL",
      PHYSICALSTATE: "NY",
      instructions:
        "Entrance is on 105th St between Broadway and Amsterdam Ave",
      PhoneNumber: "212-662-0355 "
    },
    {
      LOCALENAME: "CHURCH STREET",
      PHYSICALSTATE: "NY",
      instructions:
        "Entrance is on W. Broadway between Barclay Ave and Vesey St ",
      PhoneNumber: "212-330-5005 "
    },
    {
      LOCALENAME: "CO OP CITY",
      PHYSICALSTATE: "NY",
      instructions: "Enter back dock area located at 3392 Conner St ",
      PhoneNumber: "718-515-5698 "
    },
    {
      LOCALENAME: "COOPER",
      PHYSICALSTATE: "NY",
      instructions: "Entrance is on 11th St between 4th and 3rd Ave",
      PhoneNumber: "212-533-3529 "
    },
    {
      LOCALENAME: "FDR STATION",
      PHYSICALSTATE: "NY",
      instructions:
        "Entrance is located on 55th St side between 2nd and 3rd Ave",
      PhoneNumber: "212-330-5632 "
    },
    {
      LOCALENAME: "FLATBUSH",
      PHYSICALSTATE: "NY",
      instructions:
        "Entrance is on Martense St between Beckford Ave and Flatbush Ave",
      PhoneNumber: "347-294-1069 "
    },
    {
      LOCALENAME: "GRAVESEND",
      PHYSICALSTATE: "NY",
      instructions:
        "Entrance on Village Rd N between McDonald Ave and West St.",
      PhoneNumber: "347-713-0758 "
    },
    {
      LOCALENAME: "GREENPOINT",
      PHYSICALSTATE: "NY",
      instructions: "",
      PhoneNumber: "347-294-1940 "
    },
    {
      LOCALENAME: "HELLGATE STATION",
      PHYSICALSTATE: "NY",
      instructions: "Entrance is on 110th St between 3rd Ave and Lexington Ave",
      PhoneNumber: "212-860-1896 "
    },
    {
      LOCALENAME: "HUB",
      PHYSICALSTATE: "NY",
      instructions:
        "Entrance on Westchester Ave between St Anns Ave and Brook Ave ",
      PhoneNumber: "718-665-1942 "
    },
    {
      LOCALENAME: "JAMES A FARLEY",
      PHYSICALSTATE: "NY",
      instructions: "Please enter back dock area at 383 W 31st St",
      PhoneNumber: "917-621-9446 "
    },
    {
      LOCALENAME: "JAMES E DAVIS",
      PHYSICALSTATE: "NY",
      instructions: "",
      PhoneNumber: "247-227-6971 "
    },
    {
      LOCALENAME: "KNICKERBOCKER",
      PHYSICALSTATE: "NY",
      instructions: "Entrance is on Division St between Pike Ave and Canal St",
      PhoneNumber: "212-208-3598 "
    },
    {
      LOCALENAME: "LENOX HILL",
      PHYSICALSTATE: "NY",
      instructions: "",
      PhoneNumber: "212-879-4401 "
    },
    {
      LOCALENAME: "MADISON SQUARE",
      PHYSICALSTATE: "NY",
      instructions:
        "Enter back dock area on 24th St between 3rd Ave and Lexington Ave",
      PhoneNumber: "212-673-2469 "
    },
    {
      LOCALENAME: "MAJOR ROBERT OWENS",
      PHYSICALSTATE: "NY",
      instructions: "",
      PhoneNumber: "917-246-4506 "
    },
    {
      LOCALENAME: "MANHATTANVILLE",
      PHYSICALSTATE: "NY",
      instructions:
        "Enter back dock are on 126th St between St Nicholas Ave and Morningsid Ave",
      PhoneNumber: "212-662-1540 "
    },
    {
      LOCALENAME: "METROPOLITAN",
      PHYSICALSTATE: "NY",
      instructions:
        "Entrance is on Humbolt Ave between Varet St and Debevoise St",
      PhoneNumber: "347-272-1755 "
    },
    {
      LOCALENAME: "MIDTOWN",
      PHYSICALSTATE: "NY",
      instructions: "",
      PhoneNumber: "212-819-9604 "
    },
    {
      LOCALENAME: "MORRISANIA",
      PHYSICALSTATE: "NY",
      instructions:
        "450 Gouverneur Place the entrance to the dock is directly across the street",
      PhoneNumber: "718-993-8054 "
    },
    {
      LOCALENAME: "MURRAY HILL ANNEX",
      PHYSICALSTATE: "NY",
      instructions: "Enter back dock are on 36th St between 2nd and 3rd Ave",
      PhoneNumber: "212-545-0836 "
    },
    {
      LOCALENAME: "OLD CHELSEA",
      PHYSICALSTATE: "NY",
      instructions: "",
      PhoneNumber: "212-675-2414 "
    },
    {
      LOCALENAME: "PECK SLIP ANNEX",
      PHYSICALSTATE: "NY",
      instructions: "W Broadway between Barclay Ave and Vesey St ",
      PhoneNumber: "212-330-5005 "
    },
    {
      LOCALENAME: "PLANETARIUM",
      PHYSICALSTATE: "NY",
      instructions: "",
      PhoneNumber: "212-580-5510 "
    },
    {
      LOCALENAME: "RADIO CITY",
      PHYSICALSTATE: "NY",
      instructions: "",
      PhoneNumber: "646-810-9627 "
    },
    {
      LOCALENAME: "RCU ANNEX",
      PHYSICALSTATE: "NY",
      instructions: "Enter back dock area on 41st St between 8th and 9th Ave",
      PhoneNumber: "212-502-0424 "
    },
    {
      LOCALENAME: "RED HOOK",
      PHYSICALSTATE: "NY",
      instructions:
        "Enter back dock area on Centre St between Clinton Ave and Hamilton St",
      PhoneNumber: "917-246-4564 "
    },
    {
      LOCALENAME: "SHIRLEY A CHISHOLM",
      PHYSICALSTATE: "NY",
      instructions:
        "Enter back dock area on MacDougal St between Howard Ave and Ralph Ave",
      PhoneNumber: "947-294-1319 "
    },
    {
      LOCALENAME: "SOUTH SHORE ANNEX",
      PHYSICALSTATE: "NY",
      instructions: "",
      PhoneNumber: "347-620-9131 "
    },
    {
      LOCALENAME: "TIMES PLAZA ANNEX",
      PHYSICALSTATE: "NY",
      instructions: "",
      PhoneNumber: "718-662-0341 "
    },
    {
      LOCALENAME: "TIMES SQUARE",
      PHYSICALSTATE: "NY",
      instructions:
        "Enter back dock area on W 41st St  between 8th and 9th Ave",
      PhoneNumber: "212-502-0424 "
    },
    {
      LOCALENAME: "TOTTENVILLE",
      PHYSICALSTATE: "NY",
      instructions: "Back dock area is located on Bentley Lane",
      PhoneNumber: "718-768-5630 "
    },
    {
      LOCALENAME: "VAN BRUNT",
      PHYSICALSTATE: "NY",
      instructions: "Enter back dock area 8th  St between 4th Ave and 5th Ave",
      PhoneNumber: "718-768-5630 "
    },
    {
      LOCALENAME: "WALL STREET ANNEX",
      PHYSICALSTATE: "NY",
      instructions:
        "Entrance is on W Broadway between Barclay Ave and Vesey St ",
      PhoneNumber: "212-330-5005 "
    },
    {
      LOCALENAME: "WILLIAMSBURG",
      PHYSICALSTATE: "NY",
      instructions: "Entrance is on Borinquen Pl between Marcy St and 4th Ave",
      PhoneNumber: "917-246-4129 "
    }
  ];

  useEffect(() => {
    if (locationName === "NY") {
      for (let i in dropoffInfo) {
        if (dropoffInfo[i].LOCALENAME === physicalState) {
          setInfo(dropoffInfo[i].instructions);
          setPhoneNumber(dropoffInfo[i].PhoneNumber);
          setRenderState(true);
        }
      }
    }
  }, [locationName, dropoffInfo, physicalState]);

  if (renderState && info !== "") {
    return (
      <div>
        <p> Facility Location Instructions: {info}.</p>
        <p>
          {" "}
          If you need assistance locating the drop off location of the facility
          please call {phoneNumber}.
        </p>
        <br />
      </div>
    );
  } else if (renderState && info === "") {
    return (
      <div>
        <p>
          {" "}
          If you need assistance locating the drop off location of the facility
          please call {phoneNumber}
        </p>
        <br />
      </div>
    );
  } else {
    return <p></p>;
  }
};

export default AdditionalDropoffInfo;
