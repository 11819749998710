import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";

import axios from "axios";
import { withRouter } from "react-router-dom";
import ShippingHistoryTable from "./ShippingHistoryTable";
import DateRangeSelector from "../Popovers/DateRangeSelector";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
import update from "immutability-helper";
import * as analytics from "../Analytics/Analytics.js";

import gridViewIcon from "../../click-n-ship/images/grid-view-icon.svg";
import descendingIcon from "../../click-n-ship/images/descending-icon.svg";
import ascendingIcon from "../../click-n-ship/images/ascending-icon.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class ShippingHistory extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      isLoaded: false,
      historyPref: false,
      sortColumnCount: [],
      refreshOrderItems: 0,
      pageNum: 1,
      searchBy: "1",
      advanceSearch: false,
      startPage: 1,
      recordsPerPage: "",
      requestType: "1",
      firstName: "",
      lastName: "",
      company: "",
      orderId: "0",
      trackingNumber: "",
      startDate: "",
      transactionNumber: this.props.location.state
        ? this.props.location.state.orderId
        : "",
      labelNumber: "",
      sortColumnOptions: [
        "Label Number",
        "Shipping Status",
        "Transaction Number",
        "First Name",
        "Last Name",
        "Service",
        "Transaction Date",
        "Company Name",
        "Payment Status",
        "Label Cost",
        "City",
        "State",
        "Postal Code",
        "Province",
        "Country",
        "Payment Method"
      ],
      errors: {
        globalError: []
      }
    };

    // Counter to uniquely identify each custom sort column
    this.newestColumnId = -1;
  }
  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            [field]: errorMessage
          }
        };
      });
    }
  };
  componentDidMount() {
    this.props.toggleSpinner(true);

    axios
      .get("/go/cnsrest/fetchShipHistPref")
      .then((response1) => {
        let sortColumnsAdded = [];
        let fieldNames = Object.keys(response1.data.shippingHistorySortPrefs);
        let sortDirection = Object.values(
          response1.data.shippingHistorySortPrefs
        );
        for (let i = 0; i < fieldNames.length; i++) {
          this.newestColumnId = this.newestColumnId + 1;
          sortColumnsAdded[i] = new Array(3);
          sortColumnsAdded[i][0] = fieldNames[i];
          sortColumnsAdded[i][1] = sortDirection[i];
          sortColumnsAdded[i][2] = this.newestColumnId;
        }
        this.setState({
          historyPref: true,
          fetchedShipHistPref: response1.data,
          fetchedSortData: response1.data.shippingHistorySortPrefs,
          fetchedFilterData: response1.data.shippingHistoryFilterPrefs,
          sortColumnsAdded: sortColumnsAdded
        });

        let dataToSend = {
          startPage: 1,
          recordsPerPage: response1.data.shippingHistoryRecordsPerPagePrefs,
          requestType: "1",
          firstName: "",
          lastName: "",
          company: "",
          orderId: "0",
          trackingNumber: "",
          startDate: ""
        };
        axios
          .post("/go/cnsrest/shippingHistoryInit", dataToSend)
          .then((response2) => {
            if (
              Object.keys(response2.data.fieldErrors).length === 0 &&
              response2.data.actionErrors.length === 0
            ) {
              this.setState({
                isLoaded: true,
                focusOnTop: true,
                fetchedShippingHistoryInit: response2.data,
                shippingHistoryRecordsPerPagePrefs:
                  response2.data.shippingHistoryRecordsPerPagePrefs,
                "Delivery Address":
                  response2.data.shippingHistoryFilterPrefs["Delivery Address"],
                "Label Cost":
                  response2.data.shippingHistoryFilterPrefs["Label Cost"],
                "Label Number":
                  response2.data.shippingHistoryFilterPrefs["Label Number"],
                "Payment Method":
                  response2.data.shippingHistoryFilterPrefs["Payment Method"],
                "Payment Status":
                  response2.data.shippingHistoryFilterPrefs["Payment Status"],
                Service: response2.data.shippingHistoryFilterPrefs["Service"],
                "Shipping Status":
                  response2.data.shippingHistoryFilterPrefs["Shipping Status"],
                "Transaction Date":
                  response2.data.shippingHistoryFilterPrefs["Transaction Date"],
                "Transaction Number":
                  response2.data.shippingHistoryFilterPrefs[
                    "Transaction Number"
                  ]
              });
            } else {
              window.scrollTo(0, 0);
              this.setGlobalError(response2.data.actionErrors);
            }
          })
          .catch((error) => {
            window.scrollTo(0, 0);
            this.setGlobalError([
              "We're sorry. An unexpected error has occurred."
            ]);
            console.log(error);
          })
          .finally(() => {
            this.props.toggleSpinner(false);
          });
      })
      .catch((error) => {
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        console.log(error);
      });
  }

  componentDidUpdate() {
    // Focus on the top element on the page after loading
    if (
      this.state.focusOnTop &&
      this.state.isLoaded &&
      this.state.historyPref
    ) {
      document.getElementById("shipping-history").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
    // Focus on the newest dropdown after selecting to add another sort column
    if (this.state.focusOnRow) {
      document.getElementById("sort-by-dropdown" + this.newestColumnId).focus({
        preventScroll: true
      });
      this.setState({
        focusOnRow: false
      });
    }
    // Focus on the showing results section
    if (this.state.focusOnShowingResults) {
      document.getElementById("showing-results-section").focus();
      this.setState({
        focusOnShowingResults: false
      });
    }
  }

  advancedSearch = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        advanceSearch: !prevState.advanceSearch
      };
    });
  };

  controlSearchBy = (e) => {
    analytics.shippingHistory_Search(e.target.value);
    this.setState({
      firstName: "",
      lastName: "",
      company: "",
      transactionNumber: "",
      labelNumber: "",
      startDate: "",
      searchBy: e.target.value
    });
  };

  changeInvoiceDate = (e) => (input) => {
    this.setState({ [e]: input });
  };

  filterColumn = (e) => {
    this.props.toggleSpinner(true);
    let sortFilterPrefs = [
      "Delivery Address:" + this.state["Delivery Address"],
      "Label Cost:" + this.state["Label Cost"],
      "Label Number:" + this.state["Label Number"],
      "Payment Method:" + this.state["Payment Method"],
      "Payment Status:" + this.state["Payment Status"],
      "Service:" + this.state["Service"],
      "Shipping Status:" + this.state["Shipping Status"],
      "Transaction Date:" + this.state["Transaction Date"],
      "Transaction Number:" + this.state["Transaction Number"]
    ];

    let dataToSend = { sortFilterPrefs };

    let objectToPassDown = {
      // shippingHistoryFilterPrefs: {
      "Delivery Address": this.state["Delivery Address"],
      "Label Cost": this.state["Label Cost"],
      "Label Number": this.state["Label Number"],
      "Payment Method": this.state["Payment Method"],
      "Payment Status": this.state["Payment Status"],
      Service: this.state["Service"],
      "Shipping Status": this.state["Shipping Status"],
      "Transaction Date": this.state["Transaction Date"],
      "Transaction Number": this.state["Transaction Number"]
      // }
    };
    axios
      .post("/go/cnsrest/saveShipHistPref/filter", dataToSend)
      .then((response) => {
        this.props.toggleSpinner(false);
        this.setState({
          historyPref: true,
          fetchedFilterData: objectToPassDown
        });
      })
      .catch((error) => {
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        console.log(error);
      });
  };

  sortColumn = (e) => {
    //     sortFilterPrefs: ["First Name:A", "Company Name:D", "Label Number:A", "Transaction Date:D"]
    // 0: "First Name:A"
    // 1: "Company Name:D"
    // 2: "Label Number:A"
    // 3: "Transaction Date:D"
    // sortFilterPrefs;

    this.props.toggleSpinner(true);
    let sortFilterPrefs = [];
    //START HERE
    for (let i = 0; i < this.state.sortColumnsAdded.length; i++) {
      if (
        this.state.sortColumnsAdded[i] !== undefined &&
        this.state.sortColumnsAdded[i][0] !== "Choose Column"
      ) {
        let ascDescConvertToAD =
          this.state.sortColumnsAdded[i][1] === "asc" ? "A" : "D";
        sortFilterPrefs.push(
          String(this.state.sortColumnsAdded[i][0]) + ":" + ascDescConvertToAD
        );
      }
    }

    this.callSortEndpoint(sortFilterPrefs);
  };

  callSortEndpoint = (sortFilterPrefs) => {
    let dataToSend = { sortFilterPrefs };
    axios
      .post("/go/cnsrest/saveShipHistPref/sort", dataToSend)
      .then((response) => {
        this.setState({
          historyPref: true
        });
        this.fetchSortedShippingHistory();
      })
      .catch((error) => {
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        console.log(error);
      });
  };

  changeSortPreferences = (old, changedTo, number) => {
    // let newObjState = Object.assign([], JSON.parse(JSON.stringify(this.state.fetchedSortData)));
    // delete newObjState[item];
    // newObjState[changedTo] = "";
    // let newObjState2;
    // if ((this.state.sortColumnCount.length = 0)) {
    //   newObjState2 = [];
    // } else {
    //   newObjState2 = Object.assign([], JSON.parse(JSON.stringify(this.state.sortColumnCount)));
    // }
    // if (newObjState2[item]) {
    //   delete newObjState2[item];
    // }
    // this.setState({ fetchedSortData: newObjState, sortColumnCount: newObjState2 });

    //////USE THIS FOR REMOVAL

    // this.setState(state => {
    //   const sortColumnsAdded = state.sortColumnsAdded.filter((item, j) => old !== item[0]);

    //   return {
    //     sortColumnsAdded
    //   };
    // });
    //////USE THIS FOR REMOVAL
    let newObjState = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.sortColumnsAdded))
    );

    for (let i = 0; i < newObjState.length; i++) {
      if (old === newObjState[i][0] && number === newObjState[i][2]) {
        newObjState[i][0] = changedTo;
      }
    }

    this.setState({
      sortColumnsAdded: newObjState
    });

    // this.setState(state => {
    //   const sortColumnsAdded = state.sortColumnsAdded.filter((item, j) => old !== item[0]);

    //   return {
    //     sortColumnsAdded
    //   };
    // });

    // for (var i = 0; this.state.sortColumnsAdded.length; i++) {
    //   if (this.state.sortColumnsAdded[i][0] === old) {
    //     this.state.sortColumnsAdded[i][0] = changedTo;
    //   }
    // }

    // let joined = this.state.sortColumnsAdded.concat(changedTo);
    // this.setState({ sortColumnsAdded: joined });
  };

  removeSortPreferences = (idKey) => {
    this.setState((state) => {
      const sortColumnsAdded = state.sortColumnsAdded.filter(
        (item, j) => idKey !== item[2]
      );

      return {
        sortColumnsAdded
      };
    });
  };

  clearSortColumns = () => {
    this.props.toggleSpinner(true);
    this.setState({
      sortColumnsAdded: []
    });
    this.callSortEndpoint([]);
  };

  clickAscDesc = (e, id, sortProperty) => {
    let newObjState = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.sortColumnsAdded))
    );

    for (let i = 0; i < newObjState.length; i++) {
      if (id === String(newObjState[i][2])) {
        newObjState[i][1] = sortProperty;
      }
    }

    this.setState({ sortColumnsAdded: newObjState });
  };

  // Called when the Search button is clicked
  searchLabels = () => {
    analytics.shippingHistory_Search(this.state.searchBy);
    this.searchClicked = true;
    this.customDataToSend = {
      startPage: 1,
      recordsPerPage: this.state.shippingHistoryRecordsPerPagePrefs,
      requestType: this.state.searchBy,
      firstName: this.state.firstName || "",
      lastName: this.state.lastName || "",
      company: this.state.company || "",
      orderId: this.state.transactionNumber,
      trackingNumber: this.state.labelNumber,
      startDate: this.state.shippingDate ? this.state.shippingDate : ""
    };
    this.fetchSortedShippingHistory(1);
  };

  // Called whenever the user changes the number of results per page or
  // page to view, or clicks the Search or Sort buttons.
  fetchSortedShippingHistory = (startPage, labelsPerPage) => {
    this.props.toggleSpinner(true);
    let dataToSend;
    if (this.searchClicked) {
      // True only if the Search button is clicked.
      // All fields used in the search criteria are updated.
      dataToSend = this.customDataToSend;
    } else if (this.customDataToSend) {
      // This is defined only if the Search button was clicked previously.
      // Here, only the start page and records per page fields are updated.
      this.customDataToSend = {
        ...this.customDataToSend,
        startPage: startPage !== undefined ? startPage : 1,
        recordsPerPage:
          labelsPerPage !== undefined
            ? labelsPerPage
            : this.state.shippingHistoryRecordsPerPagePrefs
      };
      dataToSend = this.customDataToSend;
    } else {
      // This block is encountered only if the Search button has never been
      // clicked. The start page and records per page fields are set, and the
      // others are left blank or at their defaults.
      dataToSend = {
        startPage: startPage !== undefined ? startPage : 1,
        recordsPerPage:
          labelsPerPage !== undefined
            ? labelsPerPage
            : this.state.shippingHistoryRecordsPerPagePrefs,
        requestType: "1",
        firstName: "",
        lastName: "",
        company: "",
        orderId: "",
        trackingNumber: "",
        startDate: ""
      };
    }
    axios
      .post("/go/cnsrest/fetchShippingHistory", dataToSend)
      .then((response) => {
        this.props.toggleSpinner(false);
        this.searchClicked = false;
        if (
          (!response.data.fieldErrors ||
            Object.keys(response.data.fieldErrors).length === 0) &&
          (!response.data.actionErrors ||
            response.data.actionErrors.length === 0)
        ) {
          this.setState({
            fetchedShippingHistoryInit: response.data,
            shippingHistoryRecordsPerPagePrefs:
              response.data.shippingHistoryRecordsPerPagePrefs,
            pageNum: startPage !== undefined ? startPage : 1,
            focusOnShowingResults: true
          });
          this.setGlobalError([], []);
        } else {
          window.scrollTo(0, 0);
          this.setGlobalError(
            response.data.fieldErrors,
            response.data.actionErrors
          );
        }
      })
      .catch((error) => {
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        console.log(error);
      });
  };

  refreshOrderItems = (e) => {
    let dataToSend = {
      startPage: 1,
      recordsPerPage: this.state.shippingHistoryRecordsPerPagePrefs,
      requestType: "1",
      firstName: "",
      lastName: "",
      company: "",
      orderId: "0",
      trackingNumber: "",
      startDate: ""
    };
    this.props.toggleSpinner(true);
    axios
      .post("/go/cnsrest/shippingHistoryInit", dataToSend)
      .then((response) => {
        this.setState({
          fetchedShippingHistoryInit: response.data
        });
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        console.log(error);
      })
      .finally((f) => {
        this.props.toggleSpinner(false);
      });
  };

  renderShowingResults = () => {
    const totalOrders = this.state.fetchedShippingHistoryInit.orders[0]
      ? this.state.fetchedShippingHistoryInit.orders[0].totalRows
      : 0;
    if (totalOrders > 0) {
      const startingOrder =
        1 +
        (this.state.pageNum - 1) *
          this.state.shippingHistoryRecordsPerPagePrefs;
      let endingOrder =
        startingOrder + this.state.shippingHistoryRecordsPerPagePrefs - 1;
      if (endingOrder > totalOrders) {
        endingOrder = totalOrders;
      }
      return (
        <React.Fragment>
          Showing Results {startingOrder}-{endingOrder} of {totalOrders}
        </React.Fragment>
      );
    } else {
      return <React.Fragment />;
    }
  };

  // For a given row, the available options in the dropdown include only those
  // that have not yet been chosen by the user for any row, and also the
  // currently selected option.
  renderSortColumnOptions = (index) => {
    // Get only the column names that have been added by the user
    let sortColumnNamesAdded = [];
    for (let i = 0; i < this.state.sortColumnsAdded.length; i++) {
      sortColumnNamesAdded.push(this.state.sortColumnsAdded[i][0]);
    }
    // Display the options
    return (
      <React.Fragment>
        <option value="Choose Column">Choose Column</option>
        {this.state.sortColumnOptions.map((item) => {
          return (
            <React.Fragment key={item}>
              {!sortColumnNamesAdded.includes(item) ||
              item === this.state.sortColumnsAdded[index][0] ? (
                <option value={item}>{item}</option>
              ) : (
                <React.Fragment />
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  dragAndDropSort = (moved) => {
    if (this.state.sortColumnsAdded.length > 1) {
      const items = Array.from(this.state.sortColumnsAdded);
      const [reorderedItem] = items.splice(moved.source.index, 1);
      items.splice(moved.destination.index, 0, reorderedItem);

      this.setState({
        sortColumnsAdded: items
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        />
        {this.state.isLoaded && this.state.historyPref ? (
          <div
            className="Shipping_History_Container"
            id="shipping-history"
            tabIndex="-1"
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="d-md-none responsive-header">
                    Shipping History
                  </h2>
                  <h2>
                    Account # {this.state.fetchedShippingHistoryInit.accountId}
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12 gray-box">
                  <div className="gray-box-wrapper">
                    <div className="row">
                      <div className="col-12 col-md-3 form-group">
                        <label htmlFor="search-by-dropdown-id">Search by</label>
                        <select
                          id="search-by-dropdown-id"
                          className="form-control search-by-dropdown dropdown"
                          onChange={(e) => this.controlSearchBy(e)}
                        >
                          <option value="1">All Labels</option>
                          <option value="2">Pending Transactions</option>
                          <option value="4">Name/Company</option>
                          <option value="1">Transaction/Label</option>
                          <option value="3">Refund Transactions</option>
                        </select>
                      </div>
                      <div className="col-12 col-md-5">
                        {this.state.searchBy === "4" ? (
                          <div className="row">
                            <div className="col-12 col-md-4 form-group ">
                              <label htmlFor="search-by-first-name-id">
                                First Name
                              </label>
                              <input
                                tabIndex="0"
                                type="text"
                                id="search-by-first-name-id"
                                className="form-control"
                                value={this.state.firstName}
                                onChange={(e) =>
                                  this.setState({
                                    firstName: e.target.value
                                  })
                                }
                              />
                            </div>
                            <div className="col-12 col-md-4 form-group ">
                              <label htmlFor="search-by-last-name-id">
                                Last Name
                              </label>
                              <input
                                tabIndex="0"
                                type="text"
                                id="search-by-last-name-id"
                                className="form-control"
                                value={this.state.lastName}
                                onChange={(e) =>
                                  this.setState({
                                    lastName: e.target.value
                                  })
                                }
                              />
                            </div>
                            <div className="col-12 col-md-4 form-group ">
                              <label htmlFor="search-by-company-id">
                                Company
                              </label>
                              <input
                                tabIndex="0"
                                type="text"
                                id="search-by-company-id"
                                className="form-control"
                                value={this.state.company}
                                onChange={(e) =>
                                  this.setState({
                                    company: e.target.value
                                  })
                                }
                              />
                            </div>
                          </div>
                        ) : this.state.searchBy === "1" ? (
                          <div className="row">
                            <div className="col-12 col-md-4 form-group ">
                              <label htmlFor="search-by-transaction-number-id">
                                Transaction Number
                              </label>
                              <input
                                tabIndex="0"
                                maxLength="15"
                                type="text"
                                id="search-by-transaction-number-id"
                                className="form-control"
                                value={this.state.transactionNumber}
                                onChange={(e) =>
                                  this.setState({
                                    transactionNumber: e.target.value
                                  })
                                }
                              />
                            </div>
                            <div className="col-12 col-md-4 form-group ">
                              <label htmlFor="search-by-label-number-id">
                                Label Number
                              </label>
                              <input
                                tabIndex="0"
                                maxLength="30"
                                type="text"
                                id="search-by-label-number-id"
                                className="form-control"
                                value={this.state.labelNumber}
                                onChange={(e) =>
                                  this.setState({
                                    labelNumber: e.target.value
                                  })
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <React.Fragment />
                        )}
                      </div>

                      <div className="col-12 col-md-2 form-group calendar-date-30-days">
                        <label htmlFor="input-date" className="inputLabel">
                          Date + 31 days
                        </label>
                        <DateRangeSelector
                          changeNonNestedFields={this.changeInvoiceDate}
                          shippingDate={this.state.shippingDate}
                        />
                      </div>
                      <div className="col-2 form-group">
                        <button
                          className="btn-primary shipping-history-search-btn"
                          tabIndex="0"
                          onClick={() => this.searchLabels()}
                          aria-label="Search for labels"
                        >
                          Search
                        </button>
                      </div>
                    </div>

                    {this.props.shouldPromoteCnsv2 && (
                      <div className="row">
                        <div className="col-12">
                          {this.props.lookupCodes["cnsb_post_decoupling"] ===
                          "true" ? (
                            <div className="enhanced-experience-text">
                              <strong>
                                *Click-N-Ship Shipping History does not contain
                                labels from enhanced experience:
                              </strong>{" "}
                              All labels purchased in the enhanced Click-N-Ship
                              experience will not be searchable in this history.
                              If you need to search for labels purchased in the
                              enhanced experience, please navigate to the{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                  this.props.lookupCodes[
                                    "cnsb_shipping_history_page"
                                  ]
                                }
                                className="inline-link secondary"
                              >
                                <u>CNSv2 Shipping History.</u>
                              </a>
                            </div>
                          ) : (
                            <div className="enhanced-experience-text">
                              <strong>
                                The new Click-N-Ship experience is available to
                                you!
                              </strong>{" "}
                              Have you tried our enhanced label creation
                              experience? The new, modernized CNSv2 experience
                              allows you to manage multiple labels at once,
                              import from a spreadsheet, and more!{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                  this.props.lookupCodes["cnsb_landing_page"]
                                }
                                className="inline-link secondary"
                              >
                                <u>Switch to New Experience</u>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div
                        className={
                          "col-12 advanced-search-link" +
                          (this.props.shouldPromoteCnsv2
                            ? " enhanced-experience-styling"
                            : "")
                        }
                      >
                        <h4>
                          <button
                            type="button"
                            className={
                              "button-link" +
                              (this.state.advanceSearch ? " active" : "")
                            }
                            onClick={(e) => this.advancedSearch(e)}
                            aria-expanded={
                              this.state.advanceSearch ? "true" : "false"
                            }
                          >
                            Advanced Search
                          </button>
                        </h4>
                      </div>
                    </div>

                    {this.state.advanceSearch ? (
                      <div className="row advanced-search-options">
                        <div className="col-12 col-lg-6 adv-manage-table-columns">
                          <div className="row">
                            <div className="col-12">
                              <h3>Manage Table Columns</h3>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 checkboxes container">
                              <div className="checkbox-wrap">
                                <div className="checkbox-container">
                                  <label
                                    className="checkbox-component"
                                    htmlFor="adv-transaction-date-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="adv-transaction-date-checkbox"
                                      onChange={(e) =>
                                        this.setState({
                                          "Transaction Date":
                                            e.target.checked === true
                                              ? "S"
                                              : "H"
                                        })
                                      }
                                      checked={
                                        this.state["Transaction Date"] === "S"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="checkbox" />
                                    Transaction Date
                                  </label>
                                </div>
                                <div className="checkbox-container">
                                  <label
                                    className="checkbox-component"
                                    htmlFor="adv-order-number-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="adv-order-number-checkbox"
                                      onChange={(e) =>
                                        this.setState({
                                          "Transaction Number":
                                            e.target.checked === true
                                              ? "S"
                                              : "H"
                                        })
                                      }
                                      checked={
                                        this.state["Transaction Number"] === "S"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="checkbox" />
                                    Order Number
                                  </label>
                                </div>
                                <div className="checkbox-container">
                                  <label
                                    className="checkbox-component"
                                    htmlFor="adv-service-type-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="adv-service-type-checkbox"
                                      onChange={(e) =>
                                        this.setState({
                                          Service:
                                            e.target.checked === true
                                              ? "S"
                                              : "H"
                                        })
                                      }
                                      checked={
                                        this.state["Service"] === "S"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="checkbox" />
                                    Service Type
                                  </label>
                                </div>
                              </div>
                              <div className="checkbox-wrap">
                                <div className="checkbox-container">
                                  <label
                                    className="checkbox-component"
                                    htmlFor="adv-shipping-address-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="adv-shipping-address-checkbox"
                                      onChange={(e) =>
                                        this.setState({
                                          "Delivery Address":
                                            e.target.checked === true
                                              ? "S"
                                              : "H"
                                        })
                                      }
                                      checked={
                                        this.state["Delivery Address"] === "S"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="checkbox" />
                                    Shipping Address
                                  </label>
                                </div>
                                <div className="checkbox-container">
                                  <label
                                    className="checkbox-component"
                                    htmlFor="adv-label-number-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="adv-label-number-checkbox"
                                      onChange={(e) =>
                                        this.setState({
                                          "Label Number":
                                            e.target.checked === true
                                              ? "S"
                                              : "H"
                                        })
                                      }
                                      checked={
                                        this.state["Label Number"] === "S"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="checkbox" />
                                    Label Number
                                  </label>
                                </div>
                              </div>
                              <div className="checkbox-wrap">
                                <div className="checkbox-container">
                                  <label
                                    className="checkbox-component"
                                    htmlFor="adv-shipping-status-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="adv-shipping-status-checkbox"
                                      onChange={(e) =>
                                        this.setState({
                                          "Shipping Status":
                                            e.target.checked === true
                                              ? "S"
                                              : "H"
                                        })
                                      }
                                      checked={
                                        this.state["Shipping Status"] === "S"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="checkbox" />
                                    Shipping Status
                                  </label>
                                </div>
                              </div>
                              <div className="checkbox-wrap">
                                <div className="checkbox-container">
                                  <label
                                    className="checkbox-component"
                                    htmlFor="adv-payment-method-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="adv-payment-method-checkbox"
                                      onChange={(e) =>
                                        this.setState({
                                          "Payment Method":
                                            e.target.checked === true
                                              ? "S"
                                              : "H"
                                        })
                                      }
                                      checked={
                                        this.state["Payment Method"] === "S"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="checkbox" />
                                    Payment Method
                                  </label>
                                </div>
                                <div className="checkbox-container">
                                  <label
                                    className="checkbox-component"
                                    htmlFor="adv-payment-status-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="adv-payment-status-checkbox"
                                      onChange={(e) =>
                                        this.setState({
                                          "Payment Status":
                                            e.target.checked === true
                                              ? "S"
                                              : "H"
                                        })
                                      }
                                      checked={
                                        this.state["Payment Status"] === "S"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="checkbox" />
                                    Payment Status
                                  </label>
                                </div>
                                <div className="checkbox-container">
                                  <label
                                    className="checkbox-component"
                                    htmlFor="adv-cost-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="adv-cost-checkbox"
                                      onChange={(e) =>
                                        this.setState({
                                          "Label Cost":
                                            e.target.checked === true
                                              ? "S"
                                              : "H"
                                        })
                                      }
                                      checked={
                                        this.state["Label Cost"] === "S"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="checkbox" />
                                    Cost
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div id="update-columns-desktop">
                              <button
                                type="button"
                                className="btn-primary adv-sort-button"
                                tabIndex="0"
                                onClick={(e) => this.filterColumn(e)}
                              >
                                Update Columns
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d-md-none">
                              <div className="button-container">
                                <button
                                  type="button"
                                  className="btn-primary adv-update-columns"
                                  tabIndex="0"
                                >
                                  Update Columns
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 adv-sort-labels">
                          <div className="row">
                            <div className="col-12">
                              <h3>Sort Labels</h3>
                            </div>
                          </div>
                          {/* 
                          {this.state.sortColumnCount.map((item, keys) => (
                            <div id="sortable" key={item}>
                              <div className="row adv-sort-by">
                                <img src="/click-n-ship/images/grid-view-icon.svg" alt="Sort By handle" className="sort-by-handle" />
                                <div className="col-7 col-md-6 form-group">
                                  <label>Sort by</label>
                                  <select className="form-control dropdown" onChange={e => this.changeSortPreferences(item)(e.target.value)}>
                                    <option value="Choose Column">Choose Column</option>
                                    <option value=">Label Number">Label Number</option>
                                    <option value="Shipping Status">Shipping Status</option>
                                    <option value="Transaction Number">Transaction Number</option>
                                    <option value="First Name">First Name</option>
                                    <option value="Service">Service</option>
                                    <option value="Transaction Date">Transaction Date</option>
                                    <option value="Company Name">Company Name</option>
                                    <option value="Payment Status">Payment Status</option>
                                    <option value="Label Cost">Label Cost</option>
                                    <option value="City">City</option>
                                    <option value="State">State</option>
                                    <option value="Postal Code">Postal Code</option>
                                    <option value="Province">Province</option>
                                    <option value="Country">Country</option>
                                    <option value="Payment Method">Payment Method</option>
                                  </select>
                                </div>
                                <div className="radio-container horizontal">
                                  <input id="adv-sort-desc-radio" type="radio" className="radio-button" name="adv-sort-radio" tabIndex="0" />
                                  <label htmlFor="adv-sort-desc-radio">
                                    <strong>
                                      <img src="/click-n-ship/images/descending-icon.svg" alt="Descending icon" />
                                    </strong>
                                  </label>
                                </div>
                                <div className="radio-container horizontal">
                                  <input id="adv-sort-asc-radio" type="radio" className="radio-button" name="adv-sort-radio" tabIndex="0" />
                                  <label htmlFor="adv-sort-asc-radio">
                                    <img src="/click-n-ship/images/ascending-icon.svg" alt="Ascending icon" />
                                  </label>
                                </div>
                                <a href="#" className="adv-remove-link">
                                  <strong className="d-none d-md-inline">Remove</strong>
                                </a>
                              </div>
                            </div>
                          ))} */}

                          <div id="sortable">
                            <DragDropContext
                              onDragEnd={(e) => this.dragAndDropSort(e)}
                            >
                              <Droppable droppableId="characters">
                                {(provided) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="characters"
                                  >
                                    {Object.keys(
                                      this.state.sortColumnsAdded
                                    ).map((item, keys) => (
                                      <Draggable
                                        key={
                                          this.state.sortColumnsAdded[keys][2]
                                        }
                                        draggableId={String(
                                          this.state.sortColumnsAdded[keys][2]
                                        )}
                                        index={keys}
                                      >
                                        {(provided) => (
                                          <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                          >
                                            <div
                                              className="row adv-sort-by characters-thumb"
                                              key={
                                                this.state.sortColumnsAdded[
                                                  keys
                                                ][2]
                                              }
                                            >
                                              <img
                                                src={gridViewIcon}
                                                alt="Sort By handle"
                                                className="sort-by-handle"
                                              />

                                              <div className="col-7 col-md-6 form-group">
                                                <label
                                                  htmlFor={
                                                    "sort-by-dropdown" +
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][2]
                                                  }
                                                >
                                                  Sort by
                                                </label>
                                                <select
                                                  id={
                                                    "sort-by-dropdown" +
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][2]
                                                  }
                                                  className="form-control dropdown"
                                                  value={
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][0]
                                                  }
                                                  onChange={(e) =>
                                                    this.changeSortPreferences(
                                                      this.state
                                                        .sortColumnsAdded[
                                                        keys
                                                      ][0],
                                                      e.target.value,
                                                      this.state
                                                        .sortColumnsAdded[
                                                        keys
                                                      ][2]
                                                    )
                                                  }
                                                >
                                                  {this.renderSortColumnOptions(
                                                    keys
                                                  )}
                                                </select>
                                              </div>

                                              <div className="radio-container horizontal">
                                                <input
                                                  id={
                                                    "adv-sort-desc-radio" +
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][2]
                                                  }
                                                  type="radio"
                                                  className=""
                                                  name={
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][2]
                                                  }
                                                  tabIndex="0"
                                                  checked={
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][1] === "desc"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) =>
                                                    this.clickAscDesc(
                                                      e,
                                                      e.target.name,
                                                      "desc"
                                                    )
                                                  }
                                                />
                                                <label
                                                  htmlFor={
                                                    "adv-sort-desc-radio" +
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][2]
                                                  }
                                                >
                                                  <strong>
                                                    <img
                                                      src={descendingIcon}
                                                      alt="Descending icon"
                                                    />
                                                  </strong>
                                                </label>
                                              </div>
                                              <div className="radio-container horizontal">
                                                <input
                                                  id={
                                                    "adv-sort-asc-radio" +
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][2]
                                                  }
                                                  type="radio"
                                                  className=""
                                                  name={
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][2]
                                                  }
                                                  tabIndex="0"
                                                  checked={
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][1] === "asc"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) =>
                                                    this.clickAscDesc(
                                                      e,
                                                      e.target.name,
                                                      "asc"
                                                    )
                                                  }
                                                />
                                                <label
                                                  htmlFor={
                                                    "adv-sort-asc-radio" +
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][2]
                                                  }
                                                >
                                                  <img
                                                    src={ascendingIcon}
                                                    alt="Ascending icon"
                                                  />
                                                </label>
                                              </div>
                                              <button
                                                type="button"
                                                className="adv-remove-link button-link"
                                                onClick={() =>
                                                  this.removeSortPreferences(
                                                    this.state.sortColumnsAdded[
                                                      keys
                                                    ][2]
                                                  )
                                                }
                                                aria-label="Remove sort column"
                                              >
                                                <strong className="d-none d-md-inline">
                                                  Remove
                                                </strong>
                                              </button>
                                              {provided.placeholder}
                                            </div>
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>

                          {this.state.sortColumnsAdded.length <
                          this.state.sortColumnOptions.length ? (
                            <div className="row">
                              <div className="col-12 adv-add-column-link">
                                <button
                                  type="button"
                                  className="inline-link button-link"
                                  onClick={(e) => {
                                    this.newestColumnId =
                                      this.newestColumnId + 1;
                                    this.setState((prevState) => {
                                      // Add the new row
                                      return {
                                        sortColumnsAdded: prevState.sortColumnsAdded.concat(
                                          [
                                            [
                                              "Choose Column",
                                              "asc",
                                              this.newestColumnId
                                            ]
                                          ]
                                        ),
                                        focusOnRow: true
                                      };
                                    });
                                  }}
                                >
                                  Add another sort column
                                </button>
                              </div>
                            </div>
                          ) : null}
                          <div className="row">
                            <div className="col-12">
                              <div className="button-container">
                                <button
                                  type="button"
                                  className="btn-primary adv-sort-button"
                                  tabIndex="0"
                                  onClick={(e) => this.sortColumn(e)}
                                  aria-label="Sort columns"
                                >
                                  Sort
                                </button>
                              </div>
                              <div className="button-container">
                                <button
                                  type="button"
                                  className="btn-primary button--white adv-clear-button"
                                  tabIndex="0"
                                  onClick={this.clearSortColumns}
                                  aria-label="Clear sort columns"
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <React.Fragment />
                    )}
                  </div>
                </div>
              </div>
              <div
                id="showing-results-section"
                className="shipping-history-showing-results row"
                tabIndex="-1"
              >
                <div className="col-12 form-group">
                  <h4>{this.renderShowingResults()}</h4>
                </div>
                <div className="col-12 form-group">
                  <p>
                    Select labels from the table below by checking the
                    corresponding checkbox, choose an action, and click "Go" to
                    complete the task.
                  </p>
                </div>
              </div>
              {/* <div 
                style={{
                  color: "#333366",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              >
                The USPS Loyalty Program allows business customers to earn
                shipping benefits based on their spending. Visit the{" "}
                <a
                  id="pturl"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.props.lookupCodes["cns.loyalty_portal"]}
                  style={{ color: "#3573b1 !important" }}
                  className="inline-link secondary"
                >
                  USPS Loyalty Customer Portal®
                </a>{" "}
                for more details.
              </div> */}
              <ShippingHistoryTable
                shippingHistoryInit={this.state.fetchedShippingHistoryInit}
                columnFilterPrefs={this.state.fetchedFilterData}
                sortPrefs={
                  this.state.fetchedShipHistPref.shippingHistorySortPrefs
                }
                fetchSortedShippingHistory={this.fetchSortedShippingHistory}
                recordsPerPage={this.state.shippingHistoryRecordsPerPagePrefs}
                pageNum={this.state.pageNum}
                lookupCodes={this.props.lookupCodes}
                toggleSpinner={this.props.toggleSpinner}
                refreshOrderItems={this.refreshOrderItems}
              />
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}{" "}
      </React.Fragment>
    );
  }
}

export default withRouter(ShippingHistory);
