import React from "react";

const AlertMessage = (props) => {
  const { spanId, displayAlertMessage, alertMessage } = props;

  return (
    <span
      role="alert"
      id={spanId}
      className="error-message"
      tabIndex="-1"
      style={
        !displayAlertMessage
          ? {
              display: "none"
            }
          : {
              display: "block"
            }
      }
    >
      {alertMessage}
    </span>
  );
};

export default AlertMessage;
