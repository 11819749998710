import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import axios from "axios";

const LandedCostEstimate = (props) => {
  const {
    // Modal controls
    isOpen,
    toggle,
    // Props for making the Landed Cost API call
    callLandedCostApi,
    toggleCallToLandedCostApi,
    // Props relating to inputs/outputs of the Landed Cost API call
    items,
    returnAddressCountryCode,
    deliveryAddress,
    serviceInfo,
    landedCostInputs,
    setLandedCostInputs,
    setReadyToCallLandedCost,
    tlcAmounts,
    setTlcAmounts,
    // Props relating to the items table
    getPoundsAndOz,
    // Props relating to the summary section
    cnsCart,
    // Other required props
    toggleLoadingBar,
    get2LetterCountryCode
  } = props;

  // Flag that indicates if a landed cost lookup failed
  const [landedCostError, setLandedCostError] = useState(false);

  // Object that maps the sum of costs for each item
  const [itemizedAmounts, setItemizedAmounts] = useState({});

  // Update the landed cost inputs when appropriate
  useEffect(() => {
    // Mapping of our own product classes to the service levels obtained from Zonos
    const serviceLevelMapping = {
      AMB: "usps_airmail_m_bags",
      EXM: "usps_priority_express",
      FCPSR: "usps_first_class",
      IEM: "usps_priority_express_international",
      IFC: "usps_first_class_package_international",
      IPM: "usps_priority_mail_international",
      PRI: "usps_priority"
    };

    // Format the items array of objects for the request JSON
    const getFormattedItems = () => {
      const itemsFormatted = [];
      for (let index in items) {
        const item = items[index];
        // Only add items with HS Tariff Codes
        if (item.hsTariffNbr) {
          itemsFormatted.push({
            id: item.labelItemId,
            amount: item.unitValue,
            brand: item.tlcBrand || null,
            category: item.tlcCategory,
            color: item.tlcItemColor || null,
            country_of_origin: get2LetterCountryCode(
              item.countryId?.countryDbId
            ),
            description_customs: item.itemDesc,
            detail: item.tlcItemDetail || null,
            dimensions: {
              height: item.tlcProductHeight,
              length: item.tlcProductLength,
              unit: "inch",
              width: item.tlcProductWidth
            },
            hs_code: item.hsTariffNbr,
            quantity: item.itemQty,
            upc_code: item.tlcUpcCode || null,
            weight: Number(item.unitWeightOz),
            weight_unit: "ounce"
          });
        }
      }
      return itemsFormatted;
    };

    // Update the landed cost inputs
    setLandedCostInputs({
      currency: "USD",
      items: getFormattedItems(),
      ship_from_country: returnAddressCountryCode,
      ship_to: {
        city: deliveryAddress.cityName,
        country: deliveryAddress.countryCode,
        postal_code: deliveryAddress.postalCode,
        state: deliveryAddress.province
      },
      shipping: {
        amount: serviceInfo.amount,
        service_level: serviceLevelMapping[serviceInfo.productClass]
      }
    });
    // Flag to indicate the inputs for the landed cost API have been
    // prepared
    setReadyToCallLandedCost(true);
  }, [
    items,
    get2LetterCountryCode,
    returnAddressCountryCode,
    deliveryAddress.cityName,
    deliveryAddress.countryCode,
    deliveryAddress.postalCode,
    deliveryAddress.province,
    serviceInfo.amount,
    serviceInfo.productClass,
    setLandedCostInputs
  ]);

  // Call the Landed Cost API
  useEffect(() => {
    if (callLandedCostApi) {
      toggleCallToLandedCostApi(false);
      if (landedCostInputs?.items?.length > 0) {
        toggleLoadingBar(true);
        axios
          .post("/go/cnsrest/tlc/landed-cost", landedCostInputs)
          .then((response) => {
            const amountSubtotal = response?.data?.amount_subtotal;
            if (amountSubtotal) {
              setLandedCostError(false);
              const itemizedDuties = getItemizedAmounts(response.data.duties);
              const itemizedFees = getItemizedAmounts(response.data.fees);
              const itemizedTaxes = getItemizedAmounts(response.data.taxes);
              setItemizedAmounts({
                duties: itemizedDuties,
                fees: itemizedFees,
                taxes: itemizedTaxes
              });
              setTlcAmounts({
                duties: getTotalAmounts(itemizedDuties),
                fees: getTotalAmounts(itemizedFees),
                taxes: getTotalAmounts(itemizedTaxes)
              });
            } else {
              setLandedCostError(true);
              setTlcAmounts(null);
            }
          })
          .catch((error) => {
            setLandedCostError(true);
            setTlcAmounts(null);
            console.log(error);
          })
          .finally(() => {
            toggleLoadingBar(false);
          });
      } else {
        setTlcAmounts(null);
      }
    }
  }, [
    callLandedCostApi,
    toggleLoadingBar,
    toggleCallToLandedCostApi,
    landedCostInputs,
    setTlcAmounts
  ]);

  // Return an object mapping the sum of costs for each item.
  // The type of cost (duties, fees, taxes) is determined by the object array
  // passed to this function.
  // Any costs not linked to an item ID is included in the "other" property.
  const getItemizedAmounts = (costType) => {
    let itemCosts = {};
    let otherCosts = 0;
    for (let i in costType) {
      const costObject = costType[i];
      if (costObject.item_id) {
        if (!itemCosts[costObject.item_id]) {
          itemCosts[costObject.item_id] = 0;
        }
        for (let j in landedCostInputs.items) {
          const itemId = String(landedCostInputs.items[j].id);
          if (costObject.item_id === itemId) {
            itemCosts[costObject.item_id] += costObject.amount;
          }
        }
      } else {
        otherCosts += costObject.amount;
      }
    }
    itemCosts["other"] = otherCosts;
    return itemCosts;
  };

  // Return the total cost for all items.
  // The type of cost (duties, fees, taxes) is determined by the object
  // passed to this function.
  const getTotalAmounts = (costObject) => {
    let totalCost = 0;
    for (let itemId in costObject) {
      totalCost = totalCost + Number(costObject[itemId].toFixed(2));
    }
    return totalCost;
  };

  const closeModal = (e) => {
    setLandedCostError(false);
    toggle(e, false);
  };

  return (
    <Modal id="modal-tlc-estimate" isOpen={isOpen}>
      <div className="modal-content modal-container">
        <div className="modal-header">
          <button
            type="button"
            className="close button-link"
            id="close"
            data-dismiss="modal"
            tabIndex="0"
            onClick={(e) => closeModal(e)}
          >
            <span className="visuallyhidden">Close Modal</span>
          </button>
          <h3 className="modal-title">
            Item Breakdown of Calculated Landed Cost
          </h3>
        </div>
        <div className="modal-body">
          <div className="body-content">
            {!landedCostError ? (
              <React.Fragment>
                <table className="table package-items-table summary">
                  <thead className="items-table-header">
                    <tr className="d-none d-md-table-row">
                      <th className="table-col-align-left" scope="col">
                        Item
                      </th>
                      <th scope="col">Qty</th>
                      <th scope="col">Value</th>
                      <th scope="col">Weight</th>
                      <th scope="col" />
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="step-8-full-table">
                    {items.map((item) => (
                      <React.Fragment key={item.labelItemId}>
                        <tr>
                          <td className="package-items-tlc-desc table-col-align-left">
                            {item.itemDesc}
                            {item.hsTariffNbr && (
                              <React.Fragment>
                                <br />
                                HS Tariff # {item.hsTariffNbr}
                              </React.Fragment>
                            )}
                            {item.countryOfOrigin && (
                              <React.Fragment>
                                <br />
                                Origin: {item.countryOfOrigin}
                              </React.Fragment>
                            )}
                          </td>
                          <td className="package-items-qty tlc-column">
                            {item.itemQty}
                          </td>
                          <td className="package-items-value tlc-column">
                            ${item.unitValue.toFixed(2)}
                          </td>
                          <td className="package-items-weight tlc-column">
                            {getPoundsAndOz(1, item.unitWeightOz)}
                          </td>
                          <td className="package-items-tlc-costs">
                            Duties
                            <br />
                            Taxes
                          </td>
                          <td className="package-items-tlc-costs">
                            {item.hsTariffNbr
                              ? itemizedAmounts?.duties?.[item.labelItemId]
                                ? "$" +
                                  itemizedAmounts.duties[
                                    item.labelItemId
                                  ].toFixed(2)
                                : "$0.00"
                              : "-"}
                            <br />
                            {item.hsTariffNbr
                              ? itemizedAmounts?.taxes?.[item.labelItemId]
                                ? "$" +
                                  itemizedAmounts.taxes[
                                    item.labelItemId
                                  ].toFixed(2)
                                : "$0.00"
                              : "-"}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                    {itemizedAmounts?.duties?.["other"] ||
                    itemizedAmounts?.taxes?.["other"] ? (
                      <tr>
                        <td className="package-items-tlc-desc table-col-align-left">
                          Other costs
                        </td>
                        <td className="package-items-qty tlc-column"></td>
                        <td className="package-items-value tlc-column"></td>
                        <td className="package-items-weight tlc-column"></td>
                        <td className="package-items-tlc-costs">
                          Duties
                          <br />
                          Taxes
                        </td>
                        <td className="package-items-tlc-costs">
                          $
                          {itemizedAmounts?.duties?.["other"]
                            ? itemizedAmounts.duties["other"].toFixed(2)
                            : "0.00"}
                          <br />$
                          {itemizedAmounts?.taxes?.["other"]
                            ? itemizedAmounts.taxes["other"].toFixed(2)
                            : "0.00"}
                        </td>
                      </tr>
                    ) : (
                      <React.Fragment />
                    )}
                    <tr id="tlc-itemized-last-row">
                      <td colSpan="5">Estimated Duties, Taxes and Fees</td>
                      <td>${tlcAmounts?.sum || "0.00"}</td>
                    </tr>
                  </tbody>
                </table>
                <div
                  id="tlc-label-summary-wrapper"
                  className="col-12 col-md-11 col-lg-10 step-five-label-summary priority"
                >
                  <ul className="click-ship-label-summary-wrapper tlc-label-summary">
                    <li className="label-summary-text">
                      <div className="row label-summary-top">
                        <div className="col-12">
                          <h3 className="">Label Summary</h3>
                        </div>
                      </div>
                    </li>
                    {cnsCart.productId.productClass !== "IFC" &&
                    cnsCart.labelInd.insuranceInd === "N" ? (
                      <li className="label-summary-content">
                        <div className="row label-summary-wrapper">
                          <div className="col-9">
                            <p className="label-summary-data">
                              Insurance is covered up to $200
                            </p>
                          </div>
                          <div className="col-3 text-right">
                            <p className="label-summary-price">Free</p>
                          </div>
                        </div>
                      </li>
                    ) : cnsCart.labelInd.insuranceInd === "Y" ? (
                      <li className="label-summary-content">
                        <div className="row label-summary-wrapper">
                          <div className="col-9">
                            <p className="label-summary-data">
                              Insure for the entire package value
                            </p>
                          </div>
                          <div className="col-3 text-right">
                            <p className="label-summary-price">
                              {cnsCart.labelCost.insuranceCostAmt > 0
                                ? "$" +
                                  cnsCart.labelCost.insuranceCostAmt.toFixed(2)
                                : "Free"}
                            </p>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <React.Fragment />
                    )}
                    <li className="label-summary-content">
                      <div className="row label-summary-wrapper">
                        <div className="col-9">
                          <p className="label-summary-data">
                            {cnsCart.productId.productClassDesc
                              .replace("&reg;", "®")
                              .replace("&trade;", "™")}
                            {cnsCart.productId.productDesc && (
                              <React.Fragment>
                                <br />
                                {cnsCart.productId.productDesc}
                              </React.Fragment>
                            )}
                          </p>
                        </div>
                        <div className="col-3 text-right">
                          <p className="label-summary-price">
                            {cnsCart.labelCost.postageAmt > 0
                              ? "$" + cnsCart.labelCost.postageAmt.toFixed(2)
                              : "Free"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <div className="horizontal-line-container">
                      <hr className="horizontal-line label-summary-horizonal-line" />
                    </div>
                    <li className="label-summary-total-content">
                      <div className="row label-summary-wrapper">
                        <div className="col-9">
                          <p className="label-summary-total">Total</p>
                        </div>
                        <div className="col-3 text-right">
                          <p className="label-summary-price">
                            {cnsCart.labelCost.ttlShipCostAmt > 0
                              ? "$" +
                                cnsCart.labelCost.ttlShipCostAmt.toFixed(2)
                              : "Free"}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            ) : (
              <p className="error-message">
                Error retrieving Total Landed Cost estimate.
              </p>
            )}
            <div className="row">
              <div className="col-12 button-wrapper tlc-button-wrapper">
                <div className="button-container">
                  <button
                    className="btn-primary"
                    id="tlc-landed-cost-button"
                    onClick={(e) => closeModal(e)}
                  >
                    Close Summary
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LandedCostEstimate;
