import React, { useState } from "react";
import { Modal } from "reactstrap";

const RemoveScanFormModal = (props) => {
  const { buttonLabel, className, removeScanForm, item } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <React.Fragment>
      <button
        type="button"
        className="request-remove button-link"
        data-toggle="modal"
        data-target="#scan-remove-confirmation-modal"
        data-backdrop="static"
        title=""
        tabIndex="0"
        onClick={toggle}
        aria-label="Remove this label"
      >
        <strong>{buttonLabel}</strong>
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        id="scan-remove-confirmation-modal"
      >
        <div className="modal-content modal-container">
          <div className="modal-header">
            <h3 className="modal-title">
              Are you sure you want to remove this address?
            </h3>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <p
                dangerouslySetInnerHTML={{ __html: item.shipTo.displayAddress }}
              />
            </div>
          </div>
          <div className="modal-buttons">
            <div className="button-container">
              <button
                type="button"
                className="btn-primary remove-selected-button button-link"
                data-dismiss="modal"
                tabIndex="0"
                onClick={(e) => {
                  toggle();
                  removeScanForm(e, item.packageId);
                }}
              >
                Yes
              </button>
            </div>
            <div className="button-container">
              <button
                type="button"
                className="btn-primary button--white"
                data-dismiss="modal"
                tabIndex="0"
                onClick={toggle}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RemoveScanFormModal;
