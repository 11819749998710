import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const ContentsDescPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        e.g., goods subject to quarantine, sanitary/phytosanitary inspection, or
        other restrictions
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Additional Comments Information Icon"
      header="Comments"
      content={content}
    />
  );
};

export default ContentsDescPopover;
