import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const EccnNumberPopover = (props) => {
  const content = (
    <React.Fragment>
      <p></p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="ECCN Number Information Icon"
      header=""
      content={content}
    />
  );
};

export default EccnNumberPopover;
