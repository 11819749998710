import React from "react";
import AssistiveError from "./AssistiveError";

const InputFields = (props) => {
  const {
    // Always required (won't get errors if missing,
    // but styling and accessibility may be affected)
    divClassList,
    inputId,
    labelText,
    inputClassList,
    inputValue,
    inputOnChange,
    // Only needed when the input field is required and/or has error validation
    errorMessage,
    includeAssistiveError,
    // Only needed for popovers/modals
    infoIcon,
    // Only needed for <select> and <textarea> elements
    elementType,
    // Only needed for <select> elements
    optionsList,
    // Only needed if an <input> element is directly wrapped by an outer <div>
    inputDivClassList,
    // Only needed when the field has units (ex. "$", "lbs")
    fieldPrefix,
    fieldSuffix,
    fieldSuffixId,
    // Only needed for certain fields
    inputPlaceholder,
    inputMaxLength,
    inputShowLength,
    // Only needed when the input has special key press logic
    inputOnKeyPress,
    // Only needed when buttons are included
    fieldSuffixButton,
    buttonAriaLabel,
    buttonId,
    buttonClassList,
    buttonOnClick
  } = props;

  return (
    <div className={divClassList + (errorMessage ? " error" : "")}>
      <div className={inputShowLength ? "row" : ""}>
        <div className={inputShowLength ? "col-8" : ""}>
          {includeAssistiveError ? (
            <AssistiveError inputId={inputId} errorMessage={errorMessage} />
          ) : null}
          <label htmlFor={inputId}>
            {labelText} {infoIcon}
          </label>
        </div>
        {inputShowLength && (
          <div className="col-4 field-length">
            {inputValue.length} / {inputMaxLength}
          </div>
        )}
      </div>
      {elementType === "select" ? (
        <select
          name={inputId}
          id={inputId}
          className="form-control dropdown"
          value={inputValue}
          onChange={inputOnChange}
        >
          {typeof optionsList !== "function"
            ? optionsList.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))
            : optionsList()}
        </select>
      ) : elementType === "textarea" ? (
        <textarea
          id={inputId}
          className="form-control extra-input-field"
          value={inputValue}
          placeholder={inputPlaceholder}
          maxLength={inputMaxLength}
          onChange={inputOnChange}
        />
      ) : (
        <React.Fragment>
          <div className={inputDivClassList}>
            {fieldPrefix && (
              <div className="input-group-prepend">
                <span className="input-group-text">{fieldPrefix}</span>
              </div>
            )}
            <input
              name={inputId}
              id={inputId}
              type="text"
              className={inputClassList ? inputClassList : "form-control"}
              value={inputValue}
              placeholder={inputPlaceholder}
              maxLength={inputMaxLength}
              onKeyPress={inputOnKeyPress}
              onChange={inputOnChange}
            />
            {fieldSuffix && (
              <div id={fieldSuffixId} className="input-group-append">
                <span className="input-group-text">{fieldSuffix}</span>
              </div>
            )}
            {fieldSuffixButton ? (
              <button
                aria-label={buttonAriaLabel}
                id={buttonId}
                className={buttonClassList}
                onClick={buttonOnClick}
                tabIndex="-1"
              />
            ) : null}
          </div>
        </React.Fragment>
      )}
      <span className="error-message">{errorMessage}</span>
    </div>
  );
};

export default InputFields;
