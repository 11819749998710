import React from "react";
import { Modal } from "reactstrap";

const CreateAndPrintScanForm = (props) => {
  const {
    buttonLabel,
    className,
    printScanForms,
    certify,
    reprintScanForms,
    showCreatePrintModal,
    toggle,
    scanFormNumber,
    numberOfLabels,
    requestPickupLink
  } = props;

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn-primary create-scan-form-button"
        data-toggle="modal"
        data-target="#scan-form-modal"
        data-backdrop="static"
        onClick={printScanForms}
      >
        {buttonLabel}
      </button>
      {certify ? (
        <Modal
          isOpen={showCreatePrintModal}
          className={className}
          id="scan-form-modal"
        >
          <div className="modal-content modal-container">
            <div className="modal-header">
              <button
                type="button"
                className="close button-link"
                id="close"
                data-dismiss="modal"
                tabIndex="0"
                onClick={(e) => toggle(e, true)}
              >
                <span className="visuallyhidden">Close Modal</span>
              </button>
              <div className="modal-title">
                <h3>SCAN Form Confirmation</h3>
              </div>
              <div className="modal-label">
                <p>
                  <strong>SCAN Form #:</strong> {scanFormNumber}
                </p>
              </div>
              <div className="modal-label">
                <p>Labels(s) Included: {numberOfLabels}</p>
                <p>Printed: {new Date().toGMTString()}</p>
              </div>
            </div>
            <div className="modal-body scan-form">
              <h4>
                Thank you for choosing the United States Postal Service
                <sup>®</sup>.
              </h4>
              <ul>
                <li>
                  <p>
                    Please bring the Shipment Formation Acceptance Notice (SCAN)
                    Form with you to the Post Office or prominently display it
                    with your package(s) for your carrier.
                  </p>
                </li>
                <li>
                  <p>
                    Once the carrier has scanned the SCAN Form, it will be left
                    for you to keep.
                  </p>
                </li>
                <li>
                  <p>
                    Only one SCAN Form per day can be used for Package Pickup.
                    All others from the same day will need to be taken with
                    packages to the Post Office.
                  </p>
                </li>
                <li>
                  <p>
                    Use your individual label numbers to track your packages via
                    Track &amp; Confirm on USPS.com.
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer scan-form button-wrapper">
              <div className="button-container">
                <a
                  href={requestPickupLink}
                  role="button"
                  className="btn-primary"
                  id="modal-confirm"
                  data-dismiss="modal"
                >
                  Request Package Pickup
                </a>
              </div>
              <div className="button-container">
                <button
                  type="button"
                  className="btn-primary button--white"
                  id="modal-cancel"
                  data-dismiss="modal"
                  onClick={reprintScanForms}
                >
                  Reprint Scan Form
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          isOpen={showCreatePrintModal}
          toggle={toggle}
          className={className}
          id="scan-form-modal"
        >
          <div className="modal-content modal-container">
            <div className="modal-header">
              <button
                type="button"
                className="close button-link"
                id="close"
                data-dismiss="modal"
                tabIndex="0"
                onClick={toggle}
              >
                <span className="visuallyhidden">Close Modal</span>
              </button>
              <h3 className="modal-title">Certify SCAN Form</h3>
            </div>
            <div className="modal-body">
              <div className="body-content">
                <p>
                  Please read and agree to the SCAN Form certification terms.
                </p>
              </div>
            </div>
            <div className="modal-footer scan-form button-wrapper">
              <div className="button-container">
                <button
                  type="button"
                  className="btn-primary button--white"
                  data-dismiss="modal"
                  tabIndex="0"
                  onClick={toggle}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default CreateAndPrintScanForm;
