import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import InputFields from "../Subcomponents/InputFields";

const RequestCarrierPickupModal = (props) => {
  const {
    buttonLabel,
    carrierPickupModalToOpen,
    toggle,
    carrierPickupInputs,
    optionsList,
    errorMessages,
    handleCarrierPickupInputs,
    areCarrierPickupErrorsPresent,
    numberInDropOffReturnAddressGroup,
    indexOfLastItemInGroup,
    requestCarrierPickup,
    responseError,
    isForConnectLocal
  } = props;

  const [focusOnInputError, setFocusOnInputError] = useState(false);

  // When there are validation errors, focus on the first field with an error
  useEffect(() => {
    if (focusOnInputError) {
      if (errorMessages.dogAtAddress) {
        document.getElementById("dogAtAddressInput-a11y").focus();
      } else if (errorMessages.packageLocation) {
        document.getElementById("packageLocationInput-a11y").focus();
      } else if (errorMessages.additionalInstructions) {
        document.getElementById("additionalInstructionsInput-a11y").focus();
      } else if (errorMessages.termsConditions) {
        document.getElementById("termsConditionsInput-a11y").focus();
      }
      setFocusOnInputError(false);
    }
  }, [focusOnInputError]);

  // Focus on the response error message after requesting carrier pickup
  useEffect(() => {
    if (responseError) {
      document.getElementById("carrierPickupResponseErrorId").focus();
    }
  }, [responseError]);

  const toggleModal = () => {
    toggle(indexOfLastItemInGroup);
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn-primary"
        tabIndex="0"
        onClick={toggleModal}
      >
        <strong>{buttonLabel}</strong>
      </button>
      <Modal
        isOpen={carrierPickupModalToOpen === String(indexOfLastItemInGroup)}
      >
        <div className="modal-content modal-container">
          <div className="modal-header">
            <button
              type="button"
              className="close button-link"
              id="close"
              data-dismiss="modal"
              tabIndex="0"
              onClick={toggleModal}
            >
              <span className="visuallyhidden">Close Modal</span>
            </button>
            <h3 className="modal-title">
              {(isForConnectLocal ? "USPS Connect Local " : "") +
                "Carrier Pickup"}
            </h3>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <span
                id="dogAtAddressInput-a11y"
                className="error-message"
                tabIndex="-1"
              >
                {errorMessages.dogAtAddress}
              </span>
              <p>*Is there a dog at the pickup address?</p>
              <div className="radio-wrap carrierPickupInputs">
                <div className="radio-container row">
                  <div className="col-6">
                    <input
                      id="dogAtAddressYesRadio"
                      type="radio"
                      className="radio-button first"
                      name="dogAtAddress"
                      value="Y"
                      onChange={(e) => {
                        handleCarrierPickupInputs(e, "dogAtAddress");
                      }}
                      checked={carrierPickupInputs.dogAtAddress === "Y"}
                    />
                    <label
                      htmlFor="dogAtAddressYesRadio"
                      className="carrierPickupRadioSpacing"
                    >
                      Yes, there is a dog at this address.
                    </label>
                  </div>
                  <div className="col-6">
                    <input
                      id="dogAtAddressNoRadio"
                      type="radio"
                      className="radio-button second"
                      name="dogAtAddress"
                      value="N"
                      onChange={(e) => {
                        handleCarrierPickupInputs(e, "dogAtAddress");
                      }}
                      checked={carrierPickupInputs.dogAtAddress === "N"}
                    />
                    <label
                      htmlFor="dogAtAddressNoRadio"
                      className="carrierPickupRadioSpacing"
                    >
                      No, there isn't a dog at this address.
                    </label>
                  </div>
                </div>
              </div>
              <p>Where will you leave your packages?</p>
              <div className="carrierPickupInputs">
                <div className="row carrierPickupInputSpacing">
                  <InputFields
                    divClassList="col-12 col-md-9"
                    errorMessage={errorMessages.packageLocation}
                    includeAssistiveError={true}
                    inputId="packageLocationInput"
                    labelText="*Location of your packages"
                    elementType="select"
                    inputValue={carrierPickupInputs.packageLocation}
                    inputOnChange={(e) => {
                      handleCarrierPickupInputs(e, "packageLocation");
                    }}
                    optionsList={optionsList}
                  />
                </div>
                <div className="row carrierPickupInputSpacing">
                  <InputFields
                    divClassList="col-12 col-md-11"
                    errorMessage={errorMessages.additionalInstructions}
                    includeAssistiveError={true}
                    inputId="additionalInstructionsInput"
                    labelText="Enter any additional instructions"
                    elementType="textarea"
                    inputValue={carrierPickupInputs.additionalInstructions}
                    inputMaxLength="255"
                    inputOnChange={(e) => {
                      handleCarrierPickupInputs(e, "additionalInstructions");
                    }}
                  />
                </div>
                <div id="carrierPickupAdditionalInstructions">
                  {255 - carrierPickupInputs.additionalInstructions.length}{" "}
                  character
                  {255 - carrierPickupInputs.additionalInstructions.length !== 1
                    ? "s"
                    : ""}{" "}
                  remaining
                </div>
              </div>
              <div className="checkbox-wrap">
                <div className="checkbox-container">
                  <span
                    id="termsConditionsInput-a11y"
                    className="error-message"
                    tabIndex="-1"
                  >
                    {errorMessages.termsConditions}
                  </span>
                  <label
                    className="checkbox-component"
                    htmlFor="carrier-pickup-terms-checkbox"
                  >
                    <input
                      type="checkbox"
                      id="carrier-pickup-terms-checkbox"
                      onChange={(e) => {
                        handleCarrierPickupInputs(e, "termsConditions");
                      }}
                      checked={carrierPickupInputs.termsConditions}
                    />
                    <span className="checkbox" />
                    <span>
                      I have read, understand, and agree to the{" "}
                      <a
                        href="https://about.usps.com/who/legal/terms-of-use.htm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </a>
                      <br />
                      Be sure to place your package(s) in a secure location for
                      pickup. The United States Postal Service bears no
                      liability for lost, stolen, or damaged packages. The USPS
                      is also not responsible for service delays when the
                      package has incorrect postage, incomplete postage
                      information, or is otherwise not ready for shipment.
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-buttons">
            <div className="button-container">
              <button
                type="button"
                className="btn-primary"
                data-dismiss="modal"
                tabIndex="0"
                onClick={() => {
                  if (!areCarrierPickupErrorsPresent()) {
                    requestCarrierPickup(
                      numberInDropOffReturnAddressGroup,
                      indexOfLastItemInGroup
                    );
                  } else {
                    setFocusOnInputError(true);
                  }
                }}
              >
                <strong>{buttonLabel}</strong>
              </button>
            </div>
          </div>
          <span
            role="alert"
            id="carrierPickupResponseErrorId"
            className="error-message"
            style={!responseError ? { display: "none" } : { display: "block" }}
            tabIndex="-1"
          >
            {responseError}
          </span>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RequestCarrierPickupModal;
