import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import AddressBook from "../Modals/AddressBook";
import TextMessagePopover from "../Popovers/TextMessagePopover";
import AddressStandardizedPopover from "../Popovers/AddressStandardizedPopover";
import AssistiveError from "../Subcomponents/AssistiveError";
import StatesTerritoriesList from "../Subcomponents/StatesTerritoriesList";

class StepOneReturnAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editClicked: false,
      mobileShow: false
    };

    if (this.props.configInfo.labelInd.shipFromPostalCode) {
      this.props.changeTrackingNotifications("shipFromOtherZipInd")("Y");
    } else {
      this.props.changeTrackingNotifications("shipFromOtherZipInd")("N");
    }
  }

  editClick = () => {
    this.setState({
      editClicked: true,
      focusSection: true
    });
  };

  clickedTrackingNotifications = (e) => {
    this.props.changeTrackingNotifications("ptsNotifyInd")(
      e.currentTarget.checked ? "Y" : "N"
    );
  };

  clickedEmailNotifications = (e) => {
    this.props.configInfo.errors.trackingEmailNotifications !== ""
      ? this.props.clearError("trackingEmailNotifications")
      : this.props.clearError("");
    this.props.changeTrackingNotifications("emailUpdatesInd")(
      e.currentTarget.checked ? "Y" : "N"
    );
  };

  clickedTextNotifications = (e) => {
    this.props.configInfo.errors.trackingTextNotifications !== ""
      ? this.props.clearError("trackingTextNotifications")
      : this.props.clearError("");
    this.props.changeTrackingNotifications("ptsTxtUpdatesInd")(
      e.currentTarget.checked ? "Y" : "N"
    );
  };

  clickedUpdates = (e) => {
    if (e.target.id === "all-updates-radio") {
      this.props.changeTrackingNotifications("allNotificationsUpdate")("Y");
      this.setState({
        sendAllUpdates: true
      });
    } else {
      this.setState({
        sendAllUpdates: false
      });
    }
  };

  clickedShippedOtherZip = (e) => {
    this.props.changeTrackingNotifications("shipFromOtherZipInd")(
      e.currentTarget.checked ? "Y" : "N"
    );
    this.props.changeTrackingNotifications("shipFromPostalCode")("");
  };

  mobileClickMoreActions = (e) => {
    let windowWidth = window.innerWidth <= 767;

    if (
      (windowWidth && this.state.mobileShow === false) ||
      (!windowWidth && this.state.mobileShow === false) ||
      (windowWidth && this.state.mobileShow === true)
    ) {
      this.setState((prevState) => {
        return {
          mobileShow: !prevState.mobileShow
        };
      });
    }
  };

  showInfo = (e) => {
    e.preventDefault();
  };

  componentDidMount() {
    let windowWidth = window.innerWidth <= 767;
    if (windowWidth && this.state.mobileShow === false) {
      this.setState({ mobileShow: false, data: window.globalAddressBook });
    } else if (!windowWidth && this.state.mobileShow === false) {
      this.setState({ mobileShow: true, data: window.globalAddressBook });
    }
  }

  componentDidUpdate() {
    if (this.state.focusSection) {
      // Focus on the top element in the editing section
      document.getElementById("step1focus").focus();
      this.setState({
        focusSection: false
      });
    }
  }

  renderPhoneNumber = () => {
    let phoneNbr = this.props.configInfo.cnsCartList.returnAddress.phoneNbr;
    phoneNbr = phoneNbr.replace(/[^\d]/g, "");
    return (
      <span>
        {phoneNbr.slice(0, 3) +
          "-" +
          phoneNbr.slice(3, 6) +
          "-" +
          phoneNbr.slice(6)}
      </span>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.editClicked ? (
          <React.Fragment>
            <div id="step1focus" className="row" tabIndex="-1">
              <div className="col-12">
                <p className="step-one-click-ship-address-link">
                  Use address from your{" "}
                  <AddressBook
                    buttonLabel={"Address Book"}
                    changeDeliveryAddress={this.props.changeReturnAddressField}
                    addressFieldType={"GABReturn"}
                    showInternational={"false"}
                    targetName="btn-open-contact-return"
                    lookupCodes={this.props.lookupCodes}
                  />
                </p>
                <p className="step-one-standardized-address">
                  Addresses will be standardized.{" "}
                  <AddressStandardizedPopover targetName="returnAddressStandardizedIcon" />
                </p>
              </div>
            </div>

            <div className="click-step-one-input-container row">
              <div className="col-12 col-md-11 col-lg-10">
                <div className="row">
                  <div
                    className={
                      "col-9 col-md-5 form-group required-field" +
                      (this.props.configInfo.errors.returnFirstName !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="returnFirstNameId"
                      errorMessage={
                        this.props.configInfo.errors.returnFirstName
                      }
                    />
                    <label htmlFor="returnFirstNameId">*First Name</label>
                    <input
                      tabIndex="0"
                      id="returnFirstNameId"
                      type="text"
                      className="form-control"
                      placeholder="First"
                      value={this.props.configInfo.returnAddress.firstName}
                      maxLength="50"
                      onChange={(e) => {
                        this.props.changeReturnAddressField("firstName")(e);
                        this.props.configInfo.errors.returnFirstName !== ""
                          ? this.props.clearError("returnFirstName")
                          : this.props.clearError("");
                      }}
                    />
                    <span className="error-message">
                      {this.props.configInfo.errors.returnFirstName}
                    </span>
                  </div>
                  <div className="col-3 col-md-1 form-group">
                    <label htmlFor="returnMiddleInitId">MI</label>
                    <input
                      tabIndex="0"
                      id="returnMiddleInitId"
                      type="text"
                      className="form-control"
                      value={this.props.configInfo.returnAddress.middleInit}
                      maxLength="1"
                      onChange={(e) =>
                        this.props.changeReturnAddressField("middleInit")(e)
                      }
                    />
                  </div>
                  <div
                    className={
                      "col-12 col-md-6 form-group required-field" +
                      (this.props.configInfo.errors.returnLastName !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="returnLastNameId"
                      errorMessage={this.props.configInfo.errors.returnLastName}
                    />
                    <label htmlFor="returnLastNameId">*Last Name</label>
                    <input
                      tabIndex="0"
                      id="returnLastNameId"
                      type="text"
                      className="form-control"
                      placeholder="Last"
                      value={this.props.configInfo.returnAddress.lastName}
                      maxLength="50"
                      onChange={(e) => {
                        this.props.changeReturnAddressField("lastName")(e);
                        this.props.configInfo.errors.returnLastName !== ""
                          ? this.props.clearError("returnLastName")
                          : this.props.clearError("");
                      }}
                    />
                    <span className="error-message">
                      {this.props.configInfo.errors.returnLastName}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      "col-12 col-md-6 form-group" +
                      (this.props.configInfo.errors.returnCompany !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="returnCompanyNameId"
                      errorMessage={this.props.configInfo.errors.returnCompany}
                    />
                    <label htmlFor="returnCompanyNameId">
                      Company (Only required if first and last names are not
                      provided.)
                    </label>
                    <input
                      tabIndex="0"
                      id="returnCompanyNameId"
                      type="text"
                      className="form-control"
                      value={this.props.configInfo.returnAddress.companyName}
                      maxLength="50"
                      onChange={(e) => {
                        this.props.changeReturnAddressField("companyName")(e);
                        this.props.configInfo.errors.returnCompany !== ""
                          ? this.props.clearError("returnCompany")
                          : this.props.clearError("");
                      }}
                    />
                    <span className="error-message">
                      {this.props.configInfo.errors.returnCompany}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      "col-12 col-md-6 form-group required-field" +
                      (this.props.configInfo.errors.returnStreetAddr !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="returnStreetAddressId"
                      errorMessage={
                        this.props.configInfo.errors.returnStreetAddr
                      }
                    />
                    <label htmlFor="returnStreetAddressId">
                      *Street Address
                    </label>
                    <input
                      tabIndex="0"
                      id="returnStreetAddressId"
                      type="text"
                      className="form-control"
                      value={this.props.configInfo.returnAddress.line1Addr}
                      maxLength="50"
                      onChange={(e) => {
                        this.props.changeReturnAddressField("line1Addr")(e);
                        this.props.configInfo.errors.returnStreetAddr !== ""
                          ? this.props.clearError("returnStreetAddr")
                          : this.props.clearError("");
                      }}
                      onBlur={this.props.fetchDropOffLocationsIfNeeded}
                    />
                    <span className="error-message">
                      {this.props.configInfo.errors.returnStreetAddr}
                    </span>
                  </div>
                  <div
                    className={
                      "col-12 col-md-6 form-group" +
                      (this.props.configInfo.errors.returnAptSuiteOther !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="returnLine2AddrId"
                      errorMessage={
                        this.props.configInfo.errors.returnAptSuiteOther
                      }
                    />
                    <label htmlFor="returnLine2AddrId">Apt/Suite/Other</label>
                    <input
                      tabIndex="0"
                      id="returnLine2AddrId"
                      type="text"
                      className="form-control"
                      value={this.props.configInfo.returnAddress.line2Addr}
                      maxLength="50"
                      onChange={(e) => {
                        this.props.changeReturnAddressField("line2Addr")(e);
                        this.props.configInfo.errors.returnAptSuiteOther !== ""
                          ? this.props.clearError("returnAptSuiteOther")
                          : this.props.clearError("");
                      }}
                      onBlur={this.props.fetchDropOffLocationsIfNeeded}
                    />
                    <span className="error-message">
                      {this.props.configInfo.errors.returnAptSuiteOther}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      "col-12 col-md-6 form-group required-field" +
                      (this.props.configInfo.errors.returnCity !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="returnCityNameId"
                      errorMessage={this.props.configInfo.errors.returnCity}
                    />
                    <label htmlFor="returnCityNameId">*City</label>
                    <input
                      tabIndex="0"
                      id="returnCityNameId"
                      type="text"
                      className="form-control"
                      placeholder="City"
                      value={this.props.configInfo.returnAddress.cityName}
                      maxLength="50"
                      onChange={(e) => {
                        this.props.changeReturnAddressField("cityName")(e);
                        this.props.configInfo.errors.returnCity !== ""
                          ? this.props.clearError("returnCity")
                          : this.props.clearError("");
                      }}
                      onBlur={this.props.fetchDropOffLocationsIfNeeded}
                    />
                    <span className="error-message">
                      {this.props.configInfo.errors.returnCity}
                    </span>
                  </div>

                  <div
                    className={
                      "col-6 col-md-3 form-group required-field" +
                      (this.props.configInfo.errors.returnState !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="returnStateId"
                      errorMessage={this.props.configInfo.errors.returnState}
                    />
                    <label htmlFor="returnStateId">*State</label>
                    <select
                      id="returnStateId"
                      className="form-control state-dropdown dropdown"
                      onChange={(e) => {
                        this.props.updateUSAState(e.target.value);
                        this.props.configInfo.errors.returnState !== "-1"
                          ? this.props.clearError("returnState")
                          : this.props.clearError("");
                      }}
                      value={this.props.configInfo.returnAddress.stateId}
                      onBlur={this.props.fetchDropOffLocationsIfNeeded}
                    >
                      <StatesTerritoriesList />
                    </select>
                    <span className="error-message">
                      {this.props.configInfo.errors.returnState}
                    </span>
                  </div>

                  <div
                    className={
                      "col-6 col-md-3 form-group required-field" +
                      (this.props.configInfo.errors.returnZipCode !== ""
                        ? " error"
                        : "")
                    }
                  >
                    <AssistiveError
                      inputId="returnPostalCodeId"
                      errorMessage={this.props.configInfo.errors.returnZipCode}
                    />
                    <label htmlFor="returnPostalCodeId">
                      *ZIP Code<sup>™</sup>
                    </label>
                    <input
                      tabIndex="0"
                      id="returnPostalCodeId"
                      type="text"
                      className="form-control"
                      placeholder="00000"
                      value={this.props.configInfo.returnAddress.postalCode}
                      maxLength="10"
                      onChange={(e) => {
                        this.props.changeReturnAddressField("postalCode")(e);
                        this.props.configInfo.errors.returnZipCode !== ""
                          ? this.props.clearError("returnZipCode")
                          : this.props.clearError("");
                      }}
                      onBlur={
                        !this.props.configInfo.labelInd ||
                        this.props.configInfo.labelInd.shipFromOtherZipInd !==
                          "Y"
                          ? this.props.fetchDropOffLocationsIfNeeded
                          : null
                      }
                    />
                    <span className="error-message">
                      {this.props.configInfo.errors.returnZipCode}
                    </span>
                  </div>
                </div>

                {this.props.configInfo.returnAddress.stateId === "49" ? (
                  <div className="row">
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="returnUrbanizationCodeId">
                        Urbanization Code
                      </label>
                      <input
                        tabIndex="0"
                        id="returnUrbanizationCodeId"
                        type="text"
                        className="form-control"
                        value={
                          this.props.configInfo.returnAddress.urbanizationCode
                        }
                        maxLength="50"
                        onChange={(e) =>
                          this.props.changeReturnAddressField(
                            "urbanizationCode"
                          )(e)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="row">
                  <div className="col-12 col-md-6 form-group">
                    <label htmlFor="returnAddressEmail">Email</label>
                    <input
                      id="returnAddressEmail"
                      tabIndex="0"
                      type="text"
                      className="form-control"
                      placeholder="email123@email.com"
                      value={this.props.configInfo.returnAddress.emailAddress}
                      maxLength="50"
                      onChange={(e) =>
                        this.props.changeReturnAddressField("emailAddress")(e)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 save-address-book-wrapper">
                <label
                  className="checkbox-component save-address-book-checkbox"
                  htmlFor="save-address-book-checkbox"
                >
                  <input
                    type="checkbox"
                    id="save-address-book-checkbox"
                    onChange={(e) =>
                      this.props.changeReturnAddressField(
                        "saveReturnToAddressBook"
                      )(
                        !this.props.configInfo.returnAddress
                          .saveReturnToAddressBook
                      )
                    }
                    checked={
                      this.props.configInfo.returnAddress
                        .saveReturnToAddressBook
                    }
                  />
                  <span className="checkbox" />
                  {"Save to my address book."}
                </label>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="row user-entered-data step-one-address">
              <div className="col-12 col-md-3 return-address-column-wrapper">
                <span className="return-address-column-header">
                  RETURN ADDRESS
                </span>
                <span>
                  {(this.props.configInfo.cnsCartList.returnAddress.firstName
                    ? this.props.configInfo.cnsCartList.returnAddress.firstName
                    : "") +
                    " " +
                    (this.props.configInfo.cnsCartList.returnAddress.lastName
                      ? this.props.configInfo.cnsCartList.returnAddress.lastName
                      : "")}
                </span>
                {this.props.configInfo.cnsCartList.returnAddress.companyName ||
                this.props.configInfo.cnsCartList.returnAddress.companyName !==
                  "" ? (
                  <span>
                    {
                      this.props.configInfo.cnsCartList.returnAddress
                        .companyName
                    }
                  </span>
                ) : (
                  <div />
                )}
                <span>
                  {this.props.configInfo.cnsCartList.returnAddress.line1Addr}
                </span>
                {this.props.configInfo.cnsCartList.returnAddress.line2Addr ||
                this.props.configInfo.cnsCartList.returnAddress.line2Addr !==
                  "" ? (
                  <span>
                    {this.props.configInfo.cnsCartList.returnAddress.line2Addr}
                  </span>
                ) : (
                  <div />
                )}
                <span>
                  {this.props.configInfo.cnsCartList.returnAddress.cityName +
                    ", " +
                    this.props.configInfo.cnsCartList.returnAddress.stateCode +
                    " " +
                    this.props.configInfo.cnsCartList.returnAddress.postalCode}
                </span>
              </div>
              <div className="col-12 col-md-3 email-column-wrapper">
                <span className="email-column-header">EMAIL</span>
                <span>
                  {this.props.configInfo.cnsCartList.returnAddress.emailAddress}
                </span>
              </div>
              <div className="col-12 col-md-3 phone-number-column-wrapper">
                <span className="phone-number-column-header">PHONE</span>
                {this.renderPhoneNumber()}
              </div>
            </div>
            <div className="row link-edit">
              <div className="col-12">
                <button
                  type="button"
                  className="inline-link step-one-return-address inline-link-edit button-link"
                  onClick={(e) => this.editClick()}
                  aria-label="Edit Return Address"
                >
                  <strong>Edit</strong>
                </button>
              </div>
            </div>
          </React.Fragment>
        )}

        <div className="row">
          <div className="col-12">
            <h2
              className={
                "normal more-actions-header" +
                (this.state.mobileShow ? " active" : "")
              }
              onClick={this.mobileClickMoreActions}
            >
              <strong>More Actions</strong>
              <span
                className="glyphicon glyphicon-align-left"
                aria-hidden="true"
              />
            </h2>
            <div className="row step-one-tracking-notifications">
              <div className="col-12">
                <label
                  id="tracking-notifications-id"
                  className="checkbox-component"
                  htmlFor="tracking-notifications-check"
                >
                  <input
                    type="checkbox"
                    id="tracking-notifications-check"
                    onChange={this.clickedTrackingNotifications}
                    checked={
                      this.props.configInfo.labelInd.ptsNotifyInd === "Y"
                    }
                  />
                  <span
                    className="checkbox"
                    name="tracking-notifications-check"
                  />
                  Send me tracking notifications.
                </label>
                {this.props.configInfo.labelInd.ptsNotifyInd === "Y" ? (
                  <React.Fragment>
                    <div className="tracking-notification-choices-wrapper">
                      <div className="checkbox-wrap">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <label
                              id="email-notifications-id"
                              className="checkbox-component"
                              htmlFor="step-one-email-checkbox"
                            >
                              <input
                                type="checkbox"
                                id="step-one-email-checkbox"
                                checked={
                                  this.props.configInfo.labelInd
                                    .emailUpdatesInd === "Y"
                                }
                                onChange={this.clickedEmailNotifications}
                              />
                              <span className="checkbox" />
                              Email
                            </label>
                            {this.props.configInfo.labelInd.emailUpdatesInd ===
                            "Y" ? (
                              <div
                                id="step-one-email-input"
                                className={
                                  "form-group required-field" +
                                  (this.props.configInfo.errors
                                    .trackingEmailNotifications !== ""
                                    ? " error"
                                    : "")
                                }
                              >
                                <AssistiveError
                                  inputId="step-one-email-input-field"
                                  errorMessage={
                                    this.props.configInfo.errors
                                      .trackingEmailNotifications
                                  }
                                />
                                <input
                                  tabIndex="0"
                                  id="step-one-email-input-field"
                                  type="text"
                                  className="form-control"
                                  placeholder="email123@email.com"
                                  maxLength="50"
                                  value={
                                    this.props.configInfo.labelInd
                                      .ptsTrackingEmail || ""
                                  }
                                  onChange={(e) => {
                                    this.props.changeTrackingNotifications(
                                      "ptsTrackingEmail"
                                    )(e);
                                    this.props.configInfo.errors
                                      .trackingEmailNotifications !== ""
                                      ? this.props.clearError(
                                          "trackingEmailNotifications"
                                        )
                                      : this.props.clearError("");
                                  }}
                                />
                                <span className="error-message">
                                  {
                                    this.props.configInfo.errors
                                      .trackingEmailNotifications
                                  }
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <label
                              id="text-message-notifications-id"
                              className="checkbox-component"
                              htmlFor="step-one-text-message"
                            >
                              <input
                                type="checkbox"
                                id="step-one-text-message"
                                checked={
                                  this.props.configInfo.labelInd
                                    .ptsTxtUpdatesInd === "Y"
                                }
                                onChange={this.clickedTextNotifications}
                              />
                              <span className="checkbox" />
                              Text Message{" "}
                              <TextMessagePopover targetName="textMessageIcon" />
                            </label>
                            {this.props.configInfo.labelInd.ptsTxtUpdatesInd ===
                            "Y" ? (
                              <div
                                id="step-one-text-message-input"
                                className={
                                  "form-group required-field" +
                                  (this.props.configInfo.errors
                                    .trackingTextNotifications !== ""
                                    ? " error"
                                    : "")
                                }
                              >
                                <AssistiveError
                                  inputId="step-one-text-message-input-field"
                                  errorMessage={
                                    this.props.configInfo.errors
                                      .trackingTextNotifications
                                  }
                                />
                                <input
                                  tabIndex="0"
                                  id="step-one-text-message-input-field"
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone Number"
                                  maxLength="30"
                                  value={
                                    this.props.configInfo.labelInd
                                      .ptsTxtPhoneNbr || ""
                                  }
                                  onChange={(e) => {
                                    this.props.changeTrackingNotifications(
                                      "ptsTxtPhoneNbr"
                                    )(e);
                                    this.props.configInfo.errors
                                      .trackingTextNotifications !== ""
                                      ? this.props.clearError(
                                          "trackingTextNotifications"
                                        )
                                      : this.props.clearError("");
                                  }}
                                />
                                <span className="error-message">
                                  {
                                    this.props.configInfo.errors
                                      .trackingTextNotifications
                                  }
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row send-notifications-wrapper">
                      <div className="col-12">
                        <label className="click-ship-mailing-address">
                          Send me notifications for
                        </label>
                        <div className="radio-wrap">
                          <div className="radio-container">
                            <input
                              id="all-updates-radio"
                              type="radio"
                              className="radio-button first"
                              name="send-notifications-radio"
                              onChange={this.clickedUpdates}
                              checked={this.state.sendAllUpdates}
                            />
                            <label htmlFor="all-updates-radio">
                              All Updates
                            </label>
                          </div>
                          <div className="radio-container">
                            <input
                              id="select-updates-radio"
                              type="radio"
                              className="radio-button second"
                              name="send-notifications-radio"
                              onChange={this.clickedUpdates}
                              checked={!this.state.sendAllUpdates}
                            />
                            <label htmlFor="select-updates-radio">
                              Select Updates
                            </label>
                          </div>
                        </div>
                      </div>

                      {!this.state.sendAllUpdates ? (
                        <div
                          id="desired-updates"
                          className="col-12 desired-update-notification"
                        >
                          <p>Choose your desired updates from the list below</p>
                          <div className="checkbox-wrap">
                            <div className="checkbox-container">
                              <label
                                id="expected-delivery-updates-id"
                                className="checkbox-component"
                                htmlFor="expected-delivery-checkbox"
                              >
                                <input
                                  type="checkbox"
                                  id="expected-delivery-checkbox"
                                  checked={
                                    this.props.configInfo.labelInd
                                      .ptsExpectedDelvInd === "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    this.props.changeTrackingNotifications(
                                      "ptsExpectedDelvInd"
                                    )(
                                      this.props.configInfo.labelInd
                                        .ptsExpectedDelvInd === "Y"
                                        ? "N"
                                        : "Y"
                                    )
                                  }
                                />
                                <span className="checkbox" />
                                Expected Delivery Updates
                              </label>
                            </div>
                            <div className="checkbox-container">
                              <label
                                id="day-of-delivery-updates-id"
                                className="checkbox-component"
                                htmlFor="day-delivery-checkbox"
                              >
                                <input
                                  type="checkbox"
                                  id="day-delivery-checkbox"
                                  checked={
                                    this.props.configInfo.labelInd
                                      .ptsDayOfDelvInd === "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    this.props.changeTrackingNotifications(
                                      "ptsDayOfDelvInd"
                                    )(
                                      this.props.configInfo.labelInd
                                        .ptsDayOfDelvInd === "Y"
                                        ? "N"
                                        : "Y"
                                    )
                                  }
                                />
                                <span className="checkbox" />
                                Day of Delivery Updates
                              </label>
                            </div>
                            <div className="checkbox-container">
                              <label
                                id="package-delivered-id"
                                className="checkbox-component"
                                htmlFor="package-delivered-checkbox"
                              >
                                <input
                                  type="checkbox"
                                  id="package-delivered-checkbox"
                                  checked={
                                    this.props.configInfo.labelInd
                                      .ptsDeliveredToAddrInd === "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    this.props.changeTrackingNotifications(
                                      "ptsDeliveredToAddrInd"
                                    )(
                                      this.props.configInfo.labelInd
                                        .ptsDeliveredToAddrInd === "Y"
                                        ? "N"
                                        : "Y"
                                    )
                                  }
                                />
                                <span className="checkbox" />
                                Package Delivered
                              </label>
                            </div>
                            <div className="checkbox-container">
                              <label
                                id="available-for-pickup-id"
                                className="checkbox-component"
                                htmlFor="pickup-availability-checkbox"
                              >
                                <input
                                  type="checkbox"
                                  id="pickup-availability-checkbox"
                                  checked={
                                    this.props.configInfo.labelInd
                                      .ptsPickupAvailableInd === "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    this.props.changeTrackingNotifications(
                                      "ptsPickupAvailableInd"
                                    )(
                                      this.props.configInfo.labelInd
                                        .ptsPickupAvailableInd === "Y"
                                        ? "N"
                                        : "Y"
                                    )
                                  }
                                />
                                <span className="checkbox" />
                                Available for Pickup
                              </label>
                            </div>
                            <div className="checkbox-container">
                              <label
                                id="delivery-exception-updates-id"
                                className="checkbox-component"
                                htmlFor="delivery-exception-checkbox"
                              >
                                <input
                                  type="checkbox"
                                  id="delivery-exception-checkbox"
                                  checked={
                                    this.props.configInfo.labelInd
                                      .ptsAlertsOtherInd === "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    this.props.changeTrackingNotifications(
                                      "ptsAlertsOtherInd"
                                    )(
                                      this.props.configInfo.labelInd
                                        .ptsAlertsOtherInd === "Y"
                                        ? "N"
                                        : "Y"
                                    )
                                  }
                                />
                                <span className="checkbox" />
                                Delivery Exception Updates
                              </label>
                            </div>
                            <div className="checkbox-container">
                              <label
                                id="package-in-transit-updates-id"
                                className="checkbox-component"
                                htmlFor="in-transit-package-checkbox"
                              >
                                <input
                                  type="checkbox"
                                  id="in-transit-package-checkbox"
                                  checked={
                                    this.props.configInfo.labelInd
                                      .ptsPkgInTransitInd === "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    this.props.changeTrackingNotifications(
                                      "ptsPkgInTransitInd"
                                    )(
                                      this.props.configInfo.labelInd
                                        .ptsPkgInTransitInd === "Y"
                                        ? "N"
                                        : "Y"
                                    )
                                  }
                                />
                                <span className="checkbox" />
                                Package In-Transit Updates
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </div>
            </div>
            <div className="row">
              <div className="step-one-different-address col-12">
                <label
                  id="ship-from-other-zip-id"
                  className="checkbox-component"
                  htmlFor="different-address-checkbox"
                >
                  <input
                    type="checkbox"
                    id="different-address-checkbox"
                    onChange={this.clickedShippedOtherZip}
                    checked={
                      this.props.configInfo.labelInd.shipFromOtherZipInd === "Y"
                    }
                    onBlur={this.props.fetchDropOffLocationsIfNeeded}
                  />
                  <span className="checkbox" name="different-address-check" />
                  Ship from another ZIP Code™.
                  <p>
                    Print the address above as the return address, but I'm
                    shipping from another ZIP Code<sup>™</sup>.
                  </p>
                </label>
              </div>
              {this.props.configInfo.labelInd.shipFromOtherZipInd === "Y" ? (
                <div
                  className={
                    "form-group required-field step-one-shipping-zip col-12 col-md-5" +
                    (this.props.configInfo.errors.shipFromOtherZip !== ""
                      ? " error"
                      : "")
                  }
                >
                  <AssistiveError
                    inputId="shipFromOtherZipId"
                    errorMessage={this.props.configInfo.errors.shipFromOtherZip}
                  />
                  <label htmlFor="shipFromOtherZipId">
                    *Shipping ZIP Code<sup>™</sup>
                  </label>
                  <input
                    tabIndex="0"
                    id="shipFromOtherZipId"
                    type="text"
                    className="form-control"
                    placeholder="00000"
                    maxLength="5"
                    onChange={(e) => {
                      this.props.changeTrackingNotifications(
                        "shipFromPostalCode"
                      )(e);
                      this.props.configInfo.errors.shipFromOtherZip !== ""
                        ? this.props.clearError("shipFromOtherZip")
                        : this.props.clearError("");
                    }}
                    value={this.props.configInfo.shipFromPostalCode}
                    onBlur={this.props.fetchDropOffLocationsIfNeeded}
                  />
                  <span className="error-message">
                    {this.props.configInfo.errors.shipFromOtherZip}
                  </span>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 horizontal-line-container">
            <hr className="horizontal-line" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StepOneReturnAddress;
