import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const DetailTermMeaning = (props) => {
  const content = (
    <React.Fragment>
      <p>
        <strong>Acceptance Cutoff Time</strong> - This is the latest time the
        mail piece must be dropped off at the post office or the guarantee is
        not valid.
      </p>
      <p>
        <strong>Actual Acceptance Time</strong> - This is the time the mail
        piece was submitted into the mail stream, it is the earliest scan event
        we get from PTR.
      </p>
      <p>
        <strong>Guaranteed Date</strong> - This is the guaranteed date, it will
        say scheduled date if the mail piece is not guaranteed.
      </p>
      <p>
        <strong>Delivery Status</strong> - Current delivery status with the
        timestamp of the message.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Terms Information Icon"
      header="Detail Term Meaning"
      content={content}
    />
  );
};

export default DetailTermMeaning;
