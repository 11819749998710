import React, { useState, useEffect } from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../default-styles.css";
import axios from "axios";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import InputFields from "../Subcomponents/InputFields";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
import UspsConnectMap from "../Subcomponents/UspsConnectMap/UspsConnectMap";
import PostOfficeInfo from "../Subcomponents/UspsConnectMap/PostOfficeInfo";
import PageNumbers from "../Subcomponents/PageNumbers";
import statesList from "../../utils/StatesList";
import validation from "../../utils/validation";

const UspsConnect = (props) => {
  // Props
  const { toggleSpinner, lookupCodes, pageTitle } = props;

  // Set the text that displays in the browser tab
  document.title = pageTitle;

  // The section to display
  const [pageContent, setPageContent] = useState("uspsConnectLocal");

  // The user's preferred address (or eReg address, if there is no preference)
  const [userAddress, setUserAddress] = useState({
    separateLine1Addr: "",
    separateLine2Addr: "",
    separateCity: "",
    separateStateId: 0,
    separateZip5: "",
    separateZipPlus4: ""
  });

  // The location input field as entered by the user
  const [location, setLocation] = useState("");

  // The location input field parsed into address fields needed
  // by the drop-off locations API (address lines, city, state, ZIP code)
  const [parsedLocation, setParsedLocation] = useState({
    returnCity: "",
    returnStateId: "0",
    returnZip5: "",
    returnZipPlus4: ""
  });

  // The radius input field
  const [radius, setRadius] = useState("");

  // Flag to indicate when we should call the WebTools drop-off locations API
  const [fetchDropOffLocations, setFetchDropOffLocations] = useState(false);

  // Drop-off locations within the specified radius
  // of the given parsed location
  const [facilityInfo, setFacilityInfo] = useState([]);

  // The number of results to display per page
  const [resultsPerPage, setResultsPerPage] = useState(10);

  // The current page of facilities to display
  const [currentPage, setCurrentPage] = useState(1);

  // The array of page numbers to display as selectable options
  const [pageNumbersToDisplay, setPageNumbersToDisplay] = useState([1]);

  // The total number of pages needed to list all eligible facilities
  const [numOfPages, setNumOfPages] = useState(1);

  // The state of the dropdown for choosing results per page
  const [resultsDropdownOpen, setResultsDropdownOpen] = useState(false);

  // The index of the first facility to display on the page
  const [startingResult, setStartingResult] = useState(1);

  // The index of the last facility to display on the page
  const [endingResult, setEndingResult] = useState(1);

  // Flag to determine if screen dimensions can be considered desktop size
  const [isDesktop, setIsDesktop] = useState(true);

  // Error message if there is a problem calling APIs
  const [globalError, setGlobalError] = useState([]);

  // Error message if the user does not enter a location
  const [locationError, setLocationError] = useState("");

  // Error message if the user does not enter a radius
  const [radiusError, setRadiusError] = useState("");

  // Flag to focus on an error, if present (for 508 compliance purposes)
  const [focusOnError, setFocusOnError] = useState(false);

  // Fetch the user's preferred address when the page first loads
  useEffect(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);
    toggleSpinner(true);
    axios
      .get("/go/cnsrest/fetchPreferences?fetchPrefQueryString=")
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.userPref &&
          Object.keys(response.data.fieldErrors).length === 0 &&
          response.data.actionErrors.length === 0
        ) {
          // Prepare the user's preferred/eReg address
          if (response.data.userPref.userReturnAddr) {
            setUserAddress({
              separateLine1Addr:
                response.data.userPref.userReturnAddr.line1Addr || "",
              separateLine2Addr:
                response.data.userPref.userReturnAddr.line2Addr || "",
              separateCity:
                response.data.userPref.userReturnAddr.cityName || "",
              separateStateId: response.data.userPref.userReturnAddr.stateId
                ? String(response.data.userPref.userReturnAddr.stateId)
                : "0",
              separateZip5: response.data.userPref.userReturnAddr.zip5 || "",
              separateZipPlus4:
                response.data.userPref.userReturnAddr.zipPlus4 || ""
            });
          }
          // Default to the preferred radius, if present
          const radius = response.data.userPref.uspsConnectRadius;
          if (radius > 0) {
            setRadius(String(radius));
          }
        } else {
          setGlobalError(["We're sorry. An unexpected error has occurred."]);
        }
      })
      .catch((error) => {
        console.log(error);
        setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        toggleSpinner(false);
      });
  }, []);

  // Hide the location error message whenever that field is changed
  useEffect(() => {
    setLocationError("");
  }, [location]);

  // Hide the radius error message whenever that field is changed
  useEffect(() => {
    setRadiusError("");
  }, [radius]);

  // Focus on an error message if present
  useEffect(() => {
    if (focusOnError) {
      if (locationError) {
        document.getElementById("mappingLocationInputId-a11y").focus();
      } else if (radiusError) {
        document.getElementById("radiusInputId-a11y").focus();
      }
      setFocusOnError(false);
    }
  }, [focusOnError, locationError, radiusError]);

  // Reset the current page to 1 whenever the number of results per page changes
  // or drop-off facilities are fetched
  useEffect(() => {
    setCurrentPage(1);
  }, [resultsPerPage, fetchDropOffLocations]);

  // Update the starting and ending facility indexes to display on the page
  // whenever the current page, results per page, or facility count changes
  useEffect(() => {
    const startingResult = 1 + (currentPage - 1) * resultsPerPage;
    let endingResult = startingResult + resultsPerPage - 1;
    if (endingResult > facilityInfo.length) {
      endingResult = facilityInfo.length;
    }
    setStartingResult(startingResult);
    setEndingResult(endingResult);
  }, [currentPage, resultsPerPage, facilityInfo.length]);

  // Update the total number of pages whenever the results per page
  // or facility count changes
  useEffect(() => {
    let numOfPages;
    if (facilityInfo.length > 0) {
      numOfPages = Math.ceil(facilityInfo.length / resultsPerPage);
    } else {
      numOfPages = 1;
    }
    setNumOfPages(numOfPages);
  }, [resultsPerPage, facilityInfo.length]);

  // Update the page numbers that should be displayed
  // whenever the current page or number of pages changes
  useEffect(() => {
    let pageNumbersToDisplay = [];
    // Add the current page
    pageNumbersToDisplay.push(currentPage);
    // Add up to 2 pages before the current page, if available
    if (currentPage - 1 >= 1) {
      pageNumbersToDisplay.unshift(currentPage - 1);
      if (currentPage - 2 >= 1) {
        pageNumbersToDisplay.unshift(currentPage - 2);
      }
    }
    // Add pages after the current page until either the total number is 5 or
    // the last page has been reached
    for (
      let i = currentPage + 1;
      pageNumbersToDisplay.length < 5 && i <= numOfPages;
      i++
    ) {
      pageNumbersToDisplay.push(i);
    }
    // If the total number of pages is still less than 5 and the first page
    // has not been included yet, prepend pages to the array
    for (
      let j = currentPage - 3;
      pageNumbersToDisplay.length < 5 && j >= 1;
      j--
    ) {
      pageNumbersToDisplay.unshift(j);
    }
    // Update the state
    setPageNumbersToDisplay(pageNumbersToDisplay);
  }, [currentPage, numOfPages]);

  const updateScreenSize = () => {
    setIsDesktop(window.innerWidth > 767);
  };

  const loadDropOffLocations = () => {
    if (location) {
      const parsedLocation = getLocation();
      if (parsedLocation && radius) {
        setParsedLocation({
          returnCity: parsedLocation.city || "",
          returnStateId: parsedLocation.state || "0",
          returnZip5: parsedLocation.zip5 || "",
          returnZipPlus4: parsedLocation.zipPlus4 || ""
        });
        setGlobalError([]);
        setFetchDropOffLocations(true);
      } else {
        // Display errors if an invalid location or no radius was entered
        if (!parsedLocation) {
          setLocationError(
            "Please enter a valid city and state, or ZIP code™."
          );
        }
        if (!radius) {
          setRadiusError("Please select a radius.");
        }
        setFocusOnError(true);
      }
    } else {
      // Display errors if no location or radius was entered
      if (!location) {
        setLocationError("Please enter a location.");
      }
      if (!radius) {
        setRadiusError("Please select a radius.");
      }
      setFocusOnError(true);
    }
  };

  // Parse the location that was entered, if it is in a valid format
  const getLocation = () => {
    const cityStateArrayByCommas = location.split(",");
    // If the location has more than 1 comma, it is invalid
    if (cityStateArrayByCommas.length > 2) {
      return null;
    }
    // If the location has 1 comma, assume it is in the format "CITY, STATE".
    // The location is treated as valid if the city consists of acceptable
    // characters only and the state is either a valid abbreviation or valid
    // full state name.
    if (cityStateArrayByCommas.length === 2) {
      const enteredCity = cityStateArrayByCommas[0].trim().toUpperCase();
      const enteredState = cityStateArrayByCommas[1].trim().toUpperCase();
      const retrievedState = statesList.find((state) =>
        isValidState(state, enteredState)
      );
      if (
        enteredCity.length > 0 &&
        validation.nameAddress.test(enteredCity) &&
        retrievedState
      ) {
        return {
          city: enteredCity,
          state: retrievedState.id
        };
      }
      return null;
    }
    // If the location has no commas, first check if it is a ZIP code.
    // It is valid if it is in either the ZIP-5 or ZIP+4 format.
    const enteredCityStateZip = cityStateArrayByCommas[0].trim().toUpperCase();
    if (validation.zip.test(enteredCityStateZip)) {
      return {
        zip5: enteredCityStateZip.substring(0, 5),
        zipPlus4: enteredCityStateZip.substring(6)
      };
    }
    // For city/state inputs without a comma, we need to get creative.
    const cityStateArrayBySpaces = enteredCityStateZip.split(" ");
    // If the input provided was all one word, since a ZIP code was already
    // ruled out, this is invalid as a city state combination.
    if (cityStateArrayBySpaces.length < 2) {
      return null;
    }
    // If the last element in the array matches a state abbreviation,
    // assume everything before it is the city.
    // The city must consist of acceptable characters only.
    const possibleCity = cityStateArrayBySpaces
      .slice(0, cityStateArrayBySpaces.length - 1)
      .join(" ");
    const possibleStateAbbreviation =
      cityStateArrayBySpaces[cityStateArrayBySpaces.length - 1];
    const retrievedState = statesList.find((state) =>
      isValidStateAbbreviation(state.abbreviation, possibleStateAbbreviation)
    );
    if (
      possibleCity.length > 0 &&
      validation.nameAddress.test(possibleCity) &&
      retrievedState
    ) {
      return {
        city: possibleCity,
        state: retrievedState.id
      };
    }
    // Since state names can be anywhere from one (ex. Nevada) to three
    // (ex. District of Columbia) words, the last one to three words should be
    // checked (as long as the first word in the string, index 0, is not being checked,
    // since the city must be present and correspond to at least the first
    // word).
    const firstPossibleIndexOfState =
      cityStateArrayBySpaces.length - 3 >= 1
        ? cityStateArrayBySpaces.length - 3
        : 1;
    // Because of the way the loop below is written, larger state names are
    // checked first.  This is to ensure that the full state name is captured.
    // For example, if "WEST VIRGINIA" is entered as the last two words,
    // both "WEST VIRGINIA" and "VIRGINIA" are valid states,
    // but it will be treated as "WEST VIRGINIA".
    //
    // Examples of inputs and the interpreted city / state
    // after parsing and capitalizing all letters:
    //   - input: word1 word2 word3 district of columbia
    //     city: WORD1 WORD2 WORD3
    //     state: DISTRICT OF COLUMBIA
    //   - input: word1 idaho
    //     city: WORD1
    //     state: IDAHO
    //   - input: word1 word2 west virginia
    //     city: WORD1 WORD2
    //     state: WEST VIRGINIA
    for (
      let i = firstPossibleIndexOfState;
      i < cityStateArrayBySpaces.length;
      i++
    ) {
      const possibleCity = cityStateArrayBySpaces.slice(0, i).join(" ");
      const possibleStateName = cityStateArrayBySpaces
        .slice(i, cityStateArrayBySpaces.length)
        .join(" ");
      const retrievedState = statesList.find((state) =>
        isValidStateName(state.name, possibleStateName)
      );
      if (
        possibleCity.length > 0 &&
        validation.nameAddress.test(possibleCity) &&
        retrievedState
      ) {
        return {
          city: possibleCity,
          state: retrievedState.id
        };
      }
    }
    // If this is reached, the input is invalid.
    return null;
  };

  // Determine if the state entered by the user (<enteredState>) either matches
  // the abbreviation or full name of the state object provided (<state>).
  const isValidState = (state, enteredState) => {
    return (
      isValidStateAbbreviation(state.abbreviation, enteredState) ||
      isValidStateName(state.name, enteredState)
    );
  };

  // Determine if the state entered by the user (<enteredState>) matches
  // the abbreviation of the state field provided (<state>).
  const isValidStateAbbreviation = (abbreviation, enteredState) => {
    return abbreviation === enteredState;
  };

  // Determine if the state entered by the user (<enteredState>) matches
  // the full name of the state field provided (<state>).
  const isValidStateName = (name, enteredState) => {
    return name === enteredState;
  };

  // Change the number of facilities to display per page
  const changeResultsPerPage = (e) => {
    setResultsPerPage(Number(e.target.innerText));
  };

  return (
    <React.Fragment>
      <AssistiveGlobalError
        globalErrorArray={globalError}
        styling={{ paddingLeft: "15px" }}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-11 col-lg-10">
            <h3 className="connectLocalTitle">Introducing USPS Connect</h3>
          </div>
        </div>

        <div className="col-lg-1"></div>
        <div className="col-lg-11">
          <div className="row">
            <div className="col-12 col-md-2 col-lg-3 connectLocalNavbar">
              <div
                className={
                  pageContent === "uspsConnectLocal"
                    ? "connectButtonSelected"
                    : null
                }
              >
                <button
                  className="connectButtonSidebar"
                  onClick={() => {
                    setPageContent("uspsConnectLocal");
                  }}
                >
                  <strong>USPS Connect™ Local</strong>
                </button>
              </div>
              <div
                className={
                  pageContent === "supportedLocations"
                    ? "connectButtonSelected"
                    : null
                }
              >
                <button
                  className="connectButtonSidebar"
                  onClick={() => {
                    setPageContent("supportedLocations");
                  }}
                >
                  <strong>Supported Locations</strong>
                </button>
              </div>
            </div>
            <div className="col-12 col-md-10 col-lg-9">
              {pageContent === "uspsConnectLocal" && (
                <div className="connectPagePadding">
                  <h2 className="normal">
                    <strong>USPS Connect™ Local</strong>
                  </h2>
                  <p className="connectLocalDescription">
                    Introducing a new service to provide neighborhood businesses
                    access to designated local postal facilities for same-day or
                    next-day delivery at cheaper rates. Mailers shipping to
                    supported destination ZIP Codes will see a USPS Connect
                    Local service option in the Create a Label workflow,
                    provided labels will include designated drop-off addresses.
                    For a full list of supported locations, see below.
                    Interested shippers can fill out the application form below.
                  </p>
                  <button
                    type="button"
                    className="btn-primary button--white"
                    aria-label="View locations"
                    onClick={() => setPageContent("supportedLocations")}
                  >
                    <strong>View Locations</strong>
                  </button>
                  <h4 className="connectLocalBenefits">Benefits</h4>
                  <h6 className="connectLocalSubTitle">
                    <strong>Same-day delivery</strong>
                  </h6>
                  <p>
                    Bring your packages to the designated local postal facility
                    early in the morning for expected delivery within hours.
                  </p>
                  <h6 className="connectLocalSubTitle">
                    <strong>Next-day delivery</strong>
                  </h6>
                  <p>
                    Bring your packages to the designated local postal facility
                    30 minutes before back door closing for next-day delivery.
                  </p>
                  <h6 className="connectLocalSubTitle">
                    <strong>USPS Provided Shipping Supplies</strong>
                  </h6>
                  <p>
                    Save time and money by using free mailing bags/boxes
                    provided at Postal Facilities.
                  </p>
                  <h6 className="connectLocalSubTitle">
                    <strong>6-Day Service</strong>
                  </h6>
                  <p>
                    Maximize the time you can process orders and ship products.
                  </p>
                  <h6 className="connectLocalSubTitle">
                    <strong>Sunday Delivery</strong>
                  </h6>
                  <p>7-Day service supported in select locations.</p>
                  <h6 className="connectLocalSubTitle">
                    <strong>Expert Shipping Advice</strong>
                  </h6>
                  <p>
                    Optimize with help from professionals at your Post Office
                    facility and your USPS representatives.
                  </p>
                  <h6 className="connectLocalSubTitle">
                    <strong>Reduced Shipping Costs</strong>
                  </h6>
                  <p className="connectLocalBottom">
                    Create more value for your customers with cost-effective
                    shipping.
                  </p>
                </div>
              )}
              <div
                className={
                  "connectPagePadding" +
                  (pageContent !== "supportedLocations"
                    ? " hideMapSection"
                    : "")
                }
              >
                <h2 className="normal">
                  <strong>USPS Connect™ Locations</strong>
                </h2>
                <div className="row">
                  <InputFields
                    divClassList="col-10 col-md-8 form-group"
                    errorMessage={locationError}
                    includeAssistiveError={true}
                    inputId="mappingLocationInputId"
                    labelText="*Find a Location"
                    inputValue={location}
                    inputPlaceholder="City and State, or ZIP Code"
                    inputMaxLength="50"
                    inputOnKeyPress={(e) => {
                      if (e.key === "Enter") {
                        loadDropOffLocations();
                      }
                    }}
                    inputOnChange={(e) => {
                      setLocation(e.currentTarget.value);
                    }}
                    fieldSuffixButton={true}
                    buttonAriaLabel="Compass icon"
                    buttonId="compassIconId"
                    buttonClassList="button-link compassIcon"
                  />
                </div>
                <div className="row">
                  <InputFields
                    divClassList="col-6 col-md-4 form-group withinPadding"
                    errorMessage={radiusError}
                    includeAssistiveError={true}
                    inputId="radiusInputId"
                    labelText="Within"
                    inputValue={radius}
                    inputOnChange={(e) => {
                      setRadius(e.currentTarget.value);
                    }}
                    elementType="select"
                    optionsList={[
                      {
                        name: "Select",
                        value: ""
                      },
                      {
                        name: "1 Mile",
                        value: "1"
                      },
                      {
                        name: "5 Miles",
                        value: "5"
                      },
                      {
                        name: "10 Miles",
                        value: "10"
                      },
                      {
                        name: "20 Miles",
                        value: "20"
                      },
                      {
                        name: "30 Miles",
                        value: "30"
                      },
                      {
                        name: "50 Miles",
                        value: "50"
                      },
                      {
                        name: "100 Miles",
                        value: "100"
                      }
                    ]}
                  />
                  <div className="supportedLocationsButtonWrapper">
                    <button
                      type="button"
                      className="btn-primary ser-perf-save-btn supportedLocationsButton"
                      onClick={() => loadDropOffLocations()}
                      aria-label="Search for locations"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div id="LocationsConnectMapWrapper">
                    <UspsConnectMap
                      fetchDropOffLocations={fetchDropOffLocations}
                      setFetchDropOffLocations={setFetchDropOffLocations}
                      shipFromAddress={parsedLocation}
                      separateAddress={userAddress}
                      radius={radius}
                      setGlobalError={setGlobalError}
                      setFacilityInfo={setFacilityInfo}
                      mapSource="uspsConnect"
                      lookupCodes={lookupCodes}
                    />
                  </div>
                </div>
                {facilityInfo.length > 0 && (
                  <React.Fragment>
                    <div className="resultsNoPadding">
                      <p className="ab-search-results-text d-md-block">
                        <strong>
                          Showing Results {startingResult}-{endingResult} of{" "}
                          {facilityInfo.length}
                        </strong>
                      </p>
                    </div>
                    {facilityInfo
                      .slice(startingResult - 1, endingResult)
                      .map((facility) => (
                        <PostOfficeInfo
                          key={facility.locationName}
                          facility={facility}
                          isDesktopAndTableFormat={isDesktop}
                          isTableFormat={true}
                        />
                      ))}
                    <div className="row">
                      <div className="col-6 d-none d-md-inline-flex results-dropdown-container resultsMarginTop resultsContainer">
                        <strong className="results-label results-label-connect">
                          Results Per Page:
                        </strong>
                        <Dropdown
                          isOpen={resultsDropdownOpen}
                          toggle={() =>
                            setResultsDropdownOpen((prevState) => !prevState)
                          }
                          value={resultsPerPage}
                          className="dropdown-selection dropdown-selection-connect"
                        >
                          <DropdownToggle
                            id="results-amount-address-book"
                            className="btn btn-default dropdown dropdown-items-wrapper"
                          >
                            {resultsPerPage}
                          </DropdownToggle>
                          <DropdownMenu>
                            <li>
                              <DropdownItem
                                onClick={(e) => changeResultsPerPage(e)}
                              >
                                10
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={(e) => changeResultsPerPage(e)}
                              >
                                20
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={(e) => changeResultsPerPage(e)}
                              >
                                30
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={(e) => changeResultsPerPage(e)}
                              >
                                40
                              </DropdownItem>
                            </li>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <PageNumbers
                        divClassName="resultsConnectPaginationWrapper"
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageNumbersToDisplay={pageNumbersToDisplay}
                        numOfPages={numOfPages}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UspsConnect;
