import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import axios from "axios";

const ConnectLocalSignup = (props) => {
  const {
    buttonLabel,
    className,
    refreshUserInfo,
    toggleSpinner,
    isModalActive,
    setIsModalActive
  } = props;

  const [agree, setAgree] = useState(false);

  const toggle = () => {
    setIsModalActive(!isModalActive);
    setAgree(false);
    setTermsErrorMessage("");
    setSignUpErrorMessage("");
  };

  const [termsErrorMessage, setTermsErrorMessage] = useState("");

  const [signUpErrorMessage, setSignUpErrorMessage] = useState("");

  const [focusOnError, setFocusOnError] = useState(false);

  // Focus on an error message if present
  useEffect(() => {
    if (focusOnError) {
      if (termsErrorMessage) {
        document.getElementById("connectLocalSignUpTermsErrorId").focus();
      } else if (signUpErrorMessage) {
        document.getElementById("connectLocalSignUpButtonErrorId").focus();
      }
      setFocusOnError(false);
    }
  }, [focusOnError, termsErrorMessage, signUpErrorMessage]);

  const acceptContinue = () => {
    setSignUpErrorMessage("");
    if (agree === true) {
      toggleSpinner(true);
      axios
        .post("/go/cnsrest/connectLocalOptIn")
        .then((response) => {
          if (response.data.result === true) {
            refreshUserInfo();
            toggle();
          } else {
            setSignUpErrorMessage(
              "We're sorry. An unexpected error has occurred."
            );
            setFocusOnError(true);
            toggleSpinner(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSignUpErrorMessage(
            "We're sorry. An unexpected error has occurred."
          );
          setFocusOnError(true);
          toggleSpinner(false);
        });
    } else {
      setTermsErrorMessage("* Please accept the terms and conditions.");
      setFocusOnError(true);
    }
  };

  const clearTermsErrorMessage = () => {
    setAgree(!agree);
    setTermsErrorMessage("");
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-primary"
        title=""
        tabIndex="0"
        onClick={toggle}
      >
        <span>{buttonLabel}</span>
      </button>
      <Modal
        isOpen={isModalActive}
        toggle={toggle}
        className={className}
        id="step8-remove-confirmation-modal"
        size="lg"
      >
        <div
          className="modal-content modal-container"
          style={{ padding: "60px" }}
        >
          <div className="modal-header">
            <button
              type="button"
              className="close button-link"
              id="close"
              data-dismiss="modal"
              tabIndex="0"
              onClick={toggle}
            >
              <span className="visuallyhidden">Close Modal</span>
            </button>
            <h2 className="modal-title" style={{ fontSize: "26px" }}>
              Sign Up Now for USPS Connect Local
            </h2>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <div
                className="row"
                style={{
                  paddingTop: "40px"
                }}
              >
                <div className="col-6 text-left">
                  <h3>How it Works</h3>
                </div>
                <div
                  className="col-6 text-right"
                  style={{
                    fontSize: "14px",
                    textDecoration: "underline",
                    color: "#3573b1"
                  }}
                >
                  {/* <a
                    href="https://www.uspsconnect.com"
                    style={{
                      color: "#3573b1"
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Learn More About USPS Connect™
                  </a> */}
                </div>
              </div>
              <br />
              <div
                style={{
                  paddingLeft: "15px"
                }}
              >
                <div className="row">
                  <p className="signUpModalHeaders">1. Receive an order</p>
                  <p className="signUpModalTextPad">
                    Get an order for same-day or next-day delivery and pack it
                    in USPS Connect Local Flat Rate packaging —or your brand’s
                    box or bag.
                  </p>
                </div>
                <br />
                <div className="row">
                  <p className="signUpModalHeaders">2. Prepare and pay</p>
                  <p className="signUpModalTextPad">
                    Use Click-N-Ship® to print a label, easily pay for postage,
                    and get a tracking number — all in one place.
                  </p>
                </div>
                <br />
                <div className="row">
                  <p className="signUpModalHeaders">3. Bring your packages</p>
                  <p className="signUpModalTextPad">
                    Bring your prepaid, labeled packages to a designated local
                    Postal Facility early in the morning for same-day delivery,
                    or 30 minutes before back dock closing for next-day
                    delivery. Free Package Pickup for next-day delivery is also
                    available. Check ZIP Code™ availability.
                  </p>
                </div>
                <br />
                <div className="row">
                  <p className="signUpModalHeaders">4. Track Deliveries</p>
                  <p className="signUpModalTextPad">
                    The package goes out on a carrier’s daily route, and you
                    receive delivery notifications after setting them up through
                    Click-N-Ship®.
                  </p>
                </div>
                <br />

                <div
                  className="row"
                  style={{
                    fontSize: "14px",
                    textDecoration: "underline",
                    color: "#3573b1"
                  }}
                >
                  <a
                    href="https://www.uspsconnect.com"
                    style={{
                      color: "#3573b1"
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Learn more about USPS Connect™
                  </a>{" "}
                </div>

                <div className="row">
                  <p
                    style={{
                      fontSize: "12px"
                    }}
                  >
                    * Service requires an agreement and is not available
                    everywhere
                  </p>
                </div>

                <div
                  className="row"
                  style={{
                    marginTop: "40px"
                  }}
                >
                  <p
                    style={{
                      fontSize: "22px",
                      color: "#333366"
                    }}
                  >
                    Read and accept Terms and Conditions
                  </p>
                </div>
                <div
                  className="row"
                  style={{
                    paddingTop: "10px"
                  }}
                >
                  <p
                    style={{
                      textDecoration: "underline",
                      color: "#3573b1"
                    }}
                  >
                    {" "}
                    <a
                      href="https://www.usps.com/terms-conditions/usps-connect-local.htm"
                      style={{
                        color: "#3573b1"
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      USPS Connect Local Terms and Conditions{" "}
                    </a>
                  </p>
                </div>

                <div className="row">
                  <p
                    id="connectLocalSignUpTermsErrorId"
                    className="error-message"
                    style={{
                      display: !termsErrorMessage ? "none" : "block",
                      paddingTop: "20px"
                    }}
                    tabIndex="-1"
                  >
                    {termsErrorMessage}
                  </p>
                </div>
                <div
                  className="row"
                  style={{
                    paddingTop: "10px"
                  }}
                >
                  <div
                    className="col-12"
                    style={{
                      paddingLeft: "0px"
                    }}
                  >
                    <label
                      id="tracking-notifications-id"
                      className="checkbox-component"
                      htmlFor="terms-conditions-check"
                    >
                      <input
                        type="checkbox"
                        id="terms-conditions-check"
                        onChange={() => clearTermsErrorMessage()}
                        checked={agree}
                      />
                      <span
                        className="checkbox"
                        name="terms-conditions-check"
                      ></span>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          paddingLeft: "30px"
                        }}
                      >
                        {" "}
                        I have read and agree to the terms and conditions.
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-buttons" style={{}}>
            <div className="button-container">
              <button
                type="button"
                className="btn-primary remove-selected-button"
                data-dismiss="modal"
                tabIndex="0"
                onClick={() => {
                  acceptContinue();
                }}
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px"
                }}
              >
                Agree & Sign Up
              </button>
            </div>
          </div>
          <div className="row">
            <p
              id="connectLocalSignUpButtonErrorId"
              className="error-message"
              style={{
                display: !signUpErrorMessage ? "none" : "block",
                paddingLeft: "15px",
                paddingTop: "20px"
              }}
              tabIndex="-1"
            >
              {signUpErrorMessage}
            </p>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ConnectLocalSignup;
