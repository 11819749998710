import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DeleteLabelModal from "../Modals/DeleteLabelModal";
//import EnterLPACreditModal from "../Modals/EnterLPACreditModal";
import DeleteAllCartLabels from "../Modals/DeleteAllCartLabels";
import update from "immutability-helper";
import * as analytics from "../Analytics/Analytics.js";
import batchAlertIcon from "../../click-n-ship/images/alert-tri.png";
//import PendingAndEarnedPoints from "../Popovers/PendingAndEarnedPoints";
import FreeShippingSuppliesModal from "../Modals/FreeShippingSuppliesModal";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
import FreeSuppliesTable from "../Subcomponents/FreeSuppliesTable";
class ShippingCart extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.dataSet = [];

    this.pageSize = 10;
    this.pagesCount = 1;

    this.freeSuppliesCartTypeIds = ["4", "5"];

    this.state = {
      fetchedCartList: "",
      isLoaded: false,
      arrOfCartIds: [],
      currentPage: 1,
      displayRemaining: false,
      errors: {
        globalError: []
      }
    };
  }

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: errorMessage
        }
      });
    }
  };

  componentDidMount() {
    this.fetchCart();
  }

  fetchCart = () => {
    this.props.toggleSpinner(true);
    axios
      .get("/go/cnsrest/fetchcart?queryString=")
      .then((response) => {
        this.props.toggleSpinner(false);

        if (window.location.search.substring(1) === "shipAgain=true") {
          analytics.addToCart_ShippingHistoryShipAgain(response.data);
        }
        let batchItemInCart = false;
        for (let item in response.data.items) {
          if (response.data.items[item].labelInd.batchInd === "Y") {
            batchItemInCart = true;
            break;
          }
        }

        //Will be a lookup code soon I promise
        /*let arrOfEligibleLoyaltyProducts = [
          100,
          110,
          111,
          112,
          113,
          114,
          120,
          130,
          131,
          132,
          133,
          134,
          135,
          150,
          151,
          152,
          153,
          154,
          155,
          156,
          157,
          158,
          159,
          160,
          161,
          302,
          200,
          201,
          210,
          211,
          212,
          213,
          214,
          220,
          221,
          222
        ];

        let eligibleLoyaltyCredits = 0;

        if (!response.data.postageDueItems) {
          for (let item in response.data.items) {
            for (let eligibleLPAItem in arrOfEligibleLoyaltyProducts) {
              if (
                Number(arrOfEligibleLoyaltyProducts[eligibleLPAItem]) ===
                response.data.items[item].product.productId
              ) {
                eligibleLoyaltyCredits +=
                  response.data.items[item].labelCost.postageAmt;
              }
            }
          }
        }

        if (response.data.LPApoints) {
          let parsedLpaPoints = JSON.parse(response.data.LPApoints);

          if (parsedLpaPoints.availableCredit < eligibleLoyaltyCredits) {
            eligibleLoyaltyCredits = parsedLpaPoints.availableCredit;
          }
        }*/

        // Copy any free supplies to a separate array
        let freeSupplies = response.data.items.filter(
          (item) =>
            item.cnsCartTypeId &&
            this.freeSuppliesCartTypeIds.includes(
              String(item.cnsCartTypeId.value)
            )
        );
        // Remove the free supplies from the array in the response
        response.data.items = response.data.items.filter(
          (item) =>
            !item.cnsCartTypeId ||
            !this.freeSuppliesCartTypeIds.includes(
              String(item.cnsCartTypeId.value)
            )
        );

        let parcelSelectGroups = this.filterParcelSelect(response.data.items);
        //this.filterParcelSelect(response.data);
        // response.data.items = response.data.items.filter(
        //   (e) => e.parcelSelectType !== "DDU"
        // );

        if (response.data.actionErrors.length > 0) {
          window.scrollTo(0, 0);
          this.setGlobalError(response.data.actionErrors);
        }
        this.setState({
          isLoaded: true,
          batchItemInCart: batchItemInCart,
          windowWidth: window.innerWidth,
          fetchedCartList: response.data,
          freeSupplies: freeSupplies,
          parcelSelectGroups: parcelSelectGroups,
          hasUserAppliedCredits: false
          //enteredLoyaltyAmount: 0
          /*eligibleLoyaltyCredits:
            eligibleLoyaltyCredits > 0 &&
            eligibleLoyaltyCredits <= this.props.loyaltyUserInfo.availableCredit
              ? eligibleLoyaltyCredits
              : eligibleLoyaltyCredits > 0 &&
                eligibleLoyaltyCredits >
                  this.props.loyaltyUserInfo.availableCredit
              ? this.props.loyaltyUserInfo.availableCredit
              : 0,
          LPAUserData: response.data.LPApoints
            ? JSON.parse(response.data.LPApoints)
            : {}*/
        });
        this.props.toggleSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        document.getElementById("shipping-cart-top-id").focus({
          preventScroll: true
        });
      });
  };

  filterParcelSelect = (items) => {
    let parcelSelectItemArr = [];
    let normalLabelArr = [];
    let counter = 1;
    // let parcelSelectGroups = this.splitParcelSelectTypes(response.data);

    parcelSelectItemArr = items.filter((e) => e.parcelSelectType === "DDU");
    normalLabelArr = items.filter((e) => e.parcelSelectType !== "DDU");
    // parcelSelectItemArr.sort((a, b) =>
    //   a.labelDropOffAddr.locationName
    //     .normalize()
    //     .localeCompare(b.labelDropOffAddr.locationName.normalize())
    // );

    const result = parcelSelectItemArr.reduce((acc, item) => {
      acc[`${item.labelDropOffAddr.locationName}`] =
        acc[`${item.labelDropOffAddr.locationName}`] || [];
      acc[`${item.labelDropOffAddr.locationName}`].push(item);
      return acc;
    }, {});

    for (let i in Object.keys(result)) {
      for (let j in result[Object.keys(result)[i]]) {
        result[Object.keys(result)[i]][j].cartCount =
          normalLabelArr.length + counter;
        counter++;
      }
    }

    // Store the number of CL/CLM labels currently in the cart
    this.setState({
      numberOfConnectLocalLabels: parcelSelectItemArr.length
    });

    return result;
  };

  editLabel = (e, editItem) => {
    e.preventDefault();
    this.props.setEditCartId(editItem.cnsCartId);
    this.props.history.push("/labelInformation", {
      editItem: editItem.cnsCartId
    });
  };

  deleteCartLabel = (e, labelId) => {
    this.props.toggleSpinner(true);
    axios
      .post("/go/cnsrest/deleteLabel", { cnsCartId: labelId })
      .then((response) => {
        let newCartList;
        let newCartFreeSupplies;

        if (labelId !== null) {
          newCartList = this.state.fetchedCartList.items.filter(
            (item, keys) => item.cnsCartId !== labelId
          );
          // analytics.removeFromCart_ShippingCartDelete(
          //   this.state.fetchedCartList,
          //   labelId
          // );
          newCartFreeSupplies = this.state.freeSupplies.filter(
            (item, keys) => item.cnsCartId !== labelId
          );
        } else {
          newCartList = [];
          newCartFreeSupplies = [];
          // analytics.removeFromCart_ShippingCartDelete(
          //   this.state.fetchedCartList,
          //   labelId
          // );
        }
        /*let newEnternedLoyaltyAmount = 0;
        for (let cartItem in newCartList) {
          if (newCartList[cartItem].loyaltyDisplayAmountCart) {
            newEnternedLoyaltyAmount +=
              newCartList[cartItem].loyaltyDisplayAmountCart;
          }
        }*/

        let batchItemInCart = false;
        for (let item in response.data.items) {
          if (response.data.items[item].labelInd.batchInd === "Y") {
            batchItemInCart = true;
            break;
          }
        }
        /*let arrOfEligibleLoyaltyProducts = [
          100,
          110,
          111,
          112,
          113,
          114,
          120,
          130,
          131,
          132,
          133,
          134,
          135,
          150,
          151,
          152,
          153,
          154,
          155,
          156,
          157,
          158,
          159,
          160,
          161,
          302,
          200,
          201,
          210,
          211,
          212,
          213,
          214,
          220,
          221,
          222
        ];

        let eligibleLoyaltyCredits = 0;

        if (!newCartList.postageDueItems) {
          for (let item in newCartList) {
            for (let eligibleLPAItem in arrOfEligibleLoyaltyProducts) {
              if (
                Number(arrOfEligibleLoyaltyProducts[eligibleLPAItem]) ===
                newCartList[item].product.productId
              ) {
                eligibleLoyaltyCredits +=
                  newCartList[item].labelCost.postageAmt;
              }
            }
          }
        }*/

        let parcelSelectGroups = this.filterParcelSelect(newCartList);
        this.fetchCart();
        this.setState({
          fetchedCartList: {
            ...this.state.fetchedCartList,
            items: newCartList
          },
          freeSupplies: newCartFreeSupplies,
          parcelSelectGroups: parcelSelectGroups,
          /*eligibleLoyaltyCredits:
            eligibleLoyaltyCredits > 0 &&
            eligibleLoyaltyCredits <= this.props.loyaltyUserInfo.availableCredit
              ? eligibleLoyaltyCredits
              : eligibleLoyaltyCredits > 0 &&
                eligibleLoyaltyCredits >
                  this.props.loyaltyUserInfo.availableCredit
              ? this.props.loyaltyUserInfo.availableCredit
              : 0,*/
          //enteredLoyaltyAmount: newEnternedLoyaltyAmount,
          batchItemInCart: batchItemInCart
        });

        this.props.updateCartSize();
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        this.props.toggleSpinner(false);
      });
  };

  deleteFreeSupplies = (e, labelId) => {
    this.props.toggleSpinner(true);
    axios
      .post("/go/cnsrest/deleteLabel", { cnsCartId: labelId })
      .then((response) => {
        let newFreeSuppliesList = {};
        newFreeSuppliesList.items = this.state.freeSupplies.filter(
          (item, keys) => item.cnsCartId !== labelId
        );
        // analytics.removeFromCart_ShippingCartDelete(
        //   newFreeSuppliesList,
        //   labelId
        // );

        // this.setState({
        //   freeSupplies: newFreeSuppliesList.items
        // });

        this.refreshFreeSuppliesPriceAfterDelete(newFreeSuppliesList.items);
        // let freeSupplies = newFreeSuppliesList.items;
        // let fsPriceCheck = freeSupplies.filter(
        //   (item, keys) => item.cnsCartTypeId.value === 5
        // );

        // let fsItemCheck = freeSupplies.filter(
        //   (item, keys) => item.cnsCartTypeId.value === 4
        // );

        // if (fsPriceCheck.length == 0 || fsItemCheck.length == 0) {
        //   this.setState({
        //     freeSupplies: []
        //   });
        // } else {
        //   this.setState({
        //     freeSupplies: freeSupplies
        //   });
        // }

        this.props.updateCartSize();
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        this.props.toggleSpinner(false);
      });
  };

  refreshFreeSuppliesPriceAfterDelete = (freeSupplies) => {
    let selectedFreeSuppliesArray = [];
    let dataToSend = {};
    dataToSend.items = [];
    for (const i in freeSupplies) {
      if (
        (freeSupplies[i].itemQty > 0 &&
          freeSupplies[i].productId.productId !== 700) ||
        freeSupplies[i].productId.productId !== 701
      ) {
        selectedFreeSuppliesArray.push(freeSupplies[i]);
        dataToSend.items.push({
          productId: freeSupplies[i].productId.productId,
          quantity: freeSupplies[i].itemQty
        });
      }
    }
    axios
      .post("/go/cnsrest/freeSuppliesPrices", JSON.stringify(dataToSend), {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        for (const fs in freeSupplies) {
          if (freeSupplies[fs].cnsCartTypeId.value === 5) {
            freeSupplies[fs].labelCost.postageAmt = this.getTotalShippingCharge(
              response
            );
            freeSupplies[fs].labelCost.ttlShipCostAmt = Number(
              this.getTotalShippingCharge(response)
            );
          }
        }

        let fsPriceCheck = freeSupplies.filter(
          (item, keys) => item.cnsCartTypeId.value === 5
        );

        let fsItemCheck = freeSupplies.filter(
          (item, keys) => item.cnsCartTypeId.value === 4
        );

        if (fsPriceCheck.length == 0 || fsItemCheck.length == 0) {
          this.setState({
            freeSupplies: []
          });
        } else {
          this.setState({
            freeSupplies: freeSupplies
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.props.toggleSpinner(false);
      });
  };

  getTotalShippingCharge = (response) => {
    if (
      response &&
      response.data &&
      response.data.expeditedShipping &&
      response.data.expeditedShipping.items
    ) {
      const itemsArray = response.data.expeditedShipping.items;
      let totalShippingCharge = 0.0;
      for (let i in itemsArray) {
        totalShippingCharge += itemsArray[i].shipCharge;
      }
      return totalShippingCharge.toFixed(2);
    }
    return "0.00";
  };

  /*userAppliedCredit = (userEnteredAmount) => {
    this.props.toggleSpinner(true);
    if (this.state.fetchedCartList.itemsPostageDue) {
      axios
        .post(
          "/go/cnsrest/calculateLoyaltyOffTotal/postageDueCart?loyaltyCreditApplied=" +
            userEnteredAmount +
            "&listOfIds=" +
            this.state.arrOfCartIds
        )
        .then((response) => {
          this.setState({
            enteredLoyaltyAmount: Number(userEnteredAmount).toFixed(2),
            fetchedCartList: {
              ...this.state.fetchedCartList,
              items: response.data.cnsCartList
            }
          });

          this.props.updateCartSize();
        })
        .catch((error) => {
          console.log(error);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        })
        .finally(() => {
          this.props.toggleSpinner(false);
        });
    } else {
      axios
        .post(
          "/go/cnsrest/calculateLoyaltyOffTotal?loyaltyCreditApplied=" +
            userEnteredAmount
        )
        .then((response) => {
          this.setState({
            enteredLoyaltyAmount: Number(userEnteredAmount).toFixed(2),
            fetchedCartList: {
              ...this.state.fetchedCartList,
              items: response.data.cnsCartList
            }
          });

          this.props.updateCartSize();
        })
        .catch((error) => {
          console.log(error);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        })
        .finally(() => {
          this.props.toggleSpinner(false);
        });
    }
  };*/

  getPriceTotal = (e) => {
    let totalAmount = 0;
    for (let item in this.state.fetchedCartList.items) {
      totalAmount += this.state.fetchedCartList.items[item].labelCost
        .ttlShipCostAmt;
    }
    //grab the price of Free Supplies
    if (this.state.freeSupplies) {
      for (let fsItem in this.state.freeSupplies) {
        if (this.state.freeSupplies[fsItem].cnsCartTypeId.value === 5) {
          totalAmount += this.state.freeSupplies[fsItem].labelCost
            .ttlShipCostAmt;
        }
      }
    }
    /*if (this.state.enteredLoyaltyAmount > 0) {
      return Number(totalAmount - this.state.enteredLoyaltyAmount).toFixed(2);
    } else {*/
      return Number(totalAmount).toFixed(2);
    //}
  };

  editCustomsInfo = (e, cnsCartId, productClass) => {
    e.preventDefault();
    if (productClass === "GXG") {
      this.props.history.push("/customsInformationGxg", {
        cnsCartId: cnsCartId
      });
    } else {
      this.props.history.push("/customsInformation", { cnsCartId: cnsCartId });
    }
  };


  goToBilling = (e) => {
    this.state.enteredLoyaltyAmount = 0;
    e.preventDefault();
    this.props.toggleSpinner(true);
    if (Number(this.getPriceTotal()) === 0) {
      axios
        .get(
          "/go/cnsrest/checkout/loyaltyZeroTotal?mobileParam=false&loyaltyPointsApplied=" +
            String(this.state.enteredLoyaltyAmount)
        )
        .then((response) => {
          this.props.history.push(response.data.redirectUrl);
        })
        .catch((error) => {
          console.log(error);
          this.props.toggleSpinner(false);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        });
    } else {
      axios
        .get(
          "/go/cnsrest/checkout?mobileParam=false&loyaltyPointsApplied=" +
            String(this.state.enteredLoyaltyAmount)
        )
        .then((response) => {
          if (
            response.data.actionErrors &&
            response.data.actionErrors.length > 0
          ) {
            this.props.toggleSpinner(false);
            window.scrollTo(0, 0);
            this.setGlobalError(response.data.actionErrors);
          } else {
            var checkoutRedirectData = {};
            checkoutRedirectData.mobile = response.data.mobile;
            checkoutRedirectData.clientId = response.data.paymentToken.clientId;
            checkoutRedirectData.token = response.data.paymentToken.token;
            checkoutRedirectData.order = response.data.paymentToken.orderNumber;

            this.redirectToBilling(checkoutRedirectData);
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.toggleSpinner(false);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        });
    }
  };

  redirectToBilling = (e) => {
    axios
      .post("/go/cnsrest/checkoutRedirect", e, {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        window.location.replace(response.data);
      })
      .catch((error) => {
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        console.log(error);
      });
  };

  createNewLabel = (e, cnsCartId) => {
    e.preventDefault();
    this.props.history.push("/labelInformation");
  };

  selectNormalLabel = (cartObject, isChecked) => {
    if (isChecked) {
      let arrOfCartIds = this.state.arrOfCartIds.concat(cartObject.cnsCartId);
      /*let newEligibleLoyaltyAmount = 0;

      let arrOfEligibleLoyaltyProducts = [
        100,
        110,
        111,
        112,
        113,
        114,
        120,
        130,
        131,
        132,
        133,
        134,
        135,
        150,
        151,
        152,
        153,
        154,
        155,
        156,
        157,
        158,
        159,
        160,
        161,
        302,
        200,
        201,
        210,
        211,
        212,
        213,
        214,
        220,
        221,
        222
      ];*/

      //let eligibleProduct = false;
      for (let item in cartObject) {
        /*for (let eligibleLPAItem in arrOfEligibleLoyaltyProducts) {
          if (
            Number(arrOfEligibleLoyaltyProducts[eligibleLPAItem]) ===
            cartObject.product.productId
          ) {
            eligibleProduct = true;
          }
        }*/
      }

      for (let item in this.state.fetchedCartList.items) {
        for (let selecteditem in arrOfCartIds) {
          if (
            arrOfCartIds[selecteditem] ===
            this.state.fetchedCartList.items[item].cnsCartId
          ) {
            /*for (let eligibleLPAItem in arrOfEligibleLoyaltyProducts) {
              if (
                Number(arrOfEligibleLoyaltyProducts[eligibleLPAItem]) ===
                  this.state.fetchedCartList.items[item].productId.productId &&
                arrOfCartIds[selecteditem] ===
                  this.state.fetchedCartList.items[item].cnsCartId
              ) {
                newEligibleLoyaltyAmount += this.state.fetchedCartList.items[
                  item
                ].labelCost.postageAmt;
              }
            }*/
          }
        }

        this.setState({
          arrOfCartIds: arrOfCartIds/*,
          eligibleLoyaltyCredits:
            newEligibleLoyaltyAmount > this.state.LPAUserData.availableCredit
              ? this.state.LPAUserData.availableCredit
              : newEligibleLoyaltyAmount*/
        });
      }
    } else {
      const arrOfCartIds = this.state.arrOfCartIds.filter(
        (item, j) => cartObject.cnsCartId !== item
      );

      /*let newEligibleLoyaltyAmount = 0;

      let arrOfEligibleLoyaltyProducts = [
        100,
        110,
        111,
        112,
        113,
        114,
        120,
        130,
        131,
        132,
        133,
        134,
        135,
        150,
        151,
        152,
        153,
        154,
        155,
        156,
        157,
        158,
        159,
        160,
        161,
        302,
        200,
        201,
        210,
        211,
        212,
        213,
        214,
        220,
        221,
        222
      ];

      let eligibleProduct = false;
      for (let item in cartObject) {
        for (let eligibleLPAItem in arrOfEligibleLoyaltyProducts) {
          if (
            Number(arrOfEligibleLoyaltyProducts[eligibleLPAItem]) ===
            cartObject.product.productId
          ) {
            eligibleProduct = true;
          }
        }
      }*/

      /*for (let item in this.state.fetchedCartList.items) {
        for (let selecteditem in arrOfCartIds) {
          if (
            arrOfCartIds[selecteditem] ===
            this.state.fetchedCartList.items[item].cnsCartId
          ) {
            for (let eligibleLPAItem in arrOfEligibleLoyaltyProducts) {
              if (
                Number(arrOfEligibleLoyaltyProducts[eligibleLPAItem]) ===
                  this.state.fetchedCartList.items[item].productId.productId &&
                arrOfCartIds[selecteditem] ===
                  this.state.fetchedCartList.items[item].cnsCartId
              ) {
                newEligibleLoyaltyAmount += this.state.fetchedCartList.items[
                  item
                ].labelCost.postageAmt;
              }
            }
          }
        }
      }*/
      this.userAppliedCredit(0);

      this.setState((state) => {
        const arrOfCartIds = state.arrOfCartIds.filter(
          (item, j) => cartObject.cnsCartId !== item
        );
        return {
          arrOfCartIds/*,
          eligibleLoyaltyCredits:
            newEligibleLoyaltyAmount > this.state.LPAUserData.availableCredit
              ? this.state.LPAUserData.availableCredit
              : newEligibleLoyaltyAmount*/
        };
      });
    }
  };

  getPostageDuePriceTotal = (e) => {
    let totalAmount = 0;
    for (let item in this.state.fetchedCartList.itemsPostageDue) {
      if (
        this.state.fetchedCartList.itemsPostageDue[item].cartOverflow === "Y"
      ) {
        totalAmount += this.state.fetchedCartList.itemsPostageDue[item]
          .labelCost.ttlShipCostAmt;
      }
    }
    return totalAmount.toFixed(2);
  };

  getCartOverFlowPostageDueCount = (e) => {
    let totalAmount = 0;
    for (let item in this.state.fetchedCartList.itemsPostageDue) {
      if (
        this.state.fetchedCartList.itemsPostageDue[item].cartOverflow === "Y"
      ) {
        totalAmount++;
      }
    }
    return totalAmount;
  };

  goToPostageDueOrder = (e, cartItem) => {
    e.preventDefault();
    this.props.history.push("/labelDetails", {
      orderItemId: cartItem.orderItemId
    });
  };

  cartOverFlowNoCount = (e) => {
    let remainingPostageDueCount = 0;
    for (let item in this.state.fetchedCartList.itemsPostageDue) {
      if (!this.state.fetchedCartList.itemsPostageDue[item].cartOverflow) {
        remainingPostageDueCount++;
      }
    }
    return remainingPostageDueCount;
  };

  cartOverFlowTotal = (e) => {
    let remainingPostageDueCount = 0;
    for (let item in this.state.fetchedCartList.itemsPostageDue) {
      if (!this.state.fetchedCartList.itemsPostageDue[item].cartOverflow) {
        remainingPostageDueCount += this.state.fetchedCartList.itemsPostageDue[
          item
        ].labelCost.ttlShipCostAmt;
      }
    }
    return remainingPostageDueCount.toFixed(2);
  };

  handleClick(e, index) {
    e.preventDefault();
    this.props.setPageNum(index + 1);
    this.setState({
      currentPage: index
    });
  }

  goToBillingPostageDue = (e) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    axios
      .post(
        "/go/cnsrest/checkout?mobileParam=false&listOfIds=" +
          this.state.arrOfCartIds +
          "&loyaltyPointsApplied=" +
          String(this.state.enteredLoyaltyAmount)
      )
      .then((response) => {
        var checkoutRedirectData = {};
        checkoutRedirectData.mobile = response.data.mobile;
        checkoutRedirectData.clientId = response.data.paymentToken.clientId;
        checkoutRedirectData.token = response.data.paymentToken.token;
        checkoutRedirectData.order = response.data.paymentToken.orderNumber;

        this.redirectToBilling(checkoutRedirectData);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  nonOverFlowPostageDueArr = (e) => {
    let remainingPostageDueCount = [];
    for (let item in this.state.fetchedCartList.itemsPostageDue) {
      if (!this.state.fetchedCartList.itemsPostageDue[item].cartOverflow) {
        remainingPostageDueCount[
          item
        ] = this.state.fetchedCartList.itemsPostageDue[item];
      }
    }

    // remainingPostageDueCount = remainingPostageDueCount
    //   .slice(this.state.currentPage * this.pageSize, (this.state.currentPage + 1) * this.pageSize)
    //   .map((data, i) => !data.cartOverflow);
    return remainingPostageDueCount;
  };

  displayRemainingPostageDue = (action) => {
    if (action === "show") {
      this.setState({ displayRemaining: true });
    } else {
      this.setState({ displayRemaining: false });
    }
  };

  getPostageDueAndSelectedLabelCost = (e) => {
    let totalAmount = 0;
    for (let item in this.state.fetchedCartList.itemsPostageDue) {
      if (
        this.state.fetchedCartList.itemsPostageDue[item].cartOverflow === "Y"
      ) {
        totalAmount += this.state.fetchedCartList.itemsPostageDue[item]
          .labelCost.ttlShipCostAmt;
      }
    }

    for (let item in this.state.arrOfCartIds) {
      for (let cartItem in this.state.fetchedCartList.items)
        if (
          this.state.fetchedCartList.items[cartItem].cnsCartId ===
          this.state.arrOfCartIds[item]
        )
          totalAmount += this.state.fetchedCartList.items[cartItem].labelCost
            .ttlShipCostAmt;
    }

  return Number(totalAmount /*- this.state.enteredLoyaltyAmount*/).toFixed(2);
  };

  showInsurance = (productClassDesc, labelCost) => {
    if (productClassDesc && labelCost) {
      const productWithoutSymbols = productClassDesc
        .replace("&reg;", "")
        .replace("®", "")
        .replace("&trade;", "")
        .replace("™", "");
      // If not CL, not CLM, and not PSG, show insurance
      if (
        productWithoutSymbols !== "USPS Connect Local" &&
        productWithoutSymbols !== "USPS Connect Local Mail" &&
        productWithoutSymbols !== "Parcel Select Ground"
      ) {
        return true;
      }
      // If the label has an insurance amount, show insurance
      if (labelCost.insuranceCostAmt > 0 || labelCost.insRestrictedDelAmt > 0) {
        return true;
      }
    }
    return false;
  };

  render() {
    // const { currentPage } = this.state;
    return (
      <React.Fragment>
        <div
          id="shipping-cart-top-id"
          className="Shipping_Cart_Container"
          tabIndex="-1"
        >
          <div className="container-fluid">
            <h2 className="d-md-none">
              Shipping Cart (
              {(this.state.fetchedCartList &&
                this.state.fetchedCartList.items &&
                this.state.fetchedCartList.items.length) ||
                0}
              )
            </h2>
            {this.state.batchItemInCart ? (
              <p style={{ color: "#e0a904" }}>
                <img
                  alt="An upside-down gold triangle with an exclamation mark inside it"
                  src={batchAlertIcon}
                />{" "}
                Batched labels allow individual address edits but all other
                changes affect all labels in the batch.
              </p>
            ) : (
              <React.Fragment />
            )}
            <AssistiveGlobalError
              globalErrorArray={this.state.errors.globalError}
            />
            {this.state.isLoaded ? (
              <div className="row">
                <div className="col-12  shipping-cart-table">
                  {(this.state.fetchedCartList.itemsPostageDue ||
                    Object.keys(this.state.parcelSelectGroups).length > 0) &&
                  this.state.fetchedCartList.items.length -
                    this.state.fetchedCartList.items.filter(
                      (psFlag) => psFlag.parcelSelectType
                    ).length !==
                    0 ? (
                    <h2 className="d-md" style={{ paddingTop: "10px" }}>
                      <u>Labels</u>
                    </h2>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.fetchedCartList.items.length -
                    this.state.fetchedCartList.items.filter(
                      (psFlag) => psFlag.parcelSelectType
                    ).length !==
                  0 ? (
                    <div className="row shipping-cart-table-header">
                      <div className="d-none d-md-block col-2" />

                      <div className="d-none d-md-block col-3">
                        <p>
                          <strong>Shipping Address</strong>
                        </p>
                      </div>
                      <div className="d-none d-md-block col-3">
                        <p>
                          <strong>Package Details</strong>
                        </p>
                      </div>
                      <div className="d-none d-md-block col-3">
                        <p>
                          <strong>Service</strong>
                        </p>
                      </div>
                      <div className="d-none d-md-block col-1">
                        <p>
                          <strong>Price</strong>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.fetchedCartList.items.length === 0 ? (
                    <div className="row shipping-cart-table-placeholder">
                      <div className="col-12">
                        <p>There are currently no labels in the cart.</p>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.fetchedCartList.items.map((item, keys) => (
                    <React.Fragment key={item.cnsCartId}>
                      {item.labelCost.ttlShipCostAmt !== undefined &&
                      !item.parcelSelectType ? (
                        <React.Fragment>
                          <React.Fragment>
                            {item.errorCode &&
                            item.errorCode.errorId !== "NO_ERROR" ? (
                              <p className="error-message">
                                {item.errorCode.cartMessage +
                                  " - " +
                                  item.errorMsg}
                              </p>
                            ) : (
                              <React.Fragment />
                            )}
                          </React.Fragment>
                          <div className="row shipping-cart-item">
                            <div className="col-1 d-md-none order-1 mobile-shipping-cart-number">
                              <p />
                            </div>
                            <div className="col-3 col-md-2 shipping-cart-edit-delete text-right text-md-left order-3 order-md-2">
                              <p className="shipping-cart-number d-none d-md-block">
                                {" "}
                                of {this.state.fetchedCartList.items.length}
                              </p>

                              <p>
                                <a
                                  className="inline-link-edit"
                                  href="/labelInformation"
                                  onClick={(e) => this.editLabel(e, item)}
                                  aria-label="Edit this label"
                                >
                                  <strong className="d-none d-md-inline">
                                    Edit
                                  </strong>
                                </a>
                              </p>
                              <p>
                                <DeleteLabelModal
                                  deleteCartLabel={this.deleteCartLabel}
                                  item={item}
                                  isLastConnectLocalItemWithFreeSupplies={
                                    this.state.numberOfConnectLocalLabels ===
                                      1 &&
                                    this.state.freeSupplies &&
                                    this.state.freeSupplies.length > 0
                                  }
                                />
                              </p>
                              {this.state.fetchedCartList.itemsPostageDue &&
                              this.getCartOverFlowPostageDueCount() < 20 ? (
                                <label className="checkbox-component">
                                  <input
                                    id={"cart-label" + item.cnsCartId}
                                    type="checkbox"
                                    onChange={(e) =>
                                      this.selectNormalLabel(
                                        item,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <span className="checkbox" />
                                </label>
                              ) : (
                                <React.Fragment />
                              )}
                            </div>
                            <div className="col-8 col-md-3 order-2 order-md-3 addOverflowWrap">
                              {item.deliveryAddress.countryId === 840 ? (
                                <p>
                                  {item.deliveryAddress.fullName ? (
                                    <React.Fragment>
                                      {item.deliveryAddress.fullName}
                                      <br />
                                    </React.Fragment>
                                  ) : null}
                                  {item.deliveryAddress.companyName !==
                                  undefined ? (
                                    <React.Fragment>
                                      {" "}
                                      {item.deliveryAddress.companyName} <br />
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                  {item.deliveryAddress.line1Addr}
                                  <br />
                                  {item.deliveryAddress.cityName},{" "}
                                  {item.deliveryAddress.stateCode}{" "}
                                  {item.deliveryAddress.zip5}
                                  {item.deliveryAddress.zipPlus4
                                    ? "-" + item.deliveryAddress.zipPlus4
                                    : ""}
                                  <br />
                                  {item.deliveryAddress.refNbr !== undefined
                                    ? "Ref#: " + item.deliveryAddress.refNbr
                                    : ""}
                                </p>
                              ) : (
                                <p>
                                  {item.deliveryAddress.fullName ? (
                                    <React.Fragment>
                                      {item.deliveryAddress.fullName}
                                      <br />
                                    </React.Fragment>
                                  ) : null}
                                  {item.deliveryAddress.companyName !==
                                  undefined ? (
                                    <span>
                                      {" "}
                                      {item.deliveryAddress.companyName} <br />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.deliveryAddress.line1Addr}
                                  <br />
                                  {item.deliveryAddress.line2Addr !==
                                  undefined ? (
                                    <span>
                                      {" "}
                                      {item.deliveryAddress.line2Addr} <br />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.deliveryAddress.line3Addr !==
                                  undefined ? (
                                    <span>
                                      {" "}
                                      {item.deliveryAddress.line3Addr} <br />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.deliveryAddress.cityName},{" "}
                                  {item.deliveryAddress.province} <br />
                                  {item.deliveryAddress.countryName}{" "}
                                  {item.deliveryAddress.zip5}
                                  {item.deliveryAddress.zipPlus4}
                                  <br />
                                  {item.deliveryAddress.refNbr !== undefined
                                    ? "Ref#: " + item.deliveryAddress.refNbr
                                    : ""}
                                </p>
                              )}
                            </div>
                            <div className="col-11 col-md-3 order-4 offset-1 offset-md-0">
                              <p className="d-md-none mobile-shipping-cart-package-details">
                                Package Details
                              </p>

                              {item.aesDetail !== "" ? (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.packageDetailsDisplay
                                  }}
                                />
                              ) : (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.packageDetailsDisplay
                                  }}
                                />
                              )}

                              <p>
                                {item.labelInd.customsAcknowledgeInd === "Y" ? (
                                  <a
                                    href={
                                      item.product.productClass === "GXG"
                                        ? "/customsInformationGxg"
                                        : "/customsInformation"
                                    }
                                    className="inline-link-edit"
                                    onClick={(e) =>
                                      this.editCustomsInfo(
                                        e,
                                        item.cnsCartId,
                                        item.product.productClass
                                      )
                                    }
                                    aria-label="Edit customs information"
                                  >
                                    <strong>Edit</strong>
                                  </a>
                                ) : (
                                  <React.Fragment />
                                )}
                              </p>
                            </div>
                            <div className="col-11 col-md-4 order-5 offset-1 offset-md-0 service-type-table">
                              <p className="d-md-none mobile-shipping-cart-service">
                                Service
                              </p>
                              <div className="row">
                                <div className="col-8">
                                  {(item.labelInd.commercialInd === "Y" ||
                                    item.labelInd.commercialInd === "P" ||
                                    item.labelInd.commercialInd === "M" ||
                                    item.labelInd.commercialInd === "E") &&
                                  item.productId.productId !== 999 &&
                                  item.productId.productId !== 103 ? (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.product.productClassDesc +
                                          " - Commercial Base"
                                      }}
                                    />
                                  ) : (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.product.productClassDesc
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-4 text-right">
                                  {!item.product.productDesc ? (
                                    <p>
                                      $
                                      {item.labelCost.postageAmt
                                        ? item.labelCost.postageAmt.toFixed(2)
                                        : "0.00"}
                                    </p>
                                  ) : (
                                    <p />
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-8">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.product.productDesc
                                    }}
                                  />
                                </div>
                                <div className="col-4 text-right">
                                  {item.product.productDesc ? (
                                    <p>
                                      $
                                      {item.labelCost.postageAmt
                                        ? item.labelCost.postageAmt.toFixed(2)
                                        : "0.00"}
                                    </p>
                                  ) : (
                                    <p />
                                  )}
                                </div>
                              </div>

                              {item.labelInd.additionalService.displayText ===
                              "NONE" ? (
                                <React.Fragment />
                              ) : (
                                <div className="row">
                                  <div className="col-8">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.labelInd.additionalService
                                            .displayText ===
                                          "Signature Confirmation"
                                            ? "Signature Confirmation™"
                                            : item.labelInd.additionalService
                                                ._name === "DEL"
                                            ? "Signature Required"
                                            : item.labelInd.additionalService
                                                .displayText
                                      }}
                                    />
                                  </div>
                                  <div className="col-4 text-right">
                                    <p>
                                      {item.labelCost.additionalServiceAmt
                                        ? "$" +
                                          item.labelCost.additionalServiceAmt.toFixed(
                                            2
                                          )
                                        : "Free"}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {this.showInsurance(
                                item.product.productClassDesc,
                                item.labelCost
                              ) ? (
                                <div className="row">
                                  <div className="col-8">
                                    <p>Insurance</p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <p>
                                      {item.labelCost.insuranceCostAmt > 0
                                        ? "$" +
                                          item.labelCost.insuranceCostAmt.toFixed(
                                            2
                                          )
                                        : item.labelCost.insRestrictedDelAmt > 0
                                        ? "$" +
                                          item.labelCost.insRestrictedDelAmt.toFixed(
                                            2
                                          )
                                        : "Free"}
                                    </p>
                                  </div>
                                </div>
                              ) : null}
                              {item.deliveryAddress.countryId === 840 ? (
                                <div className="row">
                                  <div className="col-8">
                                    <p>
                                      USPS Tracking<sup>®</sup>
                                    </p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <p>Free</p>
                                  </div>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}
                              {item.hazmatType != undefined &&
                              item.hazmatType != "0" ? (
                                <div className="row">
                                  <div className="col-8">
                                    <p>HAZMAT</p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <p>Free</p>
                                  </div>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}
                              {item.labelInd.stealthPostageInd === "Y" ? (
                                <div className="row">
                                  <div className="col-8">
                                    <p>Suppress Postage from Label</p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <p>Free</p>
                                  </div>
                                </div>
                              ) /*: (
                                <React.Fragment />
                              )}

                              {item.loyaltyDisplayAmountCart !== undefined &&
                              item.loyaltyDisplayAmountCart > 0 ? (
                                <div className="row">
                                  <div className="col-8">
                                    <p>Loyalty Credit Applied</p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <p>
                                      -$
                                      {item.loyaltyDisplayAmountCart.toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                              )*/ : (
                                <React.Fragment />
                              )}
                              {item.labelInd.crematedRemainsInd === "Y" ? (
                                <div className="row">
                                  <div className="col-8">
                                    <p>Cremated Remains</p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <p>Free</p>
                                  </div>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}
                              {item.labelInd.deliveryTimeOption === "Y" ? (
                                <React.Fragment>
                                  <div className="row">
                                    <div className="col-8">
                                      <p>10:30 Delivery Time</p>
                                    </div>
                                    <div className="col-4 text-right">
                                      <p>
                                        {item.labelCost.deliveryTimeAmt > 0
                                          ? "$" +
                                            item.labelCost.deliveryTimeAmt.toFixed(
                                              2
                                            )
                                          : "Free"}
                                      </p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )}
                              <div className="row shipping-cart-table-totals">
                                <div className="col-8">
                                  <p>
                                    <strong>Total Label Cost</strong>
                                  </p>
                                </div>
                                {/* <div className="col-4 text-right"> 
                                  <p>
                                    <strong>
                                      $
                                      {item.loyaltyDisplayAmountCart ===
                                      undefined
                                        ? item.labelCost.ttlShipCostAmt.toFixed(
                                            2
                                          )
                                        : Number(
                                            item.labelCost.ttlShipCostAmt -
                                              item.loyaltyDisplayAmountCart
                                          ).toFixed(2)}
                                    </strong>
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          {item.packageDetails.pounds >= 65 &&
                          item.packageDetails.pounds <= 70 &&
                          item.packageDetails.pkgGirthQty +
                            item.packageDetails.pkgLengthQty <
                            120 ? (
                            <p style={{ paddingBottom: "15px" }}>
                              <strong>
                                The maximum allowed package weight is 70 lbs. If
                                your package is found to weigh over 70 pounds,
                                you may be charged an additional overweight
                                surcharge of $100.
                              </strong>
                            </p>
                          ) : item.packageDetails.pounds < 65 &&
                            item.packageDetails.pkgGirthQty +
                              item.packageDetails.pkgLengthQty >=
                              120 &&
                            item.packageDetails.pkgGirthQty +
                              item.packageDetails.pkgLengthQty <=
                              130 ? (
                            <p style={{ paddingBottom: "15px" }}>
                              <strong>
                                The maximum allowed package size is 130 inches
                                in length and girth. If your package is found to
                                measure over 130 inches, you may be charged an
                                additional oversize surcharge of $100.
                              </strong>
                            </p>
                          ) : item.packageDetails.pounds >= 65 &&
                            item.packageDetails.pounds <= 70 &&
                            item.packageDetails.pkgGirthQty +
                              item.packageDetails.pkgLengthQty >=
                              120 &&
                            item.packageDetails.pkgGirthQty +
                              item.packageDetails.pkgLengthQty <=
                              130 ? (
                            <p style={{ paddingBottom: "15px" }}>
                              <strong>
                                The maximum allowed package weight is 70lbs. The
                                maximum allowed package size is 130 inches in
                                length and girth. If your package is found to be
                                over 70 pounds and/or measure over 130 inches,
                                you may be charged an additional surcharge of
                                $100.
                              </strong>
                            </p>
                          ) : (
                            <React.Fragment />
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment />
                      )}
                    </React.Fragment>
                  ))}
                  {/* ParcelSelect */}
                  {Object.keys(this.state.parcelSelectGroups).length > 0 ? (
                    <React.Fragment>
                      {Object.keys(this.state.parcelSelectGroups).map(
                        (name, keys) => (
                          <React.Fragment key={name}>
                            <h2 className="d-md" style={{ paddingTop: "10px" }}>
                              <u>
                                USPS Connect™ Local Drop-Off Location - {name}
                              </u>
                            </h2>
                            <React.Fragment>
                              <h2
                                className="d-md"
                                style={{ paddingTop: "10px" }}
                              >
                                <u></u>
                              </h2>
                              <div
                                className="row shipping-cart-table-header"
                                style={{ paddingTop: "20px" }}
                              >
                                <div className="d-none d-md-block col-2" />
                                <div className="d-none d-md-block col-3">
                                  <p>
                                    <strong>Shipping Address</strong>
                                  </p>
                                </div>
                                <div className="d-none d-md-block col-3">
                                  <p>
                                    <strong>Package Details</strong>
                                  </p>
                                </div>
                                <div className="d-none d-md-block col-3">
                                  <p>
                                    <strong>Service</strong>
                                  </p>
                                </div>
                                <div className="d-none d-md-block col-1">
                                  <p>
                                    <strong>Price</strong>
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                            {this.state.parcelSelectGroups[name].map(
                              (item, keys) => (
                                <React.Fragment key={item.cnsCartId}>
                                  <div className="row shipping-cart-item">
                                    <div className="col-1 d-md-none order-1 mobile-shipping-cart-number">
                                      <p />
                                    </div>

                                    <div className="col-3 col-md-2 shipping-cart-edit-delete text-right text-md-left order-3 order-md-2">
                                      <p className="d-none d-md-block"> </p>

                                      <p className=" d-none d-md-block">
                                        {item.cartCount} of{" "}
                                        {
                                          this.state.fetchedCartList.items
                                            .length
                                        }
                                      </p>

                                      <p>
                                        <a
                                          className="inline-link-edit"
                                          href="/labelInformation"
                                          onClick={(e) =>
                                            this.editLabel(e, item)
                                          }
                                          aria-label="Edit this label"
                                        >
                                          <strong className="d-none d-md-inline">
                                            Edit
                                          </strong>
                                        </a>
                                      </p>
                                      <p>
                                        <DeleteLabelModal
                                          deleteCartLabel={this.deleteCartLabel}
                                          item={item}
                                          isLastConnectLocalItemWithFreeSupplies={
                                            this.state
                                              .numberOfConnectLocalLabels ===
                                              1 &&
                                            this.state.freeSupplies &&
                                            this.state.freeSupplies.length > 0
                                          }
                                        />
                                      </p>
                                      {this.state.fetchedCartList
                                        .itemsPostageDue &&
                                      this.getCartOverFlowPostageDueCount() <
                                        20 ? (
                                        <label className="checkbox-component">
                                          <input
                                            id={"cart-label" + item.cnsCartId}
                                            type="checkbox"
                                            onChange={(e) =>
                                              this.selectNormalLabel(
                                                item,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <span className="checkbox" />
                                        </label>
                                      ) : (
                                        <React.Fragment />
                                      )}
                                    </div>

                                    <div className="col-8 col-md-3 order-2 order-md-3">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item.deliveryAddress.displayAddress
                                        }}
                                      />
                                    </div>
                                    <div className="col-11 col-md-3 order-4 offset-1 offset-md-0">
                                      <p className="d-md-none mobile-shipping-cart-package-details">
                                        Package Details
                                      </p>

                                      {item.aesDetail !== "" ? (
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: item.packageDetailsDisplay
                                          }}
                                        />
                                      ) : (
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: item.packageDetailsDisplay
                                          }}
                                        />
                                      )}
                                      <p>
                                        {item.deliveryAddress.countryId !==
                                        840 ? (
                                          <a
                                            style={{ cursor: "pointer" }}
                                            href="/labelDetails"
                                            className=""
                                            onClick={(e) =>
                                              this.goToPostageDueOrder(
                                                e,
                                                item.cnsCartId
                                              )
                                            }
                                            aria-label="Details of this postage due"
                                          >
                                            <strong>Details</strong>
                                          </a>
                                        ) : (
                                          <React.Fragment />
                                        )}
                                      </p>
                                    </div>
                                    <div className="col-11 col-md-4 order-5 offset-1 offset-md-0 service-type-table">
                                      <p className="d-md-none mobile-shipping-cart-service">
                                        Service
                                      </p>
                                      <div className="row">
                                        <div className="col-8">
                                          {item.labelInd.commercialInd ===
                                          "Y" ? (
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  item.product
                                                    .productClassDesc +
                                                  " - Commercial Base"
                                              }}
                                            />
                                          ) : (
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  item.product.productClassDesc
                                              }}
                                            />
                                          )}
                                        </div>
                                        <div className="col-4 text-right">
                                          {!item.product.productDesc ? (
                                            <p>
                                              $
                                              {item.labelCost.postageAmt
                                                ? item.labelCost.postageAmt.toFixed(
                                                    2
                                                  )
                                                : "0.00"}
                                            </p>
                                          ) : (
                                            <p />
                                          )}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-8">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: item.product.productDesc
                                            }}
                                          />
                                        </div>
                                        <div className="col-4 text-right">
                                          {item.product.productDesc ? (
                                            <p>
                                              $
                                              {item.labelCost.postageAmt
                                                ? item.labelCost.postageAmt.toFixed(
                                                    2
                                                  )
                                                : "0.00"}
                                            </p>
                                          ) : (
                                            <p />
                                          )}
                                        </div>
                                      </div>

                                      {/* //SUNDAY DELIVERY */}
                                      {item.labelInd.sundayDeliveryInd ===
                                      "Y" ? (
                                        <div className="row">
                                          <div className="col-8">
                                            <p>Sunday Delivery</p>
                                          </div>
                                          <div className="col-4 text-right">
                                            <p>
                                              $
                                              {item.labelCost.sundayDeliveryAmt
                                                ? item.labelCost.sundayDeliveryAmt.toFixed(
                                                    2
                                                  )
                                                : "0.00"}
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <p></p>
                                      )}
                                      {/* // */}

                                      {item.labelInd.additionalService
                                        .displayText === "NONE" ||
                                      item.labelInd.additionalService._name ===
                                        "DEL" ? (
                                        <React.Fragment />
                                      ) : (
                                        <div className="row">
                                          <div className="col-8">
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  item.labelInd
                                                    .additionalService
                                                    .displayText
                                              }}
                                            />
                                          </div>
                                          <div className="col-4 text-right">
                                            <p>
                                              {item.labelCost
                                                .additionalServiceAmt
                                                ? "$" +
                                                  item.labelCost.additionalServiceAmt.toFixed(
                                                    2
                                                  )
                                                : "Free"}
                                            </p>
                                          </div>
                                        </div>
                                      )}

                                      {this.showInsurance(
                                        item.product.productClassDesc,
                                        item.labelCost
                                      ) ? (
                                        <div className="row">
                                          <div className="col-8">
                                            <p>Insurance</p>
                                          </div>
                                          <div className="col-4 text-right">
                                            <p>
                                              {item.labelCost.insuranceCostAmt >
                                              0
                                                ? "$" +
                                                  item.labelCost.insuranceCostAmt.toFixed(
                                                    2
                                                  )
                                                : item.labelCost
                                                    .insRestrictedDelAmt > 0
                                                ? "$" +
                                                  item.labelCost.insRestrictedDelAmt.toFixed(
                                                    2
                                                  )
                                                : "Free"}
                                            </p>
                                          </div>
                                        </div>
                                      ) : null}

                                      {item.deliveryAddress.countryId ===
                                      840 ? (
                                        <div className="row">
                                          <div className="col-8">
                                            <p>
                                              USPS Tracking<sup>®</sup>
                                            </p>
                                          </div>
                                          <div className="col-4 text-right">
                                            <p>Free</p>
                                          </div>
                                        </div>
                                      ) : (
                                        <React.Fragment />
                                      )}
                                      {item.labelInd.stealthPostageInd ===
                                      "Y" ? (
                                        <div className="row">
                                          <div className="col-8">
                                            <p>Suppress Postage from Label</p>
                                          </div>
                                          <div className="col-4 text-right">
                                            <p>Free</p>
                                          </div>
                                        </div>
                                      ) : (
                                        <React.Fragment />
                                      )}
                                      <div className="row shipping-cart-table-totals">
                                        <div className="col-8">
                                          <p>
                                            <strong>Total Label Cost</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 text-right">
                                          <p>
                                            <strong>
                                              $
                                              {item.labelCost.ttlShipCostAmt.toFixed(
                                                2
                                              )}
                                            </strong>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )
                            )}
                          </React.Fragment>
                        )
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                  {/* End ParcelSelect */}
                  {/* Free Supplies */}
                  {this.props.userData.accountType === "Business" &&
                    this.props.userData.parcelSelectFlag &&
                    Object.keys(this.state.parcelSelectGroups).length > 0 &&
                    this.state.freeSupplies &&
                    this.state.freeSupplies.length > 0 && (
                      <React.Fragment>
                        <h2 className="d-md" style={{ paddingTop: "10px" }}>
                          <u>USPS Connect™ Local Free Shipping Supplies</u>{" "}
                          <FreeShippingSuppliesModal
                            targetName="freeSuppliesButton"
                            toggleSpinner={this.props.toggleSpinner}
                            freeSuppliesCurrentlySaved={this.state.freeSupplies}
                            fetchCart={this.fetchCart}
                            className="free-shipping-modal"
                          />
                        </h2>
                        <FreeSuppliesTable
                          freeSupplies={this.state.freeSupplies}
                          deleteFreeSupplies={this.deleteFreeSupplies}
                        />
                      </React.Fragment>
                    )}
                  {/* End Free Supplies*/}
                  {this.state.fetchedCartList.itemsPostageDue ? (
                    <div>
                      <span className="inline-link-edit">
                        <strong className="d-none d-md-inline">
                          Labels Total: ${this.getPriceTotal()}
                        </strong>
                      </span>

                      <br />
                      <br />
                      <br />
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.fetchedCartList.itemsPostageDue ? (
                    <React.Fragment>
                      <h2 className="d-md" style={{ paddingTop: "10px" }}>
                        <u>
                          Postage Dues for this Order:{" "}
                          {this.getCartOverFlowPostageDueCount()}
                        </u>
                      </h2>
                      <div
                        className="row shipping-cart-table-header"
                        style={{ paddingTop: "20px" }}
                      >
                        <div className="d-none d-md-block col-2" />
                        <div className="d-none d-md-block col-3">
                          <p>
                            <strong>Shipping Address</strong>
                          </p>
                        </div>
                        <div className="d-none d-md-block col-3">
                          <p>
                            <strong>Package Details</strong>
                          </p>
                        </div>
                        <div className="d-none d-md-block col-3">
                          <p>
                            <strong>Service</strong>
                          </p>
                        </div>
                        <div className="d-none d-md-block col-1">
                          <p>
                            <strong>Price</strong>
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.fetchedCartList.itemsPostageDue ? (
                    <React.Fragment>
                      {this.state.fetchedCartList.itemsPostageDue.map(
                        (item, keys) => (
                          <React.Fragment key={item.cnsCartId}>
                            {item.cartOverflow === "Y" ? (
                              <div className="row shipping-cart-item">
                                <div className="col-1 d-md-none order-1 mobile-shipping-cart-number">
                                  <p />
                                </div>

                                <div className="col-3 col-md-2 shipping-cart-edit-delete text-right text-md-left order-3 order-md-2">
                                  <p className="d-none d-md-block">
                                    {" "}
                                    {keys + 1} of{" "}
                                    {
                                      this.state.fetchedCartList.itemsPostageDue
                                        .length
                                    }
                                  </p>

                                  <p>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className=""
                                      href="/labelDetails"
                                      onClick={(e) =>
                                        this.goToPostageDueOrder(e, item)
                                      }
                                      aria-label="Details of this postage due"
                                    >
                                      <strong className="d-none d-md-inline">
                                        Details
                                      </strong>
                                    </a>
                                  </p>
                                </div>

                                <div className="col-8 col-md-3 order-2 order-md-3">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.deliveryAddress.displayAddress
                                    }}
                                  />
                                </div>
                                <div className="col-11 col-md-3 order-4 offset-1 offset-md-0">
                                  <p className="d-md-none mobile-shipping-cart-package-details">
                                    Package Details
                                  </p>

                                  {item.aesDetail !== "" ? (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.packageDetailsDisplay
                                      }}
                                    />
                                  ) : (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.packageDetailsDisplay
                                      }}
                                    />
                                  )}
                                  <p>
                                    {item.deliveryAddress.countryId !== 840 ? (
                                      <a
                                        style={{ cursor: "pointer" }}
                                        href="/labelDetails"
                                        className=""
                                        onClick={(e) =>
                                          this.goToPostageDueOrder(
                                            e,
                                            item.cnsCartId
                                          )
                                        }
                                        aria-label="Details of this postage due"
                                      >
                                        <strong>Details</strong>
                                      </a>
                                    ) : (
                                      <React.Fragment />
                                    )}
                                  </p>
                                </div>
                                <div className="col-11 col-md-4 order-5 offset-1 offset-md-0 service-type-table">
                                  <p className="d-md-none mobile-shipping-cart-service">
                                    Service
                                  </p>
                                  <div className="row">
                                    <div className="col-8">
                                      {item.labelInd.commercialInd === "Y" ? (
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item.product.productClassDesc +
                                              " - Commercial Base"
                                          }}
                                        />
                                      ) : (
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item.product.productClassDesc
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div className="col-4 text-right">
                                      {!item.product.productDesc ? (
                                        <p>
                                          $
                                          {item.labelCost.postageAmt
                                            ? item.labelCost.postageAmt.toFixed(
                                                2
                                              )
                                            : "0.00"}
                                        </p>
                                      ) : (
                                        <p />
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-8">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.product.productDesc
                                        }}
                                      />
                                    </div>
                                    <div className="col-4 text-right">
                                      {item.product.productDesc ? (
                                        <p>
                                          $
                                          {item.labelCost.postageAmt
                                            ? item.labelCost.postageAmt.toFixed(
                                                2
                                              )
                                            : "0.00"}
                                        </p>
                                      ) : (
                                        <p />
                                      )}
                                    </div>
                                  </div>

                                  {item.labelInd.additionalService
                                    .displayText === "NONE" ||
                                  item.labelInd.additionalService._name ===
                                    "DEL" ? (
                                    <React.Fragment />
                                  ) : (
                                    <div className="row">
                                      <div className="col-8">
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item.labelInd.additionalService
                                                .displayText
                                          }}
                                        />
                                      </div>
                                      <div className="col-4 text-right">
                                        <p>
                                          {item.labelCost.additionalServiceAmt
                                            ? "$" +
                                              item.labelCost.additionalServiceAmt.toFixed(
                                                2
                                              )
                                            : "Free"}
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  <div className="row">
                                    <div className="col-8">
                                      <p>Insurance</p>
                                    </div>
                                    <div className="col-4 text-right">
                                      <p>
                                        {item.labelCost.insuranceCostAmt > 0
                                          ? "$" +
                                            item.labelCost.insuranceCostAmt.toFixed(
                                              2
                                            )
                                          : item.labelCost.insRestrictedDelAmt >
                                            0
                                          ? "$" +
                                            item.labelCost.insRestrictedDelAmt.toFixed(
                                              2
                                            )
                                          : "Free"}
                                      </p>
                                    </div>
                                  </div>

                                  {item.deliveryAddress.countryId === 840 ? (
                                    <div className="row">
                                      <div className="col-8">
                                        <p>
                                          USPS Tracking<sup>®</sup>
                                        </p>
                                      </div>
                                      <div className="col-4 text-right">
                                        <p>Free</p>
                                      </div>
                                    </div>
                                  ) : (
                                    <React.Fragment />
                                  )}
                                  <div className="row shipping-cart-table-totals">
                                    <div className="col-8">
                                      <p>
                                        <strong>Postage Due</strong>
                                      </p>
                                    </div>
                                    <div className="col-4 text-right">
                                      <p>
                                        <strong>
                                          $
                                          {item.labelCost.ttlShipCostAmt.toFixed(
                                            2
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <React.Fragment />
                            )}
                          </React.Fragment>
                        )
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.fetchedCartList.itemsPostageDue ? (
                    <div>
                      <span className="inline-link-edit">
                        <strong className="d-none d-md-inline">
                          Postage Due for this Order Cost: $
                          {this.getPostageDuePriceTotal()}
                        </strong>
                      </span>

                      <br />
                      <br />
                      <br />
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.cartOverFlowNoCount() > 0 &&
                  this.state.displayRemaining &&
                  this.cartOverFlowNoCount() > 0 ? (
                    <h3
                      className="d-md"
                      style={{ cursor: "pointer", paddingTop: "10px" }}
                      onClick={(e) => this.displayRemainingPostageDue("hide")}
                    >
                      Hide Postage Dues After Purchase
                    </h3>
                  ) : this.cartOverFlowNoCount() > 0 ? (
                    <h3
                      className="d-md"
                      style={{ cursor: "pointer", paddingTop: "10px" }}
                      onClick={(e) => this.displayRemainingPostageDue("show")}
                    >
                      Show Postage Dues After Purchase
                    </h3>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.fetchedCartList.itemsPostageDue &&
                  this.state.displayRemaining ? (
                    <React.Fragment>
                      {this.cartOverFlowNoCount() > 0 ? (
                        <React.Fragment>
                          <h2 className="d-md" style={{ paddingTop: "10px" }}>
                            <u>
                              Remaining Postage Dues After Purchase :{" "}
                              {this.cartOverFlowNoCount()}
                            </u>
                          </h2>
                          <div
                            className="row shipping-cart-table-header"
                            style={{ paddingTop: "20px" }}
                          >
                            <div className="d-none d-md-block col-2" />
                            <div className="d-none d-md-block col-3">
                              <p>
                                <strong>Shipping Address</strong>
                              </p>
                            </div>
                            <div className="d-none d-md-block col-3">
                              <p>
                                <strong>Package Details</strong>
                              </p>
                            </div>
                            <div className="d-none d-md-block col-3">
                              <p>
                                <strong>Service</strong>
                              </p>
                            </div>
                            <div className="d-none d-md-block col-1">
                              <p>
                                <strong>Price</strong>
                              </p>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment />
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.fetchedCartList.itemsPostageDue &&
                  this.state.displayRemaining ? (
                    <React.Fragment>
                      {this.nonOverFlowPostageDueArr().map((item, keys) => (
                        <React.Fragment key={item.cnsCartId}>
                          <div className="row shipping-cart-item">
                            <div className="col-1 d-md-none order-1 mobile-shipping-cart-number">
                              <p />
                            </div>

                            <div className="col-3 col-md-2 shipping-cart-edit-delete text-right text-md-left order-3 order-md-2">
                              <p className="shipping-cart-number d-none d-md-block">
                                {" "}
                                {keys + 1} of{" "}
                                {
                                  this.state.fetchedCartList.itemsPostageDue
                                    .length
                                }
                              </p>

                              <p>
                                <a
                                  style={{ cursor: "pointer" }}
                                  className=""
                                  href="/labelDetails"
                                  onClick={(e) =>
                                    this.goToPostageDueOrder(e, item)
                                  }
                                  aria-label="Details of this postage due"
                                >
                                  <strong className="d-none d-md-inline">
                                    Details
                                  </strong>
                                </a>
                              </p>
                            </div>

                            <div className="col-8 col-md-3 order-2 order-md-3">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.deliveryAddress.displayAddress
                                }}
                              />
                            </div>
                            <div className="col-11 col-md-3 order-4 offset-1 offset-md-0">
                              <p className="d-md-none mobile-shipping-cart-package-details">
                                Package Details
                              </p>

                              {item.aesDetail !== "" ? (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.packageDetailsDisplay
                                  }}
                                />
                              ) : (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.packageDetailsDisplay
                                  }}
                                />
                              )}
                              <p>
                                {item.deliveryAddress.countryId !== 840 ? (
                                  <a
                                    style={{ cursor: "pointer" }}
                                    href="/labelDetails"
                                    className=""
                                    onClick={(e) =>
                                      this.goToPostageDueOrder(
                                        e,
                                        item.cnsCartId
                                      )
                                    }
                                    aria-label="Details of this postage due"
                                  >
                                    <strong>Details</strong>
                                  </a>
                                ) : (
                                  <React.Fragment />
                                )}
                              </p>
                            </div>
                            <div className="col-11 col-md-4 order-5 offset-1 offset-md-0 service-type-table">
                              <p className="d-md-none mobile-shipping-cart-service">
                                Service
                              </p>
                              <div className="row">
                                <div className="col-8">
                                  {item.labelInd.commercialInd === "Y" ? (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.product.productClassDesc +
                                          " - Commercial Base"
                                      }}
                                    />
                                  ) : (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.product.productClassDesc
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-4 text-right">
                                  {!item.product.productDesc ? (
                                    <p>
                                      $
                                      {item.labelCost.postageAmt
                                        ? item.labelCost.postageAmt.toFixed(2)
                                        : "0.00"}
                                    </p>
                                  ) : (
                                    <p />
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-8">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.product.productDesc
                                    }}
                                  />
                                </div>
                                <div className="col-4 text-right">
                                  {item.product.productDesc ? (
                                    <p>
                                      $
                                      {item.labelCost.postageAmt
                                        ? item.labelCost.postageAmt.toFixed(2)
                                        : "0.00"}
                                    </p>
                                  ) : (
                                    <p />
                                  )}
                                </div>
                              </div>

                              {item.labelInd.additionalService.displayText ===
                              "NONE" ? (
                                <React.Fragment />
                              ) : (
                                <div className="row">
                                  <div className="col-8">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.labelInd.additionalService
                                            .displayText
                                      }}
                                    />
                                  </div>
                                  <div className="col-4 text-right">
                                    <p>
                                      {item.labelCost.additionalServiceAmt
                                        ? "$" +
                                          item.labelCost.additionalServiceAmt.toFixed(
                                            2
                                          )
                                        : "Free"}
                                    </p>
                                  </div>
                                </div>
                              )}

                              <div className="row">
                                <div className="col-8">
                                  <p>Insurance</p>
                                </div>
                                <div className="col-4 text-right">
                                  <p>
                                    {item.labelCost.insuranceCostAmt > 0
                                      ? "$" +
                                        item.labelCost.insuranceCostAmt.toFixed(
                                          2
                                        )
                                      : item.labelCost.insRestrictedDelAmt > 0
                                      ? "$" +
                                        item.labelCost.insRestrictedDelAmt.toFixed(
                                          2
                                        )
                                      : "Free"}
                                  </p>
                                </div>
                              </div>

                              {item.deliveryAddress.countryId === 840 ? (
                                <div className="row">
                                  <div className="col-8">
                                    <p>
                                      USPS Tracking<sup>®</sup>
                                    </p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <p>Free</p>
                                  </div>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}
                              <div className="row shipping-cart-table-totals">
                                <div className="col-8">
                                  <p>
                                    <strong>Postage Due</strong>
                                  </p>
                                </div>
                                <div className="col-4 text-right">
                                  <p>
                                    <strong>
                                      $
                                      {item.labelCost.ttlShipCostAmt.toFixed(2)}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.fetchedCartList.itemsPostageDue &&
                  this.state.displayRemaining ? (
                    <div>
                      <span className="inline-link-edit">
                        <strong className="d-none d-md-inline">
                          Postage Due Amount After Purchase: $
                          {this.cartOverFlowTotal()}
                        </strong>
                      </span>

                      <br />
                      <br />
                      <br />
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.props.userData.accountType === "Business" &&
                    this.props.userData.parcelSelectFlag &&
                    Object.keys(this.state.parcelSelectGroups).length > 0 &&
                    this.state.freeSupplies &&
                    this.state.freeSupplies.length === 0 && (
                      <FreeShippingSuppliesModal
                        targetName="freeSuppliesButton"
                        toggleSpinner={this.props.toggleSpinner}
                        freeSuppliesCurrentlySaved={this.state.freeSupplies}
                        fetchCart={this.fetchCart}
                        className="free-shipping-modal"
                      />
                    )}
                  {/* {this.cartOverFlowNoCount() > 0 ? (
                    <div className="col-12 col-md-6 results-pagination-wrapper">
                      <ul className="results-pagination">
                        <li className="navigation previous ">
                          {" "}
                          <PaginationItem>
                            <a onClick={e => this.handleClick(e, currentPage - 1)} previous href="#" />
                          </PaginationItem>
                        </li>

                        {[...Array(this.pagesCount)].map((page, i) => (
                          <PaginationItem active={i === currentPage} key={i}>
                            <div onClick={e => this.handleClick(e, i)} href="#">
                              <li>
                                <a href="#"> {i + 1}</a>
                              </li>
                            </div>
                          </PaginationItem>
                        ))}
                        <li className="navigation next">
                          {" "}
                          <PaginationItem disabled={currentPage >= this.pagesCount - 1} className="navigation-text">
                            <a onClick={e => this.handleClick(e, currentPage + 1)} next href="#" />
                          </PaginationItem>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <React.Fragment />
                  )} */}
                  {/* This is for a business customer that is enrolled in loyalty from LPA*/}
                  {/*this.state.LPAUserData.userEnrolled === true &&
                  this.props.userData.OMAS === false &&
                  this.props.userData.accountType === "Business" &&
                  this.props.lookupCodes.lpa_cart_off === "false" ? (
                    <div>
                      {(this.props.lookupCodes.cart_LPA_EOL_message ===
                        "5_18" ||
                        this.props.lookupCodes.cart_LPA_EOL_message ===
                          "6_9") && (
                        <div className="row">
                          <div className="col-8">
                            <div
                              id="redirect-to-new-cns-banner-wrapper"
                              className="row container-fluid"
                              style={{
                                display: "flex",
                                background: "#ededed",
                                borderLeft: "15px solid #3573b1",
                                marginRight: "auto",
                                marginLeft: "auto",
                                marginTop: "20px",
                                fontSize: "16px",
                                width: "100%"
                              }}
                            >
                              <div
                                id="redirect-to-new-cns-banner-description-wrapper"
                                className="col-12 col-md-12 col-lg-12"
                              >
                                {!!this.state.LPAUserData?.availableCredit && (
                                  <>
                                    <p
                                      id="redirect-to-new-cns-banner-description"
                                      style={{ color: "#333366" }}
                                    >
                                      <strong>
                                        Loyalty Credits Will Expire Soon:
                                      </strong>{" "}
                                      You have until 11:59 PM ET on June 9,
                                      2024, to use any remaining loyalty
                                      credits. After you use your loyalty
                                      credits, upgrade to the enhanced
                                      Click-N-Ship<sup>&reg;</sup> experience to
                                      get lower prices on shipping labels.{" "}
                                      <br />
                                    </p>
                                    <p>
                                      <a
                                        href="https://faq.usps.com/s/article/the-USPS-Loyalty-Program"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-link"
                                      >
                                        <strong className="d-none d-md-inline-block">
                                          Learn More
                                        </strong>
                                      </a>
                                    </p>
                                  </>
                                )}
                              </div>
                              <div className="col-12 col-md-5 col-lg-4 text-center banner-button"></div>
                            </div>
                          </div>
                          <div className="col-4"></div>
                        </div>
                      )}

                      <br />
                      <div className="row">
                        <div className="col-12 col-md-7">
                          {this.state.LPAUserData.userTierName !== "Gold" ? (
                            <h2
                              style={{
                                fontWeight: "bold",
                                paddingBottom: "15px"
                              }}
                            >
                              {this.props.lookupCodes.cart_LPA_EOL_message !==
                              "6_9"
                                ? "$"
                                : ""}
                              {this.props.lookupCodes.cart_LPA_EOL_message !==
                              "6_9"
                                ? this.state.LPAUserData.rewardCreditAmount
                                : ""}{" "}
                              Click-N-Ship® Credit
                            </h2>
                          ) : (
                            <React.Fragment />
                          )}
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-10 col-md-6">
                          {this.state.LPAUserData.userRewardName !== "Gold" &&
                          this.state.LPAUserData.userTierName !== "Gold" &&
                          this.props.lookupCodes.cart_LPA_EOL_message !==
                            "6_9" ? (
                            <div id="loyaltyProgress">
                              <div
                                id="loyaltybar"
                                style={{
                                  width:
                                    (this.state.LPAUserData.availablePoints /
                                      500) *
                                      100 +
                                    "%",
                                  height: "100%"
                                }}
                              ></div>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                          <div className="row">
                            {this.state.LPAUserData.userRewardName !== "Gold" &&
                            this.state.LPAUserData.userTierName !== "Gold" &&
                            this.props.lookupCodes.cart_LPA_EOL_message !==
                              "6_9" ? (
                              <div style={{ paddingLeft: "8px" }}>
                                {Math.floor(
                                  this.state.LPAUserData.pendingPoints
                                ) > 0 ? (
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "#333366",
                                      fontSize: "10px",
                                      fontStyle: "italic"
                                    }}
                                  >
                                    {Math.floor(
                                      this.state.LPAUserData.pendingPoints
                                    ) > 0
                                      ? Math.floor(
                                          this.state.LPAUserData.pendingPoints
                                        )
                                      : 0}{" "}
                                    {Math.floor(
                                      this.state.LPAUserData.pendingPoints
                                    ) === 1
                                      ? "point"
                                      : "points"}{" "}
                                    will be available in the next 30 days
                                  </p>
                                ) : Math.floor(
                                    this.state.LPAUserData.pendingPoints
                                  ) < 0 ? (
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "#333366",
                                      fontSize: "10px",
                                      fontStyle: "italic"
                                    }}
                                  >
                                    {Math.floor(
                                      this.state.LPAUserData.pendingPoints
                                    ) < 0
                                      ? Math.floor(
                                          this.state.LPAUserData.pendingPoints
                                        )
                                      : 0}{" "}
                                    {Math.floor(
                                      this.state.LPAUserData.pendingPoints
                                    ) === -1
                                      ? "point"
                                      : "points"}{" "}
                                    will be available in the next 30 days
                                  </p>
                                ) : (
                                  <React.Fragment />
                                )}
                              </div>
                            ) : (
                              <React.Fragment />
                            )}
                            <div
                              className="row"
                              style={{
                                fontWeight: "bold",
                                marginLeft: "0px",
                                marginRight: "5px",
                                paddingTop: "15px",
                                color: "black"
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "5px"
                                }}
                              >
                                <p
                                  style={{
                                    marginLeft: "15px"
                                  }}
                                >
                                  {this.state.LPAUserData.userTierName ===
                                  "Gold"
                                    ? "As a Gold Tier Loyalty Program Member, you're rewarded with Commercial Base Pricing on Priority Mail® and Priority Mail Express® labels."
                                    : ""}
                                </p>
                              </div>

                              <div
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "50px",
                                  marginLeft: "5px"
                                }}
                              >
                                {(this.state.LPAUserData.availableCredit !==
                                  0 &&
                                  this.state.LPAUserData.userTierName !==
                                    "Gold") ||
                                (this.state.LPAUserData.availableCredit > 0 &&
                                  this.state.LPAUserData.userTierName ===
                                    "Gold") ||
                                this.props.lookupCodes.cart_LPA_EOL_message ==
                                  "6_9" ? (
                                  <>
                                    <p
                                      style={{
                                        marginLeft: "15px",
                                        opacity: "1"
                                      }}
                                    >
                                      You can use any available credits you have
                                      on eligible domestic Priority Mail® or
                                      Priority Mail Express® label purchases.
                                    </p>
                                    {this.props.lookupCodes
                                      .cart_LPA_EOL_message == "6_9" && (
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          color: "#333366",
                                          fontSize: "10px",
                                          fontStyle: "italic",
                                          paddingLeft: "15px"
                                        }}
                                      >
                                        You currently have a balance of $
                                        {this.state.LPAUserData
                                          .availableCredit !== null
                                          ? this.state.LPAUserData.availableCredit.toFixed(
                                              2
                                            )
                                          : 0.0}{" "}
                                        loyalty credits
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {this.props.lookupCodes
                                      .cart_LPA_EOL_message !== "6_9" &&
                                    this.state.LPAUserData.userTierName !==
                                      "Gold" ? (
                                      <p
                                        style={{
                                          marginLeft: "15px",
                                          opacity: 0
                                        }}
                                      >
                                        You can use any available credits you
                                        have on eligible domestic Priority Mail®
                                        or Priority Mail Express® label
                                        purchases.
                                      </p>
                                    ) : (
                                      <>
                                        <p
                                          style={{
                                            marginLeft: "15px",
                                            opacity: 1
                                          }}
                                        >
                                          You can use any available credits you
                                          have on eligible domestic Priority
                                          Mail® or Priority Mail Express® label
                                          purchases.
                                        </p>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-2 col-md-2">
                          {this.state.LPAUserData.userRewardName !== "Gold" &&
                          this.state.LPAUserData.userTierName !== "Gold" &&
                          this.props.lookupCodes.cart_LPA_EOL_message !==
                            "6_9" ? (
                            <div className="row" style={{ fontWeight: "bold" }}>
                              <p
                                className="text-center align-middle"
                                style={{ fontWeight: "bold", color: "#333366" }}
                              >
                                {this.state.LPAUserData.availablePoints !== null
                                  ? Math.floor(
                                      this.state.LPAUserData.availablePoints
                                    )
                                  : 0}{" "}
                                / 500 points{" "}
                                <PendingAndEarnedPoints
                                  targetName={"pendingAndEarnedPoints"}
                                />
                              </p>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                        </div>

                        <div className="col-12 col-md-4">
                          <div
                            className="row justify-content-md-end justify-content-center"
                            style={{ fontWeight: "" }}
                          >
                            <div className="col-12">
                              {(this.state.LPAUserData.availableCredit !== 0 &&
                                this.state.LPAUserData.userTierName !==
                                  "Gold") ||
                              (this.state.LPAUserData.availableCredit > 0 &&
                                this.state.LPAUserData.userTierName ===
                                  "Gold") ? (
                                <div
                                  className="row justify-content-md-end justify-content-center"
                                  style={{ fontWeight: "bold" }}
                                >
                                  <p
                                    className="text-center"
                                    style={{
                                      fontWeight: "bold",
                                      color: "#333366",
                                      fontSize: "20px"
                                    }}
                                  >
                                    $
                                    {this.state.LPAUserData.availableCredit !==
                                    null
                                      ? this.state.LPAUserData.availableCredit.toFixed(
                                          2
                                        )
                                      : 0.0}
                                  </p>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}
                              {(this.state.LPAUserData.availableCredit !== 0 &&
                                this.state.LPAUserData.userTierName !==
                                  "Gold") ||
                              (this.state.LPAUserData.availableCredit > 0 &&
                                this.state.LPAUserData.userTierName ===
                                  "Gold") ? (
                                <div
                                  className="row justify-content-md-end justify-content-center"
                                  style={{ fontWeight: "bold" }}
                                >
                                  <p
                                    className=""
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "black"
                                    }}
                                  >
                                    Available Credit
                                  </p>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}

                              {this.state.enteredLoyaltyAmount > 0 ? (
                                <div
                                  className="row justify-content-md-end justify-content-center"
                                  style={{ fontWeight: "" }}
                                >
                                  <p
                                    className=""
                                    style={{
                                      fontWeight: "",
                                      color: "#333366"
                                    }}
                                  >
                                    Loyalty Credits Applied: $
                                    {Number(
                                      this.state.enteredLoyaltyAmount
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}

                              {this.state.LPAUserData.availableCredit > 0 &&
                              this.state.LPAUserData.availableCredit !==
                                null ? (
                                <div>
                                  {this.state.fetchedCartList.items.length >
                                  0 ? (
                                    <div
                                      className="row justify-content-md-end justify-content-center"
                                      style={{ fontWeight: "" }}
                                    >
                                      {this.state.eligibleLoyaltyCredits > 0 ? (
                                        <p
                                          className=""
                                          style={{
                                            fontWeight: "",
                                            color: "#333366",
                                            paddingTop: "0px"
                                          }}
                                        >
                                          Eligible Loyalty Credits: $
                                          {this.state.eligibleLoyaltyCredits >
                                            0 &&
                                          this.state.eligibleLoyaltyCredits <=
                                            this.state.LPAUserData
                                              .availableCredit
                                            ? this.state.eligibleLoyaltyCredits.toFixed(
                                                2
                                              )
                                            : this.state
                                                .eligibleLoyaltyCredits > 0 &&
                                              this.state
                                                .eligibleLoyaltyCredits >
                                                this.state.LPAUserData
                                                  .availableCredit
                                            ? this.state.LPAUserData.availableCredit.toFixed(
                                                2
                                              )
                                            : 0}
                                        </p>
                                      ) : (
                                        <p
                                          className="text-right"
                                          style={{
                                            fontWeight: "",
                                            color: "red",
                                            paddingTop: "0px"
                                          }}
                                        >
                                          Sorry, no items in your cart are
                                          eligible for loyalty credit
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      className="row justify-content-md-end justify-content-center"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      <div
                                        className="col-md-8 justify-content-md-end justify-content-center"
                                        style={{
                                          fontWeight: "bold",
                                          paddingRight: "0px"
                                        }}
                                      >
                                        <p
                                          className="text-right"
                                          style={{
                                            fontWeight: "bold",
                                            paddingRight: "0px"
                                          }}
                                        >
                                          Add eligible items to your cart to
                                          apply available credit.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {this.state.fetchedCartList.items.length >
                                    0 &&
                                  this.state.eligibleLoyaltyCredits > 0 ? (
                                    <div
                                      className="row justify-content-md-end justify-content-center"
                                      style={{
                                        fontWeight: "bold",
                                        paddingTop: "15px"
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          paddingRight: "0px"
                                        }}
                                      >
                                        {this.state.fetchedCartList
                                          .itemsPostageDue ? (
                                          this.state.fetchedCartList
                                            .itemsPostageDue.length < 20 &&
                                          this.state.arrOfCartIds.length > 0 ? (
                                            <div
                                              className="justify-content-md-end justify-content-center text-right"
                                              style={{ paddingTop: "20px" }}
                                            >
                                              <EnterLPACreditModal
                                                buttonLabel={"Apply Credit"}
                                                size="lg"
                                                userAppliedCredit={
                                                  this.userAppliedCredit
                                                }
                                                lpaUserData={
                                                  this.state.LPAUserData
                                                }
                                                eligibleLoyaltyCredits={
                                                  this.state
                                                    .eligibleLoyaltyCredits
                                                }
                                                windowWidth={
                                                  this.state.windowWidth
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )
                                        ) : (
                                          <div
                                            className="justify-content-md-end justify-content-center text-right"
                                            style={{
                                              paddingTop: "20px",
                                              paddingBottom: "20px"
                                            }}
                                          >
                                            <EnterLPACreditModal
                                              buttonLabel={"Apply Credit"}
                                              size="lg"
                                              userAppliedCredit={
                                                this.userAppliedCredit
                                              }
                                              lpaUserData={
                                                this.state.LPAUserData
                                              }
                                              eligibleLoyaltyCredits={
                                                this.state
                                                  .eligibleLoyaltyCredits
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <React.Fragment />
                                  )}
                                </div>
                              ) : (
                                <React.Fragment />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : this.state.LPAUserData.userEnrolled === false &&
                    this.props.userData.OMAS === false &&
                    this.props.userData.accountType === "Business" &&
                    this.props.userData.isEnrolledInLoyalty === false ? (
                    <div>
                      <div className="row">
                        <div className="col-12 col-md-7">
                          <h2
                            style={{
                              fontWeight: "bold",
                              paddingBottom: "15px"
                            }}
                          >
                            USPS® Loyalty Program
                          </h2>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-10 col-md-12">
                          <div className="">
                            <div
                              className="row"
                              style={{
                                fontWeight: "bold",
                                marginRight: "5px"
                              }}
                            >
                              <div
                                className="col-12 col-md-8"
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "5px"
                                }}
                              >
                                <p>
                                  As a valued business customer, you can now
                                  earn points for discounts on eligible
                                  Click-N-Ship® purchases. Once you complete
                                  this transaction, you will automatically be
                                  enrolled in our Base Loyalty Program. Here’s
                                  how it works:
                                </p>
                              </div>
                            </div>

                            <div
                              className="row"
                              style={{
                                paddingTop: "10px",
                                marginRight: "5px",
                                marginBottom: "15px"
                              }}
                            >
                              <div
                                className="col-12"
                                style={{
                                  paddingBottom: "50px",
                                  marginLeft: "55px",
                                  fontSize: "12px"
                                }}
                              >
                                <ul>
                                  <li>
                                    Earn points on domestic Priority Mail® or
                                    Priority Mail Express® shipping labels you
                                    purchase.
                                  </li>
                                  <li>
                                    For every 500 points you earn, you will
                                    recieve dollar credit to save money on
                                    future Priority Mail or Priority Mail
                                    Express labels.
                                  </li>
                                  <li>
                                    Apply your dollar credit to postage cost
                                    only; insurance and extra services fees are
                                    not eligible.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment />
                  )*/} 
                  <div className="row shipping-cart-table-footer">
                    <div className="col-3 col-md-6">
                      {this.state.fetchedCartList.items.length > 0 ? (
                        <p>
                          <DeleteAllCartLabels
                            buttonLabel={"Delete All"}
                            deleteCartLabel={this.deleteCartLabel}
                          />
                        </p>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                    <div className="col-9 col-md-6 text-right">
                      {this.state.fetchedCartList.items.length === 0 &&
                      !this.state.fetchedCartList.itemsPostageDue ? (
                        <p className="shipping-cart-table-order-total">
                          Order Total: <strong>$0.00</strong>
                        </p>
                      ) : this.state.fetchedCartList.itemsPostageDue &&
                        this.state.fetchedCartList.items.length === 0 ? (
                        <p className="shipping-cart-table-order-total">
                          Order Total:{" "}
                          <strong>
                            ${Number(this.getPostageDuePriceTotal()).toFixed(2)}
                          </strong>
                        </p>
                      ) : (
                        <p className="shipping-cart-table-order-total">
                          Order Total:{" "}
                          <strong>
                            $
                            {this.state.fetchedCartList.itemsPostageDue
                              ? Number(
                                  this.getPostageDueAndSelectedLabelCost()
                                ).toFixed(2)
                              : this.getPriceTotal()}
                          </strong>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 button-wrapper">
                      {this.state.fetchedCartList.items.length > 0 ||
                      this.state.fetchedCartList.itemsPostageDue ? (
                        <div className="button-container">
                          <a
                            href="https://pay.usps.com/"
                            role="button"
                            className="btn-primary"
                            tabIndex="0"
                            onClick={
                              this.state.fetchedCartList.itemsPostageDue
                                ? (e) => this.goToBillingPostageDue(e)
                                : (e) => this.goToBilling(e)
                            }
                          >
                            Billing Information
                          </a>
                        </div>
                      ) : (
                        <React.Fragment />
                      )}
                      <div className="button-container">
                        <a
                          href="/labelInformation"
                          role="button"
                          className="btn-primary button--white"
                          tabIndex="0"
                          onClick={(e) => this.createNewLabel(e)}
                        >
                          Create Another Label
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ShippingCart);
