import React from "react";

const PostOfficeInfo = (props) => {
  const { facility, isDesktopAndTableFormat, isTableFormat } = props;

  return (
    <React.Fragment>
      <div
        className={
          "postOfficeBorder" + (!isTableFormat ? " postOfficeBorderClear" : "")
        }
      >
        <div
          className={
            "row" +
            (!isDesktopAndTableFormat ? " popupSmallScreenInfoWrapper" : "")
          }
        >
          <div
            className={
              isDesktopAndTableFormat
                ? "col-5"
                : "row popupSmallScreenLocationInfo"
            }
          >
            {facility.dropOffLocationGeoDataDistance > -1 && (
              <div className="row">
                <p className="col-12">
                  {Math.round(facility.dropOffLocationGeoDataDistance * 10) /
                    10}{" "}
                  mile
                  {Math.round(facility.dropOffLocationGeoDataDistance * 10) /
                    10 !==
                  1
                    ? "s"
                    : ""}{" "}
                  away
                </p>
              </div>
            )}
            <div className="row">
              <p className="col-12 connnectMapFacilityName">
                <strong>
                  {facility.locationName +
                    (facility.dropOffLocationFacilityType === "POST_OFF"
                      ? " - Post Office™"
                      : "")}
                </strong>
              </p>
            </div>
            <div className="row">
              <p className="col-12">
                {facility.dropOffLocationAddress2 +
                  " " +
                  facility.dropOffLocationCity +
                  ", " +
                  facility.dropOffLocationState +
                  " " +
                  facility.dropOffLocationZip5 +
                  (facility.dropOffLocationZip4
                    ? "-" + facility.dropOffLocationZip4
                    : "")}
              </p>
            </div>
          </div>
          <div
            className={
              isDesktopAndTableFormat
                ? "col-7"
                : "row popupSmallScreenLocationHoursWrapper"
            }
          >
            <p className="row">
              <strong>Drop-Off Hours</strong>
            </p>
            <div className="row popupSmallScreenLocationHours">
              {facility.formattedDayHourGroups &&
                facility.formattedDayHourGroups.map((group) => (
                  <React.Fragment key={group.days}>
                    <div className="col-4 postOfficeHoursPadding">
                      <p className="row">{group.days}</p>
                      {group.times &&
                        group.times.map((time) => (
                          <p key={time} className="row pPadding">
                            {time}
                          </p>
                        ))}
                    </div>
                  </React.Fragment>
                ))}
            </div>
          </div>
          <p className="col-12 textParking">
            <strong>
              {facility.dropOffLocationParking === "LOT"
                ? "Lot Parking Available"
                : facility.dropOffLocationParking === "STREET"
                ? "Street Parking Available"
                : ""}
            </strong>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PostOfficeInfo;
