import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactGA from "react-ga";

ReactGA.initialize("GTM-MVCC8H");

ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <div>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </div>,
  document.getElementById("root")
);
