import React from "react";
import "./App.css";

import axios from "axios";
import "./click-n-ship.css";
import "./bootstrap.min.css";

import "./calendar.css";
import "./default-styles.css";
import "./spinner.css";
import update from "immutability-helper";
import CnsNavBar from "./Components/CnsNavBar";
import RedirectComponent from "./Components/RedirectComponent";
import hidePostagePriceUtils from "./utils/hidePostagePrice";

import backToTopIcon from "./click-n-ship/images/backtop.png";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import GlobalElementsModal from "./Components/GlobalElements/GlobalElementsModal";
// import GlobalElementsGreyHeader from "./Components/GlobalElements/GlobalElementsGreyHeader";
// import GlobalHeader from "./Components/GlobalElements/GlobalHeader";
// import GlobalFooter from "./Components/GlobalElements/GlobalFooter";
import CreateLabel from "./Components/CreateLabel/CreateLabel";
import ShippingCart from "./Components/ShippingCart/ShippingCart";
import CustomsDeclaration from "./Components/CustomsDeclaration/CustomsDeclaration";
import PaymentConfirmation from "./Components/PaymentConfirmation/PaymentConfirmation";
import Preferences from "./Components/Preferences/Preferences";
import ScanForms from "./Components/ScanForms/ScanForms";
import GXGParticipatingLocations from "./Components/GXGParticipatingLocations/GXGParticipatingLocations";
import GXGCustomsInformation from "./Components/GXGCustomsInformation/GXGCustomsInformation";
import GXGCommerceInformation from "./Components/GXGCommerceInformation/GXGCommerceInformation";
import GXGDateAndFinalPrice from "./Components/GXGDateAndFinalPrice/GXGDateAndFinalPrice";
import WhiteSpinner from "./Components/Modals/WhiteSpinner";
import ShippingHistory from "./Components/ShippingHistory/ShippingHistory";
import LabelDetails from "./Components/LabelDetails/LabelDetails";
import Build from "./Components/Build/Build";
import PostageDueModal from "./Components/Modals/PostageDueModal";
// import LPABusinessModal from "./Components/Modals/LPABusinessModal";
//import CNSSunsetModal from "./Components/Modals/CNSSunsetModal.js";
import CNSExtensionModal from "./Components/Modals/CNSExtensionModal.js";
import TimeOutModal from "./Components/Modals/TimeOutModal";
import * as analytics from "./Components/Analytics/Analytics.js";
import UspsConnect from "./Components/UspsConnect/UspsConnect";
import ConnectLocalSignup from "./Components/Modals/ConnectLocalSignup";
import AppSelectSplashScreen from "./Components/AppSelectSplashScreen/AppSelectSplashScreen";
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      regUrl: false,
      userData: {},
      gotCartCount: false,
      showSpinner: false,
      gotLookupCodes: false,
      test: false,
      togglePSBannerExpand: true,
      showUnenrolledParcelSelectBanner: null,
      errors: {
        globalError: []
      },
      showTimeout: false,
      editCartId: 0,
      successSignup: false,
      userPreferences: {}
    };

    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.refreshAuth = this.refreshAuth.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
    this.setTimeout();

    this.oldUrls = [
      "/labelInformation.shtml",
      "/preferences.shtml",
      "/shippingHistory.shtml",
      "/shippingCart.shtml",
      "/labelDetails.shtml",
      "/checkout-complete.shtml",
      "/scanFormList.shtml",
      "/customsInformation.shtml",
      "/gxgLocationsDestinations.shtml",
      "/customsInformationGxg.shtml",
      "/gxgCommerceInformation.shtml",
      "/gxgGuaranteedDate.shtml",
      "/build.shtml",
      "/build.html"
    ];
  }

  // Clear the timeouts that control when to warn and log the user out
  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    if (this.refreshAuthTimeout) clearTimeout(this.refreshAuthTimeout);
  }

  // Set the timeouts that control when to warn and log the user out
  setTimeout() {
    this.warnTimeout = setTimeout(this.warn, 700000);
    this.logoutTimeout = setTimeout(this.logout, 900000);
    this.refreshAuthTimeout = setTimeout(this.refreshAuth, 600000);
  }

  refreshAuth() {
    if (this.state.refreshedAuthRecently) {
      this.resetTimeout();
    }

    axios
      .get("/go/cnsrest/security")
      .then((response) => {
        this.setState({
          refreshedAuthRecently: !this.state.refreshedAuthRecently
        });
      })
      .catch((error) => {});
  }

  // Called on page load and for mouse and keyboard events
  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  // Show the timeout modal when a user is about to be logged out
  warn() {
    this.setState({ showTimeout: true });
  }

  // Send a logout request to the API to log the user out (due to timeout)
  logout() {
    console.log("Sending a logout request to the API...");

    this.toggleSpinner(true);
    axios
      .get("/go/cnsrest/signOut")
      .then((response) => {
        window.location.href = response.data;

        if (
          this.state.gotCartCount === true &&
          this.state.regUrl === true &&
          this.state.gotLookupCodes === true
        ) {
          this.toggleSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        this.toggleSpinner(false);
      });
    // this.destroy(); // Cleanup
  }

  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  // Hides the timeout modal
  resetTimeOutModal = () => {
    this.setState({ showTimeout: false });
  };

  // Log the user out (from the timeout modal)
  logUserOut = () => {
    axios
      .get("/go/cnsrest/signOut")
      .then((response) => {
        window.location.href = response.data;
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  setEditCartId = (cartId) => {
    this.setState({ editCartId: cartId });
  };

  /////////////////////////////////////////////////////////////////////////////////////
  // * https://codepen.io/gapcode/pen/vEJNZN
  // * detect IE
  // * returns version of IE or false, if browser is not Internet Explorer
  ////////////////////////////////////////////////////////////////////////////////////
  detectIE = () => {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result �

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    //try this as default? May not work in Edge
    //!(window.ActiveXObject) && "ActiveXObject" in window

    // other browser
    return false;
  };

  componentDidMount() {
    this.toggleSpinner(true);
    Promise.all([
      axios.get("/go/cnsrest/security"),
      axios.get("/go/cnsrest/cartSummary"),
      axios.get("/go/cnsrest/config/lookupCodes"),
      axios.get("/go/cnsrest/fetchLPAInfo"),
      axios.get("/go/cnsrest/configLabelInfo")
    ])
      .then((response) => {
        // Security API call
        if (response[0].data.regUrl) {
          //For some reason in IE this gets stuck in an loop. It
          //looks like the promise returns the regurl
          //which returns the user to eReg but they are already logged in
          //so eReg send us back to CNS which sends us back to eReg ....
          if (this.detectIE() === false) {
            window.location.href =
              response[0].data.regUrl +
              "?app=GSS&appURL=" +
              window.location.href;
          } else {
            if (!(document.cookie.indexOf("EntRegName") > -1)) {
              window.location.href =
                response[0].data.regUrl +
                "?app=GSS&appURL=" +
                window.location.href;
            } else {
              window.location.reload();
            }
          }
        } else {
          this.updateScreenPosition();
          window.addEventListener("scroll", this.updateScreenPosition);
          this.setState({
            regUrl: true,
            userData: response[0].data.userData
          });
          analytics.userInformation(response[0].data.userData);
        }
        // Redirect to CNSv2 if conditions are met
        if (this.redirectToCnsv2(response[0].data.userData, response[2].data)) {
          window.location.replace(response[2].data["cnsb_landing_page"]);
        }
        // Check for outstanding postage dues
        for (let item in response[1].data.cartSummaryViewBean.items) {
          if (
            response[1].data.cartSummaryViewBean.items[item].postageDue === true
          ) {
            this.setState({
              test: true
            });
            break;
          }
        }
        // LPA API call
        let parsedLPApoints;
        try {
          parsedLPApoints = JSON.parse(response[3].data.LPApoints);
        } catch (e) {
          parsedLPApoints = {
            availableCredit: null,
            availablePoints: null,
            pendingPoints: null,
            userEnrolled: false,
            userRewardName: "",
            userSuspended: false
          };
        }

        this.setState({
          // Cart summary API call
          gotCartCount: true,
          cartCount: response[1].data.cartSummaryViewBean.cartCount,
          // Lookup codes API call
          gotLookupCodes: true,
          lookupCodes: response[2].data,
          loyaltyUserInfo: parsedLPApoints
        });
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        this.toggleSpinner(false);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateScreenPosition);
  }

  updateScreenPosition = () => {
    this.setState({
      showBackToTop: window.scrollY > 0
    });
  };

  updateCartSize = (e) => {
    axios
      .get("/go/cnsrest/cartSummary")
      .then((response) => {
        this.setState({
          cartCount: response.data.cartSummaryViewBean.cartCount
        });
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  toggleSpinner = (bool) => {
    this.setState({
      showSpinner: bool
    });
  };

  toggleParcelSelectBanner = () => {
    this.setState((prevState) => {
      return {
        togglePSBannerExpand: !prevState.togglePSBannerExpand
      };
    });
  };

  // Only show the parcel select banners on the landing page
  showParcelSelectBanner = () => {
    const parcelSelectBannerUrls = [
      "/",
      "/index",
      "/index.shtml",
      "/labelInformation"
    ];
    return parcelSelectBannerUrls.includes(window.location.pathname);
  };

  // Only show the unenrolled USPS Connect banner if the customer is a business
  // user who is not already enrolled in USPS Connect and has a CustReg or
  // preferred return address within a set radius of a drop-off location
  setShowUnenrolledParcelSelectBanner = (returnAddress) => {
    if (
      this.state.userData.accountType === "Business" &&
      !this.state.userData.parcelSelectFlag &&
      this.state.lookupCodes["usps_connect_banner_on"] === "true" &&
      returnAddress
    ) {
      axios
        .post(
          "/go/cnsrest/fetchDropOffLocations",
          JSON.stringify({
            returnLine1Addr: returnAddress.line1Addr || "",
            returnLine2Addr: returnAddress.line2Addr || "",
            returnCity: returnAddress.cityName || "",
            returnStateId: returnAddress.stateId || 0,
            returnZip5:
              (returnAddress.postalCode &&
                returnAddress.postalCode.substring(0, 5)) ||
              "",
            returnZipPlus4:
              (returnAddress.postalCode &&
                returnAddress.postalCode.substring(6)) ||
              "",
            radius: "30",
            mapSource: "preferences"
          }),
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then((resDropoff) => {
          if (
            resDropoff &&
            resDropoff.data &&
            resDropoff.data.dropoffLocationData &&
            resDropoff.data.dropoffLocationData.successful &&
            resDropoff.data.dropoffLocationData.locationList &&
            Array.isArray(resDropoff.data.dropoffLocationData.locationList) &&
            resDropoff.data.dropoffLocationData.locationList.length > 0
          ) {
            // Check for outstanding postage dues
            // LPA API call

            this.setState({
              showUnenrolledParcelSelectBanner: true
            });
          } else {
            this.setState({
              showUnenrolledParcelSelectBanner: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            showUnenrolledParcelSelectBanner: false
          });
        });
    } else {
      this.setState({
        showUnenrolledParcelSelectBanner: false
      });
    }
  };

  // The Connect Local self-enrollment banner should be displayed if all
  // the following are met:
  // - the user is a business user who is not already enrolled in USPS Connect
  //   and has a CustReg or preferred return address within a set radius of a
  //   drop-off location
  showConnectLocalSelfEnrollmentBanner = () => {
    return this.state.showUnenrolledParcelSelectBanner;
  };

  // Display a placeholder space where the Connect Local self-enrollment banner
  // might be displayed if all the following are met:
  // - the user is a business user
  // - the user is not already enrolled in USPS Connect
  // - the lookup code allowing this banner is active
  // - the drop-off locations call has not returned yet
  showConnectLocalSelfEnrollmentPlaceholder = () => {
    return (
      this.state.userData.accountType === "Business" &&
      !this.state.userData.parcelSelectFlag &&
      this.state.lookupCodes["usps_connect_banner_on"] === "true" &&
      this.state.showUnenrolledParcelSelectBanner === null
    );
  };

  // CNSv2 should be promoted if either of the following are true:
  // - the lookup code is true and the customer is a business user
  // - the lookup code is false and the user has a special flag from eReg
  promoteCnsv2 = () => {
    if (
      this.state.lookupCodes["cnsb_banner_to_business_users"] &&
      this.state.lookupCodes["cnsb_banner_to_business_users"].toUpperCase() ===
        "TRUE"
    ) {
      return (
        this.state.userData.accountType &&
        this.state.userData.accountType.toUpperCase() === "BUSINESS"
      );
    } else {
      return this.state.userData.accountType.toUpperCase() === "BUSINESS";
    }
  };

  lastLPABusiness = () => {
    if (
      this.state.lookupCodes["last_LPA_business"] &&
      this.state.lookupCodes["last_LPA_business"].toUpperCase() === "TRUE"
    ) {
      return (
        this.state.userData.accountType &&
        this.state.userData.accountType.toUpperCase() === "BUSINESS"
      );
    } else {
      return false;
    }
  };

  lastLPAPersonal = () => {
    if (
      this.state.lookupCodes["last_LPA_personal"] &&
      this.state.lookupCodes["last_LPA_personal"].toUpperCase() === "TRUE"
    ) {
      return true;
    } else {
      return false;
    }
  };

  showHolidaySurchargeBanner = () => {
    if (this.state.userData.accountType) {
      return false;
    } else {
      return false;
    }
  };

  showNewV2ServiceBanner = () => {
    if (
      this.state.userData.accountType &&
      this.state.userData.accountType.toUpperCase() === "PERSONAL" &&
      this.state.lookupCodes["cnsp_label_banner"] &&
      this.state.lookupCodes["cnsp_label_banner"].toUpperCase() === "TRUE"
    ) {
      return this.state.userData.accountType.toUpperCase() === "PERSONAL";
    } else {
      return false;
    }
  };

  // Redirect to CNSv2 if the customer is a business user with
  // an account start date on or after the date specified by the lookup code
  redirectToCnsv2 = (userData, lookupCodes) => {
    if (
      userData.accountType &&
      userData.accountType.toUpperCase() === "BUSINESS" &&
      userData.accountStartDate &&
      lookupCodes
    ) {
      // Use date-time form without time zone offset to imply local time
      // (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format)
      // (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#using_date.parse)
      const timeToRedirect = Date.parse(
        lookupCodes["cnsb_date_to_redirect"] + "T00:00:00"
      );
      const timeOfAccountStart = Date.parse(
        userData.accountStartDate + "T00:00:00"
      );
      // If the lookup code is improperly formatted, timeToRedirect will be
      // parsed as NaN, and timeOfAccountStart >= NaN will be false
      return timeOfAccountStart >= timeToRedirect;
    } // If the account is a Personal account and the creation date matches the
    // lookup code redirect to CNSV2
    else if (
      userData.accountType &&
      userData.accountType.toUpperCase() === "PERSONAL" &&
      userData.accountStartDate &&
      lookupCodes
    ) {
      // Use date-time form without time zone offset to imply local time
      // (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format)
      // (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#using_date.parse)
      const timeToRedirect = Date.parse(
        lookupCodes["cnsp_date_to_redirect"] + "T00:00:00"
      );
      const timeOfAccountStart = Date.parse(
        userData.accountStartDate + "T00:00:00"
      );
      // If the lookup code is improperly formatted, timeToRedirect will be
      // parsed as NaN, and timeOfAccountStart >= NaN will be false
      return timeOfAccountStart >= timeToRedirect;
    } else {
      return false;
    }
  };

  // The hide postage price notification banner should be displayed if all
  // the following are met:
  // - the user has not added a new label to the cart after this banner first
  //   went to production (i.e. stealthPostageInd !== "N")
  // - at least one of the service types eligible for the user
  //   allows hiding the postage price based on lookup codes
  //   (no need to advertise the hide postage price feature if all service types
  //   are disabled)
  showHidePostagePriceBanner = () => {
    return (
      this.state.userPreferences &&
      this.state.userPreferences.stealthPostageInd !== "N" &&
      hidePostagePriceUtils.areLookupCodesActive(
        this.state.userData,
        this.state.lookupCodes
      )
    );
  };

  setUserPreferences = (userPreferences) => {
    this.setState({
      userPreferences: userPreferences
    });
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            [field]: errorMessage
          }
        };
      });
    }
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  refreshUserInfo = () => {
    this.toggleSpinner(true);
    axios
      .get("/go/cnsrest/security")
      .then((response) => {
        this.setState({
          successSignup: true,
          userData: response.data.userData,
          checkForDropOffLocationsNow: true
        });
      })
      .finally(() => {
        this.toggleSpinner(false);
      });
  };

  setCheckForDropOffLocationsNow = (value) => {
    this.setState({
      checkForDropOffLocationsNow: value
    });
  };

  render() {
    return (
      <React.Fragment>
        <Router>
          {this.state.regUrl &&
          this.state.gotCartCount &&
          this.state.gotLookupCodes ? (
            <div>
              {/* Alert messaging */}
              {this.state.lookupCodes &&
              this.state.lookupCodes["cns.message.desktop"] &&
              window.location.pathname !== "/" &&
              window.location.pathname !== "index" &&
              window.location.pathname !== "index.shtml" ? (
                <div id="cns-message-wrap">
                  <div id="cns-message">
                    <b
                      dangerouslySetInnerHTML={{
                        __html: this.state.lookupCodes["cns.message.desktop"]
                      }}
                    ></b>
                  </div>
                </div>
              ) : null}
              {/* USPS Connect Local messaging (if already enrolled) */}
              {this.state.userData.accountType === "Business" &&
                this.showParcelSelectBanner() &&
                this.state.userData.parcelSelectFlag &&
                this.state.successSignup === false && (
                  <div className="container-fluid">
                    <div
                      id="bannerWrapper"
                      style={{ background: "#f7f7f7" }}
                      className="row"
                    >
                      <div id="psbanner" className="col-4">
                        <div id="psbannerbluetext">
                          <span style={{ fontSize: "17px", fontWeight: "700" }}>
                            USPS Connect™ Local
                          </span>
                          <br />
                          <span
                            id="sameOrNextDay"
                            style={{ fontSize: "16px", fontWeight: "700" }}
                          >
                            {" "}
                            Same-Day & Next-Day Shipping
                          </span>
                        </div>
                      </div>
                      <div
                        id="psBannerTextWrapper"
                        style={{ background: "#f7f7f7" }}
                        className={
                          this.state.userData.parcelSelectFlag
                            ? "col-8"
                            : "col-7"
                        }
                      >
                        <p id="psbannerText">
                          Bring packages to eligible drop-off locations for same
                          or next-day delivery using USPS Connect Local.
                          <br /> To create a USPS Connect Local label, select
                          this option from the Service dropdown menu below.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              <TimeOutModal
                test={this.state.showTimeout}
                resetTimeOutModal={this.resetTimeOutModal}
                logUserOut={this.logUserOut}
              />
              <PostageDueModal test={this.state.test} />

              {/* {this.state.userData.accountType === "BUSINESS" &&
                this.state.userPreferences.LPANotice === "N" && (
                  <LPABusinessModal lookupCodes={this.state.lookupCodes} />
                )} */}
              {/* {this.state.userData.accountType === "Business" &&
              this.state.userPreferences.lpaNotice === "N" &&
              this.state.lookupCodes.lpa_notice_off === "false" ? (
                <LPABusinessModal
                  lookupCodes={this.state.lookupCodes}
                  loyaltyUserInfo={this.state.loyaltyUserInfo}
                />
              ) : (
                <></>
              )} */}
              {(this.state.userPreferences.lpaNotice === "N" ||
                !this.state.userPreferences.hasOwnProperty("lpaNotice")) &&
              window.location.pathname === "/labelInformation" &&
              this.state.lookupCodes.lpa_notice_off === "false" ? (
                <CNSExtensionModal lookupCodes={this.state.lookupCodes} />
              ) : (
                <></>
              )}
              {window.location.pathname !== "/" &&
                window.location.pathname !== "index" &&
                window.location.pathname !== "index.shtml" && (
                  <CnsNavBar
                    manifestCount={this.state.userData.manifestCount}
                    cartCount={this.state.cartCount}
                    userData={this.state.userData}
                  />
                )}
              <Switch>
                <Route
                  exact
                  path={["/", "/index", "/index.shtml"]}
                  render={(self) => (
                    <AppSelectSplashScreen
                      {...self}
                      userData={this.state.userData}
                      toggleSpinner={this.toggleSpinner}
                      lookupCodes={this.state.lookupCodes}
                      cartCount={this.state.cartCount}
                      pageTitle={"USPS.com® - Create Shipping Labels"}
                      userPreferences={this.state.userPreferences}
                    />
                  )}
                />
                <Route
                  exact
                  path={["/labelInformation"]}
                  render={(self) => (
                    <CreateLabel
                      {...self}
                      userData={this.state.userData}
                      updateCartSize={this.updateCartSize}
                      toggleSpinner={this.toggleSpinner}
                      editCartId={this.state.editCartId}
                      lookupCodes={this.state.lookupCodes}
                      cartCount={this.state.cartCount}
                      pageTitle={"USPS.com® - Create Shipping Labels"}
                      //loyaltyUserInfo={this.state.loyaltyUserInfo}
                      setShowUnenrolledParcelSelectBanner={
                        this.setShowUnenrolledParcelSelectBanner
                      }
                      checkForDropOffLocationsNow={
                        this.state.checkForDropOffLocationsNow
                      }
                      setCheckForDropOffLocationsNow={
                        this.setCheckForDropOffLocationsNow
                      }
                      userPreferences={this.state.userPreferences}
                      setUserPreferences={this.setUserPreferences}
                      // For banner carousel
                      showConnectLocalSelfEnrollmentBanner={this.showConnectLocalSelfEnrollmentBanner()}
                      showConnectLocalSelfEnrollmentPlaceholder={this.showConnectLocalSelfEnrollmentPlaceholder()}
                      successSignup={this.state.successSignup}
                      refreshUserInfo={this.refreshUserInfo}
                      showRedirectToNewCnsBanner={this.promoteCnsv2()}
                      showHidePostagePriceBanner={this.showHidePostagePriceBanner()}
                      showHolidaySurchargeBanner={this.showHolidaySurchargeBanner()}
                      showNewV2ServiceBanner={this.showNewV2ServiceBanner()}
                      showlastLPAPersonal={this.lastLPAPersonal()}
                      showlastLPABusiness={this.lastLPABusiness()}
                    />
                  )}
                />
                <Route
                  path="/preferences"
                  render={(self) => (
                    <Preferences
                      {...self}
                      toggleSpinner={this.toggleSpinner}
                      userData={this.state.userData}
                      lookupCodes={this.state.lookupCodes}
                      pageTitle={"USPS.com® - Preferences"}
                    />
                  )}
                />
                <Route
                  path="/shippingHistory"
                  render={(self) => (
                    <ShippingHistory
                      {...self}
                      toggleSpinner={this.toggleSpinner}
                      updateCartSize={this.updateCartSize}
                      lookupCodes={this.state.lookupCodes}
                      shouldPromoteCnsv2={this.promoteCnsv2()}
                      pageTitle={"USPS.com® - Shipping History"}
                    />
                  )}
                />
                {this.state.userData.accountType === "Business" &&
                  this.state.userData.parcelSelectFlag && (
                    <Route
                      path="/uspsConnect"
                      render={(self) => (
                        <UspsConnect
                          {...self}
                          toggleSpinner={this.toggleSpinner}
                          lookupCodes={this.state.lookupCodes}
                          pageTitle={"USPS.com® - USPS Connect"}
                        />
                      )}
                    />
                  )}
                <Route
                  path="/shippingCart"
                  render={(self) => (
                    <ShippingCart
                      {...self}
                      updateCartSize={this.updateCartSize}
                      toggleSpinner={this.toggleSpinner}
                      setEditCartId={this.setEditCartId}
                      userData={this.state.userData}
                      //loyaltyUserInfo={this.state.loyaltyUserInfo}
                      pageTitle={"USPS.com® - Review Shipping Labels"}
                      lookupCodes={this.state.lookupCodes}
                    />
                  )}
                />
                <Route
                  path="/labelDetails"
                  render={(self) => (
                    <LabelDetails
                      {...self}
                      updateCartSize={this.updateCartSize}
                      toggleSpinner={this.toggleSpinner}
                      userData={this.state.userData}
                      lookupCodes={this.state.lookupCodes}
                      pageTitle={"USPS.com® - Shipping History"}
                      //loyaltyUserInfo={this.state.loyaltyUserInfo}
                    />
                  )}
                />

                <Route
                  path="/customsInformation"
                  render={(self) => (
                    <CustomsDeclaration
                      {...self}
                      toggleSpinner={this.toggleSpinner}
                      setEditCartId={this.setEditCartId}
                      pageTitle={"USPS.com® - Enter Customs Information"}
                      lookupCodes={this.state.lookupCodes}
                      userData={this.state.userData}
                    />
                  )}
                />
                <Route
                  path="/checkout-complete"
                  render={(self) => (
                    <PaymentConfirmation
                      {...self}
                      toggleSpinner={this.toggleSpinner}
                      updateCartSize={this.updateCartSize}
                      lookupCodes={this.state.lookupCodes}
                      userData={this.state.userData}
                      pageTitle={"USPS.com® - Print Shipping Labels"}
                      //loyaltyUserInfo={this.state.loyaltyUserInfo}
                    />
                  )}
                />
                <Route
                  path="/scanFormList"
                  render={(self) => (
                    <ScanForms
                      {...self}
                      toggleSpinner={this.toggleSpinner}
                      lookupCodes={this.state.lookupCodes}
                      pageTitle={"USPS.com® - SCAN Form"}
                    />
                  )}
                />
                <Route
                  path="/gxgLocationsDestinations"
                  render={(self) => (
                    <GXGParticipatingLocations
                      {...self}
                      toggleSpinner={this.toggleSpinner}
                      setEditCartId={this.setEditCartId}
                      pageTitle={"USPS.com® - GXG Locations/Destinations"}
                    />
                  )}
                />
                <Route
                  path="/customsInformationGxg"
                  render={(self) => (
                    <GXGCustomsInformation
                      {...self}
                      toggleSpinner={this.toggleSpinner}
                      setEditCartId={this.setEditCartId}
                      pageTitle={"USPS.com® - Enter Customs Information"}
                    />
                  )}
                />
                <Route
                  path="/gxgCommerceInformation"
                  render={(self) => (
                    <GXGCommerceInformation
                      {...self}
                      toggleSpinner={this.toggleSpinner}
                      setEditCartId={this.setEditCartId}
                      pageTitle={"USPS.com® - GXG Review Shipment"}
                    />
                  )}
                />
                <Route
                  path="/gxgGuaranteedDate"
                  render={(self) => (
                    <GXGDateAndFinalPrice
                      {...self}
                      toggleSpinner={this.toggleSpinner}
                      setEditCartId={this.setEditCartId}
                      pageTitle={"USPS.com® - GXG Guaranteed Date"}
                    />
                  )}
                />
                <Route
                  path="/privacy-policy"
                  component={() => {
                    window.location.href = "http://faq.usps.com/";
                    return null;
                  }}
                />
                <Route
                  path="/addressBook"
                  component={() => {
                    window.location.href = this.state.lookupCodes[
                      "cns.addressbook"
                    ];
                    return null;
                  }}
                />
                <Route
                  path="/build"
                  render={(self) => (
                    <Build
                      {...self}
                      toggleSpinner={this.toggleSpinner}
                      pageTitle={"Build"}
                    />
                  )}
                />
                <Route
                  path={this.oldUrls}
                  render={(self) => (
                    <RedirectComponent {...self} oldUrls={this.oldUrls} />
                  )}
                />
                <Route
                  component={() => {
                    window.location.href =
                      "https://www.usps.com/root/global/server_responses/anyapp_outage_apology.htm";
                    return null;
                  }}
                />
              </Switch>
              {this.state.errors.globalError.map((item, keys) => (
                <p
                  className="error-message"
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </div>
          ) : (
            <React.Fragment />
          )}
          {this.state.showSpinner ? <WhiteSpinner /> : <React.Fragment />}
          <div
            className={
              "results-return" + (this.state.showBackToTop ? " active" : "")
            }
          >
            <button
              type="button"
              className="button-link"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img src={backToTopIcon} alt="Back to Top" title="Back to Top" />
            </button>
          </div>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
