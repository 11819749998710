import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const ShippingHistMoreActions = (props) => {
  const content = (
    <React.Fragment>
      <p>
        <strong>
          USPS Tracking<sup>®</sup>
        </strong>{" "}
        - Use this option for an instant status of your shipment, without
        navigating away from the page.
      </p>
      <p>
        <strong>Print/Re-Print*</strong> - Print or Re-Print a label quickly and
        easily.
      </p>
      <p>
        <strong>
          Print at the Post Office<sup>®</sup> using Label Broker
          <sup>®</sup>
        </strong>{" "}
        - Use this option to print your labels later at your local Post Office
        using Label Broker<sup>®</sup>.
      </p>
      <p>
        <strong>Save PDF*</strong> - Save a label in PDF format to print at your
        convenience.
      </p>
      <p>
        <strong>Ship Again</strong> - If you send recurring shipments with the
        same weight, service and delivery address, you'll be able to create the
        same label quickly--without re-entering information. The label is added
        to your cart for checkout with the new shipping date.
      </p>
      {props.showConnectSuppliesStatus && (
        <p>
          <strong>Connect Supplies Status</strong> - If you ordered USPS Connect
          Free Shipping Supplies when purchasing USPS Connect labels, you can
          use this link to view the order status of those supplies.
        </p>
      )}
      <p>
        *These features are available up until Midnight Central Time of the
        label's ship date.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="More Actions Icon"
      header="Shipping History Actions"
      content={content}
    />
  );
};

export default ShippingHistMoreActions;
