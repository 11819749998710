import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import ServiceTypeSelectThePackage from "./ServiceTypeSelectThePackage";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import Moment from "moment";
import lodash from "lodash";
import DropOffLocations from "../Modals/DropOffLocations";
import AssistiveError from "../Subcomponents/AssistiveError";
import validation from "../../utils/validation";
import hidePostagePriceUtils from "../../utils/hidePostagePrice";

class StepFiveServiceType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showResponse: false,
      // Preselect the service type based on the destination and the
      // user's preferences, if available
      serviceShow:
        // This strips away special symbols to make decision making
        // in the code based off the selected service type easier.
        this.props.isDomesticDestination()
          ? this.props.configInfo.domesticProductId.productClassDesc &&
            this.props.configInfo.domesticProductId.productClassDesc !==
              "No Service Assigned"
            ? this.props.configInfo.domesticProductId.productClassDesc
                .replace("&reg;", "")
                .replace("®", "")
                .replace("&trade;", "")
                .replace("™", "")
            : "Priority Mail"
          : "Priority Mail International",
      serviceShowWithSymbols:
        // This displays the selected service type with special symbols
        // to the user.
        this.props.isDomesticDestination()
          ? this.props.configInfo.domesticProductId.productClassDesc &&
            this.props.configInfo.domesticProductId.productClassDesc !==
              "No Service Assigned"
            ? this.props.configInfo.domesticProductId.productClassDesc
                .replace("&reg;", "®")
                .replace("&trade;", "™")
            : "Priority Mail®"
          : "Priority Mail® International",
      prevState: this.props.configInfo,
      dropdownOpen: false,
      serviceInfoIconId: undefined
    };

    this.serviceIconRefs = {};
  }

  componentDidMount() {
    if (this.props.isEditLabel) {
      this.fetchServices();
    }
  }

  convertToString = (obj) => {
    Object.keys(obj).forEach((k) => {
      // if (typeof obj[k] === "object") {
      //   return toString(obj[k]);
      // }

      obj[k] = "" + obj[k];
    });

    return obj;
  };

  validateFields = (e) => {
    let errorsFound = false;
    let errorObject = {};
    // Step 1 - Return Address
    if (
      !this.props.configInfo.returnAddress.firstName &&
      !this.props.configInfo.returnAddress.companyName
    ) {
      errorObject["returnFirstName"] = "Please enter first name.";
      errorsFound = true;
      this.step1ErrorFound = true;
    } else if (
      this.props.configInfo.returnAddress.firstName &&
      this.props.configInfo.returnAddress.firstName.length > 0 &&
      !validation.nameAddress.test(
        this.props.configInfo.returnAddress.firstName
      )
    ) {
      errorObject["returnFirstName"] = "Please enter a valid first name.";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    if (
      !this.props.configInfo.returnAddress.lastName &&
      !this.props.configInfo.returnAddress.companyName
    ) {
      errorObject["returnLastName"] = "Please enter last name.";
      errorsFound = true;
      this.step1ErrorFound = true;
    } else if (
      this.props.configInfo.returnAddress.lastName &&
      this.props.configInfo.returnAddress.lastName.length > 0 &&
      !validation.nameAddress.test(this.props.configInfo.returnAddress.lastName)
    ) {
      errorObject["returnLastName"] = "Please enter a valid last name.";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    if (
      this.props.configInfo.returnAddress.companyName &&
      this.props.configInfo.returnAddress.companyName.length > 0 &&
      !validation.nameAddress.test(
        this.props.configInfo.returnAddress.companyName
      )
    ) {
      errorObject["returnCompany"] = "Please enter a valid company name.";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    if (!this.props.configInfo.returnAddress.line1Addr) {
      errorObject["returnStreetAddr"] = "Please enter street address.";
      errorsFound = true;
      this.step1ErrorFound = true;
    } else if (
      this.props.configInfo.returnAddress.line1Addr &&
      this.props.configInfo.returnAddress.line1Addr.length > 0 &&
      !validation.nameAddress.test(
        this.props.configInfo.returnAddress.line1Addr
      )
    ) {
      errorObject["returnStreetAddr"] = "Please enter a valid street address.";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    if (
      this.props.configInfo.returnAddress.line2Addr &&
      this.props.configInfo.returnAddress.line2Addr.length > 0 &&
      !validation.nameAddress.test(
        this.props.configInfo.returnAddress.line2Addr
      )
    ) {
      errorObject["returnAptSuiteOther"] =
        "Please enter valid address information.";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    if (!this.props.configInfo.returnAddress.cityName) {
      errorObject["returnCity"] = "Please enter a city.";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    if (
      !this.props.configInfo.returnAddress.stateId ||
      this.props.configInfo.returnAddress.stateId === -1 ||
      this.props.configInfo.returnAddress.stateId === "-1"
    ) {
      errorObject["returnState"] = "Please select a state.";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    if (!this.props.configInfo.returnAddress.postalCode) {
      errorObject["returnZipCode"] = "Please enter ZIP Code™.";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    if (
      this.props.configInfo.labelInd.ptsNotifyInd === "Y" &&
      this.props.configInfo.labelInd.emailUpdatesInd === "Y" &&
      !this.props.configInfo.labelInd.ptsTrackingEmail
    ) {
      errorObject["trackingEmailNotifications"] =
        "Please enter an email address.";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    if (
      this.props.configInfo.labelInd.ptsNotifyInd === "Y" &&
      this.props.configInfo.labelInd.ptsTxtUpdatesInd === "Y" &&
      (!this.props.configInfo.labelInd.ptsTxtPhoneNbr ||
        this.props.configInfo.labelInd.ptsTxtPhoneNbr.replace(/[^\d]/g, "")
          .length !== 10)
    ) {
      errorObject["trackingTextNotifications"] =
        "Please enter a valid tracking US phone number in the following format: 987-654-3210";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    if (
      this.props.configInfo.labelInd.shipFromOtherZipInd === "Y" &&
      !this.props.configInfo.shipFromPostalCode
    ) {
      errorObject["shipFromOtherZip"] = "Please enter ZIP Code™.";
      errorsFound = true;
      this.step1ErrorFound = true;
    }
    // Step 2 - Delivery Address
    if (
      this.props.configInfo.batch.length === 0 &&
      !this.props.configInfo.deliveryAddress.firstName &&
      !this.props.configInfo.deliveryAddress.companyName
    ) {
      errorObject["deliveryFirstName"] =
        "Please enter a first name. Leave blank if you are using a company name.";
      errorsFound = true;
      this.step2ErrorFound = true;
    } else if (
      this.props.configInfo.batch.length === 0 &&
      this.props.configInfo.deliveryAddress.firstName &&
      this.props.configInfo.deliveryAddress.firstName.length > 0 &&
      !validation.nameAddress.test(
        this.props.configInfo.deliveryAddress.firstName
      )
    ) {
      errorObject["deliveryFirstName"] = "Please enter a valid first name.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (
      this.props.configInfo.batch.length === 0 &&
      !this.props.configInfo.deliveryAddress.lastName &&
      !this.props.configInfo.deliveryAddress.companyName
    ) {
      errorObject["deliveryLastName"] =
        "Please enter a last name. Leave blank if you are using a company name.";
      errorsFound = true;
      this.step2ErrorFound = true;
    } else if (
      this.props.configInfo.batch.length === 0 &&
      this.props.configInfo.deliveryAddress.lastName &&
      this.props.configInfo.deliveryAddress.lastName.length > 0 &&
      !validation.nameAddress.test(
        this.props.configInfo.deliveryAddress.lastName
      )
    ) {
      errorObject["deliveryLastName"] = "Please enter a valid last name.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (
      this.props.configInfo.batch.length === 0 &&
      (!this.props.configInfo.deliveryAddress.firstName ||
        !this.props.configInfo.deliveryAddress.lastName) &&
      !this.props.configInfo.deliveryAddress.companyName
    ) {
      errorObject["deliveryCompany"] =
        "Please enter a company name. You can leave this field blank if First and Last names have been provided.";
      errorsFound = true;
      this.step2ErrorFound = true;
    } else if (
      this.props.configInfo.batch.length === 0 &&
      this.props.configInfo.deliveryAddress.companyName &&
      this.props.configInfo.deliveryAddress.companyName.length > 0 &&
      !validation.nameAddress.test(
        this.props.configInfo.deliveryAddress.companyName
      )
    ) {
      errorObject["deliveryCompany"] = "Please enter a valid company name.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (
      this.props.configInfo.batch.length === 0 &&
      !this.props.configInfo.deliveryAddress.line1Addr
    ) {
      errorObject["deliveryStreetAddr"] = "Please enter a street address.";
      errorsFound = true;
      this.step2ErrorFound = true;
    } else if (
      this.props.configInfo.batch.length === 0 &&
      this.props.configInfo.deliveryAddress.line1Addr &&
      this.props.configInfo.deliveryAddress.line1Addr.length > 0 &&
      !validation.nameAddress.test(
        this.props.configInfo.deliveryAddress.line1Addr
      )
    ) {
      errorObject["deliveryStreetAddr"] =
        "Please enter a valid street address.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (
      this.props.configInfo.batch.length === 0 &&
      this.props.configInfo.deliveryAddress.line2Addr &&
      this.props.configInfo.deliveryAddress.line2Addr.length > 0 &&
      !validation.nameAddress.test(
        this.props.configInfo.deliveryAddress.line2Addr
      )
    ) {
      errorObject["deliveryAptSuiteOther"] =
        "Please enter valid address information.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (
      !this.props.isDomesticDestination() &&
      this.props.configInfo.batch.length === 0 &&
      this.props.configInfo.deliveryAddress.line3Addr &&
      this.props.configInfo.deliveryAddress.line3Addr.length > 0 &&
      !validation.nameAddress.test(
        this.props.configInfo.deliveryAddress.line3Addr
      )
    ) {
      errorObject["deliveryAddress3"] =
        "Please enter valid address information.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (
      this.props.configInfo.batch.length === 0 &&
      !this.props.configInfo.deliveryAddress.cityName
    ) {
      errorObject["deliveryCity"] = "Please enter a city.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (this.props.isDomesticDestination()) {
      if (
        this.props.configInfo.batch.length === 0 &&
        (!this.props.configInfo.deliveryAddress.stateId ||
          this.props.configInfo.deliveryAddress.stateId === -1 ||
          this.props.configInfo.deliveryAddress.stateId === "-1")
      ) {
        errorObject["deliveryState"] = "Please select a state.";
        errorsFound = true;
        this.step2ErrorFound = true;
      }
    }
    if (
      (this.props.isDomesticDestination() ||
        this.props.configInfo.deliveryAddress.countryId === "124") &&
      this.props.configInfo.batch.length === 0 &&
      !this.props.configInfo.deliveryAddress.postalCode
    ) {
      // Required only for domestic and Canadian addresses
      errorObject["deliveryZip"] = "Please enter ZIP Code™.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (
      this.props.configInfo.labelInd.shipNotifyInd === "Y" &&
      !this.props.configInfo.deliveryAddress.emailAddress
    ) {
      errorObject["sendRecipientEmail"] = "Please enter email.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (
      this.props.configInfo.labelInd.holdForPickupInd === "Y" &&
      this.props.configInfo.labelHfp.recipCheckbox &&
      (this.props.configInfo.labelHfp.recipEmailType === "EMAIL" ||
        !this.props.configInfo.labelHfp.recipEmailType) &&
      !validation.email.test(this.props.configInfo.labelHfp.recipEmailText)
    ) {
      errorObject["holdForPickupAddresseeEmail"] =
        "Please enter a valid email.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }

    if (
      this.props.configInfo.labelInd.holdForPickupInd === "Y" &&
      this.props.configInfo.labelHfp.recipCheckbox &&
      this.props.configInfo.labelHfp.recipEmailType === "SMS" &&
      !phoneNumberRegex.test(this.props.configInfo.labelHfp.recipPhoneText)
    ) {
      errorObject["holdForPickupAddresseePhone"] =
        "Please enter a valid phone number.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (
      this.props.configInfo.labelInd.holdForPickupInd === "Y" &&
      this.props.configInfo.labelHfp.senderCheckbox &&
      (this.props.configInfo.labelHfp.senderEmailType === "EMAIL" ||
        !this.props.configInfo.labelHfp.senderEmailType) &&
      !validation.email.test(this.props.configInfo.labelHfp.senderEmailText)
    ) {
      errorObject["holdForPickupMeEmail"] = "Please enter a valid email.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (
      this.props.configInfo.labelInd.holdForPickupInd === "Y" &&
      this.props.configInfo.labelHfp.senderCheckbox &&
      this.props.configInfo.labelHfp.senderEmailType === "SMS" &&
      !phoneNumberRegex.test(this.props.configInfo.labelHfp.senderPhoneText)
    ) {
      errorObject["holdForPickupMePhone"] =
        "Please enter a valid phone number.";
      errorsFound = true;
      this.step2ErrorFound = true;
    }
    if (this.step2ErrorFound) {
      errorObject["step2"] =
        "This section is required to be completed in order to see available service options and prices.";
    }
    // Step 3 - Shipping Date
    if (!this.props.configInfo.shippingDate) {
      errorObject["shippingDate"] = "Please select a shipping date.";
      errorsFound = true;
      this.step3ErrorFound = true;
    }
    // Step 4 - Package Details
    if (
      this.props.isWeightRequired() &&
      !(
        this.props.configInfo.labelPkg.weightOZQtyOunces > 0 ||
        this.props.configInfo.labelPkg.weightOZQtyPound > 0
      )
    ) {
      errorObject["pounds"] = "Please enter package weight.";
      errorsFound = true;
      this.step4ErrorFound = true;
    } else if (
      this.props.isWeightRequired() &&
      ((String(this.props.configInfo.labelPkg.weightOZQtyOunces) &&
        String(this.props.configInfo.labelPkg.weightOZQtyOunces).length > 0 &&
        !validation.numbers.test(
          this.props.configInfo.labelPkg.weightOZQtyOunces
        )) ||
        (String(this.props.configInfo.labelPkg.weightOZQtyPound) &&
          String(this.props.configInfo.labelPkg.weightOZQtyPound).length > 0 &&
          !validation.numbers.test(
            this.props.configInfo.labelPkg.weightOZQtyPound
          )))
    ) {
      errorObject["pounds"] = "Weight must be entered as a whole number.";
      errorsFound = true;
      this.step4ErrorFound = true;
    }
    if (
      this.props.configInfo.labelPkg.largePackageInd === "Y" &&
      !(this.props.configInfo.labelPkg.pkgHeightQty > 0)
    ) {
      errorObject["height"] = "Please enter height (in).";
      errorsFound = true;
      this.step4ErrorFound = true;
    }
    if (
      this.props.configInfo.labelPkg.largePackageInd === "Y" &&
      !(this.props.configInfo.labelPkg.pkgWidthQty > 0)
    ) {
      errorObject["width"] = "Please enter width (in).";
      errorsFound = true;
      this.step4ErrorFound = true;
    }
    if (
      this.props.configInfo.labelPkg.largePackageInd === "Y" &&
      !(this.props.configInfo.labelPkg.pkgLengthQty > 0)
    ) {
      errorObject["length"] = "Please enter length (in).";
      errorsFound = true;
      this.step4ErrorFound = true;
    }
    if (
      this.props.configInfo.labelPkg.pkgShape === "NR" &&
      !(this.props.configInfo.labelPkg.pkgGirthQty > 0)
    ) {
      errorObject["girth"] = "Please enter girth (in).";
      errorsFound = true;
      this.step4ErrorFound = true;
    }
    if (!this.props.configInfo.labelInd.flatRateInd) {
      errorObject["shippingType"] =
        "Please select the type of shipping for your package.";
      errorsFound = true;
      this.step4ErrorFound = true;
    }
    if (
      !validation.currency.test(this.props.configInfo.labelPkg.pkgValueAmt) ||
      Number(this.props.configInfo.labelPkg.pkgValueAmt) > 5000 ||
      (!this.props.isDomesticDestination() &&
        (!this.props.configInfo.labelPkg.pkgValueAmt === "" ||
          !(this.props.configInfo.labelPkg.pkgValueAmt > 0)))
    ) {
      errorObject["value"] =
        "Enter a value greater than zero and up to $5,000.00";
      errorsFound = true;
      this.step4ErrorFound = true;
    }
    // Step 5 - Service Type
    if (!this.props.isEditLabel && this.state.serviceShow === "Select") {
      errorObject["service"] = "Please select a service.";
      errorsFound = true;
      this.step5ErrorFound = true;
    }

    if (this.props.hazmatYesNo === null && this.props.hazmatYesNo === "N") {
      errorObject["hazmatRadio"] = "Please select an option.";
      errorsFound = true;
      this.step4ErrorFound = true;
    }
    if (
      (!this.props.hazmatType || this.props.hazmatType === "0") &&
      this.props.hazmatYesNo === true
    ) {
      errorObject["hazmat"] = "Please select a category.";
      errorsFound = true;
      this.step4ErrorFound = true;
    }

    this.errors = errorObject;
    this.props.setInputErrors("errorObject")(this.errors);

    return errorsFound;
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    // Clear all delivery address fields if it is on the denied parties list
    if (fieldErrors?.zonosDeniedParties) {
      this.props.changeDeliveryAddress("firstName")("");
      this.props.changeDeliveryAddress("middleInit")("");
      this.props.changeDeliveryAddress("lastName")("");
      this.props.changeDeliveryAddress("companyName")("");
      this.props.changeDeliveryAddress("line1Addr")("");
      this.props.changeDeliveryAddress("line2Addr")("");
      this.props.changeDeliveryAddress("line3Addr")("");
      this.props.changeDeliveryAddress("province")("");
      this.props.changeDeliveryAddress("cityName")("");
      this.props.changeDeliveryAddress("postalCode")("");
      this.props.changeDeliveryAddress("phoneNbr")("");
      this.props.changeDeliveryAddress("refNbr")("");
    }

    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.props.setInputErrors("globalError")(combinedErrors);
  };

  fetchServices = (e) => {
    this.props.toggleSpinner(true);
    if (this.props.batchChecked && this.props.configInfo.batch.length === 0) {
      // Verify that, for batch orders, the user has already added at least 1
      // batch label
      this.props.setAddToBatchMessage(true);
      this.props.toggleSpinner(false);
      this.setState({
        focusOnError: true
      });
    } else if (!this.validateFields()) {
      // Validate input fields
      var dataToSend = {};
      let cnsCartList = [{}];
      if (this.props.configInfo.batch.length === 0) {
        // Non-batch route
        cnsCartList[0].batchNbr = this.props.configInfo.cnsCartList.batchNbr.toString();
        cnsCartList[0].cnsCartId = this.props.configInfo.cnsCartList.cnsCartId.toString();
        cnsCartList[0].cnsCartTypeId = "1";
        // Step 1 - Return Address
        cnsCartList[0].returnAddress = JSON.parse(
          JSON.stringify(this.props.configInfo.returnAddress)
        );
        cnsCartList[0].returnAddress.addressCategory = String(
          this.props.configInfo.returnAddress.addressCategory.value || ""
        );
        cnsCartList[0].ptsTrackingEmail =
          this.props.configInfo.labelInd.ptsTrackingEmail || "";
        cnsCartList[0].ptsTxtPhoneNbr = this.props.configInfo.labelInd
          .ptsTxtPhoneNbr
          ? "1" +
            this.props.configInfo.labelInd.ptsTxtPhoneNbr.replace(/[^\d]/g, "")
          : "";
        cnsCartList[0].shipFromPostalCode = this.props.configInfo.shipFromPostalCode;
        delete cnsCartList[0].returnAddress.avsCode;
        delete cnsCartList[0].returnAddress.country;
        delete cnsCartList[0].returnAddress.stateCode;
        delete cnsCartList[0].returnAddress.countryName;
        delete cnsCartList[0].returnAddress.countryCode;
        delete cnsCartList[0].returnAddress.fullName;
        delete cnsCartList[0].returnAddress.fullOrCompanyName;
        delete cnsCartList[0].returnAddress.zip5;
        delete cnsCartList[0].returnAddress.zipPlus4;
        delete cnsCartList[0].returnAddress.domestic;
        delete cnsCartList[0].returnAddress.singlePly;
        delete cnsCartList[0].returnAddress.displayAddress;
        delete cnsCartList[0].returnAddress.mobileDisplayAddress;
        delete cnsCartList[0].returnAddress.militaryCity;
        delete cnsCartList[0].returnAddress.labelInd;
        delete cnsCartList[0].returnAddress.state;
        delete cnsCartList[0].returnAddress.optLock;
        delete cnsCartList[0].returnAddress.cnsCartId;
        delete cnsCartList[0].returnAddress.saveReturnToAddressBook;

        // Step 2 - Delivery Address
        cnsCartList[0].deliveryAddress = JSON.parse(
          JSON.stringify(this.props.configInfo.deliveryAddress)
        );
        cnsCartList[0].deliveryAddress.line2Addr =
          this.props.configInfo.deliveryAddress.line2Addr || "";
        cnsCartList[0].deliveryAddress.line3Addr =
          this.props.configInfo.deliveryAddress.line3Addr || "";
        cnsCartList[0].toNote = this.props.configInfo.labelInd.toNote || "";
        cnsCartList[0].labelHfp = JSON.parse(
          JSON.stringify(this.props.configInfo.labelHfp)
        );
        delete cnsCartList[0].deliveryAddress.avsCode;
        delete cnsCartList[0].deliveryAddress.addressCategory;
        delete cnsCartList[0].deliveryAddress.stateCode;
        delete cnsCartList[0].deliveryAddress.country;
        delete cnsCartList[0].deliveryAddress.countryName;
        delete cnsCartList[0].deliveryAddress.state;
        delete cnsCartList[0].deliveryAddress.countryCode;
        delete cnsCartList[0].deliveryAddress.fullName;
        delete cnsCartList[0].deliveryAddress.fullOrCompanyName;
        delete cnsCartList[0].deliveryAddress.zip5;
        delete cnsCartList[0].deliveryAddress.zipPlus4;
        delete cnsCartList[0].deliveryAddress.domestic;
        delete cnsCartList[0].deliveryAddress.singlePly;
        delete cnsCartList[0].deliveryAddress.displayAddress;
        delete cnsCartList[0].deliveryAddress.mobileDisplayAddress;
        delete cnsCartList[0].deliveryAddress.militaryCity;
        delete cnsCartList[0].deliveryAddress.saveDeliveryToAddressBook;
        delete cnsCartList[0].deliveryAddress.gabAddrKey;
        delete cnsCartList[0].labelHfp.recipCheckbox;
        delete cnsCartList[0].labelHfp.recipPhoneText;
        delete cnsCartList[0].labelHfp.senderCheckbox;
        delete cnsCartList[0].labelHfp.senderPhoneText;
        // Step 3 - Shipping Date
        cnsCartList[0].shippingDate = Moment(
          this.props.configInfo.shippingDate
        ).format("MM/DD/YYYY");
        // Step 4 - Package Details
        cnsCartList[0].labelPkg = JSON.parse(
          JSON.stringify(this.props.configInfo.labelPkg)
        );
        cnsCartList[0].labelPkg.largePackageInd =
          this.props.configInfo.labelPkg.largePackageInd || "N";
        if (
          this.props.configInfo.labelPkg.weightOZQtyPound &&
          this.props.configInfo.labelPkg.weightOZQtyOunces
        ) {
          cnsCartList[0].labelPkg.weightOzQty =
            Number(this.props.configInfo.labelPkg.weightOZQtyPound) * 16 +
            Number(this.props.configInfo.labelPkg.weightOZQtyOunces);
        } else if (
          this.props.configInfo.labelPkg.weightOZQtyPound &&
          !this.props.configInfo.labelPkg.weightOZQtyOunces
        ) {
          cnsCartList[0].labelPkg.weightOzQty =
            Number(this.props.configInfo.labelPkg.weightOZQtyPound) * 16;
        } else {
          cnsCartList[0].labelPkg.weightOzQty = Number(
            this.props.configInfo.labelPkg.weightOZQtyOunces
          );
        }
        delete cnsCartList[0].labelPkg.weightOZQtyPound;
        delete cnsCartList[0].labelPkg.weightOZQtyOunces;
        delete cnsCartList[0].labelPkg.shippingDate;
        delete cnsCartList[0].labelPkg.fromFnceZipCode;
        delete cnsCartList[0].labelPkg.containerType;
        delete cnsCartList[0].labelPkg.optLock;
        delete cnsCartList[0].labelPkg.pounds;
        delete cnsCartList[0].labelPkg.ounces;
        delete cnsCartList[0].labelPkg.resetPackageDim;
        // Other
        cnsCartList[0].labelInd = JSON.parse(
          JSON.stringify(this.props.configInfo.labelInd)
        );
        cnsCartList[0].labelInd.flatRateInd = !this.props.isWeightRequired()
          ? "Y"
          : "N";
        cnsCartList[0].hazmatType =
          this.props.hazmatType === undefined ? null : this.props.hazmatType;
        if (this.props.hideNonmachinable()) {
          // If the dropdown is hidden, set the nonmachinable indicator
          // based on whether weight was entered
          if (this.props.configInfo.labelInd.flatRateInd === "Y") {
            // Flat rate products are treated as machinable
            cnsCartList[0].labelInd.nonmachinableInd = "0";
          } else {
            // Non-flat rate products are treated as nonmachinable
            cnsCartList[0].labelInd.nonmachinableInd = "1";
          }
        } else if (
          !this.props.hideNonmachinable() &&
          !cnsCartList[0].labelInd.nonmachinableInd
        ) {
          // If the dropdown is shown but the nonmachinable indicator has not
          // been entered, assume "None" ("0") is the intended choice
          cnsCartList[0].labelInd.nonmachinableInd = "0";
        }
        if (this.props.isDomesticDestination()) {
          delete cnsCartList[0].labelInd.giftInd;
        }
        delete cnsCartList[0].labelInd.userId;
        delete cnsCartList[0].labelInd.domesticProductId;
        delete cnsCartList[0].labelInd.largePackageInd;
        delete cnsCartList[0].labelInd.pkgShape;
        delete cnsCartList[0].labelInd.ptsTrackingEmail;
        delete cnsCartList[0].labelInd.ptsTxtPhoneNbr;
        delete cnsCartList[0].labelInd.weightOzQty;
        delete cnsCartList[0].labelInd.userReturnAddr;
        delete cnsCartList[0].labelInd.pounds;
        delete cnsCartList[0].labelInd.ounces;
        delete cnsCartList[0].labelInd.rectangular;
        delete cnsCartList[0].labelInd.serviceType;
        delete cnsCartList[0].labelInd.shippingHistoryColumnFilter;
        delete cnsCartList[0].labelInd.shippingHistorySortFilter;
        delete cnsCartList[0].labelInd.shPagesize;
        delete cnsCartList[0].labelInd.optLock;
        delete cnsCartList[0].labelInd.toNote;
        delete cnsCartList[0].labelInd.printOptions;
        delete cnsCartList[0].labelInd.refNbr;
        delete cnsCartList[0].labelInd.shipFromPostalCode;
        delete cnsCartList[0].labelInd.pkgGirthQty;
        delete cnsCartList[0].labelInd.pkgHeightQty;
        delete cnsCartList[0].labelInd.pkgLengthQty;
        delete cnsCartList[0].labelInd.pkgWidthQty;
        delete cnsCartList[0].labelInd.weightOZQtyOunces;
        delete cnsCartList[0].labelInd.weightOZQtyPound;
        delete cnsCartList[0].labelInd.additionalService;
        delete cnsCartList[0].labelInd.screenSize;
        delete cnsCartList[0].labelInd.lpaNotice; //TODO: Remove 

        delete cnsCartList[0].labelInd.commercialGoldInd;

        cnsCartList[0].labelInd.parcelSelectInd =
          // Creating a new label,
          // or after making changes to a label from the cart
          this.props.selectedDropOffLocationIndex ||
          // Editing a label from the cart, but before its
          // delivery address, for example, has been modified
          (this.props.isEditLabel &&
            this.props.configInfo.cnsCartList &&
            this.props.configInfo.cnsCartList.labelDropOffAddr &&
            this.props.configInfo.cnsCartList.labelDropOffAddr.locationName)
            ? "Y"
            : "N";

        cnsCartList[0].productId = {
          productId:
            String(
              this.props.configInfo.saveLabelInfoAdditionalData.productId
            ) || "0"
        };

        dataToSend.cnsCartList = cnsCartList;
      } else if (this.props.configInfo.batch.length > 0) {
        // Batch route
        let cnsCartListArr = [];
        let cnsCartList = {};

        for (
          let index = 0;
          index < this.props.configInfo.batch.length;
          index++
        ) {
          cnsCartList.batchNbr = this.props.configInfo.cnsCartList.batchNbr;
          cnsCartList.cnsCartId = this.props.configInfo.batch[
            index
          ].cnsCartList[0].cnsCartId;
          cnsCartList.cnsCartTypeId = "1";
          // Step 1 - Return Address
          cnsCartList.returnAddress = JSON.parse(
            JSON.stringify(this.props.configInfo.returnAddress)
          );
          cnsCartList.returnAddress.addressCategory = String(
            this.props.configInfo.returnAddress.addressCategory.value || ""
          );
          cnsCartList.ptsTrackingEmail =
            this.props.configInfo.labelInd.ptsTrackingEmail || "";
          cnsCartList.ptsTxtPhoneNbr = this.props.configInfo.labelInd
            .ptsTxtPhoneNbr
            ? "1" +
              this.props.configInfo.labelInd.ptsTxtPhoneNbr.replace(
                /[^\d]/g,
                ""
              )
            : "";
          cnsCartList.shipFromPostalCode = this.props.configInfo.shipFromPostalCode;
          delete cnsCartList.returnAddress.avsCode;
          delete cnsCartList.returnAddress.country;
          delete cnsCartList.returnAddress.stateCode;
          delete cnsCartList.returnAddress.countryName;
          delete cnsCartList.returnAddress.countryCode;
          delete cnsCartList.returnAddress.fullName;
          delete cnsCartList.returnAddress.fullOrCompanyName;
          delete cnsCartList.returnAddress.zip5;
          delete cnsCartList.returnAddress.zipPlus4;
          delete cnsCartList.returnAddress.domestic;
          delete cnsCartList.returnAddress.singlePly;
          delete cnsCartList.returnAddress.displayAddress;
          delete cnsCartList.returnAddress.mobileDisplayAddress;
          delete cnsCartList.returnAddress.militaryCity;
          delete cnsCartList.returnAddress.labelInd;
          delete cnsCartList.returnAddress.state;
          delete cnsCartList.returnAddress.optLock;
          delete cnsCartList.returnAddress.cnsCartId;
          delete cnsCartList.returnAddress.saveReturnToAddressBook;

          // Step 2 - Delivery Address
          cnsCartList.deliveryAddress = JSON.parse(
            JSON.stringify(
              this.props.configInfo.batch[index].cnsCartList[0].deliveryAddress
            )
          );
          cnsCartList.toNote =
            this.props.configInfo.batch[index].cnsCartList[0].toNote || "";
          cnsCartList.deliveryAddress.poFlag = "";
          cnsCartList.deliveryAddress.province = "";
          cnsCartList.deliveryAddress.phoneNbr = "";
          delete cnsCartList.deliveryAddress.avsCode;
          delete cnsCartList.deliveryAddress.addressCategory;
          delete cnsCartList.deliveryAddress.stateCode;
          delete cnsCartList.deliveryAddress.country;
          delete cnsCartList.deliveryAddress.countryName;
          delete cnsCartList.deliveryAddress.state;
          delete cnsCartList.deliveryAddress.countryCode;
          delete cnsCartList.deliveryAddress.fullName;
          delete cnsCartList.deliveryAddress.fullOrCompanyName;
          delete cnsCartList.deliveryAddress.zip5;
          delete cnsCartList.deliveryAddress.zipPlus4;
          delete cnsCartList.deliveryAddress.domestic;
          delete cnsCartList.deliveryAddress.singlePly;
          delete cnsCartList.deliveryAddress.displayAddress;
          delete cnsCartList.deliveryAddress.mobileDisplayAddress;
          delete cnsCartList.deliveryAddress.militaryCity;
          delete cnsCartList.deliveryAddress.saveDeliveryToAddressBook;
          delete cnsCartList.deliveryAddress.gabAddrKey;
          delete cnsCartList.deliveryAddress.cnsCartId;
          delete cnsCartList.deliveryAddress.optLock;
          // Step 3 - Shipping Date
          cnsCartList.shippingDate = Moment(
            this.props.configInfo.shippingDate
          ).format("MM/DD/YYYY");
          // Step 4 - Package Details
          cnsCartList.labelPkg = JSON.parse(
            JSON.stringify(this.props.configInfo.labelPkg)
          );
          cnsCartList.labelPkg.largePackageInd =
            this.props.configInfo.labelPkg.largePackageInd || "N";
          if (
            this.props.configInfo.labelPkg.weightOZQtyPound &&
            this.props.configInfo.labelPkg.weightOZQtyOunces
          ) {
            cnsCartList.labelPkg.weightOzQty =
              Number(this.props.configInfo.labelPkg.weightOZQtyPound) * 16 +
              Number(this.props.configInfo.labelPkg.weightOZQtyOunces);
          } else if (
            this.props.configInfo.labelPkg.weightOZQtyPound &&
            !this.props.configInfo.labelPkg.weightOZQtyOunces
          ) {
            cnsCartList.labelPkg.weightOzQty =
              Number(this.props.configInfo.labelPkg.weightOZQtyPound) * 16;
          } else {
            cnsCartList.labelPkg.weightOzQty = Number(
              this.props.configInfo.labelPkg.weightOZQtyOunces
            );
          }
          delete cnsCartList.labelPkg.weightOZQtyPound;
          delete cnsCartList.labelPkg.weightOZQtyOunces;
          delete cnsCartList.labelPkg.shippingDate;
          delete cnsCartList.labelPkg.fromFnceZipCode;
          delete cnsCartList.labelPkg.containerType;
          delete cnsCartList.labelPkg.optLock;
          delete cnsCartList.labelPkg.pounds;
          delete cnsCartList.labelPkg.ounces;
          delete cnsCartList.labelPkg.resetPackageDim;
          // Other
          cnsCartList.labelInd = JSON.parse(
            JSON.stringify(this.props.configInfo.labelInd)
          );
          cnsCartList.labelInd.flatRateInd = !this.props.isWeightRequired()
            ? "Y"
            : "N";
          if (this.props.isDomesticDestination()) {
            delete cnsCartList.labelInd.giftInd;
          }
          delete cnsCartList.labelInd.userId;
          delete cnsCartList.labelInd.domesticProductId;
          delete cnsCartList.labelInd.largePackageInd;
          delete cnsCartList.labelInd.pkgShape;
          delete cnsCartList.labelInd.ptsTrackingEmail;
          delete cnsCartList.labelInd.ptsTxtPhoneNbr;
          delete cnsCartList.labelInd.weightOzQty;
          delete cnsCartList.labelInd.userReturnAddr;
          delete cnsCartList.labelInd.pounds;
          delete cnsCartList.labelInd.ounces;
          delete cnsCartList.labelInd.rectangular;
          delete cnsCartList.labelInd.serviceType;
          delete cnsCartList.labelInd.shippingHistoryColumnFilter;
          delete cnsCartList.labelInd.shippingHistorySortFilter;
          delete cnsCartList.labelInd.shPagesize;
          delete cnsCartList.labelInd.optLock;
          delete cnsCartList.labelInd.toNote;
          delete cnsCartList.labelInd.printOptions;
          delete cnsCartList.labelInd.refNbr;
          delete cnsCartList.labelInd.shipFromPostalCode;
          delete cnsCartList.labelInd.pkgGirthQty;
          delete cnsCartList.labelInd.pkgHeightQty;
          delete cnsCartList.labelInd.pkgLengthQty;
          delete cnsCartList.labelInd.pkgWidthQty;
          delete cnsCartList.labelInd.weightOZQtyOunces;
          delete cnsCartList.labelInd.weightOZQtyPound;
          delete cnsCartList.labelInd.additionalService;
          delete cnsCartList.labelInd.screenSize;
          delete cnsCartList.labelInd.lpaNotice; //TODO: remove
          cnsCartList.hazmatType =
            this.props.hazmatType === undefined ? null : this.props.hazmatType;
          delete cnsCartList.labelInd.commercialGoldInd;
          cnsCartList.productId = {
            productId: "0"
          };

          cnsCartListArr.push(cnsCartList);
          cnsCartList = {};
        }

        dataToSend.cnsCartList = cnsCartListArr;
      }

      let holdhazmat = this.props.hazmatType;
      axios
        .post("/go/cnsrest/fetchServices", JSON.stringify(dataToSend), {
          headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
          if (
            Object.keys(response.data.fieldErrors).length === 0 &&
            response.data.actionErrors.length === 0
          ) {
            // Reset global error to nothing after a successful api call
            this.setGlobalError([], []);
            // For new labels or those from the cart that have been modified,
            // check if the hide postage price option needs to be updated
            if (!this.props.isEditLabel) {
              this.props.setUsingHidePostagePriceAddress(
                response.data.cnsCartList
              );
            }

            //WT currenty has a bug here they can not do HAZMAT of any type on a flatrate product. Only Choose your own box products. THis is an array of choose your own box.

            let arrOfChooseOwnBoxProducts = [
              150,
              200,
              201,
              215,
              220,
              230,
              231,
              239,
              100,
              101,
              102,
              121,
              999,
              103,
              501,
              502,
              503,
              504,
              508,
              800,
              801
            ];

            if (
              null !== holdhazmat &&
              "0" != holdhazmat &&
              this.props.lookupCodes.hazmat_chooseOwnBoxOnly === "true"
            ) {
              // response.data.productServiceViewBean.productsByServiceType.PRI = arrOfChooseOwnBoxProducts.filter(
              //   (ele) =>
              //     !response.data.productServiceViewBean.productsByServiceType.PRI.includes(
              //       ele
              //     )
              // );

              // response.data.productServiceViewBean.productsByServiceType.EXM = arrOfChooseOwnBoxProducts.filter(
              //   (ele) =>
              //     !response.data.productServiceViewBean.productsByServiceType.EXM.includes(
              //       ele
              //     )
              // );

              response.data.productServiceViewBean.productsByServiceType.PRI = response.data.productServiceViewBean.productsByServiceType.PRI.filter(
                (ele) => arrOfChooseOwnBoxProducts.includes(ele.productId)
              );

              response.data.productServiceViewBean.productsByServiceType.EXM = response.data.productServiceViewBean.productsByServiceType.EXM.filter(
                (ele) => arrOfChooseOwnBoxProducts.includes(ele.productId)
              );

              delete response.data.parcelSelectProductServiceViewBean;
              delete response.data.productServiceViewBean.productsByServiceType
                .GA[0]?.additionalServices?.RES;
              delete response.data.productServiceViewBean.productsByServiceType
                .PRI[0]?.additionalServices?.SIGRES;
              delete response.data.productServiceViewBean.productsByServiceType
                .PRI[0]?.additionalServices?.RES;
            }

            //HAZMAT
            //811,812,813,816,817,822,828,830,831,832 only PSG
            let arrOfPSGOnlyHazmat = [
              811,
              812,
              813,
              816,
              817,
              822,
              828,
              830,
              831,
              832
            ];

            if (arrOfPSGOnlyHazmat.includes(Number(holdhazmat))) {
              delete response.data.productServiceViewBean.productsByServiceType
                .PRI;
              delete response.data.productServiceViewBean.productsByServiceType
                .EXM;
              delete response.data.productServiceViewBean.productsByServiceType
                .FC;
              delete response.data.productServiceViewBean.productsByServiceType
                .GA;
            }

            if (this.props.configInfo.labelInd.flatRateInd === "Y") {
              delete response.data.productServiceViewBean.productsByServiceType
                .GA;

              if (holdhazmat !== null) {
                delete response.data.productServiceViewBean
                  .productsByServiceType.PRI;
                delete response.data.productServiceViewBean
                  .productsByServiceType.EXM;
                delete response.data.productServiceViewBean
                  .productsByServiceType.CL;
                delete response.data.productServiceViewBean
                  .productsByServiceType.CLM;
                delete response.data.productServiceViewBean
                  .productsByServiceType.GA;
              }
            }

            //OUTLABEL is not a service CNS offers remmoving it here.
            for (let ij in response.data.productServiceViewBean
              .productsByServiceType.PRI) {
              if (
                response.data.productServiceViewBean.productsByServiceType.PRI[
                  ij
                ].additionalServices.OUTLABEL
              ) {
                delete response.data.productServiceViewBean
                  .productsByServiceType.PRI[ij].additionalServices.OUTLABEL;
              }
            }

            for (let jj in response.data.productServiceViewBean
              .productsByServiceType.EXM) {
              if (
                response.data.productServiceViewBean.productsByServiceType.EXM[
                  jj
                ].additionalServices.OUTLABEL
              ) {
                delete response.data.productServiceViewBean
                  .productsByServiceType.EXM[jj].additionalServices.OUTLABEL;
              }
            }

            //There are no STC for SIGRES/INSRES and HFP so we are removing here.
            if (this.props.configInfo.labelInd.holdForPickupInd === "Y") {
              for (let ii in response.data.productServiceViewBean
                .productsByServiceType.PRI) {
                if (
                  response.data.productServiceViewBean.productsByServiceType
                    .PRI[ii].additionalServices.SIGRES
                ) {
                  delete response.data.productServiceViewBean
                    .productsByServiceType.PRI[ii].additionalServices.SIGRES;

                  delete response.data.productServiceViewBean
                    .productsByServiceType.PRI[ii].additionalServices.INSRES;
                }
              }
            }

            // Update insurance selection, unless it was previously selected
            if (
              this.props.configInfo.labelInd.insuranceInd !== "Y" &&
              this.props.configInfo.labelInd.insRestrictedDeliveryInd !== "Y"
            ) {
              this.props.changeTrackingNotifications("insuranceInd")("N");
            }
            // For packages > $500, may need to preselect an additional service
            if (Number(this.props.configInfo.labelPkg.pkgValueAmt) > 500) {
              if (!this.props.isEditLabel) {
                // For new labels only
                if (this.state.serviceShow === "Priority Mail") {
                  this.props.changeTrackingNotifications("additionalService")(
                    "DEL"
                  );
                } else if (this.state.serviceShow === "Priority Mail Express") {
                  this.props.changeTrackingNotifications("additionalService")(
                    "SIGRQ"
                  );
                }
              } else {
                // If the label being edited is PM and has the signature
                // confirmation additional service, preselect the DEL
                // option (this will be converted back to SIG upon saving
                // the info)
                if (
                  this.state.serviceShow === "Priority Mail" &&
                  this.props.configInfo.labelInd.additionalService === "SIG"
                ) {
                  this.props.changeTrackingNotifications("additionalService")(
                    "DEL"
                  );
                }
              }
            } else {
              // For packages <= $500, need to preselect "No selection",
              // if available
              if (!this.props.isEditLabel) {
                // For new labels
                if (
                  this.state.serviceShow === "Priority Mail" ||
                  (this.state.serviceShow === "Priority Mail Express" &&
                    this.props.configInfo.labelInd.crematedRemainsInd !== "Y")
                ) {
                  this.props.changeTrackingNotifications("additionalService")(
                    ""
                  );
                }
              }
            }
            //CBP pricing: Swaps out retail prices for commercial prices if the user has CBP enabled
            if (response.data.commercialPricesViewBean) {
              if (
                response.data.commercialPricesViewBean.productsByServiceType.PRI
              ) {
                response.data.productServiceViewBean.productsByServiceType.PRI =
                  response.data.commercialPricesViewBean.productsByServiceType.PRI;
              }

              if (
                response.data.commercialPricesViewBean.productsByServiceType.EXM
              ) {
                response.data.productServiceViewBean.productsByServiceType.EXM =
                  response.data.commercialPricesViewBean.productsByServiceType.EXM;
              }

              if (
                response.data.commercialPricesViewBean.productsByServiceType
                  .FCPSR
              ) {
                response.data.productServiceViewBean.productsByServiceType.FCPSR =
                  response.data.commercialPricesViewBean.productsByServiceType.FCPSR;
              }
            }
            //

            // If present, merge all USPS Connect Local services into the list
            // of services to be displayed to the customer
            const psViewBean = response.data.parcelSelectProductServiceViewBean;
            if (psViewBean && psViewBean.productsByServiceType) {
              //Webtools wont let us manifest a label that has SIGRES with CL
              //Was requested to hide SIGRES until further notice
              //Remove this block when we can use SIGRES
              if (
                psViewBean.productsByServiceType.CL &&
                Number(this.props.configInfo.labelPkg.pkgValueAmt) > 500
              ) {
                for (let i in psViewBean.productsByServiceType.CL) {
                  if (
                    psViewBean.productsByServiceType.CL[i].additionalServices
                  ) {
                    delete psViewBean.productsByServiceType.CL[i]
                      .additionalServices["SIGRES"];
                  }
                }
              }
              //////////////////////////////////////////////////////////////////////

              const psProductsByServiceType = Object.keys(
                psViewBean.productsByServiceType
              );
              for (let i in psProductsByServiceType) {
                // For each individual service, if Sunday delivery is present,
                // move it out of additional services since it is considered
                // something different
                let currentServiceType =
                  psViewBean.productsByServiceType[psProductsByServiceType[i]];
                for (let j in currentServiceType) {
                  if (
                    currentServiceType[j].additionalServices &&
                    currentServiceType[j].additionalServices.SUNDEL
                  ) {
                    currentServiceType[j].sundayDelivery = JSON.parse(
                      JSON.stringify(
                        currentServiceType[j].additionalServices.SUNDEL
                      )
                    );
                    delete currentServiceType[j].additionalServices.SUNDEL;
                  }
                }
                // Merge the Connect Local services with the other services
                response.data.productServiceViewBean.productsByServiceType[
                  psProductsByServiceType[i]
                ] = currentServiceType;
                response.data.productServiceViewBean.serviceTypes.push(
                  psViewBean.serviceTypes[i]
                );
              }
            }
            // If present, merge all USPS Connect Local Mail services into the
            // list of services to be displayed to the customer
            const cmViewBean = response.data.connectMailProductServiceViewBean;
            if (cmViewBean && cmViewBean.productsByServiceType) {
              const cmProductsByServiceType = Object.keys(
                cmViewBean.productsByServiceType
              );
              for (let i in cmProductsByServiceType) {
                response.data.productServiceViewBean.productsByServiceType[
                  cmProductsByServiceType[i]
                ] =
                  cmViewBean.productsByServiceType[cmProductsByServiceType[i]];
                response.data.productServiceViewBean.serviceTypes.push(
                  cmViewBean.serviceTypes[i]
                );
              }
            }

            // Update the drop-off location, if necessary
            if (
              // Connect Local services
              (psViewBean && psViewBean.productsByServiceType) ||
              // Connect Local Mail services
              (cmViewBean && cmViewBean.productsByServiceType)
            ) {
              let dropoffLocationData = {
                locationList: []
              };
              if (
                response.data.dropOffLocations &&
                response.data.dropOffLocations.successful &&
                response.data.dropOffLocations.locationList &&
                Array.isArray(response.data.dropOffLocations.locationList)
              ) {
                dropoffLocationData = response.data.dropOffLocations;
              }
              const deliveryZip5 = response.data.cnsCartList[0].deliveryAddress
                .postalCode
                ? response.data.cnsCartList[0].deliveryAddress.postalCode
                : response.data.cnsCartList[0].deliveryAddress.zip5
                ? response.data.cnsCartList[0].deliveryAddress.zip5
                : "";
              this.props.updateDropOffLocation(
                dropoffLocationData,
                deliveryZip5.slice(0, 5)
              );
            }

            // When Parcel Select Ground is available, add that to the list
            // of products / service types if not already present
            const psgViewBean = response.data.parcelSelectGroundViewBean;
            if (
              psgViewBean &&
              psgViewBean.productsByServiceType &&
              psgViewBean.serviceTypes &&
              psgViewBean.serviceTypes.length > 0
            ) {
              const psgServiceType = Object.keys(
                psgViewBean.productsByServiceType
              )[0];
              if (
                !response.data.productServiceViewBean.productsByServiceType[
                  psgServiceType
                ]
              ) {
                response.data.productServiceViewBean.productsByServiceType[
                  psgServiceType
                ] = psgViewBean.productsByServiceType[psgServiceType];
                response.data.productServiceViewBean.serviceTypes.push(
                  psgViewBean.serviceTypes[0]
                );
              }
            }

            // For non-batch orders only (batch order addresses are validated
            // elsewhere):
            // Update the return and delivery addresses with the values from the
            // response to ensure the standardized/validated addresses are used.
            if (!this.props.batchChecked) {
              // We use the updater form of setState() here to ensure state is
              // up-to-date by the time this is called
              this.setState((prevState) => {
                return {
                  updateFromFetchServices: true
                };
              });
              this.props.changeReturnAddressField("firstName")(
                response.data.cnsCartList[0].returnAddress.firstName
              );
              this.props.changeReturnAddressField("middleInit")(
                response.data.cnsCartList[0].returnAddress.middleInit
              );
              this.props.changeReturnAddressField("lastName")(
                response.data.cnsCartList[0].returnAddress.lastName
              );
              this.props.changeReturnAddressField("companyName")(
                response.data.cnsCartList[0].returnAddress.companyName
              );
              this.props.changeReturnAddressField("line1Addr")(
                response.data.cnsCartList[0].returnAddress.line1Addr
              );
              this.props.changeReturnAddressField("line2Addr")(
                response.data.cnsCartList[0].returnAddress.line2Addr
              );
              this.props.changeReturnAddressField("cityName")(
                response.data.cnsCartList[0].returnAddress.cityName
              );
              this.props.updateUSAState(
                String(response.data.cnsCartList[0].returnAddress.stateId)
              );
              this.props.changeReturnAddressField("postalCode")(
                response.data.cnsCartList[0].returnAddress.postalCode
              );
              this.props.changeReturnAddressField("urbanizationCode")(
                response.data.cnsCartList[0].returnAddress.urbanizationCode
              );
              this.props.changeDeliveryAddress("firstName")(
                response.data.cnsCartList[0].deliveryAddress.firstName
              );
              this.props.changeDeliveryAddress("middleInit")(
                response.data.cnsCartList[0].deliveryAddress.middleInit
              );
              this.props.changeDeliveryAddress("lastName")(
                response.data.cnsCartList[0].deliveryAddress.lastName
              );
              this.props.changeDeliveryAddress("companyName")(
                response.data.cnsCartList[0].deliveryAddress.companyName
              );
              this.props.changeDeliveryAddress("line1Addr")(
                response.data.cnsCartList[0].deliveryAddress.line1Addr
              );
              this.props.changeDeliveryAddress("line2Addr")(
                response.data.cnsCartList[0].deliveryAddress.line2Addr
              );
              this.props.changeDeliveryAddress("cityName")(
                response.data.cnsCartList[0].deliveryAddress.cityName
              );
              this.props.changeDeliveryAddress("postalCode")(
                response.data.cnsCartList[0].deliveryAddress.postalCode
              );
              if (this.props.isDomesticDestination()) {
                this.props.changeDeliveryAddress("stateId")(
                  String(response.data.cnsCartList[0].deliveryAddress.stateId)
                );
                this.props.changeDeliveryAddress("urbanizationCode")(
                  response.data.cnsCartList[0].deliveryAddress.urbanizationCode
                );
              } else {
                this.props.changeDeliveryAddress("line3Addr")(
                  response.data.cnsCartList[0].deliveryAddress.line3Addr
                );
                this.props.changeDeliveryAddress("province")(
                  response.data.cnsCartList[0].deliveryAddress.province
                );
              }
            }

            if (
              !this.props.isEditLabel &&
              response.data.productServiceViewBean.preferences
                .domesticProductId != null &&
              response.data.productServiceViewBean.preferences.domesticProductId
                .productClassDesc &&
              this.state.serviceShow ===
                response.data.productServiceViewBean.preferences.domesticProductId.productClassDesc
                  .replace("&reg;", "")
                  .replace("®", "")
                  .replace("&trade;", "")
                  .replace("™", "")
            ) {
              // When creating a new label, if the selected service type
              // matches the user's preferences, find the service object for
              // the service from the user's preferences
              // (needed for displaying additional services)
              const products =
                response.data.productServiceViewBean.productsByServiceType;
              for (const property in products) {
                if (Array.isArray(products[property])) {
                  this.selectedService = products[property].find(
                    (element) =>
                      element.productId ===
                      response.data.productServiceViewBean.preferences
                        .domesticProductId.productId
                  );
                  if (this.selectedService !== undefined) {
                    break;
                  }
                }
              }
              // Set the service
              this.props.setPackageTypeProductId(
                response.data.productServiceViewBean.preferences
                  .domesticProductId.productId
              );
            } else if (
              this.props.isEditLabel &&
              response.data.cnsCartList[0].productId != null &&
              response.data.cnsCartList[0].productId.productClassDesc
            ) {
              // When editing a label (and before modifying any fields),
              // find the service object for the service previously selected
              // (needed for displaying additional services)
              const products =
                response.data.productServiceViewBean.productsByServiceType;
              for (const property in products) {
                if (Array.isArray(products[property])) {
                  this.selectedService = products[property].find(
                    (element) =>
                      element.productId ===
                      response.data.cnsCartList[0].productId.productId
                  );
                  if (this.selectedService !== undefined) {
                    break;
                  }
                }
              }
              // Set the service type (the text from the response must match
              // the service type name that is displayed to the user)
              const serviceShow = // FCPS case
                response.data.cnsCartList[0].productId.productClassDesc ===
                "First-Class Package Service – Retail"
                  ? "First-Class Package Service - Retail"
                  : // FCPIS case
                  response.data.cnsCartList[0].productId.productClassDesc ===
                    "First-Class International"
                  ? "First-Class Package International Service"
                  : // All Others
                    response.data.cnsCartList[0].productId.productClassDesc;
              this.setState({
                serviceShow: serviceShow
                  .replace("&reg;", "")
                  .replace("®", "")
                  .replace("&trade;", "")
                  .replace("™", ""),
                serviceShowWithSymbols: serviceShow
                  .replace("&reg;", "®")
                  .replace("&trade;", "™")
              });
              // Set the service
              this.props.setPackageTypeProductId(
                response.data.cnsCartList[0].productId.productId
              );
            } else {
              // Treat as a fresh label with no service preference
              this.props.setPackageTypeProductId("");
            }

            // Display the rest of the page.
            // We use the updater form of setState() here to ensure state is
            // up-to-date by the time this is called
            this.setState((prevState) => {
              return {
                showResponse: true,
                focusAfterResponse: true,
                listOfServices: response,
                expressMailDropOffLocations:
                  response.data.expressMailDropOffLocations,
                sortPackageTypeAsc: null,
                sortPriceAsc: null,
                sortedServices: undefined,
                updateFromFetchServices: false,
                customsRequiredServiceTypes:
                  response.data.customsRequiredServiceTypes
                //batchCartList: cnsCartList
              };
            });
            // If the address was updated on the frontend due to being
            // validated/standardized on the backend, another call to
            // getDerivedStateFromProps() might be performed after the
            // above statement.  We can check to see if that happened by
            // seeing if showResponse is not true.  If so, we can set the
            // necessary state fields manually here to ensure they are the
            // correct values.
            this.setState((prevState, prevProps) => {
              if (!prevState.showResponse) {
                prevProps.setIsEditLabel(prevState.tempIsEditLabel);
                return {
                  showResponse: true,
                  focusAfterResponse: true,
                  serviceShow: prevState.tempServiceShow,
                  serviceShowWithSymbols: prevState.tempServiceShowWithSymbols
                };
              }
            });
          } else {
            window.scrollTo(0, 0);
            this.setGlobalError(
              response.data.fieldErrors,
              response.data.actionErrors
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.props.toggleSpinner(false);
        });
    } else {
      this.props.toggleSpinner(false);
      this.setState({
        focusOnError: true
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // Do not check for changes if we are updating the fields from the fetch
    // services call (rather than the user directly updating the fields)
    if (prevState.updateFromFetchServices) {
      return null;
    }
    if (
      // Changes to delivery address
      !lodash.isEqual(
        nextProps.configInfo.deliveryAddress,
        prevState.prevState.deliveryAddress
      ) ||
      // Changes to return address
      !lodash.isEqual(
        nextProps.configInfo.returnAddress,
        prevState.prevState.returnAddress
      ) ||
      // Changes to shipping date
      !lodash.isEqual(
        nextProps.configInfo.shippingDate,
        prevState.prevState.shippingDate
      ) ||
      // Changes to package details
      !lodash.isEqual(
        nextProps.configInfo.labelPkg,
        prevState.prevState.labelPkg
      ) ||
      // Changes to the flat rate indicator
      !lodash.isEqual(
        nextProps.configInfo.labelInd.flatRateInd,
        prevState.prevState.labelInd.flatRateInd
      ) ||
      // Changes to the nonmachinable indicator
      !lodash.isEqual(
        nextProps.configInfo.labelInd.nonmachinableInd,
        prevState.prevState.labelInd.nonmachinableInd
      ) ||
      // Changes to hold for pickup fields
      !lodash.isEqual(
        nextProps.configInfo.labelHfp,
        prevState.prevState.labelHfp
      ) ||
      // Changes to the hold for pickup indicator
      nextProps.configInfo.labelInd.holdForPickupInd !==
        prevState.prevState.labelInd.holdForPickupInd ||
      // Changes to the number of items in a batch order
      nextProps.configInfo.batch.length !== prevState.prevState.batch.length ||
      // Changes to whether or not a batch item is being edited
      nextProps.inBatchEdit !== prevState.prevState.inBatchEdit ||
      // Changes to the ship from another ZIP code indicator
      nextProps.configInfo.labelInd.shipFromOtherZipInd !==
        prevState.prevState.labelInd.shipFromOtherZipInd ||
      // Changes to the ship from another ZIP code field
      nextProps.configInfo.shipFromPostalCode !==
        prevState.prevState.shipFromPostalCode || // Changes to HAZMAT info
      nextProps.configInfo.hazmatYesNo !== prevState.prevState.hazmatYesNo ||
      nextProps.configInfo.hazmatType !== prevState.prevState.hazmatType
    ) {
      // Reset service info
      const tempIsEditLabel = nextProps.isEditLabel;
      const tempServiceShow = prevState.serviceShow;
      const tempServiceShowWithSymbols = prevState.serviceShowWithSymbols;
      nextProps.setIsEditLabel(false);
      return {
        showResponse: false,
        focusAfterResponse: false,
        prevState: nextProps.configInfo,
        tempIsEditLabel: tempIsEditLabel,
        tempServiceShow: tempServiceShow,
        tempServiceShowWithSymbols: tempServiceShowWithSymbols,
        serviceShow: nextProps.isDomesticDestination()
          ? nextProps.configInfo.domesticProductId.productClassDesc &&
            nextProps.configInfo.domesticProductId.productClassDesc !==
              "No Service Assigned"
            ? nextProps.configInfo.domesticProductId.productClassDesc
                .replace("&reg;", "")
                .replace("®", "")
                .replace("&trade;", "")
                .replace("™", "")
            : "Priority Mail"
          : "Priority Mail International",
        serviceShowWithSymbols: nextProps.isDomesticDestination()
          ? nextProps.configInfo.domesticProductId.productClassDesc &&
            nextProps.configInfo.domesticProductId.productClassDesc !==
              "No Service Assigned"
            ? nextProps.configInfo.domesticProductId.productClassDesc
                .replace("&reg;", "®")
                .replace("&trade;", "™")
            : "Priority Mail®"
          : "Priority Mail® International"
      };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.focusOnError) {
      // Scroll to the first section that has an error, and
      // focus on the first field with an error
      if (this.props.addToBatchMessage) {
        // Must add to batch first if this message is set; takes precedence
        // before the other errors
        document.getElementById("mailing-address").scrollIntoView();
        document.getElementById("add-to-batch-message-a11y").focus();
      } else if (this.step1ErrorFound) {
        // Step 1 - Return Address
        document.getElementById("return-address").scrollIntoView();
        if (this.errors["returnFirstName"]) {
          document.getElementById("returnFirstNameId-a11y").focus();
        } else if (this.errors["returnLastName"]) {
          document.getElementById("returnLastNameId-a11y").focus();
        } else if (this.errors["returnCompany"]) {
          document.getElementById("returnCompanyNameId-a11y").focus();
        } else if (this.errors["returnStreetAddr"]) {
          document.getElementById("returnStreetAddressId-a11y").focus();
        } else if (this.errors["returnAptSuiteOther"]) {
          document.getElementById("returnLine2AddrId-a11y").focus();
        } else if (this.errors["returnCity"]) {
          document.getElementById("returnCityNameId-a11y").focus();
        } else if (this.errors["returnState"]) {
          document.getElementById("returnStateId-a11y").focus();
        } else if (this.errors["returnZipCode"]) {
          document.getElementById("returnPostalCodeId-a11y").focus();
        } else if (this.errors["trackingEmailNotifications"]) {
          document.getElementById("step-one-email-input-field-a11y").focus();
        } else if (this.errors["trackingTextNotifications"]) {
          document
            .getElementById("step-one-text-message-input-field-a11y")
            .focus();
        } else if (this.errors["shipFromOtherZip"]) {
          document.getElementById("shipFromOtherZipId-a11y").focus();
        }
      } else if (this.step2ErrorFound) {
        // Step 2 - Delivery Address
        document.getElementById("mailing-address").scrollIntoView();
        if (document.getElementById("deliveryFirstName-a11y")) {
          // Make sure this is the non-batch flow
          if (this.errors["deliveryFirstName"]) {
            document.getElementById("deliveryFirstName-a11y").focus();
          } else if (this.errors["deliveryLastName"]) {
            document.getElementById("deliveryLastName-a11y").focus();
          } else if (this.errors["deliveryCompany"]) {
            document.getElementById("deliveryCompany-a11y").focus();
          } else if (this.errors["deliveryStreetAddr"]) {
            document
              .getElementById(
                (this.props.isDomesticDestination()
                  ? "streetAddress"
                  : "intDeliveryAddress1") + "-a11y"
              )
              .focus();
          } else if (this.errors["deliveryAptSuiteOther"]) {
            document
              .getElementById(
                (this.props.isDomesticDestination()
                  ? "app_suite_other"
                  : "intDeliveryAddress2") + "-a11y"
              )
              .focus();
          } else if (this.errors["deliveryAddress3"]) {
            document.getElementById("intDeliveryAddress3-a11y").focus();
          } else if (this.errors["deliveryCity"]) {
            document
              .getElementById(
                (this.props.isDomesticDestination()
                  ? "deliveryCity"
                  : "intCity") + "-a11y"
              )
              .focus();
          } else if (this.errors["deliveryState"]) {
            document.getElementById("deliveryState-a11y").focus();
          } else if (this.errors["deliveryZip"]) {
            document
              .getElementById(
                (this.props.isDomesticDestination()
                  ? "zipCodeUs"
                  : "postalCode") + "-a11y"
              )
              .focus();
          } else if (this.errors["sendRecipientEmail"]) {
            document.getElementById("send-not-email-a11y").focus();
          } else if (this.errors["holdForPickupAddresseeEmail"]) {
            document
              .getElementById("holdForPickupAddresseeEmailId-a11y")
              .focus();
          } else if (this.errors["holdForPickupAddresseePhone"]) {
            document
              .getElementById("holdForPickupAddresseePhoneId-a11y")
              .focus();
          } else if (this.errors["holdForPickupMeEmail"]) {
            document.getElementById("holdForPickupMeEmailId-a11y").focus();
          } else if (this.errors["holdForPickupMePhone"]) {
            document.getElementById("holdForPickupMePhoneId-a11y").focus();
          }
        }
      } else if (this.step3ErrorFound) {
        // Step 3 - Shipping Date
        document.getElementById("shipping-date").scrollIntoView();
        if (this.errors["shippingDate"]) {
          document.getElementById("input-date-a11y").focus();
        }
      } else if (this.step4ErrorFound) {
        // Step 4 - Package Details
        document.getElementById("package-details").scrollIntoView();
        if (this.errors["shippingType"] && this.props.isDomesticDestination()) {
          document.getElementById("step4-flatrate-radio-a11y").focus();
        } else if (this.errors["pounds"]) {
          document.getElementById("pounds-input-a11y").focus();
        } else if (this.errors["length"]) {
          document.getElementById("pkgLengthQtyId-a11y").focus();
        } else if (this.errors["width"]) {
          document.getElementById("pkgWidthQtyId-a11y").focus();
        } else if (this.errors["height"]) {
          document.getElementById("pkgHeightQtyId-a11y").focus();
        } else if (this.errors["girth"]) {
          document.getElementById("pkgGirthQtyId-a11y").focus();
        } else if (this.errors["value"]) {
          document.getElementById("pkgValueAmtId-a11y").focus();
        } else if (this.errors["hazmatRadio"]) {
          document.getElementById("hazmatRadio-a11y").focus();
        } else if (this.errors["hazmat"]) {
          document.getElementById("hazmat-a11y").focus();
        }
      } else if (this.step5ErrorFound) {
        // Step 5 - Service Type
        document.getElementById("service-type-id").scrollIntoView();
        if (this.errors["service"]) {
          document
            .getElementById(
              (this.props.isDomesticDestination()
                ? "service-type-dropdown-usa-id"
                : "service-type-dropdown-int-id") + "-a11y"
            )
            .focus();
        }
      }
      // Reset fields
      this.step1ErrorFound = false;
      this.step2ErrorFound = false;
      this.step3ErrorFound = false;
      this.step4ErrorFound = false;
      this.step5ErrorFound = false;
      this.setState({
        focusOnError: false
      });
    }
    if (this.state.focusAfterResponse) {
      // Focus on the top new element after a successful fetch services call
      // (therefore, choosing to edit a label from the cart causes this
      // section to be automatically focused)
      document.getElementById("step5focus").focus();
      this.setState({
        focusAfterResponse: false
      });
    }
    if (this.state.focusOnDropOffLocations) {
      // Focus on the drop off locations button
      // (only upon selecting PME after a fetch services call)
      if (
        this.state.showResponse &&
        this.state.serviceShow === "Priority Mail Express"
      ) {
        document.getElementById("pme-drop-off-modal").focus({
          preventScroll: true
        });
      }
      this.setState({
        focusOnDropOffLocations: false
      });
    }
    // Toggles stealthPostageInd based on account type, service, stealth postage preferences
    if (
      // If both of the following:
      // - the label is brand new, or was added to the cart and then modified
      // - the services have just been fetched
      (!this.props.isEditLabel &&
        this.state.showResponse &&
        this.state.showResponse !== prevState.showResponse) ||
      // Or if the user manually changes the selected service type
      // either from the dropdown or list of tabs
      // (regardless of whether the label is brand new)
      this.state.shouldCheckStealthPostagePreference
    ) {
      this.setState({
        shouldCheckStealthPostagePreference: false
      });
      this.checkStealthPostagePreference();
    }
  }

  checkStealthPostagePreference = () => {
    // Get the current product class
    let currentProductClass;
    if (this.state.serviceShow === "Priority Mail") {
      currentProductClass = "PRI";
    } else if (this.state.serviceShow === "Priority Mail Express") {
      currentProductClass = "EXM";
    } else if (this.state.serviceShow === "USPS Retail Ground") {
      currentProductClass = "RG";
    } else if (
      this.state.serviceShow === "First-Class Package Service - Retail"
    ) {
      currentProductClass = "FC";
    } else if (this.state.serviceShow === "Ground Advantage") {
      currentProductClass = "GA";
    } else if (this.state.serviceShow === "USPS Connect Local") {
      currentProductClass = "CL";
    } else if (this.state.serviceShow === "USPS Connect Local Mail") {
      currentProductClass = "CLM";
    } else {
      currentProductClass = "";
    }
    // Set the state of the hide postage price checkbox based on
    // customs form status, lookup code status, user eligibility,
    // and user preference
    let stealthPostagePreference;
    if (
      // Hiding the postage price is only allowed for domestic,
      // non-customs form labels
      (!this.state.customsRequiredServiceTypes ||
        !this.state.customsRequiredServiceTypes.includes(
          currentProductClass
        )) &&
      // The lookup code for the selected service type must allow hiding the
      // postage price
      hidePostagePriceUtils.isServiceTypeEligible(
        this.props.configInfo.userData,
        currentProductClass,
        this.props.lookupCodes
      ) &&
      // Check if the user has either selected an address from GAB
      // or a service type that is set in the user's preferences
      // to hide the postage price
      (this.props.configInfo.usingHidePostagePriceAddress ||
        hidePostagePriceUtils.isPreferenceSet(
          currentProductClass,
          this.props.userPreferences
        ))
    ) {
      stealthPostagePreference = "Y";
    } else {
      stealthPostagePreference = "N";
    }
    this.props.changeTrackingNotifications("stealthPostageInd")(
      stealthPostagePreference
    );
  };

  setService = (event, innerText) => {
    event.preventDefault();
    // Reset the service (package type)
    this.props.setPackageTypeProductId("");
    this.setState({
      serviceShow: innerText
        .replace("&reg;", "")
        .replace("®", "")
        .replace("&trade;", "")
        .replace("™", "")
        .trim(),
      serviceShowWithSymbols: innerText
        .replace("&reg;", "®")
        .replace("&trade;", "™"),
      sortPackageTypeAsc: null,
      sortPriceAsc: null,
      sortedServices: undefined,
      serviceInfoIconId: undefined,
      focusOnDropOffLocations: true,
      shouldCheckStealthPostagePreference: true
    });
    // Clear service error
    if (this.props.configInfo.errors.service !== "") {
      this.props.clearError("service");
    } else {
      this.props.clearError("");
    }
  };

  toggle = () => {
    this.setState((prevState) => {
      return {
        dropdownOpen: !prevState.dropdownOpen
      };
    });
  };

  select = (event) => {
    if (
      event.currentTarget.innerText.indexOf(
        "(Same-Day or Next-Day Local Shipments)"
      ) > -1
    ) {
      event.currentTarget.innerText = event.currentTarget.innerText.slice(
        0,
        event.currentTarget.innerText.indexOf(
          "(Same-Day or Next-Day Local Shipments)"
        )
      );
    }
    this.setService(event, event.currentTarget.innerText);
  };

  sortColumns = (e, columnName) => {
    e.preventDefault();
    this.setState((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState.listOfServices));
      let sortPackageTypeAsc = prevState.sortPackageTypeAsc;
      let sortPriceAsc = prevState.sortPriceAsc;
      if (columnName === "packageType" && !sortPackageTypeAsc) {
        // Sort the products with the selected service type by package type
        // (in ascending order)
        sortPackageTypeAsc = true;
        sortPriceAsc = null;
        if (prevState.serviceShow === "Priority Mail") {
          newState.data.productServiceViewBean.productsByServiceType.PRI.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        } else if (prevState.serviceShow === "Priority Mail Express") {
          newState.data.productServiceViewBean.productsByServiceType.EXM.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        } else if (prevState.serviceShow === "USPS Retail Ground") {
          newState.data.productServiceViewBean.productsByServiceType.RG.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        } else if (
          prevState.serviceShow === "First-Class Package Service - Retail"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.FC.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        } else if (prevState.serviceShow === "Priority Mail International") {
          newState.data.productServiceViewBean.productsByServiceType.IPM.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        } else if (
          prevState.serviceShow === "Priority Mail Express International"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.IEM.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        } else if (
          prevState.serviceShow === "First-Class Package International Service"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.IFC.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        } else if (prevState.serviceShow === "Global Express Guaranteed") {
          newState.data.productServiceViewBean.productsByServiceType.GXG.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        } else if (prevState.serviceShow === "USPS Connect Local") {
          newState.data.productServiceViewBean.productsByServiceType.CL.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        } else if (prevState.serviceShow === "USPS Connect Local Mail") {
          newState.data.productServiceViewBean.productsByServiceType.CLM.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        } else if (prevState.serviceShow === "Ground Advantage") {
          newState.data.productServiceViewBean.productsByServiceType.GA.sort(
            (a, b) => a.productNameDisplay.localeCompare(b.productNameDisplay)
          );
        }
      } else if (columnName === "packageType" && sortPackageTypeAsc) {
        // Sort the products with the selected service type by package type
        // (in descending order)
        sortPackageTypeAsc = false;
        sortPriceAsc = null;
        if (prevState.serviceShow === "Priority Mail") {
          newState.data.productServiceViewBean.productsByServiceType.PRI.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        } else if (prevState.serviceShow === "Priority Mail Express") {
          newState.data.productServiceViewBean.productsByServiceType.EXM.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        } else if (prevState.serviceShow === "USPS Retail Ground") {
          newState.data.productServiceViewBean.productsByServiceType.RG.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        } else if (
          prevState.serviceShow === "First-Class Package Service - Retail"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.FC.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        } else if (prevState.serviceShow === "Priority Mail International") {
          newState.data.productServiceViewBean.productsByServiceType.IPM.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        } else if (
          prevState.serviceShow === "Priority Mail Express International"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.IEM.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        } else if (
          prevState.serviceShow === "First-Class Package International Service"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.IFC.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        } else if (prevState.serviceShow === "Global Express Guaranteed") {
          newState.data.productServiceViewBean.productsByServiceType.GXG.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        } else if (prevState.serviceShow === "USPS Connect Local") {
          newState.data.productServiceViewBean.productsByServiceType.CL.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        } else if (prevState.serviceShow === "USPS Connect Local Mail") {
          newState.data.productServiceViewBean.productsByServiceType.CLM.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        } else if (prevState.serviceShow === "Ground Advantage") {
          newState.data.productServiceViewBean.productsByServiceType.GA.sort(
            (a, b) => b.productNameDisplay.localeCompare(a.productNameDisplay)
          );
        }
      } else if (columnName === "price" && !sortPriceAsc) {
        // Sort the products with the selected service type by price
        // (in ascending order)
        sortPackageTypeAsc = null;
        sortPriceAsc = true;
        if (prevState.serviceShow === "Priority Mail") {
          newState.data.productServiceViewBean.productsByServiceType.PRI.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        } else if (prevState.serviceShow === "Priority Mail Express") {
          newState.data.productServiceViewBean.productsByServiceType.EXM.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        } else if (prevState.serviceShow === "USPS Retail Ground") {
          newState.data.productServiceViewBean.productsByServiceType.RG.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        } else if (
          prevState.serviceShow === "First-Class Package Service - Retail"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.FC.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        } else if (prevState.serviceShow === "Priority Mail International") {
          newState.data.productServiceViewBean.productsByServiceType.IPM.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        } else if (
          prevState.serviceShow === "Priority Mail Express International"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.IEM.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        } else if (
          prevState.serviceShow === "First-Class Package International Service"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.IFC.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        } else if (prevState.serviceShow === "Global Express Guaranteed") {
          newState.data.productServiceViewBean.productsByServiceType.GXG.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        } else if (prevState.serviceShow === "USPS Connect Local") {
          newState.data.productServiceViewBean.productsByServiceType.CL.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        } else if (prevState.serviceShow === "USPS Connect Local Mail") {
          newState.data.productServiceViewBean.productsByServiceType.CLM.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        } else if (prevState.serviceShow === "Ground Advantage") {
          newState.data.productServiceViewBean.productsByServiceType.GA.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
        }
      } else if (columnName === "price" && sortPriceAsc) {
        // Sort the products with the selected service type by price
        // (in descending order)
        sortPackageTypeAsc = null;
        sortPriceAsc = false;
        if (prevState.serviceShow === "Priority Mail") {
          newState.data.productServiceViewBean.productsByServiceType.PRI.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        } else if (prevState.serviceShow === "Priority Mail Express") {
          newState.data.productServiceViewBean.productsByServiceType.EXM.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        } else if (prevState.serviceShow === "USPS Retail Ground") {
          newState.data.productServiceViewBean.productsByServiceType.RG.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        } else if (
          prevState.serviceShow === "First-Class Package Service - Retail"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.FC.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        } else if (prevState.serviceShow === "Priority Mail International") {
          newState.data.productServiceViewBean.productsByServiceType.IPM.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        } else if (
          prevState.serviceShow === "Priority Mail Express International"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.IEM.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        } else if (
          prevState.serviceShow === "First-Class Package International Service"
        ) {
          newState.data.productServiceViewBean.productsByServiceType.IFC.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        } else if (prevState.serviceShow === "Global Express Guaranteed") {
          newState.data.productServiceViewBean.productsByServiceType.GXG.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        } else if (prevState.serviceShow === "USPS Connect Local") {
          newState.data.productServiceViewBean.productsByServiceType.CL.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        } else if (prevState.serviceShow === "USPS Connect Local Mail") {
          newState.data.productServiceViewBean.productsByServiceType.CLM.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        } else if (prevState.serviceShow === "Ground Advantage") {
          newState.data.productServiceViewBean.productsByServiceType.GA.sort(
            (a, b) => parseFloat(b.price) - parseFloat(a.price)
          );
        }
      }

      return {
        sortedServices: newState,
        sortPackageTypeAsc: sortPackageTypeAsc,
        sortPriceAsc: sortPriceAsc,
        serviceInfoIconId: undefined
      };
    });
  };

  setServiceInfoIconId = (id, serviceIdClosed) => {
    if (serviceIdClosed) {
      // Only defined if a popover is being closed
      this.serviceIconRefs[serviceIdClosed].current.focus();
    }
    this.setState({
      serviceInfoIconId: id
    });
  };

  addServiceIconRef = (productId) => {
    this.serviceIconRefs[productId] = React.createRef();
  };

  isEligibleForParcelSelectGround = () => {
    return (
      this.props.lookupCodes &&
      this.props.lookupCodes["parcel_select_ground_on"] === "true" &&
      // this.props.configInfo.userData.parcelSelectGround &&
      this.props.configInfo.userData.accountType === "Business" &&
      this.props.isDomesticDestination()
    );
  };

  render() {
    return (
      <div className="Step_Five_Container" id="service-type-id">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2 className="normal">
                Step 5: <strong> Service Type</strong>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7">
              {this.props.isInUspsConnectFlow() &&
              this.props.selectedDropOffLocationIndex ? (
                <p className="service-type-info">
                  This package is eligible for{" "}
                  <b>
                    USPS Connect™ Local shipping which gives you same-day or
                    next-day delivery on local shipments.
                  </b>{" "}
                  Select the USPS Connect™ Local service type from the drop-down
                  menu to learn more.
                </p>
              ) : null}
              <p
                className="service-type-info"
                style={{
                  paddingTop:
                    this.props.configInfo.userData.parcelSelectFlag &&
                    this.props.selectedDropOffLocationIndex
                      ? "10px"
                      : ""
                }}
              >
                Selecting a Priority Mail Flat Rate<sup>®</sup> product or a
                Priority Mail Express<sup>®</sup> Flat Rate product requires
                USPS<sup>®</sup> provided packaging.
              </p>
              <p className="click-ship-post-office-link">
                If you plan to ship live animals, please go to your local{" "}
                <a
                  href="https://tools.usps.com/go/POLocatorAction!input.action"
                  className="inline-link right-chevron"
                >
                  Post Office<sup>™</sup>
                </a>
              </p>
            </div>
          </div>
          <div>
            <div
              className={
                "click-step-five-input-container" +
                (this.state.showResponse ? " d-md-none" : "")
              }
            >
              <div className="row">
                <div
                  className={
                    "col-12 col-md-6 col-lg-5 form-group required-field service-type" +
                    (this.props.configInfo.errors.service !== ""
                      ? " error"
                      : "")
                  }
                >
                  <AssistiveError
                    inputId={
                      this.props.isDomesticDestination()
                        ? "service-type-dropdown-usa-id"
                        : "service-type-dropdown-int-id"
                    }
                    errorMessage={this.props.configInfo.errors.service}
                  />
                  <label
                    htmlFor={
                      this.props.isDomesticDestination()
                        ? "service-type-dropdown-usa-id"
                        : "service-type-dropdown-int-id"
                    }
                  >
                    *Service
                  </label>
                  {this.props.isDomesticDestination() ? (
                    // Domestic destination
                    <Dropdown
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggle}
                      value={this.state.serviceShow}
                      className={"dropdown-selection"}
                    >
                      <DropdownToggle
                        id="service-type-dropdown-usa-id"
                        className="btn btn-default dropdown-items-wrapper"
                      >
                        {this.state.serviceShowWithSymbols}
                      </DropdownToggle>
                      <DropdownMenu>
                        <li>
                          <DropdownItem onClick={(e) => this.select(e)}>
                            Select
                          </DropdownItem>
                        </li>
                        {this.props.selectedDropOffLocationIndex ? (
                          <li>
                            <DropdownItem
                              onClick={(e) => this.select(e)}
                              className="dropdownLI"
                            >
                              <b>USPS Connect™ Local</b>{" "}
                              <span className="dropdownSameOrNextDayShipping">
                                (Same-Day or Next-Day Local Shipments)
                              </span>
                            </DropdownItem>
                          </li>
                        ) : null}
                        {this.props.selectedDropOffLocationIndex ? (
                          <li>
                            <DropdownItem
                              onClick={(e) => this.select(e)}
                              className="dropdownLI"
                            >
                              <b>USPS Connect™ Local Mail</b>{" "}
                              <span className="dropdownSameOrNextDayShipping">
                                (Same-Day or Next-Day Local Shipments)
                              </span>
                            </DropdownItem>
                          </li>
                        ) : null}
                        <li>
                          <DropdownItem onClick={(e) => this.select(e)}>
                            Priority Mail<sup>®</sup>
                          </DropdownItem>{" "}
                        </li>
                        <li>
                          <DropdownItem onClick={(e) => this.select(e)}>
                            {" "}
                            Priority Mail Express
                            <sup>®</sup>
                          </DropdownItem>
                        </li>
                        {this.props.isDomesticDestination() ? (
                          <li>
                            <DropdownItem onClick={(e) => this.select(e)}>
                              Ground Advantage<sup>™</sup>
                            </DropdownItem>
                          </li>
                        ) : null}
                        {/* {this.props.configInfo.returnAddress.stateId === "2" ||
                        this.props.configInfo.returnAddress.stateId === "3" ||
                        this.props.configInfo.returnAddress.stateId === "6" ? (
                          <li>
                            <DropdownItem onClick={(e) => this.select(e)}>
                              {" "}
                              USPS Retail Ground<sup>®</sup>
                            </DropdownItem>
                          </li>
                        ) : (
                          <div />
                        )} */}
                        {/* {this.props.configInfo.userData.accountType ===
                        "Business" ? (
                          <li>
                            <DropdownItem onClick={(e) => this.select(e)}>
                              {" "}
                              First-Class Package Service - Retail<sup>®</sup>
                            </DropdownItem>
                          </li>
                        ) : null} */}
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    // International destination
                    <Dropdown
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggle}
                      value={this.state.serviceShow}
                      className={"dropdown-selection"}
                    >
                      <DropdownToggle
                        id="service-type-dropdown-int-id"
                        className="btn btn-default dropdown-items-wrapper"
                      >
                        {this.state.serviceShowWithSymbols}
                      </DropdownToggle>
                      <DropdownMenu>
                        <li>
                          <DropdownItem onClick={(e) => this.select(e)}>
                            Select
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem onClick={(e) => this.select(e)}>
                            Priority Mail<sup>®</sup> International
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem onClick={(e) => this.select(e)}>
                            {" "}
                            Priority Mail Express<sup>®</sup> International
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem onClick={(e) => this.select(e)}>
                            First-Class Package International Service
                            <sup>®</sup>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem onClick={(e) => this.select(e)}>
                            Global Express Guaranteed<sup>®</sup>
                          </DropdownItem>
                        </li>
                      </DropdownMenu>
                    </Dropdown>
                  )}

                  <span className="error-message">
                    {this.props.configInfo.errors.service}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 buttons-holder">
                <div className="button-container">
                  <button
                    type="button"
                    className={
                      "btn-primary select-service-packaging-btn" +
                      (this.state.showResponse ? " d-none" : "")
                    }
                    onClick={this.fetchServices}
                  >
                    Select Service and Packaging
                  </button>
                </div>
              </div>
            </div>
          </div>
          {this.state.showResponse ? (
            // After fetching services
            <React.Fragment>
              <div
                id="step5focus"
                className="row step-five-display-items"
                tabIndex="-1"
              >
                <div className="col-12">
                  {this.props.isWeightRequired() ? (
                    <p className="prices-based-zip">
                      Prices are based on shipping a{" "}
                      {this.props.configInfo.labelPkg.weightOZQtyPound > 0
                        ? this.props.configInfo.labelPkg.weightOZQtyPound +
                          " lbs "
                        : ""}
                      {this.props.configInfo.labelPkg.weightOZQtyOunces > 0
                        ? this.props.configInfo.labelPkg.weightOZQtyOunces +
                          " oz "
                        : ""}
                      package from ZIP Code<sup>™</sup>{" "}
                      <strong>
                        {this.props.configInfo.labelInd.shipFromOtherZipInd ===
                          "Y" && this.props.configInfo.shipFromPostalCode
                          ? this.props.configInfo.shipFromPostalCode
                          : this.props.configInfo.returnAddress.postalCode}
                      </strong>{" "}
                      to{" "}
                      <strong>
                        {!this.props.isDomesticDestination() ||
                        !this.props.configInfo.deliveryAddress.postalCode
                          ? this.props.configInfo.deliveryAddress.countryName
                          : this.props.configInfo.deliveryAddress.postalCode}
                      </strong>{" "}
                      on{" "}
                      <strong>
                        {Moment(this.props.configInfo.shippingDate).format(
                          "MM/DD/YYYY"
                        )}
                      </strong>
                      .
                    </p>
                  ) : (
                    <p className="prices-based-zip">
                      Prices are based on shipping a Flat Rate package from ZIP
                      Code<sup>™</sup>{" "}
                      <strong>
                        {this.props.configInfo.labelInd.shipFromOtherZipInd ===
                          "Y" && this.props.configInfo.shipFromPostalCode
                          ? this.props.configInfo.shipFromPostalCode
                          : this.props.configInfo.returnAddress.postalCode}
                      </strong>{" "}
                      to{" "}
                      <strong>
                        {!this.props.isDomesticDestination() ||
                        !this.props.configInfo.deliveryAddress.postalCode
                          ? this.props.configInfo.deliveryAddress.countryName
                          : this.props.configInfo.deliveryAddress.postalCode}
                      </strong>{" "}
                      on{" "}
                      <strong>
                        {Moment(this.props.configInfo.shippingDate).format(
                          "MM/DD/YYYY"
                        )}
                      </strong>
                      .
                    </p>
                  )}
                  {this.state.serviceShow === "Priority Mail Express" ? (
                    <p className="priority-express">
                      <DropOffLocations
                        buttonLabel={
                          "Priority Mail Express® Drop-Off Locations"
                        }
                        targetName={"pme-drop-off-modal"}
                        expressMailDropOffLocations={
                          this.state.expressMailDropOffLocations
                        }
                      />
                    </p>
                  ) : (
                    <div />
                  )}
                  <div className="select-service-wrapper">
                    <strong className="select-service-text">
                      Select a Service:{" "}
                    </strong>
                    {this.props.isDomesticDestination() ? (
                      // Domestic destination
                      <ul
                        className="select-service-tabs domestic-tabs"
                        style={{ display: "inline - block" }}
                      >
                        <li>
                          <button
                            type="button"
                            className={
                              "tab-link button-link" +
                              (this.state.serviceShow === "Priority Mail"
                                ? " active"
                                : "")
                            }
                            data-value="priority"
                            onClick={(e) =>
                              this.setService(
                                e,
                                e.currentTarget.lastChild.textContent
                              )
                            }
                          >
                            {this.state.serviceShow === "Priority Mail" ? (
                              <span className="visuallyhidden">
                                Currently selected service
                              </span>
                            ) : null}
                            Priority Mail®
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className={
                              "tab-link button-link" +
                              (this.state.serviceShow ===
                              "Priority Mail Express"
                                ? " active"
                                : "")
                            }
                            data-value="priority-express"
                            onClick={(e) =>
                              this.setService(
                                e,
                                e.currentTarget.lastChild.textContent
                              )
                            }
                          >
                            {this.state.serviceShow ===
                            "Priority Mail Express" ? (
                              <span className="visuallyhidden">
                                Currently selected service
                              </span>
                            ) : null}
                            Priority Mail Express®
                          </button>
                        </li>
                        {this.props.isDomesticDestination() ? (
                          <li>
                            <button
                              type="button"
                              className={
                                "tab-link button-link" +
                                (this.state.serviceShow === "Ground Advantage"
                                  ? " active"
                                  : "")
                              }
                              data-value="first-class"
                              onClick={(e) =>
                                this.setService(
                                  e,
                                  e.currentTarget.lastChild.textContent
                                )
                              }
                            >
                              {this.state.serviceShow === "Ground Advantage" ? (
                                <span className="visuallyhidden">
                                  Currently selected service
                                </span>
                              ) : null}
                              Ground Advantage™
                            </button>
                          </li>
                        ) : null}
                        {this.props.configInfo.returnAddress.stateId === "2" ||
                        this.props.configInfo.returnAddress.stateId === "3" ||
                        this.props.configInfo.returnAddress.stateId === "6" ||
                        this.state.listOfServices.data.productServiceViewBean.serviceTypes.indexOf(
                          "RG"
                        ) > -1 ? (
                          <li>
                            <button
                              type="button"
                              className={
                                "tab-link button-link" +
                                (this.state.serviceShow === "USPS Retail Ground"
                                  ? " active"
                                  : "")
                              }
                              data-value="retail-ground"
                              onClick={(e) =>
                                this.setService(
                                  e,
                                  e.currentTarget.lastChild.textContent
                                )
                              }
                            >
                              {this.state.serviceShow ===
                              "USPS Retail Ground" ? (
                                <span className="visuallyhidden">
                                  Currently selected service
                                </span>
                              ) : null}
                              USPS Retail Ground®
                            </button>
                          </li>
                        ) : null}
                        {this.props.configInfo.userData.accountType ===
                        "Business" ? (
                          <li>
                            {/* <button
                              type="button"
                              className={
                                "tab-link button-link" +
                                (this.state.serviceShow ===
                                "First-Class Package Service - Retail"
                                  ? " active"
                                  : "")
                              }
                              data-value="first-class"
                              onClick={(e) =>
                                this.setService(
                                  e,
                                  e.currentTarget.lastChild.textContent
                                )
                              }
                            >
                              {this.state.serviceShow ===
                              "First-Class Package Service - Retail" ? (
                                <span className="visuallyhidden">
                                  Currently selected service
                                </span>
                              ) : null}
                              First-Class Package Service - Retail®
                            </button> */}
                          </li>
                        ) : null}
                        {this.props.dropOffLocations &&
                        this.props.selectedDropOffLocationIndex ? (
                          <li>
                            <button
                              type="button"
                              className={
                                "tab-link button-link" +
                                (this.state.serviceShow === "USPS Connect Local"
                                  ? " active"
                                  : "")
                              }
                              data-value="parcelSelect"
                              onClick={(e) =>
                                this.setService(
                                  e,
                                  e.currentTarget.lastChild.textContent
                                )
                              }
                            >
                              {this.state.serviceShow ===
                              "USPS Connect Local" ? (
                                <span className="visuallyhidden">
                                  Currently selected service
                                </span>
                              ) : null}
                              USPS Connect™ Local
                            </button>
                          </li>
                        ) : null}
                        {this.props.dropOffLocations &&
                        this.props.selectedDropOffLocationIndex ? (
                          <li>
                            <button
                              type="button"
                              className={
                                "tab-link button-link" +
                                (this.state.serviceShow ===
                                "USPS Connect Local Mail"
                                  ? " active"
                                  : "")
                              }
                              data-value="connectLocalMail"
                              onClick={(e) =>
                                this.setService(
                                  e,
                                  e.currentTarget.lastChild.textContent
                                )
                              }
                            >
                              {this.state.serviceShow ===
                              "USPS Connect Local Mail" ? (
                                <span className="visuallyhidden">
                                  Currently selected service
                                </span>
                              ) : null}
                              USPS Connect™ Local Mail
                            </button>
                          </li>
                        ) : null}
                      </ul>
                    ) : (
                      // International destination
                      <ul
                        className="select-service-tabs international-tabs"
                        style={{ display: "inline-block" }}
                      >
                        <li>
                          <button
                            type="button"
                            className={
                              "tab-link button-link" +
                              (this.state.serviceShow ===
                              "Priority Mail International"
                                ? " active"
                                : "")
                            }
                            data-value="priority-international"
                            onClick={(e) =>
                              this.setService(
                                e,
                                e.currentTarget.lastChild.textContent
                              )
                            }
                          >
                            {this.state.serviceShow ===
                            "Priority Mail International" ? (
                              <span className="visuallyhidden">
                                Currently selected service
                              </span>
                            ) : null}
                            Priority Mail® International
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className={
                              "tab-link button-link" +
                              (this.state.serviceShow ===
                              "Priority Mail Express International"
                                ? " active"
                                : "")
                            }
                            data-value="priority-express-international"
                            onClick={(e) =>
                              this.setService(
                                e,
                                e.currentTarget.lastChild.textContent
                              )
                            }
                          >
                            {this.state.serviceShow ===
                            "Priority Mail Express International" ? (
                              <span className="visuallyhidden">
                                Currently selected service
                              </span>
                            ) : null}
                            Priority Mail Express® International
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className={
                              "tab-link button-link" +
                              (this.state.serviceShow ===
                              "First-Class Package International Service"
                                ? " active"
                                : "")
                            }
                            data-value="first-class-international"
                            onClick={(e) =>
                              this.setService(
                                e,
                                e.currentTarget.lastChild.textContent
                              )
                            }
                          >
                            {this.state.serviceShow ===
                            "First-Class Package International Service" ? (
                              <span className="visuallyhidden">
                                Currently selected service
                              </span>
                            ) : null}
                            First-Class Package International Service®
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className={
                              "tab-link button-link" +
                              (this.state.serviceShow ===
                              "Global Express Guaranteed"
                                ? " active"
                                : "")
                            }
                            data-value="gxg"
                            onClick={(e) =>
                              this.setService(
                                e,
                                e.currentTarget.lastChild.textContent
                              )
                            }
                          >
                            {this.state.serviceShow ===
                            "Global Express Guaranteed" ? (
                              <span className="visuallyhidden">
                                Currently selected service
                              </span>
                            ) : null}
                            Global Express Guaranteed®
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="row step-five-domestic">
                  <div className="col-12 col-lg-10">
                    <ul className="priority package-schedule-price-wrapper">
                      <ServiceTypeSelectThePackage
                        listOfServices={
                          this.state.sortedServices !== undefined
                            ? this.state.sortedServices
                            : this.state.listOfServices
                        }
                        serviceShow={this.state.serviceShow}
                        setPackageTypeProductId={
                          this.props.setPackageTypeProductId
                        }
                        configInfo={this.props.configInfo}
                        changeTrackingNotifications={
                          this.props.changeTrackingNotifications
                        }
                        updateCartSize={this.props.updateCartSize}
                        toggleSpinner={this.props.toggleSpinner}
                        changeNonNestedFields={this.props.changeNonNestedFields}
                        selectedService={this.selectedService}
                        sortColumns={this.sortColumns}
                        isDomesticDestination={this.props.isDomesticDestination}
                        serviceInfoIconId={this.state.serviceInfoIconId}
                        setServiceInfoIconId={this.setServiceInfoIconId}
                        isFromCart={this.props.isFromCart}
                        serviceIconRefs={this.serviceIconRefs}
                        addServiceIconRef={this.addServiceIconRef}
                        sortPackageTypeAsc={this.state.sortPackageTypeAsc}
                        sortPriceAsc={this.state.sortPriceAsc}
                        selectedDropOffLocationIndex={
                          this.props.selectedDropOffLocationIndex
                        }
                        dropOffLocations={this.props.dropOffLocations}
                        dropoffLocationData={this.props.dropoffLocationData}
                        hideNonmachinable={this.props.hideNonmachinable}
                        lookupCodes={this.props.lookupCodes}
                        customsRequiredServiceTypes={
                          this.state.customsRequiredServiceTypes
                        }
                        hazmatType={this.props.hazmatType}
                        hazmatYesNo={this.props.hazmatYesNo}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>
        <div className="row">
          <div className="col-12 horizontal-line-container">
            <hr className="horizontal-line" />
          </div>
        </div>
      </div>
    );
  }
}

export default StepFiveServiceType;
