import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const PurposeOfShipmentPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        The customer must also state the purpose of the shipment-for example:
        gift (unsolicited), personal effects, personal use (new, no resale),
        catalog/order return, commercial sale, intra-company transfer, return
        for repair, return after repair, marked samples (no resale), mutilated
        samples (no resale), etc.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Purpose of Shipment Information Icon"
      header="Purpose of Shipment"
      content={content}
    />
  );
};

export default PurposeOfShipmentPopover;
