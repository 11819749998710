const hidePostagePrice = {
  // Determine whether the user should have the option to hide the postage price
  // on a label based on the type of user and the corresponding lookup codes
  isServiceTypeEligible: (userData, currentProductClass, lookupCodes) => {
    if (!userData || !lookupCodes) {
      return false;
    }
    if (userData.accountType === "Business") {
      switch (currentProductClass) {
        case "PRI":
          return lookupCodes["stealth_postage_allowed_business_PM"] === "true";
        case "EXM":
          return lookupCodes["stealth_postage_allowed_business_PME"] === "true";
        case "RG":
          return lookupCodes["stealth_postage_allowed_business_RG"] === "true";
        case "FC":
          return (
            lookupCodes["stealth_postage_allowed_business_FCPS"] === "true"
          );
        case "PSG":
          return lookupCodes["stealth_postage_allowed_business_PSG"] === "true";
        default:
          if (userData.parcelSelectFlag) {
            switch (currentProductClass) {
              case "CL":
                return (
                  lookupCodes["stealth_postage_allowed_business_CL"] === "true"
                );
              case "CLM":
                return (
                  lookupCodes["stealth_postage_allowed_business_CLM"] === "true"
                );
              default:
                // Any other product classes are ineligible
                return false;
            }
          }
          // Any other product classes are ineligible
          return false;
      }
    } else {
      switch (currentProductClass) {
        case "PRI":
          return lookupCodes["stealth_postage_allowed_personal_PM"] === "true";
        case "EXM":
          return lookupCodes["stealth_postage_allowed_personal_PME"] === "true";
        case "RG":
          return lookupCodes["stealth_postage_allowed_personal_RG"] === "true";
        default:
          // Any other product classes are ineligible
          return false;
      }
    }
  },

  // Determine whether the user has a preference set to hide the postage price
  // based on the selected service type
  isPreferenceSet: (currentProductClass, userPreferences) => {
    if (!userPreferences) {
      return false;
    }
    switch (currentProductClass) {
      case "PRI":
        return userPreferences.stealthPostagePMInd === "Y";
      case "EXM":
        return userPreferences.stealthPostagePMEInd === "Y";
      case "RG":
        return userPreferences.stealthPostageRGInd === "Y";
      case "FC":
        return userPreferences.stealthPostageFCPSInd === "Y";
      case "PSG":
        return userPreferences.stealthPostagePSGInd === "Y";
      case "CL":
        return userPreferences.stealthPostageCLInd === "Y";
      case "CLM":
        return userPreferences.stealthPostageCLMInd === "Y";
      default:
        // Any other product classes are ineligible
        return false;
    }
  },

  // Check if at least one of the service types eligible for the user
  // allows hiding the postage price based on lookup codes
  areLookupCodesActive: (userData, lookupCodes) => {
    if (!userData || !lookupCodes) {
      return false;
    }
    if (userData.accountType === "Business") {
      if (
        lookupCodes["stealth_postage_allowed_business_PM"] === "true" ||
        lookupCodes["stealth_postage_allowed_business_PME"] === "true" ||
        lookupCodes["stealth_postage_allowed_business_RG"] === "true" ||
        lookupCodes["stealth_postage_allowed_business_FCPS"] === "true" ||
        lookupCodes["stealth_postage_allowed_business_PSG"] === "true"
      ) {
        return true;
      }
      if (userData.parcelSelectFlag) {
        if (
          lookupCodes["stealth_postage_allowed_business_CL"] === "true" ||
          lookupCodes["stealth_postage_allowed_business_CLM"] === "true"
        ) {
          return true;
        }
      }
      return false;
    } else {
      return (
        lookupCodes["stealth_postage_allowed_personal_PM"] === "true" ||
        lookupCodes["stealth_postage_allowed_personal_PME"] === "true" ||
        lookupCodes["stealth_postage_allowed_personal_RG"] === "true"
      );
    }
  }
};

export default hidePostagePrice;
