import React, { useState } from "react";
import { Modal } from "reactstrap";

const DropOffLocations = (props) => {
  const [modal, setModal] = useState(false);

  const { buttonLabel, targetName, expressMailDropOffLocations } = props;

  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="inline-link right-chevron button-link"
        data-toggle="modal"
        data-target="#pme-drop-off-modal"
        data-backdrop="static"
        id={targetName}
        onClick={toggle}
      >
        {buttonLabel}
      </button>
      <Modal isOpen={modal} toggle={toggle} className="modal-lg">
        <div className="modal-content modal-container">
          <div className="modal-header">
            <button
              type="button"
              className="close button-link"
              id="close"
              data-dismiss="modal"
              tabIndex="0"
              onClick={toggle}
            >
              <span className="visuallyhidden">Close Modal</span>
            </button>
            <h3 className="modal-title">
              Priority Mail Express<sup>&reg;</sup> Drop-Off Locations
            </h3>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <ul className="click-ship-address-box-container">
                {expressMailDropOffLocations.dropoffLocations.map(
                  (location) => {
                    return (
                      <li
                        key={Math.floor(Math.random() * 10000000) + 1}
                        className="click-ship-individual-address"
                      >
                        <div className="row individual-address-content-wrapper">
                          <div className="col-12 col-md-8">
                            <p className="pme-drop-off-location">
                              {location.facility}
                            </p>
                            <p className="pme-drop-off-address-detail">
                              {location.address} <br className="d-md-none" />
                              {location.city +
                                ", " +
                                location.state +
                                " " +
                                location.zip}
                            </p>
                          </div>
                          <div className="col-12 col-md-4">
                            <p className="pme-drop-off-service-commitment">
                              Service Commitment
                            </p>
                            <p className="pme-drop-off-service-commitment-info">
                              Next Day By {location.commitmentTime}
                            </p>
                            <p className="pme-drop-off-by">Drop-off By</p>
                            <p className="pme-drop-off-by-info">
                              {location.cutoff}
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default DropOffLocations;
