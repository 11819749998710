import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const CountryUltimateDestinationPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Enter final country of destination for goods in this shipment. This can
        be different than the recipient's country.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Country Ultimate Destination Information Icon"
      header="Country of Ultimate Destination"
      content={content}
    />
  );
};

export default CountryUltimateDestinationPopover;
