import connectLocalFlatRateBox from "../../click-n-ship/images/freeSupplies/connectLocalFlatRateBox.png";
import connectLocalSmallPolyBag from "../../click-n-ship/images/freeSupplies/connectLocalSmallPolyBag.png";
import connectLocalLargePolyBag from "../../click-n-ship/images/freeSupplies/connectLocalLargePolyBag.png";
import connectLocalTyvekLargeTwoWayBag from "../../click-n-ship/images/freeSupplies/connectLocalTyvekLargeTwoWayBag.jpg";
import connectLocalPadOfLabels from "../../click-n-ship/images/freeSupplies/connectLocalPadOfLabels.png";
import connectLocalRollOfLabels from "../../click-n-ship/images/freeSupplies/connectLocalRollOfLabels.png";
import connectLocalRollOfTape from "../../click-n-ship/images/freeSupplies/connectLocalRollOfTape.png";
import connectLocalMailFlatRateEnvelope from "../../click-n-ship/images/freeSupplies/connectLocalMailFlatRateEnvelope.jpg";
import connectLocalMailLegalFlatRateEnvelope from "../../click-n-ship/images/freeSupplies/connectLocalMailLegalFlatRateEnvelope.jpg";
import connectLocalMailRollOfLabels from "../../click-n-ship/images/freeSupplies/connectLocalMailRollOfLabels.png";

const FREE_SUPPLIES_DATA = [
  {
    productId: "525",
    imagePath: connectLocalFlatRateBox,
    imageAlt: "Image of a Connect Local Flat Rate Box",
    productClassDesc: "USPS Connect™ Local",
    productDesc: "Flat Rate Box (Pack of 25)",
    boxDimensions: '12" x 3-1/2" x 14-1/8"',
    multiplier: 25,
    selectedQuantity: 0
  },
  {
    productId: "526",
    imagePath: connectLocalFlatRateBox,
    imageAlt: "Image of a Connect Local Flat Rate Box",
    productClassDesc: "USPS Connect™ Local",
    productDesc: "Flat Rate Box (Pack of 10)",
    boxDimensions: '12" x 3-1/2" x 14-1/8"',
    multiplier: 10,
    selectedQuantity: 0
  },
  {
    productId: "527",
    imagePath: connectLocalFlatRateBox,
    imageAlt: "Image of a Connect Local Flat Rate Box",
    productClassDesc: "USPS Connect™ Local",
    productDesc: "Flat Rate Box (Pack of 5)",
    boxDimensions: '12" x 3-1/2" x 14-1/8"',
    multiplier: 5,
    selectedQuantity: 0
  },
  {
    productId: "528",
    imagePath: connectLocalSmallPolyBag,
    imageAlt: "Image of a Connect Local Flat Rate Small Poly Bag",
    productClassDesc: "USPS Connect™ Local",
    productDesc: "Flat Rate Small Poly Bag (Pack of 10)",
    boxDimensions: '9" x 12"',
    multiplier: 10,
    selectedQuantity: 0
  },
  {
    productId: "529",
    imagePath: connectLocalLargePolyBag,
    imageAlt: "Image of a Connect Local Flat Rate Large Poly Bag",
    productClassDesc: "USPS Connect™ Local",
    productDesc: "Flat Rate Large Poly Bag (Pack of 10)",
    boxDimensions: '14" x 17"',
    multiplier: 10,
    selectedQuantity: 0
  },
  {
    productId: "530",
    imagePath: connectLocalTyvekLargeTwoWayBag,
    imageAlt: "Image of a Connect Local Reusable Large Tyvek Bag",
    productClassDesc: "USPS Connect™ Local",
    productDesc: "Reusable Large Tyvek Bag (Pack of 10)",
    boxDimensions: '14" x 17"',
    multiplier: 10,
    selectedQuantity: 0
  },
  {
    productId: "531",
    imagePath: connectLocalPadOfLabels,
    imageAlt: "Image of a Connect Local Pad of Labels",
    productClassDesc: "USPS Connect™ Local",
    productDesc: "Pad of Labels",
    boxDimensions: '2" x 3"',
    multiplier: 1,
    selectedQuantity: 0
  },
  {
    productId: "532",
    imagePath: connectLocalRollOfLabels,
    imageAlt: "Image of a Connect Local Roll of Labels",
    productClassDesc: "USPS Connect™ Local",
    productDesc: "Roll of Labels",
    boxDimensions: '2" x 3"',
    multiplier: 1,
    selectedQuantity: 0
  },
  {
    productId: "533",
    imagePath: connectLocalRollOfTape,
    imageAlt: "Image of a Connect Local Roll of Tape",
    productClassDesc: "USPS Connect™ Local",
    productDesc: "Roll of Tape",
    boxDimensions: '1-7/8" x 328"',
    multiplier: 1,
    selectedQuantity: 0
  },
  {
    productId: "625",
    imagePath: connectLocalMailFlatRateEnvelope,
    imageAlt: "Image of a Connect Local Mail Flat Rate Envelope",
    productClassDesc: "USPS Connect™ Local Mail",
    productDesc: "Flat Rate Envelope (Pack of 10)",
    boxDimensions: '9-1/2" x 12-1/2"',
    multiplier: 10,
    selectedQuantity: 0
  },
  {
    productId: "626",
    imagePath: connectLocalMailLegalFlatRateEnvelope,
    imageAlt: "Image of a Connect Local Mail Legal Flat Rate Envelope",
    productClassDesc: "USPS Connect™ Local Mail",
    productDesc: "Legal Flat Rate Envelope (Pack of 10)",
    boxDimensions: '9-1/2" x 15"',
    multiplier: 10,
    selectedQuantity: 0
  },
  {
    productId: "627",
    imagePath: connectLocalMailRollOfLabels,
    imageAlt: "Image of a Connect Local Mail Roll of Labels",
    productClassDesc: "USPS Connect™ Local Mail",
    productDesc: "Roll of Labels",
    boxDimensions: '2" x 3"',
    multiplier: 1,
    selectedQuantity: 0
  }
];

export default FREE_SUPPLIES_DATA;
