import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const PartiesToTransactionPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Mark whether the parties of the transaction are related or unrelated.
        Related refers to partners, officers, directors, employers, employees or
        owners of the business, company, or organization or related by blood,
        marriage, or common-law partnership. All others should mark the
        "Unrelated" box.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Parties to Transaction Information Icon"
      header="Parties to Transaction"
      content={content}
    />
  );
};

export default PartiesToTransactionPopover;
