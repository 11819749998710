import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const PrintPostOfficePopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Selecting the "Print labels later at the Post Office" option will send
        you an email containing a Label Broker&reg; ID code which can be scanned
        at your local Post office to print your labels.
      </p>
      <p>
        When you are ready to print your labels, simply bring your email either
        digitally or printed to your local Post Office&trade; and scan the code
        that appears in the e-mail.
      </p>
      <p>
        If you cannot go to the Post Office, you can also print your label on{" "}
        <a
          href=" https://tools.usps.com/label-broker.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          USPS.com
        </a>{" "}
        when you have access to a printer.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Print At Post Office Information Icon"
      header="Print labels at the Post Office"
      content={content}
    />
  );
};

export default PrintPostOfficePopover;
