import React from "react";

import infoIcon from "../../click-n-ship/images/info-icon.svg";

const HidePostagePrice = (props) => {
  const text = (
    <>
      <strong>Hide Postage Price:</strong> Choose to keep the postage price off
      your printed domestic labels. Go to the Preferences tab to learn more.
    </>
  );

  if (props.carouselStyling) {
    return (
      <>
        <img
          src={infoIcon}
          alt="Hide Postage Price info"
          title="Hide Postage Price info"
          className="info-icon-without-popover"
        />
        <p className="bannerCarouselText">{text}</p>
      </>
    );
  } else {
    return (
      <div
        id="hide-postage-price-banner-wrapper"
        className="row container-fluid"
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          background: "#ededed",
          borderLeft: "15px solid #3573b1",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "20px",
          fontSize: "16px"
        }}
      >
        <div
          id="hide-postage-price-banner-description-wrapper"
          className="col-12 col-sm-12 col-md-12 col-lg-12"
        >
          <img
            src={infoIcon}
            alt="Hide Postage Price info"
            title="Hide Postage Price info"
            className="info-icon-without-popover"
          />
          <p
            id="hide-postage-price-banner-description"
            style={{ color: "#000000" }}
          >
            {text}
          </p>
        </div>
      </div>
    );
  }
};

export default HidePostagePrice;
