import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import axios from "axios";
import * as analytics from "../Analytics/Analytics.js";

const InformedDeliveryCrossSell = (props) => {
  const {
    className,
    displayIdxs,
    userAddr,
    orderId,
    showBanner,
    focusId
  } = props;

  const [modal, setModal] = useState(displayIdxs);
  const [answer, setUserAnswer] = useState("");
  const [userAgree, setUserAgree] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [checkDisplayError, setCheckDisplayError] = useState(false);
  const [displayAgreeError, setDisplayAgreeError] = useState(false);
  const [checkDisplayAgreeError, setCheckDisplayAgreeError] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  // Focuses the error when clicking "Submit" before making a selection
  useEffect(() => {
    if (checkDisplayError) {
      if (displayError) {
        document.getElementById("make-selection-error").focus();
      }
      setCheckDisplayError(false);
    }
  }, [checkDisplayError, displayError]);

  // Focuses the error when clicking "Submit" before agreeing to the terms &
  // conditions
  useEffect(() => {
    if (checkDisplayAgreeError) {
      if (displayAgreeError) {
        document.getElementById("agree-to-terms-error").focus();
      }
      setCheckDisplayAgreeError(false);
    }
  }, [checkDisplayAgreeError, displayAgreeError]);

  const sendIdCrossSellResponse = () => {
    if ((userAgree && answer === true) || answer === false) {
      let finalAnswer = answer === true ? "Y" : "N";
      analytics.idxsPromptIsClicked(finalAnswer === "Y" ? "Yes" : "No"); // Google Analytics
      axios
        .post(
          "/go/cnsrest/saveIdXSell?orderId=" +
            orderId +
            "&xSellFlag=" +
            finalAnswer,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          toggle();
          if (focusId) {
            document.getElementById(focusId).focus({
              preventScroll: true
            });
          }
          showBanner();
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (answer === true && userAgree === false) {
      setDisplayAgreeError(true);
      setCheckDisplayAgreeError(true);
    } else {
      setDisplayError(true);
      setCheckDisplayError(true);
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        id="cnsIDXSModal"
        aria-labelledby="modalLabel"
        aria-modal="true"
        className={className}
      >
        <div className="modal-content modal-container">
          <div id="cnsIDXSModalLabel" style={{ display: "none" }} />
          <div className="modal-header">
            <h4 className="modal-title">Track Your Package Automatically</h4>
          </div>
          <div className="modal-body">
            <p>
              Get the free Informed Delivery<sup>®</sup> feature to track all
              your incoming packages automatically with email alerts. You can
              also add and manage packages you've shipped using the online
              dashboard or app. Learn more about{" "}
              <a
                href="https://informeddelivery.usps.com/"
                className="inline-link right-chevron"
              >
                Informed Delivery
                <span className="icon-carat_right" />
              </a>
            </p>
            <p>
              <strong>*Would you like to sign up for Informed Delivery?</strong>
            </p>
            <p>
              {userAddr.line1Addr}
              <br />
              {userAddr.cityName}, {userAddr.stateCode} {userAddr.zip5}-
              {userAddr.zipPlus4}
            </p>
            <span
              role="alert"
              id="make-selection-error"
              tabIndex="-1"
              className="error-message"
              style={
                displayError === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              Please make a selection before continuing.
            </span>
            <span
              role="alert"
              id="agree-to-terms-error"
              tabIndex="-1"
              className="error-message"
              style={
                displayAgreeError === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              Please agree to the Terms and Conditions before continuing.
            </span>
            <div className="radio-wrap">
              <div className="radio-container">
                <input
                  id="ID-yes-radio"
                  type="radio"
                  className="radio-button"
                  name="radio-btnXSell"
                  tabIndex="0"
                  onChange={() => {
                    setUserAnswer(true);
                    setDisplayError(false);
                  }}
                  checked={answer === true}
                />
                <label htmlFor="ID-yes-radio">
                  <strong>Yes, I would like to sign up.</strong>
                </label>
              </div>
              {answer === true ? (
                <div className="ID-yes-checked" style={{ display: "block" }}>
                  <p>
                    Mail and packages will begin to populate your dashboard and
                    daily notifications in 2 to 5 business days. You may
                    unsubscribe at any time.
                  </p>
                  <div className="checkbox-wrap">
                    <div className="checkbox-container">
                      <label
                        className="checkbox-component"
                        htmlFor="ID-tc-checkbox"
                      >
                        <input
                          type="checkbox"
                          id="ID-tc-checkbox"
                          onChange={() => {
                            setUserAgree(!userAgree);
                            setDisplayAgreeError(false);
                          }}
                          checked={userAgree}
                        />
                        <span className="checkbox" />
                        <span>
                          I have read, understand and agree to the{" "}
                          <a
                            href="https://www.usps.com/terms-conditions/informed-delivery.htm"
                            className="inline-link"
                          >
                            Terms and Conditions
                          </a>{" "}
                          for Informed Delivery. By checking this box and
                          accepting, I acknowledge that I am option in to the
                          Informed Delivery feature, and I warrant and represent
                          that I am eligible to receive mail at the address
                          indicated in my profile.
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment />
              )}
              <div className="radio-container">
                <input
                  id="ID-no-radio"
                  type="radio"
                  className="radio-button"
                  name="radio-btnXSell"
                  tabIndex="0"
                  onChange={() => {
                    setUserAnswer(false);
                    setDisplayError(false);
                    setUserAgree(false);
                    setDisplayAgreeError(false);
                  }}
                  checked={answer === false}
                />
                <label htmlFor="ID-no-radio">
                  <strong>No, I am not interested at this time.</strong>
                </label>
              </div>
            </div>
            <div className="row">
              <div
                className={
                  "col-12 expand-collapse-wrapper" +
                  (!showPrivacy ? " collapsed" : "")
                }
              >
                <p className="expand-collapse-header">Privacy Act Statement</p>
                <div className="expandable-content">
                  <p
                    style={
                      showPrivacy ? {} : { height: "50px", overflowY: "hidden" }
                    }
                    aria-expanded={showPrivacy ? "true" : "false"}
                  >
                    Your information will be used to facilitate enrollment in
                    and administration of Informed Delivery
                    <sup>®</sup>. Collection is authorized by 39 U.S.C. 401,
                    403, and 404. Providing the information is voluntary, but if
                    not provided we will not be able to process your request. We
                    do not disclose your information to third parties without
                    your consent, except to act on your behalf or request, or as
                    legally required. This includes the following limited
                    circumstances: to a congressional office on your behalf; to
                    agencies and entities to facilitate or resolve financial
                    transactions; to a U.S. Postal Service auditor; for law
                    enforcement purposes, to labor organizations as required by
                    applicable law; incident to legal proceedings involving the
                    Postal Service; to government agencies in connection with
                    decisions as necessary; to agents or contractors when
                    necessary to fulfill a business function or provide products
                    and services to customers; and for customer service
                    purposes. For more information, please visit our{" "}
                    <a href="https://about.usps.com/who-we-are/privacy-policy/welcome.htm">
                      Privacy Policy
                    </a>
                    .
                  </p>
                  <button
                    type="button"
                    className={
                      "expand-collapse button-link" +
                      (showPrivacy ? " expanded" : " collapsed")
                    }
                    tabIndex="0"
                    onClick={() => setShowPrivacy(!showPrivacy)}
                  >
                    <span className="visuallyhidden">
                      Expand additional information.
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="button-container">
                  <button
                    type="button"
                    className="btn-primary error-display"
                    id="cnsIDXSModalSubmit"
                    tabIndex="0"
                    onClick={(e) => {
                      sendIdCrossSellResponse();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default InformedDeliveryCrossSell;
