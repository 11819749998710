import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const DetailedDescPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Enter a detailed description of each article - e.g., "men's cotton
        shirts." General descriptions - e.g., "samples, food products" - are not
        permitted. Maximum length is 35 characters for GXG and 56 for EMI/PMI
        services (including spaces).
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Detailed Description Information Icon"
      header="Please Enter a Detailed Description"
      content={content}
    />
  );
};

export default DetailedDescPopover;
