import React, { useState } from "react";
import { Modal } from "reactstrap";

const RemoveContactModal = (props) => {
  const { buttonLabel, className, removeSelectedContact, contact } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <React.Fragment>
      <button
        type="button"
        className="inline-link-remove button-link"
        data-toggle="modal"
        data-target="#remove-selected"
        data-backdrop="static"
        onClick={toggle}
        aria-label="Remove Contact"
      >
        <strong>{buttonLabel}</strong>
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        id="remove-selected"
      >
        <div className="modal-content modal-container">
          <div className="modal-header">
            <h3 className="modal-title">
              You are removing the following address:
            </h3>
          </div>
          <div className="modal-body">
            <div className="body-content batch-address">
              <p>
                {contact.firstName + " " + contact.lastName}{" "}
                {contact.address &&
                  " " +
                    contact.address.addressLine1 +
                    " " +
                    contact.address.city +
                    " " +
                    contact.address.state.stateCode}
              </p>
            </div>
          </div>
          <div className="modal-buttons">
            <div className="button-container">
              <button
                type="button"
                className="btn-primary remove-selected-button"
                data-dismiss="modal"
                tabIndex="0"
                onClick={(e) => {
                  removeSelectedContact(e, contact.contactId);
                }}
              >
                Remove
              </button>
            </div>
            <div className="button-container">
              <button
                type="button"
                className="btn-primary button--white"
                data-dismiss="modal"
                tabIndex="0"
                onClick={toggle}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RemoveContactModal;
