import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import InformedDeliveryCrossSell from "../Modals/InformedDeliveryCrossSell";
import ReceiptPrintLabelsPopover from "../Popovers/ReceiptPrintLabelsPopover";
import PrintPostOfficePopover from "../Popovers/PrintPostOfficePopover";
import update from "immutability-helper";
import * as analytics from "../Analytics/Analytics.js";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
import AssistiveError from "../Subcomponents/AssistiveError";
import PageNumbers from "../Subcomponents/PageNumbers";
import validation from "../../utils/validation";
import idxsConfirmationBanner from "../../click-n-ship/images/idxs_confirmation_banner.jpg";
import RequestCarrierPickupModal from "../Modals/RequestCarrierPickupModal";
import FreeSuppliesTable from "../Subcomponents/FreeSuppliesTable";

class PaymentConfirmation extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.freeSuppliesProductClasses = ["FSCL", "FSCLM", "FS"];

    this.state = {
      fetchedCartList: [],
      checkedPrintScanForm: false,
      isLoaded: false,
      printList: [],
      checkAll: false,
      userAddr: "",
      orderId: 0,
      showXsellBanner: false,
      maxLocations: [],
      carrierPickupInputs: {
        dogAtAddress: "",
        packageLocation: "",
        additionalInstructions: "",
        termsConditions: false
      },
      successfulCarrierPickupList: {},
      errors: {
        email: "",
        dogAtAddress: "",
        packageLocation: "",
        additionalInstructions: "",
        termsConditions: "",
        globalError: []
      }
    };

    // This is applicable to carrier pickup labels only
    this.carrierPickupLocationOptions = [
      {
        name: "Select",
        value: ""
      },
      {
        name: "In/At Mailbox",
        value: "In_Box"
      },
      {
        name: "On the Porch",
        value: "Porch"
      },
      {
        name: "Front Door",
        value: "Front_Door"
      },
      {
        name: "Back Door",
        value: "Back_Door"
      },
      {
        name: "Side Door",
        value: "Side_Door"
      },
      {
        name: "Knock on Door/Ring Bell",
        value: "Knock"
      },
      {
        name: "Mail Room",
        value: "Mail_Room"
      },
      {
        name: "Office",
        value: "Office"
      },
      {
        name: "Reception",
        value: "Reception"
      },
      {
        name: "Other (additional instructions required)",
        value: "Other"
      }
    ];
  }

  toggle = (indexOfLastItemInGroup) => {
    this.setState((prevState) => {
      return {
        // Specify which modal to open (if a modal is already open,
        // close it by setting this to an empty string)
        carrierPickupModalToOpen: !prevState.carrierPickupModalToOpen
          ? String(indexOfLastItemInGroup)
          : "",
        // Reset carrier pickup inputs and errors
        carrierPickupInputs: {
          dogAtAddress: "",
          packageLocation: "",
          additionalInstructions: "",
          termsConditions: false
        },
        responseError: "",
        errors: {
          ...prevState.errors,
          email: "",
          dogAtAddress: "",
          packageLocation: "",
          additionalInstructions: "",
          termsConditions: ""
        }
      };
    });
  };

  handleCarrierPickupInputs = (event, field) => {
    const value =
      field !== "termsConditions"
        ? event.currentTarget.value
        : event.currentTarget.checked;
    this.setState((prevState) => {
      return {
        carrierPickupInputs: {
          ...prevState.carrierPickupInputs,
          [field]: value
        },
        errors: {
          ...prevState.errors,
          [field]: ""
        }
      };
    });
  };

  getSortedCarrierPickupLabels = (items) => {
    let {
      carrierPickupClLabels,
      carrierPickupNonClLabels,
      allOtherLabels
    } = this.separateCarrierPickupLabels(items);
    carrierPickupClLabels.sort(this.carrierPickupCompare);
    carrierPickupNonClLabels.sort(this.carrierPickupCompareReturnAddresses);
    carrierPickupClLabels = this.markEndOfCarrierPickupGroup(
      carrierPickupClLabels,
      this.carrierPickupCompare
    );
    const mergedCarrierPickupGroups = this.mergeClAndNonClCarrierPickupGroups(
      carrierPickupClLabels,
      carrierPickupNonClLabels
    );
    carrierPickupClLabels = mergedCarrierPickupGroups.carrierPickupClLabels;
    carrierPickupNonClLabels =
      mergedCarrierPickupGroups.carrierPickupNonClLabels;
    carrierPickupNonClLabels = this.markEndOfCarrierPickupGroup(
      carrierPickupNonClLabels,
      this.carrierPickupCompareReturnAddresses
    );
    let carrierPickupLabels = carrierPickupClLabels.concat(
      carrierPickupNonClLabels
    );
    const carrierPickupConfirmationObject = this.checkForCarrierPickupConfirmation(
      carrierPickupLabels
    );
    carrierPickupLabels = carrierPickupConfirmationObject.carrierPickupLabels;
    const successfulCarrierPickupList =
      carrierPickupConfirmationObject.successfulCarrierPickupList;
    const allLabels = carrierPickupLabels.concat(allOtherLabels);
    return {
      carrierPickupLabels,
      allLabels,
      successfulCarrierPickupList
    };
  };

  // Separate labels into the following groups:
  // - Connect Local labels eligible for carrier pickup
  // - non-Connect Local labels eligible for carrier pickup
  // - labels not eligible for carrier pickup
  separateCarrierPickupLabels = (items) => {
    let carrierPickupClLabels = [];
    let carrierPickupNonClLabels = [];
    let allOtherLabels = [];
    for (let i in items) {
      if (
        items[i].orderItemInd.carrierPickupEligibleInd === "Y" &&
        items[i].parcelSelectType &&
        items[i].orderItemDropOff &&
        items[i].orderItemDropOff.locationName &&
        (items[i].orderItemDropOff.cutoffSameDayDelivery ||
          items[i].orderItemDropOff.cutoffNextDayDelivery)
      ) {
        carrierPickupClLabels.push(items[i]);
      } else if (
        items[i].orderItemInd.carrierPickupEligibleInd === "Y" &&
        !items[i].parcelSelectType &&
        items[i].productId.productClass !== "RG"
      ) {
        carrierPickupNonClLabels.push(items[i]);
      } else {
        allOtherLabels.push(items[i]);
      }
    }
    return {
      carrierPickupClLabels,
      carrierPickupNonClLabels,
      allOtherLabels
    };
  };

  // Compare two Connect Local order items (a and b) for equality in terms of
  // drop-off location and return address.
  //   < 0   - a comes before b when sorting
  //   === 0 - a and b are equal
  //   > 0   - a comes after b when sorting
  carrierPickupCompare = (a, b) => {
    // Compare drop-off locations
    const dropOffCompare = this.carrierPickupCompareDropOffLocations(a, b);
    if (dropOffCompare !== 0) {
      return dropOffCompare;
    }
    // Compare return addresses
    return this.carrierPickupCompareReturnAddresses(a, b);
  };

  // Compare drop-off locations.
  carrierPickupCompareDropOffLocations = (a, b) => {
    const locationNameA = a.orderItemDropOff.locationName;
    const locationNameB = b.orderItemDropOff.locationName;
    const dropOffCompare = locationNameA.localeCompare(locationNameB);
    // If both drop-off locations are different, the one with same-day
    // delivery (if exactly one is present) should be shown first.
    if (dropOffCompare !== 0) {
      if (
        a.orderItemDropOff.cutoffSameDayDelivery &&
        !b.orderItemDropOff.cutoffSameDayDelivery
      ) {
        return -1;
      } else if (
        !a.orderItemDropOff.cutoffSameDayDelivery &&
        b.orderItemDropOff.cutoffSameDayDelivery
      ) {
        return 1;
      }
    }
    // If both drop-off locations are different, a non-zero value will be
    // returned indicating how the location names should be sorted.
    // If both drop-off locations are the same, 0 will be returned.
    return dropOffCompare;
  };

  // Compare return addresses.
  carrierPickupCompareReturnAddresses = (a, b) => {
    // Compare address line 1
    const line1AddrA = a.shipFrom.line1Addr ? a.shipFrom.line1Addr : "";
    const line1AddrB = b.shipFrom.line1Addr ? b.shipFrom.line1Addr : "";
    const line1Compare = line1AddrA.localeCompare(line1AddrB);
    if (line1Compare !== 0) {
      return line1Compare;
    }
    // Compare address line 2
    const line2AddrA = a.shipFrom.line2Addr ? a.shipFrom.line2Addr : "";
    const line2AddrB = b.shipFrom.line2Addr ? b.shipFrom.line2Addr : "";
    const line2Compare = line2AddrA.localeCompare(line2AddrB);
    if (line2Compare !== 0) {
      return line2Compare;
    }
    // Compare city
    const cityNameA = a.shipFrom.cityName ? a.shipFrom.cityName : "";
    const cityNameB = b.shipFrom.cityName ? b.shipFrom.cityName : "";
    const cityNameCompare = cityNameA.localeCompare(cityNameB);
    if (cityNameCompare !== 0) {
      return cityNameCompare;
    }
    // Compare state
    const stateCodeA = a.shipFrom.stateCode ? a.shipFrom.stateCode : "";
    const stateCodeB = b.shipFrom.stateCode ? b.shipFrom.stateCode : "";
    const stateCodeCompare = stateCodeA.localeCompare(stateCodeB);
    if (stateCodeCompare !== 0) {
      return stateCodeCompare;
    }
    // Compare ZIP5
    const zip5A = a.shipFrom.zip5 ? a.shipFrom.zip5 : "";
    const zip5B = b.shipFrom.zip5 ? b.shipFrom.zip5 : "";
    const zip5Compare = zip5A.localeCompare(zip5B);
    if (zip5Compare !== 0) {
      return zip5Compare;
    }
    // Compare ZIP+4
    const zipPlus4A = a.shipFrom.zipPlus4 ? a.shipFrom.zipPlus4 : "";
    const zipPlus4B = b.shipFrom.zipPlus4 ? b.shipFrom.zipPlus4 : "";
    const zipPlus4Compare = zipPlus4A.localeCompare(zipPlus4B);
    if (zipPlus4Compare !== 0) {
      return zipPlus4Compare;
    }
    // All are equal
    return 0;
  };

  // Determine where each drop-off location / return address "group" ends
  markEndOfCarrierPickupGroup = (
    carrierPickupLabels,
    carrierPickupCompareFunction
  ) => {
    let numberInDropOffReturnAddressGroup = 0;
    for (let i in carrierPickupLabels) {
      const nextIndex = String(Number(i) + 1);
      numberInDropOffReturnAddressGroup++;
      if (
        // If this is the last element in the array
        nextIndex === String(carrierPickupLabels.length) ||
        // If this element and the next have a different drop-off location
        // or return address
        carrierPickupCompareFunction(
          carrierPickupLabels[i],
          carrierPickupLabels[nextIndex]
        ) !== 0
      ) {
        carrierPickupLabels[
          i
        ].numberInDropOffReturnAddressGroup = numberInDropOffReturnAddressGroup;
        numberInDropOffReturnAddressGroup = 0;
      }
    }
    return carrierPickupLabels;
  };

  // Merge non-Connect Local labels that are also eligible for carrier pickup
  // into the appropriate Conect Local "group" based on return address
  mergeClAndNonClCarrierPickupGroups = (
    carrierPickupClLabels,
    carrierPickupNonClLabels
  ) => {
    for (let i = 0; i < carrierPickupClLabels.length; i++) {
      if (carrierPickupClLabels[i].numberInDropOffReturnAddressGroup) {
        for (let j = 0; j < carrierPickupNonClLabels.length; j++) {
          if (
            this.carrierPickupCompareReturnAddresses(
              carrierPickupClLabels[i],
              carrierPickupNonClLabels[j]
            ) === 0
          ) {
            // Add the non-Connect Local label to the end of the existing group,
            // update the number in the group, delete the original number,
            // and increment the index to account for the extra element
            carrierPickupClLabels.splice(i + 1, 0, carrierPickupNonClLabels[j]);
            carrierPickupClLabels[i + 1].numberInDropOffReturnAddressGroup =
              carrierPickupClLabels[i].numberInDropOffReturnAddressGroup + 1;
            delete carrierPickupClLabels[i].numberInDropOffReturnAddressGroup;
            i++;
            // Remove the non-Connect Local label from its original array,
            // and decrement the index to account for the removed element
            carrierPickupNonClLabels.splice(j, 1);
            j--;
          }
        }
      }
    }
    return {
      carrierPickupClLabels,
      carrierPickupNonClLabels
    };
  };

  // Check for carrier pickup requests that were already made
  checkForCarrierPickupConfirmation = (carrierPickupLabels) => {
    let numberOfClLabelsInGroup = 0;
    let successfulCarrierPickupList = {};
    for (let i in carrierPickupLabels) {
      if (carrierPickupLabels[i].orderItemDropOff.locationName) {
        numberOfClLabelsInGroup++;
      }
      if (carrierPickupLabels[i].numberInDropOffReturnAddressGroup) {
        // Save how many Connect Local labels are part of this group
        carrierPickupLabels[
          i
        ].numberOfClLabelsInGroup = numberOfClLabelsInGroup;
        numberOfClLabelsInGroup = 0;
        // Save whether same-day pickup is available for this group
        let firstIndexInGroup =
          i - (carrierPickupLabels[i].numberInDropOffReturnAddressGroup - 1);
        carrierPickupLabels[
          i
        ].eligibleForSameDayPickup = this.isEligibleForSameDayPickup(
          carrierPickupLabels[firstIndexInGroup].orderItemDropOff
            .cutoffSameDayDelivery
        );
        // Check if a carrier pickup request was already made for this item
        if (
          carrierPickupLabels[i].carrierPickupDate &&
          carrierPickupLabels[i].carrierPickupConfirmation
        ) {
          const pickupDate = new Date(carrierPickupLabels[i].carrierPickupDate);
          const month = String(pickupDate.getMonth() + 1);
          const day = String(pickupDate.getDate());
          const year = String(pickupDate.getFullYear());
          successfulCarrierPickupList[String(i)] = {
            carrierPickupDeliveryText: this.getPickupDeliveryText(pickupDate),
            carrierPickupLocation: this.getSelectedPickupLocation(
              carrierPickupLabels[i].carrierPickupLocation
            ),
            carrierPickupUnixDayOfWeek: new Intl.DateTimeFormat("en-US", {
              weekday: "long"
            }).format(pickupDate),
            carrierPickupDate: month + "/" + day + "/" + year,
            carrierPickupConfirmationNumber:
              carrierPickupLabels[i].carrierPickupConfirmation
          };
        }
      }
    }
    return {
      carrierPickupLabels,
      successfulCarrierPickupList
    };
  };

  areCarrierPickupErrorsPresent = () => {
    let errorsFound = false;
    let errorObject = {};
    if (!this.state.carrierPickupInputs.dogAtAddress) {
      errorObject["dogAtAddress"] = "Please select a radio button.";
      errorsFound = true;
    }
    if (!this.state.carrierPickupInputs.packageLocation) {
      errorObject["packageLocation"] =
        "Please select the location of your packages.";
      errorsFound = true;
    }
    if (
      this.state.carrierPickupInputs.packageLocation === "Other" &&
      (!this.state.carrierPickupInputs.additionalInstructions ||
        !this.state.carrierPickupInputs.additionalInstructions.trim())
    ) {
      errorObject["additionalInstructions"] =
        "Please provide additional instructions.";
      errorsFound = true;
    }
    if (!this.state.carrierPickupInputs.termsConditions) {
      errorObject["termsConditions"] =
        "Please agree to the Terms and Conditions before continuing.";
      errorsFound = true;
    }
    this.setState((prevState) => {
      return {
        errors: {
          ...prevState.errors,
          ...errorObject
        }
      };
    });
    return errorsFound;
  };

  requestCarrierPickup = (
    numberInDropOffReturnAddressGroup,
    indexOfLastItemInGroup
  ) => {
    this.props.toggleSpinner(true);
    // Clear carrier pickup error from response
    this.setState({
      responseError: ""
    });
    // Get the order item info for all items in this drop-off location / return address "group"
    let orderItemIdArray = [];
    let currentIndex =
      indexOfLastItemInGroup - (numberInDropOffReturnAddressGroup - 1);
    const firstIndexInGroup = currentIndex;
    for (currentIndex; currentIndex <= indexOfLastItemInGroup; currentIndex++) {
      orderItemIdArray.push(
        this.state.fetchedCartList.items[currentIndex].orderItemId
      );
    }
    // Collect all data to send to the endpoint
    let dataToSend = {
      dogInd: this.state.carrierPickupInputs.dogAtAddress === "Y",
      location: this.state.carrierPickupInputs.packageLocation,
      customerNotes: this.state.carrierPickupInputs.additionalInstructions,
      sameDayPickup: this.isEligibleForSameDayPickup(
        this.state.fetchedCartList.items[firstIndexInGroup].orderItemDropOff
          .cutoffSameDayDelivery
      )
        ? "Y"
        : "N",
      orderItemId: orderItemIdArray
    };
    // Call the endpoint
    axios
      .post("/go/cnsrest/saveCarrierPickup", JSON.stringify(dataToSend), {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        if (
          response.data &&
          response.data.confirmationNumber &&
          response.data.pickupDate
        ) {
          const pickupDate = response.data.pickupDate;
          this.setState((prevState) => {
            let successfulCarrierPickupList = JSON.parse(
              JSON.stringify(prevState.successfulCarrierPickupList)
            );
            let month = pickupDate.substring(4, 6);
            month = month - 10 < 0 ? month.substring(1) : month;
            let day = pickupDate.substring(6);
            day = day - 10 < 0 ? day.substring(1) : day;
            const year = pickupDate.substring(0, 4);
            const pickupDateFormatted = new Date(
              year + "." + month + "." + day
            );
            successfulCarrierPickupList[String(indexOfLastItemInGroup)] = {
              carrierPickupDeliveryText: this.getPickupDeliveryText(
                pickupDateFormatted
              ),
              carrierPickupLocation: this.getSelectedPickupLocation(
                prevState.carrierPickupInputs.packageLocation
              ),
              carrierPickupUnixDayOfWeek: new Intl.DateTimeFormat("en-US", {
                weekday: "long"
              }).format(pickupDateFormatted),
              carrierPickupDate: month + "/" + day + "/" + year,
              carrierPickupConfirmationNumber: response.data.confirmationNumber
            };
            return {
              successfulCarrierPickupList: successfulCarrierPickupList
            };
          });
          this.toggle("");
        } else {
          this.setState({
            responseError: "We're sorry. An unexpected error has occurred."
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          responseError: "We're sorry. An unexpected error has occurred."
        });
      })
      .finally(() => {
        this.props.toggleSpinner(false);
      });
  };

  formatAddress = (address) => {
    // Add all address elements that are present to an array
    let addressArray = [];
    if (address.line1Addr) {
      addressArray.push(address.line1Addr + ",");
    }
    if (address.line2Addr) {
      addressArray.push(address.line2Addr + ",");
    }
    if (address.cityName) {
      addressArray.push(address.cityName + ",");
    }
    if (address.stateCode) {
      addressArray.push(address.stateCode);
    }
    if (address.zip5) {
      let fullZipCode = address.zip5;
      if (address.zipPlus4) {
        fullZipCode += "-" + address.zipPlus4;
      }
      addressArray.push(fullZipCode);
    }
    // Get the full address as a single string, inserting spaces between each
    // element
    let formattedAddress = "";
    const addressArrayLength = addressArray.length;
    for (let i = 0; i < addressArrayLength - 1; i++) {
      formattedAddress = formattedAddress + addressArray[i] + " ";
    }
    if (addressArrayLength > 0) {
      formattedAddress =
        formattedAddress + addressArray[addressArrayLength - 1];
    }
    // If the last element has a comma at the end, remove it
    if (formattedAddress.charAt(formattedAddress.length - 1) === ",") {
      formattedAddress = formattedAddress.slice(0, -1);
    }
    return formattedAddress;
  };

  getPickupDeliveryText = (pickupDate) => {
    const currentDate = new Date();
    const carrierPickupMonth = String(pickupDate.getMonth() + 1);
    const carrierPickupDay = String(pickupDate.getDate());
    const carrierPickupYear = String(pickupDate.getFullYear());
    if (currentDate.getTime() >= pickupDate.getTime()) {
      // If the current date is equal to or later than the carrier pickup
      // time/date (ex. if the user revisits this page on a future date
      // for whatever reason), treat them as equal and have the pickup and
      // delivery days be one day apart
      const tomorrowDate = new Date(pickupDate);
      tomorrowDate.setDate(pickupDate.getDate() + 1);
      const tomorrowMonth = String(tomorrowDate.getMonth() + 1);
      const tomorrowDay = String(tomorrowDate.getDate());
      const tomorrowYear = String(tomorrowDate.getFullYear());
      return (
        "picked up today, " +
        carrierPickupMonth +
        "/" +
        carrierPickupDay +
        "/" +
        carrierPickupYear +
        " and delivered tomorrow, " +
        tomorrowMonth +
        "/" +
        tomorrowDay +
        "/" +
        tomorrowYear
      );
    } else {
      // Otherwise, the pickup and delivery dates are the same
      return (
        "picked up and delivered tomorrow, " +
        carrierPickupMonth +
        "/" +
        carrierPickupDay +
        "/" +
        carrierPickupYear
      );
    }
  };

  getSelectedPickupLocation = (carrierPickupLocation) => {
    if (carrierPickupLocation) {
      for (let i in this.carrierPickupLocationOptions) {
        if (
          this.carrierPickupLocationOptions[i].value === carrierPickupLocation
        ) {
          return this.carrierPickupLocationOptions[i].name;
        }
      }
    }
    return "";
  };

  componentDidMount() {
    this.props.toggleSpinner(true);
    this.props.updateCartSize();
    var queryString = window.location.search.substring(1);
    var fetchPrefQueryString = queryString.substring(
      queryString.indexOf("=") + 1
    );
    // If no orderId has been provided in the URL, redirect to the landing page
    if (!fetchPrefQueryString) {
      window.location.replace("/labelInformation");
    }
    axios
      .get(
        "/go/cnsrest/orderSummary?fetchPrefQueryString=" + fetchPrefQueryString
      )
      .then((response) => {
        let purchaseAnalyticsEnabled = this.props.lookupCodes[
          "cns.analytics.transactionPurchaseEnabled"
        ];
        if ("true" === purchaseAnalyticsEnabled) {
          // Send off proceed clicks to Google Analytics
          if (!response.data.transPurchaseAnalyticsSent) {
            analytics.transaction_Purchase(response.data, this.props.userData);
          }
        } else {
          // Google Tag Manager
          var dataLayer = (window.dataLayer = window.dataLayer || []);
          dataLayer.push({
            event: "ecomPurchaseInfoAvailable"
          });
        }
        if (response.data.IDInd === "Y") {
          analytics.idxsPromptAppears();
          this.setState({
            displayIdxs: true,
            userAddr: response.data.regAddr,
            orderId: response.data.orderId
          });
        } else {
          this.setState({
            displayIdxs: false
          });
        }
        //let isLoyaltyOrder = false;
        //let amountOfLoyalty = 0;

        // Copy any free supplies to a separate array
        let freeSupplies = response.data.items.filter(
          (item) =>
            item.productId &&
            this.freeSuppliesProductClasses.includes(
              item.productId.productClass
            )
        );
        // Remove the free supplies from the array in the response
        response.data.items = response.data.items.filter(
          (item) =>
            !item.productId ||
            !this.freeSuppliesProductClasses.includes(
              item.productId.productClass
            )
        );

        /*response.data.items.map((item) =>
          item.order.ttlAppliedLoyalty
            ? ((isLoyaltyOrder = true),
              (amountOfLoyalty = item.order.ttlAppliedLoyalty))
            : (isLoyaltyOrder = false)
        );*/
        this.getInfoAboutLabels(response.data.items);

        // Sort any labels that are eligible for carrier pickup
        const sortedLabelInfo = this.getSortedCarrierPickupLabels(
          response.data.items
        );
        response.data.items = sortedLabelInfo.allLabels;

        // The label count needs to be updated first
        this.setState({
          normalLabelCount: this.normalLabelCount
        });
        // Use the updater form of setState() to ensure the label count has been
        // set beforehand
        this.setState((prevState) => {
          return {
            isLoaded: true,
            fetchedCartList: response.data,
            printOptions: response.data.printPreference || "",
            customsFormsOnly:
              this.customsFormOrderItemIds.length ===
              response.data.items.length,
            // Loyalty fields
            //isLoyaltyOrder: isLoyaltyOrder,
            //amountOfLoyalty: amountOfLoyalty,
            // Label Broker fields
            customsFormPresent: this.customsFormOrderItemIds.length > 0,
            // Parcel Select
            parcelSelectPresent: this.dropOffArray.length > 0,
            checkedPrintScanForm:
              this.dropOffArray.length > 0 || this.forcePrintScanForm(),
            numberOfCarrierPickupLabels:
              sortedLabelInfo.carrierPickupLabels.length,
            successfulCarrierPickupList:
              sortedLabelInfo.successfulCarrierPickupList,
            // Free supplies
            freeSupplies: freeSupplies,

            emailAddress:
              response.data.userPref &&
              response.data.userPref.hasOwnProperty("ptsTrackingEmail")
                ? response.data.userPref.ptsTrackingEmail
                : response.data.regAddr.emailAddress,
            labelBrokerRegZip5: response.data.regAddr.zip5
          };
        });
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        this.props.toggleSpinner(false);
        this.setState({
          focusOnTop: true
        });
      });
  }

  componentDidUpdate() {
    if (this.state.focusOnEmailError) {
      // Focus on the email field
      if (this.errors["email"]) {
        document.getElementById("emailAddress-a11y").focus();
      }
      this.setState({
        focusOnEmailError: false
      });
    }
    if (this.state.focusOnLabelBrokerError) {
      // Focus on the error from the label broker response
      if (this.state.errors["labelBrokerResponse"]) {
        document.getElementById("label-broker-response-id").focus();
      }
      this.setState({
        focusOnLabelBrokerError: false
      });
    }
    if (this.state.focusAfterResponse) {
      // Focus on the top new element after a successful label broker call
      document.getElementById("labelBrokerSuccessId").focus();
      this.setState({
        focusAfterResponse: false
      });
    }
    if (this.state.focusOnTop && this.state.displayIdxs === false) {
      // Focus on the top element on the page (only if IDXS not displayed)
      document.getElementById("payment-confirmation-top-id").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
  }

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            [field]: errorMessage
          }
        };
      });
    }
  };

  getInfoAboutLabels = (items) => {
    this.normalLabelCount = 0;
    this.customsFormOrderItemIds = [];
    this.dropOffArray = [];
    for (let i in items) {
      // Count the number of normal labels
      // (i.e. not counting mobile labels / postage dues / free supplies)
      if (items[i].orderItemTypeId.value === 1) {
        this.normalLabelCount++;
      }
      // Collect the Customs Forms order IDs
      if (items[i].packageIndicators.customsAcknowledgeInd === "Y") {
        this.customsFormOrderItemIds.push(items[i].orderItemId);
      }
      // Collect the parcel select labels and group by drop-off location
      if (items[i].parcelSelectType && items[i].orderItemDropOff) {
        if (
          !this.dropOffArray.includes(items[i].orderItemDropOff.locationName)
        ) {
          this.dropOffArray.push(items[i].orderItemDropOff.locationName);
        }
      }
    }
  };

  setReceipt = (e) => {
    const value = e.currentTarget.value;
    this.setState((prevState) => {
      return {
        printOptions: value,
        errors: {
          ...prevState.errors,
          globalError: []
        }
      };
    });
  };

  setupPrintAndSaveList = (item, checked) => {
    if (checked) {
      // If the checkbox is checked, add it to the printList array
      this.setState((state) => {
        const joined = state.printList.concat(item.orderItemId);
        let customsFormSelected = false;
        let printOptions;
        // Determine if a customs form was selected
        if (this.customsFormOrderItemIds.includes(item.orderItemId)) {
          customsFormSelected = true;
          // Switch to the print labels with receipt option
          if (state.printOptions === "N") {
            printOptions = "Y";
          }
        }
        return {
          printList: joined,
          customsFormSelected: customsFormSelected || state.customsFormSelected,
          printOptions: printOptions || state.printOptions,
          checkAll: false,
          errors: {
            email: "",
            globalError: []
          }
        };
      });
    } else {
      // If the checkbox is unchecked, filter the orderItemId out of the array
      this.setState((state) => {
        const printList = state.printList.filter(
          (elm) => elm !== item.orderItemId
        );
        // Determine if at least 1 customs form is still checked
        let customsFormSelected = false;
        for (let i in printList) {
          if (this.customsFormOrderItemIds.includes(printList[i])) {
            customsFormSelected = true;
            break;
          }
        }
        return {
          printList,
          customsFormSelected: customsFormSelected,
          checkAll: false
        };
      });
    }
  };

  selectDeselectAll = (checked) => {
    if (checked) {
      let customsFormSelected = false;
      let printOptions;
      // If the checkbox is checked, add all eligible items to the array
      this.setState((state) => {
        let joined = [];
        for (let orderItemId in state.fetchedCartList.items) {
          if (
            state.fetchedCartList.items[orderItemId].orderItemTypeId.value === 1
          ) {
            joined.push(state.fetchedCartList.items[orderItemId].orderItemId);
          }
        }
        // Determine if at least 1 customs form is checked
        for (let i in joined) {
          if (this.customsFormOrderItemIds.includes(joined[i])) {
            customsFormSelected = true;
            // Switch to the print labels with receipt option
            if (state.printOptions === "N") {
              printOptions = "Y";
            }
            break;
          }
        }
        return {
          checkAll: true,
          printList: joined,
          customsFormSelected: customsFormSelected,
          printOptions: printOptions || state.printOptions,
          errors: {
            email: "",
            globalError: []
          }
        };
      });
    } else {
      // If the checkbox is unchecked, empty the array
      this.setState({
        checkAll: false,
        customsFormSelected: false,
        printList: []
      });
    }
  };

  checkIfSelected = (item) => {
    let shouldBeChecked = false;
    for (let orderItemId in this.state.printList) {
      if (this.state.printList[orderItemId] === item.orderItemId) {
        shouldBeChecked = true;
        break;
      }
    }

    return shouldBeChecked;
  };

  // Get all unique ZIPs and drop-off locations from the selected labels
  getSelectedZips = () => {
    const selectedItems = this.state.printList;
    const allItems = this.state.fetchedCartList.items;
    const selectedZips = [];
    for (let i in allItems) {
      if (selectedItems.includes(allItems[i].orderItemId)) {
        // Check only the items that have been selected for printing
        if (!allItems[i].parcelSelectType) {
          // Add the ZIP code if not a parcel select label
          if (!selectedZips.includes(allItems[i].shipFromPostalCode)) {
            selectedZips.push(allItems[i].shipFromPostalCode);
          }
        } else if (
          allItems[i].parcelSelectType &&
          allItems[i].orderItemDropOff
        ) {
          // Add the drop-off location if a parcel select label
          if (
            !selectedZips.includes(allItems[i].orderItemDropOff.locationName)
          ) {
            selectedZips.push(allItems[i].orderItemDropOff.locationName);
          }
        }
      }
    }
    // Sort by ZIPs first, then alphabetically by drop-off location
    selectedZips.sort();
    // Return as a comma-separated string
    return selectedZips.join();
  };

  // Automatically include SCAN form(s) with the selected labels if the lookup
  // code flag is on and the normal label count equals or exceeds the threshold
  forcePrintScanForm = () => {
    return (
      this.props.lookupCodes["automatic_scan_forms_flag"] === "true" &&
      this.state.normalLabelCount >=
        this.props.lookupCodes["automatic_scan_forms_threshold"]
    );
  };

  printLabels = (e) => {
    e.preventDefault();
    let ee = e.target.id;
    if (this.state.printOptions && this.state.printList.length > 0) {
      if (this.state.checkedPrintScanForm) {
        let dataToSend = {
          manifestDate: this.state.fetchedCartList.manifestDate,
          selectedPostal: this.getSelectedZips()
        };
        console.log(e);
        axios
          .post("/go/cnsrest/commitManifest", dataToSend)
          .then((response) => {
            let listOfOrderItemIds = this.state.printList.join();
            // Format the manifest number(s) as comma-separated values and
            // remove null values (i.e. commas with no value before them)
            let manifestNumbers = response.data.manifestNumber
              ? response.data.manifestNumber.join()
              : "";
            manifestNumbers = manifestNumbers
              .replace(/,{2,}/g, ",")
              .replace(/^,/, "")
              .replace(/,$/, "");

            let url =
              window.location.protocol +
              "//" +
              window.location.hostname +
              "/go/Secure/LabelDownloadServlet?orderItemIds=" +
              listOfOrderItemIds +
              "&fileForSave=" +
              (ee === "save" ? "true" : "false") +
              "&prtNoInstructions=" +
              (this.state.printOptions === "N") +
              "&manifestNumber=" +
              manifestNumbers;
            var hiddenFrame = document.createElement("iframe");
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              hiddenFrame.onload = function () {};
              //hiddenFrame.src = iframeHtml;
              hiddenFrame.src = url;
              hiddenFrame.id = "pdfFrame";
              hiddenFrame.name = "pdfFrameName";
              hiddenFrame.style = "visibility: hidden";
              document.body.appendChild(hiddenFrame);
            } else {
              hiddenFrame.onload = function () {
                // invokes the auto-print functionality on the client by calling print on the iframe window
                this.contentWindow.focus();
                this.contentWindow.print();
              };
              //hiddenFrame.src = iframeHtml;
              hiddenFrame.src = url;
              let te = document.getElementById("iframeHide");
              te.appendChild(hiddenFrame);
              // hiddenFrame.style = "visibility: hidden";
              // document.body.appendChild(hiddenFrame);
            }
          })
          .catch((error) => {
            window.scrollTo(0, 0);
            this.setGlobalError([
              "We're sorry. An unexpected error has occurred."
            ]);
            console.log(error);
          })
          .finally((f) => {
            this.props.toggleSpinner(false);
          });
      } else {
        let listOfOrderItemIds = this.state.printList.join();
        let url =
          window.location.protocol +
          "//" +
          window.location.hostname +
          "/go/Secure/LabelDownloadServlet?orderItemIds=" +
          listOfOrderItemIds +
          "&fileForSave=" +
          (e.target.id === "save" ? "true" : "false") +
          "&prtNoInstructions=" +
          (this.state.printOptions === "N") +
          "&manifestNumber=";

        var hiddenFrame = document.createElement("iframe");
        if (navigator.userAgent.indexOf("Firefox") > -1) {
          hiddenFrame.onload = function () {};
          //hiddenFrame.src = iframeHtml;
          hiddenFrame.src = url;
          hiddenFrame.id = "pdfFrame";
          hiddenFrame.name = "pdfFrameName";
          hiddenFrame.style = "visibility: hidden";
          document.body.appendChild(hiddenFrame);
        } else {
          hiddenFrame.onload = function () {
            // invokes the auto-print functionality on the client by calling print on the iframe window
            this.contentWindow.focus();
            this.contentWindow.print();
          };
          //hiddenFrame.src = iframeHtml;
          hiddenFrame.src = url;
          let te = document.getElementById("iframeHide");
          te.appendChild(hiddenFrame);
          // hiddenFrame.style = "visibility: hidden";
          // document.body.appendChild(hiddenFrame);
        }
      }
    } else {
      window.scrollTo(0, 0);
      let globalErrorArray = [];
      if (!this.state.printOptions) {
        globalErrorArray.push(
          "Please select how you want to print your labels."
        );
      }
      if (this.state.printList.length <= 0) {
        globalErrorArray.push("Please select at least one label.");
      }
      this.setGlobalError(globalErrorArray);
      if (globalErrorArray.length > 0) {
        this.setState({
          focusOnPrintingError: true
        });
      }
    }
  };

  viewScanForm = (e) => {
    this.props.history.push("/scanFormList");
  };

  goToShippingHistory = (e) => {
    this.props.history.push("/shippingHistory", {
      orderId: this.state.fetchedCartList.orderId
    });
  };

  showInfo = (e) => {
    e.preventDefault();
  };

  goToCreateLabel = (e) => {
    this.props.history.push("/labelInformation");
  };

  showBanner = (e) => {
    this.setState({ showXsellBanner: true });
  };

  clickedXSellBanner = (e) => {
    var idxsBannerIsClicked = {};
    idxsBannerIsClicked.buttonText = "Go to Your Dashboard";
    analytics.idxsBannerIsClicked(idxsBannerIsClicked); // Google Analytics
  };

  sendCode = () => {
    this.props.toggleSpinner(true);
    // Check for a valid email address
    if (!this.validate()) {
      // Clear label broker error from response
      if (this.state.errors.labelBrokerResponse) {
        this.clearError("labelBrokerResponse");
      }
      // Get order item IDs
      let orderItemIdArray = [];
      for (let i in this.state.fetchedCartList.items) {
        if (this.state.fetchedCartList.items[i].hasOwnProperty("orderItemId")) {
          orderItemIdArray.push(
            this.state.fetchedCartList.items[i]["orderItemId"]
          );
        }
      }
      // Call the endpoint (requires Form Data rather than a Request Payload)
      const dataToSend =
        "orderItemIds=" +
        orderItemIdArray.join() +
        "&labelBrokerEmail=" +
        this.state.emailAddress;
      axios
        .post("/go/cnsrest/labelbroker/provider", dataToSend, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then((response) => {
          if (response.data.responseCode === "200") {
            const maxLocations = Object.assign(
              [],
              response.data.poLocations
            ).slice(0, 8);
            const perPageCount = 2;
            this.setState({
              labelBrokerSuccess: true,
              focusAfterResponse: true,
              postOfficesNear: response.data.zip5,
              currentPage: 1,
              maxLocations: maxLocations,
              perPageCount: perPageCount,
              numberOfPages: Math.ceil(maxLocations.length / perPageCount)
            });
          } else {
            this.setInputErrors("labelBrokerResponse")(
              response.data.responseMessage
            );
            this.setState({
              focusOnLabelBrokerError: true
            });
          }
        })
        .catch((error) => {
          console.log(error);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
        })
        .finally(() => {
          this.props.toggleSpinner(false);
        });
    } else {
      this.props.toggleSpinner(false);
      this.setState({
        focusOnEmailError: true
      });
    }
  };

  validate = () => {
    let errorsFound = false;
    let errorObject = {};

    if (!validation.email.test(this.state.emailAddress)) {
      errorObject["email"] = "Please enter a valid email address.";
      errorsFound = true;
    }

    this.errors = errorObject;
    this.setInputErrors("errorObject")(errorObject);
    return errorsFound;
  };

  clearError = (fieldName) => {
    this.setState((prevState) => {
      return {
        errors: {
          ...prevState.errors,
          [fieldName]: ""
        }
      };
    });
  };

  showPrintAtPostOffice = () => {
    return (
      !this.state.customsFormPresent &&
      this.props.lookupCodes["cns.labelbrokerflag"] === "true" &&
      this.state.fetchedCartList.showLabelBrokerInfo &&
      !this.state.parcelSelectPresent
    );
  };

  changePage = (newPageNumber) => {
    this.setState({
      currentPage: newPageNumber
    });
  };

  displayExtraServices = (productClassDesc, orderItemPmtDetails) => {
    let innerHtml = "<div><span>" + productClassDesc + "</span></div>";
    for (let i = 0; i < orderItemPmtDetails.length; i++) {
      if (orderItemPmtDetails[i].transactionDetailSubtype) {
        innerHtml +=
          "<div><span>" +
          orderItemPmtDetails[i].transactionDetailSubtype +
          "</span></div>";
      }
    }
    return innerHtml;
  };

  isEligibleForSameDayPickup = (cutoffSameDayDelivery) => {
    if (cutoffSameDayDelivery) {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
      const currentSecond = currentTime.getSeconds();
      const cutoffHour = Number(cutoffSameDayDelivery.substring(0, 2));
      const cutoffMinute = Number(cutoffSameDayDelivery.substring(3, 5));
      const cutoffSecond = Number(cutoffSameDayDelivery.substring(6, 8));
      return (
        currentHour < cutoffHour ||
        (currentHour === cutoffHour && currentMinute < cutoffMinute) ||
        (currentHour === cutoffHour &&
          currentMinute === cutoffMinute &&
          currentSecond < cutoffSecond)
      );
    }
    return false;
  };

  renderCarrierPickupRequestInfo = (item, currentIndex) => {
    const firstIndexInGroup =
      currentIndex - (item.numberInDropOffReturnAddressGroup - 1);
    const firstItemInGroup = this.state.fetchedCartList.items[
      firstIndexInGroup
    ];
    if (item.eligibleForSameDayPickup) {
      // Groups that contain at least one label eligible for same-day delivery,
      // but may also contain non-Connect Local labels that share the same
      // return address.
      return (
        <div className="col-10 carrierPickupInfoDesktopWrapper carrierPickupInfoMobileWrapper">
          <div className="col-12 col-md-9">
            <p className="carrierPickupInfo">
              Your <strong>{item.numberInDropOffReturnAddressGroup}</strong>{" "}
              label
              {item.numberInDropOffReturnAddressGroup !== 1 ? "s" : ""}{" "}
              designated to be taken to the{" "}
              <strong>{firstItemInGroup.orderItemDropOff.locationName}</strong>{" "}
              postal facility{" "}
              {item.numberInDropOffReturnAddressGroup !== 1 ? "are" : "is"}{" "}
              eligible for pickup at the entered return address,{" "}
              <strong>{this.formatAddress(item.shipFrom)}.</strong>
            </p>
            <p className="carrierPickupAdditionalInfo">
              Your labels are only eligible to be picked up from the return
              address entered for these packages. Your packages will be picked
              up either today or tomorrow, based on when your pickup request is
              requested.
            </p>
          </div>
          <div className="carrierPickupButtonWrapper">
            <RequestCarrierPickupModal
              buttonLabel="Request a Pickup"
              carrierPickupModalToOpen={this.state.carrierPickupModalToOpen}
              toggle={this.toggle}
              carrierPickupInputs={this.state.carrierPickupInputs}
              optionsList={this.carrierPickupLocationOptions}
              errorMessages={this.state.errors}
              handleCarrierPickupInputs={this.handleCarrierPickupInputs}
              areCarrierPickupErrorsPresent={this.areCarrierPickupErrorsPresent}
              numberInDropOffReturnAddressGroup={
                item.numberInDropOffReturnAddressGroup
              }
              indexOfLastItemInGroup={currentIndex}
              requestCarrierPickup={this.requestCarrierPickup}
              responseError={this.state.responseError}
              isForConnectLocal={item.numberOfClLabelsInGroup > 0}
            />
          </div>
        </div>
      );
    } else if (
      item.numberOfClLabelsInGroup === item.numberInDropOffReturnAddressGroup
    ) {
      // Groups that contain only Connect Local labels that are eligible for
      // next-day delivery
      return (
        <div className="col-10 carrierPickupInfoDesktopWrapper carrierPickupInfoMobileWrapper">
          <div className="col-12 col-md-9">
            <p className="carrierPickupInfo">
              Your <strong>{item.numberInDropOffReturnAddressGroup}</strong>{" "}
              label
              {item.numberInDropOffReturnAddressGroup !== 1 ? "s" : ""}{" "}
              designated to be taken to the{" "}
              <strong>{firstItemInGroup.orderItemDropOff.locationName}</strong>{" "}
              postal facility{" "}
              {item.numberInDropOffReturnAddressGroup !== 1 ? "are" : "is"}{" "}
              eligible for pickup at the entered return address,{" "}
              <strong>{this.formatAddress(item.shipFrom)}.</strong>
            </p>
            <p className="carrierPickupAdditionalInfo">
              Your labels are only eligible to be picked up from the return
              address entered for these packages. Your packages will be picked
              up tomorrow.
            </p>
          </div>
          <div className="carrierPickupButtonWrapper">
            <RequestCarrierPickupModal
              buttonLabel="Request a Pickup"
              carrierPickupModalToOpen={this.state.carrierPickupModalToOpen}
              toggle={this.toggle}
              carrierPickupInputs={this.state.carrierPickupInputs}
              optionsList={this.carrierPickupLocationOptions}
              errorMessages={this.state.errors}
              handleCarrierPickupInputs={this.handleCarrierPickupInputs}
              areCarrierPickupErrorsPresent={this.areCarrierPickupErrorsPresent}
              numberInDropOffReturnAddressGroup={
                item.numberInDropOffReturnAddressGroup
              }
              indexOfLastItemInGroup={currentIndex}
              requestCarrierPickup={this.requestCarrierPickup}
              responseError={this.state.responseError}
            />
          </div>
        </div>
      );
    } else if (item.numberOfClLabelsInGroup > 0) {
      // Groups that contain at least one Connect Local label that is eligible
      // for next-day delivery, but may also contain non-Connect Local labels
      // that share the same return address
      return (
        <div className="col-10 carrierPickupInfoDesktopWrapper carrierPickupInfoMobileWrapper">
          <div className="col-12 col-md-9">
            <p className="carrierPickupInfo">
              Your <strong>{item.numberInDropOffReturnAddressGroup}</strong>{" "}
              label
              {item.numberInDropOffReturnAddressGroup !== 1 ? "s" : ""}{" "}
              designated to be taken to the{" "}
              <strong>{firstItemInGroup.orderItemDropOff.locationName}</strong>{" "}
              postal facility{" "}
              {item.numberInDropOffReturnAddressGroup !== 1 ? "are" : "is"}{" "}
              eligible for pickup at the entered return address,{" "}
              <strong>{this.formatAddress(item.shipFrom)}.</strong>
            </p>
            <p className="carrierPickupAdditionalInfo">
              Your labels are only eligible to be picked up from the return
              address entered for these packages. Your packages will be picked
              up tomorrow.
            </p>
          </div>
          <div className="carrierPickupButtonWrapper">
            <RequestCarrierPickupModal
              buttonLabel="Request a Pickup"
              carrierPickupModalToOpen={this.state.carrierPickupModalToOpen}
              toggle={this.toggle}
              carrierPickupInputs={this.state.carrierPickupInputs}
              optionsList={this.carrierPickupLocationOptions}
              errorMessages={this.state.errors}
              handleCarrierPickupInputs={this.handleCarrierPickupInputs}
              areCarrierPickupErrorsPresent={this.areCarrierPickupErrorsPresent}
              numberInDropOffReturnAddressGroup={
                item.numberInDropOffReturnAddressGroup
              }
              indexOfLastItemInGroup={currentIndex}
              requestCarrierPickup={this.requestCarrierPickup}
              responseError={this.state.responseError}
            />
          </div>
        </div>
      );
    } else {
      // Groups that contain non-Connect Local products only
      return (
        <div className="col-10 carrierPickupInfoDesktopWrapper carrierPickupInfoMobileWrapper">
          <div className="col-12 col-md-9">
            <p className="carrierPickupInfo">
              Your <strong>{item.numberInDropOffReturnAddressGroup}</strong>{" "}
              label
              {item.numberInDropOffReturnAddressGroup !== 1 ? "s" : ""}{" "}
              {item.numberInDropOffReturnAddressGroup !== 1 ? "are" : "is"}{" "}
              eligible for pickup at the entered return address,{" "}
              <strong>{this.formatAddress(item.shipFrom)}.</strong>
            </p>
            <p className="carrierPickupAdditionalInfo">
              Your labels are only eligible to be picked up from the return
              address entered for these packages. Your packages will be picked
              up tomorrow.
            </p>
          </div>
          <div className="carrierPickupButtonWrapper">
            <RequestCarrierPickupModal
              buttonLabel="Request a Pickup"
              carrierPickupModalToOpen={this.state.carrierPickupModalToOpen}
              toggle={this.toggle}
              carrierPickupInputs={this.state.carrierPickupInputs}
              optionsList={this.carrierPickupLocationOptions}
              errorMessages={this.state.errors}
              handleCarrierPickupInputs={this.handleCarrierPickupInputs}
              areCarrierPickupErrorsPresent={this.areCarrierPickupErrorsPresent}
              numberInDropOffReturnAddressGroup={
                item.numberInDropOffReturnAddressGroup
              }
              indexOfLastItemInGroup={currentIndex}
              requestCarrierPickup={this.requestCarrierPickup}
              responseError={this.state.responseError}
            />
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div
        id="payment-confirmation-top-id"
        className="Payment_Confirmation_Container"
        tabIndex="-1"
      >
        <div className="container-fluid">
          <h2 className="d-md-none">Order Confirmation</h2>
          <h2 className="confirmation-thankyou">
            Thank you for choosing the United States Postal Service
            <sup>®</sup>.
          </h2>
          <AssistiveGlobalError
            globalErrorArray={this.state.errors.globalError}
            shouldFocus={
              // On page load, only focus if the IDXS modal is not displayed
              (this.state.focusOnTop && this.state.displayIdxs === false) ||
              // After page load, focus if the user didn't select
              // a printing option or any labels
              this.state.focusOnPrintingError ||
              // Otherwise, don't focus
              false
            }
          />
          {this.state.isLoaded ? (
            <React.Fragment>
              <div className="row">
                <div className="col-12 payment-confirmation-wrapper">
                  <label className="payment-confirmation header">
                    Payment Confirmation
                  </label>
                  <p className="payment-confirmation order-number">
                    Order #:{" "}
                    <a
                      href="/shippingHistory"
                      className="inline-link right-chevron order-link"
                      onClick={(e) => this.goToShippingHistory()}
                    >
                      {this.state.fetchedCartList.orderId}
                    </a>
                    <InformedDeliveryCrossSell
                      displayIdxs={this.state.displayIdxs}
                      userAddr={this.state.userAddr}
                      orderId={this.state.orderId}
                      showBanner={this.showBanner}
                      focusId="payment-confirmation-top-id"
                    />
                  </p>
                  <div className="row">
                    <div className="col-12 payment-confirmation-info">
                      <p>Account Number: {this.state.fetchedCartList.userId}</p>
                      <p>
                        Charged to:{" "}
                        {this.state.fetchedCartList.items[0].paymentMethod}
                      </p>
                      {/*!this.state.isLoyaltyOrder ? (
                        <p>
                          Order Total: ${this.state.fetchedCartList.labelTotal}{" "}
                          ({this.state.fetchedCartList.labelCount}{" "}
                          {this.state.fetchedCartList.labelCount !== 1
                            ? "labels"
                            : "label"}
                          )
                        </p>
                      ) : ( */}
                        <div>
                          <div className="row" style={{ paddingTop: "10px" }}>
                            <div className="col-2">
                              <p>
                                Subtotal (
                                {this.state.fetchedCartList.items.length}{" "}
                                {this.state.fetchedCartList.items.length === 1
                                  ? "Label"
                                  : "Labels"}
                                ){" "}
                              </p>
                            </div>

                            <div className="col-2">
                              <p>
                                $
                                {Number(
                                  Number(
                                    this.state.fetchedCartList.labelTotal
                                  ) //+ this.state.amountOfLoyalty
                                ).toFixed(2)}
                              </p>
                            </div>
                            <div className="col-8">
                              <p></p>
                            </div>
                          </div>
                          {/*<div className="row">
                            <div className="col-2">
                              <p>Loyalty Credits</p>
                            </div>

                            <div className="col-2">
                              <p>-${this.state.amountOfLoyalty.toFixed(2)}</p>
                            </div>
                              </div>*/}

                          <div className="col-8"></div>

                          <div className="row">
                            <div
                              className="col-2"
                              style={{ borderTop: "solid 1px" }}
                            >
                              <p>Order Total</p>
                            </div>

                            <div
                              className="col-2"
                              style={{ borderTop: "solid 1px" }}
                            >
                              <p>${this.state.fetchedCartList.labelTotal}</p>
                            </div>
                            <div className="col-8">
                              <p></p>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 print-labels-wrapper">
                  <label className="payment-confirmation print-labels-header">
                    {" "}
                    Print Your Labels{" "}
                  </label>
                  <p className="alert-message">
                    {" "}
                    You have until 11:59 PM Central Time of the Ship Date to
                    print this label.
                  </p>
                  <div className="row">
                    <div className="col-12 print-labels">
                      <div className="radio-wrap">
                        <div className="radio-container">
                          <input
                            id="print-label-with-receipt"
                            type="radio"
                            className="radio-button"
                            name="radio-btn"
                            tabIndex="0"
                            onChange={(e) => this.setReceipt(e)}
                            checked={this.state.printOptions === "Y"}
                            value="Y"
                          />
                          <label htmlFor="print-label-with-receipt">
                            Print labels with receipt. Each label will print on
                            one half of the page and the receipt/label record
                            will print on the other half.{" "}
                            <ReceiptPrintLabelsPopover targetName="receiptPrintLabelsIcon" />
                          </label>
                        </div>
                        {!this.state.customsFormsOnly &&
                        !this.state.customsFormSelected ? (
                          <div className="radio-container">
                            <input
                              id="print-label-without-receipt"
                              type="radio"
                              className="radio-button"
                              name="radio-btn"
                              tabIndex="0"
                              onChange={(e) => this.setReceipt(e)}
                              checked={this.state.printOptions === "N"}
                              value="N"
                            />
                            <label htmlFor="print-label-without-receipt">
                              Print labels without receipt. Two labels will
                              print on one page. Receipt information is also in
                              your confirmation email and Shipping History.
                            </label>
                          </div>
                        ) : null}

                        {this.showPrintAtPostOffice() ? (
                          <div className="radio-container">
                            <input
                              id="print-label-post-office"
                              type="radio"
                              className="radio-button"
                              name="radio-btn"
                              tabIndex="0"
                              onChange={(e) => this.setReceipt(e)}
                              checked={this.state.printOptions === "P"}
                              value="P"
                            />
                            <label htmlFor="print-label-post-office">
                              Print labels later at the Post Office. A Label
                              Broker ID<sup>®</sup> code will be sent to you
                              that you can use to print your labels at the Post
                              Office.{" "}
                              <PrintPostOfficePopover targetName="receiptPrintPostOfficeIcon" />
                            </label>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {this.state.printOptions !== "P" ? (
                    <div className="row">
                      <div className="col-12 print-labels-adobe-reader">
                        <span className="adobe-message">
                          Adobe<sup>®</sup> Reader<sup>®</sup> v5 or higher is
                          required to print or save labels.
                        </span>
                        <p className="adobe-link">
                          <a
                            className="inline-link right-chevron print-labels-inline-link"
                            href="https://www.adobe.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download Adobe<sup>®</sup> Reader<sup>®</sup>
                          </a>
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {this.forcePrintScanForm() ? (
                    <React.Fragment>
                      <p id="SCAN-form-alert" className="alert-message">
                        A SCAN Form will be printed for the labels below.
                      </p>
                      <p className="row col-12">
                        If you are creating more labels today, please check the
                        box below and your SCAN Form will be saved for printing
                        when you have finished creating labels for the day.
                      </p>
                      <div className="checkbox-padding-left">
                        <label
                          className="checkbox-text print-labels-checkbox"
                          htmlFor="more-labels-checkbox"
                        >
                          <input
                            type="checkbox"
                            id="more-labels-checkbox"
                            onChange={(e) =>
                              this.setState({
                                checkedPrintScanForm: !e.currentTarget.checked
                              })
                            }
                            checked={!this.state.checkedPrintScanForm}
                          />
                          <span
                            className="checkbox"
                            name="print-labels-check"
                          />
                          I am creating more labels today
                        </label>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {this.state.parcelSelectPresent ? (
                        <React.Fragment>
                          <p id="SCAN-form-alert" className="alert-message">
                            A SCAN Form is recommended when dropping USPS
                            Connect™ Local mail pieces at the selected drop off
                            location.
                          </p>
                          <p className="row col-12">
                            If you are creating more USPS Connect Local labels
                            today, please check the box below and your SCAN Form
                            will be saved for printing once you have finished
                            creating USPS Connect Local labels.
                          </p>
                          <div className="checkbox-padding-left">
                            <label
                              className="checkbox-text print-labels-checkbox"
                              htmlFor="parcel-select-labels-checkbox"
                            >
                              <input
                                type="checkbox"
                                id="parcel-select-labels-checkbox"
                                onChange={(e) =>
                                  this.setState({
                                    checkedPrintScanForm: !e.currentTarget
                                      .checked
                                  })
                                }
                                checked={!this.state.checkedPrintScanForm}
                              />
                              <span
                                className="checkbox"
                                name="print-labels-check"
                              />
                              I am creating more USPS Connect Local labels today
                            </label>
                          </div>
                        </React.Fragment>
                      ) : null}

                      {this.state.printOptions !== "P" &&
                      this.state.fetchedCartList.openManifests &&
                      this.state.fetchedCartList.multiPostalInd === false &&
                      this.state.printOptions === "Y" &&
                      !this.state.parcelSelectPresent ? (
                        <div className="row">
                          <div className="col-12 checkbox-print-labels">
                            <label
                              className="checkbox-text print-labels-checkbox"
                              htmlFor="print-labels-check"
                            >
                              <input
                                type="checkbox"
                                id="print-labels-check"
                                onChange={(e) =>
                                  this.setState({
                                    checkedPrintScanForm:
                                      e.currentTarget.checked
                                  })
                                }
                                checked={this.state.checkedPrintScanForm}
                              />
                              <span
                                className="checkbox"
                                name="print-labels-check"
                              />
                              Create and print a SCAN Form for your labels.
                            </label>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment />
                      )}
                    </React.Fragment>
                  )}
                  {this.state.printOptions !== "P" ? (
                    <div className="row">
                      <div className="col-12 button-wrapper">
                        <div className="button-container">
                          <button
                            type="button"
                            className="btn-primary"
                            id="print"
                            onClick={(e) => this.printLabels(e, 1)}
                          >
                            Print Labels
                          </button>
                        </div>
                        <div className="button-container">
                          <button
                            type="button"
                            className="btn-primary button--white"
                            id="save"
                            onClick={(e) => this.printLabels(e, 1)}
                          >
                            Save As PDF
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.printOptions === "P" &&
                  !this.state.labelBrokerSuccess ? (
                    <React.Fragment>
                      <div className="row">
                        <div
                          className={
                            "col-9 col-md-4 col-lg-5 form-group required-field" +
                            (this.state.errors.email !== "" ? " error" : "")
                          }
                        >
                          <AssistiveError
                            inputId="emailAddress"
                            errorMessage={this.state.errors.email}
                          />
                          <label htmlFor="emailAddress">*Email</label>
                          <input
                            tabIndex="0"
                            type="text"
                            className="form-control"
                            id="emailAddress"
                            placeholder="email123@email.com"
                            value={this.state.emailAddress}
                            onChange={(e) => {
                              this.setState({
                                emailAddress: e.currentTarget.value
                              });
                              this.state.errors.email !== ""
                                ? this.clearError("email")
                                : this.clearError("");
                            }}
                          />
                          <span className="error-message">
                            {this.state.errors.email}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 print-labels-post-office-info">
                          <p>
                            When choosing this option, USPS will send you an
                            email containing a Label Broker ID<sup>®</sup> code
                            that can be scanned at participating USPS Retail
                            locations. For more information visit{" "}
                            <a
                              className="inline-link right-chevron"
                              href="https://faq.usps.com/s/article/Label-Broker"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Label Broker FAQs
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 button-wrapper">
                          <div className="button-container">
                            <button
                              type="button"
                              className="btn-primary"
                              id="sendCodeButton"
                              onClick={(e) => this.sendCode(e)}
                            >
                              Send Code
                            </button>
                          </div>
                        </div>
                      </div>
                      <span
                        id="label-broker-response-id"
                        className="error-message"
                        tabIndex="-1"
                      >
                        {this.state.errors.labelBrokerResponse}
                      </span>
                    </React.Fragment>
                  ) : this.state.printOptions === "P" &&
                    this.state.labelBrokerSuccess ? (
                    <React.Fragment>
                      <br />
                      <p
                        id="labelBrokerSuccessId"
                        className="alert-message"
                        tabIndex="-1"
                      >
                        Your labels are ready to print at the Post Office!
                      </p>
                      <p>
                        Your Label Broker ID<sup>®</sup> code has been emailed
                        to you and is ready to use at your local Post Office
                        <sup>™</sup>.
                      </p>
                      <p>
                        Post Offices near:{" "}
                        <span className="label-broker-color">
                          <strong>{this.state.postOfficesNear}</strong>
                        </span>
                      </p>
                      <br />
                      <div
                        id="label-broker-showing-results"
                        className="row label-broker-table"
                      >
                        <p className="label-broker-color">
                          <strong>
                            Showing Results{" "}
                            {this.state.currentPage * this.state.perPageCount -
                              (this.state.perPageCount - 1)}
                            -{this.state.currentPage * this.state.perPageCount}{" "}
                            of {this.state.maxLocations.length}
                          </strong>
                        </p>
                        <PageNumbers
                          divClassName="results-pagination-wrapper"
                          ulId="label-broker-results-pagination"
                          liClassName="page-item"
                          currentPage={this.state.currentPage}
                          setCurrentPage={this.changePage}
                          pageNumbersToDisplay={
                            this.state.numberOfPages > 1
                              ? [
                                  ...Array(this.state.numberOfPages + 1).keys()
                                ].slice(1)
                              : [1]
                          }
                          numOfPages={this.state.numberOfPages}
                        />
                      </div>
                      <div className="row label-broker-table">
                        <ul>
                          {this.state.maxLocations
                            .slice(
                              this.state.currentPage * this.state.perPageCount -
                                (this.state.perPageCount - 1) -
                                1,
                              this.state.currentPage * this.state.perPageCount
                            )
                            .map((location, i) => (
                              <div
                                className="label-broker-border"
                                key={location.locationID}
                              >
                                {" "}
                                <li className="label-broker-row">
                                  <a
                                    href={
                                      this.props.lookupCodes[
                                        "cns.desktop.polocator.email.link"
                                      ] +
                                      "?locationType=po&serviceType=lbroretail&address=" +
                                      this.state.postOfficesNear
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="label-broker-color">
                                      <strong>{location.city}</strong>
                                    </span>
                                  </a>
                                  <p>
                                    {location.address1 +
                                      " " +
                                      location.city +
                                      ", " +
                                      location.state +
                                      " " +
                                      location.zip5}
                                  </p>
                                </li>
                              </div>
                            ))}
                        </ul>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
              {/* hide everything above me when parcelselect is checked*/}

              <div className="label-details-wrapper">
                <div className="row">
                  <div className="col-12 confirmation-table">
                    <div className="row confirmation-table-header">
                      <div className="col-1 d-none d-md-block">
                        {this.state.printOptions !== "P" ? (
                          <label className="checkbox-component payment-confirmation-checkbox">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                this.selectDeselectAll(e.target.checked)
                              }
                              checked={this.state.checkAll}
                            />
                            <span className="checkbox" />
                            <span className="visuallyhidden">
                              Select all labels
                            </span>
                          </label>
                        ) : null}
                      </div>
                      <div className="col-2 d-none d-md-block">
                        <p>
                          <strong>Shipping Address</strong>
                        </p>
                      </div>
                      <div className="col-3 d-none d-md-block">
                        <p>
                          <strong>Package Details</strong>
                        </p>
                      </div>
                      <div className="col-3 d-none d-md-block">
                        <p>
                          <strong>Service</strong>
                        </p>
                      </div>
                      <div className="col-3 d-none d-md-block text-md-right">
                        <p>
                          <strong>Label Number</strong>
                        </p>
                      </div>
                    </div>

                    {this.state.fetchedCartList.items.map(
                      (item, currentIndex) => (
                        <React.Fragment key={item.orderItemId}>
                          <div
                            className={
                              "row confirmation-item" +
                              (currentIndex <
                              this.state.numberOfCarrierPickupLabels
                                ? " carrierPickupItemEligible"
                                : "")
                            }
                          >
                            <div className="col-2 col-md-1">
                              <label className="checkbox-component payment-confirmation-checkbox">
                                {item.orderItemTypeId.value === 1 &&
                                this.state.printOptions !== "P" ? (
                                  <React.Fragment>
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        this.setupPrintAndSaveList(
                                          item,
                                          e.target.checked
                                        )
                                      }
                                      checked={
                                        this.state.checkAll
                                          ? this.state.checkAll
                                          : this.checkIfSelected(item)
                                      }
                                    />
                                    <span className="checkbox" />
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment />
                                )}
                                {currentIndex + 1}
                                <span className="d-none d-md-inline">
                                  {" "}
                                  of {this.state.fetchedCartList.items.length}
                                </span>
                              </label>
                            </div>
                            <div className="col-10 col-md-2 addOverflowWrap">
                              <p className="d-md-none mobile-confirmation-shipping-address">
                                Shipping Address
                              </p>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.orderItemDeliveryAddr.displayAddress
                                }}
                              />
                            </div>
                            <div className="col-10 col-md-3 offset-2 offset-md-0">
                              <p className="d-md-none mobile-confirmation-package-details">
                                Package Details
                              </p>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.packageDetailsDisplay
                                }}
                              />
                            </div>
                            <div className="col-10 col-md-3 offset-2 offset-md-0">
                              <p className="d-md-none mobile-confirmation-service">
                                Service
                              </p>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    // Currently, PSG isn't showing additional
                                    // services when displaying this info in the
                                    // normal way, so we need special behavior
                                    // for that product
                                    item.productId &&
                                    item.productId.productId >= 503 &&
                                    item.productId.productId <= 508 &&
                                    item.productId.productClassDesc &&
                                    item.orderItemPmtDetails
                                      ? this.displayExtraServices(
                                          item.productId.productClassDesc,
                                          item.orderItemPmtDetails
                                        )
                                      : item.detailedServiceDescription &&
                                        !item.detailedServiceDescription.includes(
                                          "null"
                                        )
                                      ? // Default (ex. for PM)
                                        item.detailedServiceDescription
                                      : // Revert to product class (ex. for FCPS)
                                        item.productId &&
                                        item.productId.productClassDesc
                                }}
                              />
                              {item.hazmatType != undefined &&
                                item.hazmatType != "0" && <p>HAZMAT</p>}
                            </div>
                            <div className="col-10 col-md-3 offset-2 offset-md-0 text-md-right">
                              <p className="d-md-none mobile-confirmation-label">
                                Label Number
                              </p>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.trackingNbr
                                }}
                              />
                            </div>
                          </div>
                          {item.numberInDropOffReturnAddressGroup > 0 ? (
                            <React.Fragment>
                              <div className="row carrierPickupItemEligible">
                                <div className="col-12">
                                  <hr className="horizontal-line carrierPickupDividerTop" />
                                </div>
                              </div>
                              <div className="row carrierPickupItemEligible carrierPickupWrapper">
                                <div className="col-2">
                                  <p className="carrierPickupHeader">
                                    <strong>
                                      {item.numberOfClLabelsInGroup > 0 ? (
                                        <React.Fragment>
                                          USPS Connect™ Local
                                          <br />
                                        </React.Fragment>
                                      ) : (
                                        <></>
                                      )}
                                      Schedule a Pickup
                                    </strong>
                                  </p>
                                </div>
                                {!this.state.successfulCarrierPickupList ||
                                !Object.keys(
                                  this.state.successfulCarrierPickupList
                                ).includes(String(currentIndex)) ? (
                                  this.renderCarrierPickupRequestInfo(
                                    item,
                                    currentIndex
                                  )
                                ) : (
                                  <React.Fragment>
                                    <div className="col-10 d-md-block">
                                      <p className="carrierPickupInfo">
                                        Your pickup has been confirmed! Based on
                                        the time of your request, your packages
                                        will be{" "}
                                        <strong>
                                          {
                                            this.state
                                              .successfulCarrierPickupList[
                                              String(currentIndex)
                                            ].carrierPickupDeliveryText
                                          }
                                          .
                                        </strong>{" "}
                                        Please find your pickup information and
                                        confirmation number below.
                                      </p>
                                      <br />
                                      <div className="row carrierPickupInfo carrierPickupLocationWrapper">
                                        <p className="col-3 carrierPickupAddress">
                                          <strong>Pickup Address:</strong>
                                        </p>
                                        <p className="col-9 carrierPickupAddressInfo">
                                          {item.shipFrom.line1Addr}
                                          <br />
                                          {item.shipFrom.line2Addr ? (
                                            <React.Fragment>
                                              {item.shipFrom.line2Addr}
                                              <br />
                                            </React.Fragment>
                                          ) : null}
                                          {item.shipFrom.cityName +
                                            ", " +
                                            item.shipFrom.stateCode +
                                            " " +
                                            item.shipFrom.zip5 +
                                            (item.shipFrom.zipPlus4
                                              ? "-" + item.shipFrom.zipPlus4
                                              : "")}
                                          <br />
                                          <strong>Pickup Location:</strong>{" "}
                                          {
                                            this.state
                                              .successfulCarrierPickupList[
                                              String(currentIndex)
                                            ].carrierPickupLocation
                                          }
                                        </p>
                                        <p className="col-3 carrierPickupDate">
                                          <strong>Pickup Date:</strong>
                                        </p>
                                        <p className="col-9 carrierPickupDateInfo">
                                          {
                                            this.state
                                              .successfulCarrierPickupList[
                                              String(currentIndex)
                                            ].carrierPickupUnixDayOfWeek
                                          }
                                          ,{" "}
                                          {
                                            this.state
                                              .successfulCarrierPickupList[
                                              String(currentIndex)
                                            ].carrierPickupDate
                                          }
                                        </p>
                                        <p className="col-3 carrierPickupConfirmationNumber">
                                          <strong>Confirmation Number:</strong>
                                        </p>
                                        <p className="col-9 carrierPickupConfirmationNumberInfo">
                                          {
                                            this.state
                                              .successfulCarrierPickupList[
                                              String(currentIndex)
                                            ].carrierPickupConfirmationNumber
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                              {currentIndex <
                              this.state.numberOfCarrierPickupLabels - 1 ? (
                                <div className="row carrierPickupItemEligible">
                                  <div className="col-12">
                                    <hr className="horizontal-line carrierPickupDividerBottom" />
                                  </div>
                                </div>
                              ) : null}
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      )
                    )}
                  </div>
                </div>
              </div>
              {this.state.freeSupplies &&
                this.state.freeSupplies.length > 0 && (
                  <FreeSuppliesTable freeSupplies={this.state.freeSupplies} />
                )}
              <div className="row">
                <div className="col-12 print-scan-form">
                  <span className="alert-message">
                    You have {this.state.fetchedCartList.manifestCount}{" "}
                    {this.state.fetchedCartList.manifestCount > 1 ||
                    this.state.fetchedCartList.manifestCount === 0
                      ? "labels"
                      : "label"}{" "}
                    that{" "}
                    {this.state.fetchedCartList.manifestCount > 1 ||
                    this.state.fetchedCartList.manifestCount === 0
                      ? "are"
                      : "is"}{" "}
                    eligible to be added to a SCAN Form. You have until 11:59PM
                    Central Time of the Ship Date to create and print your SCAN
                    Form.
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-12 button-wrapper">
                  <div className="button-container">
                    <a
                      href="http://www.usps.com/shipping/carrierpickup/welcome.htm"
                      className="btn-primary button--white"
                    >
                      Schedule a Pickup
                    </a>
                  </div>
                  <div className="button-container">
                    <a
                      href="/scanFormList"
                      className="btn-primary button--white"
                      onClick={(e) => this.viewScanForm(e)}
                    >
                      View SCAN Form
                    </a>
                  </div>
                  {/*this.props.loyaltyUserInfo ? (
                    this.props.loyaltyUserInfo.userEnrolled === true ? (
                      <div className="button-container">
                        <a
                          href={this.props.lookupCodes["cns.loyalty_portal"]}
                          className="btn-primary button--white"
                        >
                          View Loyalty Benefits
                        </a>
                      </div>
                    ) : (
                      <React.Fragment />
                    )
                  ) : (
                    <React.Fragment />
                  )*/}
                  <div className="button-container create-another-label">
                    <a
                      href="/labelInformation"
                      className="btn-primary button--white"
                      id="label-button"
                      onClick={(e) => this.goToCreateLabel()}
                    >
                      Create Another Label
                    </a>
                  </div>
                </div>
              </div>
              {this.state.showXsellBanner ? (
                <div className="row hide" id="idxs-banner-section">
                  <div>
                    <a
                      id="idxs-banner"
                      href={this.props.lookupCodes["idxs.banner"]}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={this.clickedXSellBanner}
                    >
                      <img
                        src={idxsConfirmationBanner}
                        alt="You're enrolled in Informed Delivery! Go to Your Dashboard"
                        width="100%"
                        height="auto"
                      />
                    </a>
                  </div>
                  <p id="idxs-disclaimer-bottom">
                    Mail and packages will begin to populate your dashboard and
                    daily notifications in 2 to 5 business days. You may
                    unsubscribe at any time.
                  </p>
                </div>
              ) : (
                <React.Fragment />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </div>
        <div style={{ visibility: "hidden" }} id="iframeHide"></div>
      </div>
    );
  }
}

export default withRouter(PaymentConfirmation);
