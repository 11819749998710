import React from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";

class CnsNavResponsive extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle(e) {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    return (
      <ButtonDropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        style={buttonStyle}
      >
        <DropdownToggle
          className="form-control btn dropdown-toggle dropdown dropdown-items-wrapper form-control"
          color="none"
          caret
        >
          Go To
        </DropdownToggle>
        <DropdownMenu>
          {this.props.listOfCnsLinks.map((item) => {
            // Set whether or not to hide certain links
            let classList = "";
            if (item.title === "SCAN Form" && !(this.props.manifestCount > 0)) {
              classList += "d-none";
            } else if (item.title === "FAQs") {
              classList += "d-none";
            } else if (
              item.title === "USPS Connect" &&
              (this.props.userData.accountType !== "Business" ||
                !this.props.userData.parcelSelectFlag)
            ) {
              classList += "d-none";
            }
            return (
              <DropdownItem key={item.id} className={classList}>
                <Link to={item.path}>
                  {item.title +
                    (item.title === "Shipping Cart"
                      ? " (" + this.props.cartCount + ")"
                      : "")}
                </Link>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}
const buttonStyle = {
  //fix later
  width: "100%"
};
export default CnsNavResponsive;
