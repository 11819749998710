import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const HsTariffPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        The 6-digit HS Tariff Number is based on the Harmonized Commodity
        Description and Coding System developed by the World Customs
        Organization.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="HS Tariff Information Icon"
      header="HS Tariff Number"
      content={content}
    />
  );
};

export default HsTariffPopover;
