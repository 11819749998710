import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const RedirectComponent = (props) => {
  const { oldUrls } = props;

  const [location] = useState(useLocation());

  const redirectToNewUrl = () => {
    for (let i in oldUrls) {
      if (oldUrls[i] === location.pathname) {
        return (
          location.pathname.replace(".shtml", "").replace(".html", "") +
          (location.search ? location.search : "")
        );
      }
    }
    return "/";
  };

  return (
    <React.Fragment>
      {window.location.replace(redirectToNewUrl())}
    </React.Fragment>
  );
};

export default RedirectComponent;
