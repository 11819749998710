import React from "react";

const lastLPABusiness = (props) => {
  const text = (
    <>
      As a business customer, you're eligible for additional discounted rates!
      Sign up for a Business Rate Card and start saving on postage purchases in
      our{" "}
      <a
        className="btn btn-link"
        tabIndex="0"
        href={props.cnsbUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          paddingLeft: "0px",
          fontSize: "15px",
          textDecoration: "underline"
        }}
      >
        enhanced Click-N-Ship® experience.
      </a>
    </>
  );

  const button = (
    <a
      className="btn btn-primary"
      tabIndex="0"
      href={props.cnsbUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{ width: "50%" }}
    >
      <span>Get Started</span>
    </a>
  );

  if (props.carouselStyling) {
    return (
      <>
        <div className="row">
          <div id="redirectToNewCnsText" className="col-12">
            <p className="bannerCarouselText">{text}</p>
          </div>
          {/* <div
            id="redirectToNewCnsLink"
            className="col-12 col-md-6 col-lg-5 text-center banner-button"
          >
            {button}
          </div> */}
        </div>
      </>
    );
  } else {
    return (
      <div
        id="redirect-to-new-cns-banner-wrapper"
        className="row container-fluid"
        style={{
          display: "flex",
          background: "#ededed",
          borderLeft: "15px solid #3573b1",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "20px",
          fontSize: "16px"
        }}
      >
        <div
          id="redirect-to-new-cns-banner-description-wrapper"
          className="col-12 col-md-7 col-lg-8"
        >
          <p
            id="redirect-to-new-cns-banner-description"
            style={{ color: "#333366" }}
          >
            {text}
          </p>
        </div>
        {/* <div className="col-12 col-md-5 col-lg-4 text-center banner-button">
          {button}
        </div> */}
      </div>
    );
  }
};

export default lastLPABusiness;
