import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import { withRouter } from "react-router-dom";

class WhiteSpinner extends React.Component {
  render() {
    return (
      <div className="white-spinner-wrapper">
        <div className="white-spinner-container">
          <div className="spinner-content">
            <h5>{this.props.topLine || "Processing"}</h5>
            <div className="white-spinner-progress">
              <span className="white-spinner">
                <div className="spinner">
                  <div className="bar-1" />
                  <div className="bar-2" />
                  <div className="bar-3" />
                  <div className="bar-4" />
                  <div className="bar-5" />
                </div>
              </span>
            </div>
            <p>{this.props.bottomLine}</p>
          </div>
          <div className="gray-overlay" />
        </div>
      </div>
    );
  }
}

export default withRouter(WhiteSpinner);
