import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import freeSuppliesData from "./FreeShippingSuppliesData";
import "./FreeShippingSuppliesItems/FreeShippingSuppliesItems.css";
import AlertMessage from "../Subcomponents/AlertMessage";
import FreeSuppliesPriorityMailPopover from "../Popovers/FreeSuppliesPriorityMailPopover";
import axios from "axios";
import callToAction from "../../click-n-ship/images/freeSupplies/callToActionImage.jpg";
const FreeShippingSuppliesModal = (props) => {
  const {
    targetName,
    toggleSpinner,
    freeSuppliesCurrentlySaved,
    fetchCart
  } = props;

  const [modal, setModal] = useState(false);

  const [isReview, setIsReview] = useState(false);

  const [selectedFreeSupplies, setSelectedFreeSupplies] = useState([]);

  const [displaySelectItemsError, setDisplaySelectItemsError] = useState(false);

  const [displayShippingPriceError, setDisplayShippingPriceError] = useState(
    false
  );

  const [displayShippingOptionError, setDisplayShippingOptionError] = useState(
    false
  );

  const [
    displayAddNewCartItemsError,
    setDisplayAddNewCartItemsError
  ] = useState(false);

  const [
    displaySaveFreeSuppliesError,
    setDisplaySaveFreeSuppliesError
  ] = useState(false);

  const [focusOnError, setFocusOnError] = useState(false);

  const [freeSupplies, setFreeSupplies] = useState(
    JSON.parse(JSON.stringify(freeSuppliesData))
  );

  const [selectedShippingOption, setSelectedShippingOption] = useState("");

  const [priorityPrice, setPriorityPrice] = useState("0.00");

  const [
    checkIfAllFreeSuppliesSaved,
    setCheckIfAllFreeSuppliesSaved
  ] = useState(false);

  const selectItemsErrorMessage =
    "Please select at least one item before continuing.";
  const fetchShippingPriceErrorMessage =
    "We're sorry. An unexpected error has occurred.";
  const shippingOptionErrorMessage = "Please select a shipping option.";
  const addNewCartItemsErrorMessage =
    "We're sorry. An unexpected error has occurred.";
  const saveFreeSuppliesErrorMessage =
    "We're sorry. An unexpected error has occurred.";

  // When the modal is opened, set the quantities to whatever is already
  // in the cart
  useEffect(() => {
    if (modal) {
      toggleSpinner(true);
      let newFreeSupplies = JSON.parse(JSON.stringify(freeSuppliesData));
      for (let i in newFreeSupplies) {
        for (let j in freeSuppliesCurrentlySaved) {
          if (
            Number(newFreeSupplies[i].productId) ===
            freeSuppliesCurrentlySaved[j].productId.productId
          ) {
            newFreeSupplies[i].selectedQuantity =
              freeSuppliesCurrentlySaved[j].itemQty;
          }
        }
      }
      setFreeSupplies(newFreeSupplies);
      toggleSpinner(false);
    }
  }, [modal]);

  // Hide any error message present whenever an item quantity is changed
  useEffect(() => {
    setDisplaySelectItemsError(false);
    setDisplayShippingPriceError(false);
  }, [freeSupplies]);

  // Hide any error message present whenever the shipping method is changed
  useEffect(() => {
    setDisplayShippingOptionError(false);
    setDisplayAddNewCartItemsError(false);
    setDisplaySaveFreeSuppliesError(false);
  }, [selectedShippingOption]);

  // Focus on an error message if present
  useEffect(() => {
    if (focusOnError) {
      if (displaySelectItemsError) {
        document.getElementById("selectItemsErrorId").focus();
      } else if (displayShippingPriceError) {
        document.getElementById("shippingPriceErrorId").focus();
      } else if (displayShippingOptionError) {
        document.getElementById("shippingOptionErrorId").focus();
      } else if (displayAddNewCartItemsError) {
        document.getElementById("addNewCartItemsErrorId").focus();
      } else if (displaySaveFreeSuppliesError) {
        document.getElementById("saveFreeSuppliesErrorId").focus();
      }
      setFocusOnError(false);
    }
  }, [
    focusOnError,
    displaySelectItemsError,
    displayShippingPriceError,
    displayShippingOptionError,
    displayAddNewCartItemsError,
    displaySaveFreeSuppliesError
  ]);

  // Hide the modal and refresh the cart if all axios calls to save
  // the selected free supplies are successful
  useEffect(() => {
    if (checkIfAllFreeSuppliesSaved) {
      setCheckIfAllFreeSuppliesSaved(false);
      if (!displaySaveFreeSuppliesError) {
        toggle();
        fetchCart();
      }
    }
  }, [checkIfAllFreeSuppliesSaved, displaySaveFreeSuppliesError]);

  const toggle = () => {
    setSelectedFreeSupplies([]);
    setIsReview(false);
    setDisplaySelectItemsError(false);
    setDisplayShippingPriceError(false);
    setDisplayShippingOptionError(false);
    setDisplayAddNewCartItemsError(false);
    setDisplaySaveFreeSuppliesError(false);
    setFocusOnError(false);
    setFreeSupplies(JSON.parse(JSON.stringify(freeSuppliesData)));
    setSelectedShippingOption("");
    setPriorityPrice("0.00");
    setModal(!modal);
  };

  const getSelectedFreeSuppliesList = () => {
    // Create a new array with all object properties of only the selected
    // free supplies (to display in a table),
    // and another array with only the object properties needed to fetch
    // the shipping charge
    let selectedFreeSuppliesArray = [];
    let dataToSend = {};
    dataToSend.items = [];
    for (const i in freeSupplies) {
      if (freeSupplies[i].selectedQuantity > 0) {
        selectedFreeSuppliesArray.push(freeSupplies[i]);
        dataToSend.items.push({
          productId: freeSupplies[i].productId,
          quantity:
            freeSupplies[i].selectedQuantity * freeSupplies[i].multiplier
        });
      }
    }
    setSelectedFreeSupplies(selectedFreeSuppliesArray);
    // Display an error if no free supplies were selected;
    // otherwise, get the shipping price
    if (selectedFreeSuppliesArray.length === 0) {
      setDisplaySelectItemsError(true);
      setFocusOnError(true);
    } else {
      getShippingPrice(dataToSend);
    }
  };

  const getShippingPrice = (dataToSend) => {
    toggleSpinner(true);
    axios
      .post("/go/cnsrest/freeSuppliesPrices", JSON.stringify(dataToSend), {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        setPriorityPrice(getTotalShippingCharge(response));
        setDisplayShippingPriceError(false);
        setIsReview(true);
        document.getElementById("select-supplies-review-text").focus();
      })
      .catch((error) => {
        console.log(error);
        setDisplayShippingPriceError(true);
        setFocusOnError(true);
      })
      .finally(() => {
        toggleSpinner(false);
      });
  };

  const getTotalShippingCharge = (response) => {
    if (
      response &&
      response.data &&
      response.data.expeditedShipping &&
      response.data.expeditedShipping.items
    ) {
      const itemsArray = response.data.expeditedShipping.items;
      let totalShippingCharge = 0.0;
      for (let i in itemsArray) {
        totalShippingCharge += itemsArray[i].shipCharge;
      }
      return totalShippingCharge.toFixed(2);
    }
    return "0.00";
  };

  const backToSelectedSupplyView = () => {
    setSelectedShippingOption("");
    setDisplayShippingOptionError(false);
    setDisplayAddNewCartItemsError(false);
    setDisplaySaveFreeSuppliesError(false);
    setIsReview(false);
  };

  const onChangeValueShipping = (e) => {
    // e.preventDefault();
    setSelectedShippingOption(e.currentTarget.value);
  };

  // Generate a new cart ID for each selected free supply
  const addNewCartItems = () => {
    const numberOfDistinctSuppliesPlusShipping =
      selectedFreeSupplies.length + 1;
    if (selectedShippingOption) {
      toggleSpinner(true);
      Promise.all([
        axios.post(
          "/go/cnsrest/freeSupplies?numOfCartItems=" +
            numberOfDistinctSuppliesPlusShipping,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        ),
        axios.get("/go/cnsrest/fetchPreferences?fetchPrefQueryString=")
      ])
        .then((response) => {
          if (
            response &&
            response[1] &&
            response[1].data &&
            Object.keys(response[1].data.fieldErrors).length === 0 &&
            response[1].data.actionErrors.length === 0
          ) {
            saveFreeSupplies(response);
          } else {
            setDisplayAddNewCartItemsError(true);
            setFocusOnError(true);
            toggleSpinner(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setDisplayAddNewCartItemsError(true);
          setFocusOnError(true);
          toggleSpinner(false);
        });
    } else {
      // Display an error if no shipping option was selected
      setDisplayShippingOptionError(true);
      setFocusOnError(true);
    }
  };

  // Save each selected free supply as a new cart item
  const saveFreeSupplies = (responseWithPrefs) => {
    // Hide errors that may have occurred on a previous attempt to save
    // the free supplies
    setDisplaySaveFreeSuppliesError(false);

    const shippingOption = [
      {
        productId: selectedShippingOption
      }
    ];
    const selectedFreeSuppliesWithShipping = shippingOption.concat(
      selectedFreeSupplies
    );
    // https://stackoverflow.com/questions/12409299/how-to-get-current-formatted-date-dd-mm-yyyy-in-javascript-and-append-it-to-an-i
    // https://stackoverflow.com/questions/1267283/how-can-i-pad-a-value-with-leading-zeros
    let today = new Date();
    const dd = ("00" + String(today.getDate())).slice(-2);
    const mm = ("00" + String(today.getMonth() + 1)).slice(-2); // Jan. is 0
    var yyyy = String(today.getFullYear());
    today = mm + "/" + dd + "/" + yyyy;

    // First, pass down the shipping option.  This will cause
    // all previous free supplies to be deleted.
    axios
      .post(
        "/go/cnsrest/saveLabelInfo",
        JSON.stringify(
          getCartObjectsToSend(
            responseWithPrefs,
            selectedFreeSuppliesWithShipping[0],
            0,
            today
          )
        ),
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(async (response) => {
        if (
          response &&
          response.data &&
          Object.keys(response.data.fieldErrors).length === 0 &&
          response.data.actionErrors.length === 0
        ) {
          // Loop through all selected free supplies, saving them one
          // at a time
          for (let i = 1; i < selectedFreeSuppliesWithShipping.length; i++) {
            await axios
              .post(
                "/go/cnsrest/saveLabelInfo",
                JSON.stringify(
                  getCartObjectsToSend(
                    responseWithPrefs,
                    selectedFreeSuppliesWithShipping[i],
                    i,
                    today
                  )
                ),
                {
                  headers: {
                    "Content-Type": "application/json"
                  }
                }
              )
              .then((response) => {
                if (
                  response &&
                  response.data &&
                  Object.keys(response.data.fieldErrors).length === 0 &&
                  response.data.actionErrors.length === 0
                ) {
                } else {
                  setDisplaySaveFreeSuppliesError(true);
                  setFocusOnError(true);
                }
              })
              .catch((error) => {
                console.log(error);
                setDisplaySaveFreeSuppliesError(true);
                setFocusOnError(true);
              });
          }
        } else {
          setDisplaySaveFreeSuppliesError(true);
          setFocusOnError(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setDisplaySaveFreeSuppliesError(true);
        setFocusOnError(true);
      })
      .finally(() => {
        setCheckIfAllFreeSuppliesSaved(true);
        toggleSpinner(false);
      });
  };

  const getCartObjectsToSend = (response, selectedFreeSupply, index, today) => {
    const cnsCart = [
      {
        batchNbr: 130542328,
        cnsCartId: response[0].data.arrOfCartIds[index],
        cnsCartTypeId:
          selectedFreeSupply.productId === "700" ||
          selectedFreeSupply.productId === "701"
            ? "5"
            : "4",
        returnAddress: {
          phoneId: -1,
          faxId: -1,
          emailId: -1,
          addressId: -1,
          contactId: -1,
          countryId: 840,
          addressCategory: "4",
          firstName: response[1].data.userPref.userReturnAddr.firstName || "",
          middleInit: response[1].data.userPref.userReturnAddr.middleInit || "",
          lastName: response[1].data.userPref.userReturnAddr.lastName || "",
          companyName:
            response[1].data.userPref.userReturnAddr.companyName || "",
          line1Addr: response[1].data.userPref.userReturnAddr.line1Addr || "",
          line2Addr: response[1].data.userPref.userReturnAddr.line2Addr || "",
          cityName: response[1].data.userPref.userReturnAddr.cityName || "",
          stateId: response[1].data.userPref.userReturnAddr.stateId,
          postalCode: response[1].data.userPref.userReturnAddr.postalCode || "",
          urbanizationCode:
            response[1].data.userPref.userReturnAddr.urbanizationCode || "",
          emailAddress: "aaaaa@gmail.com",
          phoneNbr: "",
          refNbr: "",
          line3Addr: ""
        },
        labelCost: {
          cnsCartId: response[0].data.arrOfCartIds[index],
          insuranceCostAmt: 0,
          postageAmt: 8.95,
          ttlShipCostAmt: 8.95,
          webtoolsRate: 8.95
        },
        ptsTrackingEmail: "",
        ptsTxtPhoneNbr: "",
        shipFromPostalCode: "",
        deliveryAddress: {
          emailAddress: "",
          refNbr: "112233",
          carrierRoute: "C06099",
          firstName: response[1].data.userPref.userReturnAddr.firstName || "",
          middleInit: response[1].data.userPref.userReturnAddr.middleInit || "",
          lastName: response[1].data.userPref.userReturnAddr.lastName || "",
          companyName:
            response[1].data.userPref.userReturnAddr.companyName || "",
          line1Addr: response[1].data.userPref.userReturnAddr.line1Addr || "",
          line2Addr: response[1].data.userPref.userReturnAddr.line2Addr || "",
          cityName: response[1].data.userPref.userReturnAddr.cityName || "",
          stateId: response[1].data.userPref.userReturnAddr.stateId,
          postalCode: response[1].data.userPref.userReturnAddr.postalCode || "",
          urbanizationCode:
            response[1].data.userPref.userReturnAddr.urbanizationCode || "",
          addressId: -1,
          contactId: -1,
          emailId: -1,
          phoneId: -1,
          faxId: -1,
          countryId: 840,
          poFlag: "",
          province: "",
          phoneNbr: ""
        },
        toNote: "",
        shippingDate: today,
        labelPkg: {
          largePackageInd: "N",
          pkgLengthQty: "",
          pkgWidthQty: "",
          pkgHeightQty: "",
          pkgGirthQty: "",
          pkgValueAmt: "",
          pkgShape: "R",
          weightOzQty: 3
        },
        labelInd: {
          flatRateInd: "Y",
          ptsNotifyInd: "Y",
          emailUpdatesInd: "Y",
          ptsTxtUpdatesInd: "N",
          ptsUpdateType: "S",
          ptsExpectedDelvInd: "Y",
          ptsDayOfDelvInd: "Y",
          ptsDeliveredToAddrInd: "Y",
          ptsPickupAvailableInd: "Y",
          ptsAlertsOtherInd: "Y",
          ptsPkgInTransitInd: "Y",
          shipNotifyInd: "N",
          shipFromOtherZipInd: "N",
          insRestrictedDeliveryInd: "N",
          insuranceInd: "N",
          additionalService: "",
          noWkndDlvyInd: "N",
          crematedRemainsInd: "N",
          sundayDeliveryInd: "N",
          batchInd: "N"
        },
        eSOFAllowed: "N",
        productId: {
          productId: selectedFreeSupply.productId
        },
        itemQty: selectedFreeSupply.selectedQuantity
      }
    ];

    return {
      fetchServicesData: {
        cnsCartList: cnsCart
      },
      saveLabelInfoAdditionalData: {
        cnsCartId: response[0].data.arrOfCartIds[index],
        productId: selectedFreeSupply.productId,
        batchCount: 0,
        eSOFAllowed: "N"
      }
    };
  };

  return (
    <React.Fragment>
      {!freeSuppliesCurrentlySaved ||
      freeSuppliesCurrentlySaved.length === 0 ? (
        <div
          style={{
            border: "1px solid #ddd",
            borderRadius: "5px",
            padding: "30px",
            marginBottom: "25px"
          }}
        >
          <div className="row">
            <div className="col-sm-8 text-left">
              <span className="need_shipping-supplies">
                <h2 style={{ "font-weight": "bold", paddingBottom: "5px" }}>
                  Do you need Shipping Supplies?
                </h2>
              </span>
              <p style={{ paddingBottom: "5px" }}>
                You can add boxes, envelopes, tape and labels to your cart for
                free when shipping USPS Connect!
              </p>
              <button
                type="button"
                className="btn-primary button--white view-all-btn"
                tabIndex="0"
                id={targetName}
                onClick={toggle}
              >
                Order Supplies
              </button>
            </div>
            <div className="col-sm-4 text-right">
              <img src={callToAction} width="150px" height="120px"></img>
            </div>
          </div>
        </div>
      ) : (
        <button
          type="button"
          className="inline-link-edit button-link"
          onClick={toggle}
          aria-label="Edit free supplies"
        >
          <strong className="d-none d-md-inline">Edit</strong>
        </button>
      )}
      <Modal size="lg" className="free-sup-modal" isOpen={modal}>
        <div className="modal-content modal-container">
          <div
            className="modal-header"
            style={{
              marginBottom: "10px"
            }}
          >
            <button
              type="button"
              className="close button-link"
              id="close"
              data-dismiss="modal"
              tabIndex="0"
              onClick={toggle}
            >
              <span className="visuallyhidden">Close Modal</span>
            </button>
            <h3
              className="modal-title"
              style={{
                paddingBottom: "0"
              }}
            >
              Free Shipping Supplies
            </h3>
            <p
              id="select-supplies-review-text"
              className="review"
              tabIndex="-1"
            >
              {!isReview
                ? "Select the Shipping Supplies you would like to add to your cart."
                : "Review your selected shipping supplies and choose your shipping method."}
            </p>
          </div>

          <div className="modal-body">
            {!isReview ? (
              <div className="body-content">
                {freeSupplies.map((item, index) => (
                  <div key={item.productId} className="free-Shipping-item">
                    <div className="image-container">
                      <img
                        className="freeSuppliesImg"
                        src={item.imagePath}
                        alt={item.imageAlt}
                      />
                    </div>
                    <div className="detail">
                      <strong>
                        <p className="supname">{item.productClassDesc}</p>
                      </strong>
                      <p className="supdesc">
                        <strong>{item.productDesc}</strong>
                      </p>
                      <p className="supdimension">{item.boxDimensions}</p>
                    </div>
                    <div className="quantity">
                      <p className="supQuanHeader">
                        <strong>Quantity</strong>
                      </p>
                    </div>
                    <div className="supInput">
                      <input
                        type="text"
                        className="selected-input-quant"
                        name="inInput"
                        style={{
                          border: "0",
                          padding: "0",
                          borderRadius: "0",
                          margin: "0",
                          background: "transparent"
                        }}
                        value={item.selectedQuantity}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          setFreeSupplies((prevState) => {
                            let freeSuppliesNew = [...prevState];
                            if (value <= 999) {
                              let zeroTruncatedValue = parseInt(
                                value,
                                10
                              ).toString();
                              if (zeroTruncatedValue === "NaN") {
                                zeroTruncatedValue = "";
                              }
                              freeSuppliesNew[
                                index
                              ].selectedQuantity = zeroTruncatedValue;
                            }
                            return freeSuppliesNew;
                          });
                        }}
                      />

                      <div className="arrows">
                        <p
                          className="upArrow"
                          onClick={() =>
                            setFreeSupplies((prevState) => {
                              let freeSuppliesNew = [...prevState];
                              if (
                                freeSuppliesNew[index].selectedQuantity < 999
                              ) {
                                freeSuppliesNew[index].selectedQuantity =
                                  Number(
                                    freeSuppliesNew[index].selectedQuantity
                                  ) + 1;
                              }
                              return freeSuppliesNew;
                            })
                          }
                        >
                          &#9650;
                        </p>
                        <p
                          className="downArrow"
                          onClick={() =>
                            setFreeSupplies((prevState) => {
                              let freeSuppliesNew = [...prevState];
                              if (freeSuppliesNew[index].selectedQuantity > 0) {
                                freeSuppliesNew[index].selectedQuantity =
                                  Number(
                                    freeSuppliesNew[index].selectedQuantity
                                  ) - 1;
                              }
                              return freeSuppliesNew;
                            })
                          }
                        >
                          &#9660;
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                <AlertMessage
                  spanId="selectItemsErrorId"
                  displayAlertMessage={displaySelectItemsError}
                  alertMessage={selectItemsErrorMessage}
                />
                <AlertMessage
                  spanId="shippingPriceErrorId"
                  displayAlertMessage={displayShippingPriceError}
                  alertMessage={fetchShippingPriceErrorMessage}
                />
                <button
                  type="button"
                  className="btn-primary supplies-next-btn"
                  tabIndex="0"
                  id={targetName}
                  onClick={getSelectedFreeSuppliesList}
                >
                  Next
                </button>
              </div>
            ) : (
              <div className="body-content">
                <div className="supply-header">
                  <div className="header-block">
                    <span>
                      <strong>
                        <p className="shippingHeader">Product</p>
                      </strong>
                    </span>
                  </div>
                  <div className="header-block">
                    <span>
                      <strong>
                        <p className="shippingHeader">Quantity</p>
                      </strong>
                    </span>
                  </div>
                </div>
                {selectedFreeSupplies.map((item) => (
                  <div
                    key={item.productId}
                    className="free-Shipping-item selected-Item"
                  >
                    <div className="image-container">
                      <img
                        className="freeSuppliesImg"
                        src={item.imagePath}
                        alt={item.imageAlt}
                      />
                    </div>
                    <div className="detail">
                      <strong>
                        <p className="supname">{item.productClassDesc}</p>
                      </strong>
                      <p className="supdesc">
                        <strong>{item.productDesc}</strong>
                      </p>
                      <p className="supdimension">{item.boxDimensions}</p>
                    </div>
                    <div className="quant">
                      <p className="selectedQuan">{item.selectedQuantity}</p>
                    </div>
                  </div>
                ))}
                <div className="shipping-section">
                  <strong>
                    <p className="shippingHeader">Select your Shipping</p>
                  </strong>
                  <div className="radio-shipping">
                    <label className="radio-input">
                      <input
                        type="radio"
                        name="shippingRadio"
                        className="radio-selector"
                        value="700"
                        checked={selectedShippingOption === "700"}
                        onChange={onChangeValueShipping}
                      />
                      <strong>
                        <span className="supname parcel-select">
                          Parcel Select
                        </span>
                        <span className="supdimension">Free</span>
                        <p className="supdimension arrival-status">
                          Arrives in 5 to 7 business days
                        </p>
                      </strong>
                    </label>
                    <label className="radio-input">
                      <input
                        type="radio"
                        name="shippingRadio"
                        className="radio-selector"
                        value="701"
                        checked={selectedShippingOption === "701"}
                        onChange={onChangeValueShipping}
                      />
                      <strong>
                        <span className="supname parcel-select">
                          Priority Mail®
                        </span>
                        <span className="supdimension ">
                          &#36;
                          {priorityPrice}{" "}
                          <FreeSuppliesPriorityMailPopover
                            priorityPrice={priorityPrice}
                            targetName="freeSuppliesPriorityMailIcon"
                          />
                        </span>
                        <p className="supdimension arrival-status">
                          Arrives in 2 to 3 business days. Priority Mail
                          shipping charge will be processed separately from the
                          rest of your order
                        </p>
                      </strong>
                    </label>
                  </div>
                </div>

                <AlertMessage
                  spanId="shippingOptionErrorId"
                  displayAlertMessage={displayShippingOptionError}
                  alertMessage={shippingOptionErrorMessage}
                />
                <AlertMessage
                  spanId="addNewCartItemsErrorId"
                  displayAlertMessage={displayAddNewCartItemsError}
                  alertMessage={addNewCartItemsErrorMessage}
                />
                <AlertMessage
                  spanId="saveFreeSuppliesErrorId"
                  displayAlertMessage={displaySaveFreeSuppliesError}
                  alertMessage={saveFreeSuppliesErrorMessage}
                />
                <div className="footer-btns">
                  <button
                    type="button"
                    className="btn-primary button--white sup-btn"
                    tabIndex="0"
                    id={targetName}
                    onClick={backToSelectedSupplyView}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn-primary supplies-next-btn sup-btn"
                    tabIndex="0"
                    id={targetName}
                    onClick={addNewCartItems}
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default FreeShippingSuppliesModal;
