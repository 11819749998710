import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import AddressBook from "../Modals/AddressBook";
import PackageWeightPopover from "../Popovers/PackageWeightPopover";
import GirthModal from "../Modals/GirthModal";
import RemoveContactModal from "../Modals/RemoveContactModal";
import PriorityMailPopover from "../Popovers/PriorityMailPopover";
import TextMessagePopover from "../Popovers/TextMessagePopover";
import AddressStandardizedPopover from "../Popovers/AddressStandardizedPopover";
import ReceiptPrintLabelsPopover from "../Popovers/ReceiptPrintLabelsPopover";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
import AssistiveError from "../Subcomponents/AssistiveError";
import StatesTerritoriesList from "../Subcomponents/StatesTerritoriesList";
import validation from "../../utils/validation";
import update from "immutability-helper";
import * as analytics from "../Analytics/Analytics.js";
import InputFields from "../Subcomponents/InputFields";
import UspsConnectMap from "../Subcomponents/UspsConnectMap/UspsConnectMap";

import sortIcon from "../../click-n-ship/images/sort-icon.svg";
import hidePostagePriceUtils from "../../utils/hidePostagePrice";

class Preferences extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      isLoaded: false,
      // Edit buttons
      editPackageOptions: false,
      editHidePostagePrice: false,
      editShipmentNotifications: false,
      editShippingMessage: false,
      editPrintSettings: false,
      editReferenceNumber: false,
      editReturnAddress: false,
      editZipCode: false,
      // Package Options
      flatRateInd: "Y",
      pounds: "",
      ounces: "",
      weightOzQty: 0,
      largePackageInd: "",
      pkgLengthQty: "",
      pkgWidthQty: "",
      pkgHeightQty: "",
      pkgShape: "",
      pkgGirthQty: "",
      selectedService: "PRI",
      selectedServiceMobile: "Priority Mail&reg;",
      showGrid: false,
      domesticProductId: {
        productId: ""
      },
      serviceInfoIconId: undefined,
      // Hide Postage Price
      stealthPostagePMInd: "",
      stealthPostagePMEInd: "",
      stealthPostageRGInd: "",
      stealthPostageFCPSInd: "",
      stealthPostagePSGInd: "",
      stealthPostageCLInd: "",
      stealthPostageCLMInd: "",
      hidePostageAddresses: {},
      hidePostageAddressIdsToDelete: [],
      // Shipment Notifications
      ptsNotifyInd: "",
      emailUpdatesInd: "",
      ptsTxtUpdatesInd: "",
      ptsTrackingEmail: "",
      ptsTxtPhoneNbr: "",
      ptsExpectedDelvInd: "",
      ptsDayOfDelvInd: "",
      ptsDeliveredToAddrInd: "",
      ptsPickupAvailableInd: "",
      ptsAlertsOtherInd: "",
      ptsPkgInTransitInd: "",

      ptsUpdateType: "",
      // Notify Recipient
      shipNotifyInd: "",
      toNote: "",
      // Print Settings
      printOptions: "",
      // Reference number
      refNbr: "",
      // Return address
      saveReturnToAddressBook: false,
      // Shipping ZIP code
      shipFromPostalCode: "",
      // Error messages
      errors: {
        weight: "",
        length: "",
        width: "",
        height: "",
        girth: "",
        shipEmail: "",
        shipText: "",
        returnFirstName: "",
        returnLastName: "",
        returnCompany: "",
        returnStreetAddr: "",
        returnAptSuiteOther: "",
        returnCity: "",
        returnState: "",
        returnZipCode: "",
        shipOtherZip: "",
        globalError: []
      },
      editUSPSConnect: false,
      connectLocalRadius: ""
    };
  }

  showInfo = (e) => {
    e.preventDefault();
  };

  componentDidMount() {
    this.props.toggleSpinner(true);
    this.refreshData();
    // Only want focusOnTop set once, so do this outside of refreshData()
    this.setState({
      focusOnTop: true
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.focusOnError) {
      // Focus on the first field with an error
      if (this.errors["weight"]) {
        // Package Options
        document.getElementById("pounds-input-a11y").focus();
      } else if (this.errors["length"]) {
        document.getElementById("pkgLengthQtyId-a11y").focus();
      } else if (this.errors["width"]) {
        document.getElementById("pkgWidthQtyId-a11y").focus();
      } else if (this.errors["height"]) {
        document.getElementById("pkgHeightQtyId-a11y").focus();
      } else if (this.errors["girth"]) {
        document.getElementById("pkgGirthQtyId-a11y").focus();
      } else if (this.errors["shipEmail"]) {
        // Shipment Notifications
        document.getElementById("shipEmailId-a11y").focus();
      } else if (this.errors["shipText"]) {
        document.getElementById("shipTextId-a11y").focus();
      } else if (this.errors["returnFirstName"]) {
        // Return Address
        document.getElementById("return-address-first-name-a11y").focus();
      } else if (this.errors["returnLastName"]) {
        document.getElementById("return-address-last-name-a11y").focus();
      } else if (this.errors["returnCompany"]) {
        document.getElementById("return-address-company-a11y").focus();
      } else if (this.errors["returnStreetAddr"]) {
        document.getElementById("return-address-street-address-a11y").focus();
      } else if (this.errors["returnAptSuiteOther"]) {
        document.getElementById("return-address-apt-a11y").focus();
      } else if (this.errors["returnCity"]) {
        document.getElementById("return-address-city-a11y").focus();
      } else if (this.errors["returnState"]) {
        document.getElementById("return-address-state-a11y").focus();
      } else if (this.errors["returnZipCode"]) {
        document.getElementById("return-address-zip-a11y").focus();
      }
      // Reset field
      this.setState({
        focusOnError: false
      });
    }
    if (this.state.focusOnTop && this.state.isLoaded) {
      // Focus on the top element on the page after loading
      document.getElementById("preferences-top-id").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
    // Focus on the top element in the appropriate section
    if (this.state.editPackageOptionsFocus) {
      document.getElementById("package-options-top").focus();
      this.setState({
        editPackageOptionsFocus: false
      });
    } else if (this.state.editHidePostagePriceFocus) {
      if (this.state.editHidePostagePrice) {
        document.getElementById("hide-postage-price-top-id-edit").focus();
      } else {
        document.getElementById("hide-postage-price-top-id").focus();
      }
      this.setState({
        editHidePostagePriceFocus: false
      });
    } else if (this.state.editShipmentNotificationsFocus) {
      if (this.state.editShipmentNotifications) {
        document.getElementById("shipment-notifications-top-id-edit").focus();
      } else {
        document.getElementById("shipment-notifications-top-id").focus();
      }
      this.setState({
        editShipmentNotificationsFocus: false
      });
    } else if (this.state.editShippingMessageFocus) {
      document.getElementById("shipping-message-top-id").focus();
      this.setState({
        editShippingMessageFocus: false
      });
    } else if (this.state.editPrintSettingsFocus) {
      document.getElementById("print-preferences-top-id").focus();
      this.setState({
        editPrintSettingsFocus: false
      });
    } else if (this.state.editReferenceNumberFocus) {
      document.getElementById("reference-number-top-id").focus();
      this.setState({
        editReferenceNumberFocus: false
      });
    } else if (this.state.editReturnAddressFocus) {
      document.getElementById("return-address-top-id").focus();
      this.setState({
        editReturnAddressFocus: false
      });
    } else if (this.state.editZipCodeFocus) {
      document.getElementById("zip-code-top-id").focus();
      this.setState({
        editZipCodeFocus: false
      });
    } else if (this.state.editUSPSConnectFocus) {
      document.getElementById("connect-local-id").focus();
      this.setState({
        editUSPSConnectFocus: false
      });
    }
    // Fetch drop-off locations whenever the user changes the radius
    // in the dropdown
    if (
      this.state.connectLocalRadiusTemp !== undefined &&
      this.state.connectLocalRadiusTemp !== prevState.connectLocalRadiusTemp
    ) {
      this.setFetchDropOffLocations(true);
    }
    // Fetch the info of contacts with which to hide the postage whenever the
    // list may need to be updated
    if (this.state.fetchHidePostageAddresses) {
      this.setState({
        fetchHidePostageAddresses: false
      });
      this.displayHidePostageAddresses();
    }
  }

  setFetchDropOffLocations = (fetchDropOffLocations) => {
    this.setState({
      fetchDropOffLocations: fetchDropOffLocations
    });
  };

  ////////////////////////////Package Options
  clickEditPackageOptions = (e) => {
    e.preventDefault();
    this.setState({
      editPackageOptions: true,
      editPackageOptionsFocus: true
    });
    this.state.errors.weight !== ""
      ? this.clearError("weight")
      : this.clearError("");
    this.state.errors.length !== ""
      ? this.clearError("length")
      : this.clearError("");
    this.state.errors.width !== ""
      ? this.clearError("width")
      : this.clearError("");
    this.state.errors.height !== ""
      ? this.clearError("height")
      : this.clearError("");
    this.state.errors.girth !== ""
      ? this.clearError("girth")
      : this.clearError("");
  };

  changeIsOver12Checked = (e) => {
    if (e.currentTarget.checked) {
      this.setState({
        largePackageIndTemp: "Y"
      });
    } else {
      this.setState({
        largePackageIndTemp: "N",
        pkgHeightQtyTemp: "",
        pkgLengthQtyTemp: "",
        pkgWidthQtyTemp: "",
        pkgShapeTemp: "R",
        pkgGirthQtyTemp: ""
      });
    }
  };

  changeGirth = (e) => {
    if (e.currentTarget.checked) {
      this.setState({
        pkgShapeTemp: "NR"
      });
    } else {
      this.setState({
        pkgShapeTemp: "R",
        pkgGirthQtyTemp: ""
      });
    }
  };

  changeFlatRateInd = (indicator) => {
    if (indicator === "flat") {
      this.setState({
        flatRateIndTemp: "Y",
        poundsTemp: "",
        ouncesTemp: "",
        largePackageIndTemp: "N",
        pkgLengthQtyTemp: "",
        pkgWidthQtyTemp: "",
        pkgHeightQtyTemp: "",
        pkgShapeTemp: "R",
        pkgGirthQtyTemp: ""
      });
    } else {
      this.setState({ flatRateIndTemp: "N" });
    }
  };

  isWeightRequired = () => {
    return this.state.flatRateIndTemp !== undefined
      ? this.state.flatRateIndTemp === "N"
      : this.state.flatRateInd === "N";
  };

  changeUserPref = (input) => (event) => {
    this.setState({
      [input]: event
    });
  };

  changeView = (clickedItem) => {
    this.setState({
      showGrid: clickedItem === "Grid",
      serviceInfoIconId: undefined
    });
  };

  changeService = (e, item) => {
    e.preventDefault();
    this.setState({ serviceSelected: item });
  };

  validateOptions = (name, inputs) => {
    let errorsFound = false;
    let errorObject = {};

    if (name === "editPackageOptions") {
      if (
        inputs.flatRateInd !== "Y" &&
        !(inputs.pounds > 0) &&
        !(inputs.ounces > 0)
      ) {
        errorObject["weight"] =
          "Please enter package weight or select flat rate.";
        errorsFound = true;
      } else if (
        inputs.flatRateInd !== "Y" &&
        ((String(inputs.pounds) &&
          String(inputs.pounds).length > 0 &&
          !validation.numbers.test(inputs.pounds)) ||
          (String(inputs.ounces) &&
            String(inputs.ounces).length > 0 &&
            !validation.numbers.test(inputs.ounces)))
      ) {
        errorObject["weight"] = "Weight must be entered as a whole number.";
        errorsFound = true;
      }
      if (inputs.largePackageInd === "Y" && !inputs.pkgHeightQty) {
        errorObject["height"] = "Please enter height (in).";
        errorsFound = true;
      }
      if (inputs.largePackageInd === "Y" && !inputs.pkgWidthQty) {
        errorObject["width"] = "Please enter width (in).";
        errorsFound = true;
      }
      if (inputs.largePackageInd === "Y" && !inputs.pkgLengthQty) {
        errorObject["length"] = "Please enter length (in).";
        errorsFound = true;
      }
      if (inputs.pkgShape === "NR" && !inputs.pkgGirthQty) {
        errorObject["girth"] = "Please enter girth (in).";
        errorsFound = true;
      }
    } else if (name === "editShipmentNotifications") {
      if (inputs.ptsNotifyInd === "Y") {
        if (!inputs.ptsTrackingEmail && !inputs.ptsTxtPhoneNbr) {
          errorObject["shipEmail"] = "Please enter email address.";
          errorObject["shipText"] = "Please enter phone number.";
          errorsFound = true;
        } else {
          if (
            inputs.ptsTrackingEmail &&
            !validation.email.test(inputs.ptsTrackingEmail)
          ) {
            errorObject["shipEmail"] =
              "Please enter a valid tracking notification email.";
            errorsFound = true;
          }
          if (inputs.ptsTxtPhoneNbr && inputs.ptsTxtPhoneNbr.length !== 10) {
            errorObject["shipText"] =
              "Please enter a valid tracking US phone number in the following format: 987-654-3210";
            errorsFound = true;
          }
        }
      }
    }

    this.errors = errorObject;
    this.setInputErrors("errorObject")(errorObject);
    return errorsFound;
  };

  // All "Temp" variables (ex. flatRateIndTemp) are defined only if the user
  // has modified the value; otherwise, the unedited value obtained from the
  // fetch preferences call (ex.flatRateInd) is used
  saveOptions = (e, name) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    let userPref = {
      // Package Options
      flatRateInd:
        name === "editPackageOptions" &&
        this.state.flatRateIndTemp !== undefined
          ? this.state.flatRateIndTemp
          : this.state.flatRateInd,
      pounds:
        name === "editPackageOptions" && this.state.poundsTemp !== undefined
          ? this.state.poundsTemp
          : this.state.pounds,
      ounces:
        name === "editPackageOptions" && this.state.ouncesTemp !== undefined
          ? this.state.ouncesTemp
          : this.state.ounces,
      weightOzQty: this.state.weightOzQty,
      largePackageInd:
        name === "editPackageOptions" &&
        this.state.largePackageIndTemp !== undefined
          ? this.state.largePackageIndTemp
          : this.state.largePackageInd,
      pkgLengthQty:
        name === "editPackageOptions" &&
        this.state.pkgLengthQtyTemp !== undefined
          ? this.state.pkgLengthQtyTemp
          : this.state.pkgLengthQty,
      pkgWidthQty:
        name === "editPackageOptions" &&
        this.state.pkgWidthQtyTemp !== undefined
          ? this.state.pkgWidthQtyTemp
          : this.state.pkgWidthQty,
      pkgHeightQty:
        name === "editPackageOptions" &&
        this.state.pkgHeightQtyTemp !== undefined
          ? this.state.pkgHeightQtyTemp
          : this.state.pkgHeightQty,
      pkgShape:
        name === "editPackageOptions" && this.state.pkgShapeTemp !== undefined
          ? this.state.pkgShapeTemp
          : this.state.pkgShape,
      pkgGirthQty:
        name === "editPackageOptions" &&
        this.state.pkgGirthQtyTemp !== undefined
          ? this.state.pkgGirthQtyTemp
          : this.state.pkgGirthQty,
      domesticProductId:
        name === "editPackageOptions" &&
        this.state.domesticProductIdTemp !== undefined
          ? this.state.domesticProductIdTemp
          : this.state.domesticProductId,
      // Hide Postage Price
      stealthPostagePMInd:
        name === "editHidePostagePrice" &&
        this.state.stealthPostagePMIndTemp !== undefined
          ? this.state.stealthPostagePMIndTemp
          : this.state.stealthPostagePMInd,
      stealthPostagePMEInd:
        name === "editHidePostagePrice" &&
        this.state.stealthPostagePMEIndTemp !== undefined
          ? this.state.stealthPostagePMEIndTemp
          : this.state.stealthPostagePMEInd,
      stealthPostageRGInd:
        name === "editHidePostagePrice" &&
        this.state.stealthPostageRGIndTemp !== undefined
          ? this.state.stealthPostageRGIndTemp
          : this.state.stealthPostageRGInd,
      stealthPostageFCPSInd:
        name === "editHidePostagePrice" &&
        this.state.stealthPostageFCPSIndTemp !== undefined
          ? this.state.stealthPostageFCPSIndTemp
          : this.state.stealthPostageFCPSInd,
      stealthPostagePSGInd:
        name === "editHidePostagePrice" &&
        this.state.stealthPostagePSGIndTemp !== undefined
          ? this.state.stealthPostagePSGIndTemp
          : this.state.stealthPostagePSGInd,
      stealthPostageCLInd:
        name === "editHidePostagePrice" &&
        this.state.stealthPostageCLIndTemp !== undefined
          ? this.state.stealthPostageCLIndTemp
          : this.state.stealthPostageCLInd,
      stealthPostageCLMInd:
        name === "editHidePostagePrice" &&
        this.state.stealthPostageCLMIndTemp !== undefined
          ? this.state.stealthPostageCLMIndTemp
          : this.state.stealthPostageCLMInd,
      stealthPostageAddressesToAdd:
        name === "editHidePostagePrice"
          ? this.getHidePostageAddressIdsToAdd()
          : [],
      stealthPostageAddressesToDelete:
        name === "editHidePostagePrice" &&
        this.state.hidePostageAddressIdsToDelete
          ? this.state.hidePostageAddressIdsToDelete
          : [],
      // Shipment Notifications
      ptsNotifyInd:
        name === "editShipmentNotifications" &&
        this.state.ptsNotifyIndTemp !== undefined
          ? this.state.ptsNotifyIndTemp
          : this.state.ptsNotifyInd,
      emailUpdatesInd:
        name === "editShipmentNotifications" &&
        this.state.ptsTrackingEmailTemp !== undefined
          ? this.state.ptsTrackingEmailTemp.length > 0
            ? "Y"
            : "N"
          : this.state.ptsTrackingEmail.length > 0
          ? "Y"
          : "N",
      ptsTrackingEmail:
        name === "editShipmentNotifications" &&
        this.state.ptsTrackingEmailTemp !== undefined
          ? this.state.ptsTrackingEmailTemp
          : this.state.ptsTrackingEmail,
      ptsTxtUpdatesInd:
        name === "editShipmentNotifications" &&
        this.state.ptsTxtPhoneNbrTemp !== undefined
          ? this.state.ptsTxtPhoneNbrTemp.length > 0
            ? "Y"
            : "N"
          : this.state.ptsTxtPhoneNbr.length > 0
          ? "Y"
          : "N",
      ptsTxtPhoneNbr:
        name === "editShipmentNotifications" &&
        this.state.ptsTxtPhoneNbrTemp !== undefined
          ? this.state.ptsTxtPhoneNbrTemp.replace(/[^\d]/g, "")
          : this.state.ptsTxtPhoneNbr.replace(/[^\d]/g, ""),
      ptsExpectedDelvInd:
        name === "editShipmentNotifications" &&
        this.state.ptsExpectedDelvIndTemp !== undefined
          ? this.state.ptsExpectedDelvIndTemp
          : this.state.ptsExpectedDelvInd,
      ptsDayOfDelvInd:
        name === "editShipmentNotifications" &&
        this.state.ptsDayOfDelvIndTemp !== undefined
          ? this.state.ptsDayOfDelvIndTemp
          : this.state.ptsDayOfDelvInd,
      ptsDeliveredToAddrInd:
        name === "editShipmentNotifications" &&
        this.state.ptsDeliveredToAddrIndTemp !== undefined
          ? this.state.ptsDeliveredToAddrIndTemp
          : this.state.ptsDeliveredToAddrInd,
      ptsPickupAvailableInd:
        name === "editShipmentNotifications" &&
        this.state.ptsPickupAvailableIndTemp !== undefined
          ? this.state.ptsPickupAvailableIndTemp
          : this.state.ptsPickupAvailableInd,
      ptsAlertsOtherInd:
        name === "editShipmentNotifications" &&
        this.state.ptsAlertsOtherIndTemp !== undefined
          ? this.state.ptsAlertsOtherIndTemp
          : this.state.ptsAlertsOtherInd,
      ptsPkgInTransitInd:
        name === "editShipmentNotifications" &&
        this.state.ptsPkgInTransitIndTemp !== undefined
          ? this.state.ptsPkgInTransitIndTemp
          : this.state.ptsPkgInTransitInd,
      // Notify Recipient
      shipNotifyInd:
        name === "editShippingMessage" &&
        this.state.shipNotifyIndTemp !== undefined
          ? this.state.shipNotifyIndTemp
          : this.state.shipNotifyInd,
      toNote:
        name === "editShippingMessage" && this.state.toNoteTemp !== undefined
          ? this.state.toNoteTemp.trim()
          : this.state.toNote,
      // Print Settings
      printOptions:
        name === "editPrintSettings" &&
        this.state.printOptionsTemp !== undefined
          ? this.state.printOptionsTemp || undefined
          : this.state.printOptions || undefined,
      // Reference Number
      refNbr:
        name === "editReferenceNumber" && this.state.refNbrTemp !== undefined
          ? this.state.refNbrTemp
          : this.state.refNbr,
      // Shipping ZIP code
      shipFromPostalCode:
        name === "editZipCode" &&
        this.state.shipFromPostalCodeTemp !== undefined
          ? this.state.shipFromPostalCodeTemp
          : this.state.shipFromPostalCode,
      //New Connect Local Radius
      connectLocalRadius:
        name === "editUSPSConnect" &&
        this.state.connectLocalRadiusTemp !== undefined
          ? this.state.connectLocalRadiusTemp
          : this.state.connectLocalRadius,

      ptsUpdateType: this.state.ptsUpdateType
    };
    userPref["weightOzQty"] =
      Number(userPref["pounds"] || 0) * 16 + Number(userPref["ounces"] || 0);

    if (!this.validateOptions(name, userPref)) {
      let dataToSend = { userPref };
      if (name === "editShipmentNotifications") {
        analytics.preferences_ShipmentEmailNotification(dataToSend);
      } else if (name === "editShippingMessage") {
        analytics.preferences_NotifyRecipientOfShipping(dataToSend);
      } else if (name === "editPrintSettings") {
        analytics.preferences_Print(dataToSend);
      } else if (name === "editReferenceNumber") {
        analytics.preferences_ReferenceNumber(dataToSend);
      } else if (name === "editZipCode") {
        analytics.preferences_ShipFromZipCode(dataToSend);
      }
      axios
        .post("/go/cnsrest/saveUserPreferences", dataToSend, {
          headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
          analytics.preferences_PackageOptions(
            this.state.fetchedPreferences.userPref
          );
          this.cancelEdit(null, name);
          this.refreshData(name);
        })
        .catch((error) => {
          console.log(error);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
          this.props.toggleSpinner(false);
        });
    } else {
      this.props.toggleSpinner(false);
      this.setState({
        focusOnError: true
      });
    }
    // Scroll to the top of the section whether there were errors or not
    if (name === "editPackageOptions") {
      document.getElementById("package-options").scrollIntoView();
    } else if (name === "hidePostagePrice") {
      document.getElementById("hide-postage-price").scrollIntoView();
    } else if (name === "editShipmentNotifications") {
      document.getElementById("shipment-notifications").scrollIntoView();
    } else if (name === "editShippingMessage") {
      document.getElementById("notify-recipient").scrollIntoView();
    } else if (name === "editPrintSettings") {
      document.getElementById("print-settings").scrollIntoView();
    } else if (name === "editReferenceNumber") {
      document.getElementById("reference-number").scrollIntoView();
    } else if (name === "editZipCode") {
      document.getElementById("shipping-zip-code").scrollIntoView();
    } else if (name === "editUSPSConnect") {
      document.getElementById("connect-local-id").scrollIntoView();
    }
  };

  saveConnectLocal = (e, name) => {
    e.preventDefault();
    // this.props.toggleSpinner(true);
    console.log(name);
    this.setState({
      editUSPSConnect: false
    });
  };
  setRadius = (value) => {
    this.setState({
      connectLocalRadiusTemp: value
    });
  };

  renderServiceTypes = (e) => {
    let serviceTypeAbbrev = Object.keys(
      this.state.fetchedPreferences.availableFavoriteProducts
    );
    let serviceTypeDesc = [];
    for (let i in serviceTypeAbbrev) {
      if (serviceTypeAbbrev[i] !== "FC") {
        serviceTypeDesc.push(
          this.state.fetchedPreferences.availableFavoriteProducts[
            serviceTypeAbbrev[i]
          ][0].productClassDesc
        );
      } else if (
        serviceTypeAbbrev[i] === "FC" &&
        this.props.userData.accountType === "Business"
      ) {
        serviceTypeDesc.push(
          this.state.fetchedPreferences.availableFavoriteProducts[
            serviceTypeAbbrev[i]
          ][0].productClassDesc
        );
      }
    }

    let serviceTypeFormatted = serviceTypeDesc.map((item, index) => (
      <li key={item}>
        <button
          type="button"
          className={
            "tab-link button-link" +
            (this.state.selectedServiceTemp === serviceTypeAbbrev[index] ||
            (this.state.selectedServiceTemp === undefined &&
              this.state.selectedService === serviceTypeAbbrev[index])
              ? " active"
              : "")
          }
          data-value="priority"
          onClick={(e) => this.selectedService(e, item)}
        >
          {this.state.selectedServiceTemp === serviceTypeAbbrev[index] ||
          (this.state.selectedServiceTemp === undefined &&
            this.state.selectedService === serviceTypeAbbrev[index]) ? (
            <span className="visuallyhidden">Currently selected service</span>
          ) : null}
          {item && item.replace("&reg;", "®").replace("&trade;", "™")}
        </button>
      </li>
    ));

    return serviceTypeFormatted;
  };

  mobileRenderServiceTypes = (e) => {
    let serviceTypeAbbrev = Object.keys(
      this.state.fetchedPreferences.availableFavoriteProducts
    );
    let serviceTypeDesc = [];
    for (let i in serviceTypeAbbrev) {
      if (serviceTypeAbbrev[i] !== "FC") {
        serviceTypeDesc.push(
          this.state.fetchedPreferences.availableFavoriteProducts[
            serviceTypeAbbrev[i]
          ][0].productClassDesc
        );
      } else if (
        serviceTypeAbbrev[i] === "FC" &&
        this.props.userData.accountType === "Business"
      ) {
        serviceTypeDesc.push(
          this.state.fetchedPreferences.availableFavoriteProducts[
            serviceTypeAbbrev[i]
          ][0].productClassDesc
        );
      }
    }

    let serviceTypeFormatted = serviceTypeDesc.map((item) => (
      <option
        key={item}
        className=""
        data-value="priority"
        dangerouslySetInnerHTML={{ __html: item }}
        value={item}
      />
    ));

    return serviceTypeFormatted;
  };

  selectedService = (e, item) => {
    e.preventDefault();

    let serviceTypeAbbrev = Object.keys(
      this.state.fetchedPreferences.availableFavoriteProducts
    );
    for (let i in serviceTypeAbbrev) {
      if (
        this.state.fetchedPreferences.availableFavoriteProducts[
          serviceTypeAbbrev[i]
        ][0].productClassDesc
          .replace("&reg;", "")
          .replace("®", "")
          .replace("&trade;", "")
          .replace("™", "") ===
        item
          .replace("&reg;", "")
          .replace("®", "")
          .replace("&trade;", "")
          .replace("™", "")
      ) {
        this.setState((prevState) => {
          return {
            selectedServiceTemp: serviceTypeAbbrev[i],
            selectedServiceMobileTemp: item,
            sortPackageTypeAsc: undefined,
            fetchedPreferencesTemp: undefined,
            domesticProductIdTemp: {
              ...prevState.domesticProductIdTemp,
              productId: ""
            },
            serviceInfoIconId: undefined
          };
        });
        break;
      }
    }
  };

  selectPackageType = (e, prodId) => {
    this.setState((prevState) => {
      return {
        domesticProductIdTemp: {
          ...prevState.domesticProductIdTemp,
          productId: prodId
        }
      };
    });
  };

  getPackageInfo = () => {
    let availableProducts = Object.keys(
      this.state.fetchedPreferences.availableFavoriteProducts
    );
    let hold;
    for (let i in availableProducts) {
      let services = this.state.fetchedPreferences.availableFavoriteProducts[
        availableProducts[i]
      ];
      for (let products in services) {
        if (
          (this.state.domesticProductIdTemp !== undefined &&
            services[products].productId ===
              this.state.domesticProductIdTemp.productId) ||
          (this.state.domesticProductIdTemp === undefined &&
            services[products].productId ===
              this.state.domesticProductId.productId)
        ) {
          hold = (
            <div className="form-group list-items">
              <p
                dangerouslySetInnerHTML={{
                  __html: services[products].productClassDesc
                }}
              />
              {services[products].productId !== 999 ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: services[products].productDescription
                  }}
                />
              ) : null}

              <p>
                {this.state.pounds ? this.state.pounds + "lbs" : ""}{" "}
                {this.state.ounces ? this.state.ounces + "oz" : ""}
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: services[products].productDimensions
                }}
              />
            </div>
          );
          break;
        }
      }
    }
    if (!hold) {
      hold = (
        <div className="form-group list-items">
          <p>No service preferences have been set.</p>
        </div>
      );
    }
    return hold;
  };

  cancelEdit = (e, name) => {
    if (e) {
      e.preventDefault();
    }
    if (name === "editPackageOptions") {
      this.setState({
        editPackageOptions: false,
        flatRateIndTemp: undefined,
        poundsTemp: undefined,
        ouncesTemp: undefined,
        largePackageIndTemp: undefined,
        pkgLengthQtyTemp: undefined,
        pkgWidthQtyTemp: undefined,
        pkgHeightQtyTemp: undefined,
        pkgShapeTemp: undefined,
        pkgGirthQtyTemp: undefined,
        selectedServiceTemp: undefined,
        selectedServiceMobileTemp: undefined,
        showGrid: false,
        sortPackageTypeAsc: undefined,
        fetchedPreferencesTemp: undefined,
        domesticProductIdTemp: undefined
      });
    } else if (name === "editHidePostagePrice") {
      this.setState((prevState) => {
        return {
          editHidePostagePrice: false,
          stealthPostagePMIndTemp: undefined,
          stealthPostagePMEIndTemp: undefined,
          stealthPostageRGIndTemp: undefined,
          stealthPostageFCPSIndTemp: undefined,
          stealthPostagePSGIndTemp: undefined,
          stealthPostageCLIndTemp: undefined,
          stealthPostageCLMIndTemp: undefined,
          // Reset the contact info to what was last retrieved from GAB
          hidePostageAddressesTemp: {
            ...prevState.hidePostageAddresses
          }
        };
      });
    } else if (name === "editShipmentNotifications") {
      this.setState({
        editShipmentNotifications: false,
        ptsNotifyIndTemp: undefined,
        ptsTrackingEmailTemp: undefined,
        ptsTxtPhoneNbrTemp: undefined,
        allOptionsTemp: undefined,
        ptsExpectedDelvIndTemp: undefined,
        ptsDayOfDelvIndTemp: undefined,
        ptsDeliveredToAddrIndTemp: undefined,
        ptsPickupAvailableIndTemp: undefined,
        ptsAlertsOtherIndTemp: undefined,
        ptsPkgInTransitIndTemp: undefined
      });
    } else if (name === "editShippingMessage") {
      this.setState({
        editShippingMessage: false,
        shipNotifyIndTemp: undefined,
        toNoteTemp: undefined
      });
    } else if (name === "editPrintSettings") {
      this.setState({
        editPrintSettings: false,
        printOptionsTemp: undefined
      });
    } else if (name === "editReferenceNumber") {
      this.setState({
        editReferenceNumber: false,
        refNbrTemp: undefined
      });
    } else if (name === "editReturnAddress") {
      this.setState({
        editReturnAddress: false,
        firstNameTemp: undefined,
        middleInitTemp: undefined,
        lastNameTemp: undefined,
        companyNameTemp: undefined,
        line1AddrTemp: undefined,
        line2AddrTemp: undefined,
        cityNameTemp: undefined,
        stateIdTemp: undefined,
        postalCodeTemp: undefined,
        urbanizationCodeTemp: undefined,
        saveReturnToAddressBook: false
      });
    } else if (name === "editZipCode") {
      this.setState({
        editZipCode: false,
        shipFromPostalCodeTemp: undefined
      });
    } else if (name === "editUSPSConnect") {
      this.setState({
        editUSPSConnect: false,
        connectLocalRadiusTemp: undefined
      });
    }
    // Focus on the top element in the cancelled section
    this.setState({
      [name + "Focus"]: true
    });
  };

  clearPackageOptions = (e) => {
    e.preventDefault();
    this.setState({
      poundsTemp: "",
      ouncesTemp: "",
      largePackageIndTemp: "N",
      pkgLengthQtyTemp: "",
      pkgWidthQtyTemp: "",
      pkgHeightQtyTemp: "",
      pkgShapeTemp: "R",
      pkgGirthQtyTemp: "",
      domesticProductIdTemp: {
        ...this.state.domesticProductIdTemp,
        productId: ""
      }
    });
  };
  ////////////////////////////END Package Options

  /////////////////////Hide Postage Price
  showPostagePriceOptions = (e) => {
    this.setState({
      hidePostageServiceTypeIndTemp: e.currentTarget.checked ? "Y" : "N"
    });
  };

  /////////////////////Shipment Notifications
  showTrackingEmailandNumber = (e) => {
    this.setState({
      ptsNotifyIndTemp: e.currentTarget.checked ? "Y" : "N"
    });
  };

  selectAllOptions = (e) => {
    this.setState({
      allOptionsTemp: true,
      ptsExpectedDelvIndTemp: "Y",
      ptsDayOfDelvIndTemp: "Y",
      ptsDeliveredToAddrIndTemp: "Y",
      ptsPickupAvailableIndTemp: "Y",
      ptsAlertsOtherIndTemp: "Y",
      ptsPkgInTransitIndTemp: "Y"
    });
  };

  selectSomeOptions = (e) => {
    this.setState({
      allOptionsTemp: false
    });
  };

  clearPrintSettings = (e) => {
    e.preventDefault();
    this.setState({
      printOptionsTemp: ""
    });
  };

  validateSaveReturnAddress = (inputs) => {
    let errorsFound = false;
    let errorObject = {};

    if (!inputs.firstName && !inputs.company) {
      errorObject["returnFirstName"] = "Please enter first name.";
      errorsFound = true;
    } else if (
      inputs.firstName &&
      inputs.firstName.length > 0 &&
      !validation.nameAddress.test(inputs.firstName)
    ) {
      errorObject["returnFirstName"] = "Please enter a valid first name.";
      errorsFound = true;
    }

    if (!inputs.lastName && !inputs.company) {
      errorObject["returnLastName"] = "Please enter last name.";
      errorsFound = true;
    } else if (
      inputs.lastName &&
      inputs.lastName.length > 0 &&
      !validation.nameAddress.test(inputs.lastName)
    ) {
      errorObject["returnLastName"] = "Please enter a valid last name.";
      errorsFound = true;
    }

    if (
      inputs.company &&
      inputs.company.length > 0 &&
      !validation.nameAddress.test(inputs.company)
    ) {
      errorObject["returnCompany"] = "Please enter a valid company name.";
      errorsFound = true;
    }

    if (!inputs.line1Addr) {
      errorObject["returnStreetAddr"] = "Please enter a street address.";
      errorsFound = true;
    } else if (
      inputs.line1Addr &&
      inputs.line1Addr.length > 0 &&
      !validation.nameAddress.test(inputs.line1Addr)
    ) {
      errorObject["returnStreetAddr"] = "Please enter a valid street address.";
      errorsFound = true;
    }

    if (
      inputs.line2Addr &&
      inputs.line2Addr.length > 0 &&
      !validation.nameAddress.test(inputs.line2Addr)
    ) {
      errorObject["returnAptSuiteOther"] =
        "Please enter valid address information.";
      errorsFound = true;
    }

    if (!inputs.cityName) {
      errorObject["returnCity"] = "Please enter a city.";
      errorsFound = true;
    }

    if (!inputs.stateId || String(inputs.stateId) === "-1") {
      errorObject["returnState"] = "Please select a state.";
      errorsFound = true;
    }

    if (!inputs.postalCode) {
      errorObject["returnZipCode"] = "Please enter ZIP Code™.";
      errorsFound = true;
    } else if (inputs.postalCode && !validation.zip.test(inputs.postalCode)) {
      errorObject["returnZipCode"] = "Please enter a valid ZIP Code™.";
      errorsFound = true;
    }

    this.errors = errorObject;
    this.setInputErrors("errorObject")(errorObject);

    return errorsFound;
  };

  changeReturnAddressField = (input) => (event) => {
    if (input === "GABReturn") {
      // Clear any existing errors for the fields provided from the address book
      if (event.firstName) {
        this.clearError("returnFirstName");
      }
      if (event.lastName) {
        this.clearError("returnLastName");
      }
      if (event.firstName && event.lastName) {
        this.clearError("returnCompany");
      } else if (event.companyName) {
        this.clearError("returnFirstName");
        this.clearError("returnLastName");
        this.clearError("returnCompany");
      }
      const address = event.address;
      if (address) {
        if (address.addressLine1) {
          this.clearError("returnStreetAddr");
        }
        // Since this field is optional, remove the error
        this.clearError("returnAptSuiteOther");
        if (address.city) {
          this.clearError("returnCity");
        }
        if (address.state && address.state.stateDbId) {
          this.clearError("returnState");
        }
        if (address.zipCode || address.postalCode) {
          this.clearError("returnZipCode");
        }
        // Update the fields provided from the address book
        this.setState({
          firstNameTemp: event.firstName || "",
          middleInitTemp: event.middleName || "",
          lastNameTemp: event.lastName || "",
          companyNameTemp: event.companyName || "",
          line1AddrTemp: address.addressLine1 || "",
          line2AddrTemp: address.addressLine2 || "",
          cityNameTemp: address.city || "",
          stateIdTemp:
            address.state && address.state.stateDbId
              ? String(address.state.stateDbId)
              : "",
          postalCodeTemp: address.zipCode || address.postalCode || "",
          urbanizationCodeTemp: address.urbanizationCode || ""
          // emailAddressTemp: event.emailAddress,
          // phoneNbrTemp: event.phoneNbr,
          // countryIdTemp: event.countryId
        });
      }
    }
  };

  saveReturnAddress = (e) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    let userPref = {
      userReturnAddr: {
        avsCode: "9001",
        countryId: "840",
        firstName:
          this.state.firstNameTemp !== undefined
            ? this.state.firstNameTemp
            : this.state.firstName,
        middleInit:
          this.state.middleInitTemp !== undefined
            ? this.state.middleInitTemp
            : this.state.middleInit,
        lastName:
          this.state.lastNameTemp !== undefined
            ? this.state.lastNameTemp
            : this.state.lastName,
        company:
          this.state.companyNameTemp !== undefined
            ? this.state.companyNameTemp
            : this.state.companyName,
        line1Addr:
          this.state.line1AddrTemp !== undefined
            ? this.state.line1AddrTemp
            : this.state.line1Addr,
        line2Addr:
          this.state.line2AddrTemp !== undefined
            ? this.state.line2AddrTemp
            : this.state.line2Addr,
        cityName:
          this.state.cityNameTemp !== undefined
            ? this.state.cityNameTemp
            : this.state.cityName,
        stateId:
          this.state.stateIdTemp !== undefined
            ? this.state.stateIdTemp
            : this.state.stateId,
        postalCode:
          this.state.postalCodeTemp !== undefined
            ? this.state.postalCodeTemp
            : this.state.postalCode,
        urbanizationCode:
          this.state.urbanizationCodeTemp !== undefined
            ? this.state.urbanizationCodeTemp
            : this.state.urbanizationCode
      },
      saveReturnToAddressBook: this.state.saveReturnToAddressBook,
      // Pre-filled fields from customer registration
      emailAddress: this.props.userData.emailAddress,
      phoneNbr: this.props.userData.phoneNbr
    };
    if (!this.validateSaveReturnAddress(userPref.userReturnAddr)) {
      let dataToSend = {};
      dataToSend.userPref = userPref;
      axios
        .post("/go/cnsrest/saveUserReturnAddr", dataToSend, {
          headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
          this.cancelEdit(null, "editReturnAddress");
          this.refreshData("editReturnAddress");
        })
        .catch((error) => {
          console.log(error);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
          this.props.toggleSpinner(false);
        });
    } else {
      this.props.toggleSpinner(false);
      this.setState({
        focusOnError: true
      });
    }
    // Scroll to the top of the section whether there were errors or not
    document.getElementById("return-address").scrollIntoView();
  };

  clearReturnAddress = (e) => {
    e.preventDefault();
    this.setState({
      firstNameTemp: "",
      middleInitTemp: "",
      lastNameTemp: "",
      companyNameTemp: "",
      line1AddrTemp: "",
      line2AddrTemp: "",
      cityNameTemp: "",
      stateIdTemp: "-1",
      postalCodeTemp: "",
      urbanizationCodeTemp: "",
      saveReturnToAddressBook: false
    });
  };

  removeReturnAddress = (e) => {
    e.preventDefault();
    this.props.toggleSpinner(true);
    axios
      .post("/go/cnsrest/deleteUserReturnAddressPreference ", {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        this.refreshData("editReturnAddress");
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        this.props.toggleSpinner(false);
      });
  };

  // All response fields are stored in read-only (non-"Temp") state
  // variables.  They are updated only when the page loads or after the user
  // saves preferences.
  refreshData = (editingSection) => {
    axios
      .get("/go/cnsrest/fetchPreferences?fetchPrefQueryString=")
      .then((response) => {
        if (
          Object.keys(response.data.fieldErrors).length === 0 &&
          response.data.actionErrors.length === 0
        ) {
          // Find the service type to preselect when fetching preferences
          let product = null;
          let selectedService = null;
          let selectedServiceMobile = null;
          if (response.data.userPref.domesticProductId) {
            const products = response.data.availableFavoriteProducts;
            for (const property in products) {
              if (Array.isArray(products[property])) {
                product = products[property].find(
                  (element) =>
                    element.productId ===
                    response.data.userPref.domesticProductId.productId
                );
                if (product) {
                  selectedService = product.productClass;
                  selectedServiceMobile = product.productClassDesc;
                  break;
                }
              }
            }
          }

          this.setState((prevState) => {
            return {
              isLoaded: true,
              fetchedPreferences: response.data,
              // Package Options
              flatRateInd:
                response.data.userPref.flatRateInd === " " ||
                response.data.userPref.flatRateInd === "Y"
                  ? "Y"
                  : "N",
              pounds:
                response.data.userPref.pounds > 0
                  ? response.data.userPref.pounds
                  : "",
              ounces:
                response.data.userPref.ounces > 0
                  ? response.data.userPref.ounces
                  : "",
              weightOzQty: response.data.userPref.weightOzQty,
              largePackageInd: response.data.userPref.largePackageInd,
              pkgLengthQty: response.data.userPref.pkgLengthQty || "",
              pkgWidthQty: response.data.userPref.pkgWidthQty || "",
              pkgHeightQty: response.data.userPref.pkgHeightQty || "",
              pkgShape: response.data.userPref.pkgShape,
              pkgGirthQty: response.data.userPref.pkgGirthQty || "",
              selectedService: selectedService || "PRI",
              selectedServiceMobile:
                selectedServiceMobile || "Priority Mail&reg;",
              domesticProductId: {
                productId: response.data.userPref.domesticProductId
                  ? response.data.userPref.domesticProductId.productId
                  : ""
              },
              // Hide Postage Price
              stealthPostagePMInd: response.data.userPref.stealthPostagePMInd,
              stealthPostagePMEInd: response.data.userPref.stealthPostagePMEInd,
              stealthPostageRGInd: response.data.userPref.stealthPostageRGInd,
              stealthPostageFCPSInd:
                response.data.userPref.stealthPostageFCPSInd,
              stealthPostagePSGInd: response.data.userPref.stealthPostagePSGInd,
              stealthPostageCLInd: response.data.userPref.stealthPostageCLInd,
              stealthPostageCLMInd: response.data.userPref.stealthPostageCLMInd,
              hidePostageAddresses:
                !editingSection || editingSection === "editHidePostagePrice"
                  ? this.initializeHidePostageAddresses(
                      response.data.hidePostagePriceAddressIds
                    )
                  : prevState.hidePostageAddresses,
              hidePostageAddressIdsToDelete:
                !editingSection || editingSection === "editHidePostagePrice"
                  ? []
                  : prevState.hidePostageAddressIdsToDelete,
              // Shipment Notifications
              ptsNotifyInd: response.data.userPref.ptsNotifyInd,
              emailUpdatesInd: response.data.userPref.emailUpdatesInd,
              ptsTrackingEmail: response.data.userPref.ptsTrackingEmail || "",
              ptsTxtUpdatesInd: response.data.userPref.ptsTxtUpdatesInd,
              ptsTxtPhoneNbr: response.data.userPref.ptsTxtPhoneNbr || "",
              allOptions:
                response.data.userPref.ptsExpectedDelvInd === "Y" &&
                response.data.userPref.ptsDayOfDelvInd === "Y" &&
                response.data.userPref.ptsDeliveredToAddrInd === "Y" &&
                response.data.userPref.ptsPickupAvailableInd === "Y" &&
                response.data.userPref.ptsAlertsOtherInd === "Y" &&
                response.data.userPref.ptsPkgInTransitInd === "Y",
              ptsExpectedDelvInd: response.data.userPref.ptsExpectedDelvInd,
              ptsDayOfDelvInd: response.data.userPref.ptsDayOfDelvInd,
              ptsDeliveredToAddrInd:
                response.data.userPref.ptsDeliveredToAddrInd,
              ptsPickupAvailableInd:
                response.data.userPref.ptsPickupAvailableInd,
              ptsAlertsOtherInd: response.data.userPref.ptsAlertsOtherInd,
              ptsPkgInTransitInd: response.data.userPref.ptsPkgInTransitInd,
              // Notify Recipient
              shipNotifyInd: response.data.userPref.shipNotifyInd,
              toNote: response.data.userPref.toNote || "",
              // Print Settings
              printOptions: response.data.userPref.printOptions || "",
              // Reference Number
              refNbr: response.data.userPref.refNbr || "",
              // Return Address
              firstName: response.data.userPref.userReturnAddr.firstName || "",
              middleInit:
                response.data.userPref.userReturnAddr.middleInit || "",
              lastName: response.data.userPref.userReturnAddr.lastName || "",
              companyName:
                response.data.userPref.userReturnAddr.companyName || "",
              line1Addr: response.data.userPref.userReturnAddr.line1Addr || "",
              line2Addr: response.data.userPref.userReturnAddr.line2Addr || "",
              cityName: response.data.userPref.userReturnAddr.cityName || "",
              stateId: response.data.userPref.userReturnAddr.stateId,
              stateCode: response.data.userPref.userReturnAddr.stateCode,
              postalCode:
                response.data.userPref.userReturnAddr.postalCode || "",
              urbanizationCode:
                response.data.userPref.userReturnAddr.urbanizationCode || "",
              // Shipping ZIP code
              shipFromPostalCode: response.data.userPref.shipFromPostalCode,

              ptsUpdateType: response.data.userPref.ptsUpdateType,
              avsCode: "9001",
              countryId: "840",
              connectLocalRadius: response.data.userPref.uspsConnectRadius
                ? String(response.data.userPref.uspsConnectRadius)
                : ""
            };
          });
        } else {
          window.scrollTo(0, 0);
          this.setGlobalError(
            response.data.fieldErrors,
            response.data.actionErrors
          );
        }
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        // If a section was being edited (or if the return address was
        // removed), set the editing flag to false now
        // and focus on the top element in the respective section
        if (editingSection) {
          this.setState({
            [editingSection]: false,
            [editingSection + "Focus"]: true
          });
        }
        // Fetch drop-off locations
        // if this is the initial page load (!editingSection) or
        // if the user was editing the return address
        if (!editingSection || editingSection === "editReturnAddress") {
          this.setFetchDropOffLocations(true);
        }
        // Fetch the latest saved contacts with which to hide the postage price
        // if this is the initial page load or
        // the user is editing the hide postage price section
        if (!editingSection || editingSection === "editHidePostagePrice") {
          this.setState({
            fetchHidePostageAddresses: true
          });
        } else {
          this.props.toggleSpinner(false);
        }
      });
  };

  displayHidePostageAddresses = () => {
    const allContactsToLoad = Object.keys(this.state.hidePostageAddresses);
    // If no preferred contacts are stored in the database,
    // update the (now empty) address list displayed to the user to reflect this
    // and do not make any JSONP calls
    if (allContactsToLoad.length === 0) {
      this.fillSelectedContacts([]);
      this.props.toggleSpinner(false);
      return;
    }

    const jsonp = (url, startingIndex, callback) => {
      // Set up a callback for each JSONP call
      const callbackName = "_jsonpCallbackHidePostage" + startingIndex;
      window.jsonp_abdojoIoScript = {
        ...window.jsonp_abdojoIoScript,
        [callbackName]: function (data) {
          delete window.jsonp_abdojoIoScript[callbackName];
          document.body.removeChild(script);
          callback(data);
        }
      };
      // Make the JSONP call
      let script = document.createElement("script");
      script.id = "gabJsonpHidePostageScript" + startingIndex;
      script.src = url + "&_abCallback=jsonp_abdojoIoScript." + callbackName;
      document.body.appendChild(script);
    };

    // Only fetch up to <jsonpCallSize> contacts per JSONP call
    // (this is to ensure no URL is too large)
    let allContacts = [];
    let loopIteration = 0;
    const jsonpCallSize = 100;
    const totalLoopIterations =
      Math.floor(allContactsToLoad.length / jsonpCallSize) + 1;
    for (let i = 0; i < allContactsToLoad.length; i = i + jsonpCallSize) {
      jsonp(
        ((this.props.lookupCodes &&
          this.props.lookupCodes["cns.addressbook.jsonp"]) ||
          "https://gab.usps.com/addrBook/Secure/ABQuerySMDAction_input") +
          "?contactId=" +
          allContactsToLoad.slice(i, i + jsonpCallSize).join(",") +
          "&jsonInput=%7B%27method%27%3A%27getAddressBookSMDResponse%27%2C%27params%27%3A%5B%5D%2C%27id%27%3A%270%27%7D",
        i,
        (data) => {
          if (data && data.result) {
            // Modify certain fields before storing and displaying the contacts
            let contactsInIteration = data.result.contacts;
            for (let j in contactsInIteration) {
              contactsInIteration[j].address =
                contactsInIteration[j].addresses &&
                contactsInIteration[j].addresses.length > 0
                  ? contactsInIteration[j].addresses[0]
                  : {};
              contactsInIteration[j].contactId =
                contactsInIteration[j].contactIdList &&
                contactsInIteration[j].contactIdList.length > 0
                  ? contactsInIteration[j].contactIdList[0]
                  : "-1";
            }
            allContacts = allContacts.concat(contactsInIteration);
          }
          // Display the contacts only if this is the last iteration
          // through the loop
          loopIteration++;
          if (loopIteration === totalLoopIterations) {
            this.fillSelectedContacts(allContacts);
          }
        }
      );
    }
    this.props.toggleSpinner(false);
  };

  // Initialize an object with the saved hide postage address IDs as keys
  initializeHidePostageAddresses = (hidePostagePriceAddressIds) => {
    let hidePostageAddresses = {};
    for (let i in hidePostagePriceAddressIds) {
      hidePostageAddresses[hidePostagePriceAddressIds[i]] = {};
    }
    return hidePostageAddresses;
  };

  // Add each contact's information obtained from Global Address Book
  // to the list of contacts with which to hide the postage price
  fillSelectedContacts = (contacts) => {
    let preferredContacts = {};
    for (let i in contacts) {
      preferredContacts[contacts[i].contactId] = contacts[i];
    }
    this.setState({
      // hidePostageAddresses stores contacts that are currently saved
      // in the database
      hidePostageAddresses: {
        ...preferredContacts
      },
      // hidePostageAddressesTemp stores contacts that are currently saved
      // in the database plus any that were manually added from the address book
      // (at this point, since we are only retrieving what is already
      // in the database, hidePostageAddresses and hidePostageAddressesTemp
      // hold the same data)
      hidePostageAddressesTemp: {
        ...preferredContacts
      }
    });
  };

  // Update new contacts manually selected from Global Address Book
  // to the list of contacts with which to hide the postage price
  updateSelectedContacts = (contacts) => {
    let preferredContacts = {};
    for (let i in contacts) {
      preferredContacts[contacts[i].contactId] = contacts[i];
    }
    // Update the contacts to display in the list for the user
    this.setState((prevState) => {
      return {
        hidePostageAddressesTemp: {
          ...prevState.hidePostageAddressesTemp,
          ...preferredContacts
        }
      };
    });
    // Update the list of contacts that the user wishes to delete
    // (this is necessary to ensure that the list is correct if the user
    // previously removed a contact and then added it back, for example)
    this.setState((prevState) => {
      let hidePostageAddressIdsToDelete = JSON.parse(
        JSON.stringify(prevState.hidePostageAddressIdsToDelete)
      );
      const hidePostageAddressIdsToRetain = Object.keys(
        JSON.parse(JSON.stringify(prevState.hidePostageAddressesTemp))
      );
      hidePostageAddressIdsToDelete = hidePostageAddressIdsToDelete.filter(
        (contactId) =>
          !hidePostageAddressIdsToRetain.includes(String(contactId))
      );
      return {
        hidePostageAddressIdsToDelete: hidePostageAddressIdsToDelete
      };
    });
  };

  // Remove the selected contact from the list of contacts
  // with which to hide the postage price
  removeSelectedContact = (event, contactId) => {
    event.preventDefault();
    this.setState((prevState) => {
      let hidePostageAddressesTemp = JSON.parse(
        JSON.stringify(prevState.hidePostageAddressesTemp)
      );
      const contactIdToDelete = hidePostageAddressesTemp[contactId].contactId;
      delete hidePostageAddressesTemp[contactId];
      return {
        // Update the list of contacts that the user wishes to delete
        hidePostageAddressIdsToDelete: prevState.hidePostageAddressIdsToDelete.concat(
          [contactIdToDelete]
        ),
        // Update the contacts to display in the list for the user
        hidePostageAddressesTemp: hidePostageAddressesTemp
      };
    });
  };

  // Only pass in addresses to add that are not already present in the database
  // (also have a backend check for this)
  getHidePostageAddressIdsToAdd = () => {
    if (
      this.state.hidePostageAddresses &&
      this.state.hidePostageAddressesTemp
    ) {
      let hidePostageAddressIdsInDb = Object.keys(
        this.state.hidePostageAddresses
      );
      let hidePostageAddressIdsToAdd = Object.keys(
        this.state.hidePostageAddressesTemp
      );
      hidePostageAddressIdsToAdd = hidePostageAddressIdsToAdd.filter(
        (contactId) => !hidePostageAddressIdsInDb.includes(contactId)
      );
      return hidePostageAddressIdsToAdd;
    } else {
      return [];
    }
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            [field]: errorMessage
          }
        };
      });
    }
  };

  clearError = (fieldName) => {
    this.setState((prevState) => {
      return {
        errors: {
          ...prevState.errors,
          [fieldName]: ""
        }
      };
    });
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  labelItemError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("labelItemError")(combinedErrors);
  };

  sortColumns = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState.fetchedPreferences));
      let sortPackageTypeAsc = !prevState.sortPackageTypeAsc;
      // Sort the products with the selected service type by package type
      newState.availableFavoriteProducts[
        prevState.selectedServiceTemp !== undefined
          ? prevState.selectedServiceTemp
          : prevState.selectedService
      ].sort((a, b) => {
        const aProductName =
          (a.productId !== 100 && a.productId !== 200
            ? a.productClassDesc + " "
            : "") + a.productDescription;
        const bProductName =
          (b.productId !== 100 && b.productId !== 200
            ? b.productClassDesc + " "
            : "") + b.productDescription;
        if (sortPackageTypeAsc) {
          // Sort in ascending order
          return aProductName.localeCompare(bProductName);
        } else {
          // Sort in descending order
          return bProductName.localeCompare(aProductName);
        }
      });

      return {
        sortPackageTypeAsc: sortPackageTypeAsc,
        fetchedPreferencesTemp: newState,
        serviceInfoIconId: undefined
      };
    });
  };

  setServiceInfoIconId = (id) => {
    this.setState({
      serviceInfoIconId: id
    });
  };

  renderList = () => {
    const fetchedPreferences =
      this.state.fetchedPreferencesTemp !== undefined
        ? this.state.fetchedPreferencesTemp
        : this.state.fetchedPreferences;
    return fetchedPreferences.availableFavoriteProducts[
      this.state.selectedServiceTemp !== undefined
        ? this.state.selectedServiceTemp
        : this.state.selectedService
    ].map((item, key) => (
      <li
        key={item.productId}
        className={
          "package-row" +
          ((this.state.domesticProductIdTemp !== undefined &&
            this.state.domesticProductIdTemp.productId === item.productId) ||
          (this.state.domesticProductIdTemp === undefined &&
            this.state.domesticProductId.productId === item.productId)
            ? " package-row-selected"
            : "")
        }
        onClick={(e) => this.selectPackageType(e, item.productId)}
      >
        <div className="row package-del-price-content">
          <div className="col package-row-text">
            <div className="row">
              <div className="package-type-container col">
                <input
                  id={"package-type-radio-list" + item.productId}
                  type="radio"
                  className="radio-button package-type-radio"
                  name="radio-package-type"
                  checked={
                    (this.state.domesticProductIdTemp !== undefined &&
                      this.state.domesticProductIdTemp.productId ===
                        item.productId) ||
                    (this.state.domesticProductIdTemp === undefined &&
                      this.state.domesticProductId.productId === item.productId)
                  }
                  onChange={(e) => this.selectPackageType(e, item.productId)}
                />
                <p
                  className="package-type-title"
                  dangerouslySetInnerHTML={{
                    __html:
                      // Prepend productClassDesc if the conditions are met
                      (item.productId !== 100 && item.productId !== 200
                        ? item.productClassDesc + " "
                        : "") +
                      // Include productDescription if not FCPSR
                      (item.productId !== 999 ? item.productDescription : "")
                  }}
                />
                <p
                  className="package-type-desc-txt"
                  dangerouslySetInnerHTML={{ __html: item.productDimensions }}
                />
              </div>
            </div>
          </div>
        </div>
      </li>
    ));
  };

  renderGrid = () => {
    const fetchedPreferences =
      this.state.fetchedPreferencesTemp !== undefined
        ? this.state.fetchedPreferencesTemp
        : this.state.fetchedPreferences;
    return fetchedPreferences.availableFavoriteProducts[
      this.state.selectedServiceTemp !== undefined
        ? this.state.selectedServiceTemp
        : this.state.selectedService
    ].map((item, key) => (
      <li
        key={item.productId}
        className={
          "package-row" +
          ((this.state.domesticProductIdTemp !== undefined &&
            this.state.domesticProductIdTemp.productId === item.productId) ||
          (this.state.domesticProductIdTemp === undefined &&
            this.state.domesticProductId.productId === item.productId)
            ? " package-row-selected"
            : "")
        }
        onClick={(e) => this.selectPackageType(e, item.productId)}
      >
        <div className="row package-del-price-content">
          <div className="col package-row-text">
            <div className="row">
              <div className="package-type-container col">
                <input
                  id={"package-type-radio-grid" + item.productId}
                  type="radio"
                  className="radio-button package-type-radio visuallyhidden"
                  name="radio-package-type"
                  onChange={(e) => this.selectPackageType(e, item.productId)}
                  checked={
                    (this.state.domesticProductIdTemp !== undefined &&
                      this.state.domesticProductIdTemp.productId ===
                        item.productId) ||
                    (this.state.domesticProductIdTemp === undefined &&
                      this.state.domesticProductId.productId === item.productId)
                  }
                />
                <p
                  className="package-type-title"
                  dangerouslySetInnerHTML={{
                    __html:
                      // Prepend productClassDesc if the conditions are met
                      (item.productId !== 100 && item.productId !== 200
                        ? item.productClassDesc + " "
                        : "") +
                      // Include productDescription if not FCPSR
                      (item.productId !== 999 ? item.productDescription : "")
                  }}
                />
                <button
                  type="button"
                  className="info-icon domestic-shipping-option-popover-link button-link"
                  data-trigger="focus"
                  data-toggle="popover"
                  data-target="#domestic-shipping-option-modal"
                  data-backdrop="static"
                  tabIndex="0"
                  data-original-title=""
                  title=""
                  id={"serviceInfoIcon" + item.productId}
                  onClick={(e) => this.setServiceInfoIconId(e.currentTarget.id)}
                />
                <p
                  className="package-type-desc-txt"
                  dangerouslySetInnerHTML={{ __html: item.productDimensions }}
                />
              </div>
            </div>
          </div>
          <div className="col package-row-img">
            <img
              src={process.env.PUBLIC_URL + item.serviceProductImage}
              alt={item.serviceProductAlt}
            />
          </div>
        </div>
      </li>
    ));
  };

  renderPackageOptions = () => {
    return (
      <div className="Package_Options_Container" id="package-options">
        <div className="container-fluid">
          <h2 className="normal">Package Options</h2>
          <div className="row">
            <div className="col-12 col-md-11 col-lg-10 package-options-step domestic-package-options">
              <p>
                Set the domestic package option you use the most to be selected
                when you create your label.
              </p>
            </div>
          </div>
          <div id="package-options-top" className="row" tabIndex="-1">
            <div className="col-12 col-md-11 col-lg-10 package-options-step service-preferences-wrapper">
              <p>
                <strong>Service Preferences</strong>
              </p>
              {!this.state.editPackageOptions ? (
                <React.Fragment>
                  {this.getPackageInfo()}
                  <div className="edit-link">
                    <button
                      type="button"
                      className="inline-link-edit service-preferences-edit button-link"
                      onClick={(e) => this.clickEditPackageOptions(e)}
                      aria-label="Edit package options"
                    >
                      <strong>Edit</strong>
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
            </div>
            {this.state.editPackageOptions ? (
              <div className="col-12 col-md-11 col-lg-11 ser-perf-edit-appent-wraper">
                <div className="radio-wrap">
                  <div className="radio-container">
                    <input
                      id="flat-rate-ser-perf-radio"
                      type="radio"
                      className="radio-button"
                      name="flat-rate-package-weight-radio-btn"
                      tabIndex="0"
                      value="flat-rate"
                      checked={
                        this.state.flatRateIndTemp !== undefined
                          ? this.state.flatRateIndTemp === "Y"
                          : this.state.flatRateInd === "Y"
                      }
                      onChange={(e) => this.changeFlatRateInd("flat")}
                    />
                    <label htmlFor="flat-rate-ser-perf-radio">
                      <strong>Ship Flat Rate.</strong>
                      <br />
                      Use USPS<sup>®</sup> packaging to ship anywhere in the
                      U.S. up to 70 lbs.
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      id="package-weight-ser-perf-radio"
                      type="radio"
                      className="radio-button"
                      name="flat-rate-package-weight-radio-btn"
                      tabIndex="0"
                      value="package-weight"
                      checked={this.isWeightRequired()}
                      onChange={(e) => this.changeFlatRateInd("largePkg")}
                    />
                    <label htmlFor="package-weight-ser-perf-radio">
                      <strong>Ship by Package Weight.</strong>
                      <br />
                      Enter a value of zero or higher for pounds and ounces.
                    </label>
                  </div>
                </div>
                <div className="ser-perf-package-weight">
                  <div className="row required-field">
                    <AssistiveError
                      inputId="pounds-input"
                      errorMessage={this.state.errors.weight}
                    />
                    <div
                      className={
                        "col-6 col-md-3 col-lg-2 required-field" +
                        (this.state.errors.weight !== "" ? " error" : "")
                      }
                    >
                      <label />
                      <div className="input-group mb-3">
                        <input
                          className="form-control append"
                          id="pounds-input"
                          type="text"
                          placeholder="0"
                          aria-label=""
                          aria-describedby=""
                          value={
                            this.isWeightRequired()
                              ? this.state.poundsTemp !== undefined
                                ? this.state.poundsTemp
                                : this.state.pounds
                              : ""
                          }
                          onChange={(e) => {
                            this.changeUserPref("poundsTemp")(e.target.value);
                            this.state.errors.weight !== ""
                              ? this.clearError("weight")
                              : this.clearError("");
                            this.changeFlatRateInd("largePkg");
                          }}
                          maxLength="3"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">lbs</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        "col-6 col-md-3 col-lg-2 required-field" +
                        (this.state.errors.weight !== "" ? " error" : "")
                      }
                    >
                      <label />
                      <div className="input-group mb-3">
                        <input
                          className="form-control append"
                          id="ounces-input"
                          type="text"
                          placeholder="0"
                          aria-label=""
                          aria-describedby=""
                          value={
                            this.isWeightRequired()
                              ? this.state.ouncesTemp !== undefined
                                ? this.state.ouncesTemp
                                : this.state.ounces
                              : ""
                          }
                          onChange={(e) => {
                            this.changeUserPref("ouncesTemp")(e.target.value);
                            this.state.errors.weight !== ""
                              ? this.clearError("weight")
                              : this.clearError("");
                            this.changeFlatRateInd("largePkg");
                          }}
                          maxLength="4"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">oz</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="error-message">
                    {this.state.errors.weight}
                  </span>
                  <div className="row">
                    <p className="col-12 ser-perf-info">
                      What if I don't know my package weight?{" "}
                      <PackageWeightPopover targetName="preferencesWeightIcon" />
                    </p>
                  </div>
                </div>
                {this.state.flatRateIndTemp === "N" ||
                (this.state.flatRateIndTemp === undefined &&
                  this.state.flatRateInd === "N") ? (
                  <div className="checkbox-container">
                    <label
                      className="checkbox-component"
                      htmlFor="over-twelve-checkbox"
                    >
                      <input
                        type="checkbox"
                        id="over-twelve-checkbox"
                        name="over-twelve-in-checkbox"
                        checked={
                          this.state.largePackageIndTemp !== undefined
                            ? this.state.largePackageIndTemp === "Y"
                            : this.state.largePackageInd === "Y"
                        }
                        onChange={this.changeIsOver12Checked}
                      />
                      <span className="checkbox" />
                      <strong>
                        This package has a dimension measuring over 12"
                      </strong>
                    </label>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="package-dimension-not-standard">
                  {this.state.largePackageIndTemp === "Y" ||
                  (!this.state.largePackageIndTemp &&
                    this.state.largePackageInd === "Y") ? (
                    <React.Fragment>
                      <div className="row">
                        <div
                          className={
                            "col-12 col-md-3 col-lg-3 form-group required-field" +
                            (this.state.errors.length !== "" ? " error" : "")
                          }
                        >
                          <AssistiveError
                            inputId="pkgLengthQtyId"
                            errorMessage={this.state.errors.length}
                          />
                          <label htmlFor="pkgLengthQtyId">*Length</label>
                          <div className="input-group">
                            <input
                              tabIndex="0"
                              className="form-control measurement-in append"
                              id="pkgLengthQtyId"
                              type="text"
                              placeholder="0"
                              aria-label=""
                              aria-describedby=""
                              value={
                                this.state.pkgLengthQtyTemp !== undefined
                                  ? this.state.pkgLengthQtyTemp
                                  : this.state.pkgLengthQty
                              }
                              onChange={(e) => {
                                this.changeUserPref("pkgLengthQtyTemp")(
                                  e.target.value
                                );
                                this.state.errors.length !== ""
                                  ? this.clearError("length")
                                  : this.clearError("");
                              }}
                              maxLength="3"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">in</span>
                            </div>
                          </div>
                          <span className="error-message">
                            {this.state.errors.length}
                          </span>
                        </div>
                        <div
                          className={
                            "col-12 col-md-3 col-lg-3 form-group required-field" +
                            (this.state.errors.width !== "" ? " error" : "")
                          }
                        >
                          <AssistiveError
                            inputId="pkgWidthQtyId"
                            errorMessage={this.state.errors.width}
                          />
                          <label htmlFor="pkgWidthQtyId">*Width</label>
                          <div className="input-group">
                            <input
                              tabIndex="0"
                              className="form-control append"
                              id="pkgWidthQtyId"
                              type="text"
                              placeholder="0"
                              aria-label=""
                              aria-describedby=""
                              value={
                                this.state.pkgWidthQtyTemp !== undefined
                                  ? this.state.pkgWidthQtyTemp
                                  : this.state.pkgWidthQty
                              }
                              onChange={(e) => {
                                this.changeUserPref("pkgWidthQtyTemp")(
                                  e.target.value
                                );
                                this.state.errors.width !== ""
                                  ? this.clearError("width")
                                  : this.clearError("");
                              }}
                              maxLength="3"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">in</span>
                            </div>
                          </div>
                          <span className="error-message">
                            {this.state.errors.width}
                          </span>
                        </div>
                        <div
                          className={
                            "col-12 col-md-3 col-lg-3 form-group required-field" +
                            (this.state.errors.height !== "" ? " error" : "")
                          }
                        >
                          <AssistiveError
                            inputId="pkgHeightQtyId"
                            errorMessage={this.state.errors.height}
                          />
                          <label htmlFor="pkgHeightQtyId">*Height</label>
                          <div className="input-group">
                            <input
                              tabIndex="0"
                              className="form-control append"
                              id="pkgHeightQtyId"
                              type="text"
                              placeholder="0"
                              aria-label=""
                              aria-describedby=""
                              value={
                                this.state.pkgHeightQtyTemp !== undefined
                                  ? this.state.pkgHeightQtyTemp
                                  : this.state.pkgHeightQty
                              }
                              onChange={(e) => {
                                this.changeUserPref("pkgHeightQtyTemp")(
                                  e.target.value
                                );
                                this.state.errors.height !== ""
                                  ? this.clearError("height")
                                  : this.clearError("");
                              }}
                              maxLength="3"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">in</span>
                            </div>
                          </div>
                          <span className="error-message">
                            {this.state.errors.height}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 checkbox-container">
                          <label
                            className="checkbox-component"
                            htmlFor="rectangular-box-checkbox"
                          >
                            <input
                              type="checkbox"
                              id="rectangular-box-checkbox"
                              name="rectangular-box-checkbox"
                              checked={
                                this.state.pkgShapeTemp !== undefined
                                  ? this.state.pkgShapeTemp === "NR"
                                  : this.state.pkgShape === "NR"
                              }
                              onChange={this.changeGirth}
                            />
                            <span className="checkbox" />
                            This package isn't a standard, rectangular box
                          </label>
                        </div>
                      </div>
                      {this.state.pkgShapeTemp === "NR" ||
                      (!this.state.pkgShapeTemp &&
                        this.state.pkgShape === "NR") ? (
                        <div className="row">
                          <div className="col-12 col-md-4 rectangular-box-girth">
                            <div
                              className={
                                "form-group required-field" +
                                (this.state.errors.girth !== "" ? " error" : "")
                              }
                            >
                              <AssistiveError
                                inputId="pkgGirthQtyId"
                                errorMessage={this.state.errors.girth}
                              />
                              <label htmlFor="pkgGirthQtyId">*Girth</label>{" "}
                              <GirthModal targetName="preferencesGirthIcon" />
                              <div className="input-group">
                                <input
                                  tabIndex="0"
                                  className="form-control append"
                                  id="pkgGirthQtyId"
                                  type="text"
                                  placeholder="0"
                                  aria-label=""
                                  aria-describedby=""
                                  value={
                                    this.state.pkgGirthQtyTemp !== undefined
                                      ? this.state.pkgGirthQtyTemp
                                      : this.state.pkgGirthQty
                                  }
                                  onChange={(e) => {
                                    this.changeUserPref("pkgGirthQtyTemp")(
                                      e.target.value
                                    );
                                    this.state.errors.girth !== ""
                                      ? this.clearError("girth")
                                      : this.clearError("");
                                  }}
                                  maxLength="2"
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">in</span>
                                </div>
                              </div>
                              <span className="error-message">
                                {this.state.errors.girth}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment />
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
                <div className="row">
                  <div className="col-12 d-md-none form-group">
                    <label>*Service</label>
                    <div className="dropdown-selection">
                      <select
                        id="preference-service-type-dropdown"
                        type="button"
                        className="btn btn-default dropdown-toggle dropdown dropdown-items-wrapper"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onChange={(e) =>
                          this.selectedService(e, e.target.value)
                        }
                        value={
                          this.state.selectedServiceMobileTemp
                            ? this.state.selectedServiceMobileTemp
                            : this.state.selectedServiceMobile
                        }
                      >
                        {this.mobileRenderServiceTypes()}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="d-none d-md-block col-md-12 col-lg-12 domestic-select-service-container">
                    <div className="select-service-wrapper">
                      <strong className="select-service-text">
                        Select a Service:{" "}
                      </strong>
                      <ul className="select-service-tabs">
                        {/* {Object.keys(this.state.fetchedPreferences.availableFavoriteProducts).map((item, key) => ( */}
                        {this.renderServiceTypes()}
                        {/* ))} */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-10">
                    <div className="view-as">
                      <strong>View as:</strong>
                      <strong
                        className={
                          "view-as-list" +
                          (!this.state.showGrid ? " active" : "")
                        }
                      >
                        <button
                          type="button"
                          className="button-link"
                          onClick={() => this.changeView("List")}
                        >
                          {!this.state.showGrid ? (
                            <span className="visuallyhidden">
                              Currently selected
                            </span>
                          ) : null}
                          List
                        </button>
                      </strong>
                      <strong
                        className={
                          "view-as-grid" +
                          (this.state.showGrid ? " active" : "")
                        }
                      >
                        <button
                          type="button"
                          className="button-link"
                          onClick={() => this.changeView("Grid")}
                        >
                          {this.state.showGrid ? (
                            <span className="visuallyhidden">
                              Currently selected
                            </span>
                          ) : null}
                          Grid
                        </button>
                      </strong>
                    </div>
                  </div>
                </div>
                {this.state.showGrid ? (
                  <div className="row">
                    <div className="col-12">
                      <li className="package-content-label">
                        <div className="row destination-address-content">
                          <div className="package-type-service-container col-12 col-md-8">
                            <p className="package-type-service-label">
                              <button
                                type="button"
                                className="button-link"
                                onClick={this.sortColumns}
                              >
                                <strong>Package Type </strong>
                                <img src={sortIcon} alt="Sort icon" />
                                {this.state.sortPackageTypeAsc !== undefined ? (
                                  <span className="visuallyhidden">
                                    Currently sorted in
                                    {this.state.sortPackageTypeAsc === false
                                      ? " reverse "
                                      : ""}
                                    alphabetical order
                                  </span>
                                ) : null}
                              </button>
                            </p>
                          </div>
                        </div>
                      </li>
                      <ul className="priority package-schedule-price-wrapper select-service-grid">
                        {this.renderGrid()}
                        {this.state.serviceInfoIconId !== undefined ? (
                          <PriorityMailPopover
                            targetName={this.state.serviceInfoIconId}
                            setServiceInfoIconId={this.setServiceInfoIconId}
                          />
                        ) : null}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12 col-lg-10">
                      <li className="package-content-label">
                        <div className="row destination-address-content">
                          <div className="package-type-service-container col-12 col-md-8">
                            <p className="package-type-service-label">
                              <strong>
                                <button
                                  type="button"
                                  className="button-link"
                                  onClick={this.sortColumns}
                                >
                                  <strong>Package Type </strong>
                                  <img src={sortIcon} alt="Sort icon" />
                                  {this.state.sortPackageTypeAsc !==
                                  undefined ? (
                                    <span className="visuallyhidden">
                                      Currently sorted in
                                      {this.state.sortPackageTypeAsc === false
                                        ? " reverse "
                                        : ""}
                                      alphabetical order
                                    </span>
                                  ) : null}
                                </button>
                              </strong>
                            </p>
                          </div>
                        </div>
                      </li>
                      <ul className="priority package-schedule-price-wrapper">
                        {this.renderList()}
                      </ul>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12 button-wrapper">
                    <div className="button-container">
                      <button
                        type="button"
                        className="btn-primary ser-perf-save-btn"
                        tabIndex="0"
                        name="editPackageOptions"
                        onClick={(e) => this.saveOptions(e, e.target.name)}
                        aria-label="Save package options"
                      >
                        Save
                      </button>
                    </div>
                    <div className="button-container">
                      <button
                        type="button"
                        className="btn-primary button--white ser-perf-cancel-btn"
                        tabIndex="0"
                        onClick={(e) =>
                          this.cancelEdit(e, "editPackageOptions")
                        }
                        aria-label="Cancel package options"
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="button-container">
                      <button
                        type="button"
                        className="btn-primary button--white ser-perf-clear-btn"
                        tabIndex="0"
                        onClick={(e) => this.clearPackageOptions(e)}
                        aria-label="Clear package options"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
          <div className="row">
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderHidePostagePrice = () => {
    return (
      <div className="Hide_Postage_Price_Container" id="hide-postage-price">
        <div className="container-fluid">
          <h2 className="normal">Hide Postage Price</h2>
          <div className="row">
            <div className="col-12 col-md-11 col-lg-10 notifications-options-step">
              <p>
                Choose to keep the postage price off your printed domestic
                labels.
              </p>
            </div>
          </div>
          {!this.state.editHidePostagePrice ? (
            <div
              id="hide-postage-price-top-id"
              className="row hide-postage-price-edit-section"
              tabIndex="-1"
            >
              <div className="col-12 notifications-options-step notifications-options-line">
                {this.state.stealthPostagePMInd !== "Y" &&
                this.state.stealthPostagePMEInd !== "Y" &&
                this.state.stealthPostageFCPSInd !== "Y" &&
                this.state.stealthPostageCLInd !== "Y" &&
                this.state.stealthPostageCLMInd !== "Y" &&
                this.state.stealthPostagePSGInd !== "Y" &&
                this.state.stealthPostageRGInd !== "Y" &&
                Object.keys(this.state.hidePostageAddresses).length === 0 ? (
                  <div className="ship-notifications-static-section">
                    <p>
                      <strong>Suppress Postage from Label Preference</strong>
                    </p>
                    <div class="form-group">
                      <p>No service preferences have been set.</p>
                    </div>
                  </div>
                ) : (
                  <div id="hide-postage-price-display-wrapper" className="row">
                    {(this.state.stealthPostagePMInd === "Y" ||
                      this.state.stealthPostagePMEInd === "Y" ||
                      this.state.stealthPostageFCPSInd === "Y" ||
                      this.state.stealthPostageCLInd === "Y" ||
                      this.state.stealthPostageCLMInd === "Y" ||
                      this.state.stealthPostagePSGInd === "Y" ||
                      this.state.stealthPostageRGInd === "Y") && (
                      <div className="col-6 col-md-4 col-lg-3">
                        <span>
                          <p>
                            <strong>Mail Class Preferences</strong>
                          </p>
                        </span>
                        <p>
                          {hidePostagePriceUtils.isServiceTypeEligible(
                            this.props.userData,
                            "PRI",
                            this.props.lookupCodes
                          ) && this.state.stealthPostagePMInd === "Y"
                            ? "Priority Mail"
                            : ""}
                        </p>
                        <p>
                          {hidePostagePriceUtils.isServiceTypeEligible(
                            this.props.userData,
                            "EXM",
                            this.props.lookupCodes
                          ) && this.state.stealthPostagePMEInd === "Y"
                            ? "Priority Mail Express"
                            : ""}
                        </p>
                        <p>
                          {hidePostagePriceUtils.isServiceTypeEligible(
                            this.props.userData,
                            "FC",
                            this.props.lookupCodes
                          ) && this.state.stealthPostageFCPSInd === "Y"
                            ? "First-Class Package Service"
                            : ""}
                        </p>
                        <p>
                          {hidePostagePriceUtils.isServiceTypeEligible(
                            this.props.userData,
                            "CL",
                            this.props.lookupCodes
                          ) && this.state.stealthPostageCLInd === "Y"
                            ? "USPS Connect Local"
                            : ""}
                        </p>
                        <p>
                          {hidePostagePriceUtils.isServiceTypeEligible(
                            this.props.userData,
                            "CLM",
                            this.props.lookupCodes
                          ) && this.state.stealthPostageCLMInd === "Y"
                            ? "USPS Connect Local Mail"
                            : ""}
                        </p>
                        <p>
                          {hidePostagePriceUtils.isServiceTypeEligible(
                            this.props.userData,
                            "PSG",
                            this.props.lookupCodes
                          ) && this.state.stealthPostagePSGInd === "Y"
                            ? "Parcel Select Ground"
                            : ""}
                        </p>
                        <p>
                          {hidePostagePriceUtils.isServiceTypeEligible(
                            this.props.userData,
                            "RG",
                            this.props.lookupCodes
                          ) && this.state.stealthPostageRGInd === "Y"
                            ? "Retail Ground"
                            : ""}
                        </p>
                      </div>
                    )}

                    {Object.keys(this.state.hidePostageAddresses).length >
                      0 && (
                      <div className="col-6 col-md-8 col-lg-9">
                        <span>
                          <p>
                            <strong>Address Preferences</strong>
                          </p>
                        </span>

                        {Object.keys(this.state.hidePostageAddresses).length <=
                        10 ? (
                          this.renderSavedHidePostageAddresses()
                        ) : (
                          <div id="hide-postage-price-address-saved">
                            {this.renderSavedHidePostageAddresses()}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div className="edit-link">
                  <button
                    type="button"
                    className="inline-link-edit hide-postage-price-edit button-link"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        editHidePostagePrice: true,
                        editHidePostagePriceFocus: true
                      });
                    }}
                    aria-label="Edit hide postage price"
                  >
                    <strong>Edit</strong>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div id="hide-postage-price-top-id-edit" tabIndex="-1">
              <div className="row">
                <div className="col-6">
                  <div className="row checkbox-wrap">
                    <div className="col-12 checkbox-container">
                      {" "}
                      <p>
                        <strong>
                          Suppress Postage from Label by Mail Class Preference
                        </strong>
                      </p>
                    </div>
                    <span className="error-message" />
                    <div className="row">
                      <div className="col-12 radio-buttons all-updates-select-updates">
                        <div className="row">
                          <div
                            id="hide-postage-price-mail-class-inputs-wrapper"
                            className="col-12 checkbox-wrap"
                          >
                            <p>
                              Choose the mail classes you wish to hide postage
                              displayed on your labels for:
                            </p>
                            {hidePostagePriceUtils.isServiceTypeEligible(
                              this.props.userData,
                              "PRI",
                              this.props.lookupCodes
                            ) && (
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="PM-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="PM-checkbox"
                                    checked={
                                      this.state.stealthPostagePMIndTemp !==
                                      undefined
                                        ? this.state.stealthPostagePMIndTemp ===
                                          "Y"
                                        : this.state.stealthPostagePMInd === "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        stealthPostagePMIndTemp: e.currentTarget
                                          .checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  Priority Mail
                                </label>
                              </div>
                            )}
                            {hidePostagePriceUtils.isServiceTypeEligible(
                              this.props.userData,
                              "EXM",
                              this.props.lookupCodes
                            ) && (
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="PME-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="PME-checkbox"
                                    checked={
                                      this.state.stealthPostagePMEIndTemp !==
                                      undefined
                                        ? this.state
                                            .stealthPostagePMEIndTemp === "Y"
                                        : this.state.stealthPostagePMEInd ===
                                          "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        stealthPostagePMEIndTemp: e
                                          .currentTarget.checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  Priority Mail Express
                                </label>
                              </div>
                            )}
                            {hidePostagePriceUtils.isServiceTypeEligible(
                              this.props.userData,
                              "FC",
                              this.props.lookupCodes
                            ) && (
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="FCPS-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="FCPS-checkbox"
                                    checked={
                                      this.state.stealthPostageFCPSIndTemp !==
                                      undefined
                                        ? this.state
                                            .stealthPostageFCPSIndTemp === "Y"
                                        : this.state.stealthPostageFCPSInd ===
                                          "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        stealthPostageFCPSIndTemp: e
                                          .currentTarget.checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  First-Class Package Service
                                </label>
                              </div>
                            )}
                            {hidePostagePriceUtils.isServiceTypeEligible(
                              this.props.userData,
                              "CL",
                              this.props.lookupCodes
                            ) && (
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="CL-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="CL-checkbox"
                                    checked={
                                      this.state.stealthPostageCLIndTemp !==
                                      undefined
                                        ? this.state.stealthPostageCLIndTemp ===
                                          "Y"
                                        : this.state.stealthPostageCLInd === "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        stealthPostageCLIndTemp: e.currentTarget
                                          .checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  USPS Connect Local
                                </label>
                              </div>
                            )}
                            {hidePostagePriceUtils.isServiceTypeEligible(
                              this.props.userData,
                              "CLM",
                              this.props.lookupCodes
                            ) && (
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="CLM-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="CLM-checkbox"
                                    checked={
                                      this.state.stealthPostageCLMIndTemp !==
                                      undefined
                                        ? this.state
                                            .stealthPostageCLMIndTemp === "Y"
                                        : this.state.stealthPostageCLMInd ===
                                          "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        stealthPostageCLMIndTemp: e
                                          .currentTarget.checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  USPS Connect Local Mail
                                </label>
                              </div>
                            )}
                            {hidePostagePriceUtils.isServiceTypeEligible(
                              this.props.userData,
                              "PSG",
                              this.props.lookupCodes
                            ) && (
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="PSG-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="PSG-checkbox"
                                    checked={
                                      this.state.stealthPostagePSGIndTemp !==
                                      undefined
                                        ? this.state
                                            .stealthPostagePSGIndTemp === "Y"
                                        : this.state.stealthPostagePSGInd ===
                                          "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        stealthPostagePSGIndTemp: e
                                          .currentTarget.checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  Parcel Select Ground
                                </label>
                              </div>
                            )}
                            {hidePostagePriceUtils.isServiceTypeEligible(
                              this.props.userData,
                              "RG",
                              this.props.lookupCodes
                            ) && (
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="RG-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="RG-checkbox"
                                    checked={
                                      this.state.stealthPostageRGIndTemp !==
                                      undefined
                                        ? this.state.stealthPostageRGIndTemp ===
                                          "Y"
                                        : this.state.stealthPostageRGInd === "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        stealthPostageRGIndTemp: e.currentTarget
                                          .checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  Retail Ground
                                </label>
                              </div>
                            )}
                            <span className="error-message" />
                          </div>
                        </div>
                        <React.Fragment />
                      </div>
                    </div>
                    <React.Fragment />
                  </div>
                </div>
                <div className="col-6">
                  <div className="row checkbox-wrap">
                    <div className="col-12 checkbox-container">
                      <p>
                        <strong>
                          Suppress Postage from Label by Address Preference
                        </strong>
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-12 radio-buttons all-updates-select-updates">
                        <div className="row">
                          <div
                            id="hide-postage-price-address-inputs-wrapper"
                            className="col-12 checkbox-wrap"
                          >
                            <p>
                              Select an address from your address book you wish
                              to hide postage displayed on your labels for:
                            </p>
                            <p className="sub-header">
                              Select an address from your{" "}
                              <AddressBook
                                buttonLabel={"Address Book"}
                                changeDeliveryAddressBatch={
                                  this.updateSelectedContacts
                                }
                                addressFieldType={"GABBatch"}
                                showInternational={"false"}
                                batchCanBeSelected={20}
                                targetName="btn-open-contact-preferences-hide-postage"
                                lookupCodes={this.props.lookupCodes}
                                hidePostage={true}
                              />
                            </p>
                            {this.state.hidePostageAddressesTemp && (
                              <div className="ret-ad-edit">
                                <div className="return-address-step user-return-address">
                                  {Object.keys(
                                    this.state.hidePostageAddressesTemp
                                  ).length <= 10 ? (
                                    this.renderTempHidePostageAddresses()
                                  ) : (
                                    <div id="hide-postage-price-address-inputs-table">
                                      {this.renderTempHidePostageAddresses()}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 button-wrapper">
                  <div className="button-container">
                    <button
                      type="button"
                      className="btn-primary shipment-notifications-save-btn"
                      tabIndex="0"
                      name="editHidePostagePrice"
                      onClick={(e) => this.saveOptions(e, e.target.name)}
                      aria-label="Save hide postage prices"
                    >
                      Save
                    </button>
                  </div>
                  <div className="button-container">
                    <button
                      type="button"
                      className="btn-primary button--white"
                      tabIndex="0"
                      onClick={(e) =>
                        this.cancelEdit(e, "editHidePostagePrice")
                      }
                      aria-label="Cancel hide postage prices"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSavedHidePostageAddresses = () => {
    return (
      <div className="ret-ad-edit">
        <div className="return-address-step user-return-address">
          <ul id="hide-postage-price-address-display-wrapper">
            {this.state.hidePostageAddresses &&
              Object.keys(this.state.hidePostageAddresses).map((contactId) => (
                <React.Fragment key={contactId}>
                  {this.state.hidePostageAddresses[contactId].address && (
                    <li id={"hide-postage-contact" + contactId} tabIndex="-1">
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-3 order-1">
                          <span>
                            {this.state.hidePostageAddresses[contactId]
                              .firstName +
                              " " +
                              this.state.hidePostageAddresses[contactId]
                                .lastName}
                          </span>
                        </div>
                        <div className="col-12 col-md-8 col-lg-9 order-3 order-md-2">
                          <span>
                            {this.state.hidePostageAddresses[contactId].address
                              .addressLine1 +
                              " " +
                              this.state.hidePostageAddresses[contactId].address
                                .city +
                              " " +
                              this.state.hidePostageAddresses[contactId].address
                                .state.stateCode}
                          </span>
                        </div>
                      </div>
                    </li>
                  )}
                </React.Fragment>
              ))}
          </ul>
        </div>
      </div>
    );
  };

  renderTempHidePostageAddresses = () => {
    return (
      <ol>
        {this.state.hidePostageAddressesTemp &&
          Object.keys(this.state.hidePostageAddressesTemp).map((contactId) => (
            <React.Fragment key={contactId}>
              {this.state.hidePostageAddressesTemp[contactId].address && (
                <li id={"hide-postage-contact" + contactId} tabIndex="-1">
                  <div className="row">
                    <div className="col-12 col-md-4 order-1">
                      <span>
                        {this.state.hidePostageAddressesTemp[contactId]
                          .firstName +
                          " " +
                          this.state.hidePostageAddressesTemp[contactId]
                            .lastName}
                      </span>
                    </div>
                    <div className="col-12 col-md-5 order-2">
                      <span>
                        {this.state.hidePostageAddressesTemp[contactId].address
                          .addressLine1 +
                          " " +
                          this.state.hidePostageAddressesTemp[contactId].address
                            .city +
                          " " +
                          this.state.hidePostageAddressesTemp[contactId].address
                            .state.stateCode}
                      </span>
                    </div>
                    <div className="col-12 col-md-3 order-3 text-left">
                      <RemoveContactModal
                        buttonLabel={"Remove"}
                        removeSelectedContact={this.removeSelectedContact}
                        contact={this.state.hidePostageAddressesTemp[contactId]}
                      />
                    </div>
                  </div>
                </li>
              )}
            </React.Fragment>
          ))}
      </ol>
    );
  };

  renderShipmentNotifications = () => {
    return (
      <div
        className="Shipment_Notifications_Container"
        id="shipment-notifications"
      >
        <div className="container-fluid">
          <h2 className="normal">Shipment Notifications</h2>
          <div className="row">
            <div className="col-12 col-md-11 col-lg-10 notifications-options-step contact-notifications">
              <p>
                Let us know how you would like to be contacted after a package
                is enroute to its destination.
              </p>
            </div>
          </div>
          {!this.state.editShipmentNotifications ? (
            <div
              id="shipment-notifications-top-id"
              className="row shipment-notifications-edit-section"
              tabIndex="-1"
            >
              <div className="col-12 notifications-options-step notifications-options-line">
                {this.state.ptsNotifyInd !== "Y" ? (
                  <div className="ship-notifications-static-section">
                    <p>
                      <strong>Notifications Options</strong>
                    </p>
                    <p>
                      Do not send USPS Tracking<sup>&trade;</sup> Notifications.
                    </p>
                  </div>
                ) : null}
                {this.state.ptsNotifyInd === "Y" ? (
                  <div className="">
                    {this.state.ptsTrackingEmail ? (
                      <React.Fragment>
                        <div className="email-update">
                          <span>
                            <p>Email Updates</p>
                          </span>
                          <p>{this.state.ptsTrackingEmail}</p>
                        </div>
                        <br />
                      </React.Fragment>
                    ) : null}
                    {this.state.ptsTxtPhoneNbr ? (
                      <React.Fragment>
                        <div className="email-update">
                          <span>
                            <p>Text Message Updates</p>
                          </span>
                          <p>{this.state.ptsTxtPhoneNbr}</p>
                        </div>
                        <br />
                      </React.Fragment>
                    ) : null}
                    <div>
                      <span>
                        <p>Updates</p>
                      </span>
                      <p>
                        {this.state.ptsExpectedDelvInd === "Y"
                          ? "Expected Delivery Options"
                          : ""}
                      </p>
                      <p>
                        {this.state.ptsDayOfDelvInd === "Y"
                          ? "Day of Delivery Updates"
                          : ""}
                      </p>
                      <p>
                        {this.state.ptsDeliveredToAddrInd === "Y"
                          ? "Package Delivery to Address"
                          : ""}
                      </p>
                      <p>
                        {this.state.ptsPickupAvailableInd === "Y"
                          ? "Available for Pickup"
                          : ""}
                      </p>
                      <p>
                        {this.state.ptsAlertsOtherInd === "Y"
                          ? "Alert Other Updates"
                          : ""}
                      </p>
                      <p>
                        {this.state.ptsPkgInTransitInd === "Y"
                          ? "Package In-Transit Updates"
                          : ""}
                      </p>
                    </div>
                  </div>
                ) : (
                  <React.Fragment />
                )}
                <div className="edit-link">
                  <button
                    type="button"
                    className="inline-link-edit shipment-notifications-edit button-link"
                    onClick={(e) => {
                      e.preventDefault();
                      this.state.errors.shipEmail !== ""
                        ? this.clearError("shipEmail")
                        : this.clearError("");
                      this.state.errors.shipText !== ""
                        ? this.clearError("shipText")
                        : this.clearError("");
                      this.setState({
                        editShipmentNotifications: true,
                        editShipmentNotificationsFocus: true
                      });
                    }}
                    aria-label="Edit shipment notifications"
                  >
                    <strong>Edit</strong>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div id="shipment-notifications-top-id-edit" tabIndex="-1">
              <div className="row checkbox-wrap">
                <div className="col-12 checkbox-container">
                  <label
                    className="checkbox-component"
                    htmlFor="shipment-notifications-checkbox"
                  >
                    <input
                      type="checkbox"
                      id="shipment-notifications-checkbox"
                      name="shipment-notifications-checkbox"
                      onChange={this.showTrackingEmailandNumber}
                      checked={
                        this.state.ptsNotifyIndTemp !== undefined
                          ? this.state.ptsNotifyIndTemp === "Y"
                          : this.state.ptsNotifyInd === "Y"
                      }
                    />
                    <span className="checkbox" /> I would like to get tracking
                    and confirmation notifications via email and/or text
                    message.
                  </label>
                </div>
                <span className="error-message" />
                {this.state.ptsNotifyIndTemp === "Y" ||
                (!this.state.ptsNotifyIndTemp &&
                  this.state.ptsNotifyInd === "Y") ? (
                  <div
                    id="shipment-notifications-checkbox-wrapper"
                    className="col-12"
                  >
                    <div className="row">
                      <div
                        className={
                          "col-12 col-md-9 form-group required-field" +
                          (this.state.errors.shipEmail !== "" ? " error" : "")
                        }
                      >
                        <AssistiveError
                          inputId="shipEmailId"
                          errorMessage={this.state.errors.shipEmail}
                        />
                        <label htmlFor="shipEmailId">*Email</label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="shipEmailId"
                          className="form-control"
                          placeholder="email123@email.com"
                          onChange={(e) => {
                            this.setState({
                              ptsTrackingEmailTemp: e.target.value
                            });
                            this.state.errors.shipEmail !== ""
                              ? this.clearError("shipEmail")
                              : this.clearError("");
                          }}
                          value={
                            this.state.ptsTrackingEmailTemp !== undefined
                              ? this.state.ptsTrackingEmailTemp
                              : this.state.ptsTrackingEmail
                          }
                        />
                        <span className="error-message">
                          {this.state.errors.shipEmail}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-12 col-md-9 extend form-group required-field" +
                          (this.state.errors.shipText !== "" ? " error" : "")
                        }
                      >
                        <AssistiveError
                          inputId="shipTextId"
                          errorMessage={this.state.errors.shipText}
                        />
                        <label htmlFor="shipTextId">
                          *Text Message{" "}
                          <TextMessagePopover targetName="preferencesTextMessageIcon" />
                        </label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="shipTextId"
                          className="form-control"
                          placeholder="(000) 000-0000"
                          onChange={(e) => {
                            this.setState({
                              ptsTxtPhoneNbrTemp: e.target.value
                            });
                            this.state.errors.shipText !== ""
                              ? this.clearError("shipText")
                              : this.clearError("");
                          }}
                          value={
                            this.state.ptsTxtPhoneNbrTemp !== undefined
                              ? this.state.ptsTxtPhoneNbrTemp
                              : this.state.ptsTxtPhoneNbr
                          }
                        />
                        <span className="error-message">
                          {this.state.errors.shipText}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 radio-buttons all-updates-select-updates">
                        <div className="radio-wrap">
                          <div className="radio-container">
                            <input
                              id="all-updates-radio"
                              type="radio"
                              className="radio-button"
                              name="all-updates-select-updates"
                              value="all-updates"
                              tabIndex="0"
                              onChange={(e) => this.selectAllOptions()}
                              checked={
                                this.state.allOptionsTemp !== undefined
                                  ? this.state.allOptionsTemp
                                  : this.state.allOptions
                              }
                            />
                            <label htmlFor="all-updates-radio">
                              All Updates
                            </label>
                          </div>
                          <div className="radio-container">
                            <input
                              id="select-updates-radio"
                              type="radio"
                              className="radio-button"
                              name="all-updates-select-updates"
                              value="select-updates"
                              tabIndex="0"
                              onChange={(e) => this.selectSomeOptions()}
                              checked={
                                this.state.allOptionsTemp !== undefined
                                  ? !this.state.allOptionsTemp
                                  : !this.state.allOptions
                              }
                            />
                            <label htmlFor="select-updates-radio">
                              Select Updates
                            </label>
                          </div>
                          <span className="error-message" />
                        </div>
                        {(this.state.allOptionsTemp !== undefined &&
                          !this.state.allOptionsTemp) ||
                        (this.state.allOptionsTemp === undefined &&
                          !this.state.allOptions) ? (
                          <div className="row">
                            <div
                              id="select-updates-container"
                              className="col-12 checkbox-wrap"
                            >
                              <p>
                                Choose your desired updates from the list below
                              </p>
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="expected-delivery-options-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="expected-delivery-options-checkbox"
                                    checked={
                                      this.state.ptsExpectedDelvIndTemp !==
                                      undefined
                                        ? this.state.ptsExpectedDelvIndTemp ===
                                          "Y"
                                        : this.state.ptsExpectedDelvInd === "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        ptsExpectedDelvIndTemp: e.currentTarget
                                          .checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  Expected Delivery Options
                                </label>
                              </div>
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="day-delivery-updates-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="day-delivery-updates-checkbox"
                                    checked={
                                      this.state.ptsDayOfDelvIndTemp !==
                                      undefined
                                        ? this.state.ptsDayOfDelvIndTemp === "Y"
                                        : this.state.ptsDayOfDelvInd === "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        ptsDayOfDelvIndTemp: e.currentTarget
                                          .checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  Day of Delivery Updates
                                </label>
                              </div>
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="package-delivery-to-address-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="package-delivery-to-address-checkbox"
                                    checked={
                                      this.state.ptsDeliveredToAddrIndTemp !==
                                      undefined
                                        ? this.state
                                            .ptsDeliveredToAddrIndTemp === "Y"
                                        : this.state.ptsDeliveredToAddrInd ===
                                          "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        ptsDeliveredToAddrIndTemp: e
                                          .currentTarget.checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  Package Delivery to Address
                                </label>
                              </div>
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="available-for-pickup-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="available-for-pickup-checkbox"
                                    checked={
                                      this.state.ptsPickupAvailableIndTemp !==
                                      undefined
                                        ? this.state
                                            .ptsPickupAvailableIndTemp === "Y"
                                        : this.state.ptsPickupAvailableInd ===
                                          "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        ptsPickupAvailableIndTemp: e
                                          .currentTarget.checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  Available for Pickup
                                </label>
                              </div>
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="alerts-other-updates-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="alerts-other-updates-checkbox"
                                    checked={
                                      this.state.ptsAlertsOtherIndTemp !==
                                      undefined
                                        ? this.state.ptsAlertsOtherIndTemp ===
                                          "Y"
                                        : this.state.ptsAlertsOtherInd === "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        ptsAlertsOtherIndTemp: e.currentTarget
                                          .checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  Alerts & Other Updates
                                </label>
                              </div>
                              <div className="checkbox-container">
                                <label
                                  className="checkbox-component"
                                  htmlFor="package-in-transit-updates-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="package-in-transit-updates-checkbox"
                                    checked={
                                      this.state.ptsPkgInTransitIndTemp !==
                                      undefined
                                        ? this.state.ptsPkgInTransitIndTemp ===
                                          "Y"
                                        : this.state.ptsPkgInTransitInd === "Y"
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        ptsPkgInTransitIndTemp: e.currentTarget
                                          .checked
                                          ? "Y"
                                          : "N"
                                      })
                                    }
                                  />
                                  <span className="checkbox" />
                                  Package In-Transit Updates
                                </label>
                              </div>
                              <span className="error-message" />
                            </div>
                          </div>
                        ) : (
                          <React.Fragment />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment />
                )}
                <div className="col-12 button-wrapper">
                  <div className="button-container">
                    <button
                      type="button"
                      className="btn-primary shipment-notifications-save-btn"
                      tabIndex="0"
                      name="editShipmentNotifications"
                      onClick={(e) => this.saveOptions(e, e.target.name)}
                      aria-label="Save shipment notifications"
                    >
                      Save
                    </button>
                  </div>
                  <div className="button-container">
                    <button
                      type="button"
                      className="btn-primary button--white"
                      tabIndex="0"
                      onClick={(e) =>
                        this.cancelEdit(e, "editShipmentNotifications")
                      }
                      aria-label="Cancel shipment notifications"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderUSPSConnect = () => {
    return (
      <div className="Shipment_Notifications_Container" id="connect-local-id">
        <div className="container-fluid">
          <h2 className="normal">USPS Connect</h2>
          <div className="row">
            <div className="col-12 col-md-11 col-lg-10 notifications-options-step contact-notifications">
              <p>
                Let us know how far you are willing to drive for USPS Connect
                Packages.
              </p>
            </div>
          </div>
          <div className="row" tabIndex="-1">
            <div className="col-12 notify-recipient-step connectLocal-wrapper">
              <p>
                <strong>USPS Connect Radius</strong>
              </p>
              {!this.state.editUSPSConnect ? (
                <div className="edit-link">
                  {this.state.connectLocalRadius && (
                    <p>{this.state.connectLocalRadius} mi</p>
                  )}

                  <button
                    type="button"
                    className="inline-link-edit shipment-notifications-edit button-link"
                    onClick={(e) => {
                      e.preventDefault();

                      this.setState({
                        editUSPSConnect: true,
                        editUSPSConnectFocus: true
                      });
                    }}
                    aria-label="Edit Connect Local"
                  >
                    <strong>Edit</strong>
                  </button>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <InputFields
                      divClassList="col-lg-3 col-md-4 col-sm-12 select-miles"
                      inputId="radiusInputId"
                      labelText=""
                      inputValue={
                        this.state.connectLocalRadiusTemp !== undefined
                          ? this.state.connectLocalRadiusTemp
                          : this.state.connectLocalRadius
                      }
                      inputOnChange={(e) => {
                        this.setRadius(e.currentTarget.value);
                      }}
                      elementType="select"
                      optionsList={[
                        {
                          name: "Select",
                          value: ""
                        },

                        {
                          name: "1 mile",
                          value: "1"
                        },
                        {
                          name: "5 miles",
                          value: "5"
                        },
                        {
                          name: "10 miles",
                          value: "10"
                        },
                        {
                          name: "20 miles",
                          value: "20"
                        },
                        {
                          name: "30 miles",
                          value: "30"
                        },
                        {
                          name: "50 miles",
                          value: "50"
                        },
                        {
                          name: "100 miles",
                          value: "100"
                        }
                      ]}
                    />
                  </div>
                </div>
              )}
            </div>

            <div id="PreferencesConnectMapWrapper">
              <div className="pref-map-location-title">
                <p>
                  <strong>USPS Locations</strong>
                </p>
              </div>
              <UspsConnectMap
                fetchDropOffLocations={this.state.fetchDropOffLocations}
                setFetchDropOffLocations={this.setFetchDropOffLocations}
                shipFromAddress={{
                  returnLine1Addr: this.state.line1Addr || "",
                  returnLine2Addr: this.state.line2Addr || "",
                  returnCity: this.state.cityName || "",
                  returnStateId: this.state.stateId || 0,
                  returnZip5:
                    (this.state.postalCode &&
                      this.state.postalCode.substring(0, 5)) ||
                    "",
                  returnZipPlus4:
                    (this.state.postalCode &&
                      this.state.postalCode.substring(6)) ||
                    ""
                }}
                radius={
                  this.state.connectLocalRadiusTemp !== undefined
                    ? this.state.connectLocalRadiusTemp
                    : this.state.connectLocalRadius
                }
                setGlobalError={this.setGlobalError}
                mapSource="preferences"
                lookupCodes={this.props.lookupCodes}
              />
            </div>
            {this.state.editUSPSConnect ? (
              <div className="col-12 button-wrapper">
                <div className="button-container">
                  <button
                    type="button"
                    className="btn-primary shipment-notifications-save-btn"
                    tabIndex="0"
                    name="editUSPSConnect"
                    onClick={(e) => this.saveOptions(e, e.target.name)}
                    aria-label="Save Connect Local Radius"
                  >
                    Save
                  </button>
                </div>
                <div className="button-container">
                  <button
                    type="button"
                    className="btn-primary button--white"
                    tabIndex="0"
                    onClick={(e) => {
                      this.cancelEdit(e, "editUSPSConnect");
                      this.setFetchDropOffLocations(true);
                    }}
                    aria-label="Cancel Connect Local"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderNotifyRecipient = () => {
    return (
      <div
        className="Notify_Recipient_Shipping_Container"
        id="notify-recipient"
      >
        <div className="container-fluid">
          <h2 className="normal">Notify Recipient of Shipping</h2>
          <div className="row">
            <div className="col-12 notify-recipient-step set-message-line">
              <p>
                Set a message to send to your recipient along with the tracking
                number.
              </p>
            </div>
          </div>
          <div id="shipping-message-top-id" className="row" tabIndex="-1">
            <div className="col-12 notify-recipient-step shipping-message-wrapper">
              <p>
                <strong>Shipping Message</strong>
              </p>
              {!this.state.editShippingMessage ? (
                <React.Fragment>
                  <div className="notify-recip-default-message">
                    <p>
                      {this.state.shipNotifyInd === "Y" ? (
                        <React.Fragment>
                          <br />
                          Automatically notify recipient of shipping
                        </React.Fragment>
                      ) : (
                        "Do not automatically notify recipient."
                      )}
                    </p>
                    <div className="default-message">
                      {this.state.toNote ? (
                        <React.Fragment>
                          <span>
                            <p>Default Message</p>
                          </span>
                          <p>{this.state.toNote}</p>
                        </React.Fragment>
                      ) : (
                        <p>No default message has been set.</p>
                      )}
                    </div>
                  </div>
                  <div className="notify-recip-updates">
                    <p>Automatically notify recipient of shipping</p>
                    <div className="form-group">
                      <span>Default Message</span>
                      <p>Your package is on its way</p>
                    </div>
                  </div>
                  <div className="notify-recipient-step-edit">
                    <div className="edit-link notify-recipient-edit-btn">
                      <button
                        type="button"
                        className="inline-link-edit button-link"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            editShippingMessage: true,
                            editShippingMessageFocus: true
                          });
                        }}
                        aria-label="Edit shipping message"
                      >
                        <strong>Edit</strong>
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div className="">
                  <div className="row">
                    <div className="col-12 col-md-6 form-group">
                      <label
                        className="checkbox-component"
                        htmlFor="auto-notify-checkbox"
                      >
                        <input
                          type="checkbox"
                          id="auto-notify-checkbox"
                          checked={
                            this.state.shipNotifyIndTemp !== undefined
                              ? this.state.shipNotifyIndTemp === "Y"
                              : this.state.shipNotifyInd === "Y"
                          }
                          onChange={(e) =>
                            this.setState({
                              shipNotifyIndTemp: e.currentTarget.checked
                                ? "Y"
                                : "N"
                            })
                          }
                        />
                        <span className="checkbox" />
                        Automatically notify recipient of shipping.
                      </label>
                    </div>
                    <div
                      className={
                        "col-12 col-md-8 textarea-counter-wrapper still form-group required-field"
                      }
                    >
                      <label htmlFor="click-ship-message-box">
                        Include a message
                      </label>
                      <textarea
                        className="form-control textarea-counter extra-input-field"
                        id="click-ship-message-box"
                        name="text"
                        maxLength="125"
                        placeholder="Type in your feedback"
                        rows="5"
                        value={
                          this.state.toNoteTemp !== undefined
                            ? this.state.toNoteTemp
                            : this.state.toNote
                        }
                        onChange={(e) => {
                          this.setState({
                            toNoteTemp: e.currentTarget.value
                          });
                        }}
                      />
                      <span
                        className="label label-default"
                        id="click-ship-message-counter"
                      >
                        {this.state.toNoteTemp !== undefined
                          ? this.state.toNoteTemp.length
                          : this.state.toNote.length}{" "}
                        / 125
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 button-wrapper">
                      <div className="button-container">
                        <button
                          type="button"
                          className="btn-primary notify-recipient-save-btn"
                          tabIndex="0"
                          name="editShippingMessage"
                          onClick={(e) => this.saveOptions(e, e.target.name)}
                          aria-label="Save shipping message"
                        >
                          Save
                        </button>
                      </div>
                      <div className="button-container">
                        <button
                          type="button"
                          className="btn-primary button--white"
                          tabIndex="0"
                          onClick={(e) =>
                            this.cancelEdit(e, "editShippingMessage")
                          }
                          aria-label="Cancel shipping message"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPrintSettings = () => {
    return (
      <div className="Print_Settings_Container" id="print-settings">
        <div className="container-fluid">
          <h2 className="normal">Print Settings</h2>
          <div className="row">
            <div className="col-12 print-settings-step print-settings-line">
              <p>
                Save print settings you would like to appear on your
                confirmation page.
              </p>
            </div>
          </div>
          <div id="print-preferences-top-id" className="row" tabIndex="-1">
            <div className="col-12 print-settings-step print-reference-line">
              <p>
                <strong>Print Preferences</strong>
              </p>
              {!this.state.editPrintSettings ? (
                <React.Fragment>
                  <p className="print-settings-deflual-text">
                    {this.state.printOptions === "Y"
                      ? "Print labels with receipt (1 per page)"
                      : this.state.printOptions === "N"
                      ? "Print labels without receipt (2 per page)"
                      : "No print preference has been set."}
                  </p>
                  <p className="print-settings-updates">
                    Print label with receipt (1 per page).
                  </p>
                  <div className="print-preferences-edit-link">
                    <div className="edit-link print-preferences-edit-btn">
                      <button
                        type="button"
                        className="inline-link-edit button-link"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            editPrintSettings: true,
                            editPrintSettingsFocus: true
                          });
                        }}
                        aria-label="Edit print settings"
                      >
                        <strong>Edit</strong>
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
            </div>
            {this.state.editPrintSettings ? (
              <div className="">
                <div className="col-12 radio-buttons">
                  <div className="radio-wrap">
                    <div className="radio-container">
                      <input
                        id="print-labels-with-receipt-radio"
                        type="radio"
                        className="radio-button"
                        name="print-preferences-edit-radio"
                        tabIndex="0"
                        onChange={(e) =>
                          this.setState({
                            printOptionsTemp: e.currentTarget.checked
                              ? "Y"
                              : "N"
                          })
                        }
                        checked={
                          this.state.printOptionsTemp !== undefined
                            ? this.state.printOptionsTemp === "Y"
                            : this.state.printOptions === "Y"
                        }
                      />
                      <label htmlFor="print-labels-with-receipt-radio">
                        Print labels with receipt. Each label will print on one
                        half of the page and the receipt/label record will print
                        on the other half.{" "}
                        <ReceiptPrintLabelsPopover targetName="receiptPrintLabelsIcon" />
                      </label>
                    </div>
                    <div className="radio-container">
                      <input
                        id="print-labels-without-receipt-radio"
                        type="radio"
                        className="radio-button"
                        name="print-preferences-edit-radio"
                        tabIndex="0"
                        onChange={(e) =>
                          this.setState({
                            printOptionsTemp: e.currentTarget.checked
                              ? "N"
                              : "Y"
                          })
                        }
                        checked={
                          this.state.printOptionsTemp !== undefined
                            ? this.state.printOptionsTemp === "N"
                            : this.state.printOptions === "N"
                        }
                      />
                      <label htmlFor="print-labels-without-receipt-radio">
                        Print labels without receipt. Two labels on one page.
                        Receipt information is also in your confirmation email
                        and Shipping History.
                      </label>
                    </div>
                    <span className="error-message" />
                  </div>
                  <div className="row">
                    <div className="col-12 button-wrapper print-preferences-edit-btns">
                      <div className="button-container">
                        <button
                          type="button"
                          className="btn-primary print-preferences-save-edit-btn"
                          tabIndex="0"
                          name="editPrintSettings"
                          onClick={(e) => this.saveOptions(e, e.target.name)}
                          aria-label="Save print settings"
                        >
                          Save
                        </button>
                      </div>
                      <div className="button-container">
                        <button
                          type="button"
                          className="btn-primary button--white"
                          tabIndex="0"
                          onClick={(e) =>
                            this.cancelEdit(e, "editPrintSettings")
                          }
                          aria-label="Cancel print settings"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="button-container">
                        <button
                          type="button"
                          className="btn-primary button--white ser-perf-clear-btn"
                          tabIndex="0"
                          onClick={(e) => this.clearPrintSettings(e)}
                          aria-label="Clear print settings"
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
          <div className="row">
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderReferenceNumber = () => {
    return (
      <div className="Reference_Number_Container" id="reference-number">
        <div className="container-fluid">
          <h2 className="normal">Reference Number</h2>
          <div className="row">
            <div className="col-12 reference-number-step reference-number-line-characters">
              <p>
                This number is for your reference only. You may use up to 10
                characters.
              </p>
            </div>
          </div>
          <div id="reference-number-top-id" className="row" tabIndex="-1">
            <div className="col-12 reference-number-step reference-number-line">
              <p>
                <strong>Reference Number</strong>
              </p>
              {!this.state.editReferenceNumber ? (
                <React.Fragment>
                  <p className="ref-num-edit-deflual">
                    {this.state.refNbr
                      ? this.state.refNbr
                      : "No reference number has been set."}
                  </p>
                  <p className="ref-num-edit-updates">209765435</p>
                  <div className="ref-num-edit">
                    <div className="edit-link ref-num-edit-link">
                      <button
                        type="button"
                        className="inline-link-edit button-link"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            editReferenceNumber: true,
                            editReferenceNumberFocus: true
                          });
                        }}
                        aria-label="Edit reference number"
                      >
                        <strong>Edit</strong>
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div className="row refrence-number-edit-append">
                  <div className="col-12 col-md-6 form-group required-field">
                    <label htmlFor="reference-number-id" className="inputLabel">
                      Reference number to be printed on label
                    </label>
                    <input
                      tabIndex="0"
                      type="text"
                      id="reference-number-id"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          refNbrTemp: e.currentTarget.value
                        })
                      }
                      value={
                        this.state.refNbrTemp !== undefined
                          ? this.state.refNbrTemp
                          : this.state.refNbr
                      }
                      maxLength="10"
                    />
                    <span className="error-message" />
                  </div>
                  <div className="col-12 button-wrapper reference-number-edit-btns">
                    <div className="button-container">
                      <button
                        type="button"
                        className="btn-primary refrence-number-save-edit-btn"
                        tabIndex="0"
                        name="editReferenceNumber"
                        onClick={(e) => this.saveOptions(e, e.target.name)}
                        aria-label="Save reference number"
                      >
                        Save
                      </button>
                    </div>
                    <div className="button-container">
                      <button
                        type="button"
                        className="btn-primary button--white"
                        tabIndex="0"
                        onClick={(e) =>
                          this.cancelEdit(e, "editReferenceNumber")
                        }
                        aria-label="Cancel reference number"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderReturnAddress = () => {
    return (
      <div className="Return_Address_Container" id="return-address">
        <div className="container-fluid">
          <h2 className="normal">Return Address</h2>
          <div className="row">
            <div className="col-12 return-adddress-step return-adddress-line-label">
              <p>
                Save the return address we should use on your label. This does
                not change your customer registered address.
              </p>
            </div>
          </div>
          <div id="return-address-top-id" className="row" tabIndex="-1">
            <div className="col-12 return-adddress-step return-adddress-line">
              <p>
                <strong>Return Address</strong>
              </p>
              {!this.state.editReturnAddress ? (
                <div className="ret-ad-edit">
                  <div className="return-address-step  user-return-address">
                    <span>
                      {this.state.firstName} {this.state.lastName}
                    </span>
                    <span>{this.state.companyName}</span>
                    <span>{this.state.line1Addr}</span>
                    <span>
                      {this.state.cityName}, {this.state.stateCode}{" "}
                      {this.state.postalCode}
                    </span>
                  </div>
                  <div className="edit-link">
                    <button
                      type="button"
                      className="inline-link-edit ret-ad-edit-link button-link"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          editReturnAddress: true,
                          editReturnAddressFocus: true
                        });
                        this.state.errors.returnFirstName !== ""
                          ? this.clearError("returnFirstName")
                          : this.clearError("");
                        this.state.errors.returnLastName !== ""
                          ? this.clearError("returnLastName")
                          : this.clearError("");
                        this.state.errors.returnStreetAddr !== ""
                          ? this.clearError("returnStreetAddr")
                          : this.clearError("");
                        this.state.errors.returnAptSuiteOther !== ""
                          ? this.clearError("returnAptSuiteOther")
                          : this.clearError("");
                        this.state.errors.returnCity !== ""
                          ? this.clearError("returnCity")
                          : this.clearError("");
                        this.state.errors.returnState !== ""
                          ? this.clearError("returnState")
                          : this.clearError("");
                        this.state.errors.returnZipCode !== ""
                          ? this.clearError("returnZipCode")
                          : this.clearError("");
                      }}
                      aria-label="Edit return address"
                    >
                      <strong>Edit</strong>
                    </button>
                  </div>
                  <span> | </span>
                  <div className="delete-link">
                    <button
                      type="button"
                      className="inline-link-remove button-link"
                      onClick={(e) => this.removeReturnAddress(e)}
                      aria-label="Remove return address"
                    >
                      <strong>Remove</strong>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 col-md-11 col-lg-10 ">
                    <div className="row">
                      <div className="col-12">
                        <p className="sub-header">
                          Use address from your{" "}
                          <AddressBook
                            buttonLabel={"Address Book"}
                            changeDeliveryAddress={
                              this.changeReturnAddressField
                            }
                            addressFieldType={"GABReturn"}
                            showInternational={"false"}
                            targetName="btn-open-contact-preferences"
                            lookupCodes={this.props.lookupCodes}
                          />
                        </p>
                        <p>
                          Your address will be standardized.{" "}
                          <AddressStandardizedPopover targetName="AddressStandardizedPreferencesIcon" />
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-9 col-md-4 col-lg-5 form-group required-field" +
                          (this.state.errors.returnFirstName !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="return-address-first-name"
                          errorMessage={this.state.errors.returnFirstName}
                        />
                        <label htmlFor="return-address-first-name">
                          *First Name
                        </label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="return-address-first-name"
                          className="form-control"
                          placeholder="First"
                          value={
                            this.state.firstNameTemp !== undefined
                              ? this.state.firstNameTemp
                              : this.state.firstName
                          }
                          onChange={(e) => {
                            this.setState({
                              firstNameTemp: e.currentTarget.value
                            });
                            this.state.errors.returnFirstName !== ""
                              ? this.clearError("returnFirstName")
                              : this.clearError("");
                          }}
                          maxLength="50"
                        />
                        <span className="error-message">
                          {this.state.errors.returnFirstName}
                        </span>
                      </div>
                      <div className="col-3 col-md-2 col-lg-1 form-group">
                        <label htmlFor="return-address-mi">MI</label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="return-address-mi"
                          className="form-control"
                          maxLength="1"
                          value={
                            this.state.middleInitTemp !== undefined
                              ? this.state.middleInitTemp
                              : this.state.middleInit
                          }
                          onChange={(e) =>
                            this.setState({
                              middleInitTemp: e.currentTarget.value
                            })
                          }
                        />
                      </div>
                      <div
                        className={
                          "col-12 col-md-6 form-group required-field" +
                          (this.state.errors.returnLastName !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="return-address-last-name"
                          errorMessage={this.state.errors.returnLastName}
                        />
                        <label htmlFor="return-address-last-name">
                          *Last Name
                        </label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="return-address-last-name"
                          className="form-control"
                          placeholder="Last"
                          value={
                            this.state.lastNameTemp !== undefined
                              ? this.state.lastNameTemp
                              : this.state.lastName
                          }
                          onChange={(e) => {
                            this.setState({
                              lastNameTemp: e.currentTarget.value
                            });
                            this.state.errors.returnLastName !== ""
                              ? this.clearError("returnLastName")
                              : this.clearError("");
                          }}
                          maxLength="50"
                        />
                        <span className="error-message">
                          {this.state.errors.returnLastName}
                        </span>
                      </div>
                    </div>
                    <div className="row return-address-fields">
                      <div
                        className={
                          "col-12 col-md-6 form-group business-field" +
                          (this.state.errors.returnCompany !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="return-address-company"
                          errorMessage={this.state.errors.returnCompany}
                        />
                        <label htmlFor="return-address-company">Company</label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="return-address-company"
                          className="form-control"
                          placeholder="Company Name"
                          value={
                            this.state.companyNameTemp !== undefined
                              ? this.state.companyNameTemp
                              : this.state.companyName
                          }
                          onChange={(e) => {
                            this.setState({
                              companyNameTemp: e.currentTarget.value
                            });
                            this.state.errors.returnCompany !== ""
                              ? this.clearError("returnCompany")
                              : this.clearError("");
                          }}
                          maxLength="50"
                        />
                        <span className="error-message">
                          {this.state.errors.returnCompany}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-12 col-md-6 form-group required-field" +
                          (this.state.errors.returnStreetAddr !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="return-address-street-address"
                          errorMessage={this.state.errors.returnStreetAddr}
                        />
                        <label htmlFor="return-address-street-address">
                          *Street Address
                        </label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="return-address-street-address"
                          className="form-control"
                          placeholder="123 Main Street"
                          value={
                            this.state.line1AddrTemp !== undefined
                              ? this.state.line1AddrTemp
                              : this.state.line1Addr
                          }
                          onChange={(e) => {
                            this.setState({
                              line1AddrTemp: e.currentTarget.value
                            });
                            this.state.errors.returnStreetAddr !== ""
                              ? this.clearError("returnStreetAddr")
                              : this.clearError("");
                          }}
                          maxLength="50"
                        />
                        <span className="error-message">
                          {this.state.errors.returnStreetAddr}
                        </span>
                      </div>
                      <div
                        className={
                          "col-12 col-md-6 form-group" +
                          (this.state.errors.returnAptSuiteOther !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="return-address-apt"
                          errorMessage={this.state.errors.returnAptSuiteOther}
                        />
                        <label htmlFor="return-address-apt">
                          Apt/Suite/Other
                        </label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="return-address-apt"
                          className="form-control"
                          value={
                            this.state.line2AddrTemp !== undefined
                              ? this.state.line2AddrTemp
                              : this.state.line2Addr
                          }
                          onChange={(e) => {
                            this.setState({
                              line2AddrTemp: e.currentTarget.value
                            });
                            this.state.errors.returnAptSuiteOther !== ""
                              ? this.clearError("returnAptSuiteOther")
                              : this.clearError("");
                          }}
                          maxLength="50"
                        />
                        <span className="error-message">
                          {this.state.errors.returnAptSuiteOther}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          "col-12 col-md-6 form-group required-field" +
                          (this.state.errors.returnCity !== "" ? " error" : "")
                        }
                      >
                        <AssistiveError
                          inputId="return-address-city"
                          errorMessage={this.state.errors.returnCity}
                        />
                        <label htmlFor="return-address-city">*City</label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="return-address-city"
                          className="form-control"
                          placeholder="City"
                          value={
                            this.state.cityNameTemp !== undefined
                              ? this.state.cityNameTemp
                              : this.state.cityName
                          }
                          onChange={(e) => {
                            this.setState({
                              cityNameTemp: e.currentTarget.value
                            });
                            this.state.errors.returnCity !== ""
                              ? this.clearError("returnCity")
                              : this.clearError("");
                          }}
                        />
                        <span className="error-message">
                          {this.state.errors.returnCity}
                        </span>
                      </div>
                      <div
                        className={
                          "col-7 col-md-3 form-group required-field" +
                          (this.state.errors.returnState !== "" ? " error" : "")
                        }
                      >
                        <AssistiveError
                          inputId="return-address-state"
                          errorMessage={this.state.errors.returnState}
                        />
                        <label htmlFor="return-address-state">*State</label>
                        <select
                          id="return-address-state"
                          className="form-control state-dropdown dropdown"
                          onChange={(e) => {
                            this.setState({
                              stateIdTemp: e.currentTarget.value
                            });
                            this.state.errors.returnState !== ""
                              ? this.clearError("returnState")
                              : this.clearError("");
                          }}
                          value={
                            this.state.stateIdTemp !== undefined
                              ? this.state.stateIdTemp
                              : this.state.stateId
                          }
                        >
                          <StatesTerritoriesList />
                        </select>
                        <span className="error-message">
                          {this.state.errors.returnState}
                        </span>
                      </div>
                      <div
                        className={
                          "col-5 col-md-3 form-group required-field" +
                          (this.state.errors.returnZipCode !== ""
                            ? " error"
                            : "")
                        }
                      >
                        <AssistiveError
                          inputId="return-address-zip"
                          errorMessage={this.state.errors.returnZipCode}
                        />
                        <label htmlFor="return-address-zip">
                          *ZIP Code<sup>™</sup>
                        </label>
                        <input
                          tabIndex="0"
                          type="text"
                          id="return-address-zip"
                          className="form-control"
                          placeholder="00000"
                          maxLength="5"
                          value={
                            this.state.postalCodeTemp !== undefined
                              ? this.state.postalCodeTemp
                              : this.state.postalCode
                          }
                          onChange={(e) => {
                            this.setState({
                              postalCodeTemp: e.currentTarget.value
                            });
                            this.state.errors.returnZipCode !== ""
                              ? this.clearError("returnZipCode")
                              : this.clearError("");
                          }}
                        />
                        <span className="error-message">
                          {this.state.errors.returnZipCode}
                        </span>
                      </div>
                    </div>
                    {(this.state.stateIdTemp !== undefined &&
                      this.state.stateIdTemp === "49") ||
                    (this.state.stateIdTemp === undefined &&
                      this.state.Id === "49") ? (
                      <div className="row">
                        <div className="col-12 col-md-6 form-group urbanization-code-input">
                          <label htmlFor="return-address-urbanization-code">
                            Urbanization Code
                          </label>
                          <input
                            tabIndex="0"
                            type="text"
                            id="return-address-urbanization-code"
                            className="form-control"
                            value={
                              this.state.urbanizationCodeTemp !== undefined
                                ? this.state.urbanizationCodeTemp
                                : this.state.urbanizationCode
                            }
                            onChange={(e) =>
                              this.setState({
                                urbanizationCodeTemp: e.currentTarget.value
                              })
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <React.Fragment />
                    )}
                    <div className="row">
                      <div className="col-12 form-group">
                        <label
                          className="checkbox-component"
                          htmlFor="business-checkbox"
                        >
                          <input
                            type="checkbox"
                            id="business-checkbox"
                            checked={this.state.saveReturnToAddressBook}
                            onChange={(e) =>
                              this.setState({
                                saveReturnToAddressBook: e.currentTarget.checked
                              })
                            }
                          />
                          <span className="checkbox" />
                          Save this address to my Address Book
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 button-wrapper return-address-edit-btns">
                        <div className="button-container">
                          <button
                            type="button"
                            className="btn-primary return-address-save-edit-btn"
                            tabIndex="0"
                            onClick={(e) => this.saveReturnAddress(e)}
                            aria-label="Save return address"
                          >
                            Save
                          </button>
                        </div>
                        <div className="button-container">
                          <button
                            type="button"
                            className="btn-primary button--white return-address-save-cancel-btn"
                            tabIndex="0"
                            onClick={(e) =>
                              this.cancelEdit(e, "editReturnAddress")
                            }
                            aria-label="Cancel return address"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="button-container">
                          <button
                            type="button"
                            className="btn-primary button--white return-address-save-clear-btn"
                            tabIndex="0"
                            onClick={(e) => this.clearReturnAddress(e)}
                            aria-label="Clear return address"
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderShippingZipCode = () => {
    return (
      <div className="Shipping_Zip_Code_Container" id="shipping-zip-code">
        <div className="container-fluid">
          <h2 className="normal">
            Shipping from ZIP Code<sup>™</sup>
          </h2>
          <div className="row">
            <div className="col-12 shipping-zip-code-step shipping-zip-code-set-alternative">
              <p>
                Set an alternate ZIP Code<sup>™</sup> to appear when you're
                shipping from a ZIP Code<sup>™</sup> that's different from your
                return address.
              </p>
            </div>
          </div>
          <div id="zip-code-top-id" className="row" tabIndex="-1">
            <div className="col-12 shipping-zip-code-step shipping-zip-code-line">
              <p>
                <strong>
                  Alternate Shipping ZIP Code<sup>™</sup>
                </strong>
              </p>
              {!this.state.editZipCode ? (
                <React.Fragment>
                  {this.state.shipFromPostalCode ? (
                    <p className="shipping-zip-code-default">
                      {this.state.shipFromPostalCode}
                    </p>
                  ) : (
                    <p className="shipping-zip-code-default">
                      No alternate shipping from ZIP Code<sup>™</sup> has been
                      set.
                    </p>
                  )}
                  <p className="shipping-zip-code-updates">20004</p>
                  <div className="shipping-zip-code">
                    <div className="edit-link">
                      <button
                        type="button"
                        className="inline-link-edit shipping-zip-code-edit-link button-link"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            editZipCode: true,
                            editZipCodeFocus: true
                          });
                        }}
                        aria-label="Edit ZIP code"
                      >
                        <strong>Edit</strong>
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div className="row shipping-zip-code-edit-append">
                  <div className="col-12 col-md-5 form-group required-field">
                    <label htmlFor="ship-from-another-zip">
                      ZIP Code<sup>™</sup>
                    </label>
                    <input
                      tabIndex="0"
                      type="text"
                      id="ship-from-another-zip"
                      className="form-control"
                      placeholder="00000"
                      maxLength="5"
                      value={
                        this.state.shipFromPostalCodeTemp !== undefined
                          ? this.state.shipFromPostalCodeTemp
                          : this.state.shipFromPostalCode
                      }
                      onChange={(e) =>
                        this.setState({
                          shipFromPostalCodeTemp: e.currentTarget.value
                        })
                      }
                    />
                    <span className="error-message" />
                  </div>
                  <div className="col-12 button-wrapper shipping-zip-code-btns">
                    <div className="button-container">
                      <button
                        type="button"
                        className="btn-primary shipping-zip-code-save-edit-btn"
                        tabIndex="0"
                        name="editZipCode"
                        onClick={(e) => this.saveOptions(e, e.target.name)}
                        aria-label="Save ZIP code"
                      >
                        Save
                      </button>
                    </div>
                    <div className="button-container">
                      <button
                        type="button"
                        className="btn-primary button--white"
                        tabIndex="0"
                        onClick={(e) => this.cancelEdit(e, "editZipCode")}
                        aria-label="Cancel ZIP code"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        />
        {this.state.isLoaded ? (
          <React.Fragment>
            <div
              id="preferences-top-id"
              className="Preferences_Greeting_Container"
              tabIndex="-1"
            >
              <div className="container-fluid">
                <h2 className="d-md-none responsive-header">Preferences</h2>
                <h2>
                  {this.state.fetchedPreferences.userPref.userReturnAddr
                    .firstName
                    ? "Hi " +
                      this.state.fetchedPreferences.userPref.userReturnAddr
                        .firstName +
                      ". "
                    : ""}
                  Set up your Click-N-Ship preferences to make shipping easier!
                </h2>
                <div className="row">
                  <div className="col-12 col-md-11 col-lg-10">
                    <p className="preferences-greeting">
                      Set up your shipping preferences so that it is easier to
                      fill out the label form in the future. You may update your
                      preferences or change your selections on a specific form
                      at any time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {this.renderPackageOptions()}
            {hidePostagePriceUtils.areLookupCodesActive(
              this.props.userData,
              this.props.lookupCodes
            ) && this.renderHidePostagePrice()}
            {this.renderShipmentNotifications()}
            {this.props.userData.accountType === "Business" &&
            this.props.userData.parcelSelectFlag ? (
              this.renderUSPSConnect()
            ) : (
              <></>
            )}
            {this.renderNotifyRecipient()}
            {this.renderPrintSettings()}
            {this.renderReferenceNumber()}
            {this.renderReturnAddress()}
            {this.renderShippingZipCode()}
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Preferences);
