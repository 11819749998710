import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const CountryOfOriginPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Country of Origin is the country where the goods were manufactured and
        assembled.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Country Of Origin Information Icon"
      header="Country Origin"
      content={content}
    />
  );
};

export default CountryOfOriginPopover;
