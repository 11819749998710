import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const ContentsDescPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>Required if "Other" is selected</p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Contents Description Information Icon"
      header="Contents Description"
      content={content}
    />
  );
};

export default ContentsDescPopover;
