import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import axios from "axios";

import CategoryLists from "../Subcomponents/CategoryLists";
import InputFields from "../Subcomponents/InputFields";
import HsTariffNotFound from "./HsTariffNotFound";

const LookupHsTariffCode = (props) => {
  const {
    // Modal controls
    isOpen,
    toggle,
    // Props controlling input fields
    item,
    itemDescMaxLength,
    handleItemEntry,
    clearParentError,
    updateHsTariff,
    isHsTariffMandatory,
    setCanOmitHsTariff,
    shipToCountryCode,
    // Other required props
    toggleLoadingBar,
    countryList,
    lookupCodes,
    deliveryStateId,
    checkForProhibitions
  } = props;

  // State that contains all errors for TLC fields
  const [tlcErrors, setTlcErrors] = useState({
    itemDesc: "",
    itemQty: "",
    unitValue: "",
    weightInPounds: ""
  });

  // State that determines if an error message should be focused via the
  // keyboard
  const [focusOnError, setFocusOnError] = useState(false);

  // State for displaying the HS tariff not found modal
  const [
    displayHsTariffNotFoundModal,
    setDisplayHsTariffNotFoundModal
  ] = useState(false);

  // Focus on the first error present
  useEffect(() => {
    if (focusOnError) {
      if (tlcErrors.itemDesc) {
        document.getElementById("itemDescTlc-a11y").focus();
      } else if (tlcErrors.itemQty) {
        document.getElementById("itemQtyTlc-a11y").focus();
      } else if (tlcErrors.unitValue) {
        document.getElementById("unitValueTlc-a11y").focus();
      } else if (tlcErrors.weightInPounds) {
        document.getElementById("weightInPoundsTlc-a11y").focus();
      }
      setFocusOnError(false);
    }
  }, [focusOnError, tlcErrors]);

  // Event handler for all fields present both within and outside this modal
  const handleFields = (event) => {
    let name = event.currentTarget.name;
    const value = event.currentTarget.value;
    // Remove the "Tlc" from the input field name (the last three characters)
    // to find the name of the associated error field, if present,
    // and the equivalent name of the input outside this modal
    name = name.substring(0, name.length - 3);
    let errorName = name;
    // The weight in pounds and weight in ounces fields both use the same error
    // (weightInPounds)
    if (name === "setWeightInOz") {
      errorName = "weightInPounds";
    }
    // Remove the error message for this input field, if present
    if (Object.hasOwn(tlcErrors, errorName)) {
      setTlcErrors((prevState) => {
        return {
          ...prevState,
          [errorName]: ""
        };
      });
    }
    // Remove the error message in the parent component for this input field,
    // if present
    switch (name) {
      case "itemDesc":
        clearParentError("detailedDescription");
        break;
      case "itemQty":
        clearParentError("quantity");
        break;
      case "unitValue":
        clearParentError("itemValue");
        break;
      case "weightInPounds":
      case "setWeightInOz":
        clearParentError("itemWeight");
        break;
      default:
        break;
    }
    // Update the value of the field
    handleItemEntry(name)(value);
    // Reset the HS tariff field since changing any of these inputs may change
    // the HS tariff
    updateHsTariff("");
  };

  // Event handler for all TLC fields exclusive to this modal
  const handleTlcFields = (event) => {
    const name = event.currentTarget.name;
    let value = event.currentTarget.value;
    // Remove any non-numeric characters from the length, width, and height fields
    if (
      name === "tlcProductLength" ||
      name === "tlcProductWidth" ||
      name === "tlcProductHeight"
    ) {
      value = value.replaceAll(/\D/g, "");
    }
    // If the category is changed, the subcategory should be reset
    if (name === "tlcCategory" && value !== item.tlcCategory) {
      handleItemEntry("tlcSubcategory")("");
    }
    handleItemEntry(name)(value);
    // Reset the HS tariff field since changing any of these inputs may change
    // the HS tariff
    updateHsTariff("");
  };

  const classifyItem = () => {
    if (areInputsValid()) {
      toggleLoadingBar(true);
      axios
        .post("/go/cnsrest/tlc/classify", {
          item: {
            id: "1",
            amount: item.unitValue,
            brand: item.tlcBrand,
            category: item.tlcCategory,
            description: item.itemDesc,
            detail: item.tlcItemDetail,
            language_code: "EN",
            subcategory: item.tlcSubcategory,
            upc_code: item.tlcUpcCode
          },
          ship_to_country:
            deliveryStateId !== "2" &&
            deliveryStateId !== "3" &&
            deliveryStateId !== "6"
              ? shipToCountryCode
              : null
        })
        .then((response) => {
          toggleLoadingBar(false);
          if (
            response?.data?.classified_code?.length >= 6 &&
            !response.data.error_message
          ) {
            updateHsTariff(response.data.classified_code);
            toggle(null, null);
            checkForProhibitions(false);
          } else {
            setDisplayHsTariffNotFoundModal(true);
          }
        })
        .catch((error) => {
          console.log(error);
          toggleLoadingBar(false);
          setDisplayHsTariffNotFoundModal(true);
        });
    } else {
      setFocusOnError(true);
    }
  };

  const areInputsValid = () => {
    let errorFound = false;
    if (!item.itemDesc || item.itemDesc.length < 2) {
      errorFound = true;
      setTlcErrors((prevState) => {
        return {
          ...prevState,
          itemDesc: "Please enter a description of this item."
        };
      });
    }
    if (item.itemQty < 1) {
      errorFound = true;
      setTlcErrors((prevState) => {
        return {
          ...prevState,
          itemQty: "Please enter the quantity of this item."
        };
      });
    }
    if (!(item.unitValue > 0)) {
      errorFound = true;
      setTlcErrors((prevState) => {
        return {
          ...prevState,
          unitValue: "Please enter the total value of this item."
        };
      });
    }
    if (item.weightInPounds < 1 && item.setWeightInOz < 1) {
      errorFound = true;
      setTlcErrors((prevState) => {
        return {
          ...prevState,
          weightInPounds: "Please enter the total weight of this item."
        };
      });
    }
    return !errorFound;
  };

  return (
    <Modal id="modal-tlc" isOpen={isOpen}>
      <div className="modal-content modal-container">
        <div className="modal-header">
          <button
            type="button"
            className="close button-link"
            id="close"
            data-dismiss="modal"
            tabIndex="0"
            onClick={(e) => {
              setTlcErrors({
                itemDesc: "",
                itemQty: "",
                unitValue: "",
                weightInPounds: ""
              });
              toggle(e, null);
            }}
          >
            <span className="visuallyhidden">Close Modal</span>
          </button>
          <h3 className="modal-title">
            Do you know the HS Tariff Code for this item?
          </h3>
        </div>
        <div className="modal-body">
          <div className="body-content">
            <p id="tlc-lookup-paragraph">
              HS code is short for Harmonized Commodity Description and Coding
              System. It refers to the single, universal and global
              classification system for most traded goods and products. It is
              used to classify products upon export and to calculate applicable
              taxes and duties.
            </p>
            <h4>Enter information</h4>
            <p id="tlc-lookup-add-more-details" className="alert-message">
              Please add more detail about your item. Consider including what it
              is, the brand, color, material
            </p>
            <div className="row">
              <InputFields
                divClassList="col-12 form-group"
                isRequired={true}
                errorMessage={tlcErrors.itemDesc}
                includeAssistiveError={true}
                inputId="itemDescTlc"
                inputClassList="form-control"
                inputType="text"
                labelText="*Item Description"
                inputMaxLength={itemDescMaxLength}
                inputShowLength={true}
                inputValue={item.itemDesc}
                inputOnChange={handleFields}
              />
            </div>
            <div className="row">
              <InputFields
                divClassList="col-8 form-group"
                inputId="tlcCategory"
                inputClassList="form-control dropdown"
                labelText="Select a Category"
                inputValue={item.tlcCategory}
                inputOnChange={handleTlcFields}
                elementType="select"
                optionsList={CategoryLists.getCategories}
              />
            </div>
            {item.tlcCategory && (
              <div className="row">
                <InputFields
                  divClassList="col-8 form-group"
                  inputId="tlcSubcategory"
                  inputClassList="form-control dropdown"
                  labelText="Select a sub-category"
                  inputValue={item.tlcSubcategory}
                  inputOnChange={handleTlcFields}
                  elementType="select"
                  optionsList={CategoryLists.getSubcategories(item.tlcCategory)}
                />
              </div>
            )}
            <h4 id="tlc-lookup-tell-us-more">Tell us more about this item</h4>
            <div className="row">
              <InputFields
                divClassList="col-3 tlc-quantity-input form-group"
                isRequired={true}
                errorMessage={tlcErrors.itemQty}
                includeAssistiveError={true}
                inputId="itemQtyTlc"
                inputClassList="form-control"
                inputType="text"
                labelText="*Total Quantity"
                inputValue={item.itemQty}
                inputOnChange={handleFields}
              />
              <div className="tlc-quantity-btn-wrapper">
                <button
                  className="btn btn-default tlc-quantity-btn"
                  onClick={() => {
                    setTlcErrors((prevState) => {
                      return {
                        ...prevState,
                        itemQty: ""
                      };
                    });
                    handleItemEntry("itemQty")(
                      String(Number(item.itemQty) + 1)
                    );
                  }}
                >
                  <strong>+</strong>
                </button>
              </div>
              <div className="tlc-quantity-btn-wrapper">
                <button
                  className="btn btn-default tlc-quantity-btn"
                  onClick={() => {
                    setTlcErrors((prevState) => {
                      return {
                        ...prevState,
                        itemQty: ""
                      };
                    });
                    const itemQty = Number(item.itemQty);
                    handleItemEntry("itemQty")(
                      String(itemQty >= 1 ? itemQty - 1 : 0)
                    );
                  }}
                >
                  <strong>-</strong>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-6 form-group">
                <div
                  id="tlc-lookup-country-of-origin-wrapper"
                  className="form-group-alt"
                >
                  <div className="inline-label-wrapper">
                    <label>Country of Origin</label>
                  </div>
                  <select
                    className="form-control country-dropdown dropdown"
                    id="tlc-country-of-origin-dropdown"
                    onChange={(e) =>
                      handleItemEntry("countryId")(e.target.value)
                    }
                    value={item.countryId ? item.countryId.countryDbId : "0"}
                  >
                    {countryList.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <InputFields
                divClassList="col-6 form-group"
                isRequired={true}
                errorMessage={tlcErrors.unitValue}
                includeAssistiveError={true}
                inputId="unitValueTlc"
                inputClassList="form-control prepend"
                inputType="text"
                labelText="*Total Item Value"
                inputDivClassList="input-group"
                inputPlaceholder="00.00 USD"
                inputValue={item.unitValue}
                inputOnChange={handleFields}
                fieldPrefix="$"
              />
              <InputFields
                divClassList="col-6 form-group"
                inputId="tlcItemDetail"
                inputClassList="form-control"
                inputType="text"
                labelText="Item Detail"
                inputMaxLength="100"
                inputValue={item.tlcItemDetail}
                inputOnChange={handleTlcFields}
              />
            </div>
            <div className="row">
              <InputFields
                divClassList="col-6 form-group"
                inputId="tlcItemColor"
                inputClassList="form-control"
                inputType="text"
                labelText="Item Color"
                inputMaxLength="100"
                inputValue={item.tlcItemColor}
                inputOnChange={handleTlcFields}
              />
              <InputFields
                divClassList="col-6 form-group"
                inputId="tlcBrand"
                inputClassList="form-control"
                inputType="text"
                labelText="Brand"
                inputMaxLength="100"
                inputValue={item.tlcBrand}
                inputOnChange={handleTlcFields}
              />
            </div>
            <div className="row">
              <InputFields
                divClassList="col-6 form-group"
                inputId="tlcUpcCode"
                inputClassList="form-control"
                inputType="text"
                labelText="UPC Code"
                inputMaxLength="100"
                inputValue={item.tlcUpcCode}
                inputOnChange={handleTlcFields}
              />
            </div>
            <div className="row">
              <InputFields
                divClassList="col-4 form-group"
                isRequired={true}
                errorMessage={tlcErrors.weightInPounds}
                includeAssistiveError={true}
                inputId="weightInPoundsTlc"
                inputClassList="dimensions-form form-control append"
                inputType="text"
                labelText="*Total Weight"
                inputDivClassList="input-group"
                inputValue={item.weightInPounds}
                inputOnChange={handleFields}
                fieldSuffix="lbs"
              />
              <InputFields
                divClassList="col-4 package-oz-input form-group"
                errorMessage={tlcErrors.weightInPounds}
                includeAssistiveError={true}
                inputId="setWeightInOzTlc"
                inputClassList="dimensions-form form-control append"
                inputType="text"
                labelText=""
                inputDivClassList="input-group"
                inputValue={item.setWeightInOz}
                inputOnChange={handleFields}
                fieldSuffix="oz"
                fieldSuffixId="setWeightInOzTlcUnitsId"
              />
            </div>
            <p id="tlc-lookup-dimensions-text">
              Dimensions of Product in Inches
            </p>
            <div className="row">
              <InputFields
                divClassList="col-4 form-group tlc-lookup-dimensions"
                inputId="tlcProductLength"
                inputClassList="form-control append"
                inputType="text"
                labelText="Length"
                inputDivClassList="input-group"
                inputMaxLength="5"
                inputValue={item.tlcProductLength}
                inputOnChange={handleTlcFields}
                fieldSuffix="in"
              />
              <InputFields
                divClassList="col-4 form-group tlc-lookup-dimensions"
                inputId="tlcProductWidth"
                inputClassList="form-control append"
                inputType="text"
                labelText="Width"
                inputDivClassList="input-group"
                inputMaxLength="5"
                inputValue={item.tlcProductWidth}
                inputOnChange={handleTlcFields}
                fieldSuffix="in"
              />
              <InputFields
                divClassList="col-4 form-group tlc-lookup-dimensions"
                inputId="tlcProductHeight"
                inputClassList="form-control append"
                inputType="text"
                labelText="Height"
                inputDivClassList="input-group"
                inputMaxLength="5"
                inputValue={item.tlcProductHeight}
                inputOnChange={handleTlcFields}
                fieldSuffix="in"
              />
            </div>
            <div className="row">
              <div className="col-12 button-wrapper tlc-button-wrapper">
                <div className="button-container">
                  <button
                    className="btn btn-primary"
                    id="submit-hs-tariff-info-button"
                    type="submit"
                    onClick={classifyItem}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
            <HsTariffNotFound
              isOpen={displayHsTariffNotFoundModal}
              toggle={setDisplayHsTariffNotFoundModal}
              updateHsTariff={updateHsTariff}
              isHsTariffMandatory={isHsTariffMandatory}
              setCanOmitHsTariff={setCanOmitHsTariff}
              checkForProhibitions={checkForProhibitions}
              toggleParentModal={toggle}
              lookupCodes={lookupCodes}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LookupHsTariffCode;
